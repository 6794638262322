// UpcomingInvoiceDisplay.js
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { node } from "constants/constants";

export default function UpcomingInvoiceDisplay({
  stripeSubscriptionId,
  stripeAccountId,
  labelClassName = "font-semibold text-sm sm:text-base",
  contentClassName = "text-gray-500 text-sm sm:text-base",
}) {
  const [upcomingInvoice, setUpcomingInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUpcomingInvoice = async () => {
      try {
        const response = await fetch(
          `${node}/serviceSubscriptionsApi/upcomingInvoice`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              stripeSubscriptionId,
              stripeAccountId,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch upcoming invoice");
        }

        const data = await response.json();
        setUpcomingInvoice(data.upcomingInvoice);
      } catch (err) {
        console.error("Error fetching upcoming invoice:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUpcomingInvoice();
  }, [stripeSubscriptionId, stripeAccountId]);

  if (loading)
    return (
      <div>
        <h3 className={labelClassName}>Next Invoice</h3>
        <div className="flex items-center space-x-2">
          <div className="h-5 w-32 bg-gray-200 rounded animate-pulse"></div>
          <div className="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
        </div>
      </div>
    );

  if (!upcomingInvoice) return null;

  return (
    <div>
      <h3 className={labelClassName}>Next Invoice</h3>
      <div className={`${contentClassName} flex items-center space-x-2`}>
        <p>
          {format(
            new Date(upcomingInvoice.next_payment_attempt * 1000),
            "MMMM d, yyyy"
          )}
        </p>
        <span className="text-gray-300">|</span>
        <p className="font-medium">
          ${(upcomingInvoice.amount_due / 100).toFixed(2)}
        </p>
      </div>
    </div>
  );
}

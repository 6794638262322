import React from "react";
import { calculateEndDateString, convertToJsDate } from "utils/helpers";
import { format, addYears } from "date-fns";
import { splitAddress } from "utils/helpers";

const frequencyMap = {
  monthly: "month",
  yearly: "year",
  quarterly: "quarter",
  "6 months": "6 months",
};

export default function SubDescription({ subscription, customer }) {
  let endDate = "Continues until canceled";

  if (subscription?.template?.type === "fixed") {
    // to calc end date we do duration times the frequecy so if duration is 12 months then it is 12 months after the start....
    // frequency is either monthly, quarterly, 6 months, yearly
    // "monthly", "quarterly", "6 months", "yearly"
    endDate = calculateEndDateString(subscription);
  }

  const formatDuration = (type, duration, startDate) => {
    const startDateJs = convertToJsDate(subscription.startDate);
    if (type === "fixed") {
      // calculate end date
      // let endDateString = new Date(startDateJs)
      // endDate.setFullYear(endDate.getFullYear() + duration);

      // or
      const endDate = addYears(startDateJs, duration);
      // then we add like 1 or 2 years to it
      return `${format(startDateJs, "MMMM do, yyyy")} - ${format(
        endDate,
        "MMMM do, yyyy"
      )} (${duration} ${duration > 1 ? "years" : "year"})`;
    }
    return `${format(startDateJs, "MMMM do, yyyy")} - until canceled`;
  };

  const addressSplit = splitAddress(customer?.address?.[0] || "");
  return (
    <div className="text-gray-600">
      <h1 className="font-semibold text-2xl sm:text-3xl text-gray-600 pl-2">
        {subscription.companyName}
      </h1>

      <div className="flex flex-col w-full rounded-lg bg-gray-25 py-4 sm:py-6 px-4 sm:px-6 mt-4 shadow-md sm:shadow-2xl">
        <h1 className="text-gray-500 text-sm sm:text-base">
          Review your service subscription
        </h1>

        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-4 sm:mt-6 px-4 sm:px-6 py-4 bg-gray-100 -mx-4 sm:-mx-6">
          <div className="flex flex-col pr-4 mb-4 sm:mb-0">
            <h2 className="text-gray-600 font-bold text-lg sm:text-xl">
              {subscription?.template?.title}
            </h2>
            <p className="text-gray-500 text-sm sm:text-base">
              {subscription?.template?.description}
            </p>
          </div>

          <div className="flex flex-row items-center">
            <div className="font-medium text-2xl sm:text-3xl">
              ${parseFloat(subscription?.template?.amount).toFixed(2)}
            </div>
            <div className="flex flex-col ml-2 text-gray-500 text-sm sm:text-base">
              <span className="leading-none">per</span>
              <span className="leading-none">
                {frequencyMap[subscription?.template?.paymentFrequency]}
              </span>
            </div>
          </div>
        </div>

        <hr className="border-gray-300 -mx-4 sm:-mx-6" />

        <div className="mt-4 sm:mt-8 space-y-2 sm:space-y-4">
          <div>
            <label className="font-semibold text-sm sm:text-base">Visits</label>
            <div className="text-gray-500 text-sm sm:text-base">
              {subscription?.template?.visits} visits per year
            </div>
          </div>

          <div>
            <label className="font-semibold text-sm sm:text-base">
              Duration
            </label>
            <div className="text-gray-500 text-sm sm:text-base">
              {formatDuration(
                subscription?.template?.type,
                subscription?.template?.duration,
                subscription.startDate
              )}
            </div>
          </div>

          {customer?.address?.[0] && (
            <div>
              <label className="font-semibold text-sm sm:text-base">
                Service Address
              </label>
              <div className="text-gray-500 text-sm sm:text-base">
                <p>{addressSplit[0]}</p>
                {addressSplit?.[1] && (
                  <p className="text-wrap">{addressSplit[1]}</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

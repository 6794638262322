import React from "react";
import Modal from "@mui/material/Modal";

export default function Dialogue({
  open,
  onClose,
  children,
  width = 500,
  p = "p-10",
  py = "py-8",
  fixedHeight = false,
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-bold ${p} ${py} rounded-md ${
          fixedHeight ? fixedHeight : ""
        } outline-none`}
        style={{ width: `${width}px` }}
      >
        {children}
      </div>
    </Modal>
  );
}

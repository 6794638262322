import { node } from "constants/constants";

export const updateServiceSubscriptionSignature = async ({
  businessId,
  subscriptionId,
  acceptObject,
}) => {
  try {
    const response = await fetch(
      `${node}/serviceSubscriptionsApi/update-signature`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          businessId,
          subscriptionId,
          acceptObject,
        }),
      }
    );
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Response was not ok.");
    }
    console.log("response was ok, data.success", data.success);
    return true;
  } catch (error) {
    console.log("error from update service subscription signature:", error);
    return false;
  }
};

export const fakeStartServiceSubscription = async ({
  businessId,
  subscriptionId,
}) => {
  try {
    const response = await fetch(
      `${node}/serviceSubscriptionsApi/fake-start-subscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          businessId,
          subscriptionId,
        }),
      }
    );
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Response was not ok.");
    }
    console.log("response was ok, data.success", data.success);
    return true;
  } catch (error) {
    console.log("error from update service subscription signature:", error);
    return false;
  }
};

import React from "react";
import AutocompleteDropdownMUI from "components/reusable/AutocompleteDropdownMUI";
import TaxesSelectMUI from "components/reusable/TaxesSelectMUI";
import TurnOnTax from "./TurnOnTax";

export default function SubDetails({
  subscriptionTemplates,
  subscriptionDetails,
  setSubscriptionDetails,
  setOpenTemplateModal,
}) {
  return (
    <>
      <h2 className="text-xl text-gray-800 mb-4 font-medium">Subscription</h2>
      <div className="w-3/4 max-w-xl  rounded-md p-6 border border-gray-100 shadow-lg mb-6">
        <div className="w-full ">
          {/* <h2 className="font-medium text-gray-800 mb-4">
        Select a Subscription Template
      </h2> */}
          <AutocompleteDropdownMUI
            options={subscriptionTemplates}
            value={subscriptionDetails.template}
            // setValue={setSelectedTemplate}
            onValueChange={(event, value) => {
              // console.log("value from autocomplete", value);

              setSubscriptionDetails((prev) => ({
                ...prev,
                template: value,
              }));
            }}
          />

          <div className="mt-3 ml-3">
            <button
              className="text-sm  text-indigo-500 hover:text-indigo-600 hover:bg-gray-50 rounded-full p-2 font-medium transition duration-200 -mb-2"
              onClick={() => setOpenTemplateModal(true)}
            >
              Create new template +
            </button>
          </div>

          {subscriptionDetails?.template && (
            <div className="mt-6 flex flex-row justify-between">
              {/* <TaxesSelectMUI
                selectedTaxRate={subscriptionDetails?.tax}
                setSelectedTaxRate={(taxRate) => {
                  setSubscriptionDetails((prev) => ({
                    ...prev,
                    tax: taxRate,
                  }));
                }}
              /> */}

              <div className="font-medium flex flex-col">
                <h2 className="text-gray-800 text-lg">
                  {subscriptionDetails?.template?.title}
                </h2>
                <ul className="list-disc pl-5 space-y-1 text-sm text-gray-600">
                  {subscriptionDetails.template.type === "fixed" ? (
                    <li className="text-sm text-gray-600">
                      Continues for {subscriptionDetails?.template?.duration}{" "}
                      {subscriptionDetails?.template?.duration > 1
                        ? "years"
                        : "year"}
                    </li>
                  ) : (
                    <li>Continues until cancelled</li>
                  )}
                  <li>
                    {subscriptionDetails?.template?.visits} visits per year
                  </li>
                  <li>
                    $
                    {parseFloat(subscriptionDetails?.template?.amount).toFixed(
                      2
                    )}{" "}
                    paid {subscriptionDetails?.template?.paymentFrequency}
                  </li>
                  {subscriptionDetails?.template?.requireSignature && (
                    <li>Signature required</li>
                  )}
                </ul>
              </div>

              <TurnOnTax
                subscriptionDetails={subscriptionDetails}
                setSubscriptionDetails={setSubscriptionDetails}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import React from "react";
import InputMUI from "components/reusable/InputMUI";
import InformationIconTooltip from "components/reusable/InformationIconTooltip";

export default function Visits({ templateDetails, setTemplateDetails }) {
  const handleChangeVisits = (event) => {
    const value = event.target.value;

    if (value === "") {
      setTemplateDetails({ ...templateDetails, visits: "" });
      return;
    }

    const number = parseInt(value, 10);
    if (!isNaN(number) && number >= 0 && number <= 12) {
      setTemplateDetails({ ...templateDetails, visits: number.toString() });
    }
  };

  const visitsError =
    templateDetails.visits === "" ||
    templateDetails.visits < 0 ||
    templateDetails.visits > 12;

  const helperText =
    templateDetails.visits === ""
      ? "Field required"
      : templateDetails.visits > 12
      ? "Max 12 visits per year"
      : "";
  return (
    <>
      {" "}
      <div className="flex flex-row items-center mt-8">
        <h2 className="text-lg mr-2 font-medium">Visits</h2>
        <InformationIconTooltip
          text="The number of visits per year for this subscription. You will be able to schedule these visits for the customer once the subscription is created. If you do not want any visits to be scheduled, set this to 0."
          className="ml-2"
        />
      </div>
      <div className="mt-2 w-36">
        <InputMUI
          label="Visits Per Year"
          type="number"
          value={templateDetails.visits}
          onChangeFunction={handleChangeVisits}
          error={visitsError}
          helperText={helperText}
        />
      </div>
    </>
  );
}

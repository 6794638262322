// layouts/SidebarLayout.js
import React, { useState } from "react";
import NavBar from "components/other/NavBar";
import SidebarCustomizations from "components/Sidebar/SidebarCustomizations";
import { Outlet } from "react-router-dom";

const SidebarLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true); // State is now managed here

  return (
    <div className="flex">
      <SidebarCustomizations isOpen={isOpen} setIsOpen={setIsOpen} />
      <div
        className={`flex-grow transition-all duration-500 ease-in-out ${
          isOpen ? "ml-[190px]" : "ml-0"
        }`}
      >
        {/* Outlet will render the component for the active nested route */}
        <Outlet />
      </div>
    </div>
  );
};

export default SidebarLayout;

import React, { useContext, useState } from "react";
import { UserContext } from "../../index";
import { auth, db } from "../../utils/firebase";
import { updateDoc, doc } from "firebase/firestore";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { useSnackBar } from "context/SnackBarContext";
import NormalButton from "components/buttons/NormalButton";
import { PasswordGeneral } from "components/reusable/fields";
import { PageHeader } from "../../layouts/PageHeader";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";

export default function Settings() {
  const { user, userData, bannerVisible } = useContext(UserContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { openSnackBar } = useSnackBar();

  async function handleChangePassword(e) {
    console.log("change password");
    if (loading) return;
    e.preventDefault();
    setMessage("");

    setLoading(true);

    if (!currentPassword || !newPassword || !confirmNewPassword) {
      openSnackBar("Please fill in all fields.", false, true);
      setLoading(false);
      return;
    }
    // console.log(currentPassword, newPassword, confirmNewPassword, user.email);

    if (newPassword !== confirmNewPassword) {
      openSnackBar("New passwords do not match.", false, true);
      setLoading(false);
      return;
    }
    try {
      // Re-authenticate the user
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      // console.log("credential", credential);

      await reauthenticateWithCredential(user, credential);

      // Update the user's password
      await updatePassword(user, newPassword);

      // Remove temporary password from the database if it exists
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        temporaryPassword: null,
      });

      openSnackBar("Password updated successfully.", true);
    } catch (error) {
      console.log("error", error.message);
      console.log("error.code", error.code);

      switch (error.code) {
        case "auth/weak-password":
          console.log("case auth/weak-password");
          openSnackBar("Password is too weak. Please try again.", false, true);
          break;
        case "auth/user-not-found":
          openSnackBar("No user found with this email. Please check and try again.", false, true);
          break;
        case "auth/wrong-password":
          openSnackBar("Incorrect password. Please check and try again.", false, true);
          break;
        case "auth/user-disabled":
          openSnackBar("Your account has been disabled. Please contact support.", false, true);
          break;
        case "auth/invalid-email":
          openSnackBar("Invalid email format. Please check and try again.", false, true);
          break;
        default:
          openSnackBar("An unexpected error occurred. Please try again.", false, true);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div
        className={`bg-gray-100 ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        }  overflow-y-auto`}
      >
        <CenteredMaxWidthLayout>
          <PageHeader title="Settings" />
          <div className="flex flex-col mx-5 bg-gray-50 shadow-md rounded-sm mb-20 relative">
            <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
              Account Details
            </h1>
            <div className="flex flex-row justify-between px-4 py-4 rounded-sm">
              <div className="flex flex-col gap-1">
                <h1 className="text-xl font-bold text-gray-900">Name</h1>
                <h1 className="text-gray-900">
                  {userData?.userData.firstName + " " + userData?.userData.lastName}
                </h1>
                {/* <button className="bg-gray-700 hover:bg-gray-600 text-xs w-fit text-green-200 hover:text-green-400 p-2 rounded-md">
                Change Plan
              </button> */}
              </div>
              <div className="flex flex-col gap-1">
                <h1 className="text-xl font-bold text-gray-900">Account role</h1>
                <h1 className="text-gray-900">{userData?.userData.userType}</h1>
              </div>
            </div>
            <div className="px-4 py-4 ">
              <h1 className="text-xl font-bold text-gray-900">Email</h1>

              <h1 className="text-gray-900">{user?.email}</h1>
            </div>
            <div className="px-4 py-4">
              <h1 className="text-xl font-bold text-gray-900">Change Password</h1>

              <div className="mt-4">
                <PasswordGeneral
                  className="w-72"
                  id="current-password"
                  label="Current Password:"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                  autoComplete="new-password"
                />
              </div>
              <div className="mt-4">
                <PasswordGeneral
                  className="w-72"
                  id="new-password"
                  label="New Password:"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  autoComplete="new-password"
                />
              </div>
              <div className="mt-4">
                <PasswordGeneral
                  className="w-72"
                  id="confirm-new-password"
                  label="Confirm New Password:"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  required
                  autoComplete="new-password"
                />
              </div>
              <div className="mt-10 mb-8">
                <NormalButton
                  text="Change Password"
                  onClick={handleChangePassword}
                  loading={loading}
                  width="w-48"
                />
              </div>
            </div>

            <h1 className="absolute -bottom-10 left-4">
              For help, or if you have bugs to report please contact{" "}
              <a href="mailto:support@homebase360.io" className="font-bold">
                support@homebase360.io
              </a>
            </h1>
          </div>
        </CenteredMaxWidthLayout>
      </div>
    </>
  );
}

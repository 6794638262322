import React, { useState, useEffect, useCallback } from "react";
import { useUserContext } from "context/UserContext";
import DisabledButton from "components/buttons/DisabledButton";
import { db } from "utils/firebase";
import { getDocs, collection } from "firebase/firestore";
import { OutlineButton } from "components/NewButtons/OutlineButton";
import { FaPlus } from "react-icons/fa";
import AddTemplateModal from "features/ServiceSubscription/components/Templates/AddTemplateModal";

const defaultTemplateDetails = {
  title: "",
  description: "",
  type: "indefinite", // default type
  duration: 1, // default duration, used if type is "fixed"
  visits: 2, // default number of visits // max of 12 per year
  amount: "0.00", // default amount
  paymentFrequency: "monthly", // default frequency
  allowOtherPayments: false, // default to false
  requireSignature: false, // default to false
  terms: null,
  attachments: [],
  // terms look like this when truthy
  //   {
  //     title: "",
  //     description: "",
  //   },
};

export default function ServiceSubscriptionCustomizations() {
  const { userData, setUserData, bannerVisible } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const fetchTemplates = useCallback(async () => {
    setLoading(true);
    try {
      const templatesRef = collection(
        db,
        "businesses",
        userData.userData.businessId,
        "subscriptionTemplates"
      );
      const templatesSnapshot = await getDocs(templatesRef);
      const templatesList = templatesSnapshot.docs.map((doc) => doc.data());
      if (templatesList.length === 0) {
        setTemplates(null);
        return;
      }
      console.log("Templates: ", templatesList);
      setTemplates(templatesList);
    } catch (error) {
      console.error("Error getting documents: ", error);
      setTemplates(null);
    } finally {
      setLoading(false);
    }
  }, [userData.userData.businessId]);

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <div
      className={`bg-gray-100 ${
        bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
      }  overflow-y-auto pb-36`}
    >
      <AddTemplateModal
        open={selectedTemplate}
        onClose={() => setSelectedTemplate(null)}
        prevTemplateDetails={selectedTemplate}
        setSubscriptionTemplates={setTemplates}
        setSubscriptionDetails={setSelectedTemplate}
      />
      <div className="flex items-center justify-center">
        <div className="flex flex-col w-11/12 max-w-3xl mt-6 mb-6">
          <div className="flex flex-row justify-between items-start mb-6">
            <div>
              <h1 className="text-3xl font-extrabold text-gray-900">
                Service Subscriptions Templates
              </h1>
              <p className="text-sm text-gray-500">
                Create and customize your different service subscription
                templates
              </p>
            </div>

            <OutlineButton
              text="New Template"
              onClick={() => setSelectedTemplate(defaultTemplateDetails)}
              icon={<FaPlus className=" h-3 w-3" />}
            />
          </div>

          <div className="bg-white rounded-lg mb-4 text-sm text-gray-700 overflow-hidden shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-48"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 flex-1 "
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-32"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-32"
                  >
                    Frequency
                  </th>
                  <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 sm:pr-6 w-16"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white relative">
                {loading ? (
                  <LoadingTemplatesView />
                ) : templates && templates.length > 0 ? (
                  templates.map((template) => (
                    <tr key={template.templateId}>
                      <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-48">
                        {template.title}
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500 flex-1 ">
                        {template.description ? template.description : "-"}
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500 w-32">
                        {template.amount ? "$" : "-"}
                        {parseFloat(template.amount).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500 w-32">
                        {template.paymentFrequency}
                      </td>
                      <td className="relative  py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-16">
                        <button
                          className="text-yellow-500 hover:text-yellow-600"
                          onClick={() => {
                            setSelectedTemplate(template);
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <EmptyTemplatesView
                    setSelectedTemplate={setSelectedTemplate}
                    defaultTemplateDetails={defaultTemplateDetails}
                  />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const LoadingTemplatesView = () => {
  return (
    <>
      {[...Array(5)].map((_, index) => (
        <tr key={index} className="animate-pulse">
          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-48">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          </td>
          <td className="px-3 py-4 text-sm text-gray-500 flex-1">
            <div className="h-4 bg-gray-200 rounded w-full"></div>
          </td>
          <td className="px-3 py-4 text-sm text-gray-500 w-32">
            <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          </td>
          <td className="px-3 py-4 text-sm text-gray-500 w-32">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          </td>
          <td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-16">
            <div className="h-4 bg-gray-200 rounded w-1/2 ml-auto"></div>
          </td>
        </tr>
      ))}
    </>
  );
};
const EmptyTemplatesView = ({
  setSelectedTemplate,
  defaultTemplateDetails,
}) => {
  return (
    <>
      {[...Array(5)].map((_, index) => (
        <tr key={index} className="animate-pulse">
          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-48">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          </td>
          <td className="px-3 py-4 text-sm text-gray-500 flex-1">
            <div className="h-4 bg-gray-200 rounded w-full"></div>
          </td>
          <td className="px-3 py-4 text-sm text-gray-500 w-32">
            <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          </td>
          <td className="px-3 py-4 text-sm text-gray-500 w-32">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          </td>
          <td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-16">
            <div className="h-4 bg-gray-200 rounded w-1/2 ml-auto"></div>
          </td>
        </tr>
      ))}
      {/* Overlay Message */}
      <tr className="absolute top-10 flex items-center justify-center w-full">
        <td colSpan="5" className="h-full">
          <div className="flex items-center justify-center h-full">
            <div className="bg-white p-4 rounded-md text-center shadow-lg w-96">
              <h1 className="text-xl font-semibold text-gray-900">
                Start by creating a{" "}
                <button
                  className="font-bold underline-effect hover:cursor-pointer underline"
                  onClick={() => setSelectedTemplate(defaultTemplateDetails)}
                >
                  template
                </button>
                !
              </h1>
              <p className="text-gray-700 mt-2">
                Templates are the first step in creating service subscriptions,
                which will allow you to create repeat payment plans for your
                customers.
              </p>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

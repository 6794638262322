import React, { useState } from "react";
import NormalButton from "components/buttons/NormalButton";
import AddOrReplacePaymentMethodModal from "components/payments/AddOrReplacePaymentMethodModal";
import RemovePaymentMethodModal from "components/payments/RemovePaymentMethodModal";
import DisplayCard from "components/payments/DisplayCard";

export default function PaymentMethodCustomerDetails({
  customerDetails,
  setCustomerDetails,
}) {
  // customer may be null in the beginning while fetching is happening so we need to make sure everything is null safe
  const [addOrReplacePaymentMethodModal, setAddOrReplacePaymentMethodModal] =
    useState(false);
  const [removePaymentMethodModal, setRemovePaymentMethodModal] =
    useState(false);

  // Show button for adding a card on file or requesting a card on file to be input via the customer.. via a link... we also need to build that link for the customer to input their card details
  // We will start with just having the ability to enter the card information and save it on file

  return (
    <div>
      <DisplayPaymentMethod
        customerDetails={customerDetails}
        setCustomerDetails={setCustomerDetails}
        setAddOrReplacePaymentMethodModal={setAddOrReplacePaymentMethodModal}
        setRemovePaymentMethodModal={setRemovePaymentMethodModal}
      />

      <AddOrReplacePaymentMethodModal
        open={addOrReplacePaymentMethodModal}
        onClose={() => setAddOrReplacePaymentMethodModal(false)}
        customerDetails={customerDetails}
        setCustomerDetails={setCustomerDetails}
      />
      <RemovePaymentMethodModal
        open={removePaymentMethodModal}
        onClose={() => setRemovePaymentMethodModal(false)}
        customerDetails={customerDetails}
        setCustomerDetails={setCustomerDetails}
      />
    </div>
  );
}

const DisplayPaymentMethod = ({
  customerDetails,
  setCustomerDetails,
  setAddOrReplacePaymentMethodModal,
  setRemovePaymentMethodModal,
}) => {
  const savedPaymentMethod = customerDetails?.stripePaymentMethod;

  if (!savedPaymentMethod) {
    return (
      <button
        className="bg-yellow-100 p-2 rounded-md shadow-sm border border-gray-400 hover:bg-yellow-200 hover:border-gray-600 transition-colors duration-200 font-medium text-sm"
        onClick={() => setAddOrReplacePaymentMethodModal(true)}
      >
        Add Card on File
      </button>
    );
  }

  return (
    <div className="flex flex-col">
      <DisplayCard savedPaymentMethod={savedPaymentMethod} />
      <div className="flex flex-row mt-2">
        <button
          onClick={() => setAddOrReplacePaymentMethodModal(true)}
          className="text-yellow-500 text-sm p-2 hover:bg-gray-50 rounded-md"
        >
          Replace
        </button>
        <button
          onClick={() => setRemovePaymentMethodModal(true)}
          className="text-yellow-500 text-sm p-2 hover:bg-gray-50 rounded-md"
        >
          Remove
        </button>
      </div>
    </div>
  );
};

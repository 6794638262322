import React, { useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

export default function ConnectBanner({ status }) {
  const [dismissed, setDismissed] = useState(false);

  let message;
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const doNotShow =
    pathname === "/settings/homebase360payments" || pathname === "/success";

  const handleLink = () => {
    setDismissed(true);
    navigate("/settings/homebase360payments");
  };

  switch (status) {
    case "Incomplete":
      message = (
        <>
          Looks like you may have some pending actions to complete.{" "}
          <button onClick={handleLink} className="text-yellow-200 underline">
            Go here
          </button>{" "}
          to complete them
        </>
      );
      break;
    case "None":
      message = (
        <>
          Looks like you haven't connected your Stripe account yet.{" "}
          <button onClick={handleLink} className="text-yellow-200 underline">
            Go here
          </button>{" "}
          to connect it
        </>
      );
      break;
  }

  if (dismissed || doNotShow) return null;

  return (
    <>
      <div
        className="absolute top-15 left-0 right-0 px-6 py-2 bg-indigo-700 text-white flex flex-row justify-between items-center"
        style={{ zIndex: 11, position: "fixed" }}
      >
        <div className="flex items-center justify-center flex-grow">
          <p className="text-sm md:text-base text-center">{message}</p>
        </div>
        <IoCloseCircle
          className="text-2xl ml-4 cursor-pointer shrink-0"
          onClick={() => setDismissed(true)}
        />
      </div>
    </>
  );
}

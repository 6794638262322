import { db } from "utils/firebase";
import {
  collection,
  query,
  doc,
  updateDoc,
  getDocs,
  getDoc,
  where,
  serverTimestamp,
  arrayUnion,
  setDoc,
  writeBatch,
  arrayRemove,
  deleteField,
  deleteDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

export const createCategory = ({ businessId, categoryId, categoryName }) => {
  const batch = writeBatch(db);

  const businessRef = doc(db, "businesses", businessId);
  const categoryRef = doc(db, "businesses", businessId, "price-book", categoryId);

  batch.update(businessRef, {
    priceBookCategories: arrayUnion({ id: categoryId, name: categoryName }),
  });
  batch.set(categoryRef, { lineItemIds: [] });

  return batch.commit();
};

export const updateCategories = ({ businessId, updatedCategories }) => {
  const businessRef = doc(db, "businesses", businessId);
  return updateDoc(businessRef, { priceBookCategories: updatedCategories });
};

export const deleteCategoryDoc = ({ businessId, categoryId }) => {
  const categoryRef = doc(db, "businesses", businessId, "price-book", categoryId);
  return deleteDoc(categoryRef);
};

export const createLineItem = ({ businessId, categoryId, lineItem }) => {
  const categoryRef = doc(db, "businesses", businessId, "price-book", categoryId);
  const lineItemId = lineItem.id ? lineItem.id : uuidv4();

  return updateDoc(categoryRef, {
    [lineItemId]: { ...lineItem, id: lineItemId },
    lineItemIds: arrayUnion(lineItemId),
  });
};

export const deleteLineItem = ({ businessId, categoryId, lineItemId }) => {
  const categoryRef = doc(db, "businesses", businessId, "price-book", categoryId);

  return updateDoc(categoryRef, {
    [lineItemId]: deleteField(),
    lineItemIds: arrayRemove(lineItemId), // Remove the line item ID from the array
  });
};

export const fetchAllLineItems = async ({ userData }) => {
  const priceBookCategories = userData.bizData.priceBookCategories;
  const lineItems = [];
  const querySnapshot = await getDocs(
    collection(db, "businesses", userData.userData.businessId, "price-book")
  );
  querySnapshot.forEach((doc) => {
    const categoryLineItems = doc.data();
    categoryLineItems.lineItemIds.forEach((lineItemId) => {
      const category = priceBookCategories.find((category) => category.id === doc.id);
      if (category)
        lineItems.push({
          ...categoryLineItems[lineItemId],
          category,
        });
    });
  });
  return lineItems;
};

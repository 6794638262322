import React, { useContext, useEffect } from "react";
import { UserContext } from "../../index";
import { node } from "../../constants/constants";
import { doc, updateDoc } from "firebase/firestore";
import { db, addValueToUserDoc } from "../../utils/firebase";
import { useNavigate } from "react-router-dom";

export default function Success() {
  const { user, userData, setRerun, bannerVisible, setUserData } =
    useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) return;
    // make a fetch post request to the server to get updated values from the users stripe account
    async function getUpdatedStripeData() {
      console.log("getUpdatedStripeData");
      try {
        const response = await fetch(`${node}/connect/retrieve-account`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stripeAccountId: userData.bizData.stripeAccountId,
          }),
        });
        const data = await response.json();
        // console.log("account data: ", data?.account);

        if (!data?.account) {
          throw new Error("No account data");
        }
        // update the user document in firestore with the charges_enabled value and details_submitted value
        const bizRef = doc(db, "businesses", userData?.userData?.businessId);
        await updateDoc(bizRef, {
          stripe_charges_enabled: data.account?.charges_enabled,
          stripe_details_submitted: data.account?.details_submitted,
          stripe_payouts_enabled: data.account?.payouts_enabled,
          stripe_email: data.account?.email,
        });
        setUserData((prev) => ({
          ...prev,
          bizData: {
            ...prev.bizData,
            stripe_charges_enabled: data.account?.charges_enabled,
            stripe_details_submitted: data.account?.details_submitted,
            stripe_payouts_enabled: data.account?.payouts_enabled,
            stripe_email: data.account?.email,
          },
        }));
        // we used to use setReRun but it doesn't work consistently so we ill update the user data directly instead and then navigate to the homebase360payments page
        // setRerun((prev) => !prev);
        // navigate("/settings/homebase360payments");
      } catch (error) {
        console.log(error.message);
      } finally {
        navigate("/settings/homebase360payments");
      }
    }
    getUpdatedStripeData();
  }, [userData]);

  return (
    <>
      <div
        className={`bg-gray-100 ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        } overflow-y-auto`}
      >
        <div className="flex flex-col items-center pt-12 h-full text-center">
          Your stripe account has been updated✅ <br />
          You will be redirected shortly...
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import TaxesDropdown from "components/other/TaxesDropdown";
import InputMUI, { inputStyles } from "components/reusable/InputMUI";
import PriceInputMUI from "components/reusable/PriceInputMUI";
import { NewLineItemModal } from "components/NewLineItemModal/NewLineItemModal";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { SwitchGreen as Switch } from "../../../components/ui/switchGreen";
import { useUserContext } from "../../../context/UserContext";

function LineItem({
  lineItem,
  index,
  updateLineItem,
  taxRates,
  disabled = false,
  autocompleteOptions = [],
  setAutocompleteOptions,
  unsavable = false,
}) {
  const { userData } = useUserContext();
  const { priceBookCategories } = userData.bizData;
  const priceBookCategoriesIds = useMemo(
    () => priceBookCategories?.map((cat) => cat.id),
    [priceBookCategories]
  );

  const { name, description, quantity, unitPrice, taxRate } = lineItem;

  const [taxesOn, setTaxesOn] = useState(!!taxRate);

  const [lineItemModalOpen, setLineItemModalOpen] = useState(false);
  const [lineItemSaved, setLineItemSaved] = useState(false);
  const [lineItemSelectedFromDropdown, setLineItemSelectedFromDropdown] = useState(false);

  const [priceInputFromSelect, setPriceInputFromSelect] = useState(null);

  const amount = useMemo(() => {
    const qty = parseFloat(quantity) || 0;
    const price = parseFloat(unitPrice) || 0;
    return qty * price;
  }, [quantity, unitPrice]);

  useEffect(() => {
    setTaxesOn(!!taxRate);
  }, [taxRate]);

  const handleToggleTax = () => {
    if (!taxRates?.length) {
      alert("Please add a tax rate first in your company profile");
    } else {
      const newTaxRate = taxesOn ? null : taxRates[0];
      updateLineItem(index, "taxRate", newTaxRate);
    }
  };

  const handleChangeQuantity = (event) => {
    const value = event.target.value;
    if (value === "") {
      updateLineItem(index, "quantity", value);
      return;
    }

    const number = parseInt(value, 10);
    if (!isNaN(number) && number >= 0) {
      updateLineItem(index, "quantity", number);
    }
  };

  return (
    <>
      <div className="flex-1">
        <div className="relative z-0">
          {disabled || unsavable ? (
            <InputMUI
              disabled={disabled}
              autoComplete="off"
              label="Item Name"
              value={name}
              setValue={(value) => updateLineItem(index, "name", value)}
            />
          ) : (
            <Autocomplete
              freeSolo
              options={autocompleteOptions.sort(
                (a, b) =>
                  priceBookCategoriesIds.indexOf(a.category.id) -
                  priceBookCategoriesIds.indexOf(b.category.id)
              )}
              blurOnSelect
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                updateLineItem(index, "name", newValue.name);
                updateLineItem(index, "description", newValue.description);
                updateLineItem(index, "unitPrice", newValue.unitPrice);
                setPriceInputFromSelect(newValue.unitPrice);
                setLineItemSelectedFromDropdown(true);
              }}
              disableClearable
              inputValue={name}
              onInputChange={(event, newInputValue) => {
                updateLineItem(index, "name", newInputValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              groupBy={(option) => option.category.name}
              renderOption={LineItemOption}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    variant="filled"
                    label="Item Name"
                    sx={inputStyles}
                    autoComplete="off"
                  />

                  <div className="absolute right-2 top-2 z-10">
                    {name && !lineItemSelectedFromDropdown && !unsavable && (
                      <Button
                        size="large"
                        variant="text"
                        color="yellow"
                        onClick={() => {
                          setLineItemModalOpen(true);
                        }}
                        disabled={lineItemSaved}
                      >
                        {lineItemSaved ? "saved" : "save"}
                      </Button>
                    )}
                  </div>
                </>
              )}
            />
          )}
        </div>

        <InputMUI
          disabled={disabled}
          autoComplete="off"
          label="Description (optional)"
          value={description}
          setValue={(value) => updateLineItem(index, "description", value)}
        />
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-2 w-1/2">
            <InputMUI
              disabled={disabled}
              autoComplete="off"
              label="Quantity"
              type="number"
              value={quantity}
              onChangeFunction={handleChangeQuantity}
              onBlur={() => {
                if (quantity === "") {
                  updateLineItem(index, "quantity", 0);
                }
              }}
              placeholder="0"
            />

            <PriceInputMUI
              disabled={disabled}
              autoComplete="off"
              label="Unit Price"
              value={unitPrice}
              onChange={(cleanValue) => updateLineItem(index, "unitPrice", cleanValue)}
              onBlur={() => {
                if (!unitPrice || isNaN(parseFloat(unitPrice))) {
                  updateLineItem(index, "unitPrice", "0.00");
                }
              }}
              placeholder="0.00"
              priceInputFromSelect={priceInputFromSelect}
            />
          </div>
          <div className="flex flex-row items-end font-medium w-48 mr-2 justify-between">
            <label className="text-lg text-gray-500">Amount</label>
            <h1 className="font-medium text-lg text-gray-500">
              $
              {amount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h1>
          </div>
        </div>
        <div className="flex flex-row gap-8 mx-1 my-4">
          <div className="flex flex-row items-center gap-2">
            <label className="text-base font-medium text-gray-600">Taxable</label>
            <Switch checked={taxesOn} onCheckedChange={handleToggleTax} disabled={disabled} />

            {taxesOn && (
              <TaxesDropdown
                disabled={disabled || !taxRates?.length}
                taxRates={taxRates || []}
                selectedTaxRate={taxRate}
                setSelectedTaxRate={(value) => updateLineItem(index, "taxRate", value)}
              />
            )}
          </div>
        </div>
      </div>
      {lineItemModalOpen && (
        <NewLineItemModal
          open={lineItemModalOpen}
          setOpen={setLineItemModalOpen}
          lineItem={lineItem}
          setSaved={setLineItemSaved}
          addAutocompleteOption={(newOption) =>
            setAutocompleteOptions((curOptions) => [...curOptions, newOption])
          }
          isLineItemEditable={false}
        />
      )}
    </>
  );
}

export default LineItem;

const LineItemOption = (props, option, { selected, active }) => (
  <li
    {...props}
    style={{
      ...props.style,

      fontFamily: '"Inter var", system-ui, sans-serif',
      backgroundColor: selected && !active ? "#fefce8" : undefined,
    }}
  >
    <div className="flex flex-row flex-1">
      <div className="flex-1">
        <p className="text-base">{option.name}</p>
        {option.description ? (
          <p
            className="text-sm text-gray-600 "
            style={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3, // Limit to three lines
              textOverflow: "ellipsis",
            }}
          >
            {option.description}
          </p>
        ) : (
          <p className="text-sm text-gray-400 italic">No description</p>
        )}
      </div>
      <div className="w-20 h-100 flex flex-row justify-end items-center">
        <p className="text-sm">
          $
          {parseFloat(option.unitPrice).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>
    </div>
  </li>
);

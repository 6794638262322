import React from "react";
import { BsPersonBadge } from "react-icons/bs";
import EmployeeSearch from "./EmployeeSearch";

export default function DispatchTo({
  employees,
  employeeList,
  setDispatchedTo,
  modal = false,
}) {
  return (
    <div
      className={`flex flex-col flex-1 bg-white   gap-2 ${
        modal ? "mt-0" : "mt-8 rounded-md shadow-bold"
      } p-4`}
    >
      <div className="flex flex-row items-center gap-2 mt-2 mb-2">
        <BsPersonBadge className="inline-block text-2xl" />
        <h1 className="text-xl font-bold">Dispatch To</h1>
      </div>
      <div className="mb-4">
        <EmployeeSearch
          employees={employees}
          employeeList={employeeList}
          setDispatchedTo={setDispatchedTo}
          modal={modal}
          label="Search"
        />
      </div>
    </div>
  );
}

export const fields = [
  {
    // Visible in table header and when matching columns.
    label: "First Name",
    // This is the key used for this field when we call onSubmit.
    key: "firstName",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["first name", "first", "name"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Stephanie",
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: "required",
        errorMessage: "First name is required",
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: "error",
      },
    ],
  },
  {
    label: "Last Name",
    key: "lastName",
    alternateMatches: ["last name", "last"],
    fieldType: {
      type: "input",
    },
    example: "Jackson",
  },
  {
    label: "Email",
    key: "email",
    alternateMatches: ["email", "customer email"],
    fieldType: {
      type: "input",
    },
    example: "sj@email.com",
  },
  {
    label: "Phone",
    key: "phone",
    alternateMatches: ["phone number", "phone #", "telephone", "mobile", "mobile phone", "number"],
    fieldType: {
      type: "input",
    },
    example: "(123) 456-7891",
  },

  {
    label: "Created At",
    key: "createdAt",
    alternateMatches: ["created", "added date", "date added", "date", "date created"],
    fieldType: {
      type: "input",
    },
    example: "12/31/2021",
  },
  {
    label: "Service Street 1",
    key: "serviceStreet1",
    alternateMatches: ["Service Address 1", "address 1", "address", "street"],
    fieldType: {
      type: "input",
    },
    example: "123 Arroyo Lane",
  },
  {
    label: "Service Street 2",
    key: "serviceStreet2",
    alternateMatches: [
      "service Street 2",
      "service Address 2",
      "service unit",
      "service apartment",
      "address 2",
    ],
    fieldType: {
      type: "input",
    },
    example: "Unit 21",
  },
  {
    label: "Service City",
    key: "serviceCity",
    alternateMatches: ["service city", "city"],
    fieldType: {
      type: "input",
    },
    example: "Albuquerque",
  },
  {
    label: "Service State",
    key: "serviceState",
    alternateMatches: ["service state", "state"],
    fieldType: {
      type: "input",
    },
    example: "New Mexico",
  },
  {
    label: "Service Zip",
    key: "serviceZip",
    alternateMatches: ["service zip code", "zip", "zip code"],
    fieldType: {
      type: "input",
    },
    example: "87104",
  },
  {
    label: "Billing Street 1",
    key: "billingStreet1",
    alternateMatches: [
      "Billing Address 1",
      "Billing Street 1",
      // "Address",
      // "Address 1",
      // "Street name",
    ],
    fieldType: {
      type: "input",
    },
    example: "123 Arroyo Lane",
  },
  {
    label: "Billing Street 2",
    key: "billingStreet2",
    alternateMatches: [
      "Billing Street 2",
      "Billing Address 2",
      "billing unit",
      "billing apartment",
    ],
    fieldType: {
      type: "input",
    },
    example: "Unit 21",
  },
  {
    label: "Billing City",
    key: "billingCity",
    alternateMatches: ["billing city"],
    fieldType: {
      type: "input",
    },
    example: "Albuquerque",
  },
  {
    label: "Billing State",
    key: "billingState",
    alternateMatches: ["billing state"],
    fieldType: {
      type: "input",
    },
    example: "New Mexico",
  },
  {
    label: "Billing Zip",
    key: "billingZip",
    alternateMatches: ["billing zip", "billing zip code"],
    fieldType: {
      type: "input",
    },
    example: "87104",
  },

  {
    label: "Notes",
    key: "notes",
    // alternateMatches: ["phone number", "phone #", "telephone", "mobile"],
    fieldType: {
      type: "input",
    },
    example: "Lorem ipsum dolor sit amet...",
  },
];

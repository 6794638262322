export const plans = [
  {
    name: "Starter",
    monthlyPrice: "$99",
    annualPrice: "$49",

    features: [
      "1 user",
      "Customer manager",
      "Collect payments with credit card",
      "Scheduling, quoting, and invoicing",
      "Business reporting",
      "KPI Tracker and territory map",
      "Mass marketing messages",
      "Mobile app access",
      "Two way texting",
    ],
  },
  {
    name: "Growing",
    monthlyPrice: "$149",
    annualPrice: "$99",
    features: [
      "Up to 8 users",
      "Customer manager",
      "Collect payments with credit card",
      "Scheduling, quoting, and invoicing",
      "Business reporting",

      "KPI Tracker and territory map",

      "Mass marketing messages",

      "Mobile app access",
      "Two way texting",
      "Automated review requests",
    ],
  },
  {
    name: "Enterprise",
    monthlyPrice: "$199",
    annualPrice: "$169",
    features: [
      "Up to 30 users",
      "Customer manager",

      "Collect payments with credit card",
      "Scheduling, quoting, and invoicing",
      "Advanced business reporting",

      "KPI Tracker and territory map",

      "Mass marketing messages",

      "Mobile app access",
      "Two way texting",
      "Automated review requests",
    ],
  },
];

import React from "react";
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group";
import InformationIconTooltip from "components/reusable/InformationIconTooltip";

export default function AcceptSubscription({
  subscriptionDetails,
  setSubscriptionDetails,
}) {
  const handleAcceptChange = (accept) => {
    setSubscriptionDetails({
      ...subscriptionDetails,
      accept: {
        ...subscriptionDetails.accept,
        type: accept,
      },
    });
  };

  return (
    <div className=" mt-6 ">
      <h2 className="text-xl text-gray-800 mb-4 font-medium">
        Accept Subscription
      </h2>
      <div className="w-3/4 max-w-xl flex flex-col  rounded-md p-6 border border-gray-100 shadow-lg mb-6 gap-2">
        <RadioGroup
          className="w-full gap-6"
          value={subscriptionDetails?.accept?.type}
          onValueChange={handleAcceptChange}
        >
          <div className="flex space-x-2 items-start">
            <RadioGroupItem value={"link"} className="mt-1" />
            <div className="ml-2 flex flex-col w-full">
              <h3 className="font-medium">Send to customer to accept</h3>
              <p className="mt-1 text-sm">
                Once you send this subscription, your customer will be able to
                accept it and enter their payment information to start.
              </p>
            </div>
          </div>
          <div className="flex space-x-2 items-start">
            <RadioGroupItem
              value={"business"}
              disabled={subscriptionDetails?.template?.requireSignature}
              className="mt-1"
            />
            <div className="ml-2 flex flex-col">
              <div className="flex flex-row items-center">
                <h3 className="font-medium mr-2">Accept for customer</h3>
                <InformationIconTooltip
                  text="This is only an option if a signature is not required for this subscription."
                  className="ml-3"
                />
              </div>
              <p className="mt-1 text-sm">
                Enter payment information and accept this subscription on behalf
                of your customer.
              </p>
            </div>
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}

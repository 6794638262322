import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useContext, useState, useEffect, useMemo } from "react";
import { UserContext } from "../../context/UserContext";
import { FaPlus } from "react-icons/fa";
import { NewLineItemModal } from "../../components/NewLineItemModal/NewLineItemModal";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import useSubscription from "../../hooks/useSubscription";
import { doc } from "firebase/firestore";
import { db } from "utils/firebase";
import { EmptyLineItemsView } from "./components/EmptyLineItemsView";
import { LoadingView } from "./components/LoadingView";
import IconButton from "@mui/material/IconButton";
import { Edit } from "@mui/icons-material";
import { EditCategoryModal } from "../../components/CategoryModal/EditCategoryModal";
import { BackLink } from "../../components/NewButtons/BackLink";
import { OutlineButton } from "../../components/NewButtons/OutlineButton";

export const Category = () => {
  const { userData, bannerVisible } = useContext(UserContext);
  const { categoryId } = useParams();
  let { state } = useLocation();
  const navigate = useNavigate();

  const [newLineItemModalOpen, setNewLineItemModalOpen] = useState(false);
  const [selectedLineItem, setSelectedLineItem] = useState(null);
  const [editCategoryModalOpen, setEditCategoryModalOpen] = useState(false);

  useEffect(() => {
    if (!userData.bizData.priceBookCategories?.find((category) => category.id === categoryId)) {
      navigate("/settings/customizations/price-book");
    }
  }, []);

  const query = useMemo(
    () => doc(db, "businesses", userData.userData.businessId, "price-book", categoryId),
    [userData.userData.businessId, categoryId]
  );

  const { data: lineItems, loading, error } = useSubscription(query);

  const categoryName = userData.bizData.priceBookCategories.find(
    (category) => category.id === categoryId
  )?.name;

  return (
    <>
      <div
        className={`bg-gray-100 ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        }  overflow-y-auto pb-36`}
      >
        <div className="flex items-center justify-center">
          <div className="flex flex-col w-11/12 max-w-3xl mt-6 mb-4">
            <div className="my-2 -ml-5">
              <BackLink toLink={"/settings/customizations/price-book"} toName={"Price Book"} />
            </div>
            {/* <Link
              className="flex flex-row text-gray-400 hover:text-gray-500 my-2 -ml-5 w-fit  items-center"
              to="/settings/customizations/price-book"
            >
              <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
              <p className="text-sm font-medium">Price Book</p>
            </Link> */}
            <div className="flex justify-between items-center mb-6">
              <div className="flex flex-row items-center gap-4">
                <h1 className="text-3xl font-extrabold text-gray-900 ">{categoryName}</h1>
                <div className="mb-1">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setEditCategoryModalOpen(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </div>
              </div>
              <OutlineButton
                text="New Line Item"
                onClick={() => setNewLineItemModalOpen(true)}
                icon={<FaPlus className=" h-3 w-3" />}
              />

              {/* <button
                type="button"
                className="rounded-md bg-white px-3.5 py-2.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex flex-row items-center justify-center"
                onClick={() => {
                  setNewLineItemModalOpen(true);
                }}
              >
                <FaPlus className="inline-block mr-2 h-3 w-3" />
                <p>New Line Item</p>
              </button> */}
            </div>
            <div>
              {loading ? (
                <LoadingView />
              ) : lineItems && lineItems.lineItemIds.length > 0 ? (
                <div className=" flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-48"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 flex-1 "
                              >
                                Description
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-32"
                              >
                                Unit Price
                              </th>
                              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 w-16">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {lineItems.lineItemIds.map((lineItemId) => {
                              const lineItem = lineItems[lineItemId];
                              return (
                                <tr key={lineItemId}>
                                  <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-48">
                                    {lineItem.name}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500 flex-1 ">
                                    {lineItem.description ? lineItem.description : "-"}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500 w-32">
                                    {lineItem.unitPrice ? "$" : "-"}
                                    {parseFloat(lineItem.unitPrice).toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </td>
                                  <td className="relative  py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-16">
                                    <button
                                      className="text-yellow-500 hover:text-yellow-600"
                                      onClick={() => {
                                        setSelectedLineItem(lineItem);
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyLineItemsView setNewLineItemModal={setNewLineItemModalOpen} />
              )}
            </div>
          </div>
        </div>
      </div>
      {editCategoryModalOpen && (
        <EditCategoryModal
          open={editCategoryModalOpen}
          setOpen={setEditCategoryModalOpen}
          categoryId={categoryId}
          categoryName={categoryName}
        />
      )}
      {newLineItemModalOpen && (
        <NewLineItemModal
          open={newLineItemModalOpen}
          setOpen={setNewLineItemModalOpen}
          preselectedCategory={{ id: categoryId, name: categoryName }}
        />
      )}
      {selectedLineItem && (
        <NewLineItemModal
          open={!!selectedLineItem}
          setOpen={setSelectedLineItem}
          preselectedCategory={{ id: categoryId, name: categoryName }}
          lineItem={selectedLineItem}
          isUpdate
        />
      )}
    </>
  );
};

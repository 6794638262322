import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoCloseSharp } from "react-icons/io5";
import { CiMoneyCheck1 } from "react-icons/ci";
import { BiLoader } from "react-icons/bi";
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import { node } from "../../../constants/constants";
import {
  getDoc,
  doc,
  updateDoc,
  serverTimestamp,
  arrayUnion,
} from "firebase/firestore";
import { format, set } from "date-fns";
import { db } from "../../../utils/firebase";
import PhoneInputManualPayment from "./PhoneInputManualPayment";
import { FaToggleOff } from "react-icons/fa";
import { FaToggleOn } from "react-icons/fa";
import Toggle from "components/reusable/Toggle";
import RemovePaymentMethodModal from "components/payments/RemovePaymentMethodModal";
import AddOrReplacePaymentMethodModal from "components/payments/AddOrReplacePaymentMethodModal";
import ExitModalButton from "components/buttons/ExitModalButton";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSnackBar } from "context/SnackBarContext";
import DisplayCard from "components/payments/DisplayCard";
import { createStripeCustomer } from "utils/stripeConnect";
import { calcTotalsLineItems, convertToCents } from "utils/calcTotals";
import NormalButton from "components/buttons/NormalButton";

export default function ManualPaymentModal({
  toggleModal,
  jobDetails,
  setJobDetails,
  userData,
  jobRef,
}) {
  const { openSnackBar } = useSnackBar();
  const [stripePromise, setStripePromise] = useState(null);
  // console.log("stripe promise", stripePromise);
  // loads stripe promise on userData change
  useEffect(() => {
    if (!userData) return;
    if (!userData?.bizData?.stripeAccountId) return;
    setStripePromise(
      loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: userData?.bizData?.stripeAccountId,
      })
    );
  }, [userData]);
  // Modal states
  const [removePaymentMethodModal, setRemovePaymentMethodModal] =
    useState(false);
  const [replacePaymentMethodModal, setReplacePaymentMethodModal] =
    useState(false);

  const [shouldSavePaymentMethod, setShouldSavePaymentMethod] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [tipAmount, setTipAmount] = useState(0);

  // Managing tab selection
  const [selectedTab, setSelectedTab] = useState("card");
  const [otherOption, setOtherOption] = useState("");
  const [paymentNote, setPaymentNote] = useState("");

  // Receipt details
  const [emailForReceipt, setEmailForReceipt] = useState(
    jobDetails?.customer?.email?.[0] || ""
  );
  const [phoneForReceipt, setPhoneForReceipt] = useState(
    jobDetails?.customer?.phone?.mobile || ""
  );
  const [shouldSendTextReceipt, setShouldSendTextReceipt] = useState(false);
  const [shouldSendEmailReceipt, setShouldSendEmailReceipt] = useState(true);

  // console.log("tipAmount", tipAmount);

  const {
    totalWithoutTax,
    tax,
    total,
    totalWithoutTaxCents,
    taxCents,
    totalCents,
  } = calcTotalsLineItems(jobDetails?.lineItems); // returns numbers for all values

  const paymentAmount = total; // this is a float --> so make sure to do .toFixed(2) when displaying it --> keeping old variable name for simplicity
  const tipAmountInCents = convertToCents(tipAmount); // this is dynamically entered so that is why we have to generate it with our function --> this is now truncating it to the nearest cent --> we can change this across the board when we update the function

  const taxInCents = taxCents; // already generated.. keeping old variable name for simplicity
  const amountInCents = totalCents; // these are all truncated to cents for time being

  // const jobTotal = parseFloat(jobDetails?.jobTotal);
  // let tax = 0;
  // if (jobDetails?.lineItems?.length > 0) {
  //   jobDetails?.lineItems.forEach((item) => {
  //     if (item?.taxRate) {
  //       let total = parseFloat(item?.unitPrice) * parseFloat(item?.quantity);
  //       let itemTax = total * (parseFloat(item?.taxRate.rate) / 100);
  //       tax += itemTax;
  //     }
  //   });
  // }

  // tax = parseFloat(parseFloat(tax).toFixed(2));

  // const paymentAmount = parseFloat(
  //   (parseFloat(jobTotal) + parseFloat(tax)).toFixed(2)
  // ); // we were setting paymentAmount to totalWithTax

  // It's better to be storing the payment method directly on the customerDoc in firestore...
  // This way is probably too slow...
  // const fetchPaymentMethods = async () => {
  //   const response = await fetch(
  //     `${node}/intents/retrieve-all-payment-methods`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         stripeCustomerId: jobDetails?.customer?.stripeCustomerId,
  //         stripeAccountId: userData.bizData.stripeAccountId,
  //       }),
  //     }
  //   );
  //   if (!response.ok) {
  //     console.error("Error fetching payment methods", response);
  //     return;
  //   }
  //   const { paymentMethods } = await response.json();
  //   console.log("paymentMethods", paymentMethods);
  //   if (paymentMethods.length > 0) {
  //     setSavedPaymentMethod(paymentMethods[0]);
  //   }
  // };
  // useEffect(() => {
  //   if (!jobDetails?.customer?.stripeCustomerId) return;

  //   fetchPaymentMethods();
  // }, [jobDetails?.customer?.stripeCustomerId]);

  const handlePayWithCardOnFile = async () => {
    // console.log("handlePayWithCardOnFile");
    // data validation
    if (
      !jobDetails?.customer?.stripePaymentMethod?.id ||
      !jobDetails.customer.stripeCustomerId
    ) {
      setErrorMessage("Invalid Payment Method or Customer ID");
      return;
    }
    if (!jobDetails || isProcessing) return;

    try {
      setIsProcessing(true);

      const response = await fetch(
        `${node}/intents/create-confirm-payment-intent-with-payment-method`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stripePaymentMethodId: jobDetails.customer.stripePaymentMethod.id,
            stripeAccountId: userData.bizData.stripeAccountId,
            stripeCustomerId: jobDetails.customer.stripeCustomerId,
            amount: amountInCents,
            tip: tipAmountInCents,
            email: emailForReceipt || jobDetails?.customer?.email?.[0] || null, // we always want to send email when we are doing these payments
            description: jobDetails?.lineItems?.[0]?.name,
            // invoiceId: jobDetails?.invoiceId || null,
            jobId: jobDetails?.jobId,
            businessId: userData.bizData.id,
            currency: userData.bizData?.currency,
            tax: taxInCents,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(
          errorData.message || "There was an error creating the payment intent."
        );
        setIsProcessing(false);
        console.error("Error during payment intent creation", errorData);
        return;
      }

      const { paymentIntent } = await response.json();

      // console.log("paymentIntent", paymentIntent);

      // check if paymentIntent was created and confirmed successfully
      if (!paymentIntent || !paymentIntent.status || !paymentIntent.amount) {
        throw new Error("Payment intent is missing necessary fields");
      }

      if (paymentIntent.status !== "succeeded") {
        throw new Error("Payment intent status is not succeeded");
      }

      // Update job details state and doc
      await updateDoc(jobRef, {
        paymentHistory: arrayUnion({
          status: paymentIntent.status,
          billingType: "card_on_file",
          date: new Date(),
          jobId: jobDetails?.jobId,
          paymentMethod: paymentIntent.payment_method_types,
          paymentMethodId: paymentIntent.payment_method,
          captureMethod: paymentIntent.capture_method,
          totalAmountFromStripe: paymentIntent.amount,
          paymentIntentId: paymentIntent.id,
          tip: tipAmountInCents,
          tax: taxInCents,
        }),
        status: paymentIntent.status === "succeeded" ? "paid" : "due",
        datePaid: serverTimestamp(),
      });

      setJobDetails((prev) => ({
        ...prev,
        paymentHistory: [
          ...(prev.paymentHistory ? prev.paymentHistory : []),
          {
            status: paymentIntent.status,
            billingType: "card_on_file",
            date: new Date(),
            jobId: jobDetails?.jobId,
            paymentMethod: paymentIntent.payment_method_types,
            paymentMethodId: paymentIntent.payment_method,
            captureMethod: paymentIntent.capture_method,
            totalAmountFromStripe: paymentIntent.amount,
            paymentIntentId: paymentIntent.id,
            tip: tipAmountInCents,
            tax: taxInCents,
          },
        ],
        status: paymentIntent.status === "succeeded" ? "paid" : "due",
        datePaid: new Date(),
      }));

      // again we need to openSnackbar here
      openSnackBar("Payment processed successfully", true);
      setIsProcessing(false);
      sendReceiptsInBackground();
      toggleModal();
    } catch (error) {
      console.error("Error processing payment:", error);
      setIsProcessing(false);
      setErrorMessage("Failed to process payment. Please try again.");
    }
  };

  async function handleSubmit(e, stripe, elements) {
    e.preventDefault();

    if (!userData.bizData.stripe_charges_enabled) {
      alert(
        "Please finish setting up homebase payments before processing payments."
      );
      return;
    }
    if (!cardComplete) {
      setErrorMessage("Please fill out the card information.");
      return;
    }
    setErrorMessage(null);
    if (!stripe || !elements || !jobDetails || isProcessing) return;
    setIsProcessing(true);

    const stripeAccountId = userData.bizData.stripeAccountId;
    // Create stripe customer if it doesn't exist
    let stripeCustomerId = jobDetails?.customer?.stripeCustomerId || null;
    try {
      if (!stripeCustomerId && stripeAccountId) {
        const customerData = jobDetails.customer;
        // backend function updates the customer doc already
        const result = await createStripeCustomer({
          customerData,
          stripeAccountId,
        });

        if (result.error) {
          throw new Error(result.error);
        }

        const stripeCustomerId = result.stripeCustomerId;

        // update job doc with stripeCustomerId -- customer doc is already updated on the backend
        await updateDoc(jobRef, {
          "customer.stripeCustomerId": stripeCustomerId,
        });

        // update jobDetails in state
        setJobDetails((prev) => ({
          ...prev,
          customer: {
            ...prev.customer,
            stripeCustomerId: stripeCustomerId,
          },
        }));
      }
    } catch (error) {
      console.error("Error creating customer", error);
      setErrorMessage(
        error.message || "There was an error creating the customer."
      );
      setIsProcessing(false);
      return;
    }

    // Create payment intent
    let paymentIntentCreated;
    try {
      const paymentIntentResponse = await fetch(
        `${node}/intents/create-payment-intent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: amountInCents, // cents
            stripeAccountId: stripeAccountId,
            stripeCustomerId: stripeCustomerId,
            shouldSavePaymentMethod: shouldSavePaymentMethod,
            tip: tipAmountInCents,
            email: emailForReceipt,
            description: jobDetails?.lineItems?.[0]?.name,
            invoiceId: jobDetails?.invoiceId || null,
            jobId: jobDetails?.jobId,
            businessId: userData.bizData.id,
            currency: userData.bizData?.currency,
            tax: taxInCents, // cents
          }),
        }
      );
      if (!paymentIntentResponse.ok) {
        const errorData = await paymentIntentResponse.json();
        setErrorMessage(
          errorData.message || "There was an error creating the payment intent."
        );
        setIsProcessing(false);
        console.error("Error during payment intent creation", errorData);
        return;
      }
      const { paymentIntent } = await paymentIntentResponse.json();

      paymentIntentCreated = paymentIntent;
    } catch (error) {
      setErrorMessage("There was a problem creating the payment intent.");
      setIsProcessing(false);
      console.error("Error during payment intent creation:", error);
      return;
    }

    // create card element
    const cardElement = elements.getElement(CardElement);

    let paymentIntentConfirmed;
    try {
      // confirm payment intent
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        paymentIntentCreated.client_secret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );

      if (error) {
        console.error("error is here", error);
        throw error;
      }
      // If there's no error, assign the result to paymentIntentConfirmed
      paymentIntentConfirmed = paymentIntent;

      // Check if the payment intent has necessary fields (feel like this check is redundant maybe?)
      if (
        !paymentIntentConfirmed ||
        !paymentIntentConfirmed.status ||
        !paymentIntentConfirmed.amount
      ) {
        throw new Error("Payment intent is missing necessary fields");
      }

      if (!paymentIntentConfirmed.status === "succeeded") {
        throw new Error(
          `Payment intent status is not succeeded, here is the status: ${paymentIntentConfirmed.status}`
        );
      }

      // Now we fetch and save the payment method with the firestore customer doc
      let stripePaymentMethod = null;
      if (shouldSavePaymentMethod && paymentIntentConfirmed.payment_method) {
        try {
          const paymentMethodResponse = await fetch(
            `${node}/intents/retrieve-payment-method`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                paymentMethodId: paymentIntentConfirmed.payment_method,
                stripeAccountId: stripeAccountId,
              }),
            }
          );

          if (!paymentMethodResponse.ok) {
            console.error(
              "Error fetching payment method",
              paymentMethodResponse
            );
            throw new Error("Error fetching payment method");
          }

          const { paymentMethod } = await paymentMethodResponse.json();
          stripePaymentMethod = paymentMethod;

          const customerRef = doc(
            db,
            "businesses",
            userData.bizData.id,
            "customers",
            jobDetails.customer.customerId
          );
          await updateDoc(customerRef, {
            stripePaymentMethod: paymentMethod,
          });
        } catch (error) {
          console.error(
            "Error updating customer doc with payment method",
            error
          );
          // we should probably provide an alert here to the customer but we don't want to stop the rest of the progress...
        }
      }

      // Updating the state and database is complicated... lot's on conditionals that are necessary
      // Initialize the update object with payment history and status
      let jobUpdate = {
        paymentHistory: arrayUnion({
          status: paymentIntentConfirmed.status,
          billingType: "manual card",
          date: new Date(),
          jobId: jobDetails?.jobId,
          paymentMethod: paymentIntentConfirmed.payment_method_types,
          paymentMethodId: paymentIntentConfirmed.payment_method,
          captureMethod: paymentIntentConfirmed.capture_method,
          totalAmountFromStripe: paymentIntentConfirmed.amount,
          paymentIntentId: paymentIntentConfirmed.id,
          tip: tipAmountInCents,
          tax: taxInCents,
        }),
        status: paymentIntentConfirmed.status === "succeeded" ? "paid" : "due",
        datePaid: serverTimestamp(),
      };

      // Conditionally add the paymentMethod to the job document if shouldSavePaymentMethod is true
      // stripePaymentMethod will only be truthy if shouldSavePaymentMethod is true and we were able to fetch the payment method from Stripe
      if (stripePaymentMethod) {
        jobUpdate["customer.stripePaymentMethod"] = stripePaymentMethod;
      }

      // Perform the job document update with the conditionally constructed jobUpdate object
      await updateDoc(jobRef, jobUpdate);

      // Prepare the payment history object for local state update
      const paymentHistoryObject = {
        status: paymentIntentConfirmed.status,
        billingType: "manual card",
        date: new Date(),
        jobId: jobDetails?.jobId,
        paymentMethod: paymentIntentConfirmed.payment_method_types,
        paymentMethodId: paymentIntentConfirmed.payment_method,
        captureMethod: paymentIntentConfirmed.capture_method,
        totalAmountFromStripe: paymentIntentConfirmed.amount,
        paymentIntentId: paymentIntentConfirmed.id,
        tip: tipAmountInCents,
        tax: taxInCents,
      };

      // Update the local jobDetails state
      setJobDetails((prev) => {
        // Start building the update with current state and payment history
        let newState = {
          ...prev,
          paymentHistory: [
            ...(prev.paymentHistory || []),
            paymentHistoryObject,
          ],
          status:
            paymentIntentConfirmed.status === "succeeded" ? "paid" : "due",
          datePaid: new Date(), // Mirroring serverTimestamp() as accurately as possible
        };

        // Conditionally add the payment method to local state if applicable
        if (stripePaymentMethod) {
          newState = {
            ...newState, // Carry over newState modifications
            customer: {
              ...prev.customer,
              stripePaymentMethod: stripePaymentMethod, // Here we're updating the state to match the document
            },
          };
        }

        return newState; // Return the newly constructed state
      });

      // await updateDoc(jobRef, {
      //   paymentHistory: arrayUnion({
      //     status: paymentIntentConfirmed.status,
      //     billingType: "manual card",
      //     date: new Date(),
      //     jobId: jobDetails?.jobId,
      //     paymentMethod: paymentIntentConfirmed.payment_method_types,
      //     captureMethod: paymentIntentConfirmed.capture_method,
      //     totalAmountFromStripe: paymentIntentConfirmed.amount,
      //     paymentIntentId: paymentIntentConfirmed.id,
      //     tip: 0,
      //     tax: tax * 100,
      //   }),
      //   status: paymentIntentConfirmed.status === "succeeded" ? "paid" : "due",
      //   datePaid: serverTimestamp(),
      // });

      // setJobDetails((prev) => ({
      //   ...prev,
      //   paymentHistory: [
      //     ...(prev.paymentHistory ? prev.paymentHistory : []),
      //     {
      //       status: paymentIntentConfirmed.status,
      //       billingType: "manual card",
      //       date: new Date(),
      //       jobId: jobDetails?.jobId,
      //       paymentMethod: paymentIntentConfirmed.payment_method_types,
      //       captureMethod: paymentIntentConfirmed.capture_method,
      //       totalAmountFromStripe: paymentIntentConfirmed.amount,
      //       paymentIntentId: paymentIntentConfirmed.id,
      //       tip: 0,
      //       tax: tax * 100,
      //     },
      //   ],
      //   status: paymentIntentConfirmed.status === "succeeded" ? "paid" : "due",
      //   datePaid: new Date(),
      // }));

      openSnackBar("Payment processed successfully", true);
      setIsProcessing(false);
      toggleModal();
      sendReceiptsInBackground();
      return;
    } catch (error) {
      console.error("Error during payment intent confirmation:", error);
      setErrorMessage(
        error.message || "There was an error confirming the payment intent."
      );
      setIsProcessing(false);

      try {
        await updateDoc(jobRef, {
          paymentHistory: arrayUnion({
            status: error.code,
            billingType: "manual card",
            date: new Date(),
            type: error.type,
            jobId: jobDetails?.jobId,
            totalAmountFromStripe: 0,
            tip: 0,
            tax: 0,
          }),
          status: error.code,
          datePaymentFailed: serverTimestamp(),
        });

        setJobDetails((prev) => ({
          ...prev,
          status: error.code,
          datePaymentFailed: new Date(),
          paymentHistory: [
            ...(prev.paymentHistory ? prev.paymentHistory : []),
            {
              status: error.code,
              billingType: "manual card",
              date: new Date(),
              type: error.type,
              jobId: jobDetails?.jobId,
              totalAmountFromStripe: 0,
              tip: 0,
              tax: 0,
            },
          ],
        }));
      } catch (dbError) {
        console.error(
          "Failed to update the database after a payment error:",
          dbError
        );
      }
    }
    setIsProcessing(false);
  }

  // this is for handling non credit card payments --> would be nice if we could import all the constituent functions from a seperate file so we could make this file smaller
  const handlePaid = async () => {
    setIsProcessing(true);

    try {
      await updateJobDetailsInDatabase();
      updateJobDetailsInState();
      sendReceiptsInBackground();
    } catch (error) {
      console.error("Error updating job details in database:", error);
      alert("There was an error processing your payment. Please try again.");
    } finally {
      openSnackBar("Payment processed successfully", true);
      setIsProcessing(false);
      toggleModal();
    }
  };

  const updateJobDetailsInDatabase = async () => {
    try {
      await updateDoc(jobRef, {
        paymentHistory: arrayUnion({
          status: "paid",
          billingType: selectedTab,
          date: new Date(),
          jobId: jobDetails?.jobId,
          paymentNote: paymentNote,
          otherOption: otherOption,
          totalAmountFromStripe: parseInt(amountInCents + tipAmountInCents),
          tip: tipAmountInCents, // always want everything in the payment history in cents for consistency
          tax: taxInCents,
        }),
        status: "paid",
        datePaid: new Date(),
      });
    } catch (error) {
      console.error("Error updating job details in database:", error);
      throw error;
    }
  };

  const updateJobDetailsInState = () => {
    setJobDetails((prev) => ({
      ...prev,
      paymentHistory: [
        ...(prev.paymentHistory ? prev.paymentHistory : []),
        {
          status: "paid",
          billingType: selectedTab,
          date: new Date(),
          jobId: jobDetails?.jobId,
          paymentNote: paymentNote,
          otherOption: otherOption,
          totalAmountFromStripe: parseInt(amountInCents + tipAmountInCents),
          tip: tipAmountInCents,
          tax: taxInCents,
        },
      ],
      status: "paid",
      datePaid: new Date(),
    }));
  };

  const sendTextReceipt = async (receiptDetails) => {
    console.log("sending text receipt");
    // console.log("receipt details", receiptDetails);
    try {
      const response = await fetch(`${node}/sendReceipt/send-text-receipt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(receiptDetails),
      });

      if (!response.ok) {
        throw new Error("Error sending text receipt");
      }

      const { message, error } = await response.json();
      if (error) {
        throw new Error(error);
      }

      // console.log("Message from backend for text receipt ==>", message);
      return true;
    } catch (error) {
      console.error("Error sending receipt", error);
      alert(
        "There was an error sending the receipt via text. Please check the phone number and try again."
      );
      return false;
    }
  };

  const sendEmailReceipt = async (receiptDetails) => {
    console.log("sending email receipt");
    // console.log("receipt details", receiptDetails);
    try {
      const response = await fetch(`${node}/sendReceipt/send-receipt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(receiptDetails),
      });

      if (!response.ok) {
        throw new Error("Error sending email receipt");
      }

      const { message, error } = await response.json();
      if (error) {
        throw new Error(error);
      }

      // console.log("Message from backend for email receipt ==>", message);
      return true;
    } catch (error) {
      console.error("Error sending receipt", error);
      alert(
        "Receipt was not sent via email. Please check the email address and try again."
      );
      return false;
    }
  };

  const sendReceiptsInBackground = async () => {
    const receiptDetails = {
      paymentAmount: paymentAmount,
      businessData: userData.bizData,
      jobData: jobDetails,
    };

    const promises = [];

    if (phoneForReceipt && shouldSendTextReceipt) {
      receiptDetails.phoneForReceipt = phoneForReceipt;
      promises.push(sendTextReceipt(receiptDetails));
    }

    if (emailForReceipt && shouldSendEmailReceipt) {
      receiptDetails.emailForReceipt = emailForReceipt;
      promises.push(sendEmailReceipt(receiptDetails));
    }

    const results = await Promise.allSettled(promises);

    results.forEach((result, index) => {
      if (result.status === "rejected") {
        const receiptType = index === 0 ? "text" : "email";
        console.error(`Error sending ${receiptType} receipt:`, result.reason);
        alert(
          `There was an error sending the ${receiptType} receipt. Please check the ${
            receiptType === "text" ? "phone number" : "email"
          } and try again.`
        );
      }
    });
  };

  // for toggling other modals
  const toggleRemovePaymentMethodModal = () => {
    setRemovePaymentMethodModal(!removePaymentMethodModal);
  };
  const toggleReplacePaymentMethodModal = () => {
    setReplacePaymentMethodModal(!replacePaymentMethodModal);
  };

  const handleToggle = (e) => {
    // Prevent default form submission behavior
    e.preventDefault();

    // Toggle the state
    setShouldSavePaymentMethod(!shouldSavePaymentMethod);
  };

  return (
    <>
      <CustomModalWrapper>
        <Elements stripe={stripePromise}>
          <Header toggleModal={toggleModal} />
          <Inputs
            paymentAmount={paymentAmount}
            tipAmount={tipAmount}
            setTipAmount={setTipAmount}
            shouldSendEmailReceipt={shouldSendEmailReceipt}
            setShouldSendEmailReceipt={setShouldSendEmailReceipt}
            emailForReceipt={emailForReceipt}
            setEmailForReceipt={setEmailForReceipt}
            shouldSendTextReceipt={shouldSendTextReceipt}
            setShouldSendTextReceipt={setShouldSendTextReceipt}
            phoneForReceipt={phoneForReceipt}
            setPhoneForReceipt={setPhoneForReceipt}
          />

          <SelectionButtons
            setSelectedTab={setSelectedTab}
            setOtherOption={setOtherOption}
            selectedTab={selectedTab}
            setPaymentNote={setPaymentNote}
          />
          {selectedTab === "card" && (
            <PaymentForm
              cardComplete={cardComplete}
              setCardComplete={setCardComplete}
              errorMessage={errorMessage}
              handleSubmit={handleSubmit}
              isProcessing={isProcessing}
              handleToggle={handleToggle}
              shouldSavePaymentMethod={shouldSavePaymentMethod}
              savedPaymentMethod={
                jobDetails?.customer?.stripePaymentMethod || null
              }
              handlePayWithCardOnFile={handlePayWithCardOnFile}
              toggleRemovePaymentMethodModal={toggleRemovePaymentMethodModal}
              toggleReplacePaymentMethodModal={toggleReplacePaymentMethodModal}
            />
          )}
          {selectedTab === "cash" && (
            <RenderCashCheckOrEtransferTab
              setPaymentNote={setPaymentNote}
              paymentNote={paymentNote}
              handlePaid={handlePaid}
              isProcessing={isProcessing}
            />
          )}
          {selectedTab === "check" && (
            <RenderCashCheckOrEtransferTab
              setPaymentNote={setPaymentNote}
              paymentNote={paymentNote}
              handlePaid={handlePaid}
              isProcessing={isProcessing}
            />
          )}
          {selectedTab === "e-transfer" && (
            <RenderCashCheckOrEtransferTab
              setPaymentNote={setPaymentNote}
              paymentNote={paymentNote}
              handlePaid={handlePaid}
              isProcessing={isProcessing}
            />
          )}

          {selectedTab === "other" && (
            <RenderOtherTab
              setPaymentNote={setPaymentNote}
              paymentNote={paymentNote}
              handlePaid={handlePaid}
              isProcessing={isProcessing}
              otherOption={otherOption}
              setOtherOption={setOtherOption}
            />
          )}
        </Elements>
      </CustomModalWrapper>

      {/* Modals for removing and replacing saved payment method */}
      <RemovePaymentMethodModal
        open={removePaymentMethodModal}
        onClose={() => setRemovePaymentMethodModal(false)}
        jobDetails={jobDetails}
        setJobDetails={setJobDetails}
      />

      <AddOrReplacePaymentMethodModal
        open={replacePaymentMethodModal}
        onClose={() => setReplacePaymentMethodModal(false)}
        jobDetails={jobDetails}
        setJobDetails={setJobDetails}
      />
    </>
  );
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////// COMPONENTS//////////////////////////////////////////////////////////////////////////////

const PaymentForm = ({
  handleSubmit,
  setCardComplete,
  isProcessing,
  cardComplete,
  handleToggle,
  errorMessage,
  shouldSavePaymentMethod,
  savedPaymentMethod,
  handlePayWithCardOnFile = () => {},
  toggleReplacePaymentMethodModal = () => {},
  toggleRemovePaymentMethodModal = () => {},
}) => {
  const [useSavedPaymentMethod, setUseSavedPaymentMethod] = useState(true);

  if (savedPaymentMethod) {
    let brand = savedPaymentMethod?.card?.brand || "";
    let formattedBrand =
      brand.charAt(0).toUpperCase() + brand.slice(1).toLowerCase();

    return (
      <div>
        <div>
          <div className="flex flex-col ml-4 mt-4 mb-6 gap-2">
            <div>
              <input
                disabled={isProcessing}
                type="radio"
                id="use-saved-payment-method"
                name="use-saved-payment-method"
                // value="warrant-work"
                checked={useSavedPaymentMethod}
                onChange={() => setUseSavedPaymentMethod(true)}
              />
              <label
                htmlFor="use-saved-payment-method"
                className="ml-2 text-gray-600"
              >
                Card on file
              </label>
              {useSavedPaymentMethod && (
                <div className="flex flex-row items-center gap-2 ml-4 p-2">
                  <DisplayCard
                    savedPaymentMethod={savedPaymentMethod}
                    className="font-bold"
                  />
                  {/* <div className="font-bold">{formattedBrand}</div>
                  <div className="font-bold">
                    ••••{savedPaymentMethod.card.last4}
                  </div>
                  <div className="font-bold">
                    Expires {savedPaymentMethod.card.exp_month}/
                    {savedPaymentMethod.card.exp_year}
                  </div> */}
                  <div className="flex flex-row ml-6">
                    <button
                      onClick={toggleReplacePaymentMethodModal}
                      className="text-yellow-500 text-sm p-2 hover:bg-gray-50 rounded-md"
                    >
                      Replace
                    </button>
                    <button
                      onClick={toggleRemovePaymentMethodModal}
                      className="text-yellow-500 text-sm p-2 hover:bg-gray-50 rounded-md"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div>
              <input
                disabled={isProcessing}
                type="radio"
                id="use-other-card"
                name="use-other-card"
                value="use-other-card"
                checked={!useSavedPaymentMethod}
                onChange={() => setUseSavedPaymentMethod(false)}
              />
              <label htmlFor="use-other-card" className="ml-2 text-gray-600">
                Another Card
              </label>
            </div>
          </div>

          {useSavedPaymentMethod ? (
            <div>
              {errorMessage && (
                <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
              )}
              {/* <button
                disabled={isProcessing}
                className={`bg-gray-900 w-1/4 ml-2 gap-2 h-10 items-center flex justify-center rounded-md ${
                  isProcessing
                    ? "text-yellow-100"
                    : "text-white hover:shadow-lg hover:text-yellow-100"
                } font-bold shadow-sm`}
                onClick={handlePayWithCardOnFile}
              >
                {isProcessing ? (
                  <BiLoader className="animate-spin-slow text-xl text-white" />
                ) : (
                  "Charge Card"
                )}
              </button> */}
              <NormalButton
                text="Charge Card"
                width="w-1/4"
                additionalClasses="ml-2"
                loading={isProcessing}
                onClick={handlePayWithCardOnFile}
              />
            </div>
          ) : (
            <NormalStripeCardForm
              handleSubmit={handleSubmit}
              setCardComplete={setCardComplete}
              isProcessing={isProcessing}
              cardComplete={cardComplete}
              handleToggle={handleToggle}
              errorMessage={errorMessage}
              shouldSavePaymentMethod={shouldSavePaymentMethod}
              shouldShowSavePaymentMethodOption={false}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <NormalStripeCardForm
        handleSubmit={handleSubmit}
        setCardComplete={setCardComplete}
        isProcessing={isProcessing}
        cardComplete={cardComplete}
        handleToggle={handleToggle}
        errorMessage={errorMessage}
        shouldSavePaymentMethod={shouldSavePaymentMethod}
      />
    );
  }
};

const NormalStripeCardForm = ({
  handleSubmit,
  setCardComplete,
  isProcessing,
  cardComplete,
  handleToggle,
  errorMessage,
  shouldSavePaymentMethod,
  shouldShowSavePaymentMethodOption = true,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const options = {
    style: {
      base: {
        iconColor: "#0f172a",
        color: "#1e293b",
        fontWeight: "400",
        fontFamily: "Inter Var, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#1e293b",
        },
        "::placeholder": {
          color: "#94a3b8",
        },
      },
      invalid: {
        iconColor: "#ef4444",
        color: "#ef4444",
      },
    },
    disableLink: true,
  };
  return (
    <form onSubmit={(e) => handleSubmit(e, stripe, elements)}>
      <CardElement
        className="p-4  shadow-md ring-1 ring-gray-500 rounded-md my-5 mb-7 mx-2 "
        options={options}
        onChange={(e) => {
          setCardComplete(e.complete);
        }}
      />
      {errorMessage && (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      )}
      <div className="flex flex-row justify-between mr-2">
        {/* <button
          disabled={isProcessing || !stripe || !cardComplete}
          className={`bg-gray-900 w-1/4 ml-2 gap-2 h-10 items-center flex justify-center rounded-md ${
            isProcessing
              ? "text-yellow-100"
              : "text-white hover:shadow-lg hover:text-yellow-100"
          } font-bold shadow-sm`}
          type="submit"
        >
          {isProcessing ? (
            <BiLoader className="animate-spin-slow text-xl text-white" />
          ) : (
            "Charge Card"
          )}
        </button> */}

        <NormalButton
          text="Charge Card"
          width="w-1/4"
          additionalClasses="ml-2"
          loading={isProcessing}
          disabled={!stripe || !cardComplete || isProcessing}
        />

        {shouldShowSavePaymentMethodOption && (
          <div className="flex flex-col items-center justify-center">
            <label className="text-gray-500 text-sm">
              Save payment method on file
            </label>
            <Toggle
              value={shouldSavePaymentMethod}
              handleToggle={(e) => handleToggle(e)}
            />
          </div>
        )}
      </div>
    </form>
  );
};

const SelectionButtons = ({
  setSelectedTab,
  setPaymentNote,
  setOtherOption,
  selectedTab,
}) => {
  return (
    <div className="flex flex-row gap-3 mx-2 ">
      <button
        className={`border border-gray-300  rounded-md p-3 ${
          selectedTab === "card"
            ? "shadow-lg border-gray-800 text-gray-800"
            : " text-gray-300"
        }`}
        onClick={() => {
          setSelectedTab("card");
          setPaymentNote("");
          setOtherOption("");
        }}
      >
        Card
      </button>
      <button
        className={`border border-gray-300  rounded-md p-3 ${
          selectedTab === "cash"
            ? "shadow-lg border-gray-800 text-gray-800"
            : "text-gray-300"
        }`}
        onClick={() => {
          setSelectedTab("cash");
          setPaymentNote("");
          setOtherOption("");
        }}
      >
        Cash
      </button>
      <button
        className={`border border-gray-300 rounded-md p-3 ${
          selectedTab === "check"
            ? "shadow-lg border-gray-800 text-gray-800"
            : "text-gray-300 "
        }`}
        onClick={() => {
          setSelectedTab("check");
          setPaymentNote("");
          setOtherOption("");
        }}
      >
        Check
      </button>
      <button
        className={`border border-gray-300  rounded-md p-3 ${
          selectedTab === "e-transfer"
            ? "shadow-lg border-gray-800 text-gray-800"
            : "text-gray-300"
        }`}
        onClick={() => {
          setSelectedTab("e-transfer");
          setPaymentNote("");
          setOtherOption("");
        }}
      >
        E-transfer
      </button>
      <button
        className={`border border-gray-300  rounded-md p-3 ${
          selectedTab === "other"
            ? "shadow-lg border-gray-800 text-gray-800"
            : "text-gray-300"
        }`}
        onClick={() => {
          setSelectedTab("other");
          setPaymentNote("");
        }}
      >
        Other
      </button>
    </div>
  );
};

const Inputs = ({
  paymentAmount,
  tipAmount,
  setTipAmount,
  shouldSendEmailReceipt,
  setShouldSendEmailReceipt,
  emailForReceipt,
  setEmailForReceipt,
  shouldSendTextReceipt,
  setShouldSendTextReceipt,
  phoneForReceipt,
  setPhoneForReceipt,
}) => {
  return (
    <div className="flex flex-row gap-3 mx-2 mb-5 ">
      <div className="flex flex-col w-1/2">
        <label className="text-gray-500 text-sm ml-2 mt-1">Amount</label>

        <input
          disabled={true}
          type="number"
          className="number-input border border-gray-300 rounded-md p-3 w-1/2"
          placeholder="Amount"
          min="0"
          step="0.01"
          onKeyDown={(event) => {
            if (event.key === "-") {
              event.preventDefault();
            }
          }}
          value={paymentAmount.toFixed(2)}
          onChange={(e) => console.log(e.target.value)}
        />
        <label className="text-gray-500 text-sm ml-2 mt-2">Tip</label>

        <input
          type="number"
          className="number-input border border-gray-300 rounded-md p-3 w-1/2 focus:outline-none"
          placeholder="Amount"
          min="0"
          step="0.01"
          onKeyDown={(event) => {
            // if (event.key === "-") {
            //   event.preventDefault();
            // }
            // Prevent non-numeric and minus key from being entered, except necessary control characters
            if (["-", "+", "e", "E"].includes(event.key)) {
              event.preventDefault();
            }
          }}
          value={tipAmount}
          onChange={(e) => {
            const value = e.target.value;
            // Regex to check if the value matches the specified number format (two decimal places)
            if (/^\d*\.?\d{0,2}$/.test(value)) {
              setTipAmount(value);
            }
          }}
        />
      </div>
      <div className="flex flex-col w-1/2">
        <div className="flex flex-row items-center">
          <label className="text-gray-500 text-sm ml-2">
            Email for receipt
          </label>
          {shouldSendEmailReceipt ? (
            <button onClick={() => setShouldSendEmailReceipt(false)}>
              <FaToggleOn className="text-green-400 text-lg ml-2 mt-2" />
            </button>
          ) : (
            <button onClick={() => setShouldSendEmailReceipt(true)}>
              <FaToggleOff className="text-gray-500 text-lg ml-2 mt-2" />
            </button>
          )}
        </div>

        <input
          type="text"
          className="border border-gray-300 rounded-md p-3 focus:outline-none"
          placeholder="Email"
          value={emailForReceipt}
          onChange={(e) => setEmailForReceipt(e.target.value)}
        />
        <div className="flex flex-row items-center">
          <label className="text-gray-500 text-sm ml-2 mt-2">
            Phone for receipt
          </label>
          {shouldSendTextReceipt ? (
            <button onClick={() => setShouldSendTextReceipt(false)}>
              <FaToggleOn className="text-green-400 text-lg ml-2 mt-2" />
            </button>
          ) : (
            <button onClick={() => setShouldSendTextReceipt(true)}>
              <FaToggleOff className="text-gray-500 text-lg ml-2 mt-2" />
            </button>
          )}
        </div>
        <PhoneInputManualPayment
          label="Phone for receipt"
          value={phoneForReceipt}
          setValue={setPhoneForReceipt}
        />
      </div>
    </div>
  );
};

const CustomModalWrapper = ({ children }) => {
  return (
    <div className="modal">
      <div className="overlay"></div>
      <div className="note-modal-content ">
        <div className="flex flex-col  flex-1 lg:col-span-2 bg-white rounded-md shadow-md  gap-2 mb-8 pb-8 p-4 relative">
          {children}
        </div>
      </div>
    </div>
  );
};

const Header = ({ toggleModal }) => {
  return (
    <>
      <ExitModalButton handleClose={toggleModal} position="top-3 right-3" />

      <div className="flex w-full flex-row items-center gap-2 mt-0 mb-2 ml-2">
        <CiMoneyCheck1 className="inline-block text-3xl" />
        <h1 className="text-xl font-bold">Payment</h1>
      </div>
    </>
  );
};

const RenderCashCheckOrEtransferTab = ({
  setPaymentNote,
  paymentNote,
  handlePaid,
  isProcessing,
}) => {
  return (
    <>
      <input
        type="text"
        className="px-4 shadow-md ring-1 ring-gray-500 rounded-md mt-5 mb-5  mx-2 focus:outline-none"
        style={{
          height: "52px",
        }}
        placeholder="Payment note"
        value={paymentNote}
        onChange={(e) => setPaymentNote(e.target.value)}
      />
      {/* <button
        disabled={isProcessing}
        className={`bg-gray-900 w-1/4 ml-2 gap-2 h-10 items-center flex justify-center rounded-md ${
          isProcessing
            ? "text-yellow-100"
            : "text-white hover:shadow-lg hover:text-yellow-100"
        } font-bold shadow-sm`}
        onClick={handlePaid}
      >
        {isProcessing ? (
          <BiLoader className="animate-spin-slow text-xl text-white" />
        ) : (
          "Paid"
        )}
      </button> */}
      <NormalButton
        text="Paid"
        width="w-1/4"
        additionalClasses="ml-2"
        loading={isProcessing}
        disabled={isProcessing}
        onClick={handlePaid}
      />
    </>
  );
};

const RenderOtherTab = ({
  setPaymentNote,
  paymentNote,
  handlePaid,
  isProcessing,
  setOtherOption,
  otherOption,
}) => {
  return (
    <div className="flex flex-col mx-3 mt-5 gap-3">
      <div>
        <input
          type="radio"
          id="warrant-work"
          name="payment-option"
          value="warrant-work"
          checked={otherOption === "warrant-work"}
          onChange={(e) => setOtherOption(e.target.value)}
        />
        <label htmlFor="warrant-work" className="ml-2 text-gray-600">
          Warranty Work
        </label>
      </div>
      <div>
        <input
          type="radio"
          id="homeowner-financing"
          name="payment-option"
          value="homeowner-financing"
          checked={otherOption === "homeowner-financing"}
          onChange={(e) => setOtherOption(e.target.value)}
        />
        <label htmlFor="homeowner-financing" className="ml-2 text-gray-600">
          Homeowner Financing
        </label>
      </div>
      <div>
        <input
          type="radio"
          id="alternative-payment"
          name="payment-option"
          value="alternative-payment"
          checked={otherOption === "alternative-payment"}
          onChange={(e) => setOtherOption(e.target.value)}
        />
        <label className="ml-2 text-gray-600" htmlFor="alternative-payment">
          Alternative Payment
        </label>
      </div>
      <input
        type="text"
        className="px-4 shadow-md ring-1 ring-gray-500 rounded-md mt-5 mb-5 w-full  mr-5 "
        style={{
          height: "52px",
        }}
        placeholder="Payment note"
        value={paymentNote}
        onChange={(e) => setPaymentNote(e.target.value)}
      />
      {/* <button
        disabled={isProcessing}
        className={`bg-gray-900 w-1/4 ml-2 gap-2 h-10 items-center flex justify-center rounded-md ${
          isProcessing
            ? "text-yellow-100"
            : "text-white hover:shadow-lg hover:text-yellow-100"
        } font-bold shadow-sm`}
        onClick={
          otherOption ? handlePaid : () => console.log("no option selected")
        }
      >
        {isProcessing ? (
          <BiLoader className="animate-spin-slow text-xl text-white" />
        ) : (
          "Paid"
        )}
      </button> */}
      <NormalButton
        text="Paid"
        width="w-1/4"
        loading={isProcessing}
        disabled={isProcessing || !otherOption}
        onClick={
          otherOption ? handlePaid : () => console.log("no option selected")
        }
      />
    </div>
  );
};

import React, { useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { IoFilter } from "react-icons/io5";
import Tooltip from "@mui/material/Tooltip";

const FilterMenu = ({ onFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentFilter, setCurrentFilter] = useState("all");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
    onFilterChange(filter);
    handleClose();
  };

  return (
    <div className="mx-2">
      <Tooltip title="Filter Conversations" arrow>
        <button
          onClick={handleClick}
          className="text-gray-600 hover:text-gray-800 font-bold px-1 rounded-md flex flex-row h-12 items-center"
        >
          <IoFilter className="text-xl" />
        </button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => handleFilterChange("all")}
          disableRipple
          sx={{
            fontFamily: "Inter var, system-ui, sans-serif",
            fontSize: "0.875rem",
            backgroundColor: currentFilter === "all" ? "#fefce8" : "inherit",
            "&:hover": {
              backgroundColor: "#f3f4f6",
            },
          }}
        >
          All Conversations
        </MenuItem>
        <MenuItem
          onClick={() => handleFilterChange("unread")}
          disableRipple
          sx={{
            fontFamily: "Inter var, system-ui, sans-serif",
            fontSize: "0.875rem",
            backgroundColor: currentFilter === "unread" ? "#fefce8" : "inherit",
            "&:hover": {
              backgroundColor: "#f3f4f6",
            },
          }}
        >
          Unread
        </MenuItem>
      </Menu>
    </div>
  );
};

export default FilterMenu;

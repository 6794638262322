import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { db } from "utils/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useUserContext } from "context/UserContext";

export default function TagsInput({ tags, setTags }) {
  const { userData } = useUserContext();

  const [options, setOptions] = useState(["Lead", "Customer", "Sale"]);

  useEffect(() => {
    const fetchTags = async () => {
      const tagsDoc = doc(
        db,
        "businesses",
        userData?.bizData?.id,
        "tags",
        "tags"
      );
      const tagsSnapshot = await getDoc(tagsDoc);
      // check if the tags document exists
      if (!tagsSnapshot.exists()) {
        return;
      }
      const tagsList = tagsSnapshot.data()?.tags;
      setOptions(tagsList);
    };

    fetchTags();
  }, []);

  const handleTagsChange = (event, values) => {
    setTags(values);
    // Additional logic to store new tags to Firestore
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={options}
      value={tags}
      onChange={handleTagsChange}
      sx={{
        "& .MuiOutlinedInput-root": {
          backgroundColor: "#f9fafb", // switched to gray-50
          color: "#111827",
          "&:hover": {
            backgroundColor: "#f9fafb",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fef08a",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fef08a",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fef08a",
          }, // I also want it to when we hover over the input field when it isn't focused but there is input in there it doesn't switch to black.. I want it to still be yellow
        },
        // "& .MuiAutocomplete-listbox": { // doesn't work..
        //   "&& .Mui-selected": {
        //     backgroundColor: "#fef3c7",
        //   },
        //   "&& .Mui-selected:hover": {
        //     backgroundColor: "#e5e7eb",
        //   },
        // },
        "& .MuiAutocomplete-tag": {
          backgroundColor: "#fef08a", // Custom background color for selected tag chips
          color: "#111827", // Text color for selected tag chips
          "&:hover": {
            backgroundColor: "#fde68a", // Background color on hover for selected tag chips
          },
        },
        // "& .MuiAutocomplete-listbox": { // doesn't work
        //   "& .MuiAutocomplete-option": {
        //     "&.Mui-selected": {
        //       backgroundColor: "#fef3c7",
        //     },
        //     "&.Mui-selected:hover": {
        //       backgroundColor: "#e5e7eb",
        //     },
        //     "&:hover": {
        //       backgroundColor: "#f3f4f6",
        //     },
        //   },
        // },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Tags"
          placeholder="Add Tags"
          InputLabelProps={{
            sx: {
              color: "#6b7280", // Label color
              "&.Mui-focused": {
                color: "#1f2937", // Label color when focused
              },
            },
          }}
        />
      )}
    />
  );
}

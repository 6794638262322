import React from "react";
import CancelButton from "components/buttons/CancelButton";
import DisabledButton from "components/buttons/DisabledButton";

export default function Footer({
  onClose,
  disabled,
  onCreate,
  loading,
  buttonText = "Create Subscription",
}) {
  return (
    <div className="absolute bottom-0 w-full h-16 flex flex-row items-center justify-between border-t border-gray-300 ">
      <CancelButton text="Cancel" handleCancel={onClose} className="ml-6" />
      <DisabledButton
        text={buttonText}
        additionalClasses="mr-6"
        width="w-40"
        disabled={disabled}
        onClick={onCreate}
        loading={loading}
      />
    </div>
  );
}

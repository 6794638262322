import React, { useState, useEffect } from "react";
import { generateVisits } from "utils/helpers";
import { ChevronDown } from "lucide-react";
import EditVisitModal from "./EditVisitModal";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function IncludedVisits({
  subscriptionDetails,
  setSubscriptionDetails,
}) {
  const [expandAll, setExpandAll] = useState(false);
  const [editVisitModal, setEditVisitModal] = useState(false);
  const [editVisit, setEditVisit] = useState(null);

  useEffect(() => {
    const newVisits = generateVisits({ subscriptionDetails });
    setSubscriptionDetails({ ...subscriptionDetails, visits: newVisits });
  }, [
    subscriptionDetails?.startDate,
    subscriptionDetails?.template?.type,
    subscriptionDetails?.template?.visits,
    subscriptionDetails?.template?.duration,
  ]);

  const toggleVisibility = () => {
    setExpandAll(!expandAll); // Toggle expanded state
  };

  const visibleVisits = expandAll
    ? subscriptionDetails?.visits
    : subscriptionDetails?.visits?.slice(0, 4);

  const handleEditVisit = (visit) => {
    setEditVisit(visit);
    setEditVisitModal(true);
  };

  return (
    <div className=" mt-6">
      <h2 className="text-xl text-gray-800 mb-4 font-medium">
        Included Visits
      </h2>

      <div className="md:w-3/4 md:max-w-xl w-full rounded-md p-2 border shadow-lg border-gray-100 mb-6 ">
        <table className="w-full text-sm px-2">
          <thead className="border-b border-gray-200">
            <tr>
              <th className="px-6 py-3 text-left font-medium text-gray-500">
                Visit Name
              </th>
              <th className="px-6 py-3 text-left font-medium text-gray-500 ">
                Date
              </th>
              <th className="px-6 py-3 text-left font-medium text-gray-500 ">
                Status
              </th>
              <th className="px-3 py-3 text-left font-medium text-gray-500 "></th>
            </tr>
          </thead>
          <tbody className=" divide-y divide-gray-200 ">
            {visibleVisits?.length > 0 ? (
              visibleVisits?.map((visit) => (
                <tr
                  key={visit.id}
                  className={`transition-opacity duration-1000 ease-in-out ${
                    expandAll ? "" : ""
                  }`}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {visit.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {monthNames[visit.month]} {visit.year}
                  </td>
                  <td className="pl-6 pr-2 py-4 whitespace-nowrap text-sm text-gray-500">
                    {visit.status.slice(0, 1).toUpperCase() +
                      visit.status.slice(1)}
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button
                      className="text-yellow-400 hover:text-yellow-500"
                      onClick={() => handleEditVisit(visit)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  No visits to display
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {subscriptionDetails?.visits?.length > 0 && (
          <div className="w-full flex justify-center ">
            <button
              onClick={toggleVisibility}
              className="mt-4 px-4 py-2 flex flex-row items-center   text-sm  text-gray-400 hover:text-gray-800 font-medium transition duration-200"
            >
              {expandAll ? "Show Less" : "Show More"}{" "}
              <ChevronDown
                className={`h-4 w-4 ml-2 transition-transform duration-200 ${
                  expandAll ? "rotate-180" : "rotate-0"
                }`}
              />
            </button>
          </div>
        )}
      </div>

      <EditVisitModal
        open={editVisitModal}
        onClose={() => {
          setEditVisitModal(false);
          setEditVisit(null);
        }}
        visit={editVisit}
        setVisit={setEditVisit}
        subscriptionDetails={subscriptionDetails}
        setSubscriptionDetails={setSubscriptionDetails}
      />
    </div>
  );
}

export function validateAttachments(
  attachments,
  maxSizeInBytes = 5 * 1024 * 1024,
  maxTotalSizeInBytes = 20 * 1024 * 1024
) {
  if (!attachments || attachments.length === 0) return true;

  console.log("attachments", attachments);

  let totalSize = 0;
  const oversizedFiles = [];

  for (const attachment of attachments) {
    if (attachment.url) continue; // this means it's already been uploaded to firestore
    if (attachment.file.size > maxSizeInBytes) {
      oversizedFiles.push({
        name: attachment.name,
        size: (attachment.file.size / 1024 / 1024).toFixed(2),
      });
    }
    totalSize += attachment.file.size;
    console.log("totalSize", totalSize);
  }

  if (oversizedFiles.length > 0) {
    const fileList = oversizedFiles
      .map((file) => `${file.name} (${file.size}MB)`)
      .join(", ");
    throw new Error(
      `The following files exceed the ${
        maxSizeInBytes / 1024 / 1024
      }MB limit: ${fileList}`
    );
  }

  if (totalSize > maxTotalSizeInBytes) {
    throw new Error(
      `Total attachment size (${(totalSize / 1024 / 1024).toFixed(
        2
      )}MB) exceeds the limit of ${maxTotalSizeInBytes / 1024 / 1024}MB.`
    );
  }

  return true;
}

import React, { useEffect, useState } from "react";
import { node } from "../../constants/constants";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import InvoiceCard from "../../components/payments/InvoiceCard";
import { calcTotalsLineItems, convertToCents } from "utils/calcTotals";

export default function Invoice() {
  const [stripePromise, setStripePromise] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [invoice, setInvoice] = useState(null);
  const [amount, setAmount] = useState(null); // will be in cents
  const [tax, setTax] = useState(0); // will be in cents
  const [amountInDollars, setAmountInDollars] = useState(null); // will be in dollars [for display purposes only
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [invoiceLoadingError, setInvoiceLoadingError] = useState(false);
  const [waitMessage, setWaitMessage] = useState(false);
  const [customer, setCustomer] = useState(null);

  // old and insecure
  // useEffect(() => {
  //   // get the query params from url
  //   if (!params.get("uid") || !params.get("invoiceId")) {
  //     console.log("no uid or invoice in the query params ");
  //     return;
  //   }

  //   const bizId = params.get("uid");
  //   const invoiceId = params.get("invoiceId");

  //   // console.log("bizId", bizId)
  //   // console.log("invoiceId", invoiceId)

  //   // get the user data from the database
  //   const getInvoiceData = async () => {
  //     // get get invoice doc from db
  //     const invoiceDoc = await getDoc(
  //       doc(db, "businesses", bizId, "invoices", invoiceId)
  //     );
  //     const invoiceData = invoiceDoc.data();
  //     setInvoice(invoiceData);
  //     setAmount(invoiceData.amount * 100);
  //     setTax(invoiceData.tax ? invoiceData.tax * 100 : 0);
  //     setStripePromise(
  //       loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
  //         stripeAccount: invoiceData.stripeAccountId,
  //       })
  //     );
  //   };
  //   getInvoiceData();
  // }, [refresh]);

  useEffect(() => {
    // get the query params from url
    if (!params.get("uid") || !params.get("invoiceId")) {
      console.log("no uid or invoice in the query params ");
      setInvoiceLoadingError("noInvoice");
      return;
    }
    const bizId = params.get("uid");
    const invoiceId = params.get("invoiceId");

    // Fetch the invoice data from the backend
    const getInvoiceData = async () => {
      try {
        const response = await fetch(`${node}/invoices/read`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            businessId: bizId,
            invoiceId: invoiceId,
          }),
        });

        const data = await response.json();

        // console.log("data", data);

        // we need at a minimum the invoice and lineItems to render the invoice
        if (data.invoice && data.invoice.lineItems) {
          const totals = calcTotalsLineItems(data.invoice.lineItems);
          setAmount(totals.totalCents);
          setTax(totals.taxCents);

          setAmountInDollars(totals.total || data.invoice.amount); // each of these is a number and we need to do toFixed(2)

          // before we were taking the amount from the invoice (that included tax), and then we were taking tax as well. Now we are using lineItems as the source of truth for the amount and tax... I think this is the correct way to do it... and it should be more accurate, and then when we implement this everywhere we should be able to convert to rounding instead of truncating...
          // order matters here I believe
          // setAmount(data.invoice.amount * 100); // put everything in cents
          // setTax(data.invoice.tax ? data.invoice.tax * 100 : 0); // put everything in cents

          // then we set the stripe promise if it already hasn't been set
          if (!stripePromise) {
            setStripePromise(
              loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
                stripeAccount: data.invoice.stripeAccountId,
              })
            );
          }
          // then we set the invoice
          setInvoice(data.invoice);
        } else {
          setInvoiceLoadingError("noInvoice");
          // console.error("Error fetching invoice:", data.message);
        }

        if (data.customer) {
          setCustomer(data.customer);
        }
      } catch (error) {
        setInvoiceLoadingError("noInvoice");
        // console.error("Error fetching invoice:", error);
      }
    };

    getInvoiceData();
  }, [refresh]);

  useEffect(() => {
    if (!invoice) return;
    //check if the invoice has been paid
    // Eventually we can not only check if it's been paid, but if it's been paid in full, if not then we can allow for another payment to be made to pay the rest of the invoice
    const checkIfPaid = async () => {
      if (invoice.status === "succeeded" && invoice.invoicePaid === true) {
        // console.log("invoice has been paid");
        setPaymentIntent(invoice.paymentIntent);
        setPaymentMethod(invoice.paymentMethod);
        // console.log("paymetn intent:", invoice.paymentIntent)
      }
    };
    checkIfPaid();
  }, [invoice, refresh]);

  // useEffect that runs on mount that will count so that we can display a message after a few seconds to let the user know that we are still loading
  useEffect(() => {
    // after 3000 ms if the invoice is still null, but we do not have a loading error, then display the wait message
    const timer = setTimeout(() => {
      if (!invoice && !invoiceLoadingError) {
        setWaitMessage(true);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  // render loading skeleton while we wait for the invoice to load
  if (!invoice || !stripePromise)
    return (
      <div
        className={`flex flex-col bg-slate-100/90 min-h-screen h-full items-center font-stripe ${
          invoiceLoadingError ? "" : "animate-pulse"
        } `}
      >
        <div className="flex flex-col mt-16 w-96 sm:w-[420px]">
          <h1 className="font-semibold text-2xl text-slate-600 pl-2 bg-slate-300/50 h-8 w-1/2 rounded-md"></h1>
          <div className="flex flex-col bg-slate-50 w-full rounded-lg shadow-2xl mt-5 relative py-6">
            {!invoiceLoadingError ? (
              <>
                <div className="absolute top-9 right-7 text-sm text-slate-600">
                  <div className="w-16 h-20 border-2 rounded-md shadow-lg bg-slate-200"></div>
                </div>
                <h1 className="text-slate-700 text-3xl font-bold pt-8 pl-6 bg-slate-200 h-10 w-1/3 rounded-md ml-6"></h1>
                <h1 className="text-slate-500 text-sm  bg-slate-200/50 h-3 w-1/4 rounded-md mt-1 ml-8"></h1>
                <h1 className="  text-sm text-slate-700 flex flex-row bg-slate-200 h-3 w-1/2 rounded-md ml-6 mt-6"></h1>
                <h1 className="  text-sm text-slate-700 flex flex-row bg-slate-200 h-3 w-1/2 rounded-md ml-6 mt-2"></h1>
                <h1 className=" text-sm text-slate-700 flex flex-row bg-slate-200 h-3 w-1/2 rounded-md ml-6 mt-2"></h1>
              </>
            ) : (
              <div className="text-slate-800 justify-center px-6">
                <h1>
                  There was an error retrieving your invoice. Please refresh the page to try again.
                  If this issue persists please contact the company sending the invoice.
                </h1>
              </div>
            )}
            <div className="pl-7">
              <hr className="text-slate-900 mt-5 mb-5 ml-16" />
            </div>
            <div className="pl-7">
              <h1
                className="text-slate-500 text-sm font-medium mb-8 ml-16 flex flex-row items-center hover:text-slate-800 cursor-pointer
               bg-slate-200 h-6 w-3/5 rounded-md "
              ></h1>
            </div>
            {waitMessage && !invoiceLoadingError && (
              <div className="text-slate-800 absolute bottom-5 w-full flex flex-row justify-center">
                <h1>Still loading please wait...</h1>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col bg-slate-50 shadow-2xl mt-8 mb-36 relative w-96 sm:w-[420px] h-64 rounded-md p-6">
          <div className="w-full flex flex-col items-center">
            <h1 className=" text-sm text-slate-700 bg-slate-200 h-6 w-1/4 rounded-md"></h1>
            <div className=" w-full flex flex-row justify-between mt-4 gap-1">
              <h1 className=" text-sm text-slate-700 flex flex-row flex-grow bg-slate-200 h-10 rounded-md"></h1>
              <h1 className=" text-sm text-slate-700 flex flex-row flex-grow bg-slate-200 h-10 rounded-md"></h1>
              <h1 className=" text-sm text-slate-700 flex flex-row flex-grow bg-slate-200 h-10 rounded-md"></h1>
              <h1 className=" text-sm text-slate-700 flex flex-row flex-grow bg-slate-200 h-10 rounded-md"></h1>
              <h1 className=" text-sm text-slate-700 flex flex-row flex-grow bg-slate-200 h-10 rounded-md"></h1>
            </div>
            <h1 className=" text-sm text-slate-700 flex flex-row bg-slate-200 h-10 w-full rounded-md mt-6"></h1>
          </div>

          <h1 className=" text-sm text-slate-700 flex flex-row bg-slate-200 h-10 w-1/4 rounded-md mt-6"></h1>
        </div>
      </div>
    );
  return (
    <>
      <Elements stripe={stripePromise}>
        <InvoiceCard
          invoice={invoice}
          setInvoice={setInvoice}
          amount={amount} // in cents
          setAmount={setAmount}
          amountInDollars={amountInDollars} // in dollars --> number
          paymentIntent={paymentIntent}
          setPaymentIntent={setPaymentIntent}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          setRefresh={setRefresh}
          tax={tax} // in cents
          customer={customer}
          setCustomer={setCustomer}
        />
      </Elements>
    </>
  );
}

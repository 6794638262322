// import React from "react";

// export default function TerritoryMap() {
//   return <div>TerritoryMap</div>;
// }

import React, { useContext, useState, useCallback, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { UserContext } from "../index";
import { db } from "../utils/firebase";
import {
  collection,
  getDocs,
  query,
  limit,
  orderBy,
  startAfter,
} from "firebase/firestore";

export default function TerritoryMap() {
  const { userData, bannerVisible } = useContext(UserContext);
  const [geocodedCustomers, setGeocodedCustomers] = useState([]);
  const [map, setMap] = useState(null);

  const containerStyle = {
    width: "100%",
    height: bannerVisible ? "calc(100vh - 66px - 56px)" : "calc(100vh - 65px )",
  };

  let center = {
    lat: 37.78825,
    lng: -96.4324,
  };
  if (
    userData?.userData?.location?.latitude &&
    userData?.userData?.location?.longitude
  ) {
    center = {
      lat: userData?.userData?.location?.latitude,
      lng: userData?.userData?.location?.longitude,
    };
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  const onLoad = useCallback((map) => {
    // console.log("center", center);
    // Determine a distance to adjust the bounds
    const distance = 2; // Adjust this value as needed
    // Create the southwest and northeast points of the bounds
    const sw = {
      lat: center.lat - distance,
      lng: center.lng - distance,
    };
    const ne = {
      lat: center.lat + distance,
      lng: center.lng + distance,
    };
    const bounds = new window.google.maps.LatLngBounds(sw, ne);
    map.fitBounds(bounds);
    setMap(map);
    // map.setCenter(ne, sw);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  // State to manage which tooltip is currently open
  const [openTooltip, setOpenTooltip] = useState(null);

  const fetchCustomersRecursive = async (
    lastDoc = null,
    accumulatedCustomers = []
  ) => {
    console.log("fetchCustomersRecursive called");
    let customersCollectionRef = collection(
      db,
      "businesses",
      userData?.userData?.businessId,
      "customers"
    );
    let customerQuery = query(
      customersCollectionRef,
      orderBy("dateAdded"),
      startAfter(lastDoc),
      limit(1000)
    );

    const customersSnapshot = await getDocs(customerQuery);
    const customers = customersSnapshot.docs.map((doc) => doc.data());
    if (customersSnapshot.empty || customers.length === 0) {
      console.log("no more documents in customers collection");
      return accumulatedCustomers;
    } else {
      console.log(`${customers.length} customers fetched`);
      accumulatedCustomers.push(...customers);
      // Since we haven't reached the end of the collection, fetch the next page
      const newLastDoc =
        customersSnapshot.docs[customersSnapshot.docs.length - 1];
      return await fetchCustomersRecursive(newLastDoc, accumulatedCustomers);
    }
  };

  // Fetch all customers on mount and process each geocoded address
  useEffect(() => {
    (async function () {
      // fetch all customers for the business (done recursively 500 customers at a time)

      const customers = await fetchCustomersRecursive();

      console.log("customers length after fetching all", customers.length);

      let geocodedCustomersWithId = [];

      // process the customers data so that we will have a pin for each address
      // (sometimes customers will have multiple geocodedObjects in their geocodedAddress field).
      for (const customer of customers) {
        if (
          customer.geocodedAddresses &&
          customer.geocodedAddresses.length > 0
        ) {
          customer.geocodedAddresses.forEach((geocodedAddress, index) => {
            // Create a unique ID by combining the customer's ID with the index of the geocodedAddress object
            const uniqueId = `${customer.customerId}_${index}`;

            // Create a new object with the required information
            const geocodedCustomerWithId = {
              id: uniqueId,
              name: customer?.displayName || "", // Assuming 'name' is the field name for customer's name
              address: geocodedAddress.address, // Using the address from the geocodedAddress object
              phoneNumber: customer?.phone?.mobile || "", // Assuming 'phoneNumber' is the field name for customer's phone number
              email: customer?.email?.[0],
              lastJobDate: customer?.lastJob
                ? new Date(customer.lastJob?.seconds * 1000)
                : null,
              createdAt: new Date(customer.dateAdded?.seconds * 1000),
              lat: geocodedAddress.lat,
              lng: geocodedAddress.lng,
            };

            // Add the new object to the geocodedCustomersWithId array
            geocodedCustomersWithId.push(geocodedCustomerWithId);
          });
        }
      }

      //   console.log("geocodedCustomersWithId", geocodedCustomersWithId);

      setGeocodedCustomers(geocodedCustomersWithId);
    })();
  }, []);

  //   console.log("openToolTip: ", openTooltip);
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={2}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {geocodedCustomers?.map((marker) => (
        <Marker
          key={marker.id}
          position={{ lat: marker.lat, lng: marker.lng }}
          onClick={() => {
            setOpenTooltip(null);
            setTimeout(() => setOpenTooltip(marker.id), 100);
          }}
        >
          {openTooltip === marker.id && (
            <InfoWindow onCloseClick={() => setOpenTooltip(null)}>
              <div>
                {marker?.name && (
                  <div className="flex flex-row items-center">
                    <p className="mr-2 font-bold">Name: </p>
                    <h4>{marker.name}</h4>
                  </div>
                )}
                <div className="flex flex-row items-center">
                  <p className="mr-2 font-bold">Address: </p>
                  <p>{marker.address}</p>
                </div>
                {marker?.phoneNumber && (
                  <div className="flex flex-row items-center">
                    <p className="mr-2 font-bold">Phone: </p>
                    <p>{marker.phoneNumber}</p>
                  </div>
                )}
                {marker?.email && (
                  <div className="flex flex-row items-center">
                    <p className="mr-2 font-bold">Email: </p>
                    <p>{marker.email}</p>
                  </div>
                )}
                {marker.lastJobDate && (
                  <div className="flex flex-row items-center">
                    <p className="mr-2 font-bold">Last Job: </p>
                    <p>{marker.lastJobDate?.toLocaleDateString()}</p>
                  </div>
                )}
                <div className="flex flex-row items-center">
                  <p className="mr-2 font-bold">Created at: </p>
                  <p>{marker.createdAt.toLocaleDateString()}</p>
                </div>
              </div>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  ) : (
    <></>
  );
}

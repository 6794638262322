import React from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordion";
import CustomFileInput from "components/reusable/CustomFileInput";
import FileInputShad from "./FileInputShad";
import { CgAttachment } from "react-icons/cg";
// import { PiTagSimple } from "react-icons/pi";
import TagsInput from "./TagsInput";

export default function AttachmentsAccordion({
  attachments,
  setAttachments,
  tags,
  setTags,
}) {
  const handleFileChange = (e) => {
    // console.log(e.target.files);
    const files = Array.from(e.target.files);

    const newAttachments = files.map((file, index) => {
      return {
        file: file,
        name: file.name,
      };
    });
    // Update the state with new and existing attachments
    setAttachments([...attachments, ...newAttachments]);
  };

  const removeAttachment = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  // console.log("attachments: ", attachments);
  return (
    <Accordion type="multiple" collapsible="true" className="w-full">
      <AccordionItem value="item-1">
        <AccordionTrigger>
          <div className="flex flex-row gap-2 items-center">
            <CgAttachment className="text-gray-600 text-2xl" />
            Attachments
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="py-5">
            <CustomFileInput
              handleFileChange={handleFileChange}
              attachments={attachments}
              removeAttachment={removeAttachment}
            />
          </div>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>
          <div className="flex flex-row gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-gray-700"
              //   style={{ transform: "rotate(45deg)" }} // Rotate 90 degrees
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 6h.008v.008H6V6Z"
              />
            </svg>
            Tags
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="py-5 ">
            <TagsInput tags={tags} setTags={setTags} />
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

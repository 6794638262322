import React, { useState, useEffect } from "react";
import Dialogue from "components/modals/Dialogue";
import { node } from "constants/constants";
import { useUserContext } from "context/UserContext";
import DisabledButton from "components/buttons/DisabledButton";
import CancelButton from "components/buttons/CancelButton";
import { useSnackBar } from "context/SnackBarContext";
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

export default function ChangeServiceSubscriptionPaymentMethodModal({
  open,
  onClose,
  stripeSubscription,
  onSuccess,
}) {
  const { userData } = useUserContext();
  const [stripePromise, setStripePromise] = useState(null);
  const { openSnackBar } = useSnackBar();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setErrorMessage("");
  }, [open]);

  useEffect(() => {
    if (!userData?.bizData?.stripeAccountId) return;
    setStripePromise(
      loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: userData.bizData.stripeAccountId,
      })
    );
  }, [userData]);

  const handleChangePaymentMethod = async ({ e, stripe, elements }) => {
    e.preventDefault();
    try {
      setLoading(true);
      setErrorMessage("");

      if (!stripe || !elements) {
        throw new Error("Stripe is not initialized");
      }

      if (!stripeSubscription.id || !userData.bizData.stripeAccountId) {
        throw new Error("Invalid subscription or stripe account");
      }

      const cardElement = elements.getElement(CardElement);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        throw new Error(error.message);
      }

      const response = await fetch(
        `${node}/serviceSubscriptionsApi/updateDefaultPaymentMethod`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stripeSubscriptionId: stripeSubscription.id,
            paymentMethodId: paymentMethod.id,
            stripeAccountId: userData.bizData.stripeAccountId,
          }),
        }
      );

      const result = await response.json();
      if (!response.ok) {
        const { error } = await response.json();
        throw new Error(error || "Failed to update the payment method");
      }
      if (!result.success) {
        throw new Error("Failed to update the payment method");
      }

      openSnackBar("Payment method updated successfully", true);
      onSuccess(result.subscription);
      onClose();
    } catch (error) {
      console.error("Error updating payment method:", error);
      setErrorMessage(
        error.message ||
          "Failed to update the payment method, please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialogue open={open} onClose={onClose} width={645}>
      <h2 className="text-xl font-medium mb-4">Change Payment Method</h2>
      <Elements stripe={stripePromise}>
        <NormalStripeCardForm
          isProcessing={loading}
          handleSubmit={handleChangePaymentMethod}
          errorMessage={errorMessage}
          onClose={onClose}
        />
      </Elements>
    </Dialogue>
  );
}

const NormalStripeCardForm = ({
  handleSubmit,
  isProcessing,
  errorMessage,
  onClose,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardComplete, setCardComplete] = useState(false);
  const options = {
    style: {
      base: {
        iconColor: "#0f172a",
        color: "#1e293b",
        fontWeight: "400",
        fontFamily: "Inter Var, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#1e293b",
        },
        "::placeholder": {
          color: "#94a3b8",
        },
      },
      invalid: {
        iconColor: "#ef4444",
        color: "#ef4444",
      },
    },
    disableLink: true,
  };

  return (
    <form onSubmit={(e) => handleSubmit({ e, stripe, elements })}>
      <CardElement
        className="p-4 shadow-md ring-1 ring-gray-500 rounded-md my-5 mb-7"
        options={options}
        onChange={(e) => {
          setCardComplete(e.complete);
        }}
      />
      {errorMessage && (
        <div className="text-red-500 text-sm mb-4">{errorMessage}</div>
      )}
      <div className="flex flex-row justify-between">
        <CancelButton handleCancel={onClose} disabled={isProcessing} />
        <DisabledButton
          width="w-48"
          text="Update Card"
          loading={isProcessing}
          disabled={!stripe || !cardComplete}
        />
      </div>
    </form>
  );
};

import React, { useState } from "react";
import { FaToggleOn, FaToggleOff } from "react-icons/fa";

export default function Toggle({
  value,
  handleToggle,
  disabled = false,
  className,
  inactiveColor = "text-gray-900",
  activeColor = "text-green-400",
  size = "text-lg",
}) {
  return (
    <button
      disabled={disabled}
      className="flex flex-row items-center gap-2"
      onClick={handleToggle}
    >
      {value ? (
        <FaToggleOn className={`${activeColor} ${size}`} />
      ) : (
        <FaToggleOff className={`${inactiveColor} ${size}`} />
      )}
    </button>
  );
}

import React from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordionExtraInputCustomerModal";
import AutocompleteMUI from "features/Customers/components/AutocompleteMUI";

export default function ExtraAutocompleteAccordion({ additionalAddress, setAdditionalAddress }) {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item-1">
        <AccordionTrigger>Additional Address</AccordionTrigger>
        <AccordionContent>
          <div className="w-full py-2">
            <AutocompleteMUI
              label="Additional Address"
              address={additionalAddress}
              setAddress={setAdditionalAddress}
            />
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

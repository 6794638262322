import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "utils/firebase";

export function useFetchAndRankData() {
  const [gamesData, setGamesData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const gamesRef = doc(db, "competitionStats", "hbGames-8-15"); // hbGames-8-15
        const gamesSnapshot = await getDoc(gamesRef);
        if (gamesSnapshot.exists()) {
          //.data() this will include businessesWithRevenue and allUserMarkerStats
          const businessesWithStats = gamesSnapshot.data().businessesWithStats;
          // Sort it by total revenue
          businessesWithStats.sort((a, b) => b.totalRevenue - a.totalRevenue);
          // Add rank based on index + 1 -- so business with top rev is number 1
          businessesWithStats.forEach((business, index) => {
            business.rank = index + 1;
          });

          let allUserMarkerStats = gamesSnapshot.data().allUserMarkerStats;
          allUserMarkerStats = allUserMarkerStats.filter(
            (user) => parseInt(user.total) > 0
          );
          allUserMarkerStats.sort((a, b) => b.total - a.total);
          allUserMarkerStats.forEach((user, index) => {
            user.rank = index + 1;
          });

          setGamesData({
            businessesWithStats,
            allUserMarkerStats,
          });
        } else {
          console.log("No such document!");
          setGamesData([]); // Handle the case where no data is found
        }
      } catch (error) {
        console.error("Error fetching games data", error);
        setGamesData([]); // Handle the case where an error occurs
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return { gamesData, loadingData: loading };
}

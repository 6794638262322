import React from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { splitAddress } from "utils/helpers";
import { AiOutlineEdit } from "react-icons/ai";
import {
  UserIcon,
  UserCircleIcon,
  MapPinIcon,
  EnvelopeIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/solid";
import { Switch } from "components/ui/switch";
import GoogleMaps from "../../../components/GoogleMaps/GoogleMaps";

export default function CustomerJob({
  toggleModal,
  customer,
  toggleNotifications,
  notifications,
  textSize,
  noEdit = false,
  textColor,
  jobId,
  subscriptionId,
}) {
  const navigate = useNavigate();

  const address1Split = splitAddress(customer?.address?.[0] || "");

  const iconButtonClasses =
    "w-5 h-5 text-gray-500 hover:text-gray-900 transition-colors duration-200 ease-in-out cursor-pointer";

  const buttonClasses =
    "p-1 rounded-full hover:bg-yellow-100 transition-colors duration-200 ease-in-out outline:none";

  const handleCustomerClick = () => {
    navigate(`/customers/${customer?.customerId}`, {
      state: {
        jobId: jobId,
        subscriptionId: subscriptionId,
      },
    });
  };

  const handleMapClick = () => {
    const address = encodeURIComponent(customer?.address?.[0] || "");
    window.open(`https://www.google.com/maps/search/?api=1&query=${address}`, "_blank");
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${customer?.email?.[0]}`;
  };

  return (
    <div className="flex flex-col flex-1 rounded-md shadow-bold bg-white py-6 ">
      <div className="flex flex-row items-center justify-between mb-4 px-4">
        <div className="flex flex-row items-center">
          <UserIcon className="w-6 h-6 mr-2 text-gray-700" />
          <h1 className="text-xl font-bold text-gray-800">Customer</h1>
        </div>

        {!noEdit && (
          <Tooltip title="Edit customer details">
            <button onClick={() => toggleModal()}>
              <AiOutlineEdit className={`text-lg cursor-pointer text-gray-600 mr-1 `} />
            </button>
          </Tooltip>
        )}
      </div>
      <GoogleMaps customer={customer} isStreetViewOnly={true} />

      <div
        className={`flex flex-col px-4 mt-4 gap-2 ${textSize ? `${textSize}` : "text-lg"} ${
          textColor ? `${textColor}` : "text-gray-900"
        }`}
      >
        {customer?.displayName && (
          <div className="flex items-center justify-between">
            <h1>{`${customer?.displayName ?? ""}`}</h1>

            <Tooltip title="View customer profile">
              <button onClick={handleCustomerClick} className={buttonClasses}>
                <UserCircleIcon className={iconButtonClasses} />
              </button>
            </Tooltip>
          </div>
        )}

        {customer?.phone?.mobile && (
          <div className="flex items-center justify-between">
            <span>
              {`(${customer.phone.mobile.substring(2, 5)}) ${customer.phone.mobile.substring(
                5,
                8
              )}-${customer.phone.mobile.substring(8, 12)}`}
            </span>
            <Tooltip title="Go to messages">
              <button
                onClick={() => {
                  navigate(`/messages/${customer?.customerId}`, {
                    state: {
                      phone: customer?.phone.mobile,
                      name: customer?.displayName,
                    },
                  });
                }}
                className={buttonClasses}
              >
                <ChatBubbleBottomCenterTextIcon className={iconButtonClasses} />
              </button>
            </Tooltip>
          </div>
        )}

        {customer?.email?.[0] && (
          <div className="flex items-center justify-between">
            <h1>{customer?.email?.[0]}</h1>
            <Tooltip title="Send an email">
              <button onClick={handleEmailClick} className={buttonClasses}>
                <EnvelopeIcon className={iconButtonClasses} />
              </button>
            </Tooltip>
          </div>
        )}

        {address1Split?.[0] && (
          <div>
            <div className="flex items-center justify-between">
              <div>
                <p>{address1Split[0]}</p>
                {address1Split?.[1] && <p className="text-wrap">{address1Split[1]}</p>}
              </div>
              <Tooltip title="Open in Google Maps">
                <button onClick={handleMapClick} className={buttonClasses}>
                  <MapPinIcon className={iconButtonClasses} />
                </button>
              </Tooltip>
            </div>
          </div>
        )}
        {toggleNotifications && (
          <div className="flex flex-row mt-4 items-center">
            <Tooltip title={`Turn ${notifications ? "off" : "on"} notifications for job updates`}>
              <Switch
                checked={notifications}
                onCheckedChange={toggleNotifications}
                className="mr-2"
              />
            </Tooltip>
            <h2 className="text-lg font-medium">Notifications</h2>
          </div>
        )}
      </div>
    </div>
  );
}

// import React, { useState } from "react";
// import SearchInput from "../../AddJob/components/SearchInput";
// import { FaPlus } from "react-icons/fa";
// import { AiOutlineEdit } from "react-icons/ai";
// import { BsPersonFill } from "react-icons/bs";
// import { FaToggleOff } from "react-icons/fa";
// import { FaToggleOn } from "react-icons/fa";
// import { FaUndo } from "react-icons/fa";
// import { RiMessageLine } from "react-icons/ri";
// import { splitAddress } from "utils/helpers";
// // import {
// //   UserIcon,
// //   UserCircleIcon,
// //   IdentificationIcon,
// //   MapPinIcon,
// //   MapIcon,
// //   GlobeAltIcon,
// //   PhoneIcon,
// //   EnvelopeIcon,
// //   AtSymbolIcon,
// //   PencilIcon,
// //   PencilSquareIcon,
// //   ChatBubbleBottomCenterTextIcon,
// // } from "@heroicons/react/24/outline";
// import {
//   UserIcon,
//   UserCircleIcon,
//   IdentificationIcon,
//   MapPinIcon,
//   MapIcon,
//   GlobeAltIcon,
//   PhoneIcon,
//   EnvelopeIcon,
//   AtSymbolIcon,
//   PencilIcon,
//   PencilSquareIcon,
//   ChatBubbleBottomCenterTextIcon,
// } from "@heroicons/react/24/solid";

// export default function CustomerJob({
//   toggleModal,
//   customer,
//   toggleNotifications,
//   notifications,
//   navigate,
//   textSize,
//   noEdit = false,
//   textColor,
// }) {
//   const [inputValue, setInputValue] = useState("");

//   const address1Split = splitAddress(customer?.address?.[0] || "");
//   const address2Split = splitAddress(customer?.address?.[1] || "");

//   const iconClasses = "w-5 h-5 mr-2 text-gray-500";
//   const buttonClasses =
//     "p-1 rounded-full hover:bg-gray-100 transition-colors duration-200 ease-in-out outline:none";

//   const iconButtonClasses =
//     "w-5 h-5 text-gray-500 hover:text-gray-900  transition-colors duration-200 ease-in-out cursor-pointer";

//   return (
//     <div className="flex flex-col flex-1 rounded-md shadow-bold bg-white py-6 px-4 ">
//       <div className="flex flex-row items-start justify-between mb-4">
//         <div className="flex flex-row items-center">
//           {/* Option 1: */}
//           <UserIcon className="w-6 h-6 mr-2 text-gray-700" />
//           {/* Option 2: */}
//           {/* <UserCircleIcon className="w-6 h-6 mr-2 text-gray-700" /> */}
//           {/* Option 3: */}
//           {/* <IdentificationIcon className="w-6 h-6 mr-2 text-gray-700" /> */}
//           {/* <BsPersonFill className="text-2xl mr-2" /> */}
//           <h1 className="text-xl font-bold text-gray-800 ">Customer</h1>
//         </div>

//         {!noEdit && (
//           <>
//             {/* Option 1: */}
//             {/* <PencilIcon className="w-5 h-5 text-gray-600" /> */}
//             {/* Option 2: */}
//             {/* <PencilSquareIcon className="w-5 h-5 text-gray-600" /> */}
//             <AiOutlineEdit
//               className={`text-lg cursor-pointer text-gray-600`}
//               onClick={() => {
//                 toggleModal();
//               }}
//             />
//           </>
//         )}
//       </div>

//       <>
//         <div
//           className={`flex flex-col gap-2 ${
//             textSize ? `${textSize}` : "text-lg"
//           }  ${textColor ? `${textColor}` : "text-gray-900"} `}
//         >
//           <div className="flex items-center justify-between">
//             <h1>{`${customer?.firstName ?? ""} ${
//               customer?.lastName ?? ""
//             }`}</h1>
//             <button className={buttonClasses}>
//               <UserCircleIcon className={iconButtonClasses} />
//             </button>
//           </div>
//           {/* <h1>
//             {(customer?.firstName ?? "") + " " + (customer?.lastName ?? "")}
//           </h1> */}
//           {/* <h1>{customer?.address?.[0] ?? ""}</h1> */}
//           {address1Split && (
//             <div className="flex items-center justify-between">
//               <div>
//                 <p>{address1Split[0]}</p>
//                 {address1Split?.[1] && (
//                   <p className="text-wrap">{address1Split[1]}</p>
//                 )}
//               </div>
//               <button className={buttonClasses}>
//                 {/* Option 1: */}
//                 <MapPinIcon className={iconButtonClasses} />
//                 {/* Option 2: */}
//                 {/* <MapIcon className={iconButtonClasses} /> */}
//                 {/* Option 3: */}
//                 {/* <GlobeAltIcon className={iconButtonClasses} /> */}
//               </button>
//             </div>
//           )}
//           {customer?.phone?.mobile && (
//             <h1 className="flex flex-row items-center justify-between">
//               {/* <RiMessageLine
//                 className="mr-2 text-lg text-yellow-600 cursor-pointer hover:text-yellow-700"
//                 onClick={() => {
//                   navigate(`/messages/${customer?.customerId}`, {
//                     state: {
//                       phone: customer?.phone.mobile,
//                       name: customer?.displayName,
//                     },
//                   });
//                 }}
//               />
//               <span>
//                 {"(" +
//                   customer?.phone?.mobile.substring(2, 5) +
//                   ") " +
//                   customer?.phone?.mobile.substring(5, 8) +
//                   "-" +
//                   customer?.phone?.mobile.substring(8, 12)}
//               </span> */}

//               <span>
//                 {"(" +
//                   customer?.phone?.mobile.substring(2, 5) +
//                   ") " +
//                   customer?.phone?.mobile.substring(5, 8) +
//                   "-" +
//                   customer?.phone?.mobile.substring(8, 12)}
//               </span>
//               <button
//                 className={buttonClasses}
//                 onClick={() => {
//                   navigate(`/messages/${customer?.customerId}`, {
//                     state: {
//                       phone: customer?.phone.mobile,
//                       name: customer?.displayName,
//                     },
//                   });
//                 }}
//               >
//                 <ChatBubbleBottomCenterTextIcon className={iconButtonClasses} />
//                 {/* <RiMessageLine className={iconButtonClasses} /> */}
//               </button>
//             </h1>
//           )}
//           {customer?.email?.[0] && (
//             <div className="flex items-center justify-between">
//               <h1>{customer?.email?.[0]}</h1>
//               <button className={buttonClasses}>
//                 {/* Option 1: */}
//                 <EnvelopeIcon className={iconButtonClasses} />
//                 {/* Option 2: */}
//                 {/* <AtSymbolIcon className={iconButtonClasses} /> */}
//               </button>
//             </div>
//           )}
//           {/* <h1>{customer?.email?.[0] ?? ""}</h1> */}
//         </div>
//         {toggleNotifications && (
//           <div className="flex flex-row mt-4 items-center ">
//             {notifications ? (
//               <FaToggleOn
//                 onClick={toggleNotifications}
//                 className="text-3xl mr-2 cursor-pointer"
//               />
//             ) : (
//               <FaToggleOff
//                 onClick={toggleNotifications}
//                 className="text-3xl mr-2 cursor-pointer"
//               />
//             )}
//             <h2 className=" text-lg font-medium">Notifications</h2>
//           </div>
//         )}
//       </>
//     </div>
//   );
// }

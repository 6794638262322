import React from "react";

export default function RenderLineItems({ invoice, invoiceDetails }) {
  return (
    <div className="border-b pb-4 pt-20 mr-2">
      {invoice.lineItems &&
        invoice.lineItems.length > 0 &&
        invoice.lineItems.map((item, key) => {
          const unitPrice = parseFloat(item.unitPrice);
          const quantity = parseFloat(item.quantity);
          const itemSubTotal = unitPrice * quantity;
          const taxRate = parseFloat(item.taxRate?.rate) || 0;
          const itemTax = itemSubTotal * (taxRate / 100);

          // let tax = null;
          // if (item?.taxRate?.name) {
          //   let taxRate = parseFloat(item?.taxRate?.rate);
          //   let lineItemTotal =
          //     parseFloat(item.quantity) * parseFloat(item.unitPrice);
          //   tax = lineItemTotal * (taxRate / 100);
          // }

          return (
            <div
              className={`flex flex-row justify-between pb-2 ${
                invoiceDetails ? "fade-in" : "fade-out"
              } `}
              key={key}
            >
              <div className="flex flex-col w-1/2">
                <h1 className="text-slate-500 text-md  pl-7">{item.name}</h1>
                {itemTax > 0 && (
                  <h1 className="text-slate-500 text-sm  pl-7">Tax</h1>
                )}

                <h1 className="text-slate-400/90 text-sm w-1/2 pl-7 ">
                  Qty {item.quantity}
                </h1>
              </div>
              <div className="flex flex-col text-end">
                <h1 className="text-slate-500 text-md pr-3">
                  $
                  {item.unitPrice &&
                    parseFloat(item.unitPrice).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </h1>
                {itemTax > 0 && (
                  <h1 className="text-slate-500 text-md pr-3">
                    $
                    {itemTax.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h1>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
}

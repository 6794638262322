import React, { useState, useEffect } from "react";
import { node } from "constants/constants";
import DisplayCard from "components/payments/DisplayCard";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function PaymentMethodDisplay({
  stripeSubscription,
  stripeAccountId,
  onChangePaymentMethod,
}) {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const getPaymentMethod = async () => {
      if (stripeSubscription && stripeSubscription.default_payment_method) {
        try {
          const response = await fetch(
            `${node}/connect/payment-method/${stripeSubscription.default_payment_method}?stripeAccountId=${stripeAccountId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                // Add any other headers you might need, like authorization
              },
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          setPaymentMethod(data);
        } catch (error) {
          console.error("Error fetching payment method:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    getPaymentMethod();
  }, [stripeSubscription, stripeAccountId, node]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangePaymentMethod = () => {
    handleMenuClose();
    onChangePaymentMethod();
  };

  if (loading)
    return (
      <div>
        <h3 className="font-semibold h-6 w-24 bg-gray-100 animate-pulse rounded-md mb-1"></h3>
        <div className="flex flex-row items center w-full justify-between">
          <div className="h-6 w-36 bg-gray-100 animate-pulse rounded-md"></div>
        </div>
      </div>
    );
  if (!paymentMethod) return null;

  return (
    <div className="">
      <h3 className="font-semibold">Payment Method</h3>
      <div className="flex flex-row items center w-full justify-between">
        <DisplayCard savedPaymentMethod={paymentMethod} className="text-gray-500" />
        <button
          className="rounded-full flex items-center text-gray-400 hover:text-gray-600"
          onClick={() => {}}
          aria-label="Open options"
        >
          <EllipsisVerticalIcon onClick={handleMenuClick} className="h-5 w-5" aria-hidden="true" />
        </button>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              width: "20ch",
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={handleChangePaymentMethod}
            disableRipple
            sx={{
              fontFamily: "Inter var, system-ui, sans-serif",
              fontSize: "0.875rem",
              "&:hover": {
                backgroundColor: "#f3f4f6", // Tailwind's gray-100 color
              },
            }}
          >
            Change Payment Method
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

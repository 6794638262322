import React, { useState, useEffect, useCallback } from "react";
import { BiSend, BiCheckCircle } from "react-icons/bi";
import { BsExclamationCircle } from "react-icons/bs";
import { ImSpinner } from "react-icons/im";
import { massText } from "../../../utils/twilio";
import { IoCloseSharp } from "react-icons/io5";
import { useSnackBar } from "../../../context/SnackBarContext";
import Tooltip from "@mui/material/Tooltip";
import NormalButton from "components/buttons/NormalButton";
import RichTextInput from "components/reusable/RichTextInput";
import Dialogue from "components/modals/Dialogue";

// Check if a character is GSM-7 safe
const isGSMCharacter = (char) => {
  const gsmChars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz\n\r !\"#$%&'()*+,-./:;<=>?@[\\]^_{}|~";
  return gsmChars.includes(char);
};

const DYNAMIC_VARIABLES = [
  { label: "Customer First Name", value: "{firstName}" },
  { label: "Customer Last Name", value: "{lastName}" },
];

// const htmlToText = (html) => {
//   try {
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(html, "text/html");
//     if (doc.body.firstChild && doc.body.firstChild.nodeName === "parsererror") {
//       console.error("Error parsing HTML:", doc.body.firstChild.textContent);
//       return html; // Return original HTML if parsing fails
//     }
//     let text = "";
//     for (const node of doc.body.childNodes) {
//       if (node.nodeType === Node.TEXT_NODE) {
//         text += node.textContent;
//       } else if (node.hasAttribute("data-value")) {
//         text += node.getAttribute("data-value").replace(/{(.+)}/, "{{$1}}");
//       }
//     }
//     return text;
//   } catch (error) {
//     console.error("Error in htmlToText:", error);
//     return html; // Return original HTML if any error occurs
//   }
// };

const htmlToText = (html) => {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    if (doc.body.firstChild && doc.body.firstChild.nodeName === "parsererror") {
      console.error("Error parsing HTML:", doc.body.firstChild.textContent);
      return html; // Return original HTML if parsing fails
    }
    let text = "";
    const traverse = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        text += node.textContent;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.nodeName === "BR") {
          text += "\n";
        } else if (node.nodeName === "DIV" || node.nodeName === "P") {
          // Add a newline before block-level elements, but not for the first one
          if (text.length > 0) {
            text += "\n";
          }
        }

        if (node.hasAttribute("data-value")) {
          text += node.getAttribute("data-value").replace(/{(.+)}/, "{{$1}}");
        } else {
          for (const childNode of node.childNodes) {
            traverse(childNode);
          }
        }

        // Add a newline after block-level elements
        if (
          (node.nodeName === "DIV" || node.nodeName === "P") &&
          node.nextSibling
        ) {
          text += "\n";
        }
      }
    };

    traverse(doc.body);
    return text.trim(); // Trim to remove any leading/trailing whitespace
  } catch (error) {
    console.error("Error in htmlToText:", error);
    return html; // Return original HTML if any error occurs
  }
};

const textToHtml = (text) => {
  return text.replace(/{{(.+?)}}/g, (match, variable) => {
    const variableInfo = DYNAMIC_VARIABLES.find(
      (v) => v.value === `{${variable}}`
    );
    if (variableInfo) {
      return `<span contenteditable="false" class="bg-yellow-50 border border-gray-400 text-gray-700 py-1 px-2 rounded-full cursor-default text-sm whitespace-nowrap" data-value="{${variable}}">${variableInfo.label}<button class="ml-1 text-gray-800 hover:text-gray-400 font-bold text-lg transition-colors duration-300 ease-in-out" data-action="remove">×</button></span>`;
    }
    return match;
  });
};

export default function MarketingMessage({ userData, filteredCustomers }) {
  // console.log("userData.subData", userData.subData);
  const { openSnackBar } = useSnackBar();
  const [loading, setLoading] = useState(false);
  const defaultMessage =
    "Hey {{firstName}}, we're doing a special this month would you like us to service your house again?";
  const [message, setMessage] = useState({
    html: textToHtml(defaultMessage),
    text: defaultMessage,
  });
  const [modal, setModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);

  const handleChangeRichTextChange = useCallback((value, setter) => {
    setter((prevState) => ({
      html: value,
      text: htmlToText(value),
    }));
  }, []);

  useEffect(() => {
    // console.log("filtered customers:", filteredCustomers);
    // console.log("userData:", userData);
    // console.log("message.text:", message.text);
    // console.log("message.html:", message.html);
    // console.log("message boolean", !!message.text);
  }, [filteredCustomers, message.text]);

  async function handleSendMassText(e) {
    e.preventDefault();
    if (loading) return;
    if (!userData?.subData || userData?.subData.status !== "active") {
      alert(
        "You must have an active subscription to send mass text messages. Please contact support if you have any questions."
      );
      return;
    }
    if (!userData?.bizData?.telnyxNumber) {
      openSnackBar(
        "Your phone number is not set up yet. If it has been more than 24 hours since you purchased a subscription please contact support@homebase360.io."
      );
      return;
    }
    // if the user isn't and admin or a manger or has the explicit permission to send mass marketing messages then return
    if (
      userData?.userData?.userType !== "Admin" &&
      userData?.userData?.userType !== "Manger" &&
      !userData?.userData?.customPermissions?.marketing_messages
    ) {
      openSnackBar(
        "You do not have permission to send marketing messages. Please contact your admin to get access."
      );
      return;
    }
    // const notAbleToSend = userData?.userData?.customPermissions?.marketing_messages === false;

    console.log("filteredCustomer.length", filteredCustomers.length);

    setModal(false);
    setLoading(true);
    console.log("message:", message);
    const res = await massText(
      userData.bizData.id,
      message.text,
      filteredCustomers,
      userData.bizData.companyName,
      userData.bizData?.telnyxNumber || ""
    );
    console.log("res:", res);
    if (res.success) {
      openSnackBar("Messages have been scheduled for sending.", true);
    } else {
      openSnackBar(res.error); // Display the actual error message returned from the backend
    }
    setLoading(false);
  }

  return (
    <div className="flex flex-col pb-10   ">
      <div className="flex flex-row h-20 px-3 bg-gray-800 text-white rounded-t-md items-center">
        <h1 className="text-2xl ">Marketing Messages</h1>
      </div>

      <div className="flex flex-col mt-5  ">
        <div className="mx-5 font-bold relative text-lg mb-2 flex items-center">
          Customize Message{" "}
          <Tooltip title="To use the first name of the client in the message insert the Customer First Name variable by clicking the Customer First Name button. You can also add the Customer Last Name">
            <span className="inline-block">
              <BsExclamationCircle className=" ml-1 hover:cursor-pointer" />
            </span>
          </Tooltip>
        </div>

        <h1 className="mx-5 relative font-bold text-lg  flex items-center">
          Filter Recipients{" "}
          <Tooltip
            title={`Filter customers by clicking the "Timeframe" dropdown or the "Tags" dropdown and choosing which filters you'd like to apply.`}
          >
            <span className="inline-block">
              <BsExclamationCircle className=" ml-1 hover:cursor-pointer" />
            </span>
          </Tooltip>
        </h1>

        <div className="pb-4 px-4 ">
          <div className="flex flex-row justify-end items-center">
            <button
              className="text-xs font-medium text-indigo-500 px-2 py-1 mb-1 rounded-full hover:bg-gray-100 hover:text-indigo-600 transition-colors duration-300 ease-in-out"
              onClick={() => setPreviewModal(true)}
            >
              PREVIEW
            </button>
          </div>
          <RichTextInput
            value={message.html}
            onChange={(value) => handleChangeRichTextChange(value, setMessage)}
            label="Customer Message"
            DYNAMIC_VARIABLES={DYNAMIC_VARIABLES}
            LIMIT={400}
            htmlToText={htmlToText}
          />
        </div>
      </div>

      <div className="ml-5 mt-6">
        <NormalButton
          onClick={() => {
            if (!message.text) {
              openSnackBar(
                "Please enter a message before sending.",
                false,
                true
              );
              return;
            }
            setModal(true);
          }}
          loading={loading}
          text={"Send"}
          additionalClasses="w-36 text-lg"
          icon={<BiSend className="text-lg" />}
          iconPosition="right"
        />
      </div>

      <PreviewModal
        open={previewModal}
        onClose={() => setPreviewModal(false)}
        message={message.text}
      />

      {modal && (
        <ConfirmModal
          handleSendMassText={handleSendMassText}
          setModal={setModal}
          loading={loading}
          filteredCustomers={filteredCustomers}
          message={message.text}
        />
      )}
    </div>
  );
}

function ConfirmModal({
  handleSendMassText,
  setModal,
  filteredCustomers,
  message,
}) {
  const firstName = filteredCustomers && filteredCustomers[0]?.firstName;
  const lastName = filteredCustomers && filteredCustomers[0]?.lastName;

  let messageExample = message.replace(/{{\s*firstName\s*}}/gi, firstName);
  messageExample = messageExample.replace(/{{\s*lastName\s*}}/gi, lastName);

  return (
    <>
      <div className="modal">
        <div
          className="overlay"
          onClick={() => console.log("clicked overlay")}
        ></div>
        <div className="note-modal-content ">
          <IoCloseSharp
            onClick={() => setModal(false)}
            className="absolute text-2xl cursor-pointer"
            style={{
              top: "20px",
              right: "35px",
            }}
          />
          {!filteredCustomers ? (
            <div className="flex flex-col flex-1 items-center text-center lg:col-span-2 bg-white rounded-md shadow-md  gap-2 mb-8 pb-8 px-10 p-4">
              <div className="flex flex-row items-center gap-2 mt-4 mb-2 ml-2">
                <h1 className="text-xl font-bold">
                  Looks like you haven't selected any customers!
                </h1>
              </div>
              <div className="flex flex-row items-start justify-center">
                <h1 className="ml-1 mt-1">
                  Create more customers or change the filter so that we can send
                  them some messages. 🚀
                </h1>
              </div>
              <div className="flex flex-row items-center gap-4 mt-8">
                <button
                  onClick={() => setModal(false)}
                  className=" bg-gray-100  text-gray-500  font-bold py-2 px-4 rounded"
                >
                  Ok
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col flex-1 items-center text-center lg:col-span-2 bg-white rounded-md shadow-md  gap-2 mb-8 pb-8 px-10 p-4">
              <div className="flex flex-row items-center gap-2 mt-4 mb-2 ml-2">
                <h1 className="text-xl font-bold">
                  Confirm this is the message you'd like to send to{" "}
                  {filteredCustomers?.length}{" "}
                  {filteredCustomers?.length > 1 ? "customers" : "customer"}
                </h1>
              </div>
              <div className="flex flex-row items-start justify-center mt-5">
                <h1 className="text-lg font-bold">Example: </h1>
                <h1 className="ml-1 -mt-1 py-2 px-3 bg-gray-50 rounded-sm shadow whitespace-pre-wrap break-words text-start text-sm min-h-8">
                  {messageExample}
                </h1>
              </div>
              <div className="flex flex-row items-center gap-4 mt-8">
                <button
                  onClick={() => setModal(false)}
                  className=" bg-gray-100  text-gray-500  font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSendMassText}
                  className=" bg-gray-700 hover:bg-gray-800 text-green-200  font-bold py-2 px-4 rounded"
                >
                  Confirm Send
                </button>
              </div>
              <div className="flex flex-row items-center gap-4 mt-8 text-gray-600 text-xs">
                <p>
                  (Messages will be sent in batches of 8 every 2 minutes to
                  avoid rate limiting.)
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const PreviewModal = ({ open, onClose, message }) => {
  const newMessage = message
    .replace(/{{firstName}}/g, "John")
    .replace(/{{lastName}}/g, "Doe");

  return (
    <Dialogue open={open} onClose={onClose} width={500} p="p-6" py="pt-6 pb-8">
      <h2 className="text-lg font-bold mb-2">Marketing Message Preview</h2>
      <div className="bg-gray-50 px-3 py-2 rounded-sm shadow min-h-8">
        <p className="text-sm text-gray-700 whitespace-pre-wrap break-words">
          {newMessage}
        </p>
      </div>
    </Dialogue>
  );
};

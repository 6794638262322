import React, { useState, useEffect } from "react";
import Dialogue from "components/modals/Dialogue";
import InputMUI from "components/reusable/InputMUI";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import DeleteButton from "components/buttons/DeleteButton";
import CancelButton from "components/buttons/CancelButton";
import ExitModalButton from "components/buttons/ExitModalButton";
import { useSnackBar } from "context/SnackBarContext";
import { convertToJsDateNano } from "utils/helpers";
import { db } from "utils/firebase";
import { doc, updateDoc, arrayRemove } from "firebase/firestore";
import { format } from "date-fns";

const formatAmount = (amount) => {
  if (!amount) return "$0.00";

  let formatted = (amount / 100).toFixed(2);

  return `$${formatted}`;
};

export default function EditPaymentFromHistoryModal({
  open,
  onClose,
  payment,
  jobDetails,
  setJobDetails,
}) {
  const { openSnackBar } = useSnackBar();
  const [warningModal, setWarningModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const deletePayment = async () => {
    if (!payment.date || loadingDelete) return;
    const businessId = jobDetails?.businessId;
    const jobId = jobDetails?.jobId;
    const paymentTimestamp = convertToJsDateNano(payment.date)?.getTime();
    if (!businessId || !jobId || !paymentTimestamp) return;

    try {
      setLoadingDelete(true);
      const jobRef = doc(db, "businesses", businessId, "jobs", jobId);
      const prevPaymentHistory = jobDetails.paymentHistory;

      const paymentToRemove = prevPaymentHistory.find((payment) => {
        const paymentTime = convertToJsDateNano(payment.date)?.getTime();
        return paymentTime === paymentTimestamp;
      });

      if (!paymentToRemove) {
        throw new Error("Did not find a payment to remove from history");
      }

      await updateDoc(jobRef, {
        paymentHistory: arrayRemove(paymentToRemove),
      });

      // remove from local state
      setJobDetails((prevJobDetails) => {
        const updatedPaymentHistory = prevJobDetails.paymentHistory.filter(
          (payment) => {
            const paymentTime = convertToJsDateNano(payment.date)?.getTime();
            return paymentTime !== paymentTimestamp;
          }
        );
        return { ...prevJobDetails, paymentHistory: updatedPaymentHistory };
      });

      setWarningModal(false);
      onClose();
      openSnackBar("Payment deleted", true);
    } catch (error) {
      console.error("catch block error removing payment", error);
      openSnackBar("Error with removing payment", false, true);
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <Dialogue open={open} onClose={onClose} py="py-6">
      <WarningModal
        open={warningModal}
        handleClose={() => setWarningModal(false)}
        loadingDelete={loadingDelete}
        deletePayment={deletePayment}
      />
      <ExitModalButton handleClose={onClose} position="top-2 right-2" />
      <div className="flex flex-row items-center mb-6">
        <RiMoneyDollarCircleLine className="inline-block text-3xl mr-2" />
        <div className="font-bold text-xl">Edit Payment</div>
      </div>

      <h1 className="text-sm  text-gray-900">
        {convertToJsDateNano(payment.date) &&
          format(convertToJsDateNano(payment.date), "EEE MM/dd/yyyy, hh:mm aa")}
      </h1>
      <div className="flex flex-row justify-between items-center">
        <div className="font-medium">Amount</div>
        <div>{formatAmount(payment?.totalAmountFromStripe)}</div>
      </div>
      <div className="flex flex-row justify-between items-center">
        <div className="font-medium">Tip</div>
        <div>({formatAmount(payment?.tip)})</div>
      </div>
      <div className="flex flex-row justify-between items-center">
        <div className="font-medium">Tax</div>
        <div>({formatAmount(payment?.tax)})</div>
      </div>
      {payment?.paymentNote && (
        <div
          className="flex flex-row justify-between items-start
        "
        >
          <div className="font-medium">Note</div>
          <div className=" max-w-75% text-right">{payment.paymentNote}</div>
        </div>
      )}
      <div className="mt-6 w-full flex justify-start">
        <DeleteButton
          onClick={() => setWarningModal(true)}
          text="Delete Payment"
          width="w-36"
        />
      </div>
    </Dialogue>
  );
}

const WarningModal = ({ open, handleClose, deletePayment, loadingDelete }) => {
  return (
    <Dialogue open={open} onClose={() => {}}>
      <div className="mb-8">
        Are you sure you want to delete this payment? This action cannot be
        undone.
      </div>
      <div className="flex flex-row items-center justify-between">
        <CancelButton handleCancel={handleClose} />
        <DeleteButton
          onClick={deletePayment}
          loading={loadingDelete}
          text="Confirm Delete"
        />
      </div>
    </Dialogue>
  );
};

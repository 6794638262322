import { format, isToday, isYesterday, isThisYear } from "date-fns";

export const formatMessageDate = (date) => {
  if (isToday(date)) {
    return format(date, "p"); // e.g., "3:30 PM"
  } else if (isYesterday(date)) {
    return `Yesterday ${format(date, "p")}`;
  } else if (isThisYear(date)) {
    return format(date, "MMM d, p"); // e.g., "Jul 18, 3:30 PM"
  } else {
    return format(date, "MM/dd/yy, p"); // e.g., "07/18/23, 3:30 PM"
  }
};

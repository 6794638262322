import React, { useState } from "react";
import { IoCloseCircle, IoAlertCircle } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function PaymentFailedBanner() {
  const [dismissed, setDismissed] = useState(false);

  const handleDismiss = () => {
    setTimeout(() => setDismissed(true), 100);
  };

  if (dismissed) return null;

  return (
    <div
      className="fixed top-15 left-0 right-0 px-6 py-2 bg-red-600 text-white flex flex-row justify-between items-center"
      style={{ zIndex: 1000 }}
    >
      <div className="flex items-center">
        <IoAlertCircle className="text-2xl mr-4 text-white shrink-0" />
        <p className="text-sm md:text-base flex-grow">
          <span className="font-bold">Payment Failed:</span> Your subscription
          was unsuccessful due to a payment issue. Please start a new
          subscription to continue.
        </p>
      </div>

      <IoCloseCircle
        className="text-2xl ml-4 cursor-pointer shrink-0"
        onClick={handleDismiss}
      />
    </div>
  );
}

export const LoadingView = () => {
  return (
    <div className="relative">
      {/* Skeleton UI */}
      <div className="animate-pulse">
        <div className="bg-gray-200 rounded h-16 my-2"></div>
        <div className="bg-gray-200 rounded h-16 my-2"></div>
        <div className="bg-gray-200 rounded h-16 my-2"></div>
        <div className="bg-gray-200 rounded h-16 my-2"></div>
        <div className="bg-gray-200/60 rounded h-16 my-2"></div>
        <div className="bg-gray-200/30 rounded h-16 my-2"></div>
        <div className="bg-gray-200/20 rounded h-16 my-2"></div>
      </div>
    </div>
  );
};

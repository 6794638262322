import React, { useState, useContext, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoCloseSharp } from "react-icons/io5";
import DispatchTo from "../../AddJob/components/DispatchTo";
import EditDate from "./EditDate";
import { FaSave } from "react-icons/fa";
import Modal from "@mui/material/Modal";
import { updateDoc, doc, writeBatch, getDocs, collection, query, where } from "firebase/firestore";
import { UserContext } from "../../../index";
import { db } from "../../../utils/firebase";

export default function EditDispatchedToModal({
  employees,
  employeeList,
  modal,
  setModal,
  jobDetails,
  setJobDetails,
}) {
  const { userData } = useContext(UserContext);
  const [dispatchedTo, setDispatchedTo] = useState(jobDetails?.dispatchedTo || []);
  const [editRecurringModal, setEditRecurringModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDispatchedTo(jobDetails?.dispatchedTo || {});
  }, [jobDetails]);

  const handleClickSave = () => {
    console.log("handle click save");
    if (jobDetails?.isRecurring) {
      setEditRecurringModal(true);
    } else {
      handleSaveSingleJob();
    }
  };

  const handleSaveAllRecurringJobs = async () => {
    if (loading) return;
    setLoading(true);
    console.log("handle save all recurring jobs =====>>>");
    console.log("dispatchedTo", dispatchedTo);
    const { canViewIdsOnly, dispatchedToIdsOnly } = generateCanViewIdsAndDispatchedToIds();
    try {
      const dispatchedToIdsOnly = dispatchedTo.map((item) => item.id);
      // this is the logic for updating all recurring docs
      const dbQuery = query(
        collection(db, "businesses", userData.userData.businessId, "jobs"),
        where("recurrenceId", "==", jobDetails.recurrenceId),
        where("start", ">=", jobDetails.start)
      );

      const querySnapshot = await getDocs(dbQuery);

      // Check if there are any documents to delete
      if (querySnapshot.empty) {
        console.log("No documents found with the provided recurrenceId.");
        return;
      }

      const batch = writeBatch(db);

      querySnapshot.forEach((docSnapshot) => {
        const docRef = doc(db, "businesses", userData.userData.businessId, "jobs", docSnapshot.id);
        batch.update(docRef, {
          dispatchedTo: dispatchedTo,
          dispatchedToIdsOnly: dispatchedToIdsOnly,
          canViewIdsOnly: canViewIdsOnly,
        });
      });

      // Commit the batch
      await batch.commit();
      console.log("All recurring jobs with the specified recurrenceId have been updated.");

      setJobDetails({
        ...jobDetails,
        dispatchedTo: dispatchedTo,
        dispatchedToIdsOnly: dispatchedToIdsOnly,
        canViewIdsOnly: canViewIdsOnly,
      });

      setEditRecurringModal(false);
      setModal(false);
    } catch (error) {
      console.log("error", error);
      alert("There was an error updating the jobs, please try again");
      setEditRecurringModal(false);
      setModal(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveSingleJob = async () => {
    if (loading) return;
    console.log("handle save single job=======>");
    console.log("dispatchedTo", dispatchedTo);

    const { canViewIdsOnly, dispatchedToIdsOnly } = generateCanViewIdsAndDispatchedToIds();

    try {
      const jobRef = doc(db, "businesses", userData.bizData.id, "jobs", jobDetails.jobId);

      // update firestore docuement and jobDetails state
      await updateDoc(jobRef, {
        dispatchedTo: dispatchedTo,
        dispatchedToIdsOnly: dispatchedToIdsOnly,
        canViewIdsOnly: canViewIdsOnly,
      });
      setJobDetails({
        ...jobDetails,
        dispatchedTo: dispatchedTo,
        dispatchedToIdsOnly: dispatchedToIdsOnly,
        canViewIdsOnly: canViewIdsOnly,
      });
      setEditRecurringModal(false);
      setModal(false);
    } catch (error) {
      console.log("error", error);
      alert("There was an error updating the jobs, please try again");
    } finally {
      setLoading(false);
    }
  };

  const generateCanViewIdsAndDispatchedToIds = () => {
    let dispatchedToIdsOnly = [];
    let canViewIdsOnly = jobDetails?.canViewIdsOnly || [];
    // create a new array of Ids only for dispatchedTo
    if (dispatchedTo?.length > 0) {
      dispatchedToIdsOnly = dispatchedTo.map((item) => item.id);
    }

    // add newly added ids to the canViewIdsOnly array
    if (dispatchedTo?.length > 0) {
      dispatchedTo.forEach((item) => {
        if (!canViewIdsOnly.includes(item.id)) {
          canViewIdsOnly.push(item.id);
        }
      });
    }

    // find IDs that were in the old version of dispatchedToIds but not in the new version (removedIds)
    let removedIds = [];
    if (jobDetails.dispatchedToIdsOnly?.length > 0) {
      removedIds = jobDetails.dispatchedToIdsOnly.filter((item) => {
        return !dispatchedToIdsOnly.includes(item);
      });
    }

    // console.log("removed ids", removedIds);
    // remove removed ids from the canViewIdsOnly array only if they are not the current leadSource.salesPerson.id or the jobDetails.createdById
    if (removedIds.length > 0) {
      removedIds.forEach((item) => {
        if (item !== jobDetails?.leadSource?.salesPerson?.id && item !== jobDetails?.createdById) {
          if (canViewIdsOnly.includes(item)) {
            canViewIdsOnly = canViewIdsOnly.filter((id) => id !== item);
          }
        }
      });
    }
    return { canViewIdsOnly, dispatchedToIdsOnly };
  };

  return (
    <>
      <Modal open={modal} onClose={() => setModal(false)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "600px",
            backgroundColor: "#fff", // Assuming "background.paper" refers to a white or light color
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Adjust as needed
            paddingTop: 16, // 4 * 4 = 16 assuming a spacing system where 4 is the base unit
            paddingLeft: 16, // 4 * 4 = 16
            paddingRight: 16, // 4 * 4 = 16
            paddingBottom: 12, // 4 * 3 = 12
            outline: "none",
          }}
        >
          <DispatchTo
            employeeList={employeeList}
            employees={dispatchedTo}
            setDispatchedTo={setDispatchedTo}
            modal={true}
          />
          <button
            onClick={handleClickSave}
            className="absolute  bg-gray-900 hover:text-yellow-100 text-white self-end font-bold py-2 px-4 rounded"
            style={{
              top: "20px",
              right: "40px",
            }}
          >
            <span className="">Save</span>
          </button>
        </div>
      </Modal>

      <Modal open={editRecurringModal} onClose={() => setEditRecurringModal(false)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 450,
            backgroundColor: "#fff", // Assuming "background.paper" refers to a white or light color
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Adjust as needed
            paddingTop: 20, // 4 * 4 = 16 assuming a spacing system where 4 is the base unit
            paddingLeft: 32, // 4 * 4 = 16
            paddingRight: 32, // 4 * 4 = 16
            paddingBottom: 20, // 4 * 3 = 12
          }}
        >
          <h1 className="mb-6 font-bold text-lg">
            Do you want to edit all recurring or just this job?
          </h1>
          <div className="flex flex-row items-center justify-center">
            <button
              className="bg-gray-900 rounded-md px-3 py-3 justify-center shadow-lg flex items-center text-white whitespace-nowrap hover:text-yellow-100"
              onClick={handleSaveAllRecurringJobs}
            >
              All recurring
            </button>
            <button
              className="bg-gray-900 rounded-md  px-3 py-3 justify-center shadow-lg flex items-center text-white whitespace-nowrap ml-6  hover:text-yellow-100 "
              onClick={handleSaveSingleJob}
            >
              Just this job
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

import { Outlet, Navigate } from "react-router-dom";
import React from "react";
import { useUserContext } from "context/UserContext";
import { auth } from "utils/firebase";
import { ImSpinner } from "react-icons/im";
import { useAuthState } from "react-firebase-hooks/auth";
import SkeletonLoader from "./SkeletonLoader";
import { calculateFreeTrialTimeLeft } from "utils/userData";

export function ProtectedRoutes() {
  const { userData } = useUserContext();
  const [user, loading, error] = useAuthState(auth);
  const pathname = window.location.pathname;

  if (!user && !loading) {
    // If the user is not authenticated, redirect to the login page
    console.log(
      "user not authenticated redirecting back to login (protected routes)"
    );
    return <Navigate to="/login" />;
  }

  if (loading || !userData) {
    // Return a loading skeleton while the user's authentication state is being checked and while fetching their docs
    return <SkeletonLoader />;
  }
  const freeTrialTimeLeft = calculateFreeTrialTimeLeft(userData);

  // For users who never started their trial and need to initiate it
  if (
    user &&
    pathname !== "/new-subscription" &&
    pathname !== "/update-subscription" &&
    pathname !== "/subscription-success" &&
    pathname !== "/new-trial" &&
    pathname !== "/new-trial-success" &&
    userData?.subData?.status === "not_started"
  ) {
    console.log(
      "user never started their trial, redirection to new trial page"
    );
    return <Navigate to="/new-trial" />;
  }

  // For users who need to update their subscription
  if (
    user &&
    pathname !== "/update-subscription" &&
    pathname !== "/subscription-success" &&
    (userData?.subData?.status === "past_due" ||
      userData?.subData?.status === "incomplete")
  ) {
    console.log("path", pathname);
    // subscription has issues so they need to update it
    console.log("user is past_due, redirecting to update payment page");
    return <Navigate to="/update-subscription" />;
  }

  // we only want to direct to the new subscription page if the user has previously cancelled their subscription or it is there is no subscription object (edge case) or they have run out of time on their free trial -- or if they have an incomplete_expired or unpaid status
  // we switched incomplete_expired to redirect to new-subscription page because that means that the user started the subscription process but never completed it and so there is no active subscription for them to update
  // we also switched unpaid to redirect to new-subscription page bc that means that we have retried the failed paymented as many times as possible and now the user needs to start a new subscription since there are no more invoices on the old subscription
  if (
    user &&
    pathname !== "/new-subscription" &&
    pathname !== "/update-subscription" &&
    pathname !== "/subscription-success" &&
    (!userData?.subData ||
      userData?.subData?.status === "canceled" ||
      userData?.subData?.status === "incomplete_expired" ||
      userData?.subData?.status === "unpaid" ||
      (freeTrialTimeLeft !== null && freeTrialTimeLeft <= 0))
  ) {
    // user needs a new subscription
    console.log(
      "user's subscription has ended, redirecting to new subscription page"
    );
    return <Navigate to="/new-subscription" />;
  }

  // If the user is authenticated, render the protected route
  return <Outlet />;
}

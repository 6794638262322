import React, { useState, useEffect } from "react";

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

const formatInitialPhone = (value) => {
  if (!value) return value;
  const formattedPhoneNumber =
    "(" +
    value.substring(2, 5) +
    ") " +
    value.substring(5, 8) +
    "-" +
    value.substring(8, 12);

  // console.log("formattedPhoneNumber", formattedPhoneNumber);

  return formattedPhoneNumber;
};

export default function PhoneInputWithLabel({
  value,
  setValue,
  label,
  onChangeFunction = null,
  ...props
}) {
  const [phoneBeta, setPhoneBeta] = useState("");

  useEffect(() => {
    // console.log("value", value);
    setPhoneBeta(formatInitialPhone(value));
  }, []);

  useEffect(() => {
    // console.log("phoneBeta", phoneBeta);
    if (phoneBeta === "") {
      setValue("");
    } else {
      let modified = phoneBeta.replace(/\D/g, "");
      const final_number = "+1" + modified;

      setValue(final_number);
    }
  }, [phoneBeta]);

  const handlePhoneInput = (e) => {
    // this is where we'll call the phoneNumberFormatter function
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setPhoneBeta(formattedPhoneNumber);
    if (onChangeFunction) {
      onChangeFunction();
    }
  };

  return (
    <div className="relative flex-1 flex flex-col " {...props}>
      <input
        className={`bg-gray-100 border-b-2 border-black flex-1 rounded-sm border-r-0 outline-none focus:border-b-yellow-400 text-lg pt-6 pb-0 px-4`}
        disabled={props.disabled ? true : false}
        value={phoneBeta}
        placeholder="(453) 453-4534"
        onChange={handlePhoneInput}
      />

      <label
        className="absolute top-0 left-0 p-3 font-medium text-gray-600"
        style={{
          pointerEvents: "none",
          transition: "all 0.3s ease",
          transform: "translateY(-10px) translateX(-5px) scale(0.8)",
        }}
      >
        {label}
      </label>
    </div>
  );
}

import React, { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../../index";
import { useNavigate, useLocation } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-datepicker/dist/react-datepicker.css";
import "../../Tooltip.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../calendar.css";
import { db } from "../../utils/firebase";
import { collection, query, getDocs, where } from "firebase/firestore";
import moment from "moment";
import moment_timezone from "moment-timezone";
import Tooltip from "@mui/material/Tooltip";
import { convertToJsDate } from "utils/helpers";
// const DragAndDropCalendar = withDragAndDrop(Calendar)

// const locales = {
//   "en-US": require("date-fns/locale/en-US"),
// };

// const localizer = dateFnsLocalizer({
//   format,
//   parse,
//   startOfWeek,
//   getDay,
//   locales,
// });

function hexToRGBA(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default function Schedule() {
  const { user, userData, bannerVisible } = useContext(UserContext);
  moment_timezone.tz.setDefault(userData?.bizData?.timeZone);
  const localizer = momentLocalizer(moment_timezone);
  const [newEvent, setNewEvent] = useState({ title: "", start: "", end: "" });
  const [allEvents, setAllEvents] = useState([]);

  const isFieldTech = userData.userData.userType === "Field Tech";
  const canViewOwnJobsAndAssignedJobs =
    userData.userData.userType === "Salesperson2" ||
    userData?.userData?.customPermissions?.manage_assigned_jobs === true;

  const canViewTeamLeadJobs =
    userData.userData.userType === "Team Lead" ||
    userData?.userData?.customPermissions?.manage_team_jobs === true;

  const navigate = useNavigate();
  const location = useLocation();
  let datePassed = location?.state?.datePassed;
  const viewPassed = location?.state?.viewPassed;

  // if date passed exists then make sure it's in the business timzone
  if (datePassed) {
    datePassed = moment.tz(datePassed, userData?.bizData?.timeZone).toDate();
  }
  // create current date in this business timezone
  const initialCurrentDate = moment
    .tz(new Date(), userData?.bizData?.timeZone)
    .toDate();

  const [currentDate, setCurrentDate] = useState(
    datePassed || initialCurrentDate
  );

  const [currentView, setCurrentView] = useState(viewPassed || "week");

  const [selectedSlot, setSelectedSlot] = useState(null);

  useEffect(() => {
    fetchEventsForMonth();
  }, []);

  const [loadedMonth, setLoadedMonth] = useState(null);

  // console.log("userType", userData?.userData?.userType);

  const fetchEventsForMonth = async (date) => {
    if (!date) {
      date = currentDate;
    }
    if (
      loadedMonth &&
      loadedMonth.getMonth() === date.getMonth() &&
      loadedMonth.getFullYear() === date.getFullYear()
    ) {
      console.log("already fetched events for this month");
      return;
    }
    // console.log("fetching events for month", date);
    // we do it correctly by using the timezone of the business, then we add a margin of safety by adding 8 days before and after
    const monthStart = moment
      .tz(date, userData?.bizData?.timeZone)
      .startOf("month")
      .subtract(8, "days")
      .toDate();
    const monthEnd = moment
      .tz(date, userData?.bizData?.timeZone)
      .endOf("month")
      .add(8, "days")
      .toDate();

    // console.log("monthStart", monthStart);
    // console.log("monthEnd", monthEnd);
    let jobsSnapshot;
    if (isFieldTech) {
      jobsSnapshot = await getDocs(
        query(
          collection(db, "businesses", userData.userData.businessId, "jobs"),
          where("start", ">=", monthStart),
          where("start", "<=", monthEnd),
          where("dispatchedToIdsOnly", "array-contains", userData.userData.id)
        )
      );
    } else if (canViewOwnJobsAndAssignedJobs) {
      jobsSnapshot = await getDocs(
        query(
          collection(db, "businesses", userData.userData.businessId, "jobs"),
          where("start", ">=", monthStart),
          where("start", "<=", monthEnd),
          where("canViewIdsOnly", "array-contains", userData.userData.id)
        )
      );
    } else if (canViewTeamLeadJobs) {
      console.log("getting docs for team lead");
      // Get the array of assigned employee IDs and add the team lead's own ID
      const canViewIds = [
        ...userData.userData.assignedEmployees,
        userData.userData.id,
      ];

      jobsSnapshot = await getDocs(
        query(
          collection(db, "businesses", userData.userData.businessId, "jobs"),
          where("start", ">=", monthStart),
          where("start", "<=", monthEnd),
          where("canViewIdsOnly", "array-contains-any", canViewIds)
        )
      );
    } else {
      jobsSnapshot = await getDocs(
        query(
          collection(db, "businesses", userData.userData.businessId, "jobs"),
          where("start", ">=", monthStart),
          where("start", "<=", monthEnd)
        )
      );
    }

    const jobs = jobsSnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        title: doc.data().customer.displayName,
        start: doc.data().start.toDate(),
        end: doc.data().end.toDate(),
      };
    });

    setLoadedMonth(date);
    setAllEvents(jobs);
  };

  const eventStyleGetter = (event) => {
    const notStarted = !event.startedJobTime ? true : false;
    const canceled = event.status === "cancelled" ? true : false;
    const missed = event.status === "missed" ? true : false;
    const paid = event.datePaid ? true : false;
    const finishedAndPaid = event.endedJobTime && event.datePaid ? true : false;
    const startedNotFinished =
      event.startedJobTime && !event.endedJobTime ? true : false;
    const finishedNotPaid =
      event.endedJobTime && !event.datePaid ? true : false;

    const baseColor =
      event.dispatchedTo &&
      event.dispatchedTo.length > 0 &&
      event.dispatchedTo?.[0].color?.value
        ? event.dispatchedTo[0].color.value
        : "#374151";
    let backgroundColor = hexToRGBA(baseColor, 0.7);

    if (canceled) {
      backgroundColor = hexToRGBA("#374151", 0.1);
    } else if (missed) {
      backgroundColor = hexToRGBA("#FFF0E0", 0.4);
    } else if (paid) {
      backgroundColor = hexToRGBA("#374151", 0.1);
    } else if (startedNotFinished) {
      backgroundColor = hexToRGBA(baseColor, 0.3);
    } else if (event.endedJobTime) {
      backgroundColor = hexToRGBA("#374151", 0.1);
    }

    let borderColor, borderStyle;
    if (canceled) {
      borderColor = "transparent";
    } else if (missed) {
      borderColor = "#FFA500";
      borderStyle = "dashed";
    } else if (paid) {
      borderColor = "#22c55e";
    } else if (finishedNotPaid) {
      borderColor = "#ef4444";
    } else if (startedNotFinished) {
      borderColor = "#374151";
    } else {
      borderColor = "transparent";
    }
    return {
      style: {
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: "2px",
        borderRadius: "5px",
        borderStyle: borderStyle || "solid",
      },
    };
  };

  const CustomEvent = ({ event }) => {
    const canceled = event.status === "cancelled" ? true : false;
    const jobTotal = event.jobTotal || 0;
    const formattedJobTotal = jobTotal.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return (
      <Tooltip
        title={<TooltipContent event={event} />}
        placement="top"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#4b5563", // light gray background
              color: "common.white", // white text
              boxShadow: 3, // predefined shadow, you can adjust the number or define your own shadow
              "& .MuiTooltip-arrow": {
                color: "#4b5563", // color of the arrow to match the tooltip background
              },
            },
          },
        }}
      >
        <div
          className="flex-1 "
          style={{
            height: "100%",
          }}
        >
          <div
            className={`font-bold text-sm ${
              canceled ? "text-red-400 line-through" : ""
            }`}
          >
            {event?.title}
          </div>

          <p
            className={`text-sm ${canceled ? "text-red-300 line-through" : ""}`}
          >
            ${formattedJobTotal}
          </p>
        </div>
      </Tooltip>
    );
  };

  const TooltipContent = ({ event }) => {
    let totalPaid = 0;

    if (event?.paymentHistory) {
      event.paymentHistory.forEach((payment) => {
        if (payment.billingType === "Refund") {
          totalPaid -= payment.totalAmountFromStripe;
        } else {
          totalPaid += payment.totalAmountFromStripe;
        }
      });

      totalPaid = parseFloat(totalPaid / 100).toFixed(2);
    }

    const jobTotal = event.jobTotal || 0;
    const formattedJobTotal = jobTotal.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const formattedTotalPaid = totalPaid.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return (
      <div
        className=""
        style={{
          maxWidth: "200px",
        }}
      >
        <div className="font-semibold">{event.lineItems[0].name}</div>
        <div>
          <span className="font-semibold">Job total:</span> ${formattedJobTotal}
        </div>
        <div>
          <span className="font-semibold">Total paid:</span> $
          {formattedTotalPaid}
        </div>
        {event?.dispatchedTo?.[0] && (
          <p>
            <span className="mr-1 font-semibold">
              {event?.dispatchedTo?.length === 1
                ? "Field Tech:"
                : "Field Techs:"}
            </span>
            {event.dispatchedTo.map((employee, key) => {
              return (
                <span className="mr-1" key={key}>
                  {employee.firstName} {employee.lastName}
                  {key !== event.dispatchedTo.length - 1 && ", "}
                </span>
              );
            })}
          </p>
        )}
        {event?.leadSource?.salesPerson && (
          <p>
            <span className="mr-1 font-semibold">Salesperson:</span>
            <span className="mr-1">
              {event?.leadSource?.salesPerson.firstName}
            </span>
            <span>{event?.leadSource?.salesPerson.lastName}</span>
          </p>
        )}
        {event?.leadSource?.online ||
          (event?.leadSource?.direct && (
            <div>
              <span className="font-semibold">Lead Type:</span>{" "}
              {event?.leadSource?.online
                ? "Online"
                : event?.leadSource?.direct
                ? "Direct"
                : ""}
            </div>
          ))}
        {event?.customer?.address?.[0] && (
          <div>
            <span className="font-semibold">Address:</span>{" "}
            {event?.customer?.address?.[0]}
          </div>
        )}
        {event?.customer?.phone?.mobile && (
          <div>
            <span className="font-semibold">Phone:</span>{" "}
            {"(" +
              event?.customer?.phone?.mobile.substring(2, 5) +
              ") " +
              event?.customer?.phone?.mobile.substring(5, 8) +
              "-" +
              event?.customer?.phone?.mobile.substring(8, 12)}
          </div>
        )}
        <div>
          <span className="font-semibold">Created:</span>{" "}
          {moment(convertToJsDate(event?.dateAdded)).format(
            "MM/DD/YYYY h:mm a"
          )}
        </div>
      </div>
    );
  };

  // usefull information for checking the timezone on a date
  useEffect(() => {
    // const createdAtDate = allEvents[0]?.createdAt;
    // const createdAtMoment = moment(createdAtDate);
    // // Now to check the timezone information:
    // console.log("UTC Offset:", createdAtMoment.format("Z")); // Shows the UTC offset
    // console.log("Timezone (attempt):", createdAtMoment.format("zz")); // Timezone abbreviation, but may not be reliable
    // // If you specifically want to validate against the 'America/Chicago' timezone:
    // console.log("timezone:", createdAtMoment.tz());
  }, []);

  const handleSelectSlot = (slotInfo) => {
    // console.log("handleSelectSlot", slotInfo);
    setSelectedSlot(slotInfo);
  };

  const handleAddJob = () => {
    console.log("Add Job");
    // go to /addjob
    navigate("/addjob", { state: { selectedSlot } });
  };

  // function handleAddEvent() {
  //   setAllEvents([...allEvents, newEvent]);
  // }

  // const onEventDrop = (data) => {
  //   const { start, end, event } = data;
  //   const id = event.jobId;

  //   setAllEvents((prevEvents) => {
  //     const updatedEvents = [...prevEvents];
  //     const index = updatedEvents.findIndex((event) => event.jobId === id);
  //     updatedEvents[index].start = start;
  //     updatedEvents[index].end = end;
  //     return updatedEvents;
  //   });
  // };

  // const onEventResize = (data) => {
  //   const { start, end, event } = data;
  //   const id = event.jobId;

  //   setAllEvents((prevEvents) => {
  //     const updatedEvents = [...prevEvents];
  //     const index = updatedEvents.findIndex((event) => event.jobId === id);
  //     updatedEvents[index].start = start;
  //     updatedEvents[index].end = end;
  //     return updatedEvents;
  //   });
  // };

  // this is the actual function that handles clicking on the event
  const handleSelectEvent = (event) => {
    // console.log("handleSelectEvent", event);

    navigate(`/jobdetails/${event.jobId}`, {
      state: {
        jobId: event.jobId,
        currentDate: currentDate,
        currentView: currentView,
      },
    });
  };

  // console.log("currentDate==============================>", currentDate);
  return (
    <div
      className={` ${
        bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
      } overflow-y-auto `}
    >
      {selectedSlot && (
        <div
          style={{
            position: "absolute",
            top: selectedSlot.box?.y
              ? selectedSlot.box?.y - 50
              : selectedSlot.bounds?.y - 50,
            left: selectedSlot.box?.x
              ? selectedSlot.box?.x - 50
              : selectedSlot.bounds?.x,
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid gray",
            borderRadius: "5px",
            zIndex: "100",
          }}
        >
          <button onClick={handleAddJob}>Add Job</button>
        </div>
      )}
      <Calendar
        localizer={localizer}
        view={currentView}
        onView={(view) => setCurrentView(view)}
        events={allEvents}
        onSelectEvent={handleSelectEvent}
        startAccessor="start"
        endAccessor="end"
        // onEventDrop={onEventDrop}
        // onEventResize={onEventResize}
        // selectable
        onSelectSlot={handleSelectSlot}
        style={{
          height: bannerVisible
            ? "calc(100vh - 145px) "
            : "calc(100vh - 100px)",
          marginTop: "10px",
          marginLeft: "2px",
          marginRight: "2px",
          marginBottom: "2px",
          margin: "10px",
          position: "relative",
          zIndex: "1",
        }}
        eventPropGetter={eventStyleGetter}
        components={{
          event: CustomEvent,
        }}
        formats={{
          eventTimeRangeFormat: () => {
            return "";
          },
        }}
        tooltipAccessor={() => {
          return null;
        }}
        date={currentDate}
        onNavigate={(date) => {
          setCurrentDate(date);
          fetchEventsForMonth(date);
        }}
      />
    </div>
  );
}

export const getLineItemErrors = (lineItem) => {
  const errors = {};

  if (!lineItem.name) {
    errors.name = "Please enter item name.";
  }

  if (!lineItem.unitPrice) {
    errors.unitPrice = "Please enter unit price.";
  }

  if (!lineItem.category) {
    errors.category = "Please select category.";
  }

  if (Object.keys(errors).length === 0) {
    return null;
  }

  return errors;
};

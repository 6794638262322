import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const BasicButton = ({
  onClick,
  disabled = false,
  loading = false,
  text,
  fontSize = "text-xs",
  fontWeight = "font-medium",
  spinnerColor = "inherit",
  spinnerSize = 20,
  additionalClasses = "",
  width = "w-fit",
  height = "auto",
}) => {
  // Determine the width based on the text length
  //   console.log(text.length);
  //   const minWidth = `${text.length - 4}rem`; // Adjust the +2 as needed

  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={`flex justify-center items-center bg-indigo-100 ${
        loading ? "" : "hover:bg-indigo-200"
      } text-gray-900 ${width}  rounded-md ${height} ${fontSize} ${fontWeight} shadow-md ${additionalClasses}`}
      //   style={{ minWidth: minWidth }}
    >
      {loading ? (
        <CircularProgress size={spinnerSize} style={{ color: spinnerColor }} />
      ) : (
        text
      )}
    </button>
  );
};

export default BasicButton;

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { OutlineButton } from "../NewButtons/OutlineButton";

export const ConfirmDeleteModal = ({ open, setOpen, deleteCategory, categoryName }) => {
  return (
    <Dialog className="relative z-50" open={open} onClose={() => {}}>
      <DialogBackdrop transition className="fixed inset-0 bg-gray-500 bg-opacity-75 " />

      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative overflow-hidden rounded-lg bg-white px-4 py-4 text-left shadow-xl   sm:w-full sm:max-w-md sm:p-6 "
          >
            <div className="text-center">
              <DialogTitle as="h2" className="text-xl font-bold text-left mb-6">
                Are you sure you want to delete {categoryName}?
              </DialogTitle>
            </div>
            <p>This will also delete all line items inside this category.</p>

            <div className="mt-5 flex flex-row items-center gap-3">
              <OutlineButton text="Cancel" onClick={() => setOpen(false)} fullWidth />

              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 sm:col-start-2 disabled:bg-red-100 flex-1"
                onClick={deleteCategory}
              >
                Delete
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

import React, { useState, useEffect, useMemo } from "react";
import Modal from "@mui/material/Modal";
import InputMUI from "components/reusable/InputMUI";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox } from "components/ui/checkbox";
import DisabledButton from "components/buttons/DisabledButton";
import CancelButton from "components/buttons/CancelButton";
import _ from "lodash";
import { db } from "utils/firebase";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { convertToJsDateNano } from "utils/helpers";
import { format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useSnackBar } from "context/SnackBarContext";

export default function EditVisitModal({
  open,
  onClose,
  visit,
  setVisit,
  subscriptionDetails,
  setSubscriptionDetails,
}) {
  // onVisitSave will update the subscriptionDetails with the new visit..

  const { openSnackBar } = useSnackBar();
  const [loadingSave, setLoadingSave] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);

  // fetch jobDetails if there is a jobId
  useEffect(() => {
    const fetchJobDetails = async () => {
      const jobRef = doc(
        db,
        "businesses",
        subscriptionDetails.businessId,
        "jobs",
        visit.jobId
      );
      const jobDoc = await getDoc(jobRef);
      if (!jobDoc.exists()) {
        console.log("No such document!");
        return;
      }
      setJobDetails(jobDoc.data());
    };

    if (visit?.jobId && subscriptionDetails?.businessId) {
      console.log(
        "jobId",
        visit.jobId,
        "businessId",
        subscriptionDetails.businessId
      );
      fetchJobDetails();
    } else {
      setJobDetails(null);
    }
  }, [visit?.jobId]);

  const saveDisabled = useMemo(() => {
    if (!visit) return true;
    // use lodash equality check
    const equalToBefore = _.isEqual(
      visit,
      subscriptionDetails.visits.find((v) => v.id === visit.id)
    );

    const nameIsTruthy = visit?.name;

    return equalToBefore || !nameIsTruthy;
  }, [visit, subscriptionDetails]);

  const handleSave = async () => {
    try {
      setLoadingSave(true);
      // check if we are removing the job from the visit
      const beforeVisit = subscriptionDetails.visits.find(
        (v) => v.id === visit.id
      );

      if (
        beforeVisit?.jobId &&
        !visit.jobId &&
        subscriptionDetails.subscriptionId
      ) {
        // update job to remove subscription ( only happens when we are removing job from visit from subscription details page/modal)
        const jobRef = doc(
          db,
          "businesses",
          subscriptionDetails.businessId,
          "jobs",
          beforeVisit.jobId
        );
        await updateDoc(jobRef, { subscription: null });
      }

      const newVisits = subscriptionDetails.visits.map((v) => {
        if (v.id === visit.id) {
          return visit;
        }
        return v;
      });

      const newSubscriptionDetails = {
        ...subscriptionDetails,
        visits: newVisits,
      };

      if (subscriptionDetails.subscriptionId) {
        // Update visit in the subscription -- if we have already created the subscription
        const subscriptionRef = doc(
          db,
          "businesses",
          subscriptionDetails.businessId,
          "subscriptions",
          subscriptionDetails.subscriptionId
        );

        await updateDoc(subscriptionRef, {
          visits: newVisits,
        });

        openSnackBar("Updated successfully", true);
      }

      setSubscriptionDetails(newSubscriptionDetails); // we did it this way because we might not have access to (prev) if we are coming from jobDetails page

      handleClose();
    } catch (error) {
      console.error("Error updating visit", error);
    } finally {
      setLoadingSave(false);
    }
  };

  const removeAssociatedVisit = () => {
    setVisit({ ...visit, jobId: null, status: "unscheduled" });
  };

  const handleCheck = () => {
    const lastStatus = visit?.status;

    setVisit({
      ...visit,
      status: lastStatus === "unscheduled" ? "completed" : "unscheduled",
    });
  };

  const handleClose = () => {
    setJobDetails(null);
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose}>
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:w-[600px] bg-white shadow-bold p-10 py-8 rounded-md`}
      >
        <h2 className="text-lg font-bold text-center mb-4">Edit Visit</h2>
        <div className="mb-4">
          <InputMUI
            value={visit?.name}
            onChangeFunction={(e) => {
              setVisit({ ...visit, name: e.target.value });
            }}
            label="Visit Name"
          />
        </div>
        <div className="flex flex-row items-center justify-end mt-6 gap-2">
          <BasicSelect
            value={visit?.month}
            setValue={(value) => {
              setVisit({ ...visit, month: value });
            }}
            options={[
              { value: 0, label: "January" },
              { value: 1, label: "February" },
              { value: 2, label: "March" },
              { value: 3, label: "April" },
              { value: 4, label: "May" },
              { value: 5, label: "June" },
              { value: 6, label: "July" },
              { value: 7, label: "August" },
              { value: 8, label: "September" },
              { value: 9, label: "October" },
              { value: 10, label: "November" },
              { value: 11, label: "December" },
            ]}
            label="Month"
            width="50%"
          />
          <BasicSelect
            value={visit?.year}
            setValue={(value) => {
              setVisit({ ...visit, year: value });
            }}
            options={[
              { value: 2023, label: 2023 },
              { value: 2024, label: 2024 },
              { value: 2025, label: 2025 },
              { value: 2026, label: 2026 },
              { value: 2027, label: 2027 },
              { value: 2028, label: 2028 },
              { value: 2029, label: 2029 },
              { value: 2030, label: 2030 },
            ]}
            label="Year"
            width="50%"
          />
        </div>
        {visit?.jobId ? (
          <div className="mt-6">
            <div>
              <div className="flex flex-row justify-between items-center">
                <label className="font-medium">Associated Job</label>

                <Tooltip title="Remove associated Job">
                  <IconButton
                    aria-label="remove"
                    size="small"
                    onClick={removeAssociatedVisit}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </div>

              <div className="flex flex-col ">
                <div className="flex flex-row ">
                  <div className="text-gray-500 w-24">Id</div>
                  <div className="text-gray-500"> {visit?.jobId}</div>
                </div>
                {jobDetails?.start && jobDetails?.end ? (
                  <>
                    <div className="flex flex-row">
                      <div className="text-gray-500 w-24">Start</div>
                      <div className="text-gray-500">
                        {convertToJsDateNano(jobDetails.start)
                          ? format(
                              convertToJsDateNano(jobDetails.start),
                              "h:mmaaa EEEE, MMMM do yyyy"
                            )
                          : ""}
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="text-gray-500 w-24">End</div>
                      <div className="text-gray-500">
                        {convertToJsDateNano(jobDetails.end)
                          ? format(
                              convertToJsDateNano(jobDetails.end),
                              "h:mmaaa EEEE, MMMM do yyyy"
                            )
                          : ""}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-row items-center">
                      <div className="text-gray-500 w-24">Start</div>
                      <div className="bg-gray-200 w-48 rounded-md h-4 animate-pulse" />
                    </div>
                    <div className="flex flex-row items-center">
                      <div className="text-gray-500 w-24">End</div>
                      <div className="bg-gray-200 w-48 rounded-md h-4 animate-pulse" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-6 flex flex-row items-center">
            <Checkbox
              checked={visit?.status === "completed"}
              onCheckedChange={handleCheck}
            />
            <label className="ml-2">Mark as completed</label>
          </div>
        )}
        <div className="flex flex-row items-center justify-end mt-6 gap-2">
          <CancelButton handleCancel={handleClose} />
          <DisabledButton
            disabled={saveDisabled || loadingSave}
            text="Save"
            onClick={handleSave}
            loading={loadingSave}
          />
        </div>
      </div>
    </Modal>
  );
}

const gray500 = "#6b7280";
const yellow400 = "#facc15";
const yellow200 = "#fef08a";
const yellow100 = "#fef9c3";
const yellow50 = "#fefce8";
const gray50 = "#f9fafb";
const gray900 = "#111827";
const gray800 = "#1f2937";

function BasicSelect({
  value,
  setValue,
  options,
  label,
  width,
  focusedBorderColor = yellow200,
  borderColor, // gray -500
  multiple = false,
  backgroundColor,
  height = "auto",
  focusedLabelColor = gray500,
  labelColor,
  borderNone = false,
  clear = false,
  selectedTextColor = yellow50,
  arrowColor = gray500,
  onChangeFunction = null,
}) {
  const [hasValue, setHasValue] = useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (value) {
      setHasValue(true);
    } else {
      setHasValue(false);
    }
  }, [value]);

  return (
    <Box sx={{ width: width }}>
      <FormControl fullWidth>
        <InputLabel
          //className="text-gray-900"
          sx={{
            "&.Mui-focused": {
              color: focusedLabelColor,
            },

            backgroundColor: gray50,
            ...(labelColor && { color: labelColor }),

            // Color when there is a value but not focused (Selected/Unfocused)
            ...(hasValue && {
              color: focusedLabelColor,
            }),
          }}
        >
          {label}
        </InputLabel>
        <Select
          sx={{
            ...(borderNone && {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }),
            ...(borderColor && {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: gray500,
              },
            }),
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: yellow200,
              "&:hover": {
                borderColor: yellow400, // Maintain yellow-200 on hover when focused
              },
            },

            backgroundColor: gray50,

            //"&:hover .MuiOutlinedInput-notchedOutline": {
            //  borderColor: gray500,
            //},

            // Style for the selected text

            ".MuiSelect-select": {
              color: gray900, // change this to your desired color
            },

            // Style for the dropdown arrow
            ...(arrowColor && {
              ".MuiSelect-icon": {
                color: arrowColor, // replace this with your desired color
              },
            }),
            ...(backgroundColor && { backgroundColor }),
            height: height,
          }}
          //size="small"
          //variant="filled"
          value={value}
          label={label}
          onChange={onChangeFunction || handleChange}
          multiple={multiple}
        >
          {clear && (
            <MenuItem key="clear" value="clear">
              Clear Selection
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

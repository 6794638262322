import React from "react";
import Dialogue from "components/modals/Dialogue";
import NormalButton from "components/buttons/NormalButton";
import DeleteButton from "components/buttons/DeleteButton";
import CancelButton from "components/buttons/CancelButton";
import ExitModalButton from "components/buttons/ExitModalButton";
import { node } from "constants/constants";
import { useSnackBar } from "context/SnackBarContext";

export default function SubscriptionCancelModal({
  open,
  onClose,
  subscription,
  setSubscription,
}) {
  const { openSnackBar } = useSnackBar();
  const [cancelLoading, setCancelLoading] = React.useState(false);
  const [cancelError, setCancelError] = React.useState(null);

  const handleCancelSubscription = async () => {
    setCancelLoading(true);
    setCancelError(null);

    try {
      const response = await fetch(`${node}/serviceSubscriptionsApi/cancel`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscriptionId: subscription.subscriptionId,
          businessId: subscription.businessId,
          stripeSubscriptionId: subscription.stripeSubscription.id,
          stripeAccountId: subscription.stripeAccountId,
        }),
      });

      const result = await response.json();

      if (result.success) {
        setSubscription((prevSubscription) => ({
          ...prevSubscription,
          status: "canceled",
          cancelledAt: new Date(),
        }));
        openSnackBar("Subscription cancelled successfully", true);
        onClose();
      } else {
        setCancelError("Failed to cancel subscription. Please try again.");
      }
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      setCancelError("An error occurred while cancelling the subscription.");
    }

    setCancelLoading(false);
  };

  return (
    <Dialogue open={open} onClose={onClose} width={500}>
      <ExitModalButton handleClose={onClose} position="top-5 right-8" />
      <div className="flex flex-col gap-4">
        <h2 className="text-xl font-bold">Cancel Subscription</h2>
        <p className="text-gray-700">
          Are you sure you want to cancel this subscription? Once a subscription
          has been cancelled, it cannot be reactivated. No further charges will
          be incurred by the customer.
        </p>
        {cancelError && <p className="text-red-500 text-sm">{cancelError}</p>}
        <div className="flex justify-between gap-4 mt-4">
          <CancelButton handleCancel={onClose} text="Go Back" />
          <DeleteButton
            onClick={handleCancelSubscription}
            text="Cancel Subscription"
            loading={cancelLoading}
            width="w-48"
          />
        </div>
      </div>
    </Dialogue>
  );
}

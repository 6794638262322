import React from "react";
import Modal from "@mui/material/Modal";
import { IoCloseSharp } from "react-icons/io5";

export default function DeleteModal({
  deleteModal,
  setDeleteModal,
  deleteJob,
  isRecurring,
  deleteRecurring,
}) {
  return (
    <Modal open={deleteModal} onClose={() => setDeleteModal(false)}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "600px",
          backgroundColor: "#fff", // Assuming "background.paper" refers to a white or light color
          borderRadius: "10px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Adjust as needed
          paddingTop: 16, // 4 * 4 = 16 assuming a spacing system where 4 is the base unit
          paddingLeft: 16, // 4 * 4 = 16
          paddingRight: 16, // 4 * 4 = 16
          paddingBottom: 12, // 4 * 3 = 12
          outline: "none",
        }}
      >
        <IoCloseSharp
          className="absolute top-2 right-2 text-2xl text-gray-500 hover:text-gray-900 cursor-pointer"
          onClick={() => setDeleteModal(false)}
        />

        {isRecurring ? (
          <>
            {" "}
            <h1 className="text-2xl font-bold text-gray-900 mb-4 text-center">
              Delete Job
            </h1>
            <p className="text-gray-700 text-center mb-4">
              Do you want to delete this and all following recurring jobs or
              just this job?
            </p>
            <div className="flex flex-col justify-center items-center mb-4 mx-4">
              <div className="flex flex-row justify-center items-center">
                <button
                  className=" bg-gray-900 font-semibold rounded-md p-2 mt-1 text-red-200 text-md hover:text-red-400 shadow-md "
                  onClick={deleteRecurring}
                >
                  Delete This And All Following Jobs
                </button>
                <button
                  className=" bg-gray-900 font-semibold rounded-md p-2 mt-1 text-red-200 text-md ml-2 hover:text-red-400 shadow-md w-"
                  onClick={deleteJob}
                >
                  Delete Just This Job
                </button>
              </div>
            </div>{" "}
          </>
        ) : (
          <>
            <h1 className="text-2xl font-bold text-gray-900 mb-4 text-center">
              Delete Job
            </h1>
            <p className="text-gray-700 text-center mb-4">
              Are you sure? You will loose your data.
            </p>
            <div className="flex flex-col justify-center items-center mb-4 mx-4">
              <div className="flex flex-row justify-center items-center">
                <button
                  className=" bg-gray-900 font-semibold rounded-md p-2 mt-1 text-red-200 text-md ml-2 hover:text-red-400 shadow-md w-"
                  onClick={deleteJob}
                >
                  Delete Job
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
    // <div className="warning-modal">
    //   <div
    //     className="warning-overlay"
    //     onClick={() => console.log("clicked overlay")}
    //   ></div>
    //   <div className="warning-delete-modal-content ">
    //     {isRecurring ? (
    //       <>
    //         <div className="mt-5">
    //           <h1 className="text-2xl font-semibold">Are you sure?</h1>
    //           <h1 className="mb-8 mt-2">Your job data will be lost</h1>
    //         </div>
    //         <div className="flex flex-row justify-start items-center mb-4">
    //           <button
    //             className=" bg-gray-900 font-semibold rounded-md p-2 mt-1 text-red-400 text-md hover:text-red-200 shadow-md w-1/2 h-16"
    //             onClick={deleteRecurring}
    //           >
    //             Delete All Recurring Jobs
    //           </button>
    //           <button
    //             className=" bg-gray-900 font-semibold rounded-md p-2 mt-1 text-red-300 text-md ml-2 hover:text-red-200 shadow-md w-1/2 h-16"
    //             onClick={deleteJob}
    //           >
    //             Delete Only This Job
    //           </button>
    //         </div>
    //         <div className="flex flex-row items-center mb-4">
    //           <button
    //             className="border-2 border-gray-300 bg-gray-50 rounded-md p-2 mt-1 text-gray-500 shadow-md text-md"
    //             onClick={toggleModal}
    //           >
    //             Cancel
    //           </button>
    //         </div>
    //       </>
    //     ) : (
    //       <>
    //         <div className="mt-5">
    //           <h1 className="text-2xl font-semibold">Are you sure?</h1>
    //           <h1 className="mt-2">Your job data will be lost</h1>
    //         </div>
    //         <div className="flex flex-row justify-end items-center mb-4">
    //           <button
    //             className="border-2 border-gray-300 bg-gray-50 rounded-md p-2 mt-1 text-gray-500 text-md"
    //             onClick={toggleModal}
    //           >
    //             Cancel
    //           </button>
    //           <button
    //             className=" bg-gray-900 font-semibold rounded-md p-2 mt-1 text-red-300 text-md ml-2 hover:bg-gray-800"
    //             onClick={deleteJob}
    //           >
    //             Delete Job
    //           </button>
    //         </div>
    //       </>
    //     )}
    //   </div>
    // </div>
  );
}

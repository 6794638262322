import React, { useRef, useEffect, useState } from "react";

export default function ExpandableTextArea({
  value,
  setValue,
  label,
  ...props
}) {
  const textAreaRef = useRef(null);
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (textAreaRef?.current) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.current.style.height = "0px";
      const scrollHeight = textAreaRef.current.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      className="relative flex-1 flex flex-col mt-5"
      ref={containerRef}
      {...props}
    >
      <textarea
        className={`
          text-gray-900 pt-4 pb-3 px-3
          bg-gray-50
          border-b-custom-b border-gray-400
          flex flex-col overflow-hidden resize-none
          rounded-t outline-none
          text-base font-normal
          w-full placeholder:text-gray-500
        `}
        placeholder={label}
        ref={textAreaRef}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
      />
      <div
        className={`
          absolute bottom-0 left-0 right-0
          h-[2px] bg-yellow-200
          transform origin-center
          transition-transform duration-300 ease-in-out
          ${isFocused ? "scale-x-100" : "scale-x-0"}
        `}
      ></div>
    </div>
  );
}

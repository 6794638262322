import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const DeleteButton = ({
  onClick = () => {},
  disabled = false,
  loading = false,
  text,
  fontSize = "text-md",
  fontWeight = "font-medium",
  spinnerColor = "#fefce8",
  spinnerSize = 20,
  additionalClasses = "",
  width = "w-32",
  height = "h-10",
  icon = null,
  iconPosition = "left",
}) => {
  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={`flex justify-center items-center text-red-200 ${
        loading ? "bg-gray-700" : "hover:text-red-400 bg-gray-900"
      }  ${width} rounded-md ${height} ${fontSize} ${fontWeight} shadow-md ${additionalClasses}`}
    >
      {loading ? (
        <CircularProgress size={spinnerSize} style={{ color: spinnerColor }} />
      ) : (
        <>
          {icon && iconPosition === "left" && (
            <span className="mr-2">{icon}</span>
          )}
          {text}
          {icon && iconPosition === "right" && (
            <span className="ml-2">{icon}</span>
          )}
        </>
      )}
    </button>
  );
};

export default DeleteButton;

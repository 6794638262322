import { db } from "../../utils/firebase";
import {
  collection,
  query,
  doc,
  updateDoc,
  getDocs,
  getDoc,
  where,
  serverTimestamp,
  arrayUnion,
  setDoc,
  writeBatch,
} from "firebase/firestore";

export const uploadTags = ({ userData, tags }) => {
  // we also want to upload any new tags to the database...
  const tagsRef = doc(db, "businesses", userData.bizData.id, "tags", "tags"); // reference to the tags doc for this business
  // merge the new tags with the existing tags in firestore
  setDoc(tagsRef, { tags: arrayUnion(...tags) }, { merge: true }); // we don't need to await this, it's just a fire and forget operation
};

export async function updateDetails({ userData, jobDetails, updatedCustomerData }) {
  if (!jobDetails) return;

  let jobRef;
  if (jobDetails.type === "estimate") {
    jobRef = doc(
      db,
      "businesses",
      userData.userData.businessId,
      "estimates",
      jobDetails.estimateId
    );
  } else {
    jobRef = doc(db, "businesses", userData.userData.businessId, "jobs", jobDetails.jobId);
  }

  if (jobDetails?.isRecurring && jobDetails?.recurrenceId) {
    // Update all recurring jobs
    const dbQuery = query(
      collection(db, "businesses", userData.userData.businessId, "jobs"),
      where("recurrenceId", "==", jobDetails.recurrenceId)
    );
    const querySnapshot = await getDocs(dbQuery);
    // Check if there are any documents to update, if there are then we batch update all of them
    if (querySnapshot.empty) {
      throw new Error("No documents found with the provided recurrenceId.");
    }
    const batch = writeBatch(db);
    querySnapshot.forEach((docSnapshot) => {
      const docRef = doc(db, "businesses", userData.userData.businessId, "jobs", docSnapshot.id);
      batch.update(docRef, {
        "customer.firstName": updatedCustomerData.firstName,
        "customer.lastName": updatedCustomerData.lastName,
        "customer.displayName": updatedCustomerData.displayName,
        "customer.phone": updatedCustomerData.phone,
        "customer.email": updatedCustomerData.email,
        "customer.address": updatedCustomerData.address,
        "customer.notes": updatedCustomerData.notes,
        "customer.notifications": updatedCustomerData.notifications,
        "customer.attachments": updatedCustomerData.attachments,
        "customer.tags": updatedCustomerData.tags,
        "customer.lastUpdated": serverTimestamp(),
      });
    });
    // Commit the batch
    await batch.commit();
    console.log("batch update complete");
  } else {
    // means it's just a single job so we just update the jobdoc

    await updateDoc(jobRef, {
      "customer.firstName": updatedCustomerData.firstName,
      "customer.lastName": updatedCustomerData.lastName,
      "customer.displayName": updatedCustomerData.displayName,
      "customer.phone": updatedCustomerData.phone,
      "customer.email": updatedCustomerData.email,
      "customer.address": updatedCustomerData.address,
      "customer.notes": updatedCustomerData.notes,
      "customer.notifications": updatedCustomerData.notifications,
      "customer.attachments": updatedCustomerData.attachments,
      "customer.tags": updatedCustomerData.tags,
      "customer.lastUpdated": serverTimestamp(),
    });
  }
}

// AddOrReplacePaymentMethodModal.js
import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { node } from "constants/constants";
import { useUserContext } from "context/UserContext";
import { db } from "utils/firebase";
import { updateDoc, doc, collection } from "firebase/firestore";
import { useSnackBar } from "context/SnackBarContext";
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import { createStripeCustomer } from "utils/stripeConnect";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { OutlineButton } from "../NewButtons/OutlineButton";
import { SolidButton } from "../NewButtons/SolidButton";

export default function AddOrReplacePaymentMethodModal({
  open,
  onClose,
  jobDetails = null,
  setJobDetails = () => {},
  customerDetails = null,
  setCustomerDetails = () => {},
}) {
  const { userData } = useUserContext();
  // const stripe = useStripe();
  // const elements = useElements();
  const [stripePromise, setStripePromise] = useState(null);
  // loads stripe promise on userData change
  useEffect(() => {
    if (!userData) return;
    if (!userData?.bizData?.stripeAccountId) return;
    setStripePromise(
      loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: userData?.bizData?.stripeAccountId,
      })
    );
  }, [userData]);
  const { openSnackBar } = useSnackBar();
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const businessId = userData?.bizData?.id;

  const isThereAttachedPaymentMethod =
    jobDetails?.customer?.stripePaymentMethod?.id ||
    customerDetails?.stripePaymentMethod?.id
      ? true
      : false;

  const customerId =
    jobDetails?.customer?.customerId || customerDetails?.customerId;

  // we need to fallback to "undefinedCustomer" or else it will throw an error if customerId is undefined
  const customerRef = doc(
    db,
    "businesses",
    businessId,
    "customers",
    customerId || "undefinedCustomer"
  );

  // this will be ran on submit
  const addOrReplacePaymentMethod = async ({ e, stripe, elements }) => {
    // console.log("add or replace payment method");
    e.preventDefault();
    try {
      setLoading(true);
      // data from business
      const stripeAccountId = userData?.bizData?.stripeAccountId;
      // data from jobDetails or customerDetails

      let stripeCustomerId =
        jobDetails?.customer?.stripeCustomerId ||
        customerDetails?.stripeCustomerId;

      const stripePaymentMethodId =
        jobDetails?.customer?.stripePaymentMethod?.id ||
        customerDetails?.stripePaymentMethod?.id;

      if (!stripeAccountId) {
        throw new Error(
          "Please finish connecting your stripe account before you can add a payment method."
        );
      }

      if (stripeCustomerId && stripePaymentMethodId) {
        // means we are replacing the payment method..
        // so we need to detach it first and then add and attach the new one
        await removePaymentMethod({ stripePaymentMethodId, stripeAccountId });
        await createAndAttachPaymentMethod({
          stripeCustomerId,
          stripeAccountId,
          stripe,
          elements,
        });

        // open snackbar
        openSnackBar("Payment method updated successfully", true);
        onClose();
        return;
      }

      if (!stripeCustomerId) {
        // means we haven't even created a stripe customer for this customer yet, so we need to do this before doing anything next
        const result = await createAndSaveStripeCustomer();
        stripeCustomerId = result.stripeCustomerId;
      }

      if (!stripePaymentMethodId) {
        // means that we are adding it for the first time
        // we should always have a stripeCustomerId at this point
        await createAndAttachPaymentMethod({
          stripeCustomerId,
          stripeAccountId,
          stripe,
          elements,
        });
      }

      openSnackBar("Payment method added successfully", true);
      onClose();
    } catch (error) {
      console.log("catch block error", error?.message);
      setErrorMessage(
        error.message || "Failed to add the payment method, please try again."
      );
      openSnackBar(
        "Failed to add the payment method, please try again.",
        false,
        true
      );
    } finally {
      setLoading(false);
    }
  };

  const createAndSaveStripeCustomer = async () => {
    // console.log("creating stripe customer");
    try {
      const customerData = jobDetails?.customer || customerDetails;
      const stripeAccountId = userData?.bizData?.stripeAccountId;

      if (!customerData || !stripeAccountId) {
        // show an alert message bc the required data is missing
        alert("Required data is missing");
        return;
      }

      // backend function updates the customer doc already
      const result = await createStripeCustomer({
        customerData,
        stripeAccountId,
      });

      if (result.error) {
        throw new Error(result.error);
      }

      const stripeCustomerId = result.stripeCustomerId;
      // console.log("stripeCustomerId", stripeCustomerId);

      // update the job doc in firestore if we are coming from jobDetails
      if (jobDetails?.jobId) {
        const jobRef = doc(
          db,
          "businesses",
          businessId,
          "jobs",
          jobDetails.jobId
        );
        await updateDoc(jobRef, {
          "customer.stripeCustomerId": stripeCustomerId,
        });

        // update the local state
        setJobDetails((prev) => ({
          ...prev,
          customer: {
            ...prev.customer,
            stripeCustomerId: stripeCustomerId,
          },
        }));
      }

      // update local customerDetails state if we are coming from customerDetails
      if (customerDetails?.customerId) {
        setCustomerDetails((prev) => ({
          ...prev,
          stripeCustomerId: stripeCustomerId,
        }));
      }

      return { stripeCustomerId };
    } catch (error) {
      console.log("catch block error", error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const createAndAttachPaymentMethod = async ({
    stripeCustomerId,
    stripeAccountId,
    stripe,
    elements,
  }) => {
    console.log("create and attach payment method");
    try {
      if (!stripe || !elements) {
        throw new Error("Stripe is not initialized");
      }

      // create card element to retrieve payment method details ?
      const cardElement = elements.getElement(CardElement);

      // create payment method
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      // attach payment method to stripe customer
      const response = await fetch(
        `${node}/connectCustomers/attach-payment-method`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stripeCustomerId,
            stripeAccountId,
            stripePaymentMethodId: paymentMethod.paymentMethod.id,
          }),
        }
      );

      if (!response.ok) {
        const { message } = await response.json();
        throw new Error(message || "Failed to add the payment method");
      }

      // update the customer object in firestore
      await updateDoc(customerRef, {
        stripePaymentMethod: paymentMethod.paymentMethod,
      });

      // console.log("updated customer doc in firestore");

      // update the job doc in firestore if we are coming from jobDetails
      if (jobDetails?.jobId) {
        const jobRef = doc(
          db,
          "businesses",
          businessId,
          "jobs",
          jobDetails.jobId
        );
        await updateDoc(jobRef, {
          "customer.stripePaymentMethod": paymentMethod.paymentMethod,
        });

        // update the local state
        setJobDetails((prev) => ({
          ...prev,
          customer: {
            ...prev.customer,
            stripePaymentMethod: paymentMethod.paymentMethod,
          },
        }));
      }

      // update local customerDetails state if we are coming from customerDetails
      if (customerDetails?.customerId) {
        // console.log("setting customer details state");
        setCustomerDetails((prev) => ({
          ...prev,
          stripePaymentMethod: paymentMethod.paymentMethod,
        }));
      }
    } catch (error) {
      console.log("catch block error", error);
      throw new Error(error);
    }
  };

  const removePaymentMethod = async ({
    stripePaymentMethodId,
    stripeAccountId,
  }) => {
    // console.log("removing payment method");
    try {
      const response = await fetch(
        `${node}/connectCustomers/remove-payment-method`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stripePaymentMethodId,
            stripeAccountId,
          }),
        }
      );
      if (!response.ok) {
        const { message } = await response.json();
        throw new Error(message || "Failed to remove the payment method");
      }

      // update the customer object in firestore --> we do this in all cases..
      await updateDoc(customerRef, {
        stripePaymentMethod: null,
      });

      // console.log("updated customer doc in firestore");
      // update the job doc in firestore if we are coming from jobDetails
      // if (jobDetails?.jobId) {
      //   const jobRef = doc(
      //     db,
      //     "businesses",
      //     businessId,
      //     "jobs",
      //     jobDetails.jobId
      //   );
      //   await updateDoc(jobRef, {
      //     "customer.stripePaymentMethod": null,
      //   });

      //   // update the local state
      //   setJobDetails((prev) => ({
      //     ...prev,
      //     customer: {
      //       ...prev.customer,
      //       stripePaymentMethod: null,
      //     },
      //   }));
      // }

      // update local customerDetails state if we are coming from customerDetails
      // I think this is messing things up
      // if (customerDetails?.customerId) {
      //   console.log("setting customer details state");
      //   setCustomerDetails((prev) => ({
      //     ...prev,
      //     stripePaymentMethod: null,
      //   }));
      // }
    } catch (error) {
      console.log("catch block error", error);
      throw new Error(error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[645px] bg-white shadow-bold p-8 py-8 rounded-md">
        <h2 className="text-xl font-medium mx-2">
          {isThereAttachedPaymentMethod
            ? "Replace Saved Card"
            : "Add Card On File"}
        </h2>
        <Elements stripe={stripePromise}>
          <NormalStripeCardForm
            isProcessing={loading}
            handleSubmit={addOrReplacePaymentMethod}
            errorMessage={errorMessage}
            onClose={onClose}
            isThereAttachedPaymentMethod={isThereAttachedPaymentMethod}
            // stripe={stripe}
          />
        </Elements>
        {/* <div className="flex flex-row items-center  gap-4 mt-6">

          <NormalButton
            onClick={addOrReplacePaymentMethod}
            loading={loading}
            text="Save"
          />
        </div> */}
      </div>
    </Modal>
  );
}

const NormalStripeCardForm = ({
  handleSubmit,
  isProcessing,
  // stripe,
  errorMessage,
  onClose,
  isThereAttachedPaymentMethod,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardComplete, setCardComplete] = useState(false);
  const options = {
    style: {
      base: {
        iconColor: "#0f172a",
        color: "#1e293b",
        fontWeight: "400",
        fontFamily: "Inter Var, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#1e293b",
        },
        "::placeholder": {
          color: "#94a3b8",
        },
      },
      invalid: {
        iconColor: "#ef4444",
        color: "#ef4444",
      },
    },
    disableLink: true,
  };

  // console.log("cardComplete", cardComplete);

  return (
    <form onSubmit={(e) => handleSubmit({ e, stripe, elements })}>
      <CardElement
        className="p-4  shadow-md ring-1 ring-gray-500 rounded-md my-5 mb-7 mx-2 "
        options={options}
        onChange={(e) => {
          setCardComplete(e.complete);
        }}
      />
      {!isThereAttachedPaymentMethod && (
        <p className="text-gray-500 text-xs mx-2 mb-6">
          By clicking "Save Card" you acknowledge that you have received written
          or verbal consent from the cardholder to save and charge the card
          listed above.
        </p>
      )}

      {errorMessage && (
        <div className="text-red-500 text-sm mx-2 mb-2">{errorMessage}</div>
      )}
      <div className="flex flex-row gap-4 mx-2">
        <OutlineButton
          onClick={onClose}
          disabled={isProcessing}
          text={"Cancel"}
          fullWidth
        />

        {/* buttons automatically submit forms without having to change them to type submit */}
        <SolidButton
          text="Save Card"
          loading={isProcessing}
          disabled={!stripe || !cardComplete}
          fullWidth
        />
      </div>
    </form>
  );
};

import React, { useState, useEffect } from "react";
import { BiSearchAlt2 } from "react-icons/bi";

// const employeeList = [
//   { id: 1, name: "John Doe" },
//   { id: 2, name: "Jane Smith" },
//   { id: 3, name: "Bob Johnson" },
//   { id: 4, name: "Alice Williams" },
// ]

export default function EmployeeSearch({
  data,
  employees,
  employeeList,
  setDispatchedTo,
  label,
  modal = false,
  ...props
}) {
  const [inputValue, setInputValue] = useState("");

  const [selectedEmployee, setSelectedEmployee] = useState(employees ? employees : []);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSelect = (employee) => {
    setSelectedEmployee((prev) => [...prev, employee]);
    setInputValue("");

    // console.log("selecting employee", employee)

    setDispatchedTo([...selectedEmployee, employee]);
  };

  // Filter the employee based on the user's input
  const filteredEmployee =
    employeeList &&
    employeeList.length > 0 &&
    employeeList.filter((employee) => {
      const isEmployeeSelected = selectedEmployee.some(
        (selectedEmp) => selectedEmp.id === employee.id
      );
      return (
        !isEmployeeSelected && employee.firstName.toLowerCase().includes(inputValue.toLowerCase())
      );
    });

  return (
    <div className="relative flex-1 flex flex-row " {...props}>
      <input
        className={`bg-gray-50 border-2 h-12 border-gray-300 ${
          selectedEmployee.length > 0 ? "w-1/2" : "w-full"
        } rounded-md outline-none focus:border-gray-900 text-lg py-2 pl-9`}
        value={inputValue}
        placeholder={label}
        onChange={handleChange}
      />
      {selectedEmployee && selectedEmployee.length > 0 && (
        <div className="relative pl-2 flex flex-row w-1/2 flex-wrap items-center gap-2">
          {selectedEmployee.map((employee) => (
            <div
              className="flex flex-row items-center gap-2 px-2 py-3 bg-gray-800 rounded-md"
              key={employee.id}
            >
              <span className="font-semibold text-sm text-yellow-100">
                {employee.firstName + " " + employee.lastName}
              </span>
              <button
                className="text-white hover:text-red-400"
                onClick={() => {
                  setSelectedEmployee(selectedEmployee.filter((item) => item.id !== employee.id));

                  setDispatchedTo(selectedEmployee.filter((item) => item.id !== employee.id));
                }}
              >
                x
              </button>
            </div>
          ))}
        </div>
      )}
      <BiSearchAlt2
        className={`absolute top-3 left-2 text-2xl text-gray-400  ${inputValue && "text-gray-600"}`}
      />
      {inputValue && (
        <div
          className="absolute z-50 w-1/2 mt-1 bg-white rounded-md shadow-lg"
          style={{
            top: "45px",
            left: "0px",
          }}
        >
          <ul>
            {filteredEmployee && filteredEmployee.length > 0 ? (
              filteredEmployee.map((employee) => (
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100 hover:rounded-md"
                  key={employee.id}
                  onClick={() => handleSelect(employee)}
                >
                  {employee.firstName + " " + employee.lastName}
                </li>
              ))
            ) : (
              <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">No employee found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

import {
  format,
  utcToZonedTime,
  zonedTimeToUtc,
  formatInTimeZone,
} from "date-fns-tz";
import { isBefore, isAfter } from "date-fns";

export function determineIfStarted() {
  // Set the target date and time in Central Time
  const targetDateString = "2024-08-15T00:00:00"; // Start of competition // switch to 8-15 when ready
  const timeZone = "America/Chicago";
  const targetDateCentralTime = zonedTimeToUtc(targetDateString, timeZone);
  const endDateString = "2024-09-14T23:59:59"; // End of competition // switch to 9-15 when ready
  const endDateCentralTime = zonedTimeToUtc(endDateString, timeZone);

  // Now, get the current date/time in UTC
  const now = new Date();
  const nowInCentralTime = utcToZonedTime(now, timeZone); // Convert current UTC time to Central Time for an accurate comparison

  // Has the competition started or ended?
  const isStarted = isAfter(nowInCentralTime, targetDateCentralTime);
  const hasEnded = isAfter(nowInCentralTime, endDateCentralTime);

  // Format the dates for display
  // const formattedStart = format(
  //   targetDateCentralTime,
  //   "MMMM d '@' h:mm a 'CST'"
  // );
  // const formattedEnd = format(endDateCentralTime, "MMMM d '@' h:mm a 'CST'");

  // Format dates for display in Central Time
  const formattedStart = formatInTimeZone(
    targetDateCentralTime,
    timeZone,
    "MMMM d '@' h:mm a 'CST'"
  );
  const formattedEnd = formatInTimeZone(
    endDateCentralTime,
    timeZone,
    "MMMM d '@' h:mm a 'CST'"
  );

  return {
    isStarted,
    hasEnded,
    startDate: targetDateCentralTime,
    endDate: endDateCentralTime,
    formattedStart,
    formattedEnd,
  };
}

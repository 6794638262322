// InvoiceOptions.jsx
import React from "react";
import { Checkbox } from "components/ui/checkbox";

const InvoiceOptions = ({ invoiceSettings, setInvoiceSettings }) => {
  const handleCheckboxChange = (field) => {
    setInvoiceSettings((prevSettings) => ({
      ...prevSettings,
      [field]: !prevSettings[field],
    }));
  };

  return (
    <div className="px-4">
      <h2 className="text-xl font-bold mb-4">Logo</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.logo}
          onCheckedChange={() => handleCheckboxChange("logo")}
        />
        <span className="ml-2">Display Logo</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Invoice Details</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.invoiceNumber}
          onCheckedChange={() => handleCheckboxChange("invoiceNumber")}
        />
        <span className="ml-2">Invoice Number</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Customer Details</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.customerDisplayName}
          onCheckedChange={() => handleCheckboxChange("customerDisplayName")}
        />
        <span className="ml-2">Display Name</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.customerAddress}
          onCheckedChange={() => handleCheckboxChange("customerAddress")}
        />
        <span className="ml-2">Address</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.customerEmail}
          onCheckedChange={() => handleCheckboxChange("customerEmail")}
        />
        <span className="ml-2">Email</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.customerPhone}
          onCheckedChange={() => handleCheckboxChange("customerPhone")}
        />
        <span className="ml-2">Phone number</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Business Details</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.businessName}
          onCheckedChange={() => handleCheckboxChange("businessName")}
        />
        <span className="ml-2">Business Name</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.businessEmail}
          onCheckedChange={() => handleCheckboxChange("businessEmail")}
        />
        <span className="ml-2">Email</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.businessPhone}
          onCheckedChange={() => handleCheckboxChange("businessPhone")}
        />
        <span className="ml-2">Phone</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.businessAddress}
          onCheckedChange={() => handleCheckboxChange("businessAddress")}
        />
        <span className="ml-2">Address</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Line Items</h2>

      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.lineItemsDescription}
          onCheckedChange={() => handleCheckboxChange("lineItemsDescription")}
        />
        <span className="ml-2">Description</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.lineItemsQuantity}
          onCheckedChange={() => handleCheckboxChange("lineItemsQuantity")}
        />
        <span className="ml-2">Quantity</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.lineItemsUnitPrice}
          onCheckedChange={() => handleCheckboxChange("lineItemsUnitPrice")}
        />
        <span className="ml-2">Unit Price</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.lineItemsAmount}
          onCheckedChange={() => handleCheckboxChange("lineItemsAmount")}
        />
        <span className="ml-2">Amount</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Total</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.subtotal}
          onCheckedChange={() => handleCheckboxChange("subtotal")}
        />
        <span className="ml-2">Subtotal</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.tax}
          onCheckedChange={() => handleCheckboxChange("tax")}
        />
        <span className="ml-2">Tax</span>
      </label>
      <h2 className="text-xl font-bold mt-6 mb-4">Footer</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.footerBusinessName}
          onCheckedChange={() => handleCheckboxChange("footerBusinessName")}
        />
        <span className="ml-2">Business Name</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.footerBusinessWebsite}
          onCheckedChange={() => handleCheckboxChange("footerBusinessWebsite")}
        />
        <span className="ml-2">Website</span>
      </label>
      <h2 className="text-xl font-bold mt-6 mb-4">Payment</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.savePaymentMethod}
          onCheckedChange={() => handleCheckboxChange("savePaymentMethod")}
        />
        <span className="ml-2">Save Card On File</span>
      </label>
    </div>
  );
};

export default InvoiceOptions;

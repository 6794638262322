import React from "react";
import InformationIconTooltip from "components/reusable/InformationIconTooltip";
import CustomFileInput from "components/reusable/CustomFileInput";

export default function TemplateAttachments({
  templateDetails,
  setTemplateDetails,
}) {
  //   console.log("templateDetails", templateDetails);
  console.log("templateDetails.attachments", templateDetails.attachments);
  const handleFileChange = (e) => {
    console.log(e.target.files);
    const files = Array.from(e.target.files);
    const newAttachments = files.map((file, index) => {
      return {
        file: file,
        name: file.name,
      };
    });

    // Update the state with new and existing attachments
    // setAttachments([...attachments, ...newAttachments]);
    const oldAttachments = templateDetails.attachments || [];
    setTemplateDetails({
      ...templateDetails,
      attachments: [...oldAttachments, ...newAttachments],
    });
  };

  const removeAttachment = (index) => {
    // setAttachments(attachments.filter((_, i) => i !== index));
    setTemplateDetails({
      ...templateDetails,
      attachments: templateDetails.attachments.filter((_, i) => i !== index),
    });
  };
  return (
    <>
      <div className="flex flex-row items-center mt-8">
        <h2 className="text-lg mr-2 font-medium">Attachments</h2>
        <InformationIconTooltip
          text="Select any attachment that you would like to be included in the email that is sent to the customer when they confirm their subscription."
          className="ml-2"
        />
      </div>

      <div className="mt-6 flex flex-col w-full max-w-md">
        <div className="">
          <CustomFileInput
            handleFileChange={handleFileChange}
            attachments={templateDetails.attachments || []}
            removeAttachment={removeAttachment}
            textColor="text-gray-500"
            backgroundColor="bg-gray-50"
          />
        </div>
      </div>
    </>
  );
}

import React, { useRef } from "react";

import CancelIcon from "@mui/icons-material/Cancel";

export default function DisplayAttachments({ attachments }) {
  return (
    <div className="flex flex-col">
      {attachments &&
        attachments?.length > 0 &&
        attachments.map((attachment, index) => (
          <div key={index} className="relative flex flex-row items-center m-1">
            <button
              className="mr-4 hover:underline"
              onClick={() => window.open(attachment.url)}
            >
              {attachment.name}
            </button>

            {/* Remove file button */}
          </div>
        ))}
    </div>
  );
}

import React, { useState, useEffect, useContext, useRef } from "react";
import SearchInputNavBar from "./SearchInputNavBar";
import { useUserContext } from "context/UserContext";
import { useNavigate } from "react-router-dom";
import algoliasearch from "algoliasearch";

export default function AlgoliaSearch() {
  const { user, userData } = useUserContext();
  const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY
  );
  const index = client.initIndex("customers");
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const navigate = useNavigate();
  const searchInputRef = useRef(null);

  const handleSelect = (customer) => {
    // console.log("handleSelect (from algolia)", customer);
    setSelected(customer);
    setInputValue("");
    navigate(`/customers/${customer.customerId}`);
  };

  const handleAlgoliaSearch = async () => {
    if (!inputValue) return;
    if (loadingCustomers) return;
    // console.log("handleAlgoliaSearch");
    // console.log("inputValue", inputValue);
    const businessId = userData.userData.businessId;

    try {
      setLoadingCustomers(true);
      const { hits } = await index.search(inputValue, {
        filters: `businessId:${businessId}`,
        hitsPerPage: 5,
      });
      // console.log("hits", hits);
      setCustomers(hits);
    } catch (err) {
      console.error("Error with search", err);
    } finally {
      setLoadingCustomers(false);
    }
  };

  useEffect(() => {
    if (!inputValue) {
      setCustomers([]);
      return;
    }
    handleAlgoliaSearch();
  }, [inputValue]);

  // useEffect that listens to clicks outside of the search input
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target)
      ) {
        setInputValue("");
        setCustomers([]);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchInputRef]);

  return (
    <div
      className=" flex flex-row text-gray-700 items-center"
      ref={searchInputRef}
    >
      <SearchInputNavBar
        label={"Search"}
        inputValue={inputValue}
        setInputValue={setInputValue}
        selected={selected}
        setSelected={setSelected}
        handleSelect={handleSelect}
        customers={customers}
        loadingCustomers={loadingCustomers}
      />
    </div>
  );
}

import React from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordionExtraInputCustomerModal";
import InputMUI from "components/reusable/InputMUI";

export default function ExtraEmailAccordion({
  additionalEmail,
  setAdditionalEmail,
}) {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item-1">
        <AccordionTrigger>Additional Email</AccordionTrigger>
        <AccordionContent>
          <div className="w-full py-2">
            <InputMUI
              label="Additional Email"
              value={additionalEmail}
              setValue={setAdditionalEmail}
            />
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

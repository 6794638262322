import clsx from "clsx";
import { useState } from "react";

import { BsExclamation } from "react-icons/bs";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const formClasses =
  "block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-yellow-500 focus:bg-white focus:outline-none focus:ring-yellow-500 sm:text-sm mt-1";

function Label({ id, children }) {
  return (
    <label
      htmlFor={id}
      className="mb-3 block text-sm font-medium text-gray-700"
    >
      {children}
    </label>
  );
}

export function PhoneField({
  id,
  label,
  type = "tel",
  name,
  placeholder,
  className = "",
  ...props
}) {
  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    if (e.currentTarget.value.includes(" ")) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
    }
  };
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <input
        id={id}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        type={type}
        name={name}
        placeholder={placeholder}
        {...props}
        className={formClasses}
      />
    </div>
  );
}

export function TextField({
  id,
  label,
  type = "text",
  className = "",
  inputClassName = "",
  ...props
}) {
  return (
    <div className={className}>
      {label && <label id={id}>{label}</label>}
      <input
        id={id}
        type={type}
        {...props}
        className={`${formClasses} ${inputClassName}`}
      />
    </div>
  );
}

export function PasswordLogin({
  id,
  label,
  type,
  className = "",
  error,
  togglePassword,
  ...props
}) {
  return (
    <div>
      <label
        htmlFor="password"
        id={id}
        className="block text-sm font-medium text-gray-700 "
      >
        Password
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          id={id}
          {...props}
          type={type}
          name="password"
          className="row-auto block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-yellow-500 focus:bg-white focus:outline-none focus:ring-yellow-500 sm:text-sm"
        />
        <div className=" absolute inset-y-0 right-0 flex items-center pr-3">
          <AiOutlineEye
            onClick={togglePassword}
            className="cursor h-5 w-5 cursor-pointer text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
      {error && (
        <div className="row-auto flex items-center gap-2 align-middle">
          <BsExclamation
            className="mt-1 h-5 w-5 text-red-500"
            aria-hidden="true"
          />{" "}
          <p className=" mt-1 text-sm text-red-600" id="email-error">
            Incorrect password.
          </p>
        </div>
      )}
    </div>
  );
}

export function PasswordRegister({
  id,
  label,
  type,
  className = "",
  error,
  togglePassword,
  passwordShown,
  ...props
}) {
  return (
    <div className={className}>
      <label htmlFor="password" id={id} className="block text-gray-900">
        Password
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          id={id}
          {...props}
          type={type}
          name="password"
          className="row-auto block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-yellow-500 focus:bg-white focus:outline-none focus:ring-yellow-500 sm:text-sm"
        />
        <div className=" absolute inset-y-0 right-0 flex items-center pr-3">
          {passwordShown ? (
            <AiOutlineEyeInvisible
              onClick={togglePassword}
              className="cursor h-5 w-5 cursor-pointer text-gray-400"
              aria-hidden="true"
            />
          ) : (
            <AiOutlineEye
              onClick={togglePassword}
              className="cursor h-5 w-5 cursor-pointer text-gray-400"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export function SelectField({ id, label, className = "", ...props }) {
  return (
    <div className={className}>
      {label && <label id={id}>{label}</label>}
      <select id={id} {...props} className={clsx(formClasses, "pr-8")} />
    </div>
  );
}

export function AuthLayout({ children }) {
  return (
    <>
      <div className="relative flex min-h-full h-screen justify-center overflow-hidden ">
        <div className="relative z-10 flex flex-1 flex-col bg-white py-10 px-4 shadow-2xl sm:justify-center md:flex-none md:px-28">
          <div className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
            {children}
          </div>
        </div>
        <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/images/background-auth.jpg"
            alt="background-image"
          />
        </div>
      </div>
    </>
  );
}

export function PasswordGeneral({
  id,
  label = "Password",
  className = "",
  error,
  ...props
}) {
  const [passwordShown, setPasswordShown] = useState(false);
  const type = passwordShown ? "text" : "password";
  return (
    <div className={className}>
      <label htmlFor="password" id={id} className="block text-gray-900">
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          id={id}
          {...props}
          type={type}
          name="password"
          className="row-auto block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-yellow-500 focus:bg-white focus:outline-none focus:ring-yellow-500 sm:text-sm"
        />
        <div className=" absolute inset-y-0 right-0 flex items-center pr-3">
          {passwordShown ? (
            <AiOutlineEyeInvisible
              onClick={() => setPasswordShown(false)}
              className="cursor h-5 w-5 cursor-pointer text-gray-400"
              aria-hidden="true"
            />
          ) : (
            <AiOutlineEye
              onClick={() => setPasswordShown(true)}
              className="cursor h-5 w-5 cursor-pointer text-gray-400"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { convertToJsDate } from "utils/helpers";
import { format, addYears } from "date-fns";
import { splitAddress } from "utils/helpers";
import UpcomingInvoiceDisplay from "../SubDetails/UpcomingInvoiceDisplay";
import { formatPhoneDisplay } from "utils/formatPhone";
const frequencyMap = {
  monthly: "month",
  yearly: "year",
  quarterly: "quarter",
  "6 months": "6 months",
};

export default function RenderSuccess({ subscription, customer, business }) {
  // const addressSplit = splitAddress(customer?.address?.[0] || "");
  const nextPaymentDate = () => {
    const startDate = new Date(convertToJsDate(subscription.startDate));
    const today = new Date();
    const frequency = subscription.template.paymentFrequency;

    if (today < startDate) {
      return format(startDate, "MMMM do, yyyy");
    }

    // this needs work.. because we need to find the next payment date based on the frequency and the current date... we may need to add more than 1 frequency to the startDate to get the next payment date... next payment date should be the closest date in the future that part of the recurring frequency
    let nextDate = new Date(startDate);
    while (nextDate <= today) {
      switch (frequency) {
        case "monthly":
          nextDate.setMonth(nextDate.getMonth() + 1);
          break;
        case "quarterly":
          nextDate.setMonth(nextDate.getMonth() + 3);
          break;
        case "6 months":
          nextDate.setMonth(nextDate.getMonth() + 6);
          break;
        case "yearly":
          nextDate.setFullYear(nextDate.getFullYear() + 1);
          break;
        default:
          return "Invalid frequency";
      }
    }
    return format(nextDate, "MMMM do, yyyy");
  };

  const formatDuration = (type, duration, startDate) => {
    const startDateJs = convertToJsDate(subscription.startDate);
    if (type === "fixed") {
      // calculate end date
      // let endDateString = new Date(startDateJs)
      // endDate.setFullYear(endDate.getFullYear() + duration);

      // or
      const endDate = addYears(startDateJs, duration);
      // then we add like 1 or 2 years to it
      return `${format(startDateJs, "MMMM do, yyyy")} - ${format(
        endDate,
        "MMMM do, yyyy"
      )} (${duration} ${duration > 1 ? "years" : "year"})`;
    }
    return `${format(startDateJs, "MMMM do, yyyy")} - until canceled`;
  };
  return (
    <div className="bg-gray-50 min-h-screen w-full flex flex-col items-center px-3 sm:px-6 py-8 sm:py-16">
      <div className="w-full max-w-[700px] font-stripe">
        <div className="text-center items-center flex flex-col">
          <h1 className="text-2xl sm:text-3xl font-semibold text-gray-600">
            Success!
          </h1>
          <div className="flex flex-row items-center mt-4">
            <p className="text-gray-500 text-sm sm:text-base">
              Your subscription is now active.
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full rounded-lg bg-gray-25 py-4 sm:py-6 px-4 sm:px-6 mt-4 shadow-md sm:shadow-2xl mb-8 sm:mb-16">
          <div className="flex flex-row items-center justify-between">
            <h1 className="text-gray-500 text-sm sm:text-base">
              Subscription Details
            </h1>
            <div className="flex flex-row items-center gap-2 rounded-md px-2 py-1 bg-green-100 text-green-600 border-green-200">
              <p className=" text-sm sm:text-base ">
                {subscription.status.slice(0, 1).toUpperCase() +
                  subscription.status.slice(1)}
              </p>
              <p className="bg-green-400 rounded-full p-1 w-2 h-2" />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-4 sm:mt-6 px-4 sm:px-6 py-4 bg-gray-100 -mx-4 sm:-mx-6">
            <div className="flex flex-col pr-4 mb-4 sm:mb-0">
              <h2 className="text-gray-600 font-bold text-lg sm:text-xl">
                {subscription.template.title}
              </h2>
              <p className="text-gray-500 text-sm sm:text-base">
                {subscription.template.description}
              </p>
            </div>
            <div className="flex flex-row items-center">
              <div className="font-medium text-2xl sm:text-3xl">
                ${parseFloat(subscription.template.amount).toFixed(2)}
              </div>
              <div className="flex flex-col ml-2 text-gray-500 text-sm sm:text-base">
                <span className="leading-none">per</span>
                <span className="leading-none">
                  {frequencyMap[subscription.template.paymentFrequency]}
                </span>
              </div>
            </div>
          </div>
          <hr className="border-gray-300 -mx-4 sm:-mx-6" />
          <div className="mt-4 sm:mt-8 space-y-2 sm:space-y-4">
            {/* <div>
              <label className="font-semibold text-sm sm:text-base">
                Next Payment Date
              </label>
              <div className="text-gray-500 text-sm sm:text-base">
                {nextPaymentDate()}
              </div>
            </div> */}

            {subscription.stripeSubscription?.id &&
              subscription.stripeAccountId && (
                <UpcomingInvoiceDisplay
                  stripeSubscriptionId={subscription.stripeSubscription.id}
                  stripeAccountId={subscription.stripeAccountId}
                />
              )}
            <div>
              <label className="font-semibold text-sm sm:text-base">
                Visits
              </label>
              <div className="text-gray-500 text-sm sm:text-base">
                {subscription.template.visits} visits per year
              </div>
            </div>
            <div>
              <label className="font-semibold text-sm sm:text-base">
                Duration
              </label>
              <div className="text-gray-500 text-sm sm:text-base">
                {formatDuration(
                  subscription.template.type,
                  subscription.template.duration,
                  subscription.startDate
                )}
              </div>
            </div>
            {/* {customer?.address?.[0] && (
              <div>
                <label className="font-semibold text-sm sm:text-base">
                  Service Address
                </label>
                <div className="text-gray-500 text-sm sm:text-base">
                  <p>{addressSplit[0]}</p>
                  {addressSplit?.[1] && (
                    <p className="text-wrap">{addressSplit[1]}</p>
                  )}
                </div>
              </div>
            )} */}
            <div>
              <label className="font-semibold text-sm sm:text-base">
                Business Contact
              </label>
              <div className="text-gray-500 text-sm sm:text-base">
                {formatPhoneDisplay(business.companyPhone) ||
                  "No phone number provided"}
              </div>
            </div>

            <div className="mt-12 p-4 bg-sky-50 border-l border-sky-200 rounded-r-md">
              <h4 className="font-semibold text-sm sm:text-base text-sky-800 mb-2">
                Next Steps
              </h4>
              <ul className="list-none text-sm sm:text-base text-sky-700 space-y-2">
                <li className="flex">
                  <span className="mr-2">•</span>
                  <span className="-mt-0.5">
                    {subscription.companyName} will contact you to schedule your
                    visits.
                  </span>
                </li>
                <li className="flex">
                  <span className="mr-2">•</span>
                  <span className="-mt-0.5">
                    For plan updates or cancellations, please contact:
                    <br />
                    <span className="font-medium">
                      {subscription.companyName} at{" "}
                      {formatPhoneDisplay(business.companyPhone) ||
                        "the number provided"}{" "}
                      or {business.email}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { node } from "../constants/constants";
import { UserContext } from "../index";
import axios from "axios";

export default function Reviews() {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [reviews, setReviews] = useState(null);
  const [placeId, setPlaceId] = useState(null);

  //   const testBackend = async () => {
  //     const response = await axios.post(`${node}/google/test`);
  //     console.log(response.data);
  //   };

  //   useEffect(() => {
  //     const code = urlParams.get("code");

  //     if (code) {
  //       axios
  //         .post(`${node}/google/google-auth`, {
  //           code,
  //           businessId: userData.userData.businessId,
  //         })
  //         .then((response) => {
  //           setReviews(response.data.reviews);
  //           setPlaceId(response.data.placeId);
  //           // remove the code from the URL
  //           navigate("/reviews");
  //         })
  //         .catch((err) => console.error(err));
  //     }
  //   }, [navigate, urlParams]);

  //   const connectGoogle = () => {
  //     const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth?";
  //     const queryParams = new URLSearchParams({
  //       client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //       response_type: "code",
  //       scope: "https://www.googleapis.com/auth/business.manage",
  //       redirect_uri: "http://localhost:3000/reviews",
  //       state: "state_parameter_passthrough_value",
  //       access_type: "offline",
  //     });

  //     // redirect the user to Google's OAuth server
  //     window.location = `${googleAuthUrl}${queryParams}`;
  //   };

  return (
    <div className="pt-10 mx-10">
      {/* <button className="bg-green-200 p-2 rounded-md" onClick={connectGoogle}>
        Connect Google My Business
      </button>

      <div className="text-xl font-bold">Place ID: {placeId}</div>
      <div className="text-2xl font-bold">Reviews</div>
      <div className="flex flex-col mx-10">
        {reviews &&
          reviews.map((review) => <div key={review.id}>{review.text}</div>)}
      </div>

      <button className="bg-green-200 p-2 rounded-md" onClick={testBackend}>
        Test backend
      </button> */}
    </div>
  );
}

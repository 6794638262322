import React, { useState, useEffect, useRef, useContext } from "react";
import CustomersList from "./components/CustomerList";
import MarketingMessage from "./components/MarketingMessage";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../index";
import { db } from "../../utils/firebase";
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter,
  getCountFromServer,
} from "firebase/firestore";
import { useSnackBar } from "../../context/SnackBarContext";
import CustomerModalCombinedNew from "components/CustomerModalCombined/CustomerModalCombined";
import { CustomersPageHeader } from "./components/CustomersPageHeader";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";
import CustomersImportModal from "./components/CustomersImport/CustomersImportModal";

export default function Customers() {
  const { user, userData, bannerVisible } = useContext(UserContext);
  const { openSnackBar } = useSnackBar();
  const state = useLocation().state;
  const [customerSearchInput, setCustomerSearchInput] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState(null);

  const [customerCount, setCustomerCount] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [modal, setModal] = useState(state?.newCustomerModal ? true : false);
  const navigate = useNavigate();
  const [rerun, setRerun] = useState(false);

  const [loadingCustomers, setLoadingCustomers] = useState(true);

  const [importModalOpen, setImportModalOpen] = useState(false);

  const notAbleToViewCustomers =
    userData?.userData?.customPermissions?.view_all_customers === false;

  const notAbleToEditCustomers = userData?.userData?.customPermissions?.manage_customers === false;

  const customerCollectionRef = collection(
    db,
    "businesses",
    userData.userData.businessId,
    "customers"
  );

  // New logic for fetching customers
  const [lastVisible, setLastVisible] = useState(null);

  const getCustomersInitial = async () => {
    if (notAbleToViewCustomers) return;
    // console.log("getCustomersInitial");
    setLoadingCustomers(true);

    let customerQuery = query(customerCollectionRef, orderBy("lastUpdated", "desc"), limit(50));
    const customerSnapshot = await getDocs(customerQuery);
    const customerList = customerSnapshot.docs.map((doc) => doc.data());

    if (customerSnapshot.docs.length > 0) {
      setLastVisible(customerSnapshot.docs[customerSnapshot.docs.length - 1]);
    }

    setCustomers(customerList);
    setLoadingCustomers(false);
  };

  const getCustomersNext = async () => {
    if (notAbleToViewCustomers) return;
    // console.log("getCustomersNext");
    // console.log("lastVisible", lastVisible);
    let customerQuery;
    if (lastVisible) {
      customerQuery = query(
        customerCollectionRef,
        orderBy("lastUpdated", "desc"),
        limit(50),
        startAfter(lastVisible)
      );
    } else return;

    const customerSnapshot = await getDocs(customerQuery);

    if (customerSnapshot.docs.length > 0) {
      const customerList = customerSnapshot.docs.map((doc) => doc.data());
      setCustomers([...customers, ...customerList]);
      setLastVisible(customerSnapshot.docs[customerSnapshot.docs.length - 1]);
    } else {
      // console.log("no more customers");
    }
  };

  const getCount = async () => {
    const customerCountSnapshot = await getCountFromServer(customerCollectionRef);
    // console.log("Customer count: ", customerCountSnapshot.data().count);
    setCustomerCount(customerCountSnapshot.data().count);
  };

  // get 50 customers on mount and get count from server
  useEffect(() => {
    getCustomersInitial();
    getCount();
  }, []);

  const toggleModal = () => {
    if (notAbleToEditCustomers) {
      openSnackBar("You do not have the permissions to edit or create customers.");
      return;
    }

    setModal(!modal);
  };

  return (
    <>
      <div
        className={`bg-gray-50  ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        } overflow-y-auto `}
      >
        <CenteredMaxWidthLayout>
          <CustomersPageHeader
            toggleModal={toggleModal}
            userData={userData}
            filteredCustomers={filteredCustomers}
            setImportModalOpen={
              notAbleToEditCustomers || notAbleToViewCustomers ? () => {} : setImportModalOpen
            }
          />
          <div className="mt-5 mx-5 flex flex-col mb-36 lg:mb-0   lg:flex-none gap-4 grid-customers-page">
            <CustomersList
              customerSearchInput={customerSearchInput}
              setCustomerSearchInput={setCustomerSearchInput}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              filteredCustomers={filteredCustomers}
              setFilteredCustomers={setFilteredCustomers}
              customers={customers}
              modal={modal}
              navigate={navigate}
              loadingCustomers={loadingCustomers}
              setLoadingCustomers={setLoadingCustomers}
              customerCount={customerCount}
              setRerun={setRerun}
              getCustomersNext={getCustomersNext}
              notAbleToViewCustomers={notAbleToViewCustomers}
              notAbleToEditCustomers={notAbleToEditCustomers}
            />
            <div className="rounded-md bg-white shadow-bold lg:self-start">
              <MarketingMessage userData={userData} filteredCustomers={filteredCustomers} />
            </div>
          </div>
        </CenteredMaxWidthLayout>
      </div>

      {modal && (
        <CustomerModalCombinedNew
          modal={modal}
          setModal={setModal}
          // MF: needs custom function because need to clear the filter and add customer to page
          setSelectedCustomer={(newCustomer) => {
            setSelectedCustomer(newCustomer);
            setCustomers([]); // clear customers
            setFilteredCustomers(null); // clear filtered customers
            getCustomersInitial(); // refetch first 50 customers
          }}
        />
      )}
      <CustomersImportModal
        isOpen={importModalOpen}
        setOpen={setImportModalOpen}
        getCustomersInitial={getCustomersInitial}
        getCount={getCount}
      />
    </>
  );
}

import React, { useState, useEffect } from "react";
import InputMUI from "components/reusable/InputMUI";
import InformationIconTooltip from "components/reusable/InformationIconTooltip";
import AutocompleteDropdownMUI from "components/reusable/AutocompleteDropdownMUI";
import Toggle from "components/reusable/Toggle";
import { Switch } from "components/ui/switch";
import NewTermsModal from "./NewTermsModal";

export default function Terms({ templateDetails, setTemplateDetails, terms }) {
  //   console.log("terms from Terms.js", terms);
  // const updatedTerms = [
  //     ...terms,
  //     {
  //       id: Date.now(),
  //       text: newTerm.text.trim(),
  //       title: newTerm.title || `Terms ${termTitleNumber}`,
  //     },
  //   ];

  const [addTermModal, setAddTermModal] = useState(false);

  return (
    <>
      <div className="flex flex-row items-center mt-8">
        <h2 className="text-lg mr-2 font-medium">Terms</h2>
        <InformationIconTooltip
          text="These are the custom terms that you can have displayed to the customer before they confirm their subscription. In order to require a signature you must have your terms selected."
          className="ml-2"
        />
      </div>
      <div className="mt-6 flex flex-col w-full max-w-md">
        <AutocompleteDropdownMUI
          options={terms}
          value={templateDetails.terms}
          onValueChange={(event, value) => {
            // console.log("value from onValueChange function", value);
            setTemplateDetails({ ...templateDetails, terms: value });
          }}
          label="Select terms (optional)"
          placeholder="Select terms"
        />

        <div className="mt-3 ml-3">
          <button
            className="text-sm  text-indigo-500 hover:text-indigo-600 hover:bg-gray-50 rounded-full font-medium p-2 -mb-2 transition duration-200"
            onClick={() => setAddTermModal(true)}
          >
            Create new terms +
          </button>
        </div>
      </div>
      {templateDetails.terms && templateDetails?.terms?.text && (
        <div className="mt-8 flex flex-col">
          <p className="text-sm text-gray-500 whitespace-pre-wrap">
            {templateDetails?.terms?.text}
          </p>
        </div>
      )}
      <div className="mt-8 flex flex-row items-center">
        <span className="mr-2 text-sm text-gray-500">Require signature</span>

        <Switch
          disabled={!templateDetails.terms}
          checked={templateDetails.requireSignature}
          onCheckedChange={() =>
            setTemplateDetails({
              ...templateDetails,
              requireSignature: !templateDetails.requireSignature,
            })
          }
        />
      </div>
      <NewTermsModal
        open={addTermModal}
        setOpen={setAddTermModal}
        terms={terms}
        setTemplateDetails={setTemplateDetails}
      />
    </>
  );
}

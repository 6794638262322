import React, { createContext, useState, useContext } from "react";

// Create the context
const SnackBarContext = createContext();

export function useSnackBar() {
  return useContext(SnackBarContext);
}

export function SnackBarProvider({ children }) {
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [successIcon, setSuccessIcon] = useState(false);
  const [isError, setIsError] = useState(false);

  const openSnackBar = (
    message = "Success",
    successIcon = false,
    isError = false
  ) => {
    setSnackBarMessage(message);
    setSuccessIcon(successIcon);
    setIsError(isError); // so we can change style if it is an error
    setSnackBarOpen(true);
  };

  const closeSnackBar = () => {
    setSnackBarOpen(false);
    setSnackBarMessage("");
    setSuccessIcon(false);
  };

  const value = {
    snackBarOpen,
    snackBarMessage,
    openSnackBar,
    closeSnackBar,
    successIcon,
    setSuccessIcon,
    isError,
  };

  return (
    <SnackBarContext.Provider value={value}>
      {children}
    </SnackBarContext.Provider>
  );
}

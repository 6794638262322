import React, { useState, useEffect, forwardRef } from "react";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import PaymentFrequency from "./PaymentFrequency";
import Duration from "./Duration";
import Visits from "./Visits";
import Details from "./Details";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import TemplateAttachments from "./TemplateAttachments";
import { useUserContext } from "context/UserContext";
import Terms from "./Terms";
import {
  saveNewSubscriptionTemplate,
  handleTemplateAttachments,
  updateSubscriptionTemplate,
} from "utils/firestoreCalls";
import { useSnackBar } from "context/SnackBarContext";
import { validateAttachments } from "utils/validateAttachments";
import { db } from "utils/firebase";
import { doc, getDoc } from "firebase/firestore";

const TransitionComponent = forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

// we should also make this the edit template modal so we need to think about that when we are building it.

const defaultTemplateDetails = {
  title: "",
  description: "",
  type: "indefinite", // default type
  duration: 1, // default duration, used if type is "fixed"
  visits: 2, // default number of visits // max of 12 per year
  amount: "0.00", // default amount
  paymentFrequency: "monthly", // default frequency
  allowOtherPayments: false, // default to false
  requireSignature: false, // default to false
  terms: null,
  attachments: [],
  // terms look like this when truthy
  //   {
  //     title: "",
  //     description: "",
  //   },
};

export default function AddTemplateModal({
  open,
  onClose,
  prevTemplateDetails,
  setSubscriptionTemplates,
  setSubscriptionDetails,
}) {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();
  const terms = userData?.bizData?.terms || [];
  const [loading, setLoading] = useState(false);

  // Local state for template details
  const [templateDetails, setTemplateDetails] = useState(
    prevTemplateDetails || defaultTemplateDetails
  );

  // keeping initial state synced
  useEffect(() => {
    setTemplateDetails(prevTemplateDetails || defaultTemplateDetails);
  }, [open]);

  // we need to modify this to also handle updating the template if it already exists
  const createTemplate = async () => {
    if (loading) return;

    try {
      setLoading(true);

      // Check if attachments are too large (skips ones that have already been uploaded to firestore)
      validateAttachments(templateDetails.attachments);

      // local attachments have .name and .file -- already uploaded attachments have .name .url -- this function will handle both cases
      const templateDetailsForDB = {
        ...templateDetails,
        attachments: await handleTemplateAttachments(
          templateDetails.attachments
        ),
      };

      let weAreUpdating = false;
      // do a check to see if the template already exists (templateId exists in the templateDetails object)
      if (templateDetails.templateId) {
        const templateRef = doc(
          db,
          "businesses",
          userData.userData.businessId,
          "subscriptionTemplates",
          templateDetails.templateId
        );
        const templateDoc = await getDoc(templateRef);
        if (templateDoc.exists()) {
          console.log("template already exists");
          weAreUpdating = true;
        }
      }

      let templateDetailsTrue = null;
      if (weAreUpdating) {
        console.log("we are updating, so we are about to update");
        templateDetailsTrue = await updateSubscriptionTemplate({
          businessId: userData.userData.businessId,
          templateDetails: templateDetailsForDB,
        });
      } else {
        templateDetailsTrue = await saveNewSubscriptionTemplate({
          businessId: userData.userData.businessId,
          templateDetails: templateDetailsForDB,
        }); // this contains the created at date and templateId
      }

      // add the template to the subscriptionTemplates array
      // if we are updating then we want to remove the old one and add the new one
      if (weAreUpdating) {
        setSubscriptionTemplates((prevTemplates) =>
          prevTemplates.map((template) =>
            template.templateId === templateDetailsTrue.templateId
              ? templateDetailsTrue
              : template
          )
        );
      } else {
        setSubscriptionTemplates((prevTemplates) => [
          ...prevTemplates,
          templateDetailsTrue,
        ]);
      }
      setSubscriptionDetails((prevDetails) => ({
        ...prevDetails,
        template: templateDetailsTrue,
      }));
      onClose();

      openSnackBar("Template saved successfully.", true);
    } catch (error) {
      if (error.message.includes("exceed the")) {
        openSnackBar(error.message, false, true);
      } else {
        openSnackBar("Error saving template", false, true);
      }
    } finally {
      setLoading(false);
    }
  };

  const disabledCreate =
    templateDetails.title === "" ||
    templateDetails.description === "" ||
    !templateDetails.duration ||
    templateDetails.visits === "" ||
    templateDetails.amount === "" ||
    templateDetails.paymentFrequency === "";

  // console.log("templateDetails", templateDetails);
  // console.log("disabedCreate", disabledCreate);

  return (
    <Modal open={open} onClose={() => {}}>
      <TransitionComponent in={open}>
        <div
          className={`absolute top-0 sm:w-64% sm:left-36% w-full bg-white shadow-bold rounded-tl-md rounded-bl-md h-full`}
          style={
            {
              // left: "36%",
              // width: "64%",
            }
          }
        >
          <Footer
            onClose={onClose}
            disabled={disabledCreate}
            onCreate={createTemplate}
            loading={loading}
            buttonText={
              prevTemplateDetails ? "Save Template" : "Create Template"
            }
          />
          <Header
            onClose={onClose}
            title={
              prevTemplateDetails
                ? "Edit Subscription Template"
                : "New Subscription Template"
            }
          />

          <div
            className="relative flex flex-col w-full p-6  overflow-auto "
            style={{ maxHeight: "calc(100% - 8rem)" }}
          >
            <Details
              templateDetails={templateDetails}
              setTemplateDetails={setTemplateDetails}
            />

            <Visits
              templateDetails={templateDetails}
              setTemplateDetails={setTemplateDetails}
            />

            <Duration
              templateDetails={templateDetails}
              setTemplateDetails={setTemplateDetails}
            />
            <PaymentFrequency
              templateDetails={templateDetails}
              setTemplateDetails={setTemplateDetails}
            />

            <Terms
              terms={terms}
              templateDetails={templateDetails}
              setTemplateDetails={setTemplateDetails}
            />
            <TemplateAttachments
              templateDetails={templateDetails}
              setTemplateDetails={setTemplateDetails}
            />
          </div>
        </div>
      </TransitionComponent>
    </Modal>
  );
}

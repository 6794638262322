import React from "react";
import { cn } from "utils/utils";

export default function CancelButton({ handleCancel, disabled, className, text = "Cancel" }) {
  return (
    <button
      disabled={disabled}
      className={cn(
        "border border-gray-300 h-10 w-20 bg-gray-50 rounded-md text-gray-500 shadow-sm",
        className
      )}
      onClick={handleCancel}
    >
      {text}
    </button>
  );
}

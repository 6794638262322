import React, { useEffect, useState } from "react";
import Dialogue from "components/modals/Dialogue";
import { node } from "constants/constants";
import NormalButton from "components/buttons/NormalButton";
import ExitModalButton from "components/buttons/ExitModalButton";
import { RiRefund2Line } from "react-icons/ri";
import { useUserContext } from "context/UserContext";
import {
  doc,
  updateDoc,
  arrayUnion,
  collection,
  addDoc,
} from "firebase/firestore";
import { db } from "utils/firebase";
import { useSnackBar } from "context/SnackBarContext";

export default function SubscriptionInvoiceRefundModal({
  open,
  onClose,
  invoice,
  setInvoices,
}) {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();
  const [refundAmount, setRefundAmount] = useState(invoice?.amount / 100 || 0);
  const [refundLoading, setRefundLoading] = useState(false);
  const [refundError, setRefundError] = useState(null);

  useEffect(() => {
    setRefundError(null);
  }, [open]);
  useEffect(() => {
    setRefundAmount(invoice?.amount / 100 || 0);
  }, [invoice]);

  const handleRefundPayment = async () => {
    setRefundLoading(true);
    setRefundError(null);

    try {
      if (refundAmount <= 0) {
        setRefundError("Refund amount must be greater than 0.");
        setRefundLoading(false);
        return;
      }
      if (!userData?.bizData?.stripeAccountId) {
        setRefundError("Stripe account not found.");
        setRefundLoading(false);
        return;
      }
      const res = await fetch(`${node}/intents/refund`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentIntentId: invoice.paymentIntentId,
          amount: invoice.amount, // already in cents
          stripeAccountId: userData.bizData.stripeAccountId,
        }),
      });

      const { refund } = await res.json();

      if (refund.status === "succeeded") {
        openSnackBar("Refund processed successfully", true);
        // Update the invoice document
        const invoiceRef = doc(
          db,
          "businesses",
          invoice.businessId,
          "subscriptions",
          invoice.subscriptionId,
          "invoices",
          invoice.invoiceId
        );
        await updateDoc(invoiceRef, {
          status: "refunded",
          refundId: refund.id,
          refundAmount: refund.amount,
          refundDate: new Date(),
        });

        // Add to activity history
        const historyRef = collection(
          db,
          "businesses",
          invoice.businessId,
          "subscriptions",
          invoice.subscriptionId,
          "history"
        );
        await addDoc(historyRef, {
          date: new Date(),
          event: "invoice_refunded",
          summary: `Refunded $${(refund.amount / 100).toFixed(2)} for invoice ${
            invoice.invoiceId
          }`,
          by: {
            id: userData.userData.id,
            name: `${userData.userData.firstName} ${userData.userData.lastName}`,
          },
          changes: {
            invoiceId: invoice.invoiceId,
            refundAmount: refund.amount,
            refundId: refund.id,
          },
        });

        // Update local state
        setInvoices((prevInvoices) =>
          prevInvoices.map((inv) =>
            inv.invoiceId === invoice.invoiceId
              ? {
                  ...inv,
                  status: "refunded",
                  refundId: refund.id,
                  refundAmount: refund.amount,
                  refundDate: new Date(),
                }
              : inv
          )
        );

        onClose();

        onClose();
      } else {
        setRefundError("Refund failed. Please try again.");
      }
    } catch (error) {
      console.error("Refund error:", error);
      setRefundError(
        error.message || "An error occurred while processing the refund."
      );
    }

    setRefundLoading(false);
  };

  if (!invoice) {
    return null;
  }

  return (
    <Dialogue open={open} onClose={onClose} width={500}>
      <ExitModalButton handleClose={onClose} position="top-5 right-8" />
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <RiRefund2Line className="text-3xl" />
          <h1 className="text-xl font-bold">Refund Invoice</h1>
        </div>
        <p className="text-gray-700 text-sm">
          This payment will be refunded to the customer's original payment
          method. This may take 3-5 business days.
        </p>
        <div className="flex flex-col gap-2">
          <label className="text-gray-500 text-sm">Amount to Refund</label>
          <input
            type="number"
            className="border border-gray-300 rounded-md p-2 w-1/2 outline-none focus:border-yellow-200 focus:ring-yellow-200 focus:ring-2"
            value={refundAmount}
            onChange={(e) =>
              setRefundAmount(
                Math.min(invoice.amount / 100, Math.max(0, e.target.value))
              )
            }
            min="0"
            max={invoice.amount / 100}
            step="0.01"
          />
        </div>
        {refundError && <p className="text-red-500 text-sm">{refundError}</p>}
        <div className="flex justify-end mt-4">
          <NormalButton
            onClick={handleRefundPayment}
            text="Process Refund"
            loading={refundLoading}
            width="w-48"
          />
        </div>
      </div>
    </Dialogue>
  );
}

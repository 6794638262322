import { useEffect, useState } from "react";
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "utils/firebase";

export function useFetchInvoices({ businessId, subscriptionId }) {
  const [invoices, setInvoices] = useState([]);
  const [loadingInvoices, setLoadingInvoices] = useState(true);

  useEffect(() => {
    if (!businessId || !subscriptionId) return;

    setLoadingInvoices(true);

    const invoicesCollection = collection(
      db,
      "businesses",
      businessId,
      "subscriptions",
      subscriptionId,
      "invoices"
    );

    const unsubscribe = onSnapshot(
      invoicesCollection,
      (snapshot) => {
        const invoicesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setInvoices(invoicesData);
        setLoadingInvoices(false);
      },
      (error) => {
        console.error("Error fetching invoices", error);
        setLoadingInvoices(false);
      }
    );

    // Cleanup function to unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, [businessId, subscriptionId]);

  return { invoices, loadingInvoices };
}

// import { useEffect, useState, useMemo } from "react";
// import {
//   collection,
//   query,
//   where,
//   getDocs,
//   doc,
//   getDoc,
// } from "firebase/firestore";
// import { db } from "utils/firebase";

// export function useFetchInvoices({ businessId, subscriptionId }) {
//   const [invoices, setInvoices] = useState([]);
//   const [loadingInvoices, setLoadingInvoices] = useState(true);

//   useEffect(() => {
//     const fetchInvoices = async () => {
//       try {
//         const invoicesCollection = collection(
//           db,
//           "businesses",
//           businessId,
//           "subscriptions",
//           subscriptionId,
//           "invoices"
//         );
//         const invoicesSnap = await getDocs(invoicesCollection);
//         if (invoicesSnap.empty) {
//           throw new Error("Subscription invoices not found");
//         }
//         const invoices = invoicesSnap.docs.map((doc) => doc.data());
//         setInvoices(invoices);
//         setLoadingInvoices(false);
//       } catch (error) {
//         console.error("Error fetching invoices", error);
//         setLoadingInvoices(false);
//       }
//     };

//     if (businessId && subscriptionId) {
//       fetchInvoices();
//     }
//   }, [businessId, subscriptionId]); // Dependency array to re-fetch if businessId or subscriptionId changes

//   return { invoices, loadingInvoices };
// }

import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useUserContext } from "context/UserContext";
import DisabledButton from "components/buttons/DisabledButton";
// import { Switch } from "components/ui/switch";
import { SwitchGreen as Switch } from "components/ui/switchGreen";
import { Checkbox } from "components/ui/checkbox";
import RichTextInput from "components/reusable/RichTextInput";
import Dialogue from "components/modals/Dialogue";
import { parseISO, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import _ from "lodash";
import { db } from "../../utils/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useSnackBar } from "context/SnackBarContext";

const DYNAMIC_VARIABLES = [
  { label: "Customer First Name", value: "{customerFirstName}" },
  { label: "Job Start", value: "{jobStart}" },
  { label: "Company Name", value: "{companyName}" },
];

// const htmlToText = (html) => {
//   try {
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(html, "text/html");
//     if (doc.body.firstChild && doc.body.firstChild.nodeName === "parsererror") {
//       console.error("Error parsing HTML:", doc.body.firstChild.textContent);
//       return html; // Return original HTML if parsing fails
//     }
//     let text = "";
//     for (const node of doc.body.childNodes) {
//       if (node.nodeType === Node.TEXT_NODE) {
//         text += node.textContent;
//       } else if (node.hasAttribute("data-value")) {
//         text += node.getAttribute("data-value").replace(/{(.+)}/, "{{$1}}");
//       }
//     }
//     return text;
//   } catch (error) {
//     console.error("Error in htmlToText:", error);
//     return html; // Return original HTML if any error occurs
//   }
// };

const htmlToText = (html) => {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    if (doc.body.firstChild && doc.body.firstChild.nodeName === "parsererror") {
      console.error("Error parsing HTML:", doc.body.firstChild.textContent);
      return html; // Return original HTML if parsing fails
    }
    let text = "";
    const traverse = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        text += node.textContent;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.nodeName === "BR") {
          text += "\n";
        } else if (node.nodeName === "DIV" || node.nodeName === "P") {
          // Add a newline before block-level elements, but not for the first one
          if (text.length > 0) {
            text += "\n";
          }
        }

        if (node.hasAttribute("data-value")) {
          text += node.getAttribute("data-value").replace(/{(.+)}/, "{{$1}}");
        } else {
          for (const childNode of node.childNodes) {
            traverse(childNode);
          }
        }

        // Add a newline after block-level elements
        if (
          (node.nodeName === "DIV" || node.nodeName === "P") &&
          node.nextSibling
        ) {
          text += "\n";
        }
      }
    };

    traverse(doc.body);
    return text.trim(); // Trim to remove any leading/trailing whitespace
  } catch (error) {
    console.error("Error in htmlToText:", error);
    return html; // Return original HTML if any error occurs
  }
};

const textToHtml = (text) => {
  return text.replace(/{{(.+?)}}/g, (match, variable) => {
    const variableInfo = DYNAMIC_VARIABLES.find(
      (v) => v.value === `{${variable}}`
    );
    if (variableInfo) {
      return `<span contenteditable="false" class="bg-yellow-50 border border-gray-400 text-gray-700 py-1 px-2 rounded-full cursor-default text-sm whitespace-nowrap" data-value="{${variable}}">${variableInfo.label}<button class="ml-1 text-gray-800 hover:text-gray-400 font-bold text-lg transition-colors duration-300 ease-in-out" data-action="remove">×</button></span>`;
    }
    return match;
  });
};

export default function JobRemindersCustomizations() {
  const { userData, setUserData, bannerVisible } = useUserContext();
  const { openSnackBar } = useSnackBar();

  const [loading, setLoading] = useState(false);

  const [jobReminderSettings, setJobReminderSettings] = useState(() => {
    const defaultSettings = {
      weekInAdvance: {
        enabled: false,
        notifyCustomer: true,
        notifyBusinessOwner: false,
        notifyDispatchedUsers: false,
      },
      dayInAdvance: {
        enabled: false,
        notifyCustomer: true,
        notifyBusinessOwner: false,
        notifyDispatchedUsers: false,
      },
      notificationMethods: {
        customer: { sms: true, email: false },
        businessOwner: { push: true, email: false },
        dispatchedUsers: { push: true, email: false },
      },
      message:
        "Hi {{customerFirstName}}, this is a reminder from {{companyName}} that we will be arriving on {{jobStart}} for your service appointment.",
    };

    return _.cloneDeep(
      userData.bizData?.jobReminderSettings || defaultSettings
    );
  });

  const [customerMessage, setCustomerMessage] = useState({
    html: textToHtml(jobReminderSettings?.message) || "",
    text: jobReminderSettings?.message || "",
  });

  // Decided against employee message... we will just make it default
  // const [employeeOwnerMessage, setEmployeeOwnerMessage] = useState({
  //   html: textToHtml(jobReminderSettings?.messages?.employeeOwner) || "",
  //   text: jobReminderSettings?.messages?.employeeOwner || "",
  // });

  const ableToSave = useMemo(() => {
    const originalSettings = userData.bizData?.jobReminderSettings || {};
    return !_.isEqual(originalSettings, jobReminderSettings);
  }, [userData, jobReminderSettings]);

  console.log(
    "userData.bizData.jobReminderSettings",
    userData.bizData.jobReminderSettings
  );

  console.log("jobReminderSettings", jobReminderSettings);

  const handleChangeRichTextChange = useCallback((value, setter) => {
    setter((prevState) => ({
      html: value,
      text: htmlToText(value),
    }));
  }, []);

  useEffect(() => {
    setJobReminderSettings((prev) => ({
      ...prev,
      message: htmlToText(customerMessage.html),
    }));
  }, [customerMessage]);

  // console.log(jobReminderSettings?.message);

  const handleSave = async () => {
    setLoading(true);
    try {
      const bizRef = doc(db, "businesses", userData.bizData.id);
      await updateDoc(bizRef, {
        jobReminderSettings,
      });
      setUserData((prev) => ({
        ...prev,
        bizData: {
          ...prev.bizData,
          jobReminderSettings: _.cloneDeep(jobReminderSettings),
        },
      }));
      openSnackBar("Settings saved successfully", true);
    } catch (error) {
      console.error("Failed to save settings:", error);
      openSnackBar("Failed to save settings", false, true);
    } finally {
      setLoading(false);
    }
  };

  const updateSettings = (path, value) => {
    setJobReminderSettings((prev) => {
      const newSettings = { ...prev };
      path.split(".").reduce((acc, key, index, arr) => {
        if (index === arr.length - 1) {
          acc[key] = value;
        }
        return acc[key];
      }, newSettings);
      return newSettings;
    });
  };

  const [previewModal, setPreviewModal] = useState(false);

  const formattedTime = useMemo(() => {
    const centralTime = new Date();
    centralTime.setHours(11, 0, 0, 0); // Set to 11:00:00.000 AM

    const businessTimezone = userData?.bizData?.timeZone || "America/Chicago";
    const businessTime = utcToZonedTime(centralTime, businessTimezone);

    const formattedBusinessTime = format(businessTime, "h:mm a");

    return `${formattedBusinessTime}`;
  }, [userData?.bizData?.timeZone]);

  return (
    <div
      className={`bg-gray-100 ${
        bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
      } overflow-y-auto pb-36`}
    >
      <div className="flex items-center justify-center">
        <div className="flex flex-col w-11/12 max-w-3xl mt-6 mb-6">
          <div className="flex justify-between items-start mb-6">
            <div>
              <h1 className="text-3xl font-extrabold text-gray-900">
                Job Reminders
              </h1>
              <p className="text-sm text-gray-500">
                Customize the job reminders that are sent to customers and
                employees.
              </p>
            </div>
            <DisabledButton
              disabled={!ableToSave}
              loading={loading}
              onClick={handleSave}
              text="Save"
              width="w-16"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow mb-4">
            <div className="grid grid-cols-3 gap-4 font-semibold mb-1">
              <div>Time before job</div>
              <div>Time to send</div>
              <div>{/* Empty header for the toggle column */}</div>
            </div>
            <hr className="mb-3" />

            {/* Week in Advance Reminder */}
            <div className="grid grid-cols-3 gap-4 items-center mb-2 text-sm text-gray-600">
              <div>1 week</div>
              <div>{formattedTime}</div>
              <div className="flex justify-end ">
                <Switch
                  checked={jobReminderSettings.weekInAdvance.enabled}
                  onCheckedChange={(checked) =>
                    updateSettings("weekInAdvance.enabled", checked)
                  }
                />
              </div>
            </div>

            {jobReminderSettings.weekInAdvance.enabled && (
              <div className="space-y-2 mb-4 ml-4 text-xs text-gray-600">
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="notifyCustomer"
                    checked={jobReminderSettings.weekInAdvance.notifyCustomer}
                    onCheckedChange={(checked) =>
                      updateSettings("weekInAdvance.notifyCustomer", checked)
                    }
                  />
                  <label htmlFor="notifyCustomer">Notify Customer</label>
                </div>
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="notifyBusinessOwner"
                    checked={
                      jobReminderSettings.weekInAdvance.notifyBusinessOwner
                    }
                    onCheckedChange={(checked) =>
                      updateSettings(
                        "weekInAdvance.notifyBusinessOwner",
                        checked
                      )
                    }
                  />
                  <label htmlFor="notifyBusinessOwner">
                    Notify Business Owner
                  </label>
                </div>
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="notifyDispatchedUsers"
                    checked={
                      jobReminderSettings.weekInAdvance.notifyDispatchedUsers
                    }
                    onCheckedChange={(checked) =>
                      updateSettings(
                        "weekInAdvance.notifyDispatchedUsers",
                        checked
                      )
                    }
                  />
                  <label htmlFor="notifyDispatchedUsers">
                    Notify Dispatched Employees
                  </label>
                </div>
              </div>
            )}

            {/* Day in Advance Reminder */}
            <div className="space-y-2 mt-4">
              <div className="grid grid-cols-3 gap-4 items-center text-sm text-gray-600">
                <div>1 day</div>
                <div>{formattedTime}</div>
                <div className="flex justify-end">
                  <Switch
                    checked={jobReminderSettings.dayInAdvance.enabled}
                    onCheckedChange={(checked) =>
                      updateSettings("dayInAdvance.enabled", checked)
                    }
                  />
                </div>
              </div>

              {jobReminderSettings.dayInAdvance.enabled && (
                <div className="space-y-2 mb-4 ml-4 text-xs text-gray-600">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="notifyCustomer"
                      checked={jobReminderSettings.dayInAdvance.notifyCustomer}
                      onCheckedChange={(checked) =>
                        updateSettings("dayInAdvance.notifyCustomer", checked)
                      }
                    />
                    <label htmlFor="notifyCustomer">Notify Customer</label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="notifyBusinessOwner"
                      checked={
                        jobReminderSettings.dayInAdvance.notifyBusinessOwner
                      }
                      onCheckedChange={(checked) =>
                        updateSettings(
                          "dayInAdvance.notifyBusinessOwner",
                          checked
                        )
                      }
                    />
                    <label htmlFor="notifyBusinessOwner">
                      Notify Business Owner
                    </label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="notifyDispatchedUsers"
                      checked={
                        jobReminderSettings.dayInAdvance.notifyDispatchedUsers
                      }
                      onCheckedChange={(checked) =>
                        updateSettings(
                          "dayInAdvance.notifyDispatchedUsers",
                          checked
                        )
                      }
                    />
                    <label htmlFor="notifyDispatchedUsers">
                      Notify Dispatched Employees
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Notification Methods */}
          <div className="bg-white p-4 rounded-lg shadow mb-4 text-gray-600">
            <h2 className="font-semibold mb-1 text-gray-800">
              Notification Methods
            </h2>
            <hr className="mb-3" />
            <div className="mb-4">
              <h3 className="text-sm font-medium mb-2">Customer</h3>
              <div className="flex items-center space-x-2 mb-2 ml-4">
                <Checkbox
                  id="customer-sms"
                  checked={jobReminderSettings.notificationMethods.customer.sms}
                  onCheckedChange={(checked) =>
                    updateSettings("notificationMethods.customer.sms", checked)
                  }
                />
                <label className="text-xs text-gray-600" htmlFor="customer-sms">
                  SMS
                </label>
              </div>
              <div className="flex items-center space-x-2 ml-4">
                <Checkbox
                  id="customer-email"
                  checked={
                    jobReminderSettings.notificationMethods.customer.email
                  }
                  onCheckedChange={(checked) =>
                    updateSettings(
                      "notificationMethods.customer.email",
                      checked
                    )
                  }
                />
                <label
                  className="text-xs text-gray-600"
                  htmlFor="customer-email"
                >
                  Email
                </label>
              </div>
            </div>
            <div className="mb-4">
              <h3 className="text-sm font-medium mb-2">Business Owner</h3>
              <div className="flex items-center space-x-2 mb-2 ml-4">
                <Checkbox
                  id="businessOwner-push"
                  checked={
                    jobReminderSettings.notificationMethods.businessOwner.push
                  }
                  onCheckedChange={(checked) =>
                    updateSettings(
                      "notificationMethods.businessOwner.push",
                      checked
                    )
                  }
                />
                <label
                  className="text-xs text-gray-600"
                  htmlFor="businessOwner-push"
                >
                  Push Notification
                </label>
              </div>
              <div className="flex items-center space-x-2 ml-4">
                <Checkbox
                  id="businessOwner-email"
                  checked={
                    jobReminderSettings.notificationMethods.businessOwner.email
                  }
                  onCheckedChange={(checked) =>
                    updateSettings(
                      "notificationMethods.businessOwner.email",
                      checked
                    )
                  }
                />
                <label
                  className="text-xs text-gray-600"
                  htmlFor="businessOwner-email"
                >
                  Email
                </label>
              </div>
            </div>
            <div>
              <h3 className="text-sm font-medium mb-2">Dispatched Employees</h3>
              <div className="flex items-center space-x-2 mb-2 ml-4">
                <Checkbox
                  id="dispatchedUsers-push"
                  checked={
                    jobReminderSettings.notificationMethods.dispatchedUsers.push
                  }
                  onCheckedChange={(checked) =>
                    updateSettings(
                      "notificationMethods.dispatchedUsers.push",
                      checked
                    )
                  }
                />
                <label
                  className="text-xs text-gray-600"
                  htmlFor="dispatchedUsers-push"
                >
                  Push Notification
                </label>
              </div>
              <div className="flex items-center space-x-2 ml-4">
                <Checkbox
                  id="dispatchedUsers-email"
                  checked={
                    jobReminderSettings.notificationMethods.dispatchedUsers
                      .email
                  }
                  onCheckedChange={(checked) =>
                    updateSettings(
                      "notificationMethods.dispatchedUsers.email",
                      checked
                    )
                  }
                />
                <label
                  className="text-xs text-gray-600"
                  htmlFor="dispatchedUsers-email"
                >
                  Email
                </label>
              </div>
            </div>
          </div>
          {/* Message Customization */}
          <div className="bg-white p-4 rounded-lg shadow text-gray-600">
            <h2 className="text-gray-800 font-semibold mb-1">
              Message Customization
            </h2>
            <hr className="mb-3" />

            <div className="flex flex-row justify-between items-center">
              <label className="text-xs text-gray5600">
                Preview of message sent to customer
              </label>
              <button
                className="text-xs font-medium text-yellow-500 px-2 py-1 rounded-full hover:bg-yellow-100 transition-colors duration-300 ease-in-out"
                onClick={() => setPreviewModal(true)}
              >
                PREVIEW
              </button>
            </div>
            <RichTextInput
              value={customerMessage.html}
              onChange={(value) =>
                handleChangeRichTextChange(value, setCustomerMessage)
              }
              label="Customer Message"
              DYNAMIC_VARIABLES={DYNAMIC_VARIABLES}
              htmlToText={htmlToText}
            />
            {/* <div className="mt-4" />
            <RichTextInput
              value={employeeOwnerMessage.html}
              onChange={(value) =>
                handleChangeRichTextChange(value, setEmployeeOwnerMessage)
              }
              label="Employee/Owner Message"
              DYNAMIC_VARIABLES={DYNAMIC_VARIABLES}
            /> */}
          </div>
        </div>
      </div>
      <PreviewModal
        open={previewModal}
        onClose={() => setPreviewModal(false)}
        message={customerMessage.text}
      />
    </div>
  );
}

const PreviewModal = ({ open, onClose, message }) => {
  //Hi {{customerFirstName}}, this is reminder from {{companyName}} that we will be arriving on {{jobStart}} for your service appointment.

  // replace those variables with fake values.. and today for the date

  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
    // timeZone: userData?.bizData?.timeZone,
  };

  const formattedDay = new Intl.DateTimeFormat("en-US", options).format(
    new Date()
  );

  const newMessage = message
    .replace(/{{customerFirstName}}/g, "John")
    .replace(/{{companyName}}/g, "ABC Company")
    .replace(/{{jobStart}}/g, formattedDay);

  return (
    <Dialogue open={open} onClose={onClose} width={500} p="p-6" py="py-6">
      <h2 className="text-lg font-bold mb-2">Job Reminder Preview</h2>
      <div className="bg-gray-50 p-4 rounded-sm shadow">
        <p className="text-sm text-gray-700 whitespace-pre-wrap break-words ">
          {newMessage}
        </p>
      </div>
    </Dialogue>
  );
};

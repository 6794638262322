export const findPrice = (planName, period) => {
  if (period === "annual" && planName === "Starter") {
    return 588;
  }
  if (period === "annual" && planName === "Growing") {
    return 1188;
  }
  if (period === "annual" && planName === "Enterprise") {
    return 2028;
  }
  if (period === "month" && planName === "Starter") {
    return 65;
  }
  if (period === "month" && planName === "Growing") {
    return 129;
  }
  if (period === "month" && planName === "Enterprise") {
    return 199;
  }
};

export const findPriceNew = (planName, period) => {
  if (period === "yearly" && planName === "Starter") {
    return 588;
  }
  if (period === "yearly" && planName === "Growing") {
    return 1188;
  }
  if (period === "yearly" && planName === "Enterprise") {
    return 2028;
  }
  if (period === "monthly" && planName === "Starter") {
    return 99;
  }
  if (period === "monthly" && planName === "Growing") {
    return 149;
  }
  if (period === "monthly" && planName === "Enterprise") {
    return 199;
  }
};

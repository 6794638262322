import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../index";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { node } from "../../../constants/constants";
import { BsReceipt } from "react-icons/bs";
import { ImSpinner } from "react-icons/im";
import { FaToggleOn, FaToggleOff } from "react-icons/fa";
import PhoneInputManualPayment from "./PhoneInputManualPayment";

export default function ReceiptModal({ modal, setModal, paymentAmount, jobDetails }) {
  const { userData } = useContext(UserContext);
  const [emailForReceipt, setEmailForReceipt] = useState(jobDetails?.customer?.email?.[0] || "");
  const [shouldSendEmailReceipt, setShouldSendEmailReceipt] = useState(true);
  const [phoneForReceipt, setPhoneForReceipt] = useState(jobDetails?.customer?.phone?.mobile || "");
  const [shouldSendTextReceipt, setShouldSendTextReceipt] = useState(false);

  useEffect(() => {
    setEmailForReceipt(jobDetails?.customer?.email?.[0] || "");
    setPhoneForReceipt(jobDetails?.customer?.phone?.mobile || "");
  }, [jobDetails?.customer?.email, jobDetails?.customer?.phone?.mobile]);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // const sendReceipt = async () => {
  //   // console.log('emailforreceipt', emailForReceipt);
  //   // console.log('paymentAmount', paymentAmount);
  //   // console.log('businessData', userData.bizData);
  //   // console.log('jobData', jobDetails);
  //   setLoading(true);
  //   if (phoneForReceipt && shouldSendTextReceipt) {
  //     // send receipt via text
  //     // this is where we will hit the backend to send a receipt to the customer
  //     const response = await fetch(`${node}/sendReceipt/send-text-receipt`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         phoneForReceipt: phoneForReceipt,
  //         paymentAmount: paymentAmount,
  //         businessData: userData.bizData,
  //         jobData: jobDetails,
  //       }),
  //     });
  //     const { message, error } = await response.json();

  //     if (error) {
  //       console.log("error sending receipt", error);
  //       alert(
  //         "There was an error sending the receipt. Please check the phone number and try again."
  //       );
  //     }
  //     if (message) {
  //       console.log("message from backend for text receipt ==>", message);
  //     }
  //   }
  //   if (shouldSendEmailReceipt && emailForReceipt) {
  //   try {
  //     console.log("sending receipt");
  //     // this is where we will hit the backend to send a receipt to the customer
  //     const response = await fetch(`${node}/sendReceipt/send-receipt`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         emailForReceipt: emailForReceipt,
  //         paymentAmount: parseFloat(
  //           (parseFloat(paymentAmount) / 100).toFixed(2)
  //         ),
  //         businessData: userData.bizData,
  //         jobData: jobDetails,
  //       }),
  //     });
  //     const { message, error } = await response.json();
  //     if (error) {
  //       throw new Error("error sending receipt");
  //     }
  //     if (message) {
  //       console.log("message from backend", message);
  //       setSuccess(true);
  //       const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  //       await delay(200);
  //     }
  //   } catch (error) {
  //     alert(
  //       "Receipt was not sent. Please check the email address and try again."
  //     );
  //   } finally {
  //     setLoading(false);
  //     setModal(false);
  //     setSuccess(false);
  //   }
  // }
  // };

  const sendTextReceipt = async (receiptDetails) => {
    console.log("sending text receipt");
    console.log("receipt details", receiptDetails);
    try {
      const response = await fetch(`${node}/sendReceipt/send-text-receipt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(receiptDetails),
      });

      if (!response.ok) {
        throw new Error("Error sending text receipt");
      }

      const { message, error } = await response.json();
      if (error) {
        throw new Error(error);
      }

      console.log("Message from backend for text receipt ==>", message);
      return true;
    } catch (error) {
      console.error("Error sending receipt", error);
      alert(
        "There was an error sending the receipt via text. Please check the phone number and try again."
      );
      return false;
    }
  };

  const sendEmailReceipt = async (receiptDetails) => {
    console.log("sending email receipt");
    console.log("receipt details", receiptDetails);
    try {
      const response = await fetch(`${node}/sendReceipt/send-receipt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(receiptDetails),
      });

      if (!response.ok) {
        throw new Error("Error sending email receipt");
      }

      const { message, error } = await response.json();
      if (error) {
        throw new Error(error);
      }

      console.log("Message from backend for email receipt ==>", message);
      return true;
    } catch (error) {
      console.error("Error sending receipt", error);
      alert("Receipt was not sent via email. Please check the email address and try again.");
      return false;
    }
  };

  // const sendReceipt = async () => {
  //   setLoading(true);

  //   const receiptDetails = {
  //     paymentAmount: parseFloat((parseFloat(paymentAmount) / 100).toFixed(2)),
  //     businessData: userData.bizData,
  //     jobData: jobDetails,
  //   };

  //   let successState = true;
  //   if (phoneForReceipt && shouldSendTextReceipt) {
  //     receiptDetails.phoneForReceipt = phoneForReceipt;
  //     const textReceiptSuccess = await sendTextReceipt(receiptDetails);
  //     successState = successState && textReceiptSuccess;
  //   }

  //   if (shouldSendEmailReceipt && emailForReceipt) {
  //     receiptDetails.emailForReceipt = emailForReceipt;
  //     const emailReceiptSuccess = await sendEmailReceipt(receiptDetails);
  //     successState = successState && emailReceiptSuccess;
  //   }
  //   // setSuccess(successState); //

  //   setLoading(false);
  //   setModal(false);
  // };

  const sendReceipt = async () => {
    setLoading(true);

    const receiptDetails = {
      paymentAmount: parseFloat((parseFloat(paymentAmount) / 100).toFixed(2)),
      businessData: userData.bizData,
      jobData: jobDetails,
    };

    const promises = [];

    if (phoneForReceipt && shouldSendTextReceipt) {
      receiptDetails.phoneForReceipt = phoneForReceipt;
      promises.push(sendTextReceipt(receiptDetails));
    }

    if (shouldSendEmailReceipt && emailForReceipt) {
      receiptDetails.emailForReceipt = emailForReceipt;
      promises.push(sendEmailReceipt(receiptDetails));
    }

    try {
      const results = await Promise.allSettled(promises);

      const successState = results.every((result) => result.status === "fulfilled");
      setSuccess(successState);
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      await delay(2000);
    } catch (error) {
      console.error("Error sending receipt", error);
    } finally {
      setLoading(false);
      setModal(false);
      setShouldSendEmailReceipt(true);
      setShouldSendTextReceipt(false);
      setSuccess(false);
    }
  };
  return (
    <Modal
      open={modal}
      onClose={() => setModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px] bg-white shadow-bold p-10 py-8 rounded-md">
        <div className="flex flex-col">
          <div className="flex flex-row gap-2 items-center mb-5">
            <BsReceipt className="inline-block text-xl text-gray-600" />
            <h1 className="text-xl font-bold">Receipt</h1>
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-sm ml-2">Amount</label>

            <input
              disabled={true}
              type="number"
              className="number-input border border-gray-300 rounded-md p-3 w-1/2"
              placeholder="Amount"
              min="0"
              step="0.01"
              onKeyDown={(event) => {
                if (event.key === "-") {
                  event.preventDefault();
                }
              }}
              value={(paymentAmount / 100).toFixed(2)}
              onChange={(e) => console.log(e.target.value)}
            />
            <div className="flex flex-row items-center mt-2">
              <label className="text-gray-500 text-sm ml-2 ">Email for receipt</label>
              {shouldSendEmailReceipt ? (
                <button onClick={() => setShouldSendEmailReceipt(false)}>
                  <FaToggleOn className="text-green-400 text-lg ml-2" />
                </button>
              ) : (
                <button onClick={() => setShouldSendEmailReceipt(true)}>
                  <FaToggleOff className="text-gray-500 text-lg ml-2 " />
                </button>
              )}
            </div>
            <input
              type="text"
              className="border border-gray-300 rounded-md p-3"
              placeholder="Email"
              value={emailForReceipt}
              onChange={(e) => setEmailForReceipt(e.target.value)}
            />
          </div>
          <div className="flex flex-row items-center mt-2">
            <label className="text-gray-500 text-sm ml-2">Phone for receipt</label>
            {shouldSendTextReceipt ? (
              <button onClick={() => setShouldSendTextReceipt(false)}>
                <FaToggleOn className="text-green-400 text-lg ml-2" />
              </button>
            ) : (
              <button onClick={() => setShouldSendTextReceipt(true)}>
                <FaToggleOff className="text-gray-500 text-lg ml-2" />
              </button>
            )}
          </div>
          <PhoneInputManualPayment
            label="Phone for receipt"
            value={phoneForReceipt}
            setValue={setPhoneForReceipt}
          />
        </div>
        <button
          disabled={loading || success}
          onClick={sendReceipt}
          className=" h-10 mt-4 w-32 flex items-center justify-center bg-gray-900 text-white hover:text-yellow-100 rounded-md shadow-md"
        >
          {success ? (
            <span>✅</span>
          ) : (
            <>{loading ? <ImSpinner className="animate-spin-slow" /> : <span>Send Receipt</span>}</>
          )}
        </button>
      </div>
    </Modal>
  );
}

import React, { useState, useEffect } from "react";
import { ImTarget } from "react-icons/im";
import { TbWorldUpload } from "react-icons/tb";
import { BsPersonPlusFill } from "react-icons/bs";
import SalesPersonSearch from "./SalesPersonSearch";

export default function EditLeadSource({ setLeadSource, employeeList, leadSource, modal = false }) {
  const [online, setOnline] = useState(false);
  const [direct, setDirect] = useState(false);
  const [other, setOther] = useState(false);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(null);

  useEffect(() => {
    setOnline(leadSource.online ? leadSource.online : false);
    setDirect(leadSource.direct ? leadSource.direct : false);
    setOther(leadSource.other ? leadSource.other : false);
    setSelectedSalesPerson(leadSource.salesPerson ? leadSource.salesPerson : null);
  }, []);

  useEffect(() => {
    setLeadSource({
      online,
      direct,
      other,
      salesPerson: selectedSalesPerson,
    });
  }, [online, direct, other, selectedSalesPerson]);

  return (
    <div
      className={`flex flex-col flex-1 ${
        modal ? "" : "rounded-md shadow-md mt-8 pb-8"
      } bg-white  gap-2 p-4`}
    >
      <div className="flex flex-row items-center gap-2 mt-2 mb-2">
        <ImTarget className="inline-block text-2xl" />
        <h1 className="text-xl font-bold">Lead Source</h1>
      </div>
      <div className="relative grid grid-cols-2 gap-2 ">
        <SalesPersonSearch
          employeeList={employeeList}
          setSelected={setSelectedSalesPerson}
          selected={selectedSalesPerson}
        />
        <button
          className={`flex flex-row items-center w-full px-2 gap-2 py-3 border border-gray-300 rounded-md text-sm font-medium focus:outline-none   ${
            online
              ? "bg-gray-800 text-yellow-100 font-semibold"
              : "bg-white text-gray-700 hover:bg-gray-50"
          }`}
          onClick={() => {
            setOnline(!online);
            setDirect(false);
            setOther(false);
          }}
        >
          <TbWorldUpload className={`${online ? "text-yellow-300" : "text-gray-700"} text-xl`} />
          Online
        </button>
        <button
          className={`flex flex-row items-center w-full px-2 gap-2 py-3 border border-gray-300 rounded-md text-sm font-medium focus:outline-none ${
            direct
              ? "bg-gray-800 text-yellow-100 font-semibold"
              : "bg-white text-gray-700 hover:bg-gray-50"
          }`}
          onClick={() => {
            setDirect(!direct);
            setOnline(false);
            setOther(false);
          }}
        >
          <BsPersonPlusFill className={`${direct ? "text-yellow-300" : "text-gray-700"} text-xl`} />
          Direct
        </button>
        <button
          className={`flex flex-row items-center w-full px-2 gap-2 py-3 border border-gray-300 rounded-md text-sm font-medium focus:outline-none ${
            other
              ? "bg-gray-800 text-yellow-100 font-semibold"
              : "bg-white text-gray-700 hover:bg-gray-50"
          }`}
          onClick={() => {
            setOther(!other);
            setDirect(false);
            setOnline(false);
          }}
        >
          Other
        </button>
      </div>
    </div>
  );
}

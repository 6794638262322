import React, { useState, useEffect, useRef } from "react";
import { BsSquareFill } from "react-icons/bs";

export default function TaxesDropdown({
  taxRates,
  selectedTaxRate,
  setSelectedTaxRate,
  disabled = false,
  textSize = "text-base",
}) {
  const [showOptions, setShowOptions] = useState(false);
  const options = taxRates;

  const editButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    //create event listener for click outside of dropdown to close it
    const handleClickOutside = (e) => {
      if (
        showOptions &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !editButtonRef.current.contains(e.target)
      ) {
        // console.log("clicked outside");
        setShowOptions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showOptions, dropdownRef]);

  useEffect(() => {
    if (showOptions && selectedTaxRate) {
      const selectedTaxRateIndex = options.indexOf(selectedTaxRate);
      if (selectedTaxRateIndex > -1) {
        const optionElements = dropdownRef.current.querySelectorAll("li");
        const selectedTaxRate = optionElements[selectedTaxRateIndex];
        const container = dropdownRef.current;
        const scrollTop = selectedTaxRate.offsetTop - container.offsetTop;
        container.scrollTop = scrollTop;
        selectedTaxRate.classList.add("bg-gray-100");
      }
    }
  }, [showOptions, selectedTaxRate]);

  const handleOptionClick = (option) => {
    setSelectedTaxRate(option);
    setShowOptions(false);
  };

  const handleDropdownClick = (event) => {
    event.preventDefault();
    setShowOptions(!showOptions);
  };

  return (
    <div className="relative">
      <button
        disabled={disabled}
        className=" px-4"
        onClick={handleDropdownClick}
        ref={editButtonRef}
      >
        {selectedTaxRate ? (
          <div className={`flex flex-row items-center gap-3 ${textSize}`}>
            <h1 className="text-gray-700">{selectedTaxRate.name}:</h1>
            <h1 className="text-gray-700"> {selectedTaxRate.rate}%</h1>
          </div>
        ) : (
          <h1 className={`text-gray-400 ${textSize}`}>Select tax rate</h1>
        )}
      </button>
      {showOptions && (
        <ul
          className="absolute mt-1 w-48 rounded-md bg-white shadow-lg max-h-60 overflow-y-auto z-10"
          ref={dropdownRef}
        >
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(option)}
              className={`px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer flex items-center gap-3 flex-row ${textSize}`}
            >
              <h1 className="text-gray-700">{option.name}</h1>
              <h1 className="text-gray-700"> {option.rate}%</h1>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

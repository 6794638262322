import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "utils/firebase";
import StarRating from "components/other/StarRating";
import { FcGoogle } from "react-icons/fc";
import { FaYelp } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { node } from "constants/constants";

export default function DynamicReviewLink() {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const [reviewData, setReviewData] = useState(null);
  const [loadingReviewData, setLoadingReviewData] = useState(true);
  const [sentiment, setSentiment] = useState(null);
  const [negativeFeedbackSubmitted, setNegativeFeedbackSubmitted] =
    useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!params.get("id") || !params.get("reviewId")) {
      console.log("no id or reviewId in the query params ");
      return;
    }

    const bizId = params.get("id");
    const reviewId = params.get("reviewId");

    // get the user data from the database
    const getReviewData = async () => {
      setLoadingReviewData(true);
      try {
        // const reviewDoc = await getDoc(
        //   doc(db, "businesses", bizId, "reviews", reviewId)
        // );
        // const reviewData = reviewDoc.data();

        const response = await fetch(`${node}/reviews/read`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            businessId: bizId,
            reviewId: reviewId,
          }),
        });
        const responseData = await response.json();

        // console.log("responseData", responseData);

        if (responseData.success && responseData.data) {
          setReviewData(responseData.data);
          setLoadingReviewData(false);
        } else {
          throw new Error("response from server was not successful");
        }
      } catch (error) {
        console.log("error getting review data", error);
        setLoadingReviewData(false);
      }
    };

    getReviewData();
  }, []);

  //   console.log("reviewData", reviewData);

  const handleSubmitNegativeFeedback = async () => {
    console.log("negative feedback submitted");
    setNegativeFeedbackSubmitted(true);

    const response = await fetch(`${node}/reviews/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        businessId: reviewData?.businessId,
        reviewId: reviewData?.reviewId,
        feedback: message,
        feedbackSubmitted: new Date(),
        type: "feedback",
      }),
    });
    const responseData = await response.json();
    console.log("responseData", responseData);
    // const reviewDocRef = doc(
    //   db,
    //   "businesses",
    //   reviewData?.businessId,
    //   "reviews",
    //   reviewData?.reviewId
    // );

    // await updateDoc(reviewDocRef, {
    //   feedback: message,
    //   feedbackSubmitted: new Date(),
    // });
  };

  return (
    <>
      <div className="relative bg-indigo-600 ">
        <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
          <div className=" sm:px-16 text-center">
            <p className="font-medium text-white">
              <span className=" inline ">Thank you for your business!</span>
            </p>
          </div>
        </div>
      </div>

      {loadingReviewData ? (
        <LoadingStarSkeleton />
      ) : (
        <div className="flex-col items-center text-center mt-10 mx-auto px-4 max-w-md ">
          {sentiment === null && (
            <>
              <div className="font-bold text-3xl flex justify-center mt-5">
                How was your experience with {reviewData?.businessName}?
              </div>

              <div className="mt-12 flex justify-center text-center text-gray-600"></div>

              <div className="mt-3 flex flex-col items-center">
                <StarRating
                  setSentiment={setSentiment}
                  reviewData={reviewData}
                />
                <div className="flex flex-row justify-between w-full max-w-[250px] mt-2 text-sm text-gray-400">
                  <div>Very Poor</div>
                  <div>Excellent</div>
                </div>
              </div>
            </>
          )}

          {sentiment === "good" && (
            <>
              <div className="font-semi-bold text-xl flex justify-center mt-5">
                <div>
                  Thank you! <b>We need your help.</b> Would you share your
                  experience on one of these sites?
                </div>
              </div>
              <div className="mt-5 flex flex-col items-center">
                {reviewData?.googleReviewLink && (
                  <a
                    // className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm  focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 gap-2 w-auto mt-5 text-slate-800 font-semibold bg-slate-100  hover:bg-slate-200"
                    className="group inline-flex items-center justify-center rounded-full py-3 px-6 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 gap-3 w-auto mt-5 text-slate-800 bg-slate-100 hover:bg-slate-200 transition-all duration-300 shadow-sm hover:shadow-md transform hover:-translate-y-1"
                    href={reviewData?.googleReviewLink}
                  >
                    <FcGoogle className="text-2xl " />{" "}
                    <span>Review on Google</span>
                    <svg
                      className="w-5 h-5 ml-2 transition-transform duration-300 ease-in-out transform group-hover:translate-x-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </a>
                )}

                {reviewData?.yelpReviewLink && (
                  <a
                    // className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm  focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 gap-2 w-auto mt-5 text-slate-800 font-semibold bg-slate-100  hover:bg-slate-200 "
                    className="group inline-flex items-center justify-center rounded-full py-3 px-6 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 gap-3 w-auto mt-5 text-slate-800 bg-slate-100 hover:bg-slate-200 transition-all duration-300 shadow-sm hover:shadow-md transform hover:-translate-y-1"
                    href={reviewData?.yelpReviewLink}
                  >
                    <FaYelp className="text-2xl text-red-600" />{" "}
                    <span>Review on Yelp</span>
                    <svg
                      className="w-5 h-5 ml-2 transition-transform duration-300 ease-in-out transform group-hover:translate-x-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </a>
                )}
                {reviewData?.facebookReviewLink && (
                  <a
                    // className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm  focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 gap-2 w-auto mt-5 text-slate-800 font-semibold bg-slate-100  hover:bg-slate-200"
                    className="group inline-flex items-center justify-center rounded-full py-3 px-6 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 gap-3 w-auto mt-5 text-slate-800 bg-slate-100 hover:bg-slate-200 transition-all duration-300 shadow-sm hover:shadow-md transform hover:-translate-y-1"
                    href={reviewData?.facebookReviewLink}
                  >
                    <BsFacebook className="text-2xl text-indigo-600" />
                    <span>Review on Facebook</span>
                    <svg
                      className="w-5 h-5 ml-2 transition-transform duration-300 ease-in-out transform group-hover:translate-x-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </a>
                )}
              </div>
            </>
          )}

          {sentiment === "bad" && (
            <div className="flex-col items-center text-center mt-10 mx-auto max-w-lg ">
              <div className="font-medium text-2xl flex justify-center mt-5 mb-10">
                We value you feedback, would you mind telling us about your
                experience?
              </div>
              {negativeFeedbackSubmitted ? (
                <div className="mt-2 text-center text-base font-medium text-green-500">
                  Thank you for your feedback.
                </div>
              ) : (
                <>
                  <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                    <label htmlFor="description" className="sr-only">
                      Description
                    </label>
                    <textarea
                      rows={4}
                      name="description"
                      id="description"
                      className="block w-full resize-none border-0 p-2 placeholder-gray-500 focus:ring-0 sm:text-base "
                      placeholder="Enter your feedback here . . ."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>

                  <div className="mt-4">
                    <button
                      onClick={handleSubmitNegativeFeedback}
                      className="inline-flex items-center justify-center w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Submit
                    </button>{" "}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

const LoadingStarSkeleton = () => {
  return (
    <div>
      <div className="mt-32 flex justify-center text-center animate-pulse">
        Loading please wait
      </div>

      <div className="App mt-3">
        <div className="flex items-center justify-center space-x-2">
          {[...Array(5)].map((_, i) => (
            <div
              key={i}
              className="w-11 h-12 bg-gray-300 rounded animate-pulse"
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSnackBar } from "context/SnackBarContext";
import { Link } from "react-router-dom";

import { useFetchAndRankData } from "./hooks";
import PrizeTable from "./components/PrizeTable";
import LeaderboardDataGrid from "./components/LeaderboardDataGrid";
import { determineIfStarted } from "./utls";
import BasicSelect from "components/reusable/BasicDropdown";
import { revenueColumns, knockingColumns } from "./constants";
import CenteredMaxWidthLayout from "layouts/CenteredMaxWidthLayout";
import PreviewLeaderboard from "./components/PreviewLeaderboard";

export default function Leaderboard() {
  const [leaderboardType, setLeaderboardType] = useState("revenue");
  const { openSnackBar } = useSnackBar();
  const { gamesData, loadingData } = useFetchAndRankData();
  const { isStarted, hasEnded, formattedStart, formattedEnd, startDate } =
    determineIfStarted();

  const getRows = () => {
    if (leaderboardType === "revenue") {
      return gamesData.businessesWithStats;
    } else {
      return gamesData.allUserMarkerStats;
    }
  };

  const getColumns = () => {
    return leaderboardType === "revenue" ? revenueColumns : knockingColumns;
  };

  return (
    <div
      className="bg-gray-50 h-screen
    overflow-y-auto  py-5 sm:px-8 px-4"
    >
      <CenteredMaxWidthLayout>
        <div className="flex sm:flex-row sm:justify-between sm:items-start  flex-col-reverse ">
          {!isStarted && !hasEnded ? (
            <div className="flex-1 ">
              <p className="text-gray-600 text-sm  mb-4 font-medium">
                Starts {formattedStart} - Ends {formattedEnd}
              </p>
              {/* <div className="flex flex-row items-center w-full my-4">
              <div
                className="flex items-center space-x-2  bg-yellow-300/80 font-medium px-3 py-0.5  shadow-md text-sm  mr-2"
                style={{
                  borderRadius: "4px",
                }}
              >
                <span className=" text-gray-700">Starts In</span>
              </div>
              <div className="text-gray-700 font-medium text-sm">
                {countdown}
              </div>
            </div> */}
            </div>
          ) : hasEnded ? (
            <div className="flex-1">
              <p className="text-gray-600 text-sm  mb-4 font-medium">
                Started {formattedStart} - Ended {formattedEnd}
              </p>
              <div className="flex items-center space-x-2  bg-red-500 font-medium px-3 py-0.5  shadow-md text-sm text-white">
                <span className="h-2 w-2 bg-red-50 rounded-full animate-blink-fast mr-1"></span>
                ENDED
              </div>
            </div>
          ) : (
            <div className="flex-1">
              <p className="text-gray-600 text-sm  mb-4 font-medium">
                Started {formattedStart} - Ends {formattedEnd}
              </p>
              <div className="flex flex-row items-center my-4">
                <div
                  className="flex items-center space-x-2  bg-green-500 font-medium px-3 py-0.5  shadow-md text-sm text-white"
                  style={{
                    borderRadius: "4px",
                  }}
                >
                  <span className="h-2 w-2 bg-red-50 rounded-full animate-blink-fast mr-1"></span>
                  LIVE - Updates Hourly
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex md:flex-row flex-col">
          <div className="md:w-8/12 w-full">
            {isStarted ? (
              <div className="mt-2 w-full">
                <div className="flex flex-row justify-center mb-2">
                  <BasicSelect
                    value={leaderboardType}
                    setValue={setLeaderboardType}
                    label="Change view"
                    labelColor="#4b5563"
                    focusedLabelColor="#4b5563"
                    width={250}
                    options={[
                      { value: "revenue", label: "Business Revenue" },
                      { value: "markers", label: "Doors Knocked" },
                    ]}
                  />
                </div>

                <LeaderboardDataGrid
                  rows={getRows()}
                  columns={getColumns()}
                  loading={loadingData}
                />
              </div>
            ) : (
              <PreviewLeaderboard />
            )}
          </div>

          {/* Right side cards */}
          <div
            className={`flex-1 mt-10 ${
              isStarted ? "md:-mt-6" : "md:mt-0"
            } md:ml-8`}
          >
            <PrizeTable />

            <div className="w-full flex flex-col justify-center mt-4 text-xs text-center text-gray-500 px-1">
              <Link
                to="/register?redirect=/games"
                className="bg-yellow-300/60 hover:bg-yellow-400/50 text-gray-900 font-bold pb-4 pt-3  rounded-lg shadow-bold text-lg transition duration-300 ease-in-out "
              >
                PLAY FOR FREE
              </Link>
              <Link
                to="/login?redirect=/games"
                className="text-gray-500 hover:text-gray-700 mt-4 transition duration-300 ease-in-out text-center font-medium  text-sm"
              >
                Already have an account? Sign in here to register.
              </Link>
              <a
                href="https://www.skool.com/homebase-games-9515"
                target="_blank"
                rel="noopener noreferrer"
                className="underline mt-6"
              >
                Join the Skool group here
              </a>
            </div>
          </div>
        </div>
      </CenteredMaxWidthLayout>
    </div>
  );
}

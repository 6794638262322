import { useState, useEffect } from "react";

export default function useFetch(fetchFunction, functionArgs) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    fetchFunction(functionArgs)
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log("error");
        setError(true);
        setLoading(false);
        console.error("Error getting data", err);
      });
  }, [fetchFunction, functionArgs]);
  return { isLoading: loading, data, error };
}

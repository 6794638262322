import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "context/UserContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "utils/firebase";

import AuthenticatedGamesView from "./components/AuthenticatedGamesView";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { isBefore, isAfter } from "date-fns";
import SkeletonLoader from "components/other/SkeletonLoader";
import { determineIfStarted } from "./utls";

export default function Games() {
  const navigate = useNavigate();
  const { userData, bannerVisible } = useUserContext();
  const [user, loading, error] = useAuthState(auth);
  const [countdown, setCountdown] = useState("");

  const { isStarted, hasEnded, startDate, endDate } = determineIfStarted();

  // Now, get the current date/time in UTC
  const now = new Date();
  const nowInCentralTime = utcToZonedTime(now, "America/Chicago"); // Convert current UTC time to Central Time for an accurate comparison

  // Handle countdown
  useEffect(() => {
    const countdownDate = startDate.getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval);
        setCountdown("LIVE");
      } else if (hasEnded) {
        clearInterval(interval);
        setCountdown("ENDED");
      } else {
        setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if ((loading || user) && !userData) {
    // Return a loading skeleton while the user's authentication state is being checked and while fetching their docs
    return <SkeletonLoader />;
  }

  if (userData)
    return (
      <div
        className={`bg-gray-50 h-full-minus-header
     overflow-y-auto  py-5 sm:px-8 px-4`}
      >
        <AuthenticatedGamesView countdown={countdown} />
      </div>
    );

  return (
    <div
      className={`bg-gray-50 ${
        userData ? "h-full-minus-header" : "h-screen"
      } overflow-y-auto flex flex-col items-center sm:p-5 p-2`}
    >
      <img
        src="/images/Homebase-logo-black-notext.png"
        alt="Homebase Logo"
        className="mb-3"
        width={100}
      />

      <h1 className="relative text-lg font-medium mb-6 text-gray-800 text-center flex items-center justify-center">
        <span className="hidden sm:inline-block mr-2 scale-x-200 transform">
          —
        </span>
        Josh & Oliver Lester and Homebase360 present
        <span className="hidden sm:inline-block ml-2 scale-x-200 transform">
          —
        </span>
      </h1>
      <h2 className="text-4xl font-bold mb-4 text-gray-800 text-center">
        The Homebase Games
      </h2>

      <p className="text-center text-gray-700 mb-6 md:w-3/4 w-full max-w-lg text-lg font-medium ">
        A fun way to build your own window cleaning business with other people.
        Simple instructions, live workshops, community, support, real time
        leaderboards, friendly competition, and prizes!
      </p>

      <div className="mb-3 mt-6">
        <Link
          to="/register?redirect=/games"
          className="bg-yellow-300/60 hover:bg-yellow-400/50 text-gray-900 font-bold pb-5 pt-4 px-8 rounded-lg shadow-bold text-xl transition duration-300 ease-in-out "
        >
          PLAY FOR FREE
        </Link>
        <p className="text-xs text-gray-600 mt-7 italic text-center">
          with a 14 day free trial
        </p>
      </div>

      <Link
        to="/login?redirect=/games"
        className="text-gray-500 hover:text-gray-700 mt-4 transition duration-300 ease-in-out text-center font-medium  text-sm"
      >
        Already have an account? Sign in here to register.
      </Link>

      <div className="mt-16 w-full flex flex-col justify-start items-center">
        {countdown === "LIVE" ? (
          <div className="flex flex-row items-center">
            <div
              className="flex items-center space-x-2  bg-red-500 font-medium px-3 py-0.5  shadow-md text-sm text-white"
              style={{
                borderRadius: "4px",
              }}
            >
              <span className="h-2 w-2 bg-red-50 rounded-full animate-blink-fast mr-1"></span>
              LIVE
            </div>
            <div className="text-gray-800 font-medium ml-2 text-sm">
              Challenge happening now
            </div>
          </div>
        ) : countdown === "ENDED" ? (
          <div className="flex flex-row items-center">
            <div
              className="flex items-center space-x-2  bg-red-500 font-medium px-3 py-0.5  shadow-md text-sm text-white"
              style={{
                borderRadius: "4px",
              }}
            >
              <span className="h-2 w-2 bg-red-50 rounded-full animate-blink-fast mr-1"></span>
              ENDED
            </div>
            <div className="text-gray-800 font-medium ml-2 text-sm">
              Competition has ended
            </div>
          </div>
        ) : (
          <div className="flex flex-row items-center">
            <div
              className="flex items-center space-x-2  bg-gray-900 font-medium px-3 py-0.5  shadow-md text-sm text-gray-50 mr-2"
              style={{
                borderRadius: "4px",
              }}
            >
              <span className=" text-yellow-100">Starts In</span>
            </div>
            <div className="text-gray-700 font-medium text-sm w-32">
              {countdown}
            </div>
          </div>
        )}
      </div>

      <div className="mt-10 flex flex-row justify-center items-center w-full">
        <img
          src="/images/treasure.png"
          alt="Prize Icon"
          width={100}
          className="mr-4"
        />
        <div className="max-w-sm sm:w-1/2 ">
          <h2 className="text-gray-800 font-bold text-xl">
            Win 1-day with Josh and Oliver
          </h2>
          <p>Prize for the top 10 on the leaderboards every month.</p>
        </div>
      </div>

      <div className="mt-10 flex flex-row items-center justify-center w-full">
        <div className="max-w-lg  text-center ">
          <h2 className="text-gray-800 font-bold text-xl">Everyone can win!</h2>
          <p>
            Create at least 5 jobs and process their payments through
            Homebase360 between August 16th and September 15th for a chance to
            win a $2,000 Deionization Tank.
          </p>
          <p className="mt-5">
            Weekly trainings and hangouts with Josh & Oliver Lester. Learning,
            winning, and masterminding with other business owners.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center mt-10">
        <div className="max-w-lg w-full">
          <h2 className="text-gray-800 font-bold text-xl mb-2 text-center">
            Top prizes
          </h2>
          <div className="p-2 bg-white rounded-md shadow-lg">
            <table className="w-full">
              <tbody className="divide-y divide-gray-200">
                <tr>
                  <td className="font-semibold w-28 px-4 py-3 border-r divide-x divide-gray-200">
                    Top 10
                  </td>
                  <td className="flex-1 px-4 py-3">
                    Free coaching session with Josh and Oliver Lester to take
                    your business to the next level.
                  </td>
                </tr>
                <tr>
                  <td className="font-semibold w-28 px-4 py-3 border-r divide-x divide-gray-200">
                    3rd Place
                  </td>
                  <td className="flex-1 px-4 py-3">
                    Screen Cleaner ($500 prize value)
                  </td>
                </tr>
                <tr>
                  <td className="font-semibold w-28 px-4 py-3 border-r divide-x divide-gray-200">
                    2nd Place
                  </td>
                  <td className="flex-1 px-4 py-3">
                    Waterfed Pole ($1,000 prize value)
                  </td>
                </tr>
                <tr>
                  <td className="font-semibold w-28 px-4 py-3 border-r divide-x divide-gray-200">
                    1st Place
                  </td>
                  <td className="flex-1 px-4 py-3">
                    Deionization Tank ($2,000 prize value)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="mt-10 flex flex-row items-center justify-center w-full">
        <div className="max-w-lg text-left">
          <div className="flex flex-row items-center justify-center">
            <img
              src="/images/Homebase-logo-black-notext.png"
              alt="Homebase Logo"
              className="mr-1"
              width={35}
            />
            <h2 className="text-gray-800 font-bold text-xl">
              Why Homebase360?
            </h2>
          </div>
          <ul className="list-disc space-y-3 mt-3 text-left pl-5">
            <li>
              <span className="font-medium">Enhanced Sales Tracking:</span>{" "}
              Track every door you knock on. Note those who didn't answer or
              declined, and revisit them with stronger social proof after
              securing other deals.
            </li>
            <li>
              <span className="font-medium">Safe Payments:</span> Overcome
              hiring hesitations related to cash mishandling. Use Homebase
              Payments to process secure electronic payments, eliminating the
              need for cash or checks. Enjoy features like revenue tracking and
              in-app tipping.
            </li>
            <li>
              <span className="font-medium">Recurring Revenue:</span> Convert
              one-time transactions into ongoing jobs with a few taps. Build and
              easily manage a consistent customer portfolio.
            </li>
            <li>
              <span className="font-medium">Direct Communication:</span> Send
              text messages directly from the app to upsell or engage past and
              current customers, acquiring immediate sales without the need for
              door-to-door visits.
            </li>
            <li>
              <span className="font-medium">Streamlined Conversations:</span>{" "}
              Maintain instant in-app communication to keep a clear log of
              discussions for every job.
            </li>
            <li>
              <span className="font-medium">Efficient Scheduling:</span> Never
              miss another appointment with our smooth scheduling feature,
              ensuring you're always on time for every job.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

import { storage } from "./firebase";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { convertToJsDate } from "utils/helpers";

export async function createReceiptPdf(invoice) {
  // console.log("Creating receipt PDF for invoice", invoice.invoiceId);
  console.log("creating receipt");
  //console log all the values that we are using
  // console.log("unit price", invoice?.lineItems?.[0]?.unitPrice);
  invoice.lineItems.forEach((item, index) => {
    const unitPrice = parseFloat(item.unitPrice);
    if (!isNaN(unitPrice)) {
      // console.log(`unit price for item ${index} is a valid number:`, unitPrice);
    } else {
      // console.log(`unit price for item ${index} is not a valid number.`);
    }
  });

  const pdfDoc = await PDFDocument.create();

  const page = pdfDoc.addPage();
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  page.setFont(font);
  page.setFontSize(12);

  // console.log("business name", invoice.businessName);
  // Invoice header
  page.setFont(boldFont);
  page.setFontSize(20);
  page.drawText("Receipt", {
    x: 50,
    y: page.getHeight() - 50,
  });
  page.setFont(font);
  page.setFontSize(20);
  page.drawText(invoice.businessName, {
    x: page.getWidth() - 50 - boldFont.widthOfTextAtSize(invoice.businessName, 20),
    y: page.getHeight() - 50,
  });

  // Invoice details

  let invoiceNumber = invoice?.invoiceId?.slice(0, 10).toUpperCase();
  if (invoice?.invoiceNumber) {
    invoiceNumber = `#${invoice.invoiceNumber}`;
  }

  page.setFontSize(10);

  const invoiceDetailsY = page.getHeight() - 125;
  const detailsXOffset = 50;
  page.setFont(boldFont);
  page.drawText("Invoice", { x: detailsXOffset, y: invoiceDetailsY });
  page.setFont(font);
  page.drawText(invoiceNumber, {
    x: detailsXOffset + 100,
    y: invoiceDetailsY,
  });
  page.setFont(boldFont);
  page.drawText("Service Date", {
    x: detailsXOffset,
    y: invoiceDetailsY - 20,
  });

  const serviceDate = convertToJsDate(invoice.serviceDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  page.setFont(font);
  page.drawText(serviceDate, {
    x: detailsXOffset + 100,
    y: invoiceDetailsY - 20,
  });
  page.setFont(boldFont);
  page.drawText("Paid", { x: detailsXOffset, y: invoiceDetailsY - 40 });
  const paidDate = convertToJsDate(invoice.datePaid).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  page.setFont(font);
  page.drawText(paidDate, {
    x: detailsXOffset + 100,
    y: invoiceDetailsY - 40,
  });
  // console.log(
  //   "business phone (first three)",
  //   invoice.businessPhone.substring(5, 8)
  // );
  // Business details
  const businessDetailsY = invoiceDetailsY - 100;
  const businessXOffset = 50;

  page.drawText(invoice.businessName, {
    x: businessXOffset,
    y: businessDetailsY,
  });
  const formattedPhone = `(${invoice.businessPhone.substring(
    2,
    5
  )}) ${invoice.businessPhone.substring(5, 8)}-${invoice.businessPhone.substring(8, 12)}`;
  page.drawText(formattedPhone, {
    x: businessXOffset,
    y: businessDetailsY - 20,
  });
  // console.log("business email", invoice.businessEmail);
  page.drawText(invoice.businessEmail, {
    x: businessXOffset,
    y: businessDetailsY - 40,
  });
  // console.log("business address", invoice.businessAddress);
  page.drawText(invoice.businessAddress, {
    x: businessXOffset,
    y: businessDetailsY - 60,
  });

  // Bill To section
  const billToY = invoiceDetailsY; // Same Y level as invoice details
  const billToXOffset = 400; // Adjust this value based on the alignment
  page.setFont(boldFont);
  page.setFontSize(12);
  page.drawText("Bill To:", {
    x: billToXOffset,
    y: billToY,
  });
  page.setFont(font);
  page.setFontSize(10);
  page.drawText(invoice.customerName, {
    x: billToXOffset,
    y: billToY - 20,
  });
  page.drawText(invoice.customerEmail[0], {
    x: billToXOffset,
    y: billToY - 40,
  });

  // Line items
  const lineItemsY = businessDetailsY - 200;
  const lineItemsXOffset = 50;
  const lineHeight = 40;

  invoice.lineItems.forEach((item, index) => {
    page.setFont(boldFont);
    page.setFontSize(12);
    page.drawText(item.name, {
      x: lineItemsXOffset,
      y: lineItemsY - index * lineHeight,
    });

    // console.log("item quantity", item.quantity);
    page.setFont(font);
    page.setFontSize(10);
    page.drawText(`Qty ${item.quantity}`, {
      x: lineItemsXOffset,
      y: lineItemsY - 20 - index * lineHeight,
    });
    // console.log("item unit price", item.unitPrice);
    const unitPrice = parseInt(item.unitPrice);
    page.drawText(`$${!isNaN(unitPrice) ? unitPrice.toFixed(2) : "0.00"}`, {
      x: lineItemsXOffset + 400,
      y: lineItemsY - index * lineHeight,
    });
  });

  // Draw a line under the line items
  const lineY = lineItemsY - invoice.lineItems.length * lineHeight - 20;
  page.drawLine({
    start: { x: 50, y: lineY },
    end: { x: page.getWidth() - 50, y: lineY },
    thickness: 1,
    color: rgb(0, 0, 0),
  });

  // Add the total
  page.setFont(boldFont);
  page.setFontSize(12);
  page.drawText("Paid Total", {
    x: 50,
    y: lineY - 40,
  });
  // console.log("Paid Total", invoice?.paymentIntent?.amount / 100);
  page.drawText(`$${(invoice.paymentIntent.amount / 100).toFixed(2)}`, {
    x:
      page.getWidth() -
      50 -
      font.widthOfTextAtSize(`$${(invoice.paymentIntent.amount / 100).toFixed(2)}`, 12),
    y: lineY - 40,
  });

  const pdfBytes = await pdfDoc.save();

  // Upload the PDF to Google Cloud Storage
  //   const bucketName = "homebase-90.appspot.com"
  //   const destination = `receipts/${invoice.invoiceId}.pdf`
  //   const fileOptions = {
  //     metadata: {
  //       contentType: "application/pdf",
  //     },
  //   }

  //   const bucket = storage.bucket(bucketName)
  //   const file = bucket.file(destination)

  //   await file.save(pdfBytes, fileOptions)

  // Do something with the generated PDF bytes, like creating a Blob to download it
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "receipt.pdf";
  link.click();
}

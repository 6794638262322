import React, { useEffect, useState } from "react";
import { useContext } from "react";
// import { UserContext } from "../index";
import { useUserContext } from "context/UserContext";
import {
  setDoc,
  doc,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  getDoc,
  where,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { node } from "../../constants/constants";
import { ImSpinner } from "react-icons/im";
import { Data } from "../../utils/data";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { PopupButton } from "react-calendly";
import {
  BsFillArrowRightSquareFill,
  BsFillHouseFill,
  BsFillCalendarFill,
} from "react-icons/bs";
import { FaUserPlus } from "react-icons/fa";
import { RxDotFilled, RxDot } from "react-icons/rx";
import { ImArrowRight } from "react-icons/im";

import PieChart from "components/other/PieChart";
import DailyRev from "components/other/DailyRev";
import TopSalesrep from "components/other/TopSalesrep";
import RevenueOverTime from "components/other/RevenueOverTime";
import AverageJobSize from "components/other/AverageJobSize";
import {
  subDays,
  format,
  differenceInMonths,
  parse,
  subMonths,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { IoCopyOutline } from "react-icons/io5";
import { BiStats } from "react-icons/bi";
import Tooltip from "@mui/material/Tooltip";
import { useSnackBar } from "context/SnackBarContext";

Chart.register(CategoryScale);

export default function Home() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // const { user, userData, bannerVisible } = useContext(UserContext);
  const { userData, bannerVisible } = useUserContext();
  const { openSnackBar } = useSnackBar();

  const isFieldTech = userData?.userData?.userType === "Field Tech";

  const notAbleToViewStats =
    isFieldTech ||
    userData?.userData?.customPermissions?.view_company_stats === false;

  const canViewOwnJobsAndAssignedJobs =
    userData.userData.userType === "Salesperson2" ||
    userData?.userData?.customPermissions?.manage_assigned_jobs === true;

  const canViewTeamLeadJobs =
    userData.userData.userType === "Team Lead" ||
    userData?.userData?.customPermissions?.manage_team_jobs === true;

  const notAbleToCreateJobs =
    userData?.userData?.customPermissions?.create_jobs === false;
  const notAbleToCreateEstimates =
    userData?.userData?.customPermissions?.create_estimates === false;
  const notAbleToCreateCustomers =
    userData?.userData?.customPermissions?.manage_customers === false;

  const [dailyRevenue, setDailyRevenue] = useState(null);
  const [monthlyRevenue, setMonthlyRevenue] = useState(null);
  const [modal, setModal] = useState(state?.setUp ? true : false);
  // const [modal, setModal] = useState(true);
  // this determines whether we should the discount promo... true means we don't show it false means we do
  const [setup, setSetup] = useState(true);
  const [upcomingJobs, setUpcomingJobs] = useState(null);
  const [loadingJobs, setLoadingJobs] = useState(true);

  async function getDailyRev() {
    // Generate an array of the last 7 days
    const last7Days = Array.from({ length: 8 }, (_, i) => {
      return format(subDays(new Date(), i), "yyyy-MM-dd");
    }).reverse();

    const dailyRevenueRef = collection(
      db,
      "businesses",
      userData.bizData.id,
      "stats",
      "dailyRevenue",
      "revenueByDay"
    );

    // Fetch data for each day in the last 7 days
    const fetchedData = await Promise.all(
      last7Days.map(async (date) => {
        const docRef = doc(dailyRevenueRef, date);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          return docSnapshot.data();
        } else {
          return { date, revenue: 0 };
        }
      })
    );

    // console.log("fetched data for last 7 days", fetchedData);

    function parseLocalDate(dateString) {
      const [year, month, day] = dateString.split("-");
      return new Date(year, month - 1, day);
    }

    const formatDailyRev = fetchedData.map((rev) => {
      return {
        revenue: rev.revenue,
        date: format(parseLocalDate(rev.date), "EEE"),
      };
    });

    // console.log("daily rev formatted", formatDailyRev);
    setDailyRevenue(formatDailyRev);
  }

  async function getMonthlyRev() {
    const businessStartMonth = format(
      new Date(userData.bizData.createdAt?.seconds * 1000),
      "yyyy-MM"
    );

    const businessStartDate = parse(businessStartMonth, "yyyy-MM", new Date());
    const numberOfMonthsSinceStart = differenceInMonths(
      new Date(),
      businessStartDate
    );

    const numberOfMonthsToFetch =
      numberOfMonthsSinceStart > 6 ? 6 : numberOfMonthsSinceStart;

    const arrayOfMonthsToFetch = Array.from(
      { length: numberOfMonthsToFetch + 1 },
      (_, i) => {
        return format(subMonths(new Date(), i), "yyyy-MM");
      }
    ).reverse();

    const fetchedData = await Promise.all(
      arrayOfMonthsToFetch.map(async (date) => {
        const docRef = doc(
          db,
          "businesses",
          userData.bizData.id,
          "stats",
          "monthlyJobRevenue",
          "revenueByMonth",
          date
        );
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          return docSnapshot.data();
        } else {
          return { date, revenue: 0 };
        }
      })
    );
    setMonthlyRevenue(fetchedData);
  }

  // get the next 5 jobs that are upcoming from firestore and set them to upcoming jobs
  const getJobs = async () => {
    // if userType == "Field Tech" then we only get the jobs that are dispatched to that user, else we get all jobs
    setLoadingJobs(true);
    try {
      let jobsSnapshot;
      if (isFieldTech) {
        jobsSnapshot = await getDocs(
          query(
            collection(db, "businesses", userData.userData.businessId, "jobs"),
            where(
              "dispatchedToIdsOnly",
              "array-contains",
              userData.userData.id
            ),
            where("start", ">=", new Date()),
            orderBy("start", "asc"),
            limit(4)
          )
        );
      } else if (canViewOwnJobsAndAssignedJobs) {
        jobsSnapshot = await getDocs(
          query(
            collection(db, "businesses", userData.userData.businessId, "jobs"),
            where("canViewIdsOnly", "array-contains", userData.userData.id),
            where("start", ">=", new Date()),
            orderBy("start", "asc"),
            limit(4)
          )
        );
      } else if (canViewTeamLeadJobs) {
        console.log("getting jobs on homepage for team lead");
        const canViewIds = [
          ...userData.userData.assignedEmployees,
          userData.userData.id,
        ];
        jobsSnapshot = await getDocs(
          query(
            collection(db, "businesses", userData.userData.businessId, "jobs"),
            where("canViewIdsOnly", "array-contains-any", canViewIds),
            where("start", ">=", new Date()),
            orderBy("start", "asc"),
            limit(4)
          )
        );
      } else {
        jobsSnapshot = await getDocs(
          query(
            collection(db, "businesses", userData.userData.businessId, "jobs"),
            where("start", ">=", new Date()),
            orderBy("start", "asc"),
            limit(4)
          )
        );
      }
      const jobs = jobsSnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          customer: doc.data().customer?.displayName,
          title: doc.data().lineItems[0]?.name,
          start: doc.data().start.toDate(),
          end: doc.data().end.toDate(),
        };
      });
      // console.log("next 4 jobs", jobs);
      setUpcomingJobs(jobs);
    } catch (err) {
      console.log(err);
    }
    setLoadingJobs(false);
  };

  useEffect(() => {
    getJobs();
    getMonthlyRev();
    getDailyRev();
  }, []);

  const StatsButton = ({ salesPerson }) => {
    return (
      <Tooltip title="See detailed report">
        <div
          className="absolute top-1 right-2 cursor-pointer"
          onClick={() =>
            navigate("/reporting", {
              state: { salesPerson: salesPerson },
            })
          }
        >
          <BiStats className="text-3xl" />
        </div>
      </Tooltip>
    );
  };

  const [chartData, setChartData] = useState({
    labels: Data.map((data) => data.year),
    datasets: [
      {
        label: "Users Gained",
        data: Data.map((data) => data.userGain),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "rgba(75,192,192,1)",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const prefill = {
    email: userData?.userData?.email,
    name: userData?.userData?.firstName + " " + userData?.userData?.lastName,

    customAnswers: {
      // a1: userData?.userData?.phone,
      a2: userData?.userData?.phone,
    },
  };

  const handleNewJob = () => {
    if (notAbleToCreateJobs) {
      openSnackBar("You do not have permission to create jobs");
      return;
    }
    navigate("/addjob");
  };

  const handleNewEstimate = () => {
    if (notAbleToCreateEstimates) {
      openSnackBar("You do not have permission to create estimates");
      return;
    }
    navigate("/newestimate");
  };

  const handleNewCustomer = () => {
    if (notAbleToCreateCustomers) {
      openSnackBar("You do not have permission to create customers");
      return;
    }
    navigate("/customers", { state: { newCustomerModal: true } });
  };

  return (
    <>
      <div
        className={`bg-gray-50 ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        } overflow-y-auto`}
      >
        <div className=" px-4 box-border pb-8">
          <div
            className={`${
              notAbleToViewStats
                ? "grid grid-cols-1 sm:grid-cols-2"
                : "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4"
            } gap-4 mt-8`}
          >
            <div className="border relative rounded-md p-5 shadow-lg bg-white h-96">
              <div className="text-lg font-semibold">
                {isFieldTech ? "Your Upcoming Jobs" : "Upcoming jobs"}
              </div>
              {loadingJobs ? (
                <>
                  {[...Array(4)].map((_, index) => (
                    <div
                      key={index}
                      className="flex flex-col mt-3 justify-start w-full rounded-md bg-gray-50 shadow-md p-2 h-16 "
                    >
                      <div className="w-1/2 h-4 animate-pulse bg-gray-200 rounded-md"></div>
                      <div className="w-1/3 h-3 animate-pulse bg-gray-200/60 rounded-md mt-2"></div>
                      <div className="w-1/2 h-3 animate-pulse bg-gray-200/60 rounded-md mt-2"></div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {upcomingJobs && upcomingJobs.length > 0 ? (
                    upcomingJobs.map((job, index) => {
                      return (
                        <div
                          className="flex flex-col mt-3 justify-center w-full rounded-md bg-yellow-100/40 shadow-md p-2 cursor-pointer hover:bg-yellow-100/70 h-16 "
                          key={index}
                          onClick={() =>
                            navigate(`/jobdetails/${job.jobId}`, {
                              state: { jobId: job.jobId },
                            })
                          }
                        >
                          <div className="text-sm font-semibold truncate">
                            {job.customer}
                          </div>
                          <div className="text-xs truncate">{job.title}</div>
                          <div className="text-xs mt-auto">
                            {format(
                              utcToZonedTime(
                                job.start,
                                userData?.bizData?.timeZone
                              ),
                              "EEE MM/dd/yyyy h:mm a"
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <div
                        className="flex  flex-col mt-3 justify-center w-full rounded-md bg-gray-50 shadow-md p-2 cursor-pointer hover:bg-yellow-100/40 h-16 "
                        onClick={handleNewJob}
                      >
                        <div className="text-sm font-semibold">
                          No upcoming jobs
                        </div>

                        <button
                          className="text-xs text-yellow-400 hover:underline hover:text-yellow-600 text-start mt-1"
                          onClick={handleNewJob}
                        >
                          Create a job to get started
                        </button>
                      </div>

                      <div className="flex flex-row absolute bottom-4 right-0 left-0 items-end  justify-between mx-10% gap-8 pr-3  ">
                        <BsFillHouseFill className="text-9xl text-gray-800 " />
                        <BsFillCalendarFill className="text-8xl text-gray-800 mb-2" />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="border relative rounded-md p-5 shadow-lg bg-white h-96">
              <div className="text-lg self-start justify-self-start font-semibold mb-4">
                Create a customer
              </div>

              <div className="flex items-center justify-center flex-col  mt-3">
                <h1 className="text-base text-gray-500 text-center">
                  Record your customers information so you can send invoices,
                  and messages.
                </h1>
                {/* <FaUserPlus className="text-4xl text-indigo-600 mt-4" /> */}
                <button
                  className="px-4 mt-10 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleNewCustomer}
                >
                  Create Customer
                </button>
              </div>
              {/* Decorative line */}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
                className="absolute bottom-0 right-0 left-0 z-0"
              >
                <path
                  fill="none"
                  stroke="#0099ff"
                  strokeWidth="10"
                  d="M0,96L60,112C120,128,240,160,360,176C480,192,600,192,720,160C840,128,960,64,1080,48C1200,32,1320,64,1380,80L1440,96"
                ></path>
              </svg>
            </div>

            <div className="border relative rounded-md p-5 shadow-lg bg-white h-96">
              <div className="text-lg font-semibold mb-4">Create a job</div>

              <div className="flex items-center justify-center flex-col mt-3">
                <h1 className="text-base text-gray-500 text-center">
                  Add jobs, so you can impress your customers with notifications
                  and convenient payments.
                </h1>
                {/* Uncomment the below line to include an icon */}
                {/* <FaBriefcase className="text-4xl text-indigo-600 mt-4" /> */}
                <button
                  className="px-4 mt-10 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleNewJob}
                >
                  Create Job
                </button>
              </div>
              {/* Decorative line */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
                className="absolute bottom-0 right-0 left-0 z-0"
              >
                <path
                  fill="none"
                  stroke="#0099ff"
                  strokeWidth="10"
                  d="M0,96L180,192C360,288,720,64,900,32C1080,0,1440,0,1440,0"
                ></path>
              </svg>
            </div>

            <div className="border relative rounded-md p-5 shadow-lg bg-white h-96">
              <div className="text-lg font-semibold mb-4">
                Create an estimate
              </div>

              <div className="flex items-center justify-center flex-col mt-3">
                <h1 className="text-base text-gray-500 text-center">
                  Prepare a detailed estimate for your customers before starting
                  the work.
                </h1>
                {/* Uncomment the below line to include an icon */}
                {/* <FaClipboardList className="text-4xl text-indigo-600 mt-4" /> */}
                <button
                  className="px-4 mt-10 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleNewEstimate}
                >
                  Create Estimate
                </button>
              </div>
              {/* Decorative line */}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
                className="absolute bottom-0 right-0 left-0 z-0"
              >
                <path
                  fill="none"
                  stroke="#0099ff"
                  strokeWidth="10"
                  d="M0,0L360,96C720,192,1080,64,1440,32"
                ></path>
              </svg>
            </div>
          </div>

          {!notAbleToViewStats && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
              <div className="border rounded-md p-5 shadow-lg bg-white h-96 relative">
                {monthlyRevenue && monthlyRevenue.length > 0 && (
                  <AverageJobSize chartData={monthlyRevenue} />
                )}
                <StatsButton salesPerson={false} />
              </div>
              <div className="border rounded-md p-5 shadow-lg bg-gray-50 h-96 relative">
                {monthlyRevenue && monthlyRevenue.length > 0 && (
                  <TopSalesrep chartData={monthlyRevenue} />
                )}
                <StatsButton salesPerson={true} />
              </div>
              <div className="border rounded-md p-5 shadow-lg bg-gray-50 h-96 relative">
                {dailyRevenue && dailyRevenue.length > 0 && (
                  <DailyRev chartData={dailyRevenue} />
                )}
                <StatsButton salesPerson={false} />
              </div>
              <div className="border rounded-md p-5 shadow-lg bg-gray-50 h-96 relative">
                {monthlyRevenue && monthlyRevenue.length > 0 && (
                  <RevenueOverTime chartData={monthlyRevenue} />
                )}
                <StatsButton salesPerson={false} />
              </div>
            </div>
          )}
        </div>
      </div>

      {modal && (
        <SetupModal
          setModal={setModal}
          setSetup={setSetup}
          setup={setup}
          prefill={prefill}
          navigate={navigate}
        />
      )}
    </>
  );
}

function SetupModal({ setSetup, setup, setModal, prefill, navigate }) {
  const [phase, setPhase] = useState(1);
  const playStoreLink =
    "https://play.google.com/store/apps/details?id=com.derickio.homebase360";
  const appStoreLink =
    "https://apps.apple.com/us/app/homebase-360/id6467654086";

  const handleLinkToApp = () => {
    console.log("download app");
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    let url = "";

    if (/windows phone/i.test(userAgent)) {
      url = playStoreLink;
    }

    if (/android/i.test(userAgent)) {
      url = playStoreLink;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      url = appStoreLink;
    }

    if (url !== "") {
      window.open(url, "_blank");
    } else {
      window.open(appStoreLink, "_blank");
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied to clipboard: ${text}`);
  };

  return (
    <>
      <div className="modal">
        <div
          className="overlay"
          onClick={() => console.log("clicked overlay")}
        ></div>
        <div className="setup-modal-content">
          {setup ? (
            <div className="flex flex-col h-full items-center justify-between">
              {phase === 1 && (
                <>
                  <h1 className="flex justify-center pl-2 text-center text-2xl font-semibold text-gray-600 max-w-lg mt-10">
                    Your free trial has started! 🚀
                  </h1>
                  <p className=" flex justify-center pl-2 text-center text-lg font-semibold text-gray-600 max-w-lg mt-10 ">
                    We recommend you schedule an onboarding call with one of our
                    account specialists to integrate your business so you can
                    experience the full power of Homebase 360.
                  </p>
                  <div className=" mt-10 mb-10 flex flex-col items-center justify-center bg-gray-800 rounded-md hover:bg-gray-700">
                    <PopupButton
                      styles={{
                        color: "#ffffff",
                        padding: "12px 20px",
                      }}
                      // url="https://calendly.com/homebase360/onboard"
                      url="https://calendly.com/james-homebase360/homebase-360-demo"
                      prefill={prefill}
                      rootElement={document.getElementById("root")}
                      text="Schedule onboarding call 📆"
                    />
                  </div>
                  <div className="flex flex-row justify-center items-center ml-4">
                    <RxDotFilled className="text-gray-800 text-2xl" />
                    <RxDot className="text-gray-800 text-2xl" />
                    <RxDot className="text-gray-800 text-2xl" />
                  </div>

                  <button
                    className="absolute right-5 bottom-5 flex flex-row items-center justify-center bg-gray-500 rounded-md px-4 py-2 text-gray-50 hover:bg-gray-400"
                    onClick={() => setPhase(2)}
                  >
                    {/* Next */}
                    <ImArrowRight className="text-gray-50 text-sm ml-1" />
                  </button>
                </>
              )}

              {phase === 2 && (
                <>
                  <h1></h1>
                  <div className="flex flex-col items-center">
                    <h1 className=" mb-10 flex justify-center pl-2 text-center text-2xl font-semibold text-gray-600 max-w-xl ">
                      For the best experience on mobile, download the Homebase
                      360 app. 👇
                    </h1>
                    <button
                      className=" mt-4 mb-6 bg-gray-800 text-white px-5 py-3 rounded-md hover:bg-gray-700"
                      onClick={handleLinkToApp}
                    >
                      Download app (mobile only) 📱
                    </button>
                    <div
                      className="flex flex-row justify-center items-center ml-4 mt-4 cursor-pointer"
                      onClick={() => copyToClipboard(appStoreLink)}
                    >
                      Copy link to App Store <IoCopyOutline className="ml-2" />
                    </div>
                    <div
                      className="flex flex-row justify-center items-center ml-4 mt-4 cursor-pointer"
                      onClick={() => copyToClipboard(playStoreLink)}
                    >
                      Copy link to Play Store <IoCopyOutline className="ml-2" />
                    </div>
                  </div>
                  <div className="flex flex-row justify-center items-center ml-4">
                    <RxDotFilled
                      className="text-gray-800 text-2xl cursor-pointer"
                      onClick={() => setPhase(1)}
                    />
                    <RxDotFilled className="text-gray-800 text-2xl" />
                    <RxDot className="text-gray-800 text-2xl" />
                  </div>

                  <button
                    className="absolute right-5 bottom-5 flex flex-row items-center justify-center bg-gray-500
                    hover:bg-gray-400 rounded-md px-4 py-2 "
                    onClick={() => setPhase(3)}
                  >
                    {/* Next */}
                    <ImArrowRight className="text-gray-50 text-sm ml-1" />
                  </button>
                </>
              )}

              {phase === 3 && (
                <>
                  <h1 className="flex justify-center pl-2 text-center text-2xl font-semibold text-gray-600 max-w-lg mt-10">
                    Your account is ready to go. ✅
                  </h1>
                  <div className="mt-4 mb-6 flex justify-center pl-2 text-center text-lg font-semibold text-gray-600 max-w-xl flex-col">
                    <span className="font-bold">Reminder:</span>
                    <h2 className="">
                      During your free trial you will be able to send text
                      messages but clients will not be able to respond to your
                      texts. Once you upgrade to a paid plan then two way
                      texting will be enabled.
                    </h2>
                  </div>
                  <button
                    className={`mt-4 mb-5 bg-gray-800 text-white px-5 py-3 w-56 rounded-md flex flex-row items-center justify-center`}
                    onClick={() => {
                      setModal(false);
                    }}
                  >
                    <span className="mr-2">Proceed to dashboard</span>
                    <BsFillArrowRightSquareFill className="text-green-400 text-sm" />
                  </button>{" "}
                  <div className="flex flex-row justify-center items-center ml-4">
                    <RxDotFilled
                      className="text-gray-800 text-2xl cursor-pointer"
                      onClick={() => setPhase(1)}
                    />
                    <RxDotFilled
                      className="text-gray-800 text-2xl cursor-pointer"
                      onClick={() => setPhase(2)}
                    />
                    <RxDotFilled className="text-gray-800 text-2xl" />
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="flex flex-col h-full items-center justify-between">
              <h2 className="text-4xl font-bold text-center mt-10 ">
                Limited time offer ⏱️
              </h2>

              <div className="">
                <p className="text-center text-xl font-semibold mt-10">
                  Save 20% for 3 months on any plan.
                </p>

                <p className="text-center mt-3">Or 10% off annual plans.</p>
              </div>
              <div className="flex flex-col justify-center ml-5 gap-4 pb-10 mt-10">
                <button
                  className="shadow-sm bg-gray-800 hover:bg-gray-900 hover:text-green-400 text-yellow-200 p-2 rounded-md text-md font-medium w-64 h-10 flex justify-center items-center"
                  onClick={() => navigate("new-subscription")}
                >
                  <span>Buy Now and Save</span>
                </button>{" "}
                <button
                  className="bg-gray-200 hover:bg-gray-100 text-gray-700 p-2 rounded-md text-md    font-medium w-64 h-10 flex justify-center items-center shadow-sm"
                  onClick={() => setSetup(true)}
                >
                  <span>No thanks</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

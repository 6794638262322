import { useEffect, useState, useMemo } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "utils/firebase";
import { useSnackBar } from "context/SnackBarContext";
import { node } from "constants/constants";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

export function useFetchSub({ businessId, subscriptionId }) {
  const [subscription, setSubscription] = useState(null);
  const { openSnackBar } = useSnackBar();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const subscriptionRef = doc(
          db,
          "businesses",
          businessId,
          "subscriptions",
          subscriptionId
        );
        const subscriptionSnap = await getDoc(subscriptionRef);

        if (!subscriptionSnap.exists()) {
          throw new Error("Subscription not found");
        }

        const subscriptionData = subscriptionSnap.data();

        setSubscription(subscriptionData);
      } catch (error) {
        openSnackBar(
          "Error loading subscription, please try again.",
          false,
          true
        );
        console.error("Error fetching subscription templates", error);
      }
    };

    if (businessId && subscriptionId) {
      fetchSubscription();
    }
  }, [businessId, subscriptionId]); // Dependency array to re-fetch if businessId or subscriptionId changes

  return { subscription, setSubscription };
}

export function useFetchSubSecure({ businessId, subscriptionId }) {
  // need to hit the node endpoint that will allow us to securely get the subscription data from the backend....
  const [loadingSub, setLoadingSub] = useState(true);
  const [customerData, setCustomerData] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [errorFetching, setErrorFetching] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const fetchSub = async () => {
      try {
        const response = await fetch(`${node}/serviceSubscriptionsApi/read`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ businessId, subscriptionId }),
        });
        const data = await response.json();
        if (response.ok) {
          const { message, customer, subscription, business } = data;
          // Handle successful response

          if (!subscription.stripeAccountId) {
            throw new Error("Business payments account is not found.");
          }
          setCustomerData(customer || null);
          setSubscriptionData(subscription);
          setBusinessData(business || null);

          // then we set the stripe promise if it already hasn't been set
          if (!stripePromise) {
            setStripePromise(
              loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
                stripeAccount: subscription.stripeAccountId,
              })
            );
          }
        } else {
          const { message, error } = data;
          // Handle error response
          console.error(message);
          console.error(error);
          setErrorFetching(error);
        }
      } catch (error) {
        // Handle fetch error
        const errorMessage = error.message || "An unexpected error occurred";
        console.error(
          "Error fetching subscription catch block ==>",
          errorMessage
        );
        setErrorFetching(errorMessage);
      } finally {
        setLoadingSub(false);
      }
    };

    if (!businessId || !subscriptionId) {
      setErrorFetching("No access token provided");
      return;
    }

    if (!subscriptionData) {
      fetchSub();
    }
  }, [businessId, subscriptionId]);

  return {
    subscriptionData,
    setSubscriptionData,
    customerData,
    businessData,
    loadingSub,
    errorFetching,
    stripePromise,
  };
}

export function useFetchParams() {
  const location = useLocation();

  const { businessId, subscriptionId } = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const businessId = params.get("uniqueToken");
    const subscriptionId = params.get("access");
    return { businessId, subscriptionId };
  }, [location.search]);

  return { businessId, subscriptionId };
}

import React, { useContext } from "react";
import { node } from "../../constants/constants";
import { UserContext } from "../../index";
import { Link } from "react-router-dom";
import NormalButton from "components/buttons/NormalButton";

export default function UpdateSubscription() {
  const { user, userData } = useContext(UserContext);

  // pulling customerId from the subscription document
  // const customerId = userData?.subData?.customer;

  const [loadingBillingPortal, setLoadingBillingPortal] = React.useState(false);

  // console.log("stripe customer data", userData?.subData?.customer);
  // console.log("subData", userData?.subData);

  const handleBillingPortal = async () => {
    if (!userData?.subData?.customer) {
      console.error("No customer ID found for user:", user.uid);
      alert("No customer ID found for user. Please contact support.");
      return;
    }
    setLoadingBillingPortal(true);
    try {
      const response = await fetch(
        `${node}/subscriptions/create-customer-portal-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerId: userData?.subData?.customer,
          }),
        }
      );

      const result = await response.json();
      if (response.ok && result.url) {
        console.log("Billing portal session created:", result.url);
        window.location.href = result.url;
      } else {
        // Handle error here
        console.error("Error creating billing portal session:", result);
        alert("Error creating billing portal session. Please contact support.");
        setLoadingBillingPortal(false);
      }
    } catch (error) {
      console.error("Error initiating billing portal session:", error);
      setLoadingBillingPortal(false);
    }
  };
  return (
    <>
      <div className="flex h-full min-h-screen flex-col items-center overflow-auto bg-slate-100/90 font-stripe px-5">
        <h1
          className="mt-12 flex justify-center pl-2  text-2xl font-semibold text-slate-600 "
          // style={{
          //   width: "920px",
          // }}
        >
          <img
            src="/images/Homebase-logo-black.png"
            // className="h-14 w-auto"
            width={200}
            alt="Homebase logo"
          />
        </h1>
        <div className="flex flex-col items-center justify-center max-w-lg text-center mt-6 mb-6">
          Your subscription has some issues that need to be fixed before you can
          access the dashboard. Please use the billing portal.
        </div>

        <NormalButton
          onClick={handleBillingPortal}
          text="Billing Portal"
          loading={loadingBillingPortal}
          width="w-40"
          additionalClasses="mt-5"
        />

        <div className="text-xs text-gray-500 mt-12 max-w-sm text-center">
          If you are having trouble, please contact us at support@homebase360.io
          or call us at 415-831-7928.
        </div>
      </div>
    </>
  );
}

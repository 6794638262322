import React, { useMemo } from "react";
import { convertToJsDateNano } from "utils/helpers";
import { format } from "date-fns";
import { useEmployeeContext } from "context/EmployeeContext";

export default function ActivityHistory({ history, loading }) {
  const { employees } = useEmployeeContext();
  // console.log("subscription.history", subscription.history);

  // console.log("history", history);
  // console.log("empoyees", employees);
  // console.log("loading", loading);
  // console.log(
  //   "history dates",
  //   history.map((h) => convertToJsDateNano(h.date).getTime())
  // );
  // console.log(
  //   "history dates formateed to the second",
  //   history.map((h) =>
  //     format(convertToJsDateNano(h.date), "eee MM/dd/yyyy hh:mm:ss a")
  //   )
  // );
  // transform the history using useMemo
  const sortedHistory = useMemo(() => {
    if (history) {
      const sorted = [...history].sort((a, b) => {
        const dateA = convertToJsDateNano(a.date);
        const dateB = convertToJsDateNano(b.date);
        return dateA - dateB; // Sort from oldest to newest
      });

      return sorted;
    }
    return []; // Return an empty array if no history is available
  }, [history]);

  const highlightText = (text) => {
    const failedIndex = text.toLowerCase().indexOf("failed");
    const succeededIndex = text.toLowerCase().indexOf("succeeded");
    const canceledIndex = text.toLowerCase().indexOf("canceled");

    if (failedIndex !== -1) {
      return (
        <>
          {text.substring(0, failedIndex)}
          <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-medium rounded bg-red-50 text-red-400 border border-red-200">
            {text.substring(failedIndex, failedIndex + 6)}
          </span>
          {text.substring(failedIndex + 6)}
        </>
      );
    } else if (succeededIndex !== -1) {
      return (
        <>
          {text.substring(0, succeededIndex)}
          <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-medium rounded bg-green-50 text-gray-600 border border-green-100">
            {text.substring(succeededIndex, succeededIndex + 9)}
          </span>
          {text.substring(succeededIndex + 9)}
        </>
      );
    } else if (canceledIndex !== -1) {
      return (
        <>
          {text.substring(0, canceledIndex)}
          <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-medium rounded bg-gray-50 text-red-400 border border-gray-100">
            {text.substring(canceledIndex, canceledIndex + 8)}
          </span>
          {text.substring(canceledIndex + 8)}
        </>
      );
    }

    return text;
  };
  const getActorColor = (actor) => {
    if (!actor) return { backgroundColor: "#f3f4f6", color: "#4b5563" }; // gray-100 and gray-600

    const name = actor.name?.toLowerCase();
    if (name === "system")
      return { backgroundColor: "#f3f4f6", color: "#4b5563" }; // gray-100 and gray-600
    if (name === "customer")
      return { backgroundColor: "#e0e7ff", color: "#4f46e5" }; // indigo-100 and indigo-600

    if (actor.id) {
      const employee = employees.find((emp) => emp.id === actor.id);
      if (employee && employee.color && employee.color.value) {
        const bgColor = `${employee.color.value}1A`; // 1A in hex is 10% opacity
        return {
          backgroundColor: bgColor,
          color: "#4b5563",
        };
      }
    }

    return { backgroundColor: "#fefce8", color: "#ca8a04" }; // yellow-50 and yellow-600
  };

  // const getActorColor = (actor) => {
  //   switch (actor.toLowerCase()) {
  //     case "system":
  //       return "bg-gray-100 text-gray-600";
  //     case "customer":
  //       return "bg-yellow-50 text-gray-600";
  //     default:
  //       return "bg-indigo-100 text-indigo-600";
  //   }
  // };

  return (
    <div className="flex flex-col flex-1 rounded-md shadow-bold bg-white  overflow-x-auto relative flex-shrink-0">
      <h2 className="font-bold text-lg px-4 pb-4 pt-6 ">Activity History</h2>
      <div className="w-full overflow-y-auto max-h-96">
        <table className="w-full text-sm px-2">
          <thead className="border-b border-gray-200 bg-white">
            <tr>
              <th className="sticky top-0 px-6 py-3 text-left font-medium text-gray-500 bg-white">
                By
              </th>
              <th className="sticky top-0 px-6 py-3 text-left font-medium text-gray-500 bg-white">
                Action
              </th>
              <th className="sticky top-0 px-6 py-3 text-left font-medium text-gray-500 bg-white">
                Date
              </th>
              <th className="sticky top-0 px-3 py-3 text-left font-medium text-gray-500 z-10 bg-white"></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {loading ? (
              Array.from({ length: 4 }).map((_, index) => (
                <tr key={index}>
                  <td className="px-4 py-4">
                    <div className="h-4 bg-gray-200 rounded-full w-20 md:w-24 lg:w-32 animate-pulse"></div>
                  </td>
                  <td className="px-4 py-4">
                    <div className="h-4 bg-gray-200 rounded-full w-32 md:w-40 lg:w-56 animate-pulse"></div>
                  </td>
                  <td className="px-4 py-4">
                    <div className="h-4 bg-gray-200 rounded-full w-24 md:w-32 lg:w-40 animate-pulse"></div>
                  </td>
                </tr>
              ))
            ) : sortedHistory.length > 0 ? (
              sortedHistory.map((event, index) => (
                <tr
                  key={index}
                  className="transition-opacity duration-1000 ease-in-out "
                >
                  <td className="px-6 py-4 text-sm">
                    <span
                      style={getActorColor(event?.by)}
                      className={`px-2 py-1 rounded-full text-xs font-medium truncate max-w-[140px] inline-block `}
                    >
                      {event?.by?.name}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {highlightText(event?.summary)}
                  </td>
                  <td className="pl-6 pr-2 py-4 text-sm text-gray-500">
                    {format(
                      convertToJsDateNano(event?.date),
                      "eee MM/dd/yyyy hh:mm a"
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="5"
                  className="px-6 py-4 text-center text-sm text-gray-500"
                >
                  No Activity to display
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import InputMUI from "components/reusable/InputMUI";

export default function Details({ templateDetails, setTemplateDetails }) {
  const [touched, setTouched] = useState({
    title: false,
    description: false,
  });

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
  };
  return (
    <>
      <div className="flex flex-row items-center">
        <h2 className="text-lg mr-2 font-medium">Details</h2>
      </div>
      <div className="mt-2 flex flex-col w-full max-w-md gap-3">
        <InputMUI
          label="Subscription name"
          value={templateDetails.title}
          onChangeFunction={(e) =>
            setTemplateDetails({
              ...templateDetails,
              title: e.target.value,
            })
          }
          onBlur={() => handleBlur("title")}
          error={touched.title && !templateDetails.title}
          helperText={
            touched.title && !templateDetails.title ? "Title is required" : ""
          }
        />
        <InputMUI
          label="Subscription description"
          value={templateDetails.description}
          multiline
          rows={3}
          onChangeFunction={(e) =>
            setTemplateDetails({
              ...templateDetails,
              description: e.target.value,
            })
          }
          onBlur={() => handleBlur("description")}
          error={touched.description && !templateDetails.description}
          helperText={
            touched.description && !templateDetails.description
              ? "Description is required"
              : ""
          }
        />
      </div>
    </>
  );
}

//local development
// export const node = "http://127.0.0.1:5001/homebase-90/us-central1";

//deployed development
// export const node = "https://us-central1-homebase-90.cloudfunctions.net";

// local production
// export const node = "http://127.0.0.1:5001/homebase-360/us-central1";

// deployed production
export const node = "https://us-central1-homebase-360.cloudfunctions.net";

// example invoice link so you can play with css
// http://localhost:3001/invoice?uid=xsUrbqrcTQSyFjJOtpEZyC0BUUK2&invoice=234

import React from "react";
import {
  differenceInSeconds,
  formatDistance,
  round,
  differenceInMilliseconds,
  differenceInMinutes,
  format,
} from "date-fns";
import { formatCurrency } from "utils/helpers";

export default function Summary({ jobDetails }) {
  let timeDriving = 0;
  let timeOnJob = 0;
  //logic for setting timeDriving
  if (
    jobDetails?.startDrivingTime &&
    jobDetails?.endDrivingTime &&
    jobDetails.startDrivingTime instanceof Date &&
    jobDetails.endDrivingTime instanceof Date
  ) {
    timeDriving = formatDistance(
      jobDetails.startDrivingTime,
      jobDetails.endDrivingTime,
      {
        roundingMethod: "round",
      }
    );
  } else if (
    jobDetails?.startDrivingTime &&
    jobDetails.startDrivingTime instanceof Date
  ) {
    timeDriving = formatDistance(jobDetails.startDrivingTime, new Date(), {
      roundingMethod: "round",
    });
  }

  //logic for setting timeOnJob

  if (
    jobDetails?.startedJobTime &&
    jobDetails?.endedJobTime &&
    jobDetails.startedJobTime instanceof Date &&
    jobDetails.endedJobTime instanceof Date
  ) {
    timeOnJob = formatDistance(
      jobDetails.startedJobTime,
      jobDetails.endedJobTime,
      {
        roundingMethod: "round",
      }
    );
  } else if (
    jobDetails?.startedJobTime &&
    jobDetails.startedJobTime instanceof Date
  ) {
    timeOnJob = formatDistance(jobDetails.startedJobTime, new Date(), {
      roundingMethod: "round",
    });
  }

  let totalPaid = 0;

  if (jobDetails?.paymentHistory) {
    jobDetails.paymentHistory.forEach((payment) => {
      if (payment.billingType === "Refund") {
        totalPaid -= payment.totalAmountFromStripe;
      } else {
        totalPaid += payment.totalAmountFromStripe;
      }
    });

    totalPaid = parseFloat(totalPaid / 100).toFixed(2);
  }

  let totalTip = 0;

  if (jobDetails?.paymentHistory) {
    jobDetails.paymentHistory.forEach((payment) => {
      if (payment.billingType === "Refund") {
        totalTip -= payment?.tip ? payment.tip : 0;
      } else {
        totalTip += payment?.tip ? payment.tip : 0;
      }
    });

    totalTip = parseFloat(totalTip / 100).toFixed(2);
  }

  return (
    <div className="flex flex-col rounded-md shadow-bold bg-white py-6 px-4 gap-2 mt-8 lg:mt-0 mb-8">
      <h1 className="text-xl font-bold pb-2">
        Summary{" "}
        {jobDetails?.status === "cancelled" && (
          <span className="font-semibold text-red-300 text-xl pl-2">
            (Cancelled)
          </span>
        )}{" "}
        {jobDetails?.status === "missed" && (
          <span className="font-semibold text-red-300 text-xl pl-2">
            (Missed)
          </span>
        )}
      </h1>
      <div className="flex flex-row gap-4">
        <h1 className="text-lg font-bold w-2/6">Total</h1>
        <h1 className="text-lg font-bold w-2/6">Employees</h1>
        <h1 className="text-lg font-bold w-2/6">Time</h1>
        <h1 className="text-lg font-bold w-2/6">Lead Source</h1>
      </div>
      <div className="flex flex-row gap-4">
        <h1 className="text-lg font-semi-bold w-2/6">
          ${formatCurrency(jobDetails?.jobTotal)} total
          <br />
          <span className="text-green-600">
            {totalPaid ? "$" + totalPaid + " paid" : ""}
          </span>
          {totalTip > 0 ? (
            <span>
              <br />
              (${totalTip} tip)
            </span>
          ) : (
            ""
          )}
        </h1>
        <h1 className="text-lg font-semi-bold w-2/6">
          {jobDetails?.dispatchedTo &&
            jobDetails?.dispatchedTo?.length > 0 &&
            jobDetails.dispatchedTo.map((employee, key) => {
              return (
                <div key={key}>
                  {employee.firstName} {employee.lastName}
                  <br />
                </div>
              );
            })}
        </h1>
        <h1 className="text-lg font-semi-bold w-2/6">
          {timeOnJob + " on job"} <br /> {timeDriving + " driving"}
        </h1>
        <h1 className="text-lg font-semi-bold w-2/6">
          {jobDetails?.leadSource?.salesPerson?.firstName &&
          jobDetails?.leadSource?.salesPerson?.lastName
            ? jobDetails?.leadSource?.salesPerson?.firstName +
              " " +
              jobDetails?.leadSource?.salesPerson?.lastName
            : ""}
          <br />
          {jobDetails?.leadSource &&
            Object.entries(jobDetails.leadSource)
              .filter(([key, value]) => value === true)
              .map(([key]) => (
                <div key={key}>
                  {key[0].toUpperCase() + key.slice(1)}
                  <br />
                </div>
              ))}
        </h1>
      </div>
    </div>
  );
}

const summary = {
  total: 249.0,
  tip: 15.0,
  employees: "Garrett Frazier Sabastean Frazier",
  hours: "2.5 hours",
  driveTime: "25 min drive",
  leadSource: "James Doe",
};

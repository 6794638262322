import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { IoIosInformationCircleOutline } from "react-icons/io";

export default function InformationIconTooltip({ text = "" }) {
  return (
    <Tooltip title={text} arrow>
      <div>
        <IoIosInformationCircleOutline size={22} className="text-gray-900" />
      </div>
    </Tooltip>
  );
}

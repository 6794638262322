import { useState, useEffect } from "react";
import { doc, collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "utils/firebase";

// this is named jobDetails, because this is different than the fetching going on in subscriptionDetails, and addJob.. and these differences are important to keep separate
export function useSubscriptionsJobDetails({
  businessId,
  customerId,
  subscription, // this is the subscription object that we have saved in the job
}) {
  const [data, setData] = useState({
    selectedSubscription: null,
    subscriptions: null,
    selectedVisit: subscription?.selectedVisit || null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //   console.log("subscription.subscriptionId", subscription?.subscriptionId);
  //   console.log("businessId", businessId);
  //   console.log("customerId", customerId);

  useEffect(() => {
    if (!businessId || !customerId) {
      setLoading(false);
      return;
    }

    const subscriptionId =
      subscription?.subscriptionId ||
      subscription?.selectedSubscription?.subscriptionId;
    const unsubscribes = [];

    if (subscriptionId) {
      console.log("setting up listener for ", subscriptionId);
      // listener for the subscription that is connected to the job (equal to selectedSubscription)
      const subscriptionRef = doc(
        db,
        "businesses",
        businessId,
        "subscriptions",
        subscriptionId
      );
      const unsubscribe = onSnapshot(
        subscriptionRef,
        (doc) => {
          console.log("subscriptionDoc changed");
          if (doc.exists()) {
            setData((prev) => ({ ...prev, selectedSubscription: doc.data() }));
          }
        },
        (err) => setError(err)
      );
      unsubscribes.push(unsubscribe);
    }

    const subscriptionsRef = collection(
      db,
      "businesses",
      businessId,
      "subscriptions"
    );
    const subscriptionsQuery = query(
      subscriptionsRef,
      where("customer.customerId", "==", customerId)
    );

    const unsubscribe = onSnapshot(
      subscriptionsQuery,
      (querySnapshot) => {
        const subscriptions = querySnapshot.docs.map((doc) => doc.data());
        setData((prev) => {
          const newData = { ...prev, subscriptions };
          if (!newData.selectedSubscription && subscriptions.length > 0) {
            newData.selectedSubscription = subscriptions[0]; // set default selectedSubscription if there isn't one already
          }
          newData.selectedVisit = subscription?.selectedVisit || null;
          return newData;
        });
        setLoading(false);
      },
      (err) => setError(err)
    );
    unsubscribes.push(unsubscribe);

    return () => unsubscribes.forEach((unsub) => unsub());
  }, [
    businessId,
    customerId,
    subscription?.subscriptionId,
    subscription?.selectedSubscription?.subscriptionId,
  ]);

  return { data, loading, error };
}

// CropImageModal.js
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Cropper from "react-easy-crop";
// import getCroppedImg from "./cropImage";
import Button from "@mui/material/Button";

const CropImageModal = ({ open, setOpen, image, onCrop }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCrop = async () => {
    console.log("handleCrop from cropImageModal.js");
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      onCrop(croppedImage);
      setOpen(false);
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "600px",
          minHeight: "516px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          paddingTop: 16,
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: 12,
          outline: "none",
          maxHeight: "80vh",
        }}
      >
        <div style={{ height: 400, position: "relative" }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>
        <div className="flex flex-row justify-center mt-4">
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="zoom-range"
          />
        </div>
        <div className="mt-4 text-right">
          <Button
            variant="contained"
            color="primary"
            onClick={handleCrop}
            style={{ marginRight: 8 }}
          >
            Crop
          </Button>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CropImageModal;

const getCroppedImg = async (imageSrc, crop) => {
  const image = new Image();
  image.src = imageSrc;
  await new Promise((resolve) => {
    image.onload = resolve;
  });

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  /* setting canvas width & height allows us to 
    resize from the original image resolution */
  canvas.width = 250;
  canvas.height = 250;

  // Draw the cropped image onto the canvas
  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  // Convert the canvas to a Blob
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      "image/png",
      1
    ); // You can adjust the quality (third parameter) between 0 and 1.
  });
};

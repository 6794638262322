import { Bar } from "react-chartjs-2";

function JobStats({ chartData }) {
  // Collected Revenue
  let collectedRevenue = chartData
    .filter((job) => job.paid === "Yes")
    .reduce((acc, job) => acc + job.totalPaid, 0)
    ?.toFixed(2);

  // Total collected via card
  const collectedViaCard = chartData
    .filter(
      (job) =>
        job.paymentType === "digital invoice" ||
        job.paymentType === "manual card"
    )
    .reduce((acc, job) => acc + job.totalPaid, 0)
    ?.toFixed(2);

  // Total collected via check
  const collectedViaCheck = chartData
    .filter((job) => job.paymentType === "check")
    .reduce((acc, job) => acc + job.totalPaid, 0)
    ?.toFixed(2);

  // Total collected via cash
  const collectedViaCash = chartData
    .filter((job) => job.paymentType === "cash")
    .reduce((acc, job) => acc + job.totalPaid, 0)
    ?.toFixed(2);

  // Total collected via other
  const collectedViaOther = chartData
    .filter(
      (job) =>
        job.paid === "Yes" &&
        job.paymentType !== "digital invoice" &&
        job.paymentType !== "manual card" &&
        job.paymentType !== "check" &&
        job.paymentType !== "cash"
    )
    .reduce((acc, job) => acc + job.totalPaid, 0)
    ?.toFixed(2);

  // Total amount unpaid
  const unpaidJobsAmount = chartData
    .filter((job) => job.paid === "No")
    .reduce((acc, job) => acc + job.totalAmount, 0)
    ?.toFixed(2);

  // Average Job Size of All Jobs
  const totalJobs = chartData.length;
  const totalJobAmount = chartData.reduce(
    (acc, job) => acc + job.totalAmount,
    0
  );
  const averageJobSize =
    totalJobs > 0 ? (totalJobAmount / totalJobs).toFixed(2) : 0;

  // Average Job Size of Paid jobs
  const totalJobAmountPaid = chartData
    .filter((job) => job.paid === "Yes")
    .reduce((acc, job) => acc + job.totalPaid, 0);

  const averageJobSizePaid =
    totalJobs > 0 ? (totalJobAmountPaid / totalJobs).toFixed(2) : 0;

  const chartDataFormatted = {
    labels: [
      "Collected Revenue",
      "Paid via Card",
      "Paid via Check",
      "Paid via Cash",
      "Paid via Other",
      "Amount Unpaid",
      "Average Job Size (all)",
      "Average Job Size (paid)",
    ],
    datasets: [
      {
        label: "",
        data: [
          collectedRevenue,
          collectedViaCard,
          collectedViaCheck,
          collectedViaCash,
          collectedViaOther,
          unpaidJobsAmount,
          averageJobSize,
          averageJobSizePaid,
        ],
        backgroundColor: [
          "rgba(28, 99, 191, 0.6)",
          "rgba(218, 228, 123, 0.6)",
          "rgba(0, 0, 0, 0.6)",
          "rgba(123, 211, 212, 0.6)",
        ],
      },
    ],
  };
  return (
    <>
      <Bar
        data={chartDataFormatted}
        options={{
          plugins: {
            title: {
              display: false,
              text: "Daily Job Revenue",
              color: "#1f2937", // Color of the title
              font: {
                size: 16,
              },
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  const value = context.parsed.y;
                  label += `$${value.toLocaleString()}`;
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                color: "rgba(255, 255, 255, 0.3)", // Color of the vertical grid lines
              },
              ticks: {
                color: "#1f2937", // Color of the x-axis labels
              },
            },
            y: {
              min: 0,
              beginAtZero: true,
              grid: {
                color: "rgba(255, 255, 255, 0.3)", // Color of the horizontal grid lines
              },
              ticks: {
                color: "#1f2937", // Color of the y-axis labels
                callback: function (value, index, values) {
                  return `$${value.toLocaleString()}`; // This adds the $ before the value
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        }}
      />
    </>
  );
}

export default JobStats;

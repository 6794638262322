import React, { useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import UpgradeBanner from "./UpgradeBanner";

export default function TimeLeftBanner({ timeLeft }) {
  const isThereTimeLeft = timeLeft > 0;

  return (
    <>
      <div className="relative bg-sky-100 w-full z-999 box-border">
        <div className="px-4 h-14 flex flex-row items-center justify-between">
          {isThereTimeLeft ? (
            <h1 className="text-gray-600">
              You have {timeLeft} days left in your trial
            </h1>
          ) : (
            <h1 className="text-gray-600">Your trial has ended</h1>
          )}
          <button className="ml-4 bg-gray-50 hover:bg-gray-100 text-gray-600 hover:text-yellow-500 font-bold py-0.5 px-2 text-sm rounded">
            <Link to="/new-subscription">Choose Plan</Link>
          </button>
        </div>

        {/* <UpgradeBanner /> */}
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { ImTarget } from "react-icons/im";

import { TbWorldUpload } from "react-icons/tb";
import { BsPersonPlusFill } from "react-icons/bs";
import { TbLayoutList } from "react-icons/tb";
import { AiOutlineEdit } from "react-icons/ai";

import SalesPersonSearch from "../../AddJob/components/SalesPersonSearch";

export default function LeadSourceJobDetails({ toggleLeadSourceModal, leadSource }) {
  const [online, setOnline] = useState(false);
  // const [referral, setReferral] = useState(false)
  const [direct, setDirect] = useState(false);
  const [other, setOther] = useState(false);
  const [sales, setSales] = useState(false);

  useEffect(() => {
    setOnline(leadSource?.online ? true : false);
    setDirect(leadSource?.direct ? true : false);
    setOther(leadSource?.other ? true : false);
    setSales(leadSource?.salesPerson ? true : false);
  }, [leadSource]);

  return (
    <div className="flex flex-col flex-1 bg-white rounded-md shadow-bold  gap-2 mt-8 pb-8 p-4">
      <div className="flex flex-row items-center justify-between gap-2 mt-2 mb-2">
        <div className="flex flex-row">
          <ImTarget className="inline-block text-2xl mr-2" />
          <h1 className="text-xl font-bold">Lead Source</h1>
        </div>
        <AiOutlineEdit onClick={toggleLeadSourceModal} className="text-lg cursor-pointer ml-2" />
      </div>

      <div className="relative grid grid-cols-2 gap-2 ">
        <div
          className={`flex flex-row items-center w-full px-2 gap-2 py-3 border border-gray-300 rounded-md text-sm first-letter:focus:outline-none bg-gray-50 text-gray-700 font-medium ${
            sales ? "bg-gray-800 text-yellow-100 font-semibold" : "bg-white text-gray-700 "
          } `}
        >
          <TbLayoutList className={`${sales ? "text-yellow-300" : "text-gray-700"} text-xl`} />
          {leadSource?.salesPerson ? leadSource.salesPerson.firstName : "Sales Person"}
        </div>

        <div
          className={`flex flex-row items-center w-full px-2 gap-2 py-3 border border-gray-300 rounded-md text-sm font-medium focus:outline-none   ${
            online ? "bg-gray-800 text-yellow-100 font-semibold" : "bg-white text-gray-700 "
          }`}
        >
          <TbWorldUpload className={`${online ? "text-yellow-300" : "text-gray-700"} text-xl`} />{" "}
          Online
        </div>
        <div
          className={`flex flex-row items-center w-full px-2 gap-2 py-3 border border-gray-300 rounded-md text-sm font-medium focus:outline-none ${
            direct ? "bg-gray-800 text-yellow-100 font-semibold" : "bg-white text-gray-700 "
          }`}
        >
          <BsPersonPlusFill className={`${direct ? "text-yellow-300" : "text-gray-700"} text-xl`} />
          Direct
        </div>
        <div
          className={`flex flex-row items-center w-full px-2 gap-2 py-3 border border-gray-300 rounded-md text-sm font-medium focus:outline-none ${
            other ? "bg-gray-800 text-yellow-100 font-semibold" : "bg-white text-gray-700 "
          }`}
        >
          Other
        </div>
      </div>
    </div>
  );
}

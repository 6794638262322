import React, { useState, useEffect, useRef } from "react";
import { StarIcon } from "@heroicons/react/24/outline";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "utils/firebase";
import { node } from "constants/constants";

const StarRating = ({ reviewData, setSentiment }) => {
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);

  async function handleUserRatedBad(rating) {
    console.log("handleBad");
    setSentiment("bad");
    //update the review doc with the rating

    const response = await fetch(`${node}/reviews/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        businessId: reviewData?.businessId,
        reviewId: reviewData?.reviewId,
        rating: rating,
        ratingSubmitted: new Date(),
        type: "rating",
      }),
    });
    const responseData = await response.json();
    console.log("response data", responseData);

    // const reviewRef = doc(
    //   db,
    //   "businesses",
    //   reviewData?.businessId,
    //   "reviews",
    //   reviewData?.reviewId
    // );
    // await updateDoc(reviewRef, {
    //   rating: rating,
    //   ratingSubmitted: new Date(),
    // });
  }

  async function handleUserRatedGood(rating) {
    console.log("handleGood");
    setSentiment("good");

    const response = await fetch(`${node}/reviews/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        businessId: reviewData?.businessId,
        reviewId: reviewData?.reviewId,
        rating: rating,
        ratingSubmitted: new Date(),
        type: "rating",
      }),
    });
    const responseData = await response.json();
    console.log("response data", responseData);
    // const reviewRef = doc(
    //   db,
    //   "businesses",
    //   reviewData?.businessId,
    //   "reviews",
    //   reviewData?.reviewId
    // );
    // await updateDoc(reviewRef, {
    //   rating: rating,
    //   ratingSubmitted: new Date(),
    // });
  }

  useEffect(() => {
    if (rating == null) {
      console.log("start");
    } else if (rating <= 3) {
      console.log("eject");
      handleUserRatedBad(rating);
    } else if (rating > 3) {
      console.log("engage");
      handleUserRatedGood(rating);
    }
  }, [rating]);

  return (
    <div className="flex items-center justify-center">
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;
        return (
          <label key={i}>
            <input
              type="radio"
              name="rating"
              className="invisible"
              value={ratingValue}
              onClick={(e) => setRating(ratingValue)}
            />
            <StarIcon
              id="star"
              color={ratingValue <= (hover || rating) ? "gold" : "#e5e7eb"}
              fill={ratingValue <= (hover || rating) ? "gold" : "#e5e7eb"}
              width={50}
              className="cursor cursor-pointer touch-auto ease-in duration-200"
              //  onPointerDown={(e)=>{setHover(ratingValue); console.log(ratingValue);console.log(e); }}
              onPointerEnter={(e) => {
                setHover(ratingValue);
                console.log(ratingValue);
                console.log(e);
              }}
              onPointerLeave={(e) => {
                setHover(null);
                console.log(ratingValue);
                console.log(e);
              }}

              //  onMouseEnter={()=>{setHover(ratingValue); console.log(ratingValue)}}
              //  onMouseLeave={()=>{setHover(null) ; console.log(ratingValue)}}
            />
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;

import React, { useState, useEffect, useRef } from "react";

function CustomDropdown({
  initial,
  selectedTime,
  setSelectedTime,
  timesWrong,
  setTimeHasBeenChanged,
}) {
  const [showOptions, setShowOptions] = useState(false);

  const options = [
    "12:00 AM",
    "12:30 AM",
    "1:00 AM",
    "1:30 AM",
    "2:00 AM",
    "2:30 AM",
    "3:00 AM",
    "3:30 AM",
    "4:00 AM",
    "4:30 AM",
    "5:00 AM",
    "5:30 AM",
    "6:00 AM",
    "6:30 AM",
    "7:00 AM",
    "7:30 AM",
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
    "8:30 PM",
    "9:00 PM",
    "9:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
  ];

  const editButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    //create event listener for click outside of dropdown to close it
    const handleClickOutside = (e) => {
      if (
        showOptions &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !editButtonRef.current.contains(e.target)
      ) {
        console.log("clicked outside");
        setShowOptions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showOptions, dropdownRef]);

  useEffect(() => {
    if (showOptions && selectedTime) {
      const selectedOptionIndex = options.indexOf(selectedTime);
      if (selectedOptionIndex > -1) {
        const optionElements = dropdownRef.current.querySelectorAll("li");
        const selectedOption = optionElements[selectedOptionIndex];
        const container = dropdownRef.current;
        const scrollTop = selectedOption.offsetTop - container.offsetTop;
        container.scrollTop = scrollTop;
        selectedOption.classList.add("bg-gray-100");
      }
    }
  }, [showOptions, selectedTime]);

  const handleOptionClick = (option) => {
    setSelectedTime(option);
    if (setTimeHasBeenChanged) {
      setTimeHasBeenChanged(true);
    }

    setShowOptions(false);
  };

  const handleDropdownClick = (event) => {
    event.preventDefault();
    setShowOptions(!showOptions);
  };

  // weird bug going on with border
  return (
    <div
      className="relative ml-4"
      // style={{
      //   marginLeft: "30px",
      // }}
    >
      <button
        onClick={handleDropdownClick}
        className={`${
          timesWrong ? "bg-red-300" : "bg-white"
        } border border-gray-300  rounded-md px-4 py-2 w-28 text-center focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent`}
        ref={editButtonRef}
      >
        {selectedTime}
      </button>
      {showOptions && (
        <ul
          className="absolute mt-1 w-36 rounded-md bg-white shadow-lg max-h-60 overflow-y-auto z-10"
          ref={dropdownRef}
        >
          {options.map((option) => (
            <li
              key={option}
              onClick={() => handleOptionClick(option)}
              className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default CustomDropdown;

import React, { useState, useEffect, useRef } from "react";
import InputWithLabel from "../../features/AddJob/components/InputWithLabel";
import { TextField } from "../reusable/fields";

export default function ManualAddress({ label, address, setAddress, notFilledIn, ...props }) {
  const [addressRaw, setAddressRaw] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [unit, setUnit] = useState("");
  const [country, setCountry] = useState("United States");

  // set formatted address
  useEffect(() => {
    if (!city && !state && !zip && addressRaw) {
      setAddress(addressRaw);
    } else if (city && state && addressRaw && country && zip) {
      const updatedAddress = `${addressRaw},${
        unit ? " " + unit + "," : ""
      } ${city}, ${state} ${zip} ${country === "United States" ? "US" : "CA"}`;
      setAddress(updatedAddress);
    } else {
      setAddress("");
    }
  }, [addressRaw, unit, city, state, zip, country]);

  return (
    <div className="relative mt-5  col-span-ful" {...props}>
      <label className=" text-gray-900">Address</label>
      <input
        required={true}
        className={`block w-full appearance-none rounded-md border ${
          notFilledIn
            ? "border-red-300 focus:border-red-200 focus:ring-red-200"
            : "border-gray-200 focus:border-yellow-500 focus:ring-yellow-500"
        } bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400  focus:bg-white focus:outline-none  sm:text-sm`}
        value={addressRaw}
        label="Address"
        onChange={(e) => setAddressRaw(e.target.value)}
        autoComplete="off"
      />

      <div className="w-full grid grid-cols-2 justify-center gap-4 mt-5 ">
        <TextField
          className="col-span-1"
          label="Unit"
          value={unit}
          onChange={(e) => setUnit(e.target.value)}
        />
        <TextField
          required={true}
          className="col-span-1"
          inputClassName={` ${
            notFilledIn
              ? "border-red-300 focus:border-red-200 focus:ring-red-200"
              : "border-gray-200 focus:border-yellow-500 focus:ring-yellow-500"
          } `}
          label="Zip"
          value={zip}
          onChange={(e) => setZip(e.target.value)}
        />
      </div>
      <div className="w-full grid grid-cols-4 justify-center gap-4 mt-5 ">
        <TextField
          required={true}
          className="col-span-1"
          inputClassName={` ${
            notFilledIn
              ? "border-red-300 focus:border-red-200 focus:ring-red-200"
              : "border-gray-200 focus:border-yellow-500 focus:ring-yellow-500"
          } `}
          label="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <TextField
          required={true}
          className="col-span-1"
          inputClassName={` ${
            notFilledIn
              ? "border-red-300 focus:border-red-200 focus:ring-red-200"
              : "border-gray-200 focus:border-yellow-500 focus:ring-yellow-500"
          } `}
          label="State"
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
        <div className="col-span-2">
          <label className="mb-2 block text-sm font-medium text-gray-700">Country</label>
          <select
            className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-yellow-500 focus:bg-white focus:outline-none focus:ring-yellow-500 sm:text-sm"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            <option value="United States">United States</option>
            <option value="Canada">Canada</option>
          </select>
        </div>
      </div>
    </div>
  );
}

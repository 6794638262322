import React, { useState } from "react";

export default function InputWithLabel({
  value,
  setValue,
  label,
  onChangeFunction = null,
  ...props
}) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="relative flex-1 flex flex-col " {...props}>
      <input
        className={
          `bg-gray-100 border-b-2 border-black flex-1 rounded-sm border-r-0 outline-none focus:border-b-yellow-400 text-lg ` +
          (value ? "pt-6 pb-0 px-4" : "pt-4 pb-2 px-4")
        }
        disabled={props.disabled ? true : false}
        value={value}
        placeholder={label}
        onChange={onChangeFunction || handleChange}
      />
      {value && (
        <label
          className="absolute top-0 left-0 p-3 font-medium text-gray-600"
          style={{
            pointerEvents: "none",
            transition: "all 0.3s ease",
            transform: "translateY(-10px) translateX(-5px) scale(0.8)",
            opacity: value ? 1 : 0,
          }}
        >
          {label}
        </label>
      )}
    </div>
  );
}

import React, { useState, useRef, useEffect, useContext } from "react";
import { useUserContext } from "context/UserContext";
import CustomersSearch from "./CustomersSearch";
import { TbLayoutList } from "react-icons/tb";
import algoliasearch from "algoliasearch";
import BasicSelect from "components/reusable/BasicDropdown";
import { db } from "utils/firebase";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";
import AdvancedFiltersSidebar from "./AdvancedFiltersSidebar";
import { IoFilter } from "react-icons/io5";
import Tooltip from "@mui/material/Tooltip";

export default function CustomersList({
  customerSearchInput,
  setCustomerSearchInput,
  selectedCustomer,
  setSelectedCustomer,
  customers,
  filteredCustomers,
  setFilteredCustomers,
  modal,
  navigate,
  loadingCustomers,
  customerCount,
  setRerun,
  getCustomersNext,
  notAbleToViewCustomers,
  notAbleToEditCustomers,
  setLoadingCustomers,
}) {
  const { userData } = useUserContext();
  const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY
  );
  const index = client.initIndex("customers");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const filterButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [viewingSearchResults, setViewingSearchResults] = useState(false);
  const [preFilteredCustomers, setPreFilteredCustomers] = useState([]);

  const [selectedTag, setSelectedTag] = useState("");
  const [tags, setTags] = useState([]); // will be fetched from firestore

  const [openAdvancedFilters, setOpenAdvancedFilters] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    created: false,
    lastJob: false,
    city: false,
  });

  useEffect(() => {
    // Fetch tags from the tags collection
    const fetchTags = async () => {
      const tagRef = doc(
        db,
        "businesses",
        userData?.bizData?.id,
        "tags",
        "tags"
      );
      const snapshot = await getDoc(tagRef);
      if (!snapshot.exists()) {
        setTags([]);
        return;
      }
      const fetchedTags = snapshot.data().tags;
      setTags(fetchedTags);
    };

    fetchTags();
  }, []);

  const handleTagsChange = (newSelectedTag, clearedFromTimeframeFilter) => {
    // console.log(
    //   "newselectedTag",
    //   newSelectedTag,
    //   "selectedTag",
    //   selectedTag,
    //   "clearedFromTimeframeFilter",
    //   clearedFromTimeframeFilter
    // );
    // if selected tag is the same as before then we set selectedTag to empty string
    if (newSelectedTag === "clear") {
      // console.log("selected clear so we reset");
      if (!clearedFromTimeframeFilter) {
        setSelectedFilter("All");
        setFilteredCustomers(customers);
      }

      setSelectedTag("");

      return;
    }

    setSelectedTag(newSelectedTag);
  };

  useEffect(() => {
    // Perform Firestore query when tags are selected
    const fetchCustomersByTags = async () => {
      setIsLoading(true);
      // console.log("fetching", selectedTag);

      const customersRef = collection(
        db,
        "businesses",
        userData?.bizData?.id,
        "customers"
      );
      const querySnapshot = await getDocs(
        query(customersRef, where("tags", "array-contains", selectedTag))
      );
      // let query = customersRef;
      // selectedTags.forEach((tag) => {
      //   query = query.where("tags", "array-contains", tag);
      // });
      // const querySnapshot = await getDocs(query);

      const fetchedCustomers = querySnapshot.docs.map((doc) => doc.data());

      // console.log("fetchedCustomers", fetchedCustomers);
      // console.log("fetchedCustomer.length", fetchedCustomers.length);
      setFilteredCustomers(fetchedCustomers);

      setIsLoading(false);
    };

    // only fetch it's between 1 and 5 length
    if (selectedTag) {
      fetchCustomersByTags();
    }
  }, [selectedTag]);

  const handleAlgoliaSearch = async () => {
    if (notAbleToViewCustomers) return;
    if (!customerSearchInput) return;
    if (isLoading) return;
    // console.log("handleAlgoliaSearch");
    // console.log("customerSearchInput", customerSearchInput);
    const businessId = userData.userData.businessId;

    try {
      setIsLoading(true);
      const { hits } = await index.search(customerSearchInput, {
        filters: `businessId:${businessId}`,
      });
      // console.log("hits", hits);
      // setViewingSearchResults(true);
      setPreFilteredCustomers(hits);
    } catch (err) {
      console.error("Error with search", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (notAbleToViewCustomers) return;
    if (!customerSearchInput || customerSearchInput === "") {
      setViewingSearchResults(false);
      setFilteredCustomers(customers);
      return;
    } else {
      setViewingSearchResults(true);
      handleAlgoliaSearch();
    }
  }, [customerSearchInput]);

  const filterOptions = [
    "All",
    "Created > 3 months ago",
    "Created > 6 months ago",
    "Created > 1 year ago",
    "Last job > 3 months ago",
    "Last job > 6 months ago",
    "Last job > 1 year ago",
  ];

  function handleCustomerSelect(customer) {
    if (notAbleToViewCustomers) return;
    // console.log("customer was selected:", customer);
    setSelectedCustomer(customer);
    navigate(`/customers/${customer.customerId}`, {
      state: { customerId: customer.customerId },
    });
  }

  function handleFilterClick(value) {
    setSelectedFilter(value);
    handleTagsChange("clear", true); // clear tags to not confuse people
    setShowDropdown(false);
  }

  // create a date ojbect for two days ago
  const twoDaysAgo = new Date() - 172800000;
  // create a date object for a month ago
  const oneMonthAgo = new Date() - 2592000000;

  // create a date object for 3 months ago
  const threeMonthsAgo = new Date() - 7776000000;
  // create a date object for 6 months ago
  const sixMonthsAgo = new Date() - 15552000000;
  // create a date object for 1 year ago
  const oneYearAgo = new Date() - 31104000000;

  useEffect(() => {
    if (!customers || customers.length == 0) return;
    if (!selectedFilter) return;
    let firstFilter = customers;
    let customersToFilter = customers;
    if (viewingSearchResults) {
      firstFilter = preFilteredCustomers;
      customersToFilter = preFilteredCustomers;
    }

    function getDateObject(date) {
      if (!date) {
        console.log("date is undefined");
        return;
      }
      if (date instanceof Date) {
        return date; // already a JavaScript Date object
      } else if (date?.hasOwnProperty("seconds")) {
        return new Date(date.seconds * 1000); // Firestore Timestamp
      } else if (typeof date === "number") {
        return new Date(date); // Unix timestamp
      }
      throw new Error(`Invalid date: ${date}`);
    }

    if (selectedFilter === "All") {
    } else if (selectedFilter === "Created > 3 months ago") {
      firstFilter = customersToFilter.filter(
        (customer) => getDateObject(customer.dateAdded) < threeMonthsAgo
      );
    } else if (selectedFilter === "Created > 6 months ago") {
      firstFilter = customersToFilter.filter(
        (customer) => getDateObject(customer.dateAdded) < sixMonthsAgo
      );
    } else if (selectedFilter === "Created > 1 year ago") {
      firstFilter = customersToFilter.filter(
        (customer) => getDateObject(customer.dateAdded) < oneYearAgo
      );
    } else if (selectedFilter === "Last job > 3 months ago") {
      firstFilter = customersToFilter.filter(
        (customer) => getDateObject(customer?.lastJob) < threeMonthsAgo
      );
    } else if (selectedFilter === "Last job > 6 months ago") {
      firstFilter = customersToFilter.filter(
        (customer) => getDateObject(customer?.lastJob) < sixMonthsAgo
      );
    } else if (selectedFilter === "Last job > 1 year ago") {
      firstFilter = customersToFilter.filter(
        (customer) => getDateObject(customer?.lastJob) < oneYearAgo
      );
    }

    setFilteredCustomers(firstFilter);
  }, [selectedFilter, customers, viewingSearchResults, preFilteredCustomers]);

  const handleScroll = (e) => {
    // console.log("handleScroll");
    // console.log("bottom", e.target.scrollHeight - e.target.scrollTop);
    if (viewingSearchResults) {
      // console.log("viewingSearchResults");
      return;
    }

    // if any of the applied filters are true then we return -- that way we will never fetch more customers
    if (
      appliedFilters?.created ||
      appliedFilters?.lastJob ||
      appliedFilters?.city
    ) {
      return;
    }
    // const bottom =
    //   e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 5;

    // console.log("bottom", bottom);
    if (bottom && !isLoading) {
      // console.log("bottom of container");
      setIsLoading(true);
      getCustomersNext().then(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    // create event listener for click outside of dropdown if click is done anywhere then set the dropdown to false
    const handleClickOutside = (event) => {
      if (
        showDropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !filterButtonRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef, filterButtonRef, showDropdown]);

  const NotAbleToViewView = () => {
    return (
      <>
        <div className="relative flex flex-col w-full">
          {[...Array(9)].map((_, index) => (
            <div
              key={index}
              className="flex flex-col shrink-0 justify-start space-x-4 animate-pulse bg-gray-100 rounded h-16 "
            >
              <div className="flex flex-row shrink-0 items-start justify-start space-x-4 animate-pulse mt-6 ">
                <div className="w-3/12 ml-3 h-6 bg-gray-200 rounded"></div>
                <div className="w-4/12 mr-3 h-6 bg-gray-200 rounded"></div>
                <div className="w-3/12 mr-3 h-6 bg-gray-200 rounded"></div>
                <div className="w-2/12 pr-3 h-6 bg-gray-200 rounded"></div>
              </div>
            </div>
          ))}

          {/* Overlay Message */}
          <div className="absolute top-1/4 left-0 right-0 flex items-center justify-center">
            <div className="bg-white p-4 rounded-md text-center shadow-lg">
              <h1 className="text-xl font-semibold text-gray-900">
                It looks like you don't have permission to view customers.
              </h1>
              <p className="text-gray-700">
                If this is a mistake please contact your admin.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <AdvancedFiltersSidebar
        open={openAdvancedFilters}
        onClose={() => setOpenAdvancedFilters(false)}
        setFilteredCustomers={setFilteredCustomers}
        setIsLoadingCustomers={setIsLoading}
        setLoadingCustomersIndicator={setLoadingCustomers}
        userData={userData}
        setAppliedFilters={setAppliedFilters}
      />
      <div className="bg-white shadow-bold flex flex-col relative lg:self-start rounded-md h-4/6 lg:h-full ">
        <div className="flex flex-row justify-between py-3 px-3 rounded-t-md bg-gray-800 h-20 items-center">
          <CustomersSearch
            label="Search all"
            inputValue={customerSearchInput}
            setInputValue={setCustomerSearchInput}
            selected={selectedCustomer}
            setSelected={setSelectedCustomer}
            customers={customers}
            className="relative flex flex-col w-1/2"
          />
          {/* <button
            className="bg-gray-50 text-gray-900 font-bold px-4 rounded-md shadow-md flex flex-row h-12 items-center "
            onClick={handleAlgoliaSearch}
          >
            Search
          </button> */}
          <div className="flex flex-row items-center">
            {tags && tags.length > 0 && (
              <div className="mx-2">
                <BasicSelect
                  value={selectedTag}
                  setValue={handleTagsChange}
                  options={tags.map((tag) => ({ value: tag, label: tag }))}
                  label="Tags"
                  width={100}
                  // multiple
                  backgroundColor="#374151"
                  focusedLabelColor="#fde047"
                  borderColor="#6b7280"
                  labelColor="#d1d5db"
                  selectedTextColor="#f9fafb"
                  arrowColor="#f9fafb"
                  clear={true}
                />
              </div>
            )}

            <div className="mx-2">
              <BasicSelect
                value={selectedFilter}
                setValue={handleFilterClick}
                options={filterOptions.map((option) => ({
                  value: option,
                  label: option,
                }))}
                label="Timeframe"
                width={100}
                // multiple
                backgroundColor="#374151"
                focusedLabelColor="#fde047"
                borderColor="#6b7280"
                labelColor="#f9fafb"
                selectedTextColor="#f9fafb"
                arrowColor="#f9fafb"
              />
            </div>
            <div className="mx-2">
              <Tooltip title="Advanced Filters" arrow>
                <button
                  onClick={() => setOpenAdvancedFilters(true)}
                  className=" text-gray-50 font-bold px-1 rounded-md shadow-md flex flex-row h-12 items-center "
                >
                  <IoFilter className="text-xl" />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="flex flex-row font-semibold items-center justify-start py-4 px-3 gap-3 border-b-2 border-yellow-300 bg-gray-100 ">
          <h1 className="w-3/12 ">Client</h1>
          <h1 className="w-4/12 ">Address</h1>
          <h1 className="w-3/12 ">Phone</h1>
          <h1 className="w-2/12 text-end pr-42">Created At</h1>
        </div>
        <div
          className="flex flex-col relative  h-customers-map overflow-auto pb-6"
          onScroll={(e) => handleScroll(e)}
        >
          {notAbleToViewCustomers ? (
            <NotAbleToViewView />
          ) : loadingCustomers ? (
            <>
              {[...Array(9)].map((_, index) => (
                <div
                  key={index}
                  className="flex flex-col shrink-0 justify-start  space-x-4 animate-pulse bg-gray-100 rounded h-16 "
                >
                  <div className="flex flex-row shrink-0 items-start justify-start  space-x-4 animate-pulse mt-6 ">
                    <div className="w-3/12 ml-3 h-6 bg-gray-200 rounded"></div>
                    <div className="w-4/12 mr-3 h-6 bg-gray-200 rounded"></div>
                    <div className="w-3/12 mr-3 h-6 bg-gray-200 rounded"></div>
                    <div className="w-2/12 pr-3 h-6 bg-gray-200 rounded"></div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {filteredCustomers && filteredCustomers.length > 0 ? (
                filteredCustomers.map((customer, key) => (
                  <div
                    key={key}
                    className="flex flex-row items-center justify-start py-4 hover:bg-gray-50 hover:cursor-pointer "
                    onClick={() => handleCustomerSelect(customer)}
                  >
                    <h1 className="w-3/12 ml-3 mr-3 ">{`${
                      customer?.firstName || ""
                    } ${customer?.lastName || ""}`}</h1>
                    <h1 className="w-4/12 mr-3 ">{customer.address?.[0]}</h1>
                    <h1 className="w-3/12 mr-3 ">
                      {customer.phone?.mobile &&
                        "(" +
                          customer.phone.mobile.substring(2, 5) +
                          ") " +
                          customer.phone.mobile.substring(5, 8) +
                          "-" +
                          customer.phone.mobile.substring(8, 12)}
                    </h1>
                    {customer.dateAdded && (
                      <h1 className="w-2/12  text-end pr-4 ">
                        {customer.dateAdded && customer.dateAdded.seconds
                          ? new Date(
                              customer.dateAdded.seconds * 1000
                            ).toLocaleDateString()
                          : new Date(customer.dateAdded).toLocaleDateString()}
                      </h1>
                    )}
                    <hr />
                  </div>
                ))
              ) : (
                <>
                  {customers?.length === 0 && (
                    <>
                      {[...Array(9)].map((_, index) => (
                        <div
                          key={index}
                          className="flex flex-col shrink-0 justify-start  space-x-4 animate-pulse bg-gray-100 rounded h-16 "
                        >
                          <div className="flex flex-row shrink-0 items-start justify-start  space-x-4 animate-pulse mt-6 ">
                            <div className="w-3/12 ml-3 h-6 bg-gray-200 rounded"></div>
                            <div className="w-4/12 mr-3 h-6 bg-gray-200 rounded"></div>
                            <div className="w-3/12 mr-3 h-6 bg-gray-200 rounded"></div>
                            <div className="w-2/12 pr-3 h-6 bg-gray-200 rounded"></div>
                          </div>
                        </div>
                      ))}
                      {/* Overlay Message */}
                      <div className="absolute top-1/4 right-1/4 left-1/4 flex items-center justify-center">
                        <div className="bg-white p-4 rounded-md text-center shadow-lg">
                          <h1 className="text-xl font-semibold text-gray-900 ">
                            Start by creating a{" "}
                            <span className="font-bold underline-effect ">
                              customer
                            </span>{" "}
                          </h1>
                          <p className="text-gray-700">
                            Or click the three dots in the top right if you need
                            customers{" "}
                            <span className="font-bold underline-effect ">
                              imported.
                            </span>{" "}
                            Once you build up your customers then you can take
                            advantage of customized marketing blasts that will
                            keep them coming back.
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className={`absolute bottom-0 right-0 ${modal ? "z-0" : "z-10"}`}>
          <div
            className={`bg-gray-200 w-max text-right pr-5 pl-5 rounded-tl-md`}
          >
            Showing {filteredCustomers?.length ? filteredCustomers?.length : 0}{" "}
            of {customerCount}
          </div>
        </div>
      </div>
    </>
  );
}

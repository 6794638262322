import React from "react";
import { Line } from "react-chartjs-2";
function RevenueOverTime({ chartData }) {
  // console.log("chartData from monthly revenue", chartData);
  function formatDate(date) {
    if (date === "01") {
      return "Jan";
    } else if (date === "02") {
      return "Feb";
    } else if (date === "03") {
      return "Mar";
    } else if (date === "04") {
      return "Apr";
    } else if (date === "05") {
      return "May";
    } else if (date === "06") {
      return "Jun";
    } else if (date === "07") {
      return "Jul";
    } else if (date === "08") {
      return "Aug";
    } else if (date === "09") {
      return "Sep";
    } else if (date === "10") {
      return "Oct";
    } else if (date === "11") {
      return "Nov";
    } else if (date === "12") {
      return "Dec";
    }
  }
  const chartDataFormatted = {
    labels: chartData.map((data) => formatDate(data.date.slice(5))),
    datasets: [
      {
        label: "Job Revenue",
        data: chartData.map((data) => data.revenue),
        backgroundColor: [
          "rgba(28, 99, 191, 0.6)",
          "rgba(218, 228, 123, 0.6)",

          "rgba(0, 0, 0, 0.6)",
          "rgba(123, 211, 212, 0.6)",
        ],
        borderColor: "rgba(0, 0, 0, 0.6)",
        borderWidth: 1,

        tension: 0.4,
      },
    ],
  };

  return (
    <>
      <Line
        data={chartDataFormatted}
        options={{
          plugins: {
            title: {
              display: true,
              text: "Monthly Revenue",
              font: {
                size: 16,
              },
              color: "#1f2937",
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  let value = context.parsed.y;
                  value = parseFloat(value).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                  label += `$${value}`;
                  return label;
                },
              },
            },
          },
          scales: {
            y: {
              min: 0,
              beginAtZero: true,
              grid: {
                color: "rgba(255, 255, 255, 0.3)", // Color of the horizontal grid lines
              },
              ticks: {
                color: "#1f2937", // Color of the y-axis labels
                callback: function (value, index, values) {
                  return `$${value.toLocaleString()}`; // This adds the $ before the value
                },
              },
            },

            x: {
              grid: {
                color: "rgba(255, 255, 255, 0.3)", // Color of the vertical grid lines
              },
              ticks: {
                color: "#1f2937", // Color of the x-axis labels
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        }}
      />
    </>
  );
}
export default RevenueOverTime;

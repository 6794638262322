import React, { useState } from "react";
import { node } from "constants/constants";
import { useUserContext } from "context/UserContext";
import { Link } from "react-router-dom";
import NormalButton from "components/buttons/NormalButton";
import { createFreeTrialCheckoutSession } from "utils/stripe";

export default function NewTrial() {
  const { user, userData } = useUserContext();
  const [loading, setLoading] = useState(false);

  const handleCheckoutSession = async () => {
    if (userData?.subData?.status === "trialing" || userData?.subData?.status === "active") {
      console.log("User already has an active subscription");
      alert("You already have an active subscription.");
      return;
    }
    if (!userData?.userData?.businessId) {
      console.error("No business ID found for user:", userData?.userData?.businessId);
      alert("No business ID found for user. Please contact support.");
      return;
    }
    setLoading(true);

    const { url, error } = await createFreeTrialCheckoutSession({
      businessId: userData?.userData?.businessId,
    });
    if (url) {
      console.log("Billing portal session created:", url);
      window.location.href = url;
    } else {
      console.error("Error creating billing portal session:", error);
      alert("Error creating billing portal session. Please contact support.");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex h-full min-h-screen flex-col items-center overflow-auto bg-slate-100/90 font-stripe px-5">
        <h1
          className="mt-12 flex justify-center pl-2  text-2xl font-semibold text-slate-600 "
          // style={{
          //   width: "920px",
          // }}
        >
          <img
            src="/images/Homebase-logo-black.png"
            // className="h-14 w-auto"
            width={200}
            alt="Homebase logo"
          />
        </h1>
        <div className="flex flex-col items-center justify-center max-w-lg text-center mt-6 mb-6">
          You still need to activate your free trial to start using Homebase 360. Click the button
          below to get started.
        </div>

        <NormalButton
          onClick={handleCheckoutSession}
          text="Activate"
          loading={loading}
          width="w-40"
          additionalClasses="mt-5"
        />

        <div className="text-xs text-gray-500 mt-12 max-w-sm text-center">
          If you are having trouble, please contact us at support@homebase360.io or call us at
          415-831-7928.
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import { useUserContext } from "context/UserContext";

import { DataGrid } from "@mui/x-data-grid";
import { GiTargetPrize } from "react-icons/gi";
import { FaVideo } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import NormalButton from "components/buttons/NormalButton";

import { db } from "utils/firebase";
import { updateDoc, doc, setDoc } from "firebase/firestore";
import { useSnackBar } from "context/SnackBarContext";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { isBefore, isAfter } from "date-fns";
import { node } from "constants/constants";
import { Link } from "react-router-dom";
import { useFetchAndRankData } from "../hooks";
import PrizeTable from "./PrizeTable";
import { determineIfStarted } from "../utls";
import LeaderboardDataGrid from "./LeaderboardDataGrid";
import BasicSelect from "components/reusable/BasicDropdown";
import { revenueColumns, knockingColumns } from "../constants";
import CenteredMaxWidthLayout from "layouts/CenteredMaxWidthLayout";
import PreviewLeaderboard from "./PreviewLeaderboard";

export default function AuthenticatedGamesView({ countdown }) {
  const { userData, setUserData } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [loadingInviteSend, setLoadingInviteSend] = useState(false);
  const { openSnackBar } = useSnackBar();
  const { gamesData, loadingData } = useFetchAndRankData();
  const [leaderboardType, setLeaderboardType] = useState("revenue");

  const { formattedStart, formattedEnd, isStarted, hasEnded } =
    determineIfStarted();

  const registerForGames = async () => {
    // Register user logic
    setLoading(true);
    try {
      const bizRef = doc(db, "businesses", userData?.bizData?.id);
      // this will update the doc without changing previous registration data
      // if the hb games object doesn't exist then this will create it
      await setDoc(
        bizRef,
        {
          hbGames: {
            isRegisteredAugust: true,
          },
        },
        { merge: true }
      );

      setUserData((prev) => ({
        ...prev,
        bizData: {
          ...prev.bizData,
          hbGames: {
            ...prev.bizData.hbGames,
            isRegisteredAugust: true,
          },
        },
      }));

      openSnackBar("Successfully registered for games", true);
    } catch (error) {
      console.error("Error updating document: ", error);
      openSnackBar(
        "Failed to register for games, please try again.",
        false,
        true
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSendInvite = async () => {
    setLoadingInviteSend(true);
    const email = userData?.bizData?.email;
    const businessId = userData?.bizData?.id;

    try {
      // use fetch to do post request to above url
      const response = await fetch(`${node}/gamesApi/send-invite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          businessId,
        }),
      });

      const responseData = await response.json();
      // console.log("responseData", responseData);
      if (!response.ok) {
        throw new Error("Failed to send invite");
      }

      const bizRef = doc(db, "businesses", userData?.bizData?.id);
      updateDoc(bizRef, {
        "hbGames.inviteSentAugust": true,
      });

      setUserData((prev) => ({
        ...prev,
        bizData: {
          ...prev.bizData,
          hbGames: {
            ...prev.bizData.hbGames,
            inviteSentAugust: true,
          },
        },
      }));

      openSnackBar(`Invite sent to ${email}`, true);
    } catch (error) {
      openSnackBar("Failed to send invite, please try again.", false, true);
      console.error("Error hitting API via POST:", error);
    } finally {
      setLoadingInviteSend(false);
    }
  };

  const getRows = () => {
    if (leaderboardType === "revenue") {
      return gamesData.businessesWithStats;
    } else {
      return gamesData.allUserMarkerStats;
    }
  };

  const getColumns = () => {
    return leaderboardType === "revenue" ? revenueColumns : knockingColumns;
  };

  return (
    <div className="relative flex-1">
      <CenteredMaxWidthLayout>
        <div className="flex sm:flex-row sm:justify-between sm:items-start  flex-col-reverse">
          {!isStarted && !hasEnded ? (
            <div className="flex-1 ">
              <p className="text-gray-600 text-sm  mb-4 font-medium">
                Starts {formattedStart} - Ends {formattedEnd}
              </p>
              <div className="flex flex-row items-center w-full my-4">
                <div
                  className="flex items-center space-x-2  bg-yellow-300/80 font-medium px-3 py-0.5  shadow-md text-sm  mr-2"
                  style={{
                    borderRadius: "4px",
                  }}
                >
                  <span className=" text-gray-700">Starts In</span>
                </div>
                <div className="text-gray-700 font-medium text-sm">
                  {countdown}
                </div>
              </div>
            </div>
          ) : hasEnded ? (
            <div className="flex-1">
              <p className="text-gray-600 text-sm  mb-4 font-medium">
                Started {formattedStart} - Ended {formattedEnd}
              </p>
              <div className="flex flex-row items-center my-4">
                <div
                  className="flex items-center space-x-2  bg-red-500 font-medium px-3 py-0.5  shadow-md text-sm text-white"
                  style={{
                    borderRadius: "4px",
                  }}
                >
                  <span className="h-2 w-2 bg-red-50 rounded-full animate-blink-fast mr-1"></span>
                  ENDED
                </div>
              </div>
            </div>
          ) : (
            <div className="flex-1">
              <p className="text-gray-600 text-sm  mb-4 font-medium">
                Started {formattedStart} - Ends {formattedEnd}
              </p>
              <div className="flex flex-row items-center my-4">
                <div
                  className="flex items-center space-x-2  bg-green-500 font-medium px-3 py-0.5  shadow-md text-sm text-white"
                  style={{
                    borderRadius: "4px",
                  }}
                >
                  <span className="h-2 w-2 bg-red-50 rounded-full animate-blink-fast mr-1"></span>
                  LIVE - Updates Hourly
                </div>
                {/* <div className="text-gray-700 font-medium text-sm ml-1">
                Updates Hourly
              </div> */}
              </div>
            </div>
          )}

          {userData?.bizData?.hbGames?.isRegisteredAugust ? (
            <div className="flex sm:mb-0 mb-4">
              <div className="text-xs font-medium bg-gray-50 shadow-md border border-green-100 rounded-md px-3 py-1 text-nowrap">
                {userData?.bizData?.companyName} is registered 🎉
              </div>
            </div>
          ) : (
            <div className="flex sm:mb-0 mb-4">
              <NormalButton
                onClick={registerForGames}
                text="Register for games"
                width="w-48"
                loading={loading}
              />
            </div>
          )}
        </div>
        <div className="flex md:flex-row flex-col">
          <div className="md:w-8/12 w-full">
            {isStarted ? (
              <div className="mt-2 w-full">
                <div className="flex flex-row justify-center mb-2">
                  <BasicSelect
                    value={leaderboardType}
                    setValue={setLeaderboardType}
                    label="Change view"
                    labelColor="#4b5563"
                    focusedLabelColor="#4b5563"
                    width={250}
                    options={[
                      { value: "revenue", label: "Business Revenue" },
                      { value: "markers", label: "Doors Knocked" },
                    ]}
                  />
                </div>

                <LeaderboardDataGrid
                  rows={getRows()}
                  columns={getColumns()}
                  loading={loadingData}
                />
              </div>
            ) : (
              <PreviewLeaderboard />
            )}
          </div>

          {/* Right side cards */}
          <div
            className={`flex-1 mt-10 ${
              isStarted ? "md:-mt-6" : "md:mt-0"
            } md:ml-8`}
          >
            <PrizeTable />
            {/* 
          <div className="bg-white shadow p-4 rounded mb-4">
            <div className="flex flex-row items-start">
              <GiTargetPrize className="text-gray-700 mt-0.5 mr-1 ml-[-6px]" />
              <h3 className="font-bold text-gray-800 text-sm mb-2">
                Win a coaching session with Josh Lester
              </h3>
            </div>
            <p className="text-xs text-gray-600">
              Rank in the top 10 and win a 1 hour coaching call with Josh Lester
              to take your business to the next level.
            </p>
          </div>

          <div className="bg-white shadow p-4 rounded mb-4">
            <div className="flex flex-row items-start">
              <FaVideo className="text-gray-700 mt-1 mr-2 " size={14} />
              <h3 className="font-bold text-gray-800 text-sm mb-2">
                Josh & Oliver 1-day recordings
              </h3>
            </div>
            <p className="text-xs text-gray-600">
              Everyone who gets 5 jobs completed and paid through Homebase 360
              will unlock the 1-day with Josh & Oliver recordings.
            </p>
          </div>

          <div className="bg-white shadow p-4 rounded">
            <div className="flex flex-row items-start">
              <FaCheckCircle className="text-gray-700 mt-0.5 mr-2 " size={16} />
              <h3 className="font-bold text-gray-800 text-sm mb-2">
                Everyone Can Win
              </h3>
            </div>
            <p className="text-xs text-gray-600">
              Run 5 paid jobs through Homebase 360 to enter a draw and win a
              full $2000+ deionization tank and brush! Winner will also receive
              a triple state deionization tank. Second place will receive a 30
              foot waterfed pole. Third place will receive a screen cleaner.
            </p>
          </div> */}

            {userData?.userData?.isAdmin &&
              userData?.bizData?.hbGames?.isRegisteredAugust && (
                <div className="w-full flex justify-center mt-4">
                  {!userData?.bizData?.hbGames?.inviteSent ? (
                    <NormalButton
                      text="Skool Invite Link"
                      onClick={handleSendInvite}
                      loading={loadingInviteSend}
                      width="w-full max-w-48"
                    />
                  ) : (
                    <div className="w-full flex flex-col justify-center mt-4 text-xs text-center text-gray-500">
                      <p>Invite to Skool group has been sent</p>
                      <a
                        href="https://www.skool.com/homebase-games-9515"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline mt-2"
                      >
                        View the Skool group here
                      </a>
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      </CenteredMaxWidthLayout>
    </div>
  );
}

import React, { useEffect } from "react";
import { updateCustomerInSubscription } from "utils/firestoreCalls";

export function useUpdateCustomer({ subscription, setSubscription }) {
  useEffect(() => {
    if (
      !subscription?.customer?.customerId ||
      !subscription?.businessId ||
      !subscription?.subscriptionId
    ) {
      return;
    }

    updateCustomerInSubscription({
      businessId: subscription?.businessId,
      subscription,
      setSubscription,
    });
  }, [subscription?.customer?.customerId]);
  return null;
}

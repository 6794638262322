import React from "react";

import { ScrollArea, ScrollBar } from "components/ui/scroll-area";

export default function HorizontalScrollImages({ images, handleClick }) {
  return (
    <ScrollArea className="w-full h-auto py-4 whitespace-nowrap rounded-md">
      <div className="flex w-max space-x-4">
        {images.map((image, index) => (
          <figure
            key={index}
            className="shrink-0 w-32 h-32"
            onClick={() => handleClick(image)}
          >
            <div className="overflow-hidden h-full w-full rounded-md hover:cursor-pointer ">
              <img
                src={image}
                alt={`Image ${image}`}
                className="h-full w-full object-cover"
              />
            </div>
          </figure>
        ))}
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}

import React, { useContext, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { FaTruckPickup } from "react-icons/fa";
import { MdOutlineNotStarted } from "react-icons/md";
import { CiMoneyCheck1 } from "react-icons/ci";
import { BiSend } from "react-icons/bi";
import { AiOutlineStar } from "react-icons/ai";
import { format } from "date-fns";
import { UserContext } from "../../../index";
import { db } from "../../../utils/firebase";
import {
  collection,
  query,
  doc,
  setDoc,
  updateDoc,
  getDocs,
  getDoc,
  deleteDoc,
  where,
  serverTimestamp,
  increment,
  writeBatch,
} from "firebase/firestore";
import { sendMessage } from "../../../utils/twilio";
import { useParams } from "react-router-dom";
import { sendMessageRouteStart } from "../data/api";
import { formatDateTimezone } from "utils/helpers";
import { useSnackBar } from "context/SnackBarContext";
import { node } from "constants/constants";

export const JobButtons = ({
  jobId,
  jobDetails,
  setJobDetails,
  setManualPaymentModal,
}) => {
  const { userData } = useContext(UserContext);
  const timeZone = userData?.bizData?.timeZone;
  // const { jobId } = useParams();
  const jobRef = doc(
    db,
    "businesses",
    userData.userData.businessId,
    "jobs",
    jobId
  );
  const { openSnackBar } = useSnackBar();

  const [invoiceLoading, setInvoiceLoading] = useState(false);

  const handleDriveStartOrEnd = async () => {
    const to = jobDetails?.customer?.phone?.mobile;
    const from = null; // deprecated
    const businessId = userData.bizData?.id;
    const companyName = userData.bizData?.companyName;
    const customerName = jobDetails?.customer?.displayName;
    const notifications = jobDetails?.customer?.notifications;

    if (!jobDetails?.startDrivingTime && !jobDetails?.endDrivingTime) {
      await updateDoc(jobRef, {
        startDrivingTime: serverTimestamp(),
      });
      setJobDetails({
        ...jobDetails,
        startDrivingTime: new Date(),
      });

      // send message to customer
      const message =
        userData?.bizData?.onDriveStartMessage ||
        `Your technician is on their way to your location. \n\n- ${userData.bizData?.companyName}`;
      if (to && businessId && companyName && customerName && notifications) {
        await sendMessage(
          message,
          to,
          from,
          businessId,
          companyName,
          customerName
        );
      }
    } else if (jobDetails?.startDrivingTime && !jobDetails?.endDrivingTime) {
      await updateDoc(jobRef, {
        endDrivingTime: serverTimestamp(),
      });
      setJobDetails({
        ...jobDetails,
        endDrivingTime: new Date(),
      });
      //send message to customer
      const message =
        userData?.bizData?.onDriveEndMessage ||
        `Your technician has arrived.\n\n- ${userData.bizData?.companyName}`;
      if (to && businessId && companyName && customerName && notifications) {
        await sendMessage(
          message,
          to,
          from,
          businessId,
          companyName,
          customerName
        );
      }
    }
  };

  const handleJobStart = async () => {
    //update firestore document with startDrivingTime
    // console.log("handleJobStart");
    if (!jobDetails?.startedJobTime && !jobDetails?.endedJobTime) {
      await updateDoc(jobRef, {
        startedJobTime: serverTimestamp(),
      });
      setJobDetails({
        ...jobDetails,
        startedJobTime: new Date(),
      });
    } else if (jobDetails?.startedJobTime && !jobDetails?.endedJobTime) {
      await updateDoc(jobRef, {
        endedJobTime: serverTimestamp(),
      });
      setJobDetails({
        ...jobDetails,
        endedJobTime: new Date(),
      });
      //send message to customer
      const message =
        userData?.bizData?.onJobFinishMessage ||
        `Your work with ${userData.bizData?.companyName} has finished.`;
      const to = jobDetails?.customer?.phone?.mobile;
      const from = userData.bizData?.twilioNumber;
      const businessId = userData.bizData?.id;
      const companyName = userData.bizData?.companyName;
      const customerName = jobDetails?.customer?.displayName;
      const notifications = jobDetails?.customer?.notifications;

      if (to && businessId && companyName && customerName && notifications) {
        const response = await sendMessage(
          message,
          to,
          from,
          businessId,
          companyName,
          customerName
        );
        // console.log(response);
      } else {
        alert("missing data or notifications turned off");
        // console.log("missing data or notifcations turned off");
      }
    } else {
      // console.log("you've already finished the job");
    }
  };

  const handleInvoiceSend = async () => {
    if (
      !userData.bizData.stripeAccountId ||
      userData.bizData?.stripe_charges_enabled === false ||
      userData.bizData?.stripe_payouts_enabled === false ||
      userData.bizData?.stripe_details_submitted === false
    ) {
      alert(
        "You must set up Homebase360 payments before you can send an invoice. Please go to the Homebase360 payments page to connect your stripe account."
      );
      return;
    }
    if (
      !jobDetails ||
      !userData ||
      !userData.bizData ||
      !jobDetails?.jobTotal ||
      !jobDetails?.lineItems
    )
      return;

    if (invoiceLoading) return;
    setInvoiceLoading(true);

    let updating = false;

    try {
      const sentStatus = { email: false, text: false };
      // logic for generating the total amount with taxes included
      // we should probably take a more line item centric approach to this... so we add up the total based on the line items, and we also add up the total tax... but I mean the jobTotal should be accurate, it's just if we ever change the job total to reflect tax amount too then it is going to be super confusing...
      const jobTotal = parseFloat(jobDetails?.jobTotal);
      let tax = 0;
      if (jobDetails.lineItems?.length > 0) {
        jobDetails.lineItems.forEach((item) => {
          if (item?.taxRate) {
            let total =
              parseFloat(item?.unitPrice) * parseFloat(item?.quantity);
            let itemTax = total * (parseFloat(item?.taxRate.rate) / 100);
            tax += itemTax;
          }
        });
      }

      tax = parseFloat(parseFloat(tax).toFixed(2));

      const totalAmountWithTax = parseFloat(jobTotal) + parseFloat(tax);
      let trueInvoiceId = "";
      // check if an invoice exists connected to the job (the invoiceId is in the job doc)
      if (jobDetails?.invoiceId) {
        trueInvoiceId = jobDetails.invoiceId;
        // update the invoice
        await updateInvoice(trueInvoiceId, totalAmountWithTax, tax);
        updating = true;
        // Only send email when updating
        const emailBoolyan = await sendInvoiceEmail(
          trueInvoiceId,
          totalAmountWithTax
        );
        if (emailBoolyan) {
          sentStatus.email = true;
        }
      } else {
        // create new invoice --> this automatically sends invoice email

        trueInvoiceId = await createNewInvoice(totalAmountWithTax, tax);
      }

      if (!trueInvoiceId) {
        throw new Error("Failed to send invoice. Please try again.");
      }
      // send invoice text
      const textBoolyan = await sendInvoiceText(trueInvoiceId);
      if (textBoolyan) {
        sentStatus.text = true;
      }

      console.log("sentStatus", sentStatus);
      if (sentStatus.email && sentStatus.text) {
        openSnackBar("Invoice successfully sent via text and email", true);
      } else if (sentStatus.email) {
        openSnackBar("Invoice successfully sent via email", true);
      } else if (sentStatus.text) {
        openSnackBar("Invoice successfully sent via text", true);
      }

      // if we were updating, and it was successfully sent then we update last sent time in jobdoc
      if (updating && (sentStatus.email || sentStatus.text)) {
        await updateDoc(jobRef, {
          invoiceSentTime: serverTimestamp(),
          numberOfTimesSent: increment(1),
        });
      }
    } catch (error) {
      console.log(error);
      alert("Failed to send invoice. Please try again.");
    } finally {
      setInvoiceLoading(false);
    }
  };

  const updateInvoice = async (invoiceId, totalAmountWithTax, tax) => {
    const previousInvoiceRef = doc(
      db,
      "businesses",
      userData.userData?.businessId,
      "invoices",
      invoiceId
    );

    // changed to update the customer info incase it changes when we resend the invoice , this also updates the line items and total too
    await updateDoc(previousInvoiceRef, {
      numberOfTimesSent: increment(1),
      invoiceSentTime: serverTimestamp(),
      amount: totalAmountWithTax,
      tax: tax,
      lineItems: jobDetails?.lineItems,
      customerName: jobDetails?.customer?.displayName,
      customerEmail: jobDetails?.customer?.email,
      customerPhone: jobDetails?.customer?.phone?.mobile,
      customerAddress: jobDetails?.customer?.address?.[0] || "",
    });

    setJobDetails({
      ...jobDetails,
      invoiceSentTime: new Date(),
      numberOfTimesSent: jobDetails?.numberOfTimesSent + 1,
    });
  };

  const sendInvoiceEmail = async (invoiceId, totalAmountWithTax) => {
    // send invoice to customer via email ---> if we are resending
    const customerEmail = jobDetails?.customer?.email?.[0];
    if (!customerEmail) {
      return;
    }
    const emailResponse = await fetch(`${node}/invoice/send-invoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        invoiceId: invoiceId,
        businessId: userData?.userData?.businessId,
        customerEmail: customerEmail,
        businessEmail: userData?.bizData?.email,
        businessName: userData?.bizData?.companyName,
        customerName: jobDetails?.customer?.displayName,
        amount: totalAmountWithTax,
      }),
    });

    console.log("emailResponse", emailResponse);

    if (!emailResponse.ok) {
      console.log("failed to send via email");
      // alert("Failed to send invoice via email");
      return false;
    }

    return true;
  };

  const createNewInvoice = async (totalAmountWithTax, tax) => {
    const prevJobDetails = { ...jobDetails };
    try {
      const invoiceRef = doc(
        collection(db, "businesses", userData.userData?.businessId, "invoices")
      );
      setJobDetails({
        ...jobDetails,
        invoiceId: invoiceRef.id,
        invoiceSentTime: new Date(),
        numberOfTimesSent: 1,
      });

      // I like the idea of adding the invoice count on the backend... kind of makes more sense that way

      const invoiceData = {
        invoiceId: invoiceRef.id,
        jobId: jobDetails?.jobId,
        businessId: userData.userData.businessId,
        businessName: userData.bizData.companyName,
        businessAddress: userData.bizData.address,
        businessPhone: userData.bizData.companyPhone,
        businessEmail: userData.bizData.email,
        stripeAccountId: userData.bizData.stripeAccountId,
        dueDate: serverTimestamp(),
        customerId: jobDetails?.customer?.customerId,
        customerName: jobDetails?.customer?.displayName,
        customerEmail: jobDetails?.customer?.email,
        customerPhone: jobDetails?.customer?.phone?.mobile,
        customerAddress: jobDetails?.customer?.address?.[0],
        amount: totalAmountWithTax, // amount includes the tax
        tax: tax,
        status: "sent",
        billingType: "digital invoice",
        lineItems: jobDetails?.lineItems,
        serviceDate: jobDetails?.start,
        invoiceSentTime: serverTimestamp(),
        numberOfTimesSent: 1,
        invoicePaid: false,
        currency: userData.bizData?.currency,
        invoiceSettings: userData.bizData?.invoiceSettings || null,
      };
      await setDoc(invoiceRef, invoiceData);
      //update job document with invoiceSentTime
      await updateDoc(jobRef, {
        invoiceId: invoiceRef.id,
        invoiceSentTime: serverTimestamp(),
        numberOfTimesSent: 1,
      });
      return invoiceRef.id;
    } catch (error) {
      console.log(error);
      alert("Failed to create invoice. Please try again.");
      setJobDetails(prevJobDetails);
      return null;
    }
  };

  const sendInvoiceText = async (invoiceId) => {
    let preLinkMessage =
      userData?.bizData?.onInvoiceMessage ||
      `Here is our invoice. We appreciate your business. \n\n- ${userData.bizData?.companyName}`;
    const message =
      preLinkMessage +
      `\n\nhttps://app.homebase360.io/invoice?uid=${userData?.userData?.businessId}&invoiceId=${invoiceId}`;
    const to = jobDetails?.customer?.phone?.mobile;
    const businessId = userData.bizData?.id;
    const companyName = userData.bizData?.companyName;
    const customerName = jobDetails?.customer?.displayName;
    if (!to || !businessId || !companyName) {
      console.log("no phone number for text message");
      return false;
    }
    const response = await sendMessage(
      message,
      to,
      null,
      businessId,
      companyName,
      customerName
    );
    if (!response) {
      console.log("failed to send via text");
      // alert("Failed to send invoice via text");
      return false;
    }
    return true;
  };

  const handleManualPayment = async () => {
    // console.log("handleManualPayment");

    if (
      !userData.bizData.stripeAccountId ||
      userData.bizData?.stripe_charges_enabled === false ||
      userData.bizData?.stripe_payouts_enabled === false ||
      userData.bizData?.stripe_details_submitted === false
    ) {
      alert(
        "You must set up Homebase360 payments before you can accept payment with credit card. Please go to the Homebase360 payments page to connect your stripe account."
      );
      return;
    }

    setManualPaymentModal(true);
  };

  const handleReviewRequest = async () => {
    // need to build a different function if user has reviewFilterDisabled and they also have their review request message that exists in biz doc
    if (
      userData.bizData?.reviewFilterDisabled &&
      userData.bizData?.onReviewRequestMessage
    ) {
      await handleDisabledReviewRequest();
      return;
    }

    if (
      !userData.bizData.googleReviewLink &&
      !userData.bizData.yelpReviewLink &&
      !userData.bizData.facebookReviewLink
    ) {
      alert(
        "Please fill in your review links in your company profile, so you can receive reviews for your business!"
      );
      return;
    }

    const from = userData.bizData?.twilioNumber;

    const notifications = jobDetails?.customer?.notifications;
    // console.log("notifications", notifications);

    if (!notifications) {
      alert("You have notifications turned off for this customer.");
      return;
    }

    if (!jobDetails?.reviewId) {
      // create the review doc in buisnesses/{businessId}/reviews/{reviewId}
      const reviewRef = doc(
        collection(db, "businesses", userData.userData?.businessId, "reviews")
      );
      const reviewData = {
        reviewId: reviewRef.id,
        jobId: jobDetails?.jobId,
        customerId: jobDetails?.customer?.customerId,
        businessId: userData.userData.businessId,
        businessName: userData.bizData.companyName,
        businessAddress: userData.bizData.address,
        businessPhone: userData.bizData.companyPhone,
        email: userData.bizData.email,
        customerName: jobDetails?.customer?.displayName,
        customerEmail: jobDetails?.customer?.email,
        customerPhone: jobDetails?.customer?.phone?.mobile,
        reviewMessageSent: serverTimestamp(),
        googleReviewLink: userData.bizData.googleReviewLink,
        yelpReviewLink: userData.bizData.yelpReviewLink,
        facebookReviewLink: userData.bizData.facebookReviewLink,
      };

      await setDoc(reviewRef, reviewData);

      await updateDoc(jobRef, {
        reviewMessageSent: serverTimestamp(),
        reviewId: reviewRef.id,
      });
      setJobDetails({
        ...jobDetails,
        reviewMessageSent: new Date(),
        reviewId: reviewRef.id,
      });
      //send review request to customer via text
      //send message to customer
      let preLinkMessage =
        userData?.bizData?.onReviewRequestMessage ||
        `Please leave us a review. We appreciate your business. \n\n- ${userData.bizData?.companyName}`;
      const message =
        preLinkMessage +
        `\n\nhttps://app.homebase360.io/review?id=${userData.userData?.businessId}&reviewId=${reviewRef.id}`;

      const to = jobDetails?.customer?.phone?.mobile;

      const businessId = userData.bizData?.id;
      const companyName = userData.bizData?.companyName;
      const customerName = jobDetails?.customer?.displayName;

      if (to && businessId && companyName) {
        const response = await sendMessage(
          message,
          to,
          from,
          businessId,
          companyName,
          customerName
        );
        // console.log(response);
      } else {
        alert("missing data for sending text");
        // console.log("missing data");
      }
    } else if (jobDetails.reviewId) {
      // console.log("you've already sent the request!");
      // this is where we resend the review request
      //send message to customer
      // get review id from jobDetails and fetch the review doc

      //send message to customer
      let preLinkMessage =
        userData?.bizData?.onReviewRequestMessage ||
        `Please leave us a review. We appreciate your business. \n\n- ${userData.bizData?.companyName}`;
      const message =
        preLinkMessage +
        `\n\nhttps://app.homebase360.io/review?id=${userData.userData?.businessId}&reviewId=${jobDetails.reviewId}`;

      const to = jobDetails?.customer?.phone?.mobile;

      const businessId = userData.bizData?.id;
      const companyName = userData.bizData?.companyName;
      const customerName = jobDetails?.customer?.displayName;

      if (to && businessId && companyName) {
        const response = await sendMessage(
          message,
          to,
          from,
          businessId,
          companyName,
          customerName
        );
        console.log(response);
      } else {
        alert("Missing data for sending text");
        // console.log("missing data");
      }
    }
  };

  const handleDisabledReviewRequest = async () => {
    // This function will just take the onReviewRequestMessage from the bizData and send it to the customer and then mark the job as reviewMessageSent

    const message = userData?.bizData?.onReviewRequestMessage;

    const to = jobDetails?.customer?.phone?.mobile;

    const businessId = userData.bizData?.id;
    const companyName = userData.bizData?.companyName;
    const customerName = jobDetails?.customer?.displayName;

    if (to && businessId && companyName && message) {
      // Optimistically update the state
      const originalJobDetails = { ...jobDetails };
      setJobDetails({
        ...jobDetails,
        reviewMessageSent: new Date(),
      });
      try {
        const response = await sendMessage(
          message,
          to,
          null,
          businessId,
          companyName,
          customerName
        );
        if (!response) {
          throw new Error("Failed to send message");
        }

        await updateDoc(jobRef, {
          reviewMessageSent: serverTimestamp(),
        });
      } catch (error) {
        console.log(error);
        // Revert to the original state in case of an error
        setJobDetails(originalJobDetails);
        alert(
          "Failed to send message, please check to ensure the mobile number is valid and try again. If the problem persists, please contact support."
        );
      }
    }
  };

  let finishedInfoInvoice = "";
  if (
    jobDetails?.invoicePaidTime &&
    jobDetails.invoicePaidTime instanceof Date
  ) {
    finishedInfoInvoice = `Paid: ${format(
      jobDetails?.invoicePaidTime,
      "MM/dd/yy"
    )}`;
  } else if (jobDetails?.datePaid && jobDetails.datePaid instanceof Date) {
    finishedInfoInvoice = `Paid: ${format(jobDetails?.datePaid, "MM/dd/yy")}`;
  }

  let invoiceStatus = "inactive";
  if (jobDetails?.invoiceSentTime) {
    invoiceStatus = "inprogress";
  }
  if (jobDetails?.invoicePaid || jobDetails?.datePaid) {
    invoiceStatus = "finished";
  }

  let reviewStatus;
  let reviewInfoToDisplay;
  if (!jobDetails?.reviewMessageSent) {
    reviewStatus = "inactive";
  } else if (jobDetails?.reviewMessageSent && !jobDetails?.reviewSubmitted) {
    reviewStatus = "inprogress";
    reviewInfoToDisplay = `Sent: ${format(
      jobDetails?.reviewMessageSent,
      "MM/dd/yy"
    )}`;
  } else if (jobDetails?.reviewMessageSent && jobDetails?.reviewSubmitted) {
    reviewStatus = "finished";
    reviewInfoToDisplay = `Received: ${format(
      jobDetails?.reviewSubmitted,
      "MM/dd/yy"
    )}\nRating: ${jobDetails?.reviewRating}`;
  }

  const buttons = [
    {
      icon: FaTruckPickup,
      label: "Drive",
      handleClick: handleDriveStartOrEnd,
      labelInProgress: "End",
      labelFinished: "Arrived",
      status: !jobDetails?.startDrivingTime
        ? "inactive"
        : !jobDetails?.endDrivingTime
        ? "inprogress"
        : "finished",
      inprogressInfo:
        jobDetails?.startDrivingTime &&
        jobDetails.startDrivingTime instanceof Date
          ? `Start: ${format(jobDetails?.startDrivingTime, "h:mm a", {
              timeZone,
            })}`
          : "",
      finishedInfo:
        jobDetails?.startDrivingTime &&
        jobDetails?.endDrivingTime &&
        jobDetails.startDrivingTime instanceof Date &&
        jobDetails.endDrivingTime instanceof Date
          ? `Start: ${format(
              jobDetails?.startDrivingTime,
              "h:mm a"
            )} \nEnd: ${format(jobDetails?.endDrivingTime, "h:mm a")}`
          : "",
    },
    {
      icon: MdOutlineNotStarted,
      label: "Start",
      labelInProgress: "Finish",
      labelFinished: "Done",
      handleClick: handleJobStart,
      status: !jobDetails?.startedJobTime
        ? "inactive"
        : !jobDetails?.endedJobTime
        ? "inprogress"
        : "finished",
      inprogressInfo:
        jobDetails?.startedJobTime && jobDetails.startedJobTime instanceof Date
          ? `Start: ${format(jobDetails?.startedJobTime, "h:mm a")}`
          : "",
      finishedInfo:
        jobDetails?.startedJobTime &&
        jobDetails.startedJobTime instanceof Date &&
        jobDetails?.endedJobTime &&
        jobDetails.endedJobTime instanceof Date
          ? `Start: ${format(
              jobDetails?.startedJobTime,
              "h:mm a"
            )} \nEnd: ${format(jobDetails?.endedJobTime, "h:mm a")}`
          : "",
    },
    {
      icon: BiSend,
      label: "Invoice",
      labelInProgress: "Sent",
      labelFinished: "Paid",
      handleClick: handleInvoiceSend,
      status: invoiceStatus,
      inprogressInfo:
        jobDetails?.invoiceSentTime &&
        jobDetails.invoiceSentTime instanceof Date
          ? `Sent: ${formatDateTimezone(jobDetails?.invoiceSentTime, timeZone)}`
          : "",
      finishedInfo: finishedInfoInvoice,
    },
    {
      icon: CiMoneyCheck1,
      label: "Pay",
      labelFinished: "Paid",
      handleClick: handleManualPayment,
      status: !jobDetails?.datePaid ? "inactive" : "finished",
      finishedInfo:
        jobDetails?.datePaid && jobDetails.datePaid instanceof Date
          ? `Paid: ${format(jobDetails?.datePaid, "MM/dd/yy")}`
          : "",
    },
    {
      icon: AiOutlineStar,
      label: "Review",
      labelInProgress: "Sent",
      labelFinished: "Reviewed",
      handleClick: handleReviewRequest,
      status: reviewStatus,
      inprogressInfo: reviewInfoToDisplay,
      finishedInfo: reviewInfoToDisplay,
    },
  ];
  return (
    <div className="flex-1 flex flex-row items-center justify-start   my-8 mx-10  relative z-0">
      {buttons.map(
        (
          {
            icon: Icon,
            label,
            handleClick,
            status,
            inprogressInfo,
            finishedInfo,
            labelInProgress,
            labelFinished,
          },
          key
        ) => (
          <React.Fragment key={label}>
            <div
              onClick={handleClick}
              className={`flex flex-col ${
                status === "inactive"
                  ? "bg-gray-200 text-gray-800 hover:text-yellow-600/100 cursor-pointer"
                  : status === "inprogress"
                  ? "bg-gray-800 text-yellow-400 hover:text-green-400 cursor-pointer"
                  : "bg-gray-900 text-green-500"
              } rounded-md shadow-bold lg:p-8 p-4 items-center`}
              style={{ width: "12%" }}
            >
              {label === "Invoice" && status === "inprogress" ? (
                <Tooltip title="Resend Invoice" arrow placement="top">
                  <div>
                    <Icon className="text-5xl" />
                  </div>
                </Tooltip>
              ) : (
                <Icon className="text-5xl" />
              )}

              <h1 className=" font-bold">
                {status === "inactive"
                  ? label.toString()
                  : status === "inprogress"
                  ? labelInProgress.toString()
                  : labelFinished.toString()}
              </h1>
              <div
                className="absolute text-gray-900 text-center text-sm cursor-default"
                style={{
                  bottom: "-45px",
                  width: "120px",
                  pointerEvents: "none",
                }}
              >
                {status === "inactive"
                  ? ""
                  : status === "inprogress"
                  ? inprogressInfo.toString()
                  : finishedInfo.toString()}
              </div>
            </div>
            <div className="border-b-2 border-gray-900 flex flex-grow last:hidden" />
          </React.Fragment>
        )
      )}
    </div>
  );
};

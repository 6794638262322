import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { BiLoader } from "react-icons/bi";
import { ImSpinner } from "react-icons/im";
import BillingCard from "components/other/BillingCard";
import { useUserContext } from "context/UserContext";

export default function Billing() {
  const [stripePromise, setStripePromise] = useState(null);
  const { userData, setUserData } = useUserContext();

  // console.log('userData from billing: ', userData)

  useEffect(() => {
    async function getStripeKey() {
      // console.log(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)
      // console.log('user: ', user)
      setStripePromise(
        loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
      );
    }
    getStripeKey();
  }, []);

  if (!userData) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <ImSpinner className="gray-700 animate-spin-slow text-6xl" />
      </div>
    );
  }
  return (
    <>
      <Elements stripe={stripePromise}>
        <BillingCard />
      </Elements>
    </>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { SnackBarProvider } from "context/SnackBarContext";
import { UserProvider, useUserContext } from "context/UserContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { EmployeeProvider } from "./context/EmployeeContext";

// temporary measure until we can change all usercontext imports in all files
export { UserContext } from "context/UserContext";

const RootComponent = () => {
  const { error } = useUserContext();

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <App />;
};

const Index = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <SnackBarProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <EmployeeProvider>
              <RootComponent />
            </EmployeeProvider>
          </LocalizationProvider>
        </SnackBarProvider>
      </UserProvider>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>
);

import React, { useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import { IoAlertCircle } from "react-icons/io5";

export default function DLCBanner() {
  const [dismissed, setDismissed] = useState(false);

  const handleDismiss = () => {
    setTimeout(() => setDismissed(true), 100);
  };

  if (dismissed) return null;

  return (
    <div
      className="fixed top-15 left-0 right-0 px-6 py-2 bg-indigo-700 text-white flex flex-row justify-between items-center"
      style={{ zIndex: 12 }}
    >
      <div className="flex items-center">
        <IoAlertCircle className="text-2xl mr-4 text-red-400 shrink-0" />
        <p className="text-sm md:text-base flex-grow">
          <span className="font-bold">Important:</span> To comply with new 10DLC
          regulations, please submit your business details as soon as possible
          to continue uninterrupted text messaging service.
        </p>
      </div>
      <Link to="/settings/DLCInfo" onClick={handleDismiss}>
        <div className="ml-4 bg-indigo-400 text-indigo-100 font-bold hover:bg-indigo-500 hover:text-white p-2 rounded-md cursor-pointer text-center">
          Update Information
        </div>
      </Link>
      <IoCloseCircle
        className="text-2xl ml-4 cursor-pointer shrink-0"
        onClick={() => setDismissed(true)}
      />
    </div>
  );
}

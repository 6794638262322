// import { format, setHours, setMinutes, addHours, parse } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc, format } from "date-fns-tz";
import { v4 as uuidv4 } from "uuid";

export const convertToJsDate = (input) => {
  if (input instanceof Date) {
    return input;
  }

  // Check if it has 'seconds' property to assume it's a Firestore Timestamp
  if (input && typeof input.seconds === "number") {
    return new Date(input.seconds * 1000);
  }

  // check if it has _seconds property to assume it's a Firestore Timestamp
  if (input && typeof input._seconds === "number") {
    return new Date(input._seconds * 1000);
  }

  // If it's a manually generated timestamp number
  if (typeof input === "number") {
    return new Date(input);
  }

  return null; // Or handle this case as needed
};

export const convertToJsDateNano = (input) => {
  if (!input) return null;

  if (input instanceof Date) return input;

  // Firestore timestamp with toDate method
  if (input.toDate && typeof input.toDate === "function") {
    return input.toDate();
  }

  // being extra safe with the firestore timestamps in case the above check doesn't work
  // Firestore timestamp
  if (input.seconds !== undefined && input.nanoseconds !== undefined) {
    return new Date(input.seconds * 1000 + input.nanoseconds / 1000000);
  }

  // Firestore timestamp (server-side)
  if (input._seconds !== undefined && input._nanoseconds !== undefined) {
    return new Date(input._seconds * 1000 + input._nanoseconds / 1000000);
  }

  // If it's a manually generated timestamp number
  if (typeof input === "number") {
    return new Date(input);
  }

  // If it's a string, attempt to parse it as a date
  if (typeof input === "string") {
    const parsedDate = new Date(input);
    return isNaN(parsedDate) ? null : parsedDate;
  }

  return null;
};

export const formatDateTimezone = (date, timeZone, dateFormat = "MM/dd/yy") => {
  const dateToFormat = convertToJsDate(date);
  if (!dateToFormat) {
    return "";
  }

  const dateInBusinessTimeZone = utcToZonedTime(dateToFormat, timeZone);
  const formattedDate = format(dateInBusinessTimeZone, dateFormat, {
    timeZone,
  });
  return formattedDate;
};

export function splitAddress(address) {
  const parts = address.split(",").map((part) => part.trim());
  // console.log("parts length", parts.length);

  if (parts.length === 1) {
    // Handle the case where there are no commas in the address
    return [parts[0]];
  }

  if (parts.length === 2) {
    // Handle the case where there is only one comma in the address
    // console.log("parts --->0", parts[0]);
    // console.log("parts --->1", parts[1]);
    return [parts[0], parts[1]];
  }

  // Initialize firstLine with the first part
  let firstLine = parts[0];

  // Assume the rest of the parts constitute the second line initially
  let secondLine = parts.slice(1).join(", ");

  if (parts.length > 1) {
    const potentialUnitOrApt = parts[1];

    // Regular expression to check if the second part looks like an apt, unit, etc.
    // This regex looks for common indicators of an apartment, suite, or unit]
    // we should also just check if there is a number in it? bc not alot of cites have numbers...
    const aptUnitRegex = /^(Apt|Unit|Suite|No|#)?\s*\d+/i;

    // Check if the second part is a unit or apartment/suite number
    if (aptUnitRegex.test(potentialUnitOrApt)) {
      // console.log("passes apt regex");
      // If true, append it to firstLine and adjust secondLine accordingly
      firstLine += `, ${potentialUnitOrApt}`;
      secondLine = parts.slice(2).join(", ");
    }
  }

  // console.log("first", firstLine);
  // console.log("second", secondLine);

  // Return an array where the first element is the first line (street address)
  // and the second element is the rest (city, state, ZIP, optionally country)
  return [firstLine, secondLine];
}

export const generateVisits = ({ subscriptionDetails }) => {
  if (
    !subscriptionDetails?.template ||
    !subscriptionDetails?.startDate ||
    !subscriptionDetails?.template?.visits ||
    !subscriptionDetails?.template?.duration ||
    !subscriptionDetails?.template?.type
  ) {
    return [];
  }
  const startMonth = subscriptionDetails.startDate.getMonth();
  const startYear = subscriptionDetails.startDate.getFullYear();

  const years =
    subscriptionDetails.template.type === "indefinite"
      ? 4
      : Math.min(parseInt(subscriptionDetails.template.duration), 4);

  const visitsPerYear = subscriptionDetails.template.visits;
  const interval = 12 / visitsPerYear;
  const visitArray = [];

  let visitNumber = 1;
  for (let year = 0; year < years; year++) {
    for (let i = 0; i < visitsPerYear; i++) {
      const monthIndex = Math.floor((startMonth + interval * i) % 12);
      const extraYear = Math.floor((startMonth + interval * i) / 12);
      //const month = Math.round((i + 1) * interval) - 1; // 0-indexed month
      visitArray.push({
        id: uuidv4(),
        name: `Visit ${visitNumber}`,
        month: monthIndex,
        year: startYear + year + extraYear, // adjusting the year if months overflow
        status: "unscheduled",
        jobId: null,
        number: visitNumber,
      });
      visitNumber++;
    }
  }

  return visitArray;
};

export const calculateEndDateString = (subscription) => {
  if (subscription?.template?.type === "fixed") {
    const startDate = convertToJsDate(subscription.startDate);
    const duration = subscription.template.duration;

    const monthsToAdd = 12 * duration;

    startDate.setMonth(startDate.getMonth() + monthsToAdd);
    return format(startDate, "MMMM do, yyyy");
  }
  return "Continues until canceled";
};

export function formatCurrency(input) {
  // Remove any commas and attempt to parse the input as a float
  const parsedInput = parseFloat(String(input).replace(/,/g, ""));

  // Check if the parsed input is a number and is not NaN
  if (!isNaN(parsedInput)) {
    return parsedInput.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    // Default to "0.00" if the input is not a valid number
    return "0.00";
  }
}

// export const generateUniqueId = () => uuidv4();

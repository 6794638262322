import { db } from "utils/firebase";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  writeBatch,
  getDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { daysOfWeek } from "./constants";

// export const fetchEmployees = async ({ userData }) => {
//   // console.log("FIRESTORE: fetching employees");
//   try {
//     const employeeCollectionRef = collection(db, "users");
//     const employeeQuery = query(
//       employeeCollectionRef,
//       where("businessId", "==", userData.userData.businessId),
//       limit(50)
//     );
//     const employeeSnapshot = await getDocs(employeeQuery);
//     const employeeListFirestore = employeeSnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//     const sortedEmployeeList = employeeListFirestore.sort((a, b) =>
//       a.firstName.localeCompare(b.firstName)
//     );
//     return sortedEmployeeList;
//   } catch (error) {
//     console.error("Error fetching employees:", error);
//     throw error;
//   }
// };

// Unique from the function in jobDetails
export const fetchSubscriptions = async ({
  businessId,
  customerId,
  subscriptionState,
}) => {
  if (!businessId || !customerId) return;
  // console.log("FIRESTORE: fetching subscriptions");
  try {
    const subscriptionsRef = collection(
      db,
      "businesses",
      businessId,
      "subscriptions"
    );
    const subscriptionsQuery = query(
      subscriptionsRef,
      where("customer.customerId", "==", customerId)
    );
    const querySnapshot = await getDocs(subscriptionsQuery);
    if (!querySnapshot.empty) {
      const subscriptionsData = querySnapshot.docs.map((doc) => doc.data());
      return {
        subscriptions: subscriptionsData,
        selectedSubscription: subscriptionState
          ? subscriptionState.selectedSubscription
          : subscriptionsData[0], // If we were passed subscriptionState, use that, otherwise default to first subscription
        selectedVisit: subscriptionState
          ? subscriptionState.selectedVisit
          : null, // Same as above, except default to null since null means we haven't selected a visit yet
      };
    } else {
      return null; // or appropriate default object
    }
  } catch (error) {
    console.error("Error fetching subscriptions:", error);
    throw error;
  }
};

export const addSingleJob = async ({
  userData,
  newJobData,
  subscription,
  selectedCustomer,
}) => {
  // console.log("FIRESTORE: adding single job");
  // Normal logic for adding just a singular job that isn't recurring
  const newJobRef = doc(
    collection(db, "businesses", userData.userData.businessId, "jobs")
  );

  // Add jobId to newJobData
  newJobData.jobId = newJobRef.id;

  await setDoc(newJobRef, newJobData);

  // update subscription if it exists -- why are we adding the doc and then updating it... we could just add it before we add the job right?
  if (
    subscription?.selectedSubscription?.subscriptionId &&
    subscription?.selectedVisit?.id
  ) {
    const updatedSubscription = await updateSubscriptionForJob({
      businessId: userData.userData.businessId,
      newJobRef,
      subscription,
    });
    // again we don't really need to do anything with the updated subscription here..
    // if (updatedSubscription) newJobData.subscription = updatedSubscription;
  }

  //update lastJob field in customer
  const customerRef = doc(
    db,
    "businesses",
    userData.userData.businessId,
    "customers",
    selectedCustomer.customerId
  );
  await updateDoc(customerRef, {
    lastJob: serverTimestamp(),
  });
  return newJobRef.id;
};

export const addAllRecurringJobs = async ({
  userData,
  newJobData,
  subscription,
  selectedCustomer,
  recurrenceData,
}) => {
  // console.log("FIRESTORE: add all recurring jobs");
  let foreverEndDate = new Date(newJobData.start);
  foreverEndDate.setFullYear(foreverEndDate.getFullYear() + 2);

  let recurrenceEndDate = null;
  if (recurrenceData.forever) {
    recurrenceEndDate = foreverEndDate;
  } else if (recurrenceData.endDate) {
    recurrenceEndDate = recurrenceData.endDate;
  }
  Object.assign(newJobData, {
    recurrenceType: recurrenceData.type,
    recurrenceLabel: recurrenceData.label,
    recurrenceStartDate: recurrenceData.rawDate,
    recurrenceDayOfWeek: recurrenceData.dayOfWeek,
    recurrenceDayOfMonth: recurrenceData.dayOfMonth,
    recurrenceWeekOfMonth: recurrenceData.weekOfMonth,
    recurrenceDayOfYear: recurrenceData.dayOfYear,
    recurrenceEndDate: recurrenceEndDate,
    recurrenceOccurrences: recurrenceData.occurrences,
    recurrenceFrequency: recurrenceData.frequency,
    recurrenceForever: recurrenceData.forever,
    recurrenceMonthOfYear: recurrenceData.monthOfYear,
    recurrenceLastUpdated: new Date(),
    recurrenceId: uuidv4(),
  });

  // Calculate the number of occurrences
  let occurrences;
  const frequency = parseInt(newJobData.recurrenceFrequency) || 1;
  const jobDuration =
    new Date(newJobData.end).getTime() - new Date(newJobData.start).getTime();
  if (recurrenceData.occurrences) {
    occurrences = recurrenceData.occurrences;
  } else {
    // If no specific number of occurrences is set, calculate based on the end date
    const startDate = new Date(newJobData.recurrenceStartDate);
    const endDate = new Date(newJobData.recurrenceEndDate);
    const timeDifference = endDate - startDate;

    if (recurrenceData.type.includes("week")) {
      occurrences = Math.ceil(
        timeDifference / (7 * frequency * 24 * 60 * 60 * 1000)
      );
    } else if (
      recurrenceData.type.includes("month") &&
      recurrenceData.dayOfMonth
    ) {
      let tempDate = new Date(startDate);
      occurrences = 0;
      while (tempDate <= endDate) {
        occurrences++;
        tempDate.setMonth(tempDate.getMonth() + frequency);
      }
    } else if (
      recurrenceData.type.includes("month") &&
      recurrenceData.weekOfMonth
    ) {
      // ect 3 = third week of every month on recurrenceData.dayOfWeek which would be like Thursday or Friday
      occurrences = Math.floor(
        timeDifference / (7 * 4 * frequency * 24 * 60 * 60 * 1000)
      );
    } else if (recurrenceData.type.includes("year")) {
      occurrences =
        Math.ceil(timeDifference / (365 * frequency * 24 * 60 * 60 * 1000)) + 1;
    }
  }

  // console.log("occurances for adding jobs", occurrences);
  // Create and write each job to Firestore

  const batch = writeBatch(db);
  let firstJobRef = null;
  let lastJobRef = null;
  for (let i = 0; i < occurrences; i++) {
    // console.log("i", i);
    const jobStartDate = new Date(newJobData.recurrenceStartDate);

    if (recurrenceData.type.includes("week")) {
      jobStartDate.setDate(jobStartDate.getDate() + 7 * frequency * i);
    } else if (
      recurrenceData.type.includes("month") &&
      recurrenceData.dayOfMonth
    ) {
      jobStartDate.setMonth(jobStartDate.getMonth() + frequency * i);
    } else if (
      recurrenceData.type.includes("month") &&
      recurrenceData.weekOfMonth
    ) {
      // ect 3 = third week of every month on recurrenceData.dayOfWeek which would be like Thursday or Friday

      // Adjust the jobStartDate based on the weekOfMonth and dayOfWeek
      jobStartDate.setMonth(jobStartDate.getMonth() + frequency * i); // Move to the next month based on frequency and iteration
      jobStartDate.setDate(1); // Set to the first day of the month

      // Find the first occurrence of the specified dayOfWeek in the month
      while (jobStartDate.getDay() !== daysOfWeek[recurrenceData.dayOfWeek]) {
        jobStartDate.setDate(jobStartDate.getDate() + 1);
      }

      // Adjust by the specified weekOfMonth
      jobStartDate.setDate(
        jobStartDate.getDate() + 7 * (recurrenceData.weekOfMonth - 1)
      );
    } else if (recurrenceData.type.includes("year")) {
      jobStartDate.setFullYear(jobStartDate.getFullYear() + frequency * i);
    }

    if (
      jobStartDate > newJobData.recurrenceEndDate &&
      !recurrenceData.occurrences
    ) {
      break; // Exit the loop if the jobStartDate exceeds the endDate ( but only if no specific number of occurrences is set)
    }

    const jobEndDate = new Date(jobStartDate.getTime() + jobDuration); // Adjust the end time based on the job duration

    const jobRefIndividualRecurringJob = doc(
      collection(db, "businesses", userData.userData.businessId, "jobs")
    );

    lastJobRef = jobRefIndividualRecurringJob;

    const job = {
      ...newJobData,
      start: jobStartDate,
      end: jobEndDate,
      dateAdded: serverTimestamp(),
      lastUpdated: serverTimestamp(),
      jobId: jobRefIndividualRecurringJob.id,
    };

    if (i === 0) {
      // console.log("setting firstJobRef", jobRefIndividualRecurringJob);
      firstJobRef = jobRefIndividualRecurringJob;
      job.recurrenceFirstJob = true;
    } else {
      job.recurrenceFirstJob = false;
    }

    // add each individual job to batch
    batch.set(jobRefIndividualRecurringJob, job);
  }

  if (lastJobRef) {
    // update just that job with recurrenceLastJob = true
    console.log("updating lastjob ref");
    batch.update(lastJobRef, {
      recurrenceLastJob: true,
    });
  }
  // Commit the batch
  await batch.commit();
  console.log("commited the batch");
  //update lastJob field in customer
  const customerRef = doc(
    db,
    "businesses",
    userData.userData.businessId,
    "customers",
    selectedCustomer.customerId
  );
  await updateDoc(customerRef, {
    lastJob: serverTimestamp(),
  });

  // update subscription for the first job if it exists
  if (
    subscription?.selectedSubscription?.subscriptionId &&
    subscription?.selectedVisit?.id
  ) {
    await updateSubscriptionForJob({
      businessId: userData.userData.businessId,
      newJobRef: firstJobRef,
      subscription,
    });
    // we don't really need the state of the new updated subscription here...
    // if (updatedSubscription) newJobData.subscription = updatedSubscription;
  }
  return firstJobRef.id;
};

export async function updateSubscriptionForJob({
  businessId,
  newJobRef,
  subscription,
}) {
  const subscriptionId =
    subscription?.selectedSubscription?.subscriptionId ||
    subscription?.subscriptionId;
  if (!subscriptionId || !subscription?.selectedVisit?.id) {
    return;
  }

  console.log("updating subscription");

  const subscriptionRef = doc(
    db,
    "businesses",
    businessId,
    "subscriptions",
    subscriptionId
  );

  const updatedVisits = subscription.selectedSubscription.visits.map(
    (visit) => {
      // update selected visit
      if (visit.id === subscription.selectedVisit.id) {
        return {
          ...visit,
          status: "scheduled",
          jobId: newJobRef.id,
        };
      }
      // keep all the other visits the same
      return visit;
    }
  );

  // update subscription with the added visit
  await updateDoc(subscriptionRef, { visits: updatedVisits });
  // update job
  await updateDoc(newJobRef, {
    subscription: {
      subscriptionId: subscriptionId,
      // selectedSubscription: subscription.selectedSubscription, // this is too much data to be storing here
      selectedVisit: subscription.selectedVisit,
    },
  });

  // Return the updated subscription data
  return {
    ...subscription.selectedSubscription,
    visit: {
      ...subscription.selectedVisit,
      status: "scheduled",
      jobId: newJobRef.id,
    },
  };
}

export async function removeVisitFromJob({
  businessId,
  jobId,
  visitId,
  subscriptionId,
}) {
  const jobRef = doc(db, "businesses", businessId, "jobs", jobId);
  const subRef = doc(
    db,
    "businesses",
    businessId,
    "subscriptions",
    subscriptionId
  );

  // so we update the job first to remove the subscription data... we can just clear it out -- set to null
  await updateDoc(jobRef, {
    subscription: null,
  });
  // then we update the subscription to change the visit status to "unscheduled" and the jobId to null
  // Fetch the current subscription document
  const subscriptionSnap = await getDoc(subRef);

  if (!subscriptionSnap.exists()) {
    throw new Error(`Subscription ${subscriptionId} not found`);
  }

  const subscription = subscriptionSnap.data();

  // Find the visit and modify only the necessary fields
  const updatedVisits = subscription.visits.map((visit) => {
    if (visit.id === visitId) {
      return {
        ...visit,
        status: "unscheduled",
        jobId: null,
      };
    }
    return visit;
  });

  // Update the subscription with the modified visits array
  await updateDoc(subRef, {
    visits: updatedVisits,
  });
}

import React, { useState, useEffect, useContext } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoCloseSharp } from "react-icons/io5";
import DispatchTo from "../../AddJob/components/DispatchTo";
import EditDate from "./EditDate";
import { FaSave } from "react-icons/fa";
import { GrNotes } from "react-icons/gr";
import TextAreaWithLabel from "components/other/TextAreaWithLabel";
import LeadSource from "../../AddJob/components/LeadSource";
import AddNote from "../../AddJob/components/AddNote";
import Modal from "@mui/material/Modal";
import {
  updateDoc,
  doc,
  writeBatch,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { UserContext } from "../../../index";
import { db } from "../../../utils/firebase";
import { set } from "lodash";
import HorizontalScrollAndDelete from "components/reusable/HorizontalScrollAndDelete";
import CustomImageInput from "components/reusable/CustomImageInput";
import { uploadFile } from "utils/uploadFile";
import NormalButton from "components/buttons/NormalButton";

export default function AddNoteModal({
  setModal,
  modal,
  jobDetails,
  setJobDetails,
  estimate = false,
}) {
  const { userData } = useContext(UserContext);
  const [editRecurringModal, setEditRecurringModal] = useState(false);
  const [loadingSingle, setLoadingSingle] = useState(false);
  const [loadingRecurring, setLoadingRecurring] = useState(false);

  const [images, setImages] = useState(
    jobDetails?.images?.map((image) => {
      return {
        data: null,
        preview: image,
      };
    }) || []
  );

  const [notes, setNotes] = useState(jobDetails?.note || "");

  useEffect(() => {
    setNotes(jobDetails?.note || "");
    setImages(
      jobDetails?.images?.map((image) => {
        return {
          data: null,
          preview: image,
        };
      }) || []
    );
  }, [jobDetails]);

  // Helper function for uploading images
  const uploadImages = async (imagesToUpload) => {
    const uploadedImageUrls = [];

    for (let file of imagesToUpload) {
      try {
        const downloadURL = await uploadFile(file.data, "jobPhotos");
        uploadedImageUrls.push(downloadURL);
      } catch (error) {
        console.error("Upload error:", error);
        alert("Upload error:", error.message); // TODO: Replace with a notification (e.g., snackbar)
      }
    }

    return uploadedImageUrls;
  };

  const handleClickSave = async () => {
    console.log("handle click save");
    if (loadingSingle || loadingRecurring) return;
    if (estimate) {
      await handleSaveEstimate();
      return;
    }

    if (jobDetails?.isRecurring) {
      setEditRecurringModal(true);
    } else {
      handleSaveSingleJob();
    }
  };

  const handleSaveEstimate = async () => {
    if (loadingSingle || loadingRecurring) return;
    setLoadingSingle(true);
    console.log("handle save estimate");
    try {
      const estimateRef = doc(
        db,
        "businesses",
        userData.userData.businessId,
        "estimates",
        jobDetails?.estimateId
      );

      await updateDoc(estimateRef, {
        note: notes,
      });
      setJobDetails({
        ...jobDetails,
        note: notes,
      });
      setEditRecurringModal(false);
      setModal(false);
    } catch (error) {
      console.log("error", error);
      alert("There was an error updating the estimate, please try again");
    } finally {
      setLoadingSingle(false);
    }
  };

  const handleSaveAllRecurringJobs = async () => {
    if (loadingSingle || loadingRecurring) return;
    console.log("handle save all recurring jobs =====>>>");
    setLoadingRecurring(true);
    try {
      let imageUrlsForFirestore = [];
      if (images?.length > 0) {
        const newImages = images.filter((image) => image.data !== null);
        const oldImages = images.filter((image) => image.data === null);

        const newImageUrls = await uploadImages(newImages);

        imageUrlsForFirestore = [
          ...newImageUrls,
          ...oldImages.map((image) => image.preview),
        ];
        console.log("all image urls generated", imageUrlsForFirestore);
      }

      const dbQuery = query(
        collection(db, "businesses", userData.userData.businessId, "jobs"),
        where("recurrenceId", "==", jobDetails.recurrenceId),
        where("start", ">=", jobDetails.start)
      );

      const querySnapshot = await getDocs(dbQuery);

      // Check if there are any documents to delete
      if (querySnapshot.empty) {
        console.log("No documents found with the provided recurrenceId.");
        return;
      }

      const batch = writeBatch(db);

      querySnapshot.forEach((docSnapshot) => {
        const docRef = doc(
          db,
          "businesses",
          userData.userData.businessId,
          "jobs",
          docSnapshot.id
        );
        batch.update(docRef, {
          note: notes,
          images: imageUrlsForFirestore || [],
        });
      });

      setJobDetails({
        ...jobDetails,
        note: notes,
        images: imageUrlsForFirestore || [],
      });

      // Commit the batch
      await batch.commit();
      console.log(
        "All recurring jobs with the specified recurrenceId have been updated."
      );
      setEditRecurringModal(false);
      setModal(false);
    } catch (error) {
      console.log("error", error);
      alert("There was an error updating the jobs, please try again");
      setEditRecurringModal(false);
      setModal(false);
    } finally {
      setLoadingRecurring(false);
    }
  };

  const handleSaveSingleJob = async () => {
    if (loadingRecurring || loadingSingle) return;
    setLoadingSingle(true);
    console.log("handle save single job");
    // update firestore docuement and jobDetails state

    try {
      let imageUrlsForFirestore = [];
      if (images?.length > 0) {
        const newImages = images.filter((image) => image.data !== null);
        const oldImages = images.filter((image) => image.data === null);

        const newImageUrls = await uploadImages(newImages);

        imageUrlsForFirestore = [
          ...newImageUrls,
          ...oldImages.map((image) => image.preview),
        ];
        // console.log("all image urls generated", imageUrlsForFirestore);
      }

      const jobRef = doc(
        db,
        "businesses",
        userData.userData.businessId,
        "jobs",
        jobDetails?.jobId
      );
      console.log("saved note", notes);
      await updateDoc(jobRef, {
        note: notes,
        images: imageUrlsForFirestore || [],
      });
      setJobDetails({
        ...jobDetails,
        note: notes,
        images: imageUrlsForFirestore || [],
      });
      setEditRecurringModal(false);
      setModal(false);
    } catch (error) {
      console.log("error", error);
      alert("There was an error updating the jobs, please try again");
    } finally {
      setLoadingSingle(false);
    }
  };

  const handleRemoveImage = async (image) => {
    setImages(images.filter((img) => img.preview !== image.preview));
  };

  const handleFileChange = (event) => {
    console.log("handle file change");
    const files = Array.from(event.target.files);
    const filesWithPreview = files.map((file) => ({
      data: file,
      preview: URL.createObjectURL(file),
    }));

    setImages(filesWithPreview);
  };

  // console.log("images from add note modal", images);

  return (
    <>
      <Modal open={modal} onClose={() => setModal(false)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "600px",
            maxHeight: "90%",
            overflowY: "auto",
            backgroundColor: "#fff", // Assuming "background.paper" refers to a white or light color
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Adjust as needed
            paddingTop: 16, // 4 * 4 = 16 assuming a spacing system where 4 is the base unit
            paddingLeft: 16, // 4 * 4 = 16
            paddingRight: 16, // 4 * 4 = 16
            paddingBottom: 12, // 4 * 3 = 12
            outline: "none",
          }}
        >
          <div className="flex flex-col flex-1 lg:col-span-2 bg-white gap-2 mb-8 pb-8 p-4">
            <div className="flex flex-row items-center gap-2 mt-4 mb-2 ml-2">
              <GrNotes className="inline-block text-2xl" />
              <h1 className="text-xl font-bold">Private Notes</h1>
            </div>
            <TextAreaWithLabel
              label="Notes"
              value={notes}
              setValue={setNotes}
            />
            {!estimate && (
              <div className="flex flex-row mt-2 ml-1 ">
                <CustomImageInput handleFileChange={handleFileChange} />
              </div>
            )}
            {images && images?.length > 0 && (
              <div className="max-w-[500px]">
                <HorizontalScrollAndDelete
                  images={images}
                  deleteImage={handleRemoveImage}
                />
              </div>
            )}
          </div>
          {/* <button
            onClick={handleClickSave}
            className="absolute  bg-gray-900 hover:text-yellow-100 text-white self-end font-bold py-2 px-4 rounded"
            style={{
              top: "20px",
              right: "35px",
            }}
          >
            <span className="">Save</span>
          </button> */}
          <div
            style={{
              position: "absolute",
              top: "20px",
              right: "35px",
            }}
          >
            <NormalButton
              onClick={handleClickSave}
              loading={!jobDetails?.isRecurring && loadingSingle}
              width="w-20"
              text="Save"
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={editRecurringModal}
        onClose={() => setEditRecurringModal(false)}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 450,
            backgroundColor: "#fff", // Assuming "background.paper" refers to a white or light color
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Adjust as needed
            paddingTop: 20, // 4 * 4 = 16 assuming a spacing system where 4 is the base unit
            paddingLeft: 32, // 4 * 4 = 16
            paddingRight: 32, // 4 * 4 = 16
            paddingBottom: 20, // 4 * 3 = 12
          }}
        >
          <h1 className="mb-6 font-bold text-lg">
            Do you want to edit all recurring or just this job?
          </h1>
          <div className="flex flex-row items-center justify-center gap-5">
            {/* <button
              className="bg-gray-900 rounded-md px-3 py-3 justify-center shadow-lg flex items-center text-white whitespace-nowrap hover:text-yellow-100"
              onClick={handleSaveAllRecurringJobs}
            >
              All recurring
            </button> */}

            <NormalButton
              onClick={handleSaveAllRecurringJobs}
              loading={loadingRecurring}
              width="w-32"
              text="All recurring"
            />
            {/* <button
              className="bg-gray-900 rounded-md  px-3 py-3 justify-center shadow-lg flex items-center text-white whitespace-nowrap ml-6  hover:text-yellow-100 "
              onClick={handleSaveSingleJob}
            >
              Just this job
            </button> */}
            <NormalButton
              onClick={handleSaveSingleJob}
              loading={loadingSingle}
              width="w-32"
              text="Just this job"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useUserContext } from "context/UserContext";
import { db, auth } from "../../utils/firebase";
import { doc, getDoc, getDocs, updateDoc, collection } from "firebase/firestore";
import Tooltip from "@mui/material/Tooltip";
import { ImSpinner } from "react-icons/im";
import { FaToggleOff } from "react-icons/fa";
import { FaToggleOn } from "react-icons/fa";
import { useSnackBar } from "../../context/SnackBarContext";
import DisabledButton from "components/buttons/DisabledButton";
import CollapsibleMessagesSection from "components/Customizations/Messages/CollapsibleMessagesSection";
import { IoIosInformationCircleOutline } from "react-icons/io";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import InputMUI from "components/reusable/InputMUI";
import Modal from "@mui/material/Modal";
import { AiOutlineEdit } from "react-icons/ai";
import EstimatePreview from "components/customizeEstimates/EstimatePreview";
import EstimateOptions from "components/customizeEstimates/EstimateOptions";

export default function EstimateCustomizations() {
  const { userData, setUserData, subData, bannerVisible } = useUserContext();
  const { openSnackBar } = useSnackBar();
  // Permissions //////////////////////////////////////////////////
  const notAbleToViewCompanyInfo =
    userData?.userData?.customPermissions?.view_company_info === false;
  const notAbleToEditCompanyInfo =
    !userData?.userData?.isAdmin && !userData?.userData?.customPermissions?.edit_company_info;

  // State //////////////////////////////////////////////////////
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [estimateSettings, setEstimateSettings] = useState(
    userData?.bizData?.estimateSettings || {
      logo: userData?.bizData?.logo ? true : false,
      estimateNumber: true,
      estimateDate: true,
      customerDisplayName: true,
      customerAddress: true,
      customerEmail: true,
      customerPhone: true,
      businessName: true,
      businessPhone: true,
      businessEmail: true,
      businessAddress: true,
      lineItemsDescription: true,
      lineItemsQuantity: true,
      lineItemsUnitPrice: true,
      lineItemsAmount: true,
      subtotal: true,
      tax: true,
      footerBusinessName: true,
      footerWebsite: true,
    }
  );

  // keep in sync with the database
  useEffect(() => {
    setEstimateSettings(
      userData?.bizData?.estimateSettings || {
        logo: userData?.bizData?.logo ? true : false,
        estimateNumber: true,
        estimateDate: true,
        customerDisplayName: true,
        customerAddress: true,
        customerEmail: true,
        customerPhone: true,
        businessName: true,
        businessPhone: true,
        businessEmail: true,
        businessAddress: true,
        lineItemsDescription: true,
        lineItemsQuantity: true,
        lineItemsUnitPrice: true,
        lineItemsAmount: true,
        subtotal: true,
        tax: true,
        footerBusinessName: true,
        footerBusinessWebsite: true,
      }
    );
  }, [userData?.bizData?.estimateSettings]);
  // Check dirty
  useEffect(() => {
    if (JSON.stringify(estimateSettings) !== JSON.stringify(userData?.bizData?.estimateSettings)) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [estimateSettings, userData?.bizData?.estimateSettings]);

  const handleSave = async () => {
    if (notAbleToEditCompanyInfo) {
      openSnackBar("You do not have permission to edit company info");
      return;
    }
    setLoading(true);

    try {
      // Update the terms in the Firestore
      const bizRef = doc(db, "businesses", userData.bizData.id);
      await updateDoc(bizRef, { estimateSettings: estimateSettings });
      setUserData({
        ...userData,
        bizData: { ...userData.bizData, estimateSettings },
      });
      openSnackBar("Estimate settings updated", true);
    } catch (error) {
      openSnackBar("Failed to update");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`bg-gray-100 ${
        bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
      }  overflow-y-auto pb-36`}
    >
      <div className="flex items-center justify-center">
        <div className="flex flex-row w-full md:justify-center justify-between items-center  mt-6 mb-6  ml-4">
          <h1 className="text-3xl md:w-3/4 max-w-2xl font-extrabold text-gray-900 ">
            Estimate Settings
          </h1>

          <div className="md:w-1/4 ">
            <DisabledButton
              disabled={!dirty}
              loading={loading}
              onClick={handleSave}
              text="Save"
              width="w-16"
              additionalClasses="mr-8"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-start mt-3 mb-5 px-4">
        <div className="md:w-3/4 w-full max-w-2xl mb-4 md:mb-0">
          <EstimatePreview userData={userData} estimateSettings={estimateSettings} />
        </div>
        <div className="md:w-1/4 w-full md:ml-4">
          <EstimateOptions
            estimateSettings={estimateSettings}
            setEstimateSettings={setEstimateSettings}
          />
        </div>
      </div>
    </div>
  );
}

import React, { useContext } from "react";
import { useUserContext } from "context/UserContext";

export default function SkeletonLoader() {
  const { bannerVisible } = useUserContext();

  const pathname = window.location.pathname;
  const isSuccessPage =
    pathname === "/new-trial-success" ||
    pathname === "/subscription-success" ||
    pathname === "/games";
  return (
    <div
      className={`bg-gray-100 ${
        bannerVisible
          ? "h-full-minus-head-and-banner"
          : isSuccessPage
          ? "min-h-screen"
          : "h-full-minus-header"
      } overflow-y-auto`}
    >
      <div className="flex flex-col py-10 px-16 mb-20 ">
        <h1 className="text-3xl hidden lg:block font-extrabold text-gray-900 mb-6"></h1>
        <div className="flex flex-col items-center md:flex-row w-full lg:justify-around gap-10 mt-10 ">
          <div className="border rounded-md p-5 shadow-lg bg-gray-50 md:w-1/3 w-full h-64">
            <div className="animate-pulse">
              <div className="h-6 bg-gray-200 rounded"></div>
              <div className="h-3 mt-4 bg-gray-300 rounded w-3/4"></div>
              <div className="h-3 mt-2 bg-gray-300 rounded w-3/5"></div>
            </div>
          </div>
          <div className="border rounded-md p-5 shadow-lg bg-gray-50 md:w-1/3 w-full h-64">
            <div className="animate-pulse">
              <div className="h-6 bg-gray-200 rounded"></div>
              <div className="h-3 mt-4 bg-gray-300 rounded w-3/4"></div>
              <div className="h-3 mt-2 bg-gray-300 rounded w-3/5"></div>
            </div>
          </div>
          <div className="border rounded-md p-5 shadow-lg bg-gray-50 md:w-1/3 w-full h-64">
            <div className="animate-pulse">
              <div className="h-6 bg-gray-200 rounded"></div>
              <div className="h-3 mt-4 bg-gray-300 rounded w-3/4"></div>
              <div className="h-3 mt-2 bg-gray-300 rounded w-3/5"></div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center md:flex-row w-full lg:justify-around gap-10 mt-10 ">
          <div className="border rounded-md p-5 shadow-lg bg-gray-50 md:w-1/3 w-full h-64">
            <div className="animate-pulse">
              <div className="h-6 bg-gray-200 rounded"></div>
              <div className="h-3 mt-4 bg-gray-300 rounded w-3/4"></div>
              <div className="h-3 mt-2 bg-gray-300 rounded w-3/5"></div>
            </div>
          </div>
          <div className="border rounded-md p-5 shadow-lg bg-gray-50 md:w-1/3 w-full h-64">
            <div className="animate-pulse">
              <div className="h-6 bg-gray-200 rounded"></div>
              <div className="h-3 mt-4 bg-gray-300 rounded w-3/4"></div>
              <div className="h-3 mt-2 bg-gray-300 rounded w-3/5"></div>
            </div>
          </div>
          <div className="border rounded-md p-5 shadow-lg bg-gray-50 md:w-1/3 w-full h-64">
            <div className="animate-pulse">
              <div className="h-6 bg-gray-200 rounded"></div>
              <div className="h-3 mt-4 bg-gray-300 rounded w-3/4"></div>
              <div className="h-3 mt-2 bg-gray-300 rounded w-3/5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

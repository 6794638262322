import React, { useState, useEffect, useContext } from "react";
import { updateDoc, doc, writeBatch, getDocs, collection, query, where } from "firebase/firestore";
import { UserContext } from "../../../index";
import Modal from "@mui/material/Modal";
import { db } from "../../../utils/firebase";
import NormalButton from "components/buttons/NormalButton";
import { useSnackBar } from "context/SnackBarContext";
import DisabledButton from "components/buttons/DisabledButton";
import _ from "lodash";
import AddLineItems from "../../AddJob/components/AddLineItem";

export default function AddLineItemModal({
  toggleModal,
  jobDetails,
  setJobDetails,
  setLineItemModal,
  lineItemModal,
  estimate = false,
}) {
  const { userData } = useContext(UserContext);
  const defaultTaxRate = userData?.bizData?.defaultTaxRate || null;

  const [warningModal, setWarningModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [lineItems, setLineItems] = useState(jobDetails?.lineItems || []);
  const [editRecurringModal, setEditRecurringModal] = useState(false);
  const [loading, setLoading] = useState({ recurring: false, single: false });
  const { openSnackBar } = useSnackBar();
  const [disabled, setDisabled] = useState(true);
  const [jobTotal, setJobTotal] = useState(0);

  // syncing state with jobDetails
  useEffect(() => {
    setLineItems(jobDetails?.lineItems);
  }, [jobDetails, lineItemModal]);

  const handleClickSave = async () => {
    if (estimate) {
      await handleSaveEstimate();
      return;
    }
    if (jobDetails?.isRecurring) {
      setEditRecurringModal(true);
    } else {
      handleSaveSingleJob();
    }
  };

  // useEffect to check whether the new line items are different from the old line items and only set disabled to false if they are different
  useEffect(() => {
    if (_.isEqual(lineItems, jobDetails?.lineItems)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [lineItems, jobDetails?.lineItems]);

  const handleSaveEstimate = async () => {
    console.log("handle save estimate");
    if (loading?.single || loading?.recurring) return;
    setLoading({ ...loading, single: true });
    try {
      const estimateRef = doc(
        db,
        "businesses",
        userData.bizData.id,
        "estimates",
        jobDetails.estimateId
      );
      await updateDoc(estimateRef, {
        lineItems: lineItems,
        jobTotal: total,
      });
      setJobDetails({
        ...jobDetails,
        lineItems: lineItems,
        jobTotal: total,
      });
      setEditRecurringModal(false);
      setLineItemModal(false);
      openSnackBar("Line Items Saved", true);
    } catch (error) {
      console.log("error", error);
      openSnackBar("Error saving line items", false, true);
    } finally {
      setLoading({ ...loading, single: false });
    }
  };

  const handleSaveAllRecurringJobs = async () => {
    if (loading?.single || loading?.recurring) return;
    console.log("handle save all recurring jobs");
    setLoading({ ...loading, recurring: true });

    try {
      const dbQuery = query(
        collection(db, "businesses", userData.userData.businessId, "jobs"),
        where("recurrenceId", "==", jobDetails.recurrenceId),
        where("start", ">=", jobDetails.start)
      );

      const querySnapshot = await getDocs(dbQuery);

      // Check if there are any documents to delete
      if (querySnapshot.empty) {
        console.log("No documents found with the provided recurrenceId.");
        return;
      }

      const batch = writeBatch(db);

      querySnapshot.forEach((docSnapshot) => {
        const docRef = doc(db, "businesses", userData.userData.businessId, "jobs", docSnapshot.id);
        batch.update(docRef, {
          lineItems: lineItems,
          jobTotal: total,
        });
      });

      setJobDetails({
        ...jobDetails,
        lineItems: lineItems,
        jobTotal: total,
      });

      // Commit the batch
      await batch.commit();
      console.log("All recurring jobs with the specified recurrenceId have been updated.");
      setEditRecurringModal(false);
      setLineItemModal(false);
      openSnackBar("Line items updated for all jobs", true);
    } catch (error) {
      console.log("error", error);
      openSnackBar("Error saving line items", false, true);
      setEditRecurringModal(false);
      setLineItemModal(false);
    } finally {
      setLoading({ ...loading, recurring: false });
    }
  };

  const handleSaveSingleJob = async () => {
    // update firestore docuement and jobDetails state
    if (loading?.single || loading?.recurring) return;
    setLoading({ ...loading, single: true });
    try {
      const jobRef = doc(db, "businesses", userData.bizData.id, "jobs", jobDetails.jobId);
      await updateDoc(jobRef, {
        lineItems: lineItems,
        jobTotal: total,
      });
      setJobDetails({
        ...jobDetails,
        lineItems: lineItems,
        jobTotal: total,
      });
      setEditRecurringModal(false);
      setLineItemModal(false);
      openSnackBar("Line Items Saved", true);
    } catch (error) {
      console.log("error", error);
      openSnackBar("Error saving line items", false, true);
    } finally {
      setLoading({ ...loading, single: false });
    }
  };

  // could probably be refactored...
  useEffect(() => {
    // console.log("line items changed", lineItems);
    // add up the total
    if (!lineItems || lineItems?.length === 0) {
      setTotal(0);
      return;
    }
    let total = 0;
    lineItems?.forEach((item) => {
      total += item.unitPrice * item.quantity;
    });
    total = parseFloat(total.toFixed(2));

    // total = parseFloat(total.toFixed(2));
    setTotal(total);
  }, [lineItems]);

  const toggleWarningModal = () => {
    setWarningModal(!warningModal);
  };

  return (
    <>
      <Modal open={lineItemModal} onClose={toggleModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "700px",
            maxHeight: "80%",
            overflowY: "auto",
            backgroundColor: "#fff", // Assuming "background.paper" refers to a white or light color
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Adjust as needed
            paddingTop: 16, // 4 * 4 = 16 assuming a spacing system where 4 is the base unit
            paddingLeft: 16, // 4 * 4 = 16
            paddingRight: 16, // 4 * 4 = 16
            paddingBottom: 12, // 4 * 3 = 12
            outline: "none",
          }}
        >
          <DisabledButton
            loading={!jobDetails?.isRecurring && loading?.single}
            onClick={handleClickSave}
            text="Save"
            additionalClasses="absolute self-end top-4 right-10"
            fontSize="text-base"
            fontWeight="font-bold"
            width="w-24"
            disabled={disabled}
          />

          <AddLineItems
            modal={true}
            lineItems={lineItems}
            setLineItems={setLineItems}
            setJobTotal={setJobTotal}
            taxRates={userData?.bizData?.taxRates}
            defaultTaxRate={defaultTaxRate}
            lineItemsUnsavable={true}
          />
        </div>
      </Modal>

      <Modal open={editRecurringModal} onClose={() => setEditRecurringModal(false)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 450,
            backgroundColor: "#fff", // Assuming "background.paper" refers to a white or light color
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Adjust as needed
            paddingTop: 20, // 4 * 4 = 16 assuming a spacing system where 4 is the base unit
            paddingLeft: 32, // 4 * 4 = 16
            paddingRight: 32, // 4 * 4 = 16
            paddingBottom: 20, // 4 * 3 = 12
          }}
        >
          <h1 className="mb-6 font-bold text-lg">
            Do you want to edit all recurring or just this job?
          </h1>
          <div className="flex flex-row items-center justify-center">
            {/* <button
              className="bg-gray-900 rounded-md px-3 py-3 justify-center shadow-lg flex items-center text-white whitespace-nowrap hover:text-yellow-100"
              onClick={handleSaveAllRecurringJobs}
            >
              All recurring
            </button> */}
            <NormalButton
              loading={loading?.recurring}
              onClick={handleSaveAllRecurringJobs}
              text="All recurring"
            />
            {/* <button
              className="bg-gray-900 rounded-md  px-3 py-3 justify-center shadow-lg flex items-center text-white whitespace-nowrap ml-6  hover:text-yellow-100 "
              onClick={handleSaveSingleJob}
            >
              Just this job
            </button> */}
            <NormalButton
              loading={loading?.single}
              onClick={handleSaveSingleJob}
              text="Just this job"
              additionalClasses="ml-6"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

import React from "react";
import Modal from "@mui/material/Modal";
import { IoCloseSharp } from "react-icons/io5";

export default function CancelModal({
  alreadyCancelled,
  setCancelModal,
  cancelModal,
  cancelJob,
  cancelRecurringJobs,
}) {
  const cancelText = alreadyCancelled ? "Uncancel" : "Cancel";
  return (
    <Modal open={cancelModal} onClose={() => setCancelModal(false)}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "600px",
          backgroundColor: "#fff", // Assuming "background.paper" refers to a white or light color
          borderRadius: "10px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Adjust as needed
          paddingTop: 16, // 4 * 4 = 16 assuming a spacing system where 4 is the base unit
          paddingLeft: 16, // 4 * 4 = 16
          paddingRight: 16, // 4 * 4 = 16
          paddingBottom: 12, // 4 * 3 = 12
          outline: "none",
        }}
      >
        <IoCloseSharp
          className="absolute top-2 right-2 text-2xl text-gray-500 hover:text-gray-900 cursor-pointer"
          onClick={() => setCancelModal(false)}
        />
        <h1 className="text-2xl font-bold text-gray-900 mb-4 text-center">
          {cancelText} Job
        </h1>
        <p className="text-gray-700 text-center mb-4">
          Do you want to {cancelText} all recurring jobs or just this job?{" "}
        </p>
        <div className="flex flex-col justify-center items-center mb-4 mx-4">
          <div className="flex flex-row justify-center items-center">
            <button
              className=" bg-gray-900 font-semibold rounded-md p-2 mt-1 text-red-200 text-md hover:text-red-400 shadow-md "
              onClick={cancelRecurringJobs}
            >
              {cancelText} All Jobs
            </button>
            <button
              className=" bg-gray-900 font-semibold rounded-md p-2 mt-1 text-red-200 text-md ml-2 hover:text-red-400 shadow-md w-"
              onClick={cancelJob}
            >
              {cancelText} Just This Job
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../index";
import { db } from "../../utils/firebase";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import LineItemsStatic from "../JobDetails/components/LineItemsStatic";
import CustomerJob from "../JobDetails/components/CustomerJob";
import LeadSourceJobDetails from "../JobDetails/components/LeadSourceJobDetails";
import NoteJobDetails from "../JobDetails/components/NoteJobDetails";
import { BiSend } from "react-icons/bi";
import EditLeadSourceModal from "../JobDetails/components/EditLeadSourceModal";
import AddNoteModal from "../JobDetails/components/AddNoteModal";
import AddLineItemModal from "../JobDetails/components/AddLineItemModal";
import NormalButton from "components/buttons/NormalButton";
// import CustomerModalCombined from "components/reusable/CustomerModalCombined";
import CustomerModalCombinedNew from "components/CustomerModalCombined/CustomerModalCombined";
import { useSnackBar } from "context/SnackBarContext";
import useFetch from "../../hooks/useFetch";
import { getEstimateDetails, resendEstimate } from "./api";
import { useToggle } from "../../hooks/useToggle";
import { useEmployeeContext } from "../../context/EmployeeContext";
import { PageHeader } from "../../layouts/PageHeader";
import { SolidButton } from "../../components/NewButtons/SolidButton";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";

export default function EstimateDetails() {
  const { user, userData, bannerVisible } = useContext(UserContext);
  const { openSnackBar } = useSnackBar();
  const { employees: employeeList } = useEmployeeContext();

  const navigate = useNavigate();
  const location = useLocation();
  const { estimateId } = location?.state;
  const businessId = userData?.userData?.businessId;

  // state that we fetch on mount
  const [estimateDetails, setEstimateDetails] = useState({});
  // const [employeeList, setEmployeeList] = useState([]);

  // state for modals
  const [lineItemModal, setLineItemModal] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [leadSourceModal, setLeadSourceModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);

  // state for edited customer data (we can refactor this eventually so it's within the edit customer modal)

  // state for sending estimate
  const [sendingEstimate, setSendingEstimate] = useState(false);

  const getEstimateDetailsArgs = useMemo(() => {
    return { businessId, estimateId };
  }, [businessId, estimateId]);
  const { data: fetchedEstimateDetails } = useFetch(getEstimateDetails, getEstimateDetailsArgs);

  useEffect(() => {
    if (fetchedEstimateDetails) setEstimateDetails(fetchedEstimateDetails);
  }, [fetchedEstimateDetails]);

  const toggle = useToggle();
  const toggleLineItemModal = () => toggle(setLineItemModal);
  const toggleCustomerModal = () => toggle(setCustomerModal);
  const toggleLeadSourceModal = () => toggle(setLeadSourceModal);
  const toggleNoteModal = () => toggle(setNoteModal);

  const handleResendEstimate = async () => {
    // this needs to be updated like the new estimate page where we first create a modal and have them enter the correct email... and then send the estimate
    // check to ensure there is an email address connected to customer
    if (!estimateDetails?.customer?.email?.[0]) {
      alert("Please add an email address to the customer before sending the estimate");
      return;
    }
    if (sendingEstimate) return;

    setSendingEstimate(true);

    const message = await resendEstimate({ estimateDetails, userData });
    if (message === "Email sent") {
      openSnackBar("Estimate sent successfully");
    }
    setSendingEstimate(false);
  };

  return (
    <>
      <div
        className="bg-gray-50"
        style={{
          height: bannerVisible ? "calc(100vh - 60px - 60px)" : "calc(100vh - 64px)",
          overflowY: "auto",
        }}
      >
        <CenteredMaxWidthLayout>
          <PageHeader title="Estimate Details">
            <SolidButton
              text="Resend Estimate"
              width="w-48"
              loading={sendingEstimate}
              onClick={handleResendEstimate}
              icon={<BiSend className="text-xl font-extrabold " />}
            />
          </PageHeader>
          <div className="flex flex-col gap-10 lg:grid lg:grid-cols-5 lg:relative flex-1  pb-4 px-4 mx-1">
            <div className="flex flex-col flex-grow lg:col-start-3 lg:col-end-6 lg:row-start-1">
              <LineItemsStatic
                lineItems={estimateDetails?.lineItems || []}
                toggleModal={toggleLineItemModal}
                disabled={true}
              />
            </div>
            <div className="flex flex-col lg:col-start-1 lg:col-end-3 lg:row-start-1  gap-2 lg:gap-0">
              <div className="flex flex-col flex-none">
                <CustomerJob
                  toggleModal={toggleCustomerModal}
                  customer={estimateDetails?.customer}
                  notifications={estimateDetails?.customer?.notifications}
                  toggleNotifications={null}
                  navigate={navigate}
                />

                <LeadSourceJobDetails
                  toggleLeadSourceModal={toggleLeadSourceModal}
                  leadSource={estimateDetails?.leadSource}
                />
                <div className="pt-8">
                  <NoteJobDetails note={estimateDetails?.note} toggleNoteModal={toggleNoteModal} />
                </div>
              </div>
            </div>
          </div>
        </CenteredMaxWidthLayout>
      </div>

      <CustomerModalCombinedNew
        modal={customerModal}
        setModal={setCustomerModal}
        setSelectedCustomer={(updatedCustomerData) => {
          setEstimateDetails((prev) => {
            return { ...prev, customer: { ...updatedCustomerData } };
          });
        }}
        jobDetails={{ ...estimateDetails, type: "estimate" }}
        customer={estimateDetails?.customer}
        customerId={estimateDetails?.customer?.customerId}
      />
      <EditLeadSourceModal
        employeeList={employeeList}
        modal={leadSourceModal}
        setModal={setLeadSourceModal}
        jobDetails={estimateDetails}
        setJobDetails={setEstimateDetails}
        estimate={true}
      />

      <AddNoteModal
        jobDetails={estimateDetails}
        setJobDetails={setEstimateDetails}
        modal={noteModal}
        setModal={setNoteModal}
        estimate={true}
      />

      <AddLineItemModal
        toggleModal={toggleLineItemModal}
        jobDetails={estimateDetails}
        setJobDetails={setEstimateDetails}
        taxRates={userData?.bizData?.taxRates}
        setLineItemModal={setLineItemModal}
        lineItemModal={lineItemModal}
        estimate={true}
      />
    </>
  );
}

import { useState } from "react";
import { Grid, Pagination } from "@mui/material";
import NormalButton from "components/buttons/NormalButton";
import CancelButton from "components/buttons/CancelButton";
import DeleteButton from "components/buttons/DeleteButton";
import Dialogue from "components/modals/Dialogue";
import BasicSelect from "components/reusable/BasicDropdown";
import { doc } from "firebase/firestore";
import { db } from "utils/firebase";
import { useSnackBar } from "context/SnackBarContext";
import { generateYearOptions } from "../data/utils";
import { updateSubscriptionForJob, removeVisitFromJob } from "../data/api";
import { monthMap } from "../data/constants";

export const AssociatedVisitToJobModal = ({
  subscription,
  setSubscription,
  open,
  onClose,
  fromJobDetails,
  pushVisitToLineItems = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const { openSnackBar } = useSnackBar();
  const nextVisit = subscription?.selectedSubscription?.visits.find(
    (visit) => visit.status === "unscheduled"
  );
  const startYear = nextVisit?.year || new Date().getFullYear();

  const [selectedYear, setSelectedYear] = useState(startYear);
  const [selectedSubscription, setSelectedSubscription] = useState(
    subscription?.selectedSubscription
  );
  const [selectedVisit, setSelectedVisit] = useState(
    subscription?.selectedVisit || null
  );
  const [page, setPage] = useState(1);

  const visitsPerPage = 4;
  const filteredVisits = selectedSubscription.visits.filter(
    (visit) => visit.status === "unscheduled" && visit.year === selectedYear
  );
  const totalPages = Math.ceil(filteredVisits.length / visitsPerPage);

  const handleYearChange = (value) => {
    setSelectedYear(value);
    setSelectedVisit(null);
  };
  const handleSubscriptionChange = (event) => {
    const newSub = subscription?.subscriptions.find(
      (sub) => sub.subscriptionId === event.target.value
    );

    setSelectedSubscription(newSub);
    setSelectedVisit(null);
  };

  const handleVisitSelect = (visit) => {
    // if we are clicking on the same visit twice, unselect it
    if (selectedVisit?.id === visit.id) {
      setSelectedVisit(null);
      return;
    }
    // otherwise, select the visit
    setSelectedVisit(visit);
  };

  const handleSave = async () => {
    // console.log("handleSave", fromJobDetails);
    // console.log("selectedVisit", selectedVisit);
    try {
      setLoading(true);
      // when we are saving on job details here we need to update the job with the new selected subscription and selectedVisit
      if (fromJobDetails) {
        // this is the id
        const jobRef = doc(
          db,
          "businesses",
          selectedSubscription.businessId,
          "jobs",
          fromJobDetails
        );
        // this updates the subscription with the new visit -- this will only happen if we are adding it for the first time.. no editing is allowed
        await updateSubscriptionForJob({
          businessId: selectedSubscription.businessId,
          newJobRef: jobRef,
          subscription: {
            // subscriptionId: selectedSubscription.subscriptionId, // we don't really need to be passing this here
            selectedSubscription: selectedSubscription,
            selectedVisit: selectedVisit,
          },
        });

        openSnackBar("Visit added to job", true);
      }
      // so the add line items function will be something we do later on when we refactor the line items component to be better
      // also we need to make it so that
      setSubscription({
        ...subscription,
        subscriptionId: selectedSubscription.subscriptionId,
        selectedSubscription: selectedSubscription,
        selectedVisit: selectedVisit,
      });

      pushVisitToLineItems(selectedVisit);
      onClose();
    } catch (error) {
      console.error("Error updating subscription for job", error);
      openSnackBar("Error updating subscription for job", false, true);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRemoveVisit = async () => {
    try {
      setLoading(true);
      await removeVisitFromJob({
        businessId: selectedSubscription.businessId,
        jobId: fromJobDetails,
        visitId: subscription.selectedVisit.id,
        subscriptionId: selectedSubscription.subscriptionId,
      });
      setSubscription({
        ...subscription,
        selectedVisit: null,
      });

      openSnackBar("Visit removed from job", true);
      onClose();
    } catch (error) {
      console.error("Error removing visit from job", error);
      openSnackBar("Error removing visit from job", false, true);
    } finally {
      setLoading(false);
    }
  };

  // console.log("selected subscription", selectedSubscription);

  // means we need to give simplified modal to remove the visit from the job
  if (fromJobDetails && subscription?.selectedVisit) {
    return (
      <Dialogue open={open} onClose={onClose}>
        <h1 className="text-xl font-bold text-gray-900 mb-4">
          Remove Visit from Job
        </h1>
        <h2 className="text-base text-gray-500 mb-4">
          Once you remove this visit from the job, you will be able to add a new
          visit to the job.
        </h2>
        <div className="flex flex-row w-full pt-4">
          <CancelButton text="Cancel" handleCancel={onClose} className="mr-3" />
          <DeleteButton
            text="Remove Visit"
            onClick={handleRemoveVisit}
            loading={loading}
          />
        </div>
      </Dialogue>
    );
  }
  return (
    <Dialogue open={open} onClose={onClose} fixedHeight="h-[500px]">
      <h1 className="text-xl font-bold text-gray-900 mb-4">
        Associate Visit to Job
      </h1>
      <div className="flex flex-col w-full p-4">
        <div className="flex justify-between w-full mb-4">
          {subscription?.subscriptions?.length > 1 &&
          selectedSubscription?.template?.title ? (
            <div className="min-w-48 ">
              <BasicSelect
                label="Select Subscription"
                value={selectedSubscription?.subscriptionId}
                onChangeFunction={handleSubscriptionChange}
                options={subscription?.subscriptions.map((sub) => ({
                  label: sub.template.title,
                  value: sub.subscriptionId,
                  description:
                    `(${sub.status})` + " " + sub.template.description,
                }))}
                borderNone={true}
                labelColor="#6b7280"
                focusedLabelColor="#6b7280"
                fullWidth
              />
            </div>
          ) : (
            <div />
          )}
          <div className="w-24">
            <BasicSelect
              fontSize="0.875rem"
              value={selectedYear}
              setValue={handleYearChange}
              options={generateYearOptions(startYear)}
              borderNone={true}
              fullWidth
            />
          </div>
        </div>

        <Grid container spacing={2} className="mt-4">
          {filteredVisits
            .slice((page - 1) * visitsPerPage, page * visitsPerPage)
            .map((visit) => (
              <Grid item xs={12} key={visit.id}>
                <div
                  className={`p-2 rounded-md cursor-pointer  ${
                    selectedVisit?.id === visit.id
                      ? "bg-yellow-100 hover:bg-yellow-100"
                      : "bg-white hover:bg-yellow-50"
                  }`}
                  onClick={() => handleVisitSelect(visit)}
                >
                  <div className="flex justify-between items-center">
                    <span>{visit.name}</span>
                    <span>{`${monthMap[visit.month]} ${visit.year}`}</span>
                    {/* {selectedVisit?.id === visit.id && <span>✔️</span>} */}
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>

        <div className="flex justify-center my-4">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            sx={{
              "& .MuiPaginationItem-root": {
                color: "#6B7280", // Tailwind gray-500
              },
              "& .Mui-selected": {
                backgroundColor: "#F3F4F6", // Tailwind gray-50
                color: "#6B7280", // Tailwind gray-500
              },
              "& .MuiPaginationItem-root:hover": {
                backgroundColor: "#D1D5DB", // Tailwind gray-300 for hover effect
              },
            }}
          />
        </div>

        {/* <button
          className="self-end bg-yellow-400 text-gray-700 hover:bg-yellow-500 py-2 px-4 rounded-md"
          onClick={handleSave}
        >
          Save
        </button> */}
        <div className="flex flex-row justify-end absolute bottom-4 left-4 right-4">
          <CancelButton text="Cancel" handleCancel={onClose} className="mr-3" />
          <NormalButton
            // disabled={!selectedVisit}
            text="Save"
            onClick={handleSave}
            additionalClasses="self-end"
            loading={loading}
          />
        </div>
      </div>
    </Dialogue>
  );
};

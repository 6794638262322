export const formatPhone = (value) => {
  // console.log("raw phone value", value);
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const formatPhoneDisplay = (value) => {
  // console.log("raw phone value", value);
  if (!value) return value;

  // Remove any non-digit characters from the phone number
  const phoneNumber = value.replace(/[^\d]/g, "");

  // Check if the phone number starts with the country code "+1"
  const hasCountryCode = phoneNumber.startsWith("1");

  // Remove the country code if present
  const phoneNumberWithoutCountryCode = hasCountryCode
    ? phoneNumber.slice(1)
    : phoneNumber;

  const phoneNumberLength = phoneNumberWithoutCountryCode.length;

  if (phoneNumberLength < 4) return phoneNumberWithoutCountryCode;

  if (phoneNumberLength < 7) {
    return `(${phoneNumberWithoutCountryCode.slice(
      0,
      3
    )}) ${phoneNumberWithoutCountryCode.slice(3)}`;
  }

  return `(${phoneNumberWithoutCountryCode.slice(
    0,
    3
  )}) ${phoneNumberWithoutCountryCode.slice(
    3,
    6
  )}-${phoneNumberWithoutCountryCode.slice(6, 10)}`;
};

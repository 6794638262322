import React, { useState, useEffect } from "react";
import { CiMoneyCheck1 } from "react-icons/ci";
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import { node } from "constants/constants";
import {
  getDoc,
  doc,
  updateDoc,
  serverTimestamp,
  arrayUnion,
} from "firebase/firestore";
import { format, set } from "date-fns";
import { db } from "utils/firebase";
import Toggle from "components/reusable/Toggle";
import ExitModalButton from "components/buttons/ExitModalButton";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSnackBar } from "context/SnackBarContext";
import DisplayCard from "components/payments/DisplayCard";
import NormalButton from "components/buttons/NormalButton";
import { useUserContext } from "context/UserContext";
import Dialogue from "components/modals/Dialogue";
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group";
import DisabledButton from "components/buttons/DisabledButton";
import CancelButton from "components/buttons/CancelButton";
import { handleSubscriptionPaymentNewCard } from "../../utils/handleSubscriptionPaymentNewCard";
import { useNavigate } from "react-router-dom";
import { determinePaymentTiming } from "../../utils/determinePaymentTiming";

// ok so we need to implement this from service subscription details first.. because we need to create the subscription first and then have them pay... so it will create, navigate, and then auto open the modal

export default function ManualAcceptAndPayServiceSubscription({
  open,
  onClose,
  subscription,
  setSubscription,
}) {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();
  const navigate = useNavigate();

  const [stripePromise, setStripePromise] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [useSavedPaymentMethod, setUseSavedPaymentMethod] = useState(true);

  useEffect(() => {
    setErrorMessage(null);
  }, [open]);

  useEffect(() => {
    if (!userData?.bizData?.stripeAccountId) return;
    setStripePromise(
      loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: userData.bizData.stripeAccountId,
      })
    );
  }, [userData]);

  const [paymentTiming, setPaymentTiming] = useState(null);

  useEffect(() => {
    if (subscription?.startDate) {
      try {
        const timing = determinePaymentTiming(subscription.startDate);
        setPaymentTiming(timing);
      } catch (error) {
        setErrorMessage(error.message);
      }
    }
  }, [subscription]);

  const handlePayment = async (e, stripe, elements) => {
    e.preventDefault();
    setIsProcessing(true);
    setErrorMessage(null);
    try {
      if (
        useSavedPaymentMethod &&
        subscription?.customer?.stripePaymentMethod
      ) {
        await handlePayWithCardOnFile();
      } else {
        await handlePayWithNewCard(stripe, elements);
      }
      openSnackBar("Subscription activated", true);
      onClose();
    } catch (error) {
      console.error(
        "Error processing payment ===>>> catchblock main error object:",
        error
      );
      console.log("catchblock... error.message", error.message);
      setErrorMessage(
        error.message || "Failed to process payment. Please try again."
      );
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePayWithCardOnFile = async () => {
    // console.log("handlePayWithCardOnFile");
    // console.log("subscription", subscription.subscriptionId);
    // console.log("subscription.customer", subscription.customer.customerId);
    // console.log(
    //   "subscription.customer.stripePaymentMethod.id",
    //   subscription.customer.stripePaymentMethod.id
    // );
    // console.log(
    //   "userData.bizData.stripeAccountId",
    //   userData.bizData.stripeAccountId
    // );

    try {
      const response = await fetch(`${node}/serviceSubscriptionsApi/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscription: {
            businessId: subscription.businessId,
            customerId: subscription.customer.customerId,
            subscriptionId: subscription.subscriptionId,
            template: subscription.template,
            startDate: subscription.startDate,
            accept: subscription.accept,
            tax: subscription.tax,
          },
          customer: subscription.customer,
          paymentMethodId: subscription.customer.stripePaymentMethod.id,
          shouldSavePaymentMethod: false, // it should already be saved on file to customer doc
          stripeAccountId: userData.bizData.stripeAccountId,
          useExistingPaymentMethod: true,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to create subscription");
      }

      const result = await response.json();

      // console.log("result", result);

      if (!result.success) {
        throw new Error(result.error || "Failed to create subscription");
      }

      if (result.message === "Operation was already completed") {
        throw new Error("Subscription already activated");
      }

      setSubscription((prev) => ({
        ...prev,
        status: "active",
        stripeSubscription: result.stripeSubscription,
      }));
    } catch (error) {
      console.error("Error creating subscription:", error);
      throw error;
    }
  };

  const handlePayWithNewCard = async (stripe, elements) => {
    // Implement the logic for paying with a new card
    const result = await handleSubscriptionPaymentNewCard({
      stripe,
      elements,
      subscription,
      customer: subscription.customer,
      shouldSavePaymentMethod: !subscription?.customer?.stripePaymentMethod, // if there is no saved method then we save it
    });

    if (!result.success) {
      throw new Error(
        result.error || "Failed to process payment. Please try again."
      );
    }

    // console.log("result.stripeSubscription", result.stripeSubscription);

    setSubscription((prev) => ({
      ...prev,
      status: "active",
      stripeSubscription: result.stripeSubscription,
    }));
  };

  return (
    <Dialogue onClose={onClose} open={open} fixedHeight="h-[400px]" width={700}>
      <Elements stripe={stripePromise}>
        <div className="flex flex-col h-full">
          <Header onClose={onClose} />
          <div className="flex-grow overflow-y-auto px-4">
            <PaymentForm
              cardComplete={cardComplete}
              setCardComplete={setCardComplete}
              errorMessage={errorMessage}
              isProcessing={isProcessing}
              savedPaymentMethod={subscription?.customer?.stripePaymentMethod}
              useSavedPaymentMethod={useSavedPaymentMethod}
              setUseSavedPaymentMethod={setUseSavedPaymentMethod}
            />
            {true && (
              <div className="text-red-600 text-xs mb-2 mx-4">
                <p>{errorMessage}</p>
              </div>
            )}
          </div>
          <div className="mt-auto p-4 mb-2 flex justify-between items-center">
            {paymentTiming && (
              <div className="bg-yellow-50 border-l border-yellow-500 text-yellow-600 px-2 mr-4 text-xs h-10 flex items-center rounded-r-md">
                <p>{paymentTiming.message}</p>
              </div>
            )}
            <div className="flex">
              <CancelButton
                text="Cancel"
                handleCancel={onClose}
                className="mr-4"
                disabled={isProcessing}
              />
              <PaymentButton
                handlePayment={handlePayment}
                isProcessing={isProcessing}
                cardComplete={cardComplete}
                useSavedPaymentMethod={useSavedPaymentMethod}
              />
            </div>
          </div>
        </div>
      </Elements>
    </Dialogue>
  );
}

const PaymentForm = ({
  setCardComplete,
  isProcessing,
  cardComplete,
  errorMessage,
  savedPaymentMethod,
  useSavedPaymentMethod,
  setUseSavedPaymentMethod,
}) => {
  if (!savedPaymentMethod) {
    return (
      <div className="mt-16 p-4">
        <NewCardStripeForm
          setCardComplete={setCardComplete}
          isProcessing={isProcessing}
          cardComplete={cardComplete}
          errorMessage={errorMessage}
        />
      </div>
    );
  }

  return (
    <div className="p-4">
      <RadioGroup
        className="flex flex-col gap-4 mb-2"
        value={useSavedPaymentMethod ? "saved" : "new"}
        onValueChange={(value) => setUseSavedPaymentMethod(value === "saved")}
        disabled={isProcessing}
      >
        <PaymentOption
          value="saved"
          label="Card on file"
          checked={useSavedPaymentMethod}
        >
          {useSavedPaymentMethod && (
            <>
              <DisplayCard
                savedPaymentMethod={savedPaymentMethod}
                className="font-bold mt-2"
              />
            </>
          )}
        </PaymentOption>
        <PaymentOption
          value="new"
          label="Another Card"
          checked={!useSavedPaymentMethod}
        />
      </RadioGroup>

      {!useSavedPaymentMethod && (
        <div className="mt-4">
          <NewCardStripeForm
            setCardComplete={setCardComplete}
            isProcessing={isProcessing}
            cardComplete={cardComplete}
            errorMessage={errorMessage}
          />
        </div>
      )}
    </div>
  );
};

const PaymentOption = ({ value, label, checked, children }) => (
  <div className="flex items-start space-x-2">
    <RadioGroupItem value={value} id={`payment-${value}`} className="mt-1" />
    <div>
      <label htmlFor={`payment-${value}`} className="text-gray-600">
        {label}
      </label>
      {checked && children}
    </div>
  </div>
);

const NewCardStripeForm = ({ setCardComplete, isProcessing, errorMessage }) => {
  const options = {
    style: {
      base: {
        iconColor: "#0f172a",
        color: "#1e293b",
        fontWeight: "400",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#1e293b" },
        "::placeholder": { color: "#94a3b8" },
      },
      invalid: {
        iconColor: "#ef4444",
        color: "#ef4444",
      },
    },
    disableLink: true,
  };

  return (
    <div>
      <CardElement
        className="p-4 shadow-md ring-1 ring-gray-500 rounded-md"
        options={options}
        onChange={(e) => setCardComplete(e.complete)}
      />

      <div className="text-center">
        <label className=" text-gray-400 text-xs">
          This payment method will be saved for future use
        </label>
      </div>
    </div>
  );
};

const PaymentButton = ({
  handlePayment,
  isProcessing,
  cardComplete,
  useSavedPaymentMethod,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  return (
    <DisabledButton
      text="Activate"
      width="w-48"
      loading={isProcessing}
      disabled={
        !stripe || (!cardComplete && !useSavedPaymentMethod) || isProcessing
      }
      onClick={(e) => handlePayment(e, stripe, elements)}
    />
  );
};

const Header = ({ onClose }) => {
  return (
    <>
      <ExitModalButton handleClose={onClose} position="top-6 right-5" />
      <div className="flex w-full flex-row items-center gap-2 mt-0 mb-2 ml-2">
        <CiMoneyCheck1 className="inline-block text-3xl" />
        <h1 className="text-xl font-bold">Accept Service Subscription</h1>
      </div>
    </>
  );
};

import React, { useState, useContext, useEffect, useRef } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";
import { BsPeopleFill } from "react-icons/bs";
import { RiProfileFill } from "react-icons/ri";
import { BsCreditCardFill } from "react-icons/bs";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { AiOutlineLogout } from "react-icons/ai";
import { BsFillGearFill, BsPinMapFill } from "react-icons/bs";
import { BiCustomize } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import { useUserContext } from "context/UserContext";
import { SignOutUser } from "utils/firebase";

const NavbarDropdown = ({ bannerVisible }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUserData } = useUserContext();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const dropdownRef = useRef();
  const editButtonRef = useRef();

  const menuItems = [
    {
      path: "/settings/companyprofile",
      label: "Company Profile",
      Icon: RiProfileFill,
    },
    { path: "/settings/employees", label: "Employees", Icon: BsPeopleFill },
    {
      path: "/settings/homebase360payments",
      label: "Homebase Payments",
      Icon: BsCreditCardFill,
    },
    {
      path: "/settings/customizations/notifications",
      label: "Customizations",
      Icon: BiCustomize,
    },
    { path: "/territorymap", label: "Map", Icon: BsPinMapFill },
    // { path: "/price-book", label: "Price Book", Icon: RiMoneyDollarCircleFill }, // MF: temp icon

    {
      path: "/settings/billing",
      label: "Billing",
      Icon: RiMoneyDollarCircleFill,
    },
    { path: "/settings", label: "Settings", Icon: BsFillGearFill },
  ];

  const isActive = (path) => {
    // Special handling for the "/settings" path to ensure it's not falsely marked active
    if (path === "/settings") {
      return location.pathname === "/settings";
    }

    if (location.pathname === "/settings/newemployee") {
      return path === "/settings/employees";
    }

    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    //create event listener for click outside of dropdown to close it
    const handleClickOutside = (e) => {
      if (
        settingsOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !editButtonRef.current.contains(e.target)
      ) {
        console.log("clicked outside");
        setSettingsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [settingsOpen, dropdownRef]);

  const handleToggle = () => {
    setSettingsOpen(!settingsOpen);
  };

  const handleNavigation = (path) => {
    setSettingsOpen(false);
    navigate(path);
  };

  return (
    <>
      <li className="my-2 list-none">
        <div
          className="rounded-md  hover:text-yellow-50 hover:font-extrabold cursor-pointer "
          ref={editButtonRef}
          onClick={handleToggle}
        >
          <IoSettingsOutline className="text-2xl" />
        </div>
      </li>

      {settingsOpen && (
        <div
          className={`fixed ${
            bannerVisible ? "top-28" : "top-14"
          } right-0 p-5 rounded-md bg-gray-900 w-64 z-50`}
          ref={dropdownRef}
        >
          <div className="flex flex-col text-gray-50 font-medium gap-4 ">
            {menuItems.map(({ path, label, Icon }) => (
              <div
                key={label}
                className={`hover:font-bold hover:text-white flex flex-row cursor-pointer items-center ${
                  isActive(path) && "text-white font-bold"
                }`}
                onClick={() => handleNavigation(path)}
              >
                {isActive(path) && <IoIosArrowForward className="mr-1" />}
                <Icon className={`text-lg mr-2 mb-0.5 ${isActive(path) && "text-white"}`} />
                {label}
              </div>
            ))}
            <hr className="mt-3 border-gray-200" />
            {user ? (
              <div
                className="mt-3 hover:font-bold hover:text-white cursor-pointer flex flex-row items-center"
                onClick={() => SignOutUser({ setUserData })}
              >
                <AiOutlineLogout className="text-lg mr-2 mb-0.5" />
                Logout
              </div>
            ) : (
              <div
                className="mt-3 hover:font-bold hover:text-white cursor-pointer flex flex-row items-center"
                onClick={() => navigate("/login")}
              >
                <AiOutlineLogout className="text-lg mr-2 mb-0.5" />
                Login
              </div>
            )}
          </div>
        </div>
      )}

      {/* {settingsOpen && (
        <div
          className={`fixed ${
            bannerVisible ? "top-28" : "top-14"
          } right-0 p-5 rounded-md bg-gray-900 w-64 z-50`}
          ref={dropdownRef}
        >
          <div className="flex flex-col text-gray-50 font-medium gap-4 ">
            <h1
              className="hover:font-bold hover:text-white cursor-pointer flex flex-row items-center mt-2"
              onClick={() => {
                setSettingsOpen(false);
                navigate("/settings/companyprofile");
              }}
            >
              <RiProfileFill className="text-gray-50 text-lg hover:text-white mr-2 mb-0.5" />
              Company Profile
            </h1>
            <button
              className="hover:font-bold hover:text-white cursor-pointer flex flex-row items-center"
              onClick={() => {
                setSettingsOpen(false);
                navigate("/settings/employees");
              }}
            >
              <BsPeopleFill className="text-gray-50 text-lg hover:text-white mr-2 mb-0.5" />
              Employees
            </button>
            <h1
              className="hover:font-bold hover:text-white cursor-pointer flex flex-row items-center"
              onClick={() => {
                setSettingsOpen(false);
                navigate("settings/homebase360payments");
              }}
            >
              <BsCreditCardFill className="text-gray-50 text-lg hover:text-white mr-2 mb-0.5" />
              Homebase Payments
            </h1>
            <h1
              className="hover:font-bold hover:text-white cursor-pointer flex flex-row items-center"
              onClick={() => {
                setSettingsOpen(false);
                navigate("settings/customizations");
              }}
            >
              <BiCustomize className="text-gray-50 text-lg hover:text-white mr-2 mb-0.5" />
              Customizations
            </h1>
            <h1
              className="hover:font-bold hover:text-white cursor-pointer flex flex-row items-center"
              onClick={() => {
                setSettingsOpen(false);
                navigate("territorymap");
              }}
            >
              <BsPinMapFill className="text-gray-50 text-lg hover:text-white mr-2 mb-0.5" />
              Map
            </h1>
            <h1
              className="hover:font-bold hover:text-white cursor-pointer flex flex-row items-center"
              onClick={() => {
                setSettingsOpen(false);
                navigate("settings/billing");
              }}
            >
              <RiMoneyDollarCircleFill className="text-gray-50 text-lg hover:text-white mr-2 mb-0.5" />
              Billing
            </h1>
            <h1
              className="hover:font-bold hover:text-white cursor-pointer flex flex-row items-center"
              onClick={() => {
                setSettingsOpen(false);
                navigate("settings");
              }}
            >
              <BsFillGearFill className="text-gray-50 text-lg hover:text-white mr-2 mb-0.5" />
              Settings
            </h1>
            <hr className="mt-3 border-gray-200" />
            {user ? (
              <h1
                className="mt-3 hover:font-bold hover:text-white cursor-pointer flex flex-row items-center"
                onClick={() => SignOutUser({ setUserData })}
              >
                <AiOutlineLogout className="text-gray-50 text-lg hover:text-white mr-2 mb-0.5" />{" "}
                Logout
              </h1>
            ) : (
              <h1
                className="mt-3 hover:font-bold hover:text-white cursor-pointer flex flex-row items-center"
                onClick={() => navigate("/login")}
              >
                <AiOutlineLogout className="text-gray-50 text-lg hover:text-white mr-2 mb-0.5" />{" "}
                Login
              </h1>
            )}
          </div>
        </div>
      )} */}
    </>
  );
};

export default NavbarDropdown;

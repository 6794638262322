import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { BiSearchAlt2 } from "react-icons/bi";

export default function SearchConversations({
  label,
  inputValue,
  setInputValue,
  conversations,
  setFilteredConversations,
  ...props
}) {
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  // Filter the salespeople based on the user's input

  useEffect(() => {
    if (conversations && conversations.length > 0) {
      const filteredConversation = conversations?.filter(
        (conversation) =>
          conversation.displayName
            ?.toLowerCase()
            .includes(inputValue?.toLowerCase()) ||
          conversation.phone?.toLowerCase().includes(inputValue?.toLowerCase())
      );

      setFilteredConversations(filteredConversation);
    } else {
      setFilteredConversations([]);
    }
  }, [inputValue, conversations]);

  // const filteredConversation = conversations?.filter(
  //   (conversation) =>
  //     conversation.firstName
  //       .toLowerCase()
  //       .includes(inputValue?.toLowerCase()) ||
  //     conversation?.lastName
  //       ?.toLowerCase()
  //       .includes(inputValue?.toLowerCase()) ||
  //     conversation?.phone?.toLowerCase().includes(inputValue?.toLowerCase())
  // )

  return (
    <div className="relative w-1/2 flex flex-col " {...props}>
      <input
        className="bg-gray-50 border border-gray-300 rounded-md outline-none focus:border-gray-400 text-base font-thin py-1 pl-9"
        value={inputValue}
        placeholder={label}
        onChange={handleChange}
      />
      <BiSearchAlt2
        className={`absolute top-1.5 left-2 text-2xl text-gray-400  ${
          inputValue && "text-gray-600"
        }`}
      />
    </div>
  );
}

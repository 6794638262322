import React, { useState, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { RiRefund2Line } from "react-icons/ri";
import { BiLoader } from "react-icons/bi";
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import { node } from "../../../constants/constants";
import {
  getDoc,
  doc,
  updateDoc,
  serverTimestamp,
  arrayUnion,
} from "firebase/firestore";
import { format } from "date-fns";
import NormalButton from "components/buttons/NormalButton";
import ExitModalButton from "components/buttons/ExitModalButton";

export default function RefundModal({
  toggleModal,
  jobDetails,
  setJobDetails,
  userData,
  jobRef,
  payment,
}) {
  const [paymentAmount, setPaymentAmount] = useState(
    payment.totalAmountFromStripe / 100
  );
  const [emailForReceipt, setEmailForReceipt] = useState(
    jobDetails?.customer.email[0]
  );
  const [refundLoading, setRefundLoading] = useState(false);

  const [refundError, setRefundError] = useState(null);

  const handleRefundPayment = async () => {
    // call the node server at intents/refund to refund the payment intent
    // console.log("paymentIntentId", payment.paymentIntentId)
    // console.log("paymentAmount", paymentAmount * 100)
    // console.log("stripeAccountId", userData.bizData.stripeAccountId)
    setRefundLoading(true);
    // console.log("emailForReceipt", emailForReceipt)
    try {
      const res = await fetch(`${node}/intents/refund`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentIntentId: payment.paymentIntentId,
          amount: paymentAmount * 100,
          stripeAccountId: userData.bizData.stripeAccountId,
        }),
      });
      const { refund } = await res.json();
      // console.log("data", refund)
      if (refund.status === "succeeded") {
        // update the job details in the database
        console.log("refund succeeded");
        await updateDoc(jobRef, {
          paymentHistory: arrayUnion({
            amount: paymentAmount,
            date: new Date(),
            refundId: refund.id,
            totalAmountFromStripe: refund.amount,
            status: refund.status,
            paymentIntentId: refund.payment_intent,
            billingType: "Refund",
          }),
        });
        // update the job details in the state
        setJobDetails((prev) => ({
          ...prev,
          paymentHistory: [
            ...(prev.paymentHistory ? prev.paymentHistory : []),
            {
              amount: paymentAmount,
              date: new Date(),
              refundId: refund.id,
              totalAmountFromStripe: refund.amount,
              status: refund.status,
              paymentIntentId: refund.payment_intent,
              billingType: "Refund",
            },
          ],
        }));
        // close the modal

        toggleModal();
      } else {
        console.log("refund failed");
        setRefundError("Refund failed");
      }
    } catch (error) {
      console.log("error is here", error);
      setRefundError("Refund failed");
    }
    setRefundLoading(false);
  };
  // for toggling the modal
  const [warningModal, setWarningModal] = useState(false);
  const toggleWarningModal = () => {
    setWarningModal(!warningModal);
  };
  const handleConfirmWarningModal = () => {
    toggleModal();
    toggleWarningModal();
  };
  return (
    <>
      <div className="modal">
        <div
          className="overlay"
          onClick={() => console.log("clicked overlay")}
        ></div>
        <div className="note-modal-content ">
          {/* <IoCloseSharp
            onClick={toggleWarningModal}
            className="absolute text-5xl cursor-pointer"
            style={{
              top: "35px",
              right: "50px",
            }}
          /> */}
          <ExitModalButton
            handleClose={toggleWarningModal}
            position="top-5 right-8"
          />
          <div className="flex flex-col  flex-1 lg:col-span-2 bg-white rounded-md shadow-md  gap-2 mb-8 pb-8 p-4">
            <div className="flex w-full flex-row items-center gap-2 mt-4 mb-2 ml-2">
              <RiRefund2Line className="inline-block text-3xl" />
              <h1 className="text-xl font-bold">Refund</h1>
            </div>
            <h1 className="text-gray-700 text-sm mt-3 mx-2">
              This payment will be refunded to the customer's card. This may
              take 3-5 business days.
            </h1>
            <div className="flex flex-row w-full justify-between items-end mt-5 mx-2">
              <div className="flex flex-col w-1/2 ">
                <label className="text-gray-500 text-sm ml-2">Amount</label>

                <input
                  type="number"
                  className="number-input border w-1/2 border-gray-300 rounded-md p-3 outline-none focus:border-yellow-200 focus:ring-yellow-200 focus:ring-2"
                  placeholder="Amount"
                  min="0"
                  step="0.01"
                  onKeyDown={(event) => {
                    if (event.key === "-") {
                      event.preventDefault();
                    }
                  }}
                  value={paymentAmount}
                  onChange={(e) => setPaymentAmount(e.target.value)}
                />
              </div>
              {/* <div className="flex flex-col w-1/2">
                <label className="text-gray-500 text-sm ml-2">
                  Email for receipt
                </label>

                <input
                  type="text"
                  className=" border border-gray-300 rounded-md p-3 "
                  placeholder="Email for receipt"
                  value={emailForReceipt}
                  onChange={(e) => setEmailForReceipt(e.target.value)}
                />
              </div> */}
              <div className="mr-3">
                <NormalButton
                  onClick={handleRefundPayment}
                  text="Refund"
                  loading={refundLoading}
                />
              </div>
              {/* <button
                className="bg-gray-900 font-semibold w-1/4 mt-5 mx-2 rounded-md p-3  text-yellow-50 text-md mr-3 hover:text-yellow-100"
                onClick={handleRefundPayment}
              >
                {refundLoading ? (
                  <BiLoader className="animate-spin-slow inline-block text-2xl text-white" />
                ) : (
                  "Refund"
                )}
              </button> */}
            </div>{" "}
            {refundError && (
              <h1 className="text-red-500 text-sm mt-3 mx-2">{refundError}</h1>
            )}
          </div>
        </div>
      </div>

      {warningModal && (
        <div className="warning-modal">
          <div
            className="warning-overlay"
            onClick={() => console.log("clicked overlay")}
          ></div>
          <div className="warning-modal-content ">
            <div className="mt-5">
              <h1 className="text-2xl font-semibold">Are you sure?</h1>
              <h1>Your changes will be discarded</h1>
            </div>
            <div className="flex flex-row justify-end items-center mb-4">
              <button
                className="border-2 border-gray-300 bg-gray-50 rounded-md p-2 mt-1 text-gray-500 text-md"
                onClick={toggleWarningModal}
              >
                Cancel
              </button>
              <button
                className=" bg-gray-900 font-semibold rounded-md p-2 mt-1 text-yellow-100 text-md ml-2 hover:bg-gray-800"
                onClick={handleConfirmWarningModal}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import YellowButtonFilled from "components/buttons/YellowButtonFilled";
import { useSnackBar } from "context/SnackBarContext";
import { useNavigate } from "react-router-dom";
import SubscriptionCancelModal from "./SubscriptionCancelModal";
import SubscriptionDeleteModal from "./SubscriptionDeleteModal";
import ConfirmCreationModal from "../NewServiceSubscription/ConfirmCreationModal";
import { node } from "constants/constants";
import { useUserContext } from "context/UserContext";
import ManualAcceptAndPayServiceSubscription from "../NewServiceSubscription/ManualAcceptAndPayServiceSubscription";
import { BackLink } from "../../../../components/NewButtons/BackLink";

export default function Header({
  fromCustomerDetailsPage,
  subscription,
  setSubscription,
  openAcceptModal,
}) {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();
  const navigate = useNavigate();
  const [cancelModal, setCancelModal] = useState(false);
  const [sendSubModal, setSendSubModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [acceptAndPayModal, setAcceptAndPayModal] = useState(
    openAcceptModal || false
  );
  const [loadingSubscriptionSend, setLoadingSubscriptionSend] = useState(false);
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState(null);
  const isMoreActionsMenuOpen = Boolean(moreActionsAnchorEl);

  const handleMoreActionsClick = (event) => {
    setMoreActionsAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMoreActionsAnchorEl(null);
  };

  const handleResendSubscription = async ({
    emailToSend,
    phoneToSend,
    message,
  }) => {
    try {
      setLoadingSubscriptionSend(true);
      const res = await fetch(`${node}/serviceSubscriptionsApi/resend`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscriptionId: subscription.subscriptionId,
          businessId: subscription.businessId,
          emailToSend,
          phoneToSend,
          message,
          userId: userData?.userData?.id,
          userName: [userData.userData?.firstName, userData.userData?.lastName]
            .filter(Boolean)
            .join(" "),
        }),
      });
      if (!res.ok) {
        throw new Error(
          "An unexpected error occurred. Please try again later."
        );
      }
      const result = await res.json();
      if (!result.success) {
        throw new Error(
          result.error ||
            "An unexpected error occurred. Please try again later."
        );
      }

      openSnackBar("Subscription sent successfully", true);
      setSendSubModal(false);
    } catch (error) {
      openSnackBar(
        error.message || "An error occurred. Please try again",
        false,
        true
      );
    } finally {
      setLoadingSubscriptionSend(false);
    }
  };

  return (
    <div className="flex max-w-[1510px] mx-auto">
      <div className="flex flex-row m-5 my-6 items-center justify-between w-full relative h-11">
        {fromCustomerDetailsPage && (
          <div className="absolute flex flex-row items-center -top-4 -left-5">
            <BackLink
              toName={`${subscription?.customer?.displayName || "Customer"}`}
              toLink={`/customers/${fromCustomerDetailsPage}`}
            />
          </div>
        )}
        <h1 className=" text-3xl text-gray-900 font-bold">
          Service Subscription
        </h1>

        <div className="flex flex-row items-center justify-between">
          {/* <YellowButtonFilled
            onClick={() => {}}
            text="Send"
            className="mr-4 w-20"
          />
          <button
            onClick={() => {}}
            className="bg-gray-900 p-2 rounded-md shadow-sm hover:text-yellow-100 transition-colors duration-200 font-medium text-sm text-white w-20 mr-4"
          >
            Accept
          </button> */}
          <IconButton
            aria-label="more actions"
            onClick={handleMoreActionsClick}
            sx={{
              "&:hover": {
                backgroundColor: "#f3f4f6", // Tailwind's gray-100 color
              },
            }}
            disableRipple
          >
            <MoreVertIcon style={{ color: "#111827" }} />
          </IconButton>
          <Menu
            anchorEl={moreActionsAnchorEl}
            open={isMoreActionsMenuOpen}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "more-actions-button",
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {/* <MenuItem
              onClick={() => {}}
              disableRipple
              sx={{
                fontFamily: "Inter var, system-ui, sans-serif",

                fontSize: "0.875rem",
                "&:hover": {
                  backgroundColor: "#fefce8", // Tailwind's yellow-50 color
                },
              }}
            >
              Edit Subscription
            </MenuItem> */}
            {(subscription?.status === "draft" ||
              subscription?.status === "sent") &&
              !subscription.template.requireSignature && (
                <MenuItem
                  onClick={() => {
                    setAcceptAndPayModal(true);
                    handleMenuClose();
                  }}
                  disableRipple
                  sx={{
                    fontFamily: "Inter var, system-ui, sans-serif",

                    fontSize: "0.875rem",
                    "&:hover": {
                      backgroundColor: "#f3f4f6", // Tailwind's gray-100 color
                    },
                  }}
                >
                  Accept
                </MenuItem>
              )}

            {(subscription?.status === "draft" ||
              subscription?.status === "sent") && (
              <MenuItem
                onClick={() => {
                  setSendSubModal(true);
                  handleMenuClose();
                }}
                disableRipple
                sx={{
                  fontFamily: "Inter var, system-ui, sans-serif",

                  fontSize: "0.875rem",
                  "&:hover": {
                    backgroundColor: "#f3f4f6", // Tailwind's gray-100 color
                  },
                }}
              >
                Resend
              </MenuItem>
            )}
            {subscription?.status === "active" && (
              <MenuItem
                onClick={() => {
                  setCancelModal(true);
                  handleMenuClose();
                }}
                disableRipple
                sx={{
                  fontFamily: "Inter var, system-ui, sans-serif",

                  fontSize: "0.875rem",
                  "&:hover": {
                    backgroundColor: "#fecaca", // Tailwind's yellow-50 color
                  },
                }}
              >
                Cancel Subscription
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                setDeleteModal(true);
                handleMenuClose();
              }}
              disableRipple
              sx={{
                fontFamily: "Inter var, system-ui, sans-serif",

                fontSize: "0.875rem",
                "&:hover": {
                  backgroundColor: "#fecaca", // Tailwind's yellow-50 color
                },
                // "&:focus": {
                //   backgroundColor: "#eb0101", // Tailwind's yellow-50 color
                // },
              }}
            >
              Delete Subscription
            </MenuItem>
          </Menu>
        </div>
      </div>
      <ManualAcceptAndPayServiceSubscription
        subscription={subscription}
        setSubscription={setSubscription}
        open={acceptAndPayModal}
        onClose={() => setAcceptAndPayModal(false)}
      />
      <SubscriptionCancelModal
        open={cancelModal}
        onClose={() => setCancelModal(false)}
        subscription={subscription}
        setSubscription={setSubscription}
      />
      <SubscriptionDeleteModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        subscription={subscription}
        navigate={navigate}
      />
      <ConfirmCreationModal
        open={sendSubModal}
        onClose={() => {
          setSendSubModal(false);
        }}
        subscriptionDetails={subscription}
        handleCreateSubscription={handleResendSubscription}
        loading={loadingSubscriptionSend}
      />
    </div>
  );
}

// font-family: "Inter var", system-ui, sans-serif;

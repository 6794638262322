import { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "utils/firebase"; // Adjust this import based on your project structure

export function useJobDetailsListener({ businessId, jobId }) {
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!businessId || !jobId) {
      setLoading(false);
      return;
    }

    const jobRef = doc(db, "businesses", businessId, "jobs", jobId);

    const unsubscribe = onSnapshot(
      jobRef,
      (doc) => {
        console.log("job doc changed");
        if (doc.exists()) {
          const jobData = doc.data();
          setJobDetails({
            ...jobData,
            start: jobData?.start?.toDate() ?? null,
            end: jobData?.end?.toDate() ?? null,
            startDrivingTime: jobData?.startDrivingTime?.toDate() ?? null,
            endDrivingTime: jobData?.endDrivingTime?.toDate() ?? null,
            startedJobTime: jobData?.startedJobTime?.toDate() ?? null,
            endedJobTime: jobData?.endedJobTime?.toDate() ?? null,
            invoiceSentTime: jobData?.invoiceSentTime?.toDate() ?? null,
            invoicePaidTime: jobData?.invoicePaidTime?.toDate() ?? null,
            datePaid: jobData?.datePaid?.toDate() ?? null,
            reviewMessageSent: jobData?.reviewMessageSent?.toDate() ?? null,
            reviewSubmitted: jobData?.reviewSubmitted?.toDate() ?? null,
            recurrenceStartDate: jobData?.recurrenceStartDate?.toDate() ?? null,
            recurrenceEndDate: jobData?.recurrenceEndDate?.toDate() ?? null,
          });
        } else {
          setJobDetails(null);
          console.log("No such job");
        }
        setLoading(false);
      },
      (err) => {
        console.error("Error fetching job details:", err);
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [businessId, jobId]);

  return { jobDetails, setJobDetails, loading, error };
}

import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "context/SnackBarContext";

export default function NewDropdown({ userData }) {
  const navigate = useNavigate();
  const { openSnackBar } = useSnackBar();
  const [newClicked, setNewClicked] = useState(false);
  const dropdownRef = useRef(null);
  const editButtonRef = useRef(null);

  const notAbleToCreateJobs =
    userData?.userData?.customPermissions?.create_jobs === false;
  const notAbleToCreateEstimates =
    userData?.userData?.customPermissions?.create_estimates === false;
  const notAbleToCreateCustomers =
    userData?.userData?.customPermissions?.manage_customers === false;

  const handleAddJob = () => {
    if (notAbleToCreateJobs) {
      openSnackBar("You do not have permission to create jobs");
      return;
    }
    navigate("/addjob");
  };

  const handleAddEstimate = () => {
    if (notAbleToCreateEstimates) {
      openSnackBar("You do not have permission to create estimates");
      return;
    }
    navigate("/newestimate");
  };

  const handleAddCustomer = () => {
    if (notAbleToCreateCustomers) {
      openSnackBar("You do not have permission to create customers");
      return;
    }
    navigate("/customers", { state: { newCustomerModal: true } });
  };

  useEffect(() => {
    //create event listener for click outside of dropdown to close it
    const handleClickOutside = (e) => {
      if (
        newClicked &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !editButtonRef.current.contains(e.target)
      ) {
        console.log("clicked outside");
        setNewClicked(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [newClicked, dropdownRef]);
  return (
    <button
      className="bg-gray-100 hover:bg-yellow-50 text-gray-900 shadow-md font-semibold h-9 px-4 rounded-full relative z-1000"
      onClick={() => setNewClicked(!newClicked)}
      ref={editButtonRef}
    >
      {newClicked && (
        <div
          className="absolute top-12 -left-4 z-50 bg-gray-900 rounded-b-md "
          ref={dropdownRef}
        >
          <div className="flex flex-col px-2   pl-4 w-24 text-sm text-gray-100 text-left">
            <h1
              className=" cursor-pointer 
           hover:text-yellow-100 rounded-t-md pt-4 pb-2"
              onClick={handleAddJob}
            >
              Job
            </h1>
            <h1
              className="cursor-pointer  hover:text-yellow-100 pt-2 pb-2 "
              onClick={handleAddEstimate}
            >
              Estimate
            </h1>
            <h1
              className="cursor-pointer  hover:text-yellow-100 rounded-b-md pt-2 pb-4"
              onClick={handleAddCustomer}
            >
              Customer
            </h1>
          </div>
        </div>
      )}
      New
    </button>
  );
}

import React, { useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  //   {
  //     field: "id",
  //     headerName: "#",
  //     width: 50,
  //     filterable: false,
  //     renderCell: (params) =>
  //       params.api.getRowIndexRelativeToVisibleRows(params.id) + 1,
  //   },
  {
    field: "name",
    headerName: "Salesperson",
    flex: 1,
    editable: true,
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", padding: "4px 0px" }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "numberOfJobs",
    headerName: "Jobs",
    flex: 0.5,

    editable: true,
  },

  {
    field: "numberOfCancelledJobs",
    headerName: "Cancelled",
    editable: true,
    flex: 0.5,
  },
  {
    field: "totalAmountSold",
    headerName: "Total Amount Jobs",

    flex: 0.75,
    editable: true,
    valueFormatter: (params) => {
      let value = params.value;
      value = parseFloat(value).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return `$${value}`;
    },
    description: "Total amount of scheduled jobs. (not including tax or tip)",
  },
  {
    field: "numberOfPaidJobs",
    headerName: "Jobs Paid",
    editable: true,
    flex: 0.5,
  },

  {
    field: "totalAmountSoldPaid",
    headerName: "Total Amount Paid Jobs",
    flex: 1,
    // maxWidth: 180,
    valueFormatter: (params) => {
      let value = params.value;
      value = parseFloat(value).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return `$${value}`;
    },
    description: "Total amount of paid jobs (not including tax or tip).",
  },
];

export default function SalespersonReporting({ rows }) {
  const [sortModel, setSortModel] = useState([
    {
      field: "totalAmountSoldPaid",
      sort: "desc",
    },
  ]);

  return (
    <>
      <Box
        sx={{ backgroundColor: "#fff" }}
        className="mt-2 mx-10 shadow-md relative rounded-md mb-16"
      >
        <DataGrid
          sx={{
            "& .Mui-checked .MuiSvgIcon-root": {
              color: "#111827", // Change to your desired color
            },
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "#fefce8", // This is a light red. Change this to your desired color.
            },
            "& .MuiDataGrid-row.Mui-selected:hover": {
              backgroundColor: "#fef9c3", // This is a yellow color for the hover effect. Adjust as needed.
            },
            "& .MuiDataGrid-cell:focus": {
              border: "1px solid #111827 ", // This gives a yellow border to the focused cell.
              boxShadow: "none",
              outline: "none",
            },
            "& .MuiDataGrid-cellCheckbox:focus-within": {
              border: "1px solid #111827", // This targets the checkbox cell specifically when focused.
              boxShadow: "none",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              border: "1px solid #111827",
              boxShadow: "none",
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              border: "1px solid #111827",
              boxShadow: "none",
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus .MuiDataGrid-sortIcon": {
              color: "#111827",
            },
            "& .MuiDataGrid-columnHeaderCheckbox:focus-within": {
              border: "1px solid #111827",
              boxShadow: "none",
              outline: "none",
            },
            "& .MuiDataGrid-columnHeaderCheckbox .Mui-checked .MuiSvgIcon-root":
              {
                color: "#111827",
              },
            "& .MuiDataGrid-columnHeaderCheckbox .MuiIconButton-root:focus-visible":
              {
                boxShadow: "none",
              },
            // "& .MuiDataGrid-cell": {
            //   whiteSpace: "normal",
            //   wordWrap: "break-word",
            // },
            // "& .MuiDataGrid-cell": {
            //   paddingLeft: "32px", // Adjust as needed
            //   paddingRight: "32px", // Adjust as needed
            // },
            // "& .MuiDataGrid-columnHeader": {
            //   paddingLeft: "32px", // Adjust as needed
            //   paddingRight: "32px", // Adjust as needed
            // },
          }}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[15]}
          disableRowSelectionOnClick
          getRowHeight={() => 50}
          sortModel={sortModel}
          onSortModelChange={(params) => {
            setSortModel(params.sortModel);
          }}
        />
      </Box>
    </>
  );
}

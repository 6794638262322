// LeaderboardDataGrid.js
import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const LeaderboardDataGrid = ({ rows, columns, loading }) => {
  //   console.log("columns", columns);
  //   console.log("rows", rows);

  //   if (!rows || !columns) return;

  return (
    <DataGrid
      sx={{
        "& .Mui-checked .MuiSvgIcon-root": {
          color: "#111827",
        },
        "& .MuiDataGrid-row.Mui-selected": {
          backgroundColor: "#fefce8",
        },
        "& .MuiDataGrid-row.Mui-selected:hover": {
          backgroundColor: "#fef9c3",
        },
        "& .MuiDataGrid-cell:focus": {
          border: "1px solid #111827 ",
          boxShadow: "none",
          outline: "none",
        },
        "& .MuiDataGrid-cellCheckbox:focus-within": {
          border: "1px solid #111827",
          boxShadow: "none",
        },
        "& .MuiDataGrid-columnHeader:focus-within": {
          border: "1px solid #111827",
          boxShadow: "none",
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus": {
          border: "1px solid #111827",
          boxShadow: "none",
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus .MuiDataGrid-sortIcon": {
          color: "#111827",
        },
        "& .MuiDataGrid-columnHeaderCheckbox:focus-within": {
          border: "1px solid #111827",
          boxShadow: "none",
          outline: "none",
        },
        "& .MuiDataGrid-columnHeaderCheckbox .Mui-checked .MuiSvgIcon-root": {
          color: "#111827",
        },
        "& .MuiDataGrid-columnHeaderCheckbox .MuiIconButton-root:focus-visible":
          {
            boxShadow: "none",
          },
        "& .MuiCircularProgress-colorPrimary": {
          color: "#fde047",
        },
        "& .MuiDataGrid-virtualScroller": {
          overflowX: "auto",
        },
      }}
      rows={rows || []}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "rank",
              sort: "asc",
            },
          ],
        },
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      pageSizeOptions={[10]}
      disableRowSelectionOnClick
      loading={loading}
    />
  );
};

export default LeaderboardDataGrid;

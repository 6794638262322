import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../index";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { node } from "../../constants/constants";

export default function Refresh() {
  const { user, userData, setRerun } = useContext(UserContext);
  // this is how you would do it if you are making the database call manually instead of accessing the userData from the provided context
  // useEffect(() => {
  //   const docRef = doc(db, "users", user.uid)
  //   const getDocFromFirestore = async () => {
  //     try {
  //       const docSnap = await getDoc(docRef)
  //       if (docSnap.exists()) {
  //         const data = docSnap.data()
  //         const stripeAccountId = data.stripeAccountId

  //         console.log("stripeAccountId", stripeAccountId)

  //         // Submit the form with the stripeAccountId programatically
  //         const form = document.createElement("form")
  //         form.setAttribute("method", "post")
  //         form.setAttribute("action", `${localNode}/connect/refresh`)

  //         const stripeAccountIdInput = document.createElement("input")
  //         stripeAccountIdInput.setAttribute("type", "hidden")
  //         stripeAccountIdInput.setAttribute("name", "stripeAccountId")
  //         stripeAccountIdInput.setAttribute("value", stripeAccountId)

  //         form.appendChild(stripeAccountIdInput)
  //         document.body.appendChild(form)
  //         form.submit()
  //       } else {
  //         // doc.data() will be undefined in this case
  //         console.log("No such document!")
  //       }
  //     } catch (error) {
  //       console.log("Error getting document:", error)
  //     }
  //   }
  //   getDocFromFirestore()
  // }, [])

  // useEffect(() => {
  //   setRerun((prev) => !prev)
  // }, [])

  useEffect(() => {
    if (!userData) return;

    const stripeAccountId = userData.bizData.stripeAccountId;
    // console.log("stripeAccountId2", stripeAccountId)

    // Submit the form with the stripeAccountId programatically
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", `${node}/connect/refresh`);

    const stripeAccountIdInput = document.createElement("input");
    stripeAccountIdInput.setAttribute("type", "hidden");
    stripeAccountIdInput.setAttribute("name", "stripeAccountId");
    stripeAccountIdInput.setAttribute("value", stripeAccountId);

    form.appendChild(stripeAccountIdInput);
    document.body.appendChild(form);
    form.submit();
  }, [userData]);

  return <div>This is the refresh page, you should be getting redirected soon</div>;
}

// features/ServiceSubscription/utils/determinePaymentTiming.js
import { convertToJsDateNano } from "utils/helpers";
import { format } from "date-fns";

export const determinePaymentTiming = (startDate) => {
  const now = new Date();
  const parsedStartDate = convertToJsDateNano(startDate);
  const startDateSeconds = Math.floor(parsedStartDate.getTime() / 1000);
  const nowSeconds = Math.floor(now.getTime() / 1000);
  const fiveYearsFromNowSeconds = nowSeconds + 5 * 365 * 24 * 60 * 60;

  // format parsed date like "March 1, 2022"
  const formattedStartDate = format(parsedStartDate, "MMMM d, yyyy");
  if (
    startDateSeconds > nowSeconds &&
    startDateSeconds <= fiveYearsFromNowSeconds
  ) {
    return {
      immediateCharge: false,
      firstPaymentDate: parsedStartDate,
      message: `The first payment will be processed on ${formattedStartDate}.`,
    };
  } else if (startDateSeconds <= nowSeconds) {
    return {
      immediateCharge: true,
      firstPaymentDate: now,
      message: "The first payment will be processed immediately.",
    };
  } else {
    throw new Error("Start date cannot be more than 5 years in the future");
  }
};

import React, { useState, useContext, useEffect } from "react";
import { ImSpinner } from "react-icons/im";
import NotAutocomplete from "../../components/AddCustomer/NotAutocomplete";
import InputWithLabel from "../AddJob/components/InputWithLabel";
import PhoneInputWithLabel from "../../components/AddCustomer/PhoneInputWithLabel";
import { UserContext } from "../../index";
import { db, auth } from "../../utils/firebase";
import { doc, getDoc, getDocs, updateDoc, collection, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { AiOutlineCloseCircle, AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import TimezoneDropdown from "components/other/TimezoneDropdown";
import CurrencyDropdown from "components/other/CurrencyDropdown";
import { FaToggleOff } from "react-icons/fa";
import { FaToggleOn } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import { useSnackBar } from "../../context/SnackBarContext";
import FreeformTags from "components/companyProfile/FreeformTags";
import CustomFileInputLogo from "components/reusable/CustomFileInputLogo";
import CropImageModal from "components/companyProfile/CropImageModal";
import { uploadFile } from "utils/uploadFile";
import DisabledButton from "components/buttons/DisabledButton";
import { PageHeader } from "../../layouts/PageHeader";
import { SolidButton } from "../../components/NewButtons/SolidButton";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";

export default function CompanyProfile() {
  const navigate = useNavigate();
  const { user, userData, bannerVisible, setUserData } = useContext(UserContext);
  const { openSnackBar } = useSnackBar();
  const businessData = userData?.bizData;

  const notAbleToViewCompanyInfo =
    userData?.userData?.customPermissions?.view_company_info === false;

  // only Admins and people with custom permission to edit_company_info can edit the company info
  const notAbleToEditCompanyInfo =
    !userData?.userData?.isAdmin && !userData?.userData?.customPermissions?.edit_company_info;

  // useEffect that tries to update the subscription doc in the stripe subcollection of the business doc to test the security rules

  // useEffect(() => {
  //   const updateSubscriptionDoc = async () => {
  //     try {
  //       const docRef = doc(
  //         db,
  //         "businesses",
  //         businessData.id,
  //         "stripe",
  //         "subscription"
  //       );
  //       await updateDoc(docRef, {
  //         test: "test",
  //       });
  //       console.log("updated the subscription doc");
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   updateSubscriptionDoc();
  // }, []);

  const [businessName, setBusinessName] = useState(businessData?.companyName || "");
  const [phoneNumber, setPhoneNumber] = useState(businessData?.companyPhone || "");
  const [website, setWebsite] = useState(businessData?.companyWebsite || "");
  const [email, setEmail] = useState(businessData?.email || "");
  const [address, setAddress] = useState(businessData?.address || "");
  const [loading, setLoading] = useState(false);

  const [googleReviewLink, setGoogleReviewLink] = useState(businessData?.googleReviewLink || "");
  const [facebookReviewLink, setFacebookReviewLink] = useState(
    businessData?.facebookReviewLink || ""
  );
  const [yelpReviewLink, setYelpReviewLink] = useState(businessData?.yelpReviewLink || "");
  const [modal, setModal] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [taxRates, setTaxRates] = useState(
    businessData?.taxRates?.map((rate) => ({
      ...rate,
      isDeleting: false,
    })) || []
  );
  const [timeZone, setTimeZone] = useState(businessData?.timeZone || "");
  const [currency, setCurrency] = useState(businessData?.currency || "usd");
  const [defaultTaxRate, setDefaultTaxRate] = useState(businessData?.defaultTaxRate || null);
  const [tags, setTags] = useState([]);
  const [logo, setLogo] = useState(businessData?.logo || null);
  const [newLogoLocalUrl, setNewLogoLocalUrl] = useState(null);
  const [newLogoFile, setNewLogoFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cropImageModal, setCropImageModal] = useState(false);

  // console.log("defaultTaxRate", defaultTaxRate);
  // console.log("currency", currency);

  // fetch biz doc with the useEffect and update all the states and userData to keep everything up to data, everytime we land on this page
  useEffect(() => {
    const fetchBizDoc = async () => {
      const docRef = doc(db, "businesses", businessData.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setUserData({
          ...userData,
          bizData: {
            ...data,
          },
        });
        setBusinessName(data?.companyName || "");
        setPhoneNumber(data?.companyPhone || "");
        setWebsite(data?.companyWebsite || "");
        setEmail(data?.email || "");
        setAddress(data?.address || "");
        setGoogleReviewLink(data?.googleReviewLink || "");
        setFacebookReviewLink(data?.facebookReviewLink || "");
        setYelpReviewLink(data?.yelpReviewLink || "");
        setTaxRates(
          data.taxRates?.map((rate) => ({
            ...rate,
            isDeleting: false,
          })) || []
        );
        setTimeZone(data?.timeZone || "");
        setCurrency(data?.currency || "usd");
        setDefaultTaxRate(data?.defaultTaxRate || null);
        setLogo(data?.logo || null);
      } else {
        console.log("No such document!");
      }
    };
    const fetchTags = async () => {
      const tagsDoc = doc(db, "businesses", businessData.id, "tags", "tags");
      const tagsSnapshot = await getDoc(tagsDoc);
      // check if the tags document exists
      if (!tagsSnapshot.exists()) {
        return;
      }
      const tagsList = tagsSnapshot.data()?.tags;
      // console.log("tagsList", tagsList);
      setTags(tagsList);
    };
    fetchBizDoc();
    fetchTags();
  }, []);

  async function handleSave() {
    console.log("save clicked");
    if (!dirty) return;
    if (notAbleToEditCompanyInfo) {
      openSnackBar(
        "You do not have permission to edit company info. Please contact your admin to get access."
      );
      return;
    }
    if (!businessName || !phoneNumber || !email || !address) {
      alert("Please do not leave any fields blank");
      return;
    }
    if (phoneNumber.length !== 12) {
      alert("Please enter a valid phone number");
      return;
    }
    setLoading(true);

    let logoUrlForFirestore = logo || null;
    if (newLogoFile) {
      try {
        const logoUrl = await uploadFile(newLogoFile, "company-logos");
        logoUrlForFirestore = logoUrl;
      } catch (error) {
        console.error("Error uploading logo", error);
        openSnackBar("Error uploading logo");
        setLoading(false);
        return;
      }
    }

    const docRef = doc(db, "businesses", businessData.id);
    const tagsRef = doc(db, "businesses", businessData.id, "tags", "tags");

    let taxRatesForDB = [];
    if (taxRates?.length > 0) {
      taxRatesForDB = taxRates.map((rate) => ({
        name: rate.name,
        rate: rate.rate,
      }));
    }

    let defaultTaxRateForDB = null;
    if (defaultTaxRate) {
      defaultTaxRateForDB = {
        name: defaultTaxRate.name,
        rate: defaultTaxRate.rate,
      };
    }

    try {
      await updateDoc(docRef, {
        companyName: businessName,
        companyPhone: phoneNumber,
        companyWebsite: website,
        email: email,
        address: address,
        googleReviewLink: googleReviewLink,
        facebookReviewLink: facebookReviewLink,
        yelpReviewLink: yelpReviewLink,
        taxRates: taxRatesForDB,
        timeZone: timeZone,
        currency: currency,
        defaultTaxRate: defaultTaxRateForDB,
        logo: logoUrlForFirestore,
      });

      // await updateDoc(tagsRef, {
      //   tags: tags,
      // });

      await setDoc(
        tagsRef,
        {
          tags: tags,
        },
        { merge: true }
      );

      console.log("Document successfully updated!");
      setUserData({
        ...userData,
        bizData: {
          ...businessData,
          companyName: businessName,
          companyPhone: phoneNumber,
          companyWebsite: website,
          email: email,
          address: address,
          googleReviewLink: googleReviewLink,
          facebookReviewLink: facebookReviewLink,
          yelpReviewLink: yelpReviewLink,
          taxRates: taxRatesForDB,
          timeZone: timeZone,
          currency: currency,
          defaultTaxRate: defaultTaxRateForDB,
          logo: logoUrlForFirestore,
        },
      });
      setDirty(false);
      openSnackBar("Company profile updated", true);
    } catch (error) {
      openSnackBar("Error updating company profile");
      console.error("Error updating document: ", error);
    } finally {
      setLoading(false);
    }
  }

  const handleBusinessNameChange = (e) => {
    setBusinessName(e.target.value);
    setDirty(true);
  };
  const handlePhoneNumberChange = () => {
    setDirty(true);
  };
  const handleWebsiteChange = (e) => {
    setWebsite(e.target.value);
    setDirty(true);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setDirty(true);
  };
  const handleAddressChange = (e) => {
    console.log("handle Address chantge");
    setDirty(true);
  };
  const handleGoogleReviewLinkChange = (e) => {
    setGoogleReviewLink(e.target.value);
    setDirty(true);
  };
  const handleFacebookReviewLinkChange = (e) => {
    setFacebookReviewLink(e.target.value);
    setDirty(true);
  };
  const handleYelpReviewLinkChange = (e) => {
    setYelpReviewLink(e.target.value);
    setDirty(true);
  };

  const handleDeleteTaxRateClick = (index) => {
    setDirty(true);
    if (taxRates[index].isDeleting) {
      setTaxRates((prevTaxRates) => {
        const newTaxRates = [...prevTaxRates];
        newTaxRates.splice(index, 1);
        return newTaxRates;
      });
      if (areTaxRatesEqual(taxRates[index], defaultTaxRate)) {
        setDefaultTaxRate(null);
      }
    } else {
      setTaxRates((prevTaxRates) => {
        const newTaxRates = [...prevTaxRates];
        newTaxRates[index].isDeleting = true;
        return newTaxRates;
      });
    }
  };

  const handleTaxRateChange = (index, e) => {
    setDirty(true);
    setDefaultTaxRate(null);
    setTaxRates((prevTaxRates) => {
      const newTaxRates = [...prevTaxRates];
      newTaxRates[index].rate = e.target.value;

      return newTaxRates;
    });
  };

  const handleTaxRateNameChange = (index, e) => {
    setDirty(true);
    setDefaultTaxRate(null);
    setTaxRates((prevTaxRates) => {
      const newTaxRates = [...prevTaxRates];
      newTaxRates[index].name = e.target.value;
      return newTaxRates;
    });
  };

  const addTaxRate = () => {
    setTaxRates((prevTaxRates) => [...prevTaxRates, { name: "", rate: "", isDeleting: false }]);
  };

  const handleSetDefaultTaxRate = (taxRate, index) => {
    if (notAbleToEditCompanyInfo) return;
    setDirty(true);
    // console.log("taxRate", taxRate);
    // console.log("index", index);
    setDefaultTaxRate(taxRate);
  };

  const handleRemoveDefaultTaxRate = (taxRate, index) => {
    setDirty(true);
    // console.log("taxRate", taxRate);
    // console.log("index", index);
    setDefaultTaxRate(null);
  };
  function areTaxRatesEqual(taxRate1, taxRate2) {
    if (!taxRate1 || !taxRate2) return false;
    return taxRate1.rate === taxRate2.rate && taxRate1.name === taxRate2.name;
  }

  const addEditLogo = (e) => {
    console.log("e", e.target.files);
    const file = e.target?.files?.[0];
    console.log("file", file);
    if (!file) return;
    setSelectedImage(URL.createObjectURL(file)); // is this right?
    setCropImageModal(true);
  };

  const handleCrop = (croppedImageBlob) => {
    console.log("croppedImageBlob from handleCrop companyProfile.js", croppedImageBlob);
    // Convert blob to file (optional, depends on your Firebase upload function)
    const croppedImageFile = new File([croppedImageBlob], `${businessData.id}croppedLogo.png"`, {
      type: "image/png",
    });

    // create local url from this file so that we can display it
    const localUrl = URL.createObjectURL(croppedImageFile);
    // console.log("localUrl", localUrl);
    setNewLogoLocalUrl(localUrl); // for displaying purposes

    // so then we will pass this file to uploadFile function to upload it to firestore
    setNewLogoFile(croppedImageFile);
    setDirty(true);
    setSelectedImage(null);
  };

  return (
    <>
      <CropImageModal
        open={cropImageModal}
        setOpen={setCropImageModal}
        image={selectedImage}
        onCrop={handleCrop}
      />
      <div
        className={`bg-gray-100 ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        }  overflow-y-auto pb-36`}
      >
        <CenteredMaxWidthLayout>
          <PageHeader title="Company Profile">
            <SolidButton
              text="Save"
              disabled={!dirty || notAbleToEditCompanyInfo}
              loading={loading}
              onClick={handleSave}
            />
          </PageHeader>
          <div
            className={`flex flex-col md:flex-row justify-start mx-1 ${
              notAbleToViewCompanyInfo && "blur"
            } `}
          >
            <div className="flex flex-col w-full md:w-3/5 px-4 pb-4 rounded-sm gap-4">
              <div className="flex flex-col  bg-gray-50 shadow-md rounded-sm ">
                <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
                  Details
                </h1>
                <div className="flex flex-col w-full shrink-0  px-4 py-4 rounded-sm gap-4">
                  <InputWithLabel
                    disabled={notAbleToEditCompanyInfo}
                    label="Business Name"
                    value={businessName}
                    setValue={setBusinessName}
                    onChangeFunction={handleBusinessNameChange}
                  />

                  <PhoneInputWithLabel
                    disabled={notAbleToEditCompanyInfo}
                    label="Phone Number"
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    onChangeFunction={handlePhoneNumberChange}
                  />

                  <InputWithLabel
                    disabled={notAbleToEditCompanyInfo}
                    label="Website"
                    value={website}
                    setValue={setWebsite}
                    onChangeFunction={handleWebsiteChange}
                  />
                  <InputWithLabel
                    disabled={notAbleToEditCompanyInfo}
                    label="Email"
                    value={email}
                    setValue={setEmail}
                    onChangeFunction={handleEmailChange}
                  />

                  <NotAutocomplete
                    disabled={notAbleToEditCompanyInfo}
                    setAddress={setAddress}
                    address={address}
                    onChangeFunction={handleAddressChange}
                  />

                  <h1 className="font-semibold text-sm pl-3 text-gray-500">Timezone</h1>
                  <TimezoneDropdown
                    disabled={notAbleToEditCompanyInfo}
                    selectedTimezone={timeZone}
                    setSelectedTimezone={setTimeZone}
                    setTimezoneHasBeenChanged={setDirty}
                  />
                  <h1 className="font-semibold text-sm pl-3 text-gray-500">Currency</h1>
                  <CurrencyDropdown
                    disabled={notAbleToEditCompanyInfo}
                    selectedCurrency={currency}
                    setSelectedCurrency={setCurrency}
                    setCurrencyHasBeenChanged={setDirty}
                  />
                </div>
              </div>
            </div>

            <div
              className={`flex flex-col w-full md:w-2/5 px-4 pb-4 rounded-sm gap-4 ${
                notAbleToViewCompanyInfo && "blur"
              }`}
            >
              <div className="flex flex-col  bg-gray-50 shadow-md rounded-sm ">
                <div className="text-2xl flex flex-row items-end justify-between font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
                  <h1>Review links</h1>
                  <a
                    href="https://homebase360.io/find-review-links"
                    className="text-sm font-medium text-indigo-600 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Where do I find these?
                  </a>
                </div>

                <div className="flex flex-col w-full shrink-0  px-4 py-4 rounded-sm gap-4">
                  <InputWithLabel
                    disabled={notAbleToEditCompanyInfo}
                    label="Google"
                    value={googleReviewLink}
                    setValue={setGoogleReviewLink}
                    onChangeFunction={handleGoogleReviewLinkChange}
                  />

                  <InputWithLabel
                    disabled={notAbleToEditCompanyInfo}
                    label="Facebook"
                    value={facebookReviewLink}
                    setValue={setFacebookReviewLink}
                    onChangeFunction={handleFacebookReviewLinkChange}
                  />

                  <InputWithLabel
                    disabled={notAbleToEditCompanyInfo}
                    label="Yelp"
                    value={yelpReviewLink}
                    setValue={setYelpReviewLink}
                    onChangeFunction={handleYelpReviewLinkChange}
                  />
                </div>
              </div>

              <div className="flex flex-col  bg-gray-50 shadow-md rounded-sm mt-4">
                <div className="text-2xl flex flex-row items-end justify-between font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
                  <h1>Logo</h1>

                  <CustomFileInputLogo
                    disabled={notAbleToEditCompanyInfo}
                    handleFileChange={addEditLogo}
                    editMode={logo ? true : false}
                  />
                </div>

                <div className="flex flex-col w-full shrink-0  px-4 py-4 rounded-sm gap-4 bg-gray-100 ">
                  {logo || newLogoLocalUrl ? (
                    <img
                      src={logo || newLogoLocalUrl}
                      alt="Company Logo"
                      className="w-full h-48 object-contain bg-white rounded-md"
                    />
                  ) : (
                    <div className="w-full h-48 bg-white rounded-md flex items-center justify-center ">
                      <p className="text-gray-500">No logo</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="flex flex-col w-2/5 px-4 py-4 rounded-sm gap-4">
            <div className="flex flex-col  bg-gray-50 shadow-md rounded-sm ">
              <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
                Business Hours
              </h1>
              <div className="flex flex-col w-full shrink-0  px-4 py-4 rounded-sm gap-4">
                <InputWithLabel label="Monday" />
                <InputWithLabel label="Tuesday" />
                <InputWithLabel label="Wednesday" />
                <InputWithLabel label="Thursday" />
                <InputWithLabel label="Friday" />
                <InputWithLabel label="Saturday" />
                <InputWithLabel label="Sunday" />
              </div>
            </div>
          </div> */}
          </div>

          <div
            className={`flex flex-col md:flex-row justify-start mx-1 ${
              notAbleToViewCompanyInfo && "blur"
            }`}
          >
            <div className="flex flex-col w-full md:w-3/5 px-4 py-4 rounded-sm">
              <div className="flex flex-col  bg-gray-50 shadow-md rounded-sm ">
                <div className="text-2xl flex flex-row items-center justify-between font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
                  <div className="flex flex-row items-end">
                    <h1 className="mr-2">Tax rates</h1>
                    <div className="mr-2 text-xs font-medium pb-1">
                      (Toggle to set default tax rate always on)
                    </div>
                  </div>
                  <button
                    disabled={notAbleToEditCompanyInfo}
                    onClick={addTaxRate}
                    className="text-sm font-medium text-indigo-600 hover:underline"
                  >
                    <AiOutlinePlusCircle className="text-indigo-600 text-2xl" />
                  </button>
                </div>

                <div className="flex flex-col w-full shrink-0  px-4 py-4 rounded-sm gap-4">
                  {taxRates.length > 0 ? (
                    taxRates.map((taxRate, index) => (
                      <div key={index} className="flex flex-row justify-between items-center gap-4">
                        {taxRate.isDeleting ? (
                          <button
                            disabled={notAbleToEditCompanyInfo}
                            onClick={() => handleDeleteTaxRateClick(index)}
                          >
                            <AiOutlineCloseCircle className="text-red-400 text-2xl" />
                          </button>
                        ) : (
                          <button
                            disabled={notAbleToEditCompanyInfo}
                            onClick={() => handleDeleteTaxRateClick(index)}
                          >
                            <AiOutlineMinusCircle className="text-gray-700 text-2xl" />
                          </button>
                        )}
                        <div className="w-1/3">
                          <InputWithLabel
                            disabled={notAbleToEditCompanyInfo}
                            label="Name"
                            value={taxRate.name}
                            onChangeFunction={(e) => handleTaxRateNameChange(index, e)}
                          />
                        </div>

                        <div className="w-1/3">
                          <InputWithLabel
                            disabled={notAbleToEditCompanyInfo}
                            label="Rate (%)"
                            value={taxRate.rate}
                            onChangeFunction={(e) => handleTaxRateChange(index, e)}
                            onKeyPress={(e) => {
                              const keyCode = e.which || e.keyCode;
                              const character = String.fromCharCode(keyCode);
                              const pattern = /^[0-9.]*$/; // Allow numbers and a decimal point
                              const valid = pattern.test(character);

                              // Allow numbers and only one decimal point
                              if (!valid || (character === "." && e.target.value.includes("."))) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                        {areTaxRatesEqual(defaultTaxRate, taxRate) ? (
                          <Tooltip title="Remove default tax rate.">
                            <div>
                              <FaToggleOn
                                disabled={notAbleToEditCompanyInfo}
                                className="text-green-400 cursor-pointer text-2xl"
                                onClick={() => handleRemoveDefaultTaxRate(taxRate, index)}
                              />
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Set default tax rate. When creating jobs this tax rate will automatically be turned on.">
                            <div>
                              <FaToggleOff
                                disabled={notAbleToEditCompanyInfo}
                                className="text-gray-900 cursor-pointer text-2xl"
                                onClick={() => handleSetDefaultTaxRate(taxRate, index)}
                              />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-row justify-between items-center gap-4">
                      <h1>No tax rates</h1>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full md:w-2/5 px-4 py-4 rounded-sm gap-4">
              <div className="flex flex-col  ">
                <div className="text-2xl flex flex-row items-end justify-between px-4 py-2 rounded-sm"></div>

                <div className="flex flex-col w-full shrink-0  px-4 py-4 rounded-sm gap-4"></div>
              </div>
            </div>
          </div>

          <div
            className={`flex flex-col md:flex-row justify-start mx-1 ${
              notAbleToViewCompanyInfo && "blur"
            }`}
          >
            <div className="flex flex-col w-full md:w-3/5 px-4 py-4 rounded-sm">
              <div className="flex flex-col  bg-gray-50 shadow-md rounded-sm ">
                <div className="text-2xl flex flex-row items-center justify-between font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
                  <h1 className="mr-2">Custom Tags</h1>
                </div>

                <div className="flex flex-col w-full shrink-0  px-4 py-4 rounded-sm gap-4">
                  <FreeformTags tags={tags} setTags={setTags} setDirty={setDirty} />
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full md:w-2/5 px-4 py-4 rounded-sm gap-4">
              <div className="flex flex-col  ">
                <div className="text-2xl flex flex-row items-end justify-between px-4 py-2 rounded-sm"></div>

                <div className="flex flex-col w-full shrink-0  px-4 py-4 rounded-sm gap-4"></div>
              </div>
            </div>
          </div>
        </CenteredMaxWidthLayout>
      </div>
    </>
  );
}

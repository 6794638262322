import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const PayButton = ({
  onClick = () => {},
  disabled = false,
  loading = false,
  text = "Pay",
  fontSize = "text-md",
  fontWeight = "font-bold",
  spinnerColor = "#ffffff",
  spinnerSize = 20,
  additionalClasses = "",
  width = "w-2/5",
  height = "h-10",
  icon = null,
  iconPosition = "left",
  cardComplete = true,
  type = "submit",
}) => {
  return (
    <button
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={` w-2/5 bg-sky-600/90 gap-2 h-10 items-center flex justify-center rounded-md ${
        cardComplete ? "text-white hover:shadow-lg" : "text-sky-200"
      }  shadow-sm ${
        loading ? "opacity-40" : ""
      }  ${width} rounded-md ${height} ${fontSize} ${fontWeight} ${additionalClasses}`}
    >
      {loading ? (
        <CircularProgress size={spinnerSize} style={{ color: spinnerColor }} />
      ) : (
        <>
          {icon && iconPosition === "left" && (
            <span className="mr-2">{icon}</span>
          )}
          {text}
          {icon && iconPosition === "right" && (
            <span className="ml-2">{icon}</span>
          )}
        </>
      )}
    </button>
  );
};

export default PayButton;

import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { BiSearchAlt2 } from "react-icons/bi";

export default function CustomersSearch({
  data,
  label,
  inputValue,
  setInputValue,
  selected,
  setSelected,
  customers,
  ...props
}) {
  const handleChange = (event) => {
    // setSelected(false)
    setInputValue(event.target.value);
  };

  //   const handleSelect = (customer) => {
  //     setInputValue(customer.name)
  //     setSelected(customer)
  //   }

  // Filter the salespeople based on the user's input
  const filteredCustomer =
    customers &&
    customers.length > 0 &&
    customers?.filter((customer) =>
      customer.firstName
        ?.toLowerCase()
        .includes(
          inputValue.toLowerCase() ||
            customer.lastName?.toLowerCase().includes(inputValue.toLowerCase())
        )
    );

  return (
    <div className="relative flex-1 flex flex-col " {...props}>
      <input
        className="bg-white border border-gray-500 rounded-md text-lg outline-none focus:border-yellow-300  py-2 pl-9"
        value={inputValue}
        placeholder={label}
        onChange={handleChange}
      />
      <BiSearchAlt2
        className={`absolute top-3 left-2 text-2xl text-gray-400  ${
          inputValue && "text-gray-600"
        }`}
      />
      {/* {inputValue && !selected && (
        <div
          className="absolute z-50 mt-1 bg-white rounded-md shadow-lg"
          style={{
            top: "45px",
            left: "0px",
          }}
        >
          <ul>
            {filteredCustomer.map((customer) => (
              <li
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                key={customer.id}
                onClick={() => handleSelect(customer)}
              >
                {customer.name}, {customer.address}
              </li>
            ))}
          </ul>
        </div>
      )} */}
    </div>
  );
}

import React from "react";

import { ScrollArea, ScrollBar } from "components/ui/scroll-area";

export default function HorizontalScrollAndDelete({ images, deleteImage }) {
  return (
    <ScrollArea className="w-full h-auto py-4 whitespace-nowrap rounded-md">
      <div className="flex w-max space-x-4">
        {images.map((image, index) => (
          <figure key={index} className="shrink-0 w-32 h-32">
            <div className="overflow-hidden h-full w-full rounded-md relative">
              <img
                src={image.preview}
                alt={`Image ${image.preview}`}
                className="h-full w-full object-cover"
              />
              <button
                className="absolute top-1 right-1 bg-gray-50 hover:bg-gray-100 rounded-full p-0.5 shadow-md"
                onClick={() => deleteImage(image)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </figure>
        ))}
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}

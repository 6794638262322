import React from "react";
import {
  SelectField,
  TextField,
  PhoneField,
  PasswordRegister,
} from "../reusable/fields";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export default function Details({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phoneBeta,
  setPhoneBeta,
  country,
  setCountry,
  email,
  setEmail,
  setStep,
  setError,
  error,
  companyName,
  setCompanyName,
  handleSubmit,
  isLoading,
}) {
  const navigate = useNavigate();
  const handlePhoneInput = (e) => {
    // this is where we'll call the phoneNumberFormatter function
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setPhoneBeta(formattedPhoneNumber);
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return regex.test(phoneNumber);
  };

  const handleNext = (e) => {
    e.preventDefault();

    if (!firstName || !lastName || !phoneBeta || !email) {
      setError("Please fill out all fields");
      return;
    }
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }

    if (!isValidPhoneNumber(phoneBeta)) {
      setError("Please enter a valid phone number");
      return;
    }

    setStep(2);
    setError("");
  };

  return (
    <div className="mb-24 flex w-11/12 max-w-md flex-col items-center sm:w-1/2">
      <Link href="https://homebase360.io" aria-label="Home">
        <img className="h-14 w-auto" src="/images/Homebase-logo-black.png" />
      </Link>
      <div className="mt-5 flex flex-col items-center">
        <h2 className="text-lg font-semibold text-gray-900">
          Get started today
        </h2>
        <p className="mt-2 text-sm text-gray-700">
          Already registered?{" "}
          <button
            onClick={() => navigate("/login")}
            className="font-medium text-yellow-400 hover:text-yellow-500 hover:underline"
          >
            Sign in{" "}
          </button>{" "}
          to your account.
        </p>
      </div>

      <div className="mt-10 grid w-full grid-cols-1 gap-y-8 gap-x-6  sm:grid-cols-4">
        <TextField
          label="Company Name"
          id="company_name"
          name="company_name"
          type="text"
          autoComplete="none"
          className="col-span-full"
          onChange={(e) => setCompanyName(e.target.value)}
          value={companyName}
          required
        />
        <TextField
          label="First name"
          id="first_name"
          name="first_name"
          type="text"
          autoComplete="given-name"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
          required
          className="col-span-2"
        />
        <TextField
          label="Last name"
          id="last_name"
          name="last_name"
          type="text"
          autoComplete="family-name"
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
          required
          className="col-span-2"
        />
        <TextField
          className="col-span-full"
          label="Email address"
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />

        <div className="col-span-full">
          <label
            htmlFor="phone-number"
            className="mb-3 block text-sm font-medium text-gray-700"
          >
            Phone Number
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center">
              <label htmlFor="country" className="sr-only">
                Country
              </label>
              <select
                id="country"
                name="country"
                className="h-full rounded-md border-transparent bg-transparent py-0 pl-4 pr-2 text-gray-500 focus:border-yellow-500 focus:ring-yellow-500 focus:outline-none"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
              >
                <option>US</option>
                <option>CA</option>
              </select>
            </div>
            <input
              type="tel"
              name="phone-number"
              id="phone-number"
              autoComplete="none"
              className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-yellow-500 focus:bg-white focus:outline-none focus:ring-yellow-500 sm:text-sm pl-20"
              placeholder="(555) 987-6543"
              onChange={(e) => handlePhoneInput(e)}
              value={phoneBeta}
              required
            />
          </div>
        </div>

        <div className="col-span-full">
          <button
            type="submit"
            variant="solid"
            color="slate"
            className="w-full gap-2  mt-10 bg-neutral-900 text-white hover:bg-neutral-700 hover:text-neutral-100 active:bg-neutral-800
          active:text-neutral-300 focus-visible:outline-neutral-900 group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2"
            onClick={handleSubmit}
          >
            {isLoading ? (
              // <ImSpinner className="animate-spin-slow text-lg" />
              <CircularProgress size={20} style={{ color: "#fefce8" }} />
            ) : (
              <span>
                Sign up <span aria-hidden="true">&rarr;</span>
              </span>
            )}
          </button>

          {error && (
            <div className="text-red-600 tx-sm text-center justify-self-center mt-3">
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

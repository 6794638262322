import React, { useRef } from "react";
import { CgAttachment } from "react-icons/cg";
import CancelIcon from "@mui/icons-material/Cancel";

export default function CustomFileInput({
  handleFileChange,
  attachments,
  removeAttachment,
  renderIcon = false,
  backgroundColor = "bg-gray-50",
  textColor = "text-gray-900",
}) {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <input
        ref={fileInputRef}
        className="hidden"
        // accept="image/*"
        multiple
        type="file"
        onChange={handleFileChange}
      />
      <button
        onClick={handleButtonClick}
        className={` ${textColor} ${backgroundColor} rounded-md py-2 px-4 flex flex-row`}
      >
        {renderIcon ? (
          <CgAttachment className="text-gray-600 text-2xl" />
        ) : (
          "Choose Files"
        )}
      </button>
      <div className="flex flex-col mt-2">
        {attachments &&
          attachments?.length > 0 &&
          attachments.map((attachment, index) => (
            <div
              key={index}
              className="relative flex flex-row items-center m-1"
            >
              {/* Display file name */}
              <button className="mr-1" onClick={() => removeAttachment(index)}>
                <CancelIcon
                  className="text-gray-400 hover:text-gray-500"
                  fontSize="small"
                />
              </button>
              <div className="mr-4">{attachment.name}</div>

              {/* Remove file button */}
            </div>
          ))}
      </div>
    </>
  );
}

import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function AutocompleteDropdownMUI({
  options,
  value,
  setValue,
  placeholder = "Select Template",
  label = "Select Template",
  onValueChange = null,
}) {
  // console.log("value from autocomplete dropdown", value);
  const handleChange = (event, values) => {
    // console.log("values from hanlde change", values);
    setValue(values);
  };
  const borderColor = "#6b7280"; // gray-500
  const yellow = "#fef08a";

  return (
    <Autocomplete
      options={options}
      value={value}
      onChange={onValueChange || handleChange}
      getOptionLabel={(option) => option.title}
      isOptionEqualToValue={(option, value) =>
        option.title === value.title &&
        (option.description === value.description || option.text === value.text) &&
        option.templateId === value.templateId
      } // since we are using this for templates and terms... there is a better way to do this.. but for now this is fine
      getOptionKey={(option) => option.id || option.templateId || option.title}
      sx={{
        fontFamily: '"Inter var", system-ui, sans-serif',
        "& .MuiOutlinedInput-root": {
          backgroundColor: "#f9fafb", // switched to gray-50
          color: "#111827",
          "&:hover": {
            backgroundColor: "#f9fafb",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: borderColor,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: borderColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: yellow,
          }, // I also want it to when we hover over the input field when it isn't focused but there is input in there it doesn't switch to black.. I want it to still be yellow
          "& .MuiAutocomplete-option": {
            fontFamily: '"Inter var", system-ui, sans-serif',
            "&.Mui-selected:": {
              backgroundColor: yellow, // Add the color you want here
            },
          },
        },
      }}
      renderOption={(props, option, { selected, active }) => (
        <li
          {...props}
          style={{
            ...props.style,
            fontFamily: '"Inter var", system-ui, sans-serif',
            backgroundColor: selected && !active ? "#fefce8" : undefined,
          }}
        >
          <div>
            <div className="text-base">{option.title}</div>
            <div
              className="text-sm  text-gray-600 "
              style={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3, // Limit to three lines
                textOverflow: "ellipsis",
              }}
            >
              {option.description || option.text}
            </div>
          </div>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          autoComplete="new-password"
          sx={{
            fontFamily: '"Inter var", system-ui, sans-serif',
            "& .MuiInputBase-input": {
              fontFamily: '"Inter var", system-ui, sans-serif',
            },
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          InputLabelProps={{
            sx: {
              color: "#6b7280", // Label color
              "&.Mui-focused": {
                color: "#1f2937", // Label color when focused
              },
              fontFamily: '"Inter var", system-ui, sans-serif',
            },
          }}
        />
      )}
    />
  );
}

import React from "react";
import PaymentIcon from "components/payments/PaymentIcon";
import { cn } from "utils/utils";

export default function DisplayCard({ savedPaymentMethod, className = "" }) {
  return (
    <div className={cn("flex flex-row items-center gap-2", className)}>
      <PaymentIcon brand={savedPaymentMethod.card?.brand} />
      <div>••••{savedPaymentMethod.card.last4}</div>
      <div>
        Expires {savedPaymentMethod.card.exp_month}/{savedPaymentMethod.card.exp_year}
      </div>
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../index";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import InputWithLabel from "../AddJob/components/InputWithLabel";
import PhoneInputWithLabel from "../../components/AddCustomer/PhoneInputWithLabel";
import { FaSave } from "react-icons/fa";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "../../utils/firebase";
import { node } from "../../constants/constants";
import ColorDropdown from "components/other/ColorDropdown";
import LoadingSpinner from "../../components/reusable/LoadingSpinner";
import { useSnackBar } from "../../context/SnackBarContext";
import { BackLink } from "../../components/NewButtons/BackLink";
import { PageHeader } from "../../layouts/PageHeader";
import { SolidButton } from "../../components/NewButtons/SolidButton";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";

export default function EmployeeDetails() {
  const { user, userData, bannerVisible } = useContext(UserContext);
  const { openSnackBar } = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const employees = location.state?.employees || null;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("Manager");
  const [selectedColor, setSelectedColor] = useState({
    label: "Blue",
    value: "#3b82f6",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    // console.log("userData", userData)
    // console.log("user", user)
  }, [userData]);

  async function handleCreateEmployee() {
    console.log("create employee");
    if (isLoading) return;

    if (firstName === "") {
      alert("Please enter a first name");
      return;
    }
    if (lastName === "") {
      alert("Please enter a last name");
      return;
    }
    if (phone === "") {
      alert("Please enter a phone number");
      return;
    }
    if (email === "") {
      alert("Please enter an email");
      return;
    }
    if (password === "") {
      alert("Please enter a password");
      return;
    }
    if (password && password.length < 6) {
      alert("Password must be at least 6 characters");
      return;
    }
    setIsLoading(true);

    // create custom permission object and populate it with the selected permissions if userType === "Custom"
    let customPermissions = {};

    if (userType === "Custom") {
      permissionGroups.forEach((group) => {
        group.permissions.forEach((permission) => {
          // Set the permission value to true if it's included in selectedPermissions, false otherwise
          customPermissions[permission.id] = selectedPermissions.includes(permission.id);
        });
      });
    }

    try {
      const createEmployee = httpsCallable(functions, "createEmployee");
      const result = await createEmployee({
        email,
        password,
        firstName,
        lastName,
        phone,
        userType,
        businessId: userData.bizData.id,
        color: selectedColor,
        assignedEmployees: selectedEmployees,
        customPermissions: userType === "Custom" ? customPermissions : null,
      });

      console.log("result from createEmployee: ", result);

      if (!result.data.success) {
        openSnackBar(`Error creating employee: ${result.data.error}`, false, true);
        return;
      }

      const sendEmployeeInvite = httpsCallable(functions, "sendEmployeeInvite");
      const res = await sendEmployeeInvite({
        email: email,
        password: password,
        firstName: firstName,
        businessName: userData.bizData.companyName,
        phone: phone,
      });

      const { emailSent, textSent } = res.data;

      let successMessage = "Employee created successfully.";
      if (emailSent && textSent) {
        successMessage += " Invite email and text sent.";
      } else if (emailSent) {
        successMessage += " Invite email sent. Text message failed.";
      } else if (textSent) {
        successMessage += " Invite text sent. Email failed.";
      } else {
        successMessage += " Failed to send invite email and text.";
      }

      openSnackBar(successMessage, true);
      navigate("/settings/employees");
    } catch (error) {
      console.error("Error in handleCreateEmployee:", error);
      openSnackBar(`Error: ${error.message}`, false, true);
    } finally {
      setIsLoading(false);
    }
  }

  // console.log("userType", userType);

  // console.log("employees", employees);
  const handleEmployeeSelection = (employeeId) => {
    setSelectedEmployees((prevSelectedEmployees) => {
      if (prevSelectedEmployees.includes(employeeId)) {
        return prevSelectedEmployees.filter((id) => id !== employeeId);
      } else {
        return [...prevSelectedEmployees, employeeId];
      }
    });
  };

  const handleCustomPermissionSelection = (permissionId) => {
    const exclusivePermissions = ["manage_all_jobs", "manage_assigned_jobs", "manage_team_jobs"];

    setSelectedPermissions((prevSelectedPermissions) => {
      // Check if the selected permission is one of the exclusive permissions
      if (exclusivePermissions.includes(permissionId)) {
        // Filter out the other exclusive permissions
        const filteredPermissions = prevSelectedPermissions.filter(
          (id) => !exclusivePermissions.includes(id) || id === permissionId
        );

        // If the current permission is already selected, remove it, otherwise add it
        if (prevSelectedPermissions.includes(permissionId)) {
          return filteredPermissions.filter((id) => id !== permissionId);
        } else {
          return [...filteredPermissions, permissionId];
        }
      } else {
        // For non-exclusive permissions, use the existing toggle logic
        if (prevSelectedPermissions.includes(permissionId)) {
          return prevSelectedPermissions.filter((id) => id !== permissionId);
        } else {
          return [...prevSelectedPermissions, permissionId];
        }
      }
    });
  };

  // console.log("selectedEmployees", selectedEmployees);
  // console.log("userType", userType);

  // logic to determine if the user can see the assigned employees section
  let canSeeAssignedEmployeesSection = false;
  if (userType === "Custom" && selectedPermissions.includes("manage_team_jobs")) {
    canSeeAssignedEmployeesSection = true;
  }
  if (userType === "Team Lead") {
    canSeeAssignedEmployeesSection = true;
  }

  return (
    <>
      <div
        className={`bg-gray-100 pb-36 ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        }  overflow-y-auto`}
      >
        <CenteredMaxWidthLayout>
          <PageHeader
            title="Add Employee"
            backLink={<BackLink toName="Employees" toLink="/settings/employees" />}
          >
            <SolidButton text="Save" loading={isLoading} onClick={handleCreateEmployee} />
          </PageHeader>
          <div className="flex flex-col mx-5 bg-gray-50 shadow-md rounded-sm ">
            <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
              Personal Info
            </h1>
            <div className="flex flex-row justify-start px-4 py-4 rounded-sm gap-4 max-w-2xl">
              <div className="w-1/2">
                <InputWithLabel
                  label="Firstname"
                  style={{
                    maxWidth: "300px",
                  }}
                  value={firstName}
                  setValue={setFirstName}
                />
              </div>
              <div className="w-1/2">
                <InputWithLabel
                  label="Lastname"
                  style={{
                    maxWidth: "300px",
                  }}
                  value={lastName}
                  setValue={setLastName}
                />
              </div>
            </div>
            <div className="flex flex-row justify-start px-4 py-4 rounded-sm gap-4 max-w-2xl">
              <div className="w-1/2">
                <PhoneInputWithLabel
                  label="Phone"
                  style={{
                    maxWidth: "300px",
                  }}
                  value={phone}
                  setValue={setPhone}
                />
              </div>
              <div className="w-1/2">
                <InputWithLabel
                  label="Email"
                  style={{
                    maxWidth: "300px",
                  }}
                  value={email}
                  setValue={setEmail}
                />
              </div>
            </div>
            <div className="flex flex-row justify-start px-4 py-4 rounded-sm gap-4 max-w-2xl items-end">
              <div className="w-1/2">
                <InputWithLabel
                  label="Password"
                  style={{
                    maxWidth: "300px",
                  }}
                  value={password}
                  setValue={setPassword}
                />
              </div>
              <div className="">
                <ColorDropdown selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
              </div>
            </div>
          </div>

          <div className="flex flex-col mx-5 mt-10 bg-gray-50 shadow-md rounded-sm ">
            <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
              Permissions
            </h1>
            <div className="flex flex-col justify-start px-4 py-4 rounded-sm gap-4">
              <fieldset>
                <legend className="sr-only">Plan</legend>
                <div className="space-y-5">
                  {plans.map((plan) => (
                    <div key={plan.id} className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id={plan.id}
                          aria-describedby={`${plan.id}-description`}
                          name="plan"
                          type="radio"
                          defaultChecked={plan.id === "2"}
                          className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600"
                          onChange={() => setUserType(plan.value)}
                        />
                      </div>
                      <div className="ml-3">
                        <label
                          htmlFor={plan.id}
                          className="text-sm font-medium leading-6 text-gray-900"
                        >
                          {plan.name}
                        </label>
                        <p id={`${plan.id}-description`} className="text-sm text-gray-500">
                          {plan.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>

          {userType === "Custom" && (
            <div className="flex flex-col mx-5 mt-10 bg-gray-50 shadow-md rounded-sm ">
              <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
                Custom Permissions
              </h1>
              {permissionGroups.map((group) => (
                <div key={group.title} className="px-4 py-4">
                  <h2 className="text-lg font-bold text-gray-700">{group.title}</h2>
                  {group.subTitle && <p className="text-sm text-gray-500">{group.subTitle}</p>}
                  <div className="pt-2"></div>
                  <div className="flex flex-col justify-start rounded-sm gap-4">
                    {group.permissions.map((permission) => (
                      <div key={permission.id} className="relative flex items-start">
                        <div className="flex items-center h-6">
                          <input
                            id={permission.id}
                            name="customPermissions"
                            type="checkbox"
                            checked={selectedPermissions.includes(permission.id)}
                            className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600"
                            onChange={() => handleCustomPermissionSelection(permission.id)}
                          />
                        </div>
                        <div className="ml-3">
                          <label
                            htmlFor={permission.id}
                            className="text-sm font-medium leading-6 text-gray-900"
                          >
                            {permission.name}
                          </label>
                          <p className="text-sm text-gray-500">{permission.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          {canSeeAssignedEmployeesSection && (
            <div className="flex flex-col mx-5 mt-10 bg-gray-50 shadow-md rounded-sm ">
              <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
                Assigned Employees
              </h1>
              <div className="flex flex-col justify-start px-4 py-4 rounded-sm gap-4">
                {employees?.map((employee) => (
                  <div key={employee.id} className="relative flex items-start">
                    <div className="flex items-center h-6">
                      <input
                        id={employee.id}
                        name="assignedEmployees"
                        type="checkbox"
                        checked={selectedEmployees.includes(employee.id)}
                        className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600"
                        onChange={() => handleEmployeeSelection(employee.id)}
                      />
                    </div>
                    <div className="ml-3">
                      <label
                        htmlFor={employee.id}
                        className="text-sm font-medium leading-6 text-gray-900"
                      >
                        {employee.firstName} {employee.lastName}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </CenteredMaxWidthLayout>
      </div>
    </>
  );
}

const permissionGroups = [
  {
    title: "Messages",
    subTitle: null,
    permissions: [
      {
        id: "view_messages",
        name: "View Messages",
        description: "Allows user to view all messages with customers.",
      },
      {
        id: "respond_messages",
        name: "Respond to Messages",
        description: "Grants permission to respond to messages.",
      },
      {
        id: "marketing_messages",
        name: "Send Mass Marketing Messages",
        description: "Grants permission to send mass marketing messages to customers.",
      },
    ],
  },
  {
    title: "Customers",
    subTitle: null,
    permissions: [
      {
        id: "view_all_customers",
        name: "View All Customers",
        description: "Allows viewing of all customer profiles in the database.",
      },
      {
        id: "manage_customers",
        name: "Manage Customers",
        description: "Enables user to create and edit customer profiles.",
      },
      {
        id: "delete_customers",
        name: "Delete Customers",
        description: "Grants permission to delete customers from the database.",
      },
    ],
  },
  {
    title: "Jobs",
    subTitle: null,
    permissions: [
      {
        id: "create_jobs",
        name: "Create Jobs",
        description: "Permission to create new job entries.",
      },

      {
        id: "manage_all_jobs",
        name: "View & Manage All Jobs",
        description: "Enables user to view/edit all job listings in the system.",
      },
      {
        id: "manage_assigned_jobs",
        name: "View & Manage Assigned Jobs",
        description: "User can view/edit jobs they created or are assigned to.",
      },
      {
        id: "manage_team_jobs",
        name: "View & Manage Team Jobs",
        description:
          "User can view/edit jobs that are created by or assigned to specific team members. (Specific team members can be selected below in the Assigned Employees section)",
      },
      {
        id: "create_estimates",
        name: "Create Estimates",
        description: "Allows the user to create and send estimates to customers.",
      },
    ],
  },
  {
    title: "Company Info & Stats",
    subTitle: null,
    permissions: [
      {
        id: "view_company_stats",
        name: "View Company Stats",
        description:
          "Permission to access and view company statistics such as average job size, top salespeople and daily/monthly revenue.",
      },
      {
        id: "view_company_info",
        name: "View Company Info",
        description:
          "Allows user to view general company information such as company phone number, tax rates, and review links.",
      },
      {
        id: "edit_company_info",
        name: "Edit Company Info",
        description:
          "Grants ability to edit company's basic information such as company phone number, tax rates, and review links.",
      },
      {
        id: "edit_billing",
        name: "Edit Billing Info",
        description: "Grants access to edit billing and subscription information.",
      },
    ],
  },
  {
    title: "Team Management",
    subTitle: null,
    permissions: [
      {
        id: "view_employees",
        name: "View Team Members",
        description: "Enables viewing of all team member profiles.",
      },
      {
        id: "manage_employees",
        name: "Manage Team Members",
        description: "Permission to edit or create new team member accounts.",
      },
    ],
  },
  {
    title: "Territory Maps",
    subTitle: "Users will always be able to create and view their own markers",
    permissions: [
      {
        id: "create_maps",
        name: "Create Territory Maps",
        description: "Allows the user to create and manage territory maps.",
      },
      {
        id: "view_all_markers",
        name: "View All Markers",
        description: "User can see all markers of everyone in the company.",
      },
    ],
  },
];

const plans = [
  {
    id: "1",
    name: "Admin",
    value: "Admin",
    description: "Can manage all areas.",
  },
  {
    id: "2",
    name: "Manager",
    value: "Manager",
    description:
      "Can see and edit all jobs, territories, and customer info. Recommended for team leads or office staff that need to view/edit all the jobs in the schedule, and all territories in the territory map.",
  },
  {
    id: "3",
    name: "Team Lead",
    value: "Team Lead",
    description:
      "Can manage their own jobs as well as the jobs of assigned salespeople. Recommend for team leads that do not need to see all jobs in the schedule, but need to manage the jobs of their assigned salespeople.",
  },
  {
    id: "4",
    name: "Salesperson (All Jobs)",
    value: "Salesperson",
    description:
      "Can see all jobs, can edit jobs and customer info, and can only see their territory and see/edit their own markers. Recommended for salespeople that need to know all of the jobs scheduled across the company.",
  },
  {
    id: "5",
    name: "Salesperson (Own Jobs Only)",
    value: "Salesperson2",
    description:
      "Can see only jobs they created or are assigned to them, can edit their own job and customer info, and can see only their territory and see/edit their own markers. Recommended for salespeople that only need to see their own jobs.",
  },
  {
    id: "6",
    name: "Field Tech",
    value: "Field Tech",
    description:
      "Can only view and edit jobs that are dispatched to them. Recommended for field techs who will be servicing jobs they are dispatched to.",
  },
  {
    id: "7",
    name: "Custom",
    value: "Custom",
    description:
      "Set custom permissions for this employee. Recommended for employees that need a custom set of permissions.",
  },
];

import React, { useRef } from "react";
import { IoImageOutline } from "react-icons/io5";

export default function CustomImageInput({ handleFileChange }) {
  // Creating a ref for the input element
  const fileInputRef = useRef(null);

  // Function to trigger the hidden file input click event
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <input
        ref={fileInputRef}
        className="hidden" // Utilizing Tailwind's utility class to hide the input
        accept="image/*"
        multiple
        type="file"
        onChange={handleFileChange} // Ensure your handleFileChange function is defined
      />
      {/* Use an icon as a button and apply Tailwind styles */}
      <button
        onClick={handleButtonClick}
        className="p-2 text-gray-900 flex flex-row hover:opacity-80"
      >
        <IoImageOutline size={24} />
        <span className="ml-1">Attach Images</span>
      </button>
    </>
  );
}

import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

export const BackLink = ({ toName, toLink, state }) => {
  return (
    <Link
      className="flex flex-row text-gray-400 hover:text-gray-500 w-fit items-center"
      to={toLink}
      state={state}
    >
      <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
      <p className="text-base font-medium">{toName}</p>
    </Link>
  );
};

import React from "react";
import InformationIconTooltip from "components/reusable/InformationIconTooltip";
import TextField from "@mui/material/TextField";
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group";

export default function Duration({ templateDetails, setTemplateDetails }) {
  const handleChangeYears = (e) => {
    const value = parseInt(e.target.value, 10);
    if (isNaN(value) || value < 1 || value > 14) return;
    setTemplateDetails({
      ...templateDetails,
      duration: value,
    });
  };

  const handleChangeType = (value) => {
    setTemplateDetails({
      ...templateDetails,
      type: value,
    });
  };

  const yearsError =
    templateDetails.duration < 1 || templateDetails.duration === "";

  const helperTextYears = yearsError ? "Field Required" : "";
  return (
    <>
      <div className="flex flex-row items-center mt-8">
        <h2 className="text-lg mr-2 font-medium">Duration</h2>
        <InformationIconTooltip
          text="How long do you want this subscription to last? You can choose to have it continue indefinitely or end after a set number of years."
          className="ml-2"
        />
      </div>
      <div className="mt-4 flex flex-col gap-4">
        <RadioGroup
          value={templateDetails.type}
          onValueChange={handleChangeType}
        >
          <div className="flex flex-row items-center">
            <RadioGroupItem value="indefinite" id="indefinite" />
            <label htmlFor="indefinite" className="ml-2">
              Continues until customer cancels
            </label>
          </div>
          <div className="flex flex-row items-center h-12">
            <RadioGroupItem value="fixed" id="fixed" />
            <label htmlFor="fixed" className="ml-2">
              Ends after
            </label>
            {templateDetails.type === "fixed" && (
              <div className="w-32 flex flex-row items-center ml-2">
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#9ca3af",
                      },
                      "&:hover fieldset": {
                        borderColor: "#6b7280",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#fef08a",
                      },
                    },
                  }}
                  type="number"
                  value={templateDetails.duration}
                  onChange={handleChangeYears}
                  error={yearsError}
                  helperText={helperTextYears}
                  variant="outlined"
                  size="small"
                  className="w-20"
                  inputProps={{
                    min: 1,
                  }}
                />
                <span className="ml-2">
                  {templateDetails.duration > 1 ? "years" : "year"}
                </span>
              </div>
            )}
          </div>
        </RadioGroup>
      </div>

      {/* <div className="mt-4 flex flex-col gap-4">
        <div className="flex flex-row">
          <input
            type="radio"
            name="duration"
            value="indefinite"
            checked={templateDetails.type === "indefinite"}
            onChange={() =>
              setTemplateDetails({
                ...templateDetails,
                type: "indefinite",
                duration: 1,
              })
            }
          />
          <label className="ml-2">Continues until customer cancels</label>
        </div>
        <div className="flex flex-row items-center h-12">
          <input
            type="radio"
            name="duration"
            value="fixed"
            checked={templateDetails.type === "fixed"}
            onChange={() =>
              setTemplateDetails({
                ...templateDetails,
                type: "fixed",
              })
            }
          />
          <label className="ml-2">Ends after</label>
          {templateDetails.type === "fixed" ? (
            <div className="w-32 flex flex-row items-center ml-2">
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#9ca3af", // Set a lighter border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#6b7280", // Change border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#fef08a",
                    },
                  },
                }}
                type="number"
                value={templateDetails.duration}
                onChange={handleChangeYears}
                error={yearsError}
                helperText={helperTextYears}
                variant="outlined"
                size="small"
                className="w-20" // fixed width using Tailwind
                inputProps={{
                  min: 1,
                }}
              />

              <span className="ml-2">
                {" "}
                {templateDetails?.duration > 1 ? "years" : "year"}
              </span>
            </div>
          ) : (
            <p className=" ml-1"> a set number of years</p>
          )}
        </div>
      </div> */}
    </>
  );
}

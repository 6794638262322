import { useEffect, useState } from "react";
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "utils/firebase";

export function useFetchHistory({ businessId, subscriptionId }) {
  const [history, setHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(true);

  useEffect(() => {
    if (!businessId || !subscriptionId) return;

    setLoadingHistory(true);

    const historyCollection = collection(
      db,
      "businesses",
      businessId,
      "subscriptions",
      subscriptionId,
      "history"
    );

    const unsubscribe = onSnapshot(
      historyCollection,
      (snapshot) => {
        const historyData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setHistory(historyData);
        setLoadingHistory(false);
      },
      (error) => {
        console.error("Error fetching history", error);
        setLoadingHistory(false);
      }
    );

    // Cleanup function to unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, [businessId, subscriptionId]);

  return { history, loadingHistory };
}

// import { useEffect, useState, useMemo } from "react";
// import {
//   collection,
//   query,
//   where,
//   getDocs,
//   doc,
//   getDoc,
// } from "firebase/firestore";
// import { db } from "utils/firebase";

// export function useFetchHistory({ businessId, subscriptionId }) {
//   const [history, setHistory] = useState([]);
//   const [loadingHistory, setLoadingHistory] = useState(true);

//   useEffect(() => {
//     const fetchHistory = async () => {
//       try {
//         const historyCollection = collection(
//           db,
//           "businesses",
//           businessId,
//           "subscriptions",
//           subscriptionId,
//           "history"
//         );
//         const historySnapshot = await getDocs(historyCollection);
//         if (historySnapshot.empty) {
//           throw new Error("Subscription history not found");
//         }
//         const history = historySnapshot.docs.map((doc) => doc.data());
//         setHistory(history);
//         setLoadingHistory(false);
//       } catch (error) {
//         console.error("Error fetching history", error);
//         setLoadingHistory(false);
//       }
//     };

//     if (businessId && subscriptionId) {
//       fetchHistory();
//     }
//   }, [businessId, subscriptionId]); // Dependency array to re-fetch if businessId or subscriptionId changes

//   return { history, loadingHistory };
// }

import React, { useState, useEffect, useContext, useMemo } from "react";
import { BiLoader, BiSend, BiCheck } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";
import SearchCustomer from "../AddJob/components/SearchCustomer";
import AddLineItem from "../AddJob/components/AddLineItem";
import AddNote from "../AddJob/components/AddNote";
import LeadSource from "../AddJob/components/LeadSource";
import { UserContext } from "../../index";
import { useSnackBar } from "context/SnackBarContext";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmModal from "components/NewEstimate/ConfirmModal";
import { useEmployeeContext } from "../../context/EmployeeContext";
import CustomerModalCombinedNew from "components/CustomerModalCombined/CustomerModalCombined";
import { PageHeader } from "../../layouts/PageHeader";
import { SolidButton } from "../../components/NewButtons/SolidButton";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";

export default function NewEstimate() {
  const { user, userData, bannerVisible } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { openSnackBar } = useSnackBar();
  // get customerState from location if it exists
  const customerState = location.state?.customer;
  const [modal, setModal] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState(customerState || false);
  const defaultTaxRate = userData?.bizData?.defaultTaxRate || null;
  const [lineItems, setLineItems] = useState([
    {
      name: "",
      description: "",
      quantity: 1,
      unitPrice: "0.00",
      taxRate: defaultTaxRate, // { name: "Tax Rate", rate: "5"}
    },
  ]);
  const [jobTotal, setJobTotal] = useState(0);
  const [leadSource, setLeadSource] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [confirmSendMethodsModal, setConfirmSendMethodsModal] = useState(false);

  const { employees: employeeList } = useEmployeeContext();

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleClick = () => {
    // we want to create a modal that asks the user if they want to send via email, text or both -- like the receipt modal
    if (!selectedCustomer || !lineItems.length || !jobTotal) {
      alert("Please fill out all fields.");
      return;
    }
    setConfirmSendMethodsModal(true);
  };

  return (
    <>
      <div
        className="bg-gray-50"
        style={{
          height: bannerVisible ? "calc(100vh - 60px - 60px)" : "calc(100vh - 64px)",
          overflowY: "auto",
        }}
      >
        <CenteredMaxWidthLayout>
          <PageHeader title="New Estimate">
            <button
              disabled={loading || success}
              // className={`${
              //   loading ? "bg-gray-700" : " bg-gray-900"
              // } rounded-md px-2 py-3 flex items-center justify-center text-white whitespace-nowrap  w-48 h-12 hover:text-yellow-100`}
              className={`relative rounded-md px-3.5 py-2.5 font-semibold  flex flex-row items-center justify-center text-base gap-2 bg-gray-900  hover:bg-gray-800  disabled:bg-gray-500 disabled:hover:bg-gray-500 disabled:hover:cursor-not-allowed  ${
                loading ? "text-transparent" : "text-white hover:text-yellow-50"
              }`}
              onClick={handleClick}
            >
              {success ? (
                <BiCheck className="text-xl text-green-400" />
              ) : (
                <>
                  {loading ? (
                    // <BiLoader className="text-lg animate-spin-slow" />
                    <CircularProgress size={20} style={{ color: "#fefce8" }} />
                  ) : (
                    <span className="flex items-center font-medium">
                      Create and Send <BiSend className="text-lg inline-block ml-2" />
                    </span>
                  )}
                </>
              )}
            </button>
          </PageHeader>
          <div className="flex flex-col flex-1 p-4 pt-0 mx-10 lg:mx-1 lg:grid lg:grid-cols-5 lg:gap-8">
            <div className=" lg:col-span-2">
              <SearchCustomer
                toggleModal={toggleModal}
                selected={selectedCustomer}
                setSelected={setSelectedCustomer}
              />

              <LeadSource setLeadSource={setLeadSource} employeeList={employeeList} />

              <div className="mt-8">
                <AddNote note={note} setNote={setNote} fromEstimate={true} />
              </div>
            </div>
            <div className="lg:mt-0 lg:col-span-3 relative">
              <AddLineItem
                // setLineItemsForJob={setLineItems}
                lineItems={lineItems}
                setLineItems={setLineItems}
                setJobTotal={setJobTotal}
                taxRates={userData?.bizData?.taxRates || []}
                defaultTaxRate={defaultTaxRate}
              />
            </div>
          </div>
        </CenteredMaxWidthLayout>
      </div>

      <ConfirmModal
        modal={confirmSendMethodsModal}
        setModal={setConfirmSendMethodsModal}
        customerData={selectedCustomer}
        newEstimateData={{
          dateAdded: new Date(),
          lastUpdated: new Date(),
          businessId: userData.userData.businessId,
          customer: selectedCustomer,
          lineItems: lineItems,
          estimateTotal: jobTotal,
          leadSource: leadSource,
          note: note,
        }}
      />

      <CustomerModalCombinedNew
        modal={modal}
        setModal={setModal}
        setSelectedCustomer={setSelectedCustomer}
      />

      {/* {modal && (
        <AddCustomerModal
          toggleModal={toggleModal}
          setNewCustomerData={setNewCustomerData}
          handleSave={handleAddNewCustomer}
          duplicateCustomerWarning={duplicateCustomerWarning}
          setDuplicateCustomerWarning={setDuplicateCustomerWarning}
          duplicateCustomer={duplicateCustomer}
          handleMergeAfterWarning={handleMergeAfterWarning}
        />
      )} */}
    </>
  );
}

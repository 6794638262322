export const FormattedStatus = ({ status }) => {
  const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1);
  // if status is active then we have a green dot next to the status if not we will just render a gray dot for now
  const statusColor = status === "active" ? "bg-green-400" : "bg-gray-500";
  const backgroundColor = status === "active" ? "" : "bg-yellow-100";
  return (
    <div className={`flex flex-row items-center ${backgroundColor} rounded-md p-1`}>
      <h1>{capitalizedStatus}</h1>
      <div className={`h-2 w-2 rounded-full ml-2 ${statusColor}`}></div>
    </div>
  );
};

import React, { useState } from "react";
import { CiUndo } from "react-icons/ci";

export default function EnterTip({ setTip, tip, amount, isProcessing }) {
  const [custom, setCustom] = useState(false);
  const [done, setDone] = useState(false);
  const [noTip, setNoTip] = useState(true);
  const [tenPercent, setTenPercent] = useState(false);
  const [fifteenPercent, setFifteenPercent] = useState(false);
  const [twentyPercent, setTwentyPercent] = useState(false);

  const tipOptions = [
    {
      name: "No Tip",
      onClick: () => {
        setTip(0);
        setNoTip(!noTip);
        setTenPercent(false);
        setFifteenPercent(false);
        setTwentyPercent(false);
      },
      state: noTip,
    },
    {
      name: "10%",
      onClick: () => {
        setTip(parseInt(0.1 * amount));
        setNoTip(false);
        setTenPercent(!tenPercent);
        setFifteenPercent(false);
        setTwentyPercent(false);
      },
      state: tenPercent,
    },
    {
      name: "15%",
      onClick: () => {
        setTip(parseInt(0.15 * amount));
        setNoTip(false);
        setTenPercent(false);
        setFifteenPercent(!fifteenPercent);
        setTwentyPercent(false);
      },
      state: fifteenPercent,
    },
    {
      name: "20%",
      onClick: () => {
        setTip(parseInt(0.2 * amount));
        setNoTip(false);
        setTenPercent(false);
        setFifteenPercent(false);
        setTwentyPercent(!twentyPercent);
      },
      state: twentyPercent,
    },
    { name: "Custom", onClick: () => setCustom(true) },
  ];

  return (
    <div className="flex flex-col mx-7 mt-5">
      <label className="text-center text-slate-500 mb-4 font-semibold">
        Tip Amount
      </label>

      {!custom ? (
        <div className="flex flex-row justify-between gap-2 text-slate-800 ">
          {tipOptions.map((option, key) => (
            <button
              disabled={isProcessing}
              key={key}
              className={`py-3 w-1/5 ring-1 rounded-md text-sm  ${
                option.state
                  ? "ring-slate-900 shadow-lg"
                  : "ring-slate-200 shadow-sm"
              }`}
              onClick={option.onClick}
            >
              {option.name}
            </button>
          ))}
        </div>
      ) : (
        <div className="flex flex-row justify-center">
          <input
            disabled={isProcessing}
            className="block appearance-none rounded-md border border-slate-300 bg-slate-50 px-3 py-2 mr-2 w-20 text-slate-800 
                  focus:outline-none  text-sm shadow-md"
            type="text"
            value={isNaN(tip) ? "" : (tip / 100).toString()}
            onChange={(e) => setTip(parseInt(e.target.value) * 100)}
          />
          <button
            disabled={isProcessing}
            className="ring-slate-500 bg-slate-50 ring-1 shadow-md text-sm rounded-md w-16 py-3 hover:shadow-lg hover:ring-slate-900"
            onClick={() => {
              setCustom(false);
              setNoTip(true);
              setTenPercent(false);
              setFifteenPercent(false);
              setTwentyPercent(false);
              setTip(0);
            }}
          >
            Back
          </button>
        </div>
      )}
    </div>
  );
}

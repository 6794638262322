// DisplayTags.js

import React from "react";

const DisplayTags = ({ tags }) => {
  return (
    <div className="flex flex-wrap gap-2">
      {tags.map((tag, index) => (
        <span
          key={index}
          className="px-2 py-1 bg-yellow-200 text-gray-800 rounded-md"
        >
          {tag}
        </span>
      ))}
    </div>
  );
};

export default DisplayTags;

import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CustomerDetailsHeader from "./components/CustomerDetailsHeader";
import HistoryTabs from "./components/HistoryTabs";
import InfoColumn from "./components/InfoColumn";
import { useUserContext } from "context/UserContext";
import { db } from "../../utils/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { useSnackBar } from "../../context/SnackBarContext";
import Dialogue from "components/modals/Dialogue";
import CancelButton from "components/buttons/CancelButton";
import DeleteButton from "components/buttons/DeleteButton";
import AddServiceSubscriptionModal from "features/ServiceSubscription/components/NewServiceSubscription/AddServiceSubscriptionModal";
import {
  fetchConversation,
  fetchEstimates,
  fetchInvoices,
  fetchJobs,
  fetchSubscriptions,
  fetchCustomer,
} from "./data/api";
import useFetch from "hooks/useFetch";
import CustomerModalCombined from "components/CustomerModalCombined/CustomerModalCombined";
import { OutlineButton } from "../../components/NewButtons/OutlineButton";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";

export default function CustomerDetails() {
  const { user, userData, bannerVisible } = useUserContext();
  const { openSnackBar } = useSnackBar();
  // were now using params instead of state to pass the customer id
  const { jobId, subscriptionId } = useLocation().state || {};
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [selectedTab, setSelectedTab] = useState("Job History");
  const [modal, setModal] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [subscriptions, setSubscriptions] = useState(null);

  const [deleteCustomerWarning, setDeleteCustomerWarning] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [servicePlanModal, setServicePlanModal] = useState(false);

  const notAbleToViewCustomers =
    userData?.userData?.customPermissions?.view_all_customers === false;

  const notAbleToEditCustomers =
    userData?.userData?.customPermissions?.manage_customers === false;

  const notAbleToDeleteCustomers =
    userData?.userData?.customPermissions?.delete_customers === false;

  const fetchJobArgs = useMemo(
    () => ({ businessId: userData.userData.businessId, customerId }),
    [userData.userData.businessId, customerId]
  );
  const { data: jobs, isLoading: loadingJobs } = useFetch(
    fetchJobs,
    fetchJobArgs
  );

  const fetchEstimateArgs = useMemo(
    () => ({ businessId: userData.userData.businessId, customerId }),
    [userData.userData.businessId, customerId]
  );
  const { data: estimates, isLoading: loadingEstimates } = useFetch(
    fetchEstimates,
    fetchEstimateArgs
  );

  const fetchInvoiceArgs = useMemo(
    () => ({ businessId: userData.userData.businessId, customerId }),
    [userData.userData.businessId, customerId]
  );
  const { data: invoices, isLoading: loadingInvoices } = useFetch(
    fetchInvoices,
    fetchInvoiceArgs
  );

  const fetchSubscriptionArgs = useMemo(
    () => ({ businessId: userData.userData.businessId, customerId }),
    [userData.userData.businessId, customerId]
  );
  const { data: subscriptionsData, isLoading: loadingSubscriptions } = useFetch(
    fetchSubscriptions,
    fetchSubscriptionArgs
  );

  useEffect(() => {
    if (subscriptionsData) {
      setSubscriptions(subscriptionsData);
    }
  }, [subscriptionsData]);

  const fetchCustomerArgs = useMemo(
    () => ({ businessId: userData.userData.businessId, customerId }),
    [userData.userData.businessId, customerId]
  );
  const { data: customerDataFromDB, isLoading: loadingCustomer } = useFetch(
    fetchCustomer,
    fetchCustomerArgs
  );

  useEffect(() => {
    if (customerDataFromDB) {
      setCustomerData(customerDataFromDB);
    }
  }, [customerDataFromDB]);

  const fetchConversationArgs = useMemo(
    () => ({
      businessId: userData.userData.businessId,
      customerId,
      customerPhone: customerData?.phone,
    }),
    [userData.userData.businessId, customerId, customerData?.phone]
  );
  const { data: conversation, isLoading: loadingConversation } = useFetch(
    fetchConversation,
    fetchConversationArgs
  );

  const toggleModal = () => {
    if (notAbleToEditCustomers) {
      openSnackBar(
        "You do not have permission to edit customers. Please contact your admin to get access."
      );
      return;
    }
    // console.log("modal was toggled");
    setModal(!modal);
  };

  const openServicePlanModal = () => {
    setServicePlanModal(true);
  };

  const openDeleteCustomerWarning = () => {
    setDeleteCustomerWarning(true);
  };

  const closeDeleteCustomerWarning = () => {
    setDeleteCustomerWarning(false);
  };

  const handleDeleteCustomer = async () => {
    if (notAbleToDeleteCustomers) {
      openSnackBar(
        "You do not have permission to delete customers. Please contact your admin to get access."
      );
      return;
    }

    if (loadingDelete) return;
    setLoadingDelete(true);
    try {
      const customerRef = doc(
        db,
        "businesses",
        userData.userData.businessId,
        "customers",
        customerData.customerId
      );
      await deleteDoc(customerRef);
      // console.log("customer deleted");
      // we might want to open a modal here to confirm the delete
      openSnackBar("Customer deleted");
      navigate("/customers");
    } catch (err) {
      console.error("Error deleting customer", err);
      openSnackBar("Error deleting customer");
    } finally {
      setLoadingDelete(false);
      closeDeleteCustomerWarning();
    }
  };

  return (
    <>
      <div
        className={`relative ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        } overflow-y-auto text-gray-800 ${notAbleToViewCustomers && "blur"}`}
      >
        <CenteredMaxWidthLayout>
          <CustomerDetailsHeader
            customer={customerData}
            navigate={navigate}
            toggleModal={toggleModal}
            handleDeleteCustomer={openDeleteCustomerWarning}
            notAbleToEditCustomers={notAbleToEditCustomers}
            notAbleToDeleteCustomers={notAbleToDeleteCustomers}
            openServicePlanModal={openServicePlanModal}
            loading={loadingCustomer}
            jobId={jobId}
            subscriptionId={subscriptionId}
          />
          <div className="flex w-full mt-1">
            <div className="flex flex-col w-3/5 min-w-0 overflow-hidden">
              <HistoryTabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                jobs={jobs}
                invoices={invoices}
                estimates={estimates}
                subscriptions={subscriptions}
                loadingJobs={loadingJobs}
                loadingInvoices={loadingInvoices}
                loadingEstimates={loadingEstimates}
                loadingSubscriptions={loadingSubscriptions}
              />
            </div>
            <div className="flex flex-col w-2/5 min-w-0 overflow-hidden">
              <InfoColumn
                customer={customerData}
                conversation={conversation}
                setCustomer={setCustomerData}
                loading={loadingCustomer}
                loadingConversation={loadingConversation}
              />
            </div>
          </div>
        </CenteredMaxWidthLayout>
      </div>

      <CustomerModalCombined
        modal={modal}
        setModal={setModal}
        setSelectedCustomer={setCustomerData}
        customer={customerData}
        customerId={customerId}
      />

      <Dialogue
        open={deleteCustomerWarning}
        onClose={closeDeleteCustomerWarning}
        width={500}
      >
        <div className="">
          <h2 className="text-2xl font-semibold">Delete Customer</h2>
          <p className="mt-2 text-sm">
            Are you sure you want to delete this customer? This action cannot be
            undone.
          </p>
        </div>
        <div className="flex flex-row justify-end items-center mt-8">
          <CancelButton handleCancel={closeDeleteCustomerWarning} />

          <DeleteButton
            onClick={handleDeleteCustomer}
            loading={loadingDelete}
            text="Delete Customer"
            width="w-36"
            additionalClasses="ml-2"
          />
        </div>
      </Dialogue>
      <AddServiceSubscriptionModal
        open={servicePlanModal}
        onClose={() => setServicePlanModal(false)}
        customer={customerData}
        setSubscriptions={setSubscriptions}
      />
    </>
  );
}

import { CircularProgress } from "@mui/material";

export const SolidButton = ({
  text,
  onClick = () => {},
  icon,
  fullWidth = false,
  disabled = false,
  loading = false,
  innerRef,
}) => {
  return (
    <button
      ref={innerRef}
      disabled={disabled || loading}
      className={`min-w-20 relative rounded-md px-3.5 py-2.5 font-semibold focus:outline-none flex flex-row items-center justify-center text-base gap-2 bg-gray-900  hover:bg-gray-800  disabled:bg-gray-500 disabled:hover:bg-gray-500 disabled:hover:cursor-not-allowed ${
        fullWidth && "flex-1"
      } ${loading ? "text-transparent" : "text-white hover:text-yellow-50"}`}
      onClick={onClick}
    >
      {icon && icon}
      <p>{text}</p>
      {loading && (
        <CircularProgress style={{ color: "#fefce8", position: "absolute" }} size="1.5rem" />
      )}
    </button>
  );
};

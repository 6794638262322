import { useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import InputMUI from "components/reusable/InputMUI";
import { useNavigate } from "react-router-dom";
import { updateCategories, deleteCategoryDoc } from "../../features/PriceBook/data/api";
import { useUserContext } from "../../context/UserContext";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import { useSnackBar } from "../../context/SnackBarContext";
import CircularProgress from "@mui/material/CircularProgress";
import { OutlineButton } from "../NewButtons/OutlineButton";
import { SolidButton } from "../NewButtons/SolidButton";

export const EditCategoryModal = ({ open, setOpen, categoryId, categoryName }) => {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();
  const navigate = useNavigate();

  const [name, setName] = useState(categoryName);
  const [deleteModalOpen, setDeletModalOpen] = useState(false);
  const [updating, setUpdating] = useState(false);

  const onUpdateCategory = async () => {
    setUpdating(true);
    const updatedCategories = [...userData.bizData.priceBookCategories].map((category) => {
      if (category.id === categoryId) {
        return { ...category, name };
      }
      return category;
    });
    try {
      await updateCategories({
        businessId: userData.userData.businessId,
        updatedCategories,
      });
    } catch (err) {
      console.error("Error", err);
      openSnackBar("Error updating category.", false, true);
    }
    setUpdating(false);
    setOpen(false);
  };

  const onDeleteCategory = async () => {
    try {
      const updatedCategories = userData.bizData.priceBookCategories.filter(
        (category) => category.id !== categoryId
      );
      await updateCategories({
        businessId: userData.userData.businessId,
        updatedCategories,
      });
      deleteCategoryDoc({ businessId: userData.userData.businessId, categoryId });
      navigate("/settings/customizations/price-book");
    } catch (err) {
      console.error("Error", err);
      openSnackBar("Error deleting category.", false, true);
    }
    setOpen(false);
  };

  return (
    <>
      <Dialog className="relative z-50" open={open} onClose={() => {}}>
        <DialogBackdrop transition className="fixed inset-0 bg-gray-500 bg-opacity-75 " />

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative overflow-hidden rounded-lg bg-white px-4 py-4 text-left shadow-xl w-full max-w-lg "
            >
              <div>
                <div className="flex flex-row justify-between items-center  mb-4">
                  <DialogTitle as="h2" className="text-xl font-bold text-left">
                    Edit Category
                  </DialogTitle>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 w-fit"
                    onClick={() => setDeletModalOpen(true)}
                  >
                    Delete Category
                  </button>
                </div>

                <div className="mt-2">
                  <InputMUI
                    disabled={false}
                    autoComplete="off"
                    label="Category Name"
                    value={name}
                    setValue={(value) => setName(value)}
                  />
                </div>
              </div>

              <div className="mt-5 flex flex-row items-center gap-3">
                <OutlineButton text="Cancel" onClick={() => setOpen(false)} fullWidth />
                <SolidButton
                  onClick={onUpdateCategory}
                  disabled={!name || name === categoryName}
                  text="Update"
                  fullWidth
                  loading={updating}
                />
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      {deleteModalOpen && (
        <ConfirmDeleteModal
          open={deleteModalOpen}
          setOpen={setDeletModalOpen}
          deleteCategory={onDeleteCategory}
          categoryName={categoryName}
        />
      )}
    </>
  );
};

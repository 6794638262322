import React, { useRef } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";

export default function CustomFileInputLogo({
  handleFileChange,
  disabled,
  editMode,
}) {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (!disabled) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <input
        ref={fileInputRef}
        className="hidden"
        accept="image/jpeg, image/jpg, image/png"
        type="file"
        onChange={handleFileChange}
      />
      <button
        disabled={disabled}
        onClick={handleButtonClick}
        className={`text-sm font-medium ${
          disabled
            ? "text-gray-400 cursor-not-allowed"
            : "text-indigo-600 hover:underline"
        }`}
      >
        {editMode ? (
          "Edit"
        ) : (
          <AiOutlinePlusCircle
            className={`text-${disabled ? "gray-400" : "indigo-600"} text-2xl`}
          />
        )}
      </button>
    </>
  );
}

import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { format } from "date-fns";
import { uploadFile } from "utils/uploadFile";
import { useSnackBar } from "context/SnackBarContext";
import { updateServiceSubscriptionSignature } from "utils/serverCalls";

export default function DisplayTerms({
  subscription,
  customer,
  setSubscription,
}) {
  const { openSnackBar } = useSnackBar();
  const sigCanvas = useRef({});
  const [loading, setLoading] = useState(false);

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const saveSignature = async () => {
    setLoading(true);
    const sigData = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    const dateAccepted = new Date();

    try {
      // Optimistic update
      setSubscription((prevSubscription) => ({
        ...prevSubscription,
        accept: {
          ...prevSubscription.accept,
          signature: sigData,
          dateAccepted: dateAccepted,
          type: "link",
        },
      }));

      // Convert base64 to a Blob for upload
      const blob = await fetch(sigData).then((resp) => resp.blob());
      const file = new File(
        [blob],
        `signature-${customer.displayName || "n/a"}.png`,
        {
          type: "image/png",
        }
      );

      // Upload to Firebase Storage
      const downloadURL = await uploadFile(file, "signatures");

      // Final update with the URL
      setSubscription((prevSubscription) => ({
        ...prevSubscription,
        accept: {
          ...prevSubscription.accept,
          signature: downloadURL,
        },
      }));

      // Update the subscription in the database
      const success = await updateServiceSubscriptionSignature({
        businessId: subscription.businessId,
        subscriptionId: subscription.subscriptionId,
        acceptObject: {
          ...subscription.accept,
          signature: downloadURL,
          dateAccepted: dateAccepted,
          type: "link",
        },
      });

      console.log("success from updating database", success);
    } catch (error) {
      // Revert to previous state in case of an error
      setSubscription((prevSubscription) => ({
        ...prevSubscription,
        accept: {
          signature: null,
          dateAccepted: null,
          type: "link",
        },
      }));
      // Show an error message (snackbar or alert)
      openSnackBar(
        "Failed to save signature, please check your network connection and try again.",
        false,
        true
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col w-full rounded-lg bg-gray-25 py-4 sm:py-6 px-4 sm:px-6 mt-4 sm:mt-8 shadow-md sm:shadow-2xl text-gray-600">
      <div className="bg-gray-100 -mx-4 sm:-mx-6 py-2 -mt-4 sm:-mt-6 mb-4 rounded-t-lg">
        <h1 className="py-2 sm:py-4 px-4 sm:px-6 text-lg sm:text-xl font-semibold">
          Terms
        </h1>
      </div>
      <p className="whitespace-pre-wrap mb-4 sm:mb-6 text-sm sm:text-base">
        {subscription.template.terms.text}
      </p>

      {subscription.template.requireSignature &&
        !subscription.accept.signature && (
          <div>
            <h2 className="mb-2 text-sm sm:text-base">Signature</h2>
            <div className="border border-gray-300 rounded-lg p-2 sm:p-4">
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  className: "w-full sm:w-[640px] h-24",
                }}
              />
              <div className="mt-1 border-t border-gray-400" />
            </div>
            <div className="flex mt-2 space-x-4 mx-2">
              <button
                onClick={clearSignature}
                className="bg-gray-50 text-gray-500 border border-gray-300 py-1 px-4 rounded-lg text-sm sm:text-base"
                disabled={loading}
              >
                Clear
              </button>
              <button
                onClick={saveSignature}
                className="bg-gray-700 text-white py-1 px-4 rounded-lg text-sm sm:text-base"
                disabled={loading}
              >
                Save
              </button>
            </div>
          </div>
        )}

      {subscription.accept.signature && (
        <div className="mt-4 sm:mt-6">
          <h2 className="font-semibold text-sm sm:text-base">
            Submitted Signature
          </h2>
          <div className="border border-gray-300 rounded-lg p-2 sm:p-3 max-w-72">
            <img
              src={subscription.accept.signature}
              alt="Signature"
              className="w-full"
            />
          </div>
          <div className="mt-2 space-y-1 sm:space-y-2">
            <div>
              <label className="font-semibold text-sm sm:text-base">Name</label>
              <p className="text-gray-500 text-sm sm:text-base">
                {customer?.displayName}
              </p>
            </div>
            <div>
              <label className="font-semibold text-sm sm:text-base">Date</label>
              <p className="text-gray-500 text-sm sm:text-base">
                {format(
                  new Date(subscription.accept.dateAccepted),
                  "MMMM do, yyyy hh:mm a"
                )}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

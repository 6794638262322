import React from "react";
import { cn } from "utils/utils";

export default function ExitModalInlineButton({ handleClose, className = "" }) {
  return (
    <button
      className={cn(
        "hover:bg-gray-100 rounded-lg p-1 transition-colors duration-200",
        className
      )}
      onClick={handleClose}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  );
}

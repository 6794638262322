import React, { useState } from "react";
import { findPriceId, findPriceIdNew } from "utils/findPriceId";
import { findPriceNew } from "utils/findPrice";
import { useUserContext } from "context/UserContext";
import { updateSubscriptionCheckoutSession } from "utils/stripe";
import Pricing from "components/payments/Subscriptions/Pricing";
import { node } from "constants/constants";
import ConfirmUpdateModal from "components/payments/Subscriptions/ConfirmUpdateModal";
import { useSnackBar } from "context/SnackBarContext";
import { useNavigate } from "react-router-dom";

export default function ChangeSubscription() {
  const { userData, setUserData, bannerVisible } = useUserContext();
  const [period, setPeriod] = useState("monthly");
  const [loading, setLoading] = useState(false);
  const { openSnackBar } = useSnackBar();
  const currentPlan = userData?.subData?.plan?.nickname?.split(" ")[0];
  const currentPeriod = userData?.subData?.plan?.nickname?.split(" ")[1];
  const isTrial = userData?.subData?.status === "trialing";
  const navigate = useNavigate();

  const [selectedPlan, setSelectedPlan] = useState({
    planName: "",
    period: "",
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [proratedInfo, setProratedInfo] = useState({
    total: 0,
    appliedBalance: 0,
    amountDue: 0,
  });
  const [loadingSim, setLoadingSim] = useState({
    planName: "None",
    period: "None",
  });

  const businessId = userData?.userData?.businessId;
  const subscriptionId = userData?.subData?.id;
  const customerId = userData?.subData?.customer;
  const subscriptionItemId = userData?.subData?.items.data[0].id;

  const handleClickBuyNow = async ({ planName }) => {
    // console.log("handleClickBuyNow", planName);

    // first we need to check if the user has too many employees to downgrade to the plan they are trying to downgrade to

    const numberOfEmployees = userData?.bizData?.numberOfUsers || 1; // this includes yourself

    // console.log("numberOfEmployees", numberOfEmployees);

    if (planName === "Starter") {
      if (numberOfEmployees > 1) {
        openSnackBar(
          "You have too many employees to downgrade to the Starter plan. Please remove some employees before downgrading.",
          false,
          true
        );

        return;
      }
    }

    if (planName === "Growing") {
      if (numberOfEmployees > 8) {
        openSnackBar(
          "You have too many employees to downgrade to the Growing plan. Please remove some employees before downgrading.",
          false,
          true
        );

        return;
      }
    }

    setLoadingSim({ planName, period });
    const newPriceId = findPriceIdNew(planName, period);
    setSelectedPlan({ planName, period });

    if (isTrial) {
      // if it's a trial, we set amountDue to the amount of the new plan
      const amountDue = findPriceNew(planName, period);
      setProratedInfo({
        total: 0,
        appliedBalance: 0,
        amountDue: amountDue,
      });
      setLoadingSim({ planName: "None", period: "None" });
      setConfirmModal(true);
      return;
    }
    try {
      const simulationResponse = await fetch(`${node}/subscriptions/simulate-invoice`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customerId,
          subscriptionId,
          newPriceId,
          subscriptionItemId,
        }),
      });

      const invoicePreview = await simulationResponse.json();

      if (!simulationResponse.ok) {
        throw new Error(invoicePreview.error || "Failed to simulate invoice");
      }

      //   console.log("invoicePreview", invoicePreview);
      // Use the invoicePreview to display prorated charges in the modal
      const proratedAmount = invoicePreview.total; // This will show the total amount the next invoice will be
      const appliedBalance = invoicePreview.starting_balance;
      const amountDue = invoicePreview.amount_due;

      let totalProratedAmount = 0;
      invoicePreview.lines.data.forEach((lineItem) => {
        if (lineItem.proration) {
          totalProratedAmount += lineItem.amount;
        }
      });

      setProratedInfo({
        total: proratedAmount / 100,
        appliedBalance: appliedBalance / 100,
        amountDue: amountDue / 100,
        totalProratedAmount: totalProratedAmount / 100,
      });

      //   setProratedCharge(proratedAmount / 100); // assuming the amount is in cents, converting to dollars
      setConfirmModal(true);
    } catch (error) {
      console.error("Failed to simulate invoice catchblock: ", error.message);
      alert(
        "Failed to fetch subscription, please try again. If this issue persists please contact support@homebase360.io"
      );
    } finally {
      setLoadingSim({ planName: "None", period: "None" });
    }
  };

  const handleConfirm = async () => {
    if (loading) return;

    try {
      setLoading(true);

      const priceId = findPriceIdNew(selectedPlan.planName, selectedPlan.period);

      if (!priceId) {
        throw new Error("Price ID not found");
      }

      const response = await fetch(`${node}/subscriptions/update-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: customerId,
          subscriptionId: subscriptionId,
          newPriceId: priceId,
          businessId: businessId,
        }),
      });

      const result = await response.json();
      //   console.log("result ====>", result);

      if (!response.ok) {
        const error = result?.error;
        if (!error) {
          throw new Error("Unexpected Error Occurred");
        }
        await handleCustomError(error);
        return;
      }

      if (!result.updatedSubscription) {
        throw new Error("Result does not contain updated subscription");
      }

      setUserData((prev) => ({
        ...prev,
        subData: result.updatedSubscription,
      }));

      // openSnackBar
      openSnackBar("Subscription updated successfully", true);
      // close the modal
      setConfirmModal(false);
    } catch (error) {
      console.error("Failed to update subscription catchblock: ", error.message);
      openSnackBar(
        "Failed to update subscription, please try again. If this continues to fail please contact support at support@homebase360.io",
        false,
        true
      );
    } finally {
      setLoading(false);
      //   setLoading({ planName: "None", period: "None" });
    }
  };

  const handleCustomError = async (error) => {
    switch (error?.type) {
      case "StripeCardError":
        console.log(`A payment error occurred: ${error.message}`);
        // this is where we should redirect them to billing portal?
        openSnackBar(
          "Payment error occurred, redirecting to billing portal to update card.",
          false,
          true
        );

        // wait for 2 seconds
        const wait = new Promise((resolve) => setTimeout(resolve, 2000));
        await wait;
        navigate("/update-subscription"); // this has the button to take them to the billing portal where they can update their card
        break;
      case "StripeInvalidRequestError":
        console.log("An invalid request occurred.", error.message);
        openSnackBar("Invalid request, update failed. Please try again.", false, true);
        break;
      default:
        throw new Error("Fall back error handling --> should end up in catchblock");
    }
    return;
  };

  const handleCloseModal = () => {
    if (loading) return;
    setConfirmModal(false);
  };
  return (
    <>
      <div
        className={`flex ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        } flex-col items-center overflow-auto bg-slate-100/90 font-stripe`}
      >
        {/* <h1 className="mt-12 flex justify-center pl-2  text-4xl font-bold text-slate-900 ">
        Limited Time Offer
       
      </h1> */}
        {/* <h2 className="mt-2 flex justify-center pl-2 text-center  text-2xl font-semibold text-slate-800  w-4/5 max-w-3xl  ">
        Save 20% for three months when you choose a monthly plan. Or save up
        to 10% when you select an annual plan. Offer ends June 30th.
      </h2> */}
        <div className="relative mt-10 mb-36 flex   w-11/12 max-w-5xl flex-col rounded-lg bg-slate-50 shadow-2xl">
          <Pricing
            period={period}
            setPeriod={setPeriod}
            // goToCheckout={goToCheckout}
            handleClickBuyNow={handleClickBuyNow}
            // setModal={setBillingInfoModal}
            // handleBillingInfoModal={handleBillingInfoModal}
            currentPlan={currentPlan}
            currentPeriod={currentPeriod}
            loading={loadingSim}
            updating={true}
          />

          <div
            className="absolute w-full text-center text-xs text-gray-400"
            style={{
              bottom: "-70px",
            }}
          >
            Powered by <span className="font-semibold text-gray-500/90">Homebase 360</span>
          </div>
        </div>
      </div>

      <ConfirmUpdateModal
        open={confirmModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
        selectedPlan={selectedPlan}
        loading={loading}
        proratedInfo={proratedInfo}
        currentPlan={currentPlan}
        currentPeriod={currentPeriod}
        isTrial={isTrial}
      />
    </>
  );
}

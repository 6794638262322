import React from "react";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcDiscover,
  FaCcJcb,
} from "react-icons/fa";

import { FaCcAmex } from "react-icons/fa6";

export default function PaymentIcon({ brand, margin = 0, size = 32 }) {
  const iconStyles = {
    visa: { color: "#1a1f71", margin }, // Visa blue
    mastercard: { color: "#374151", margin }, // Mastercard red
    amex: { color: "#4b9cd3", margin }, // Amex blue
    discover: { color: "#374151", margin }, // Discover orange
    jcb: { color: "#1a4b9c", margin }, // JCB blue
  };

  const IconComponent = {
    visa: FaCcVisa,
    mastercard: FaCcMastercard,
    amex: FaCcAmex,
    discover: FaCcDiscover,

    jcb: FaCcJcb,
    // map other payment brands to corresponding icon components
  }[brand?.toLowerCase()];

  let formattedBrand =
    brand?.charAt(0).toUpperCase() + brand?.slice(1).toLowerCase();

  if (!IconComponent) {
    return <span>{formattedBrand}</span>; // or some default icon
  }

  return <IconComponent size={size} style={iconStyles[brand?.toLowerCase()]} />;
}

import React, { useState, useEffect } from "react";
import DollarInputMUI from "components/reusable/DollarInputMUI";
import InformationIconTooltip from "components/reusable/InformationIconTooltip";
import Toggle from "components/reusable/Toggle";
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group";
import { Switch } from "components/ui/switch";

export default function PaymentFrequency({
  templateDetails,
  setTemplateDetails,
}) {
  // Use a local state to handle the input for the amount
  const handleAmountChange = (amount) => {
    let amountForUpdate = "";
    if (amount) {
      amountForUpdate = amount;
    }
    setTemplateDetails({
      ...templateDetails,
      amount: amountForUpdate,
    });
  };

  const handleFrequencyChange = (frequency) => {
    // When changing frequency, save the current input amount to the state
    // then switch to the new frequency's amount (if any)
    setTemplateDetails({
      ...templateDetails,
      paymentFrequency: frequency,
      amount: "0.00",
    });
  };

  const error = isNaN(parseFloat(templateDetails.amount));
  const helperText = error ? "Field Required" : "";

  return (
    <>
      <div className="flex flex-row items-center mt-8 mb-6">
        <h2 className="text-lg mr-2 font-medium">Payment Frequency</h2>
        <InformationIconTooltip
          text="Frequency and amount which the customer will be billed for this subscription."
          className="ml-2"
        />
      </div>

      <RadioGroup
        className="w-full max-w-72 gap-6"
        value={templateDetails.paymentFrequency}
        onValueChange={handleFrequencyChange}
      >
        {["monthly", "quarterly", "6 months", "yearly"].map((freq) => (
          <div key={freq} className="flex items-center justify-between">
            <div className="flex flex-row items-center">
              <RadioGroupItem value={freq} />
              <span className="ml-2 ">
                {freq.charAt(0).toUpperCase() + freq.slice(1)}
              </span>
            </div>
            <div className="w-32">
              <DollarInputMUI
                value={
                  templateDetails.paymentFrequency === freq
                    ? templateDetails.amount
                    : ""
                }
                onValueChange={handleAmountChange}
                disabled={templateDetails.paymentFrequency !== freq}
                error={templateDetails.paymentFrequency === freq && error}
                helperText={
                  templateDetails.paymentFrequency === freq ? helperText : ""
                }
              />
            </div>
          </div>
        ))}
      </RadioGroup>
      {/* <div className=" flex flex-col w-full max-w-72 gap-6">
        {["monthly", "quarterly", "6 months", "yearly"].map((freq) => (
          <div
            key={freq}
            className="flex flex-row items-center justify-between "
          >
            <div>
              <input
                type="radio"
                name="frequency"
                value={freq}
                checked={templateDetails.paymentFrequency === freq}
                onChange={() => handleFrequencyChange(freq)}
              />
              <label className="ml-2 ">
                {freq.charAt(0).toUpperCase() + freq.slice(1)}
              </label>
            </div>
            <div className="w-32">
              <DollarInputMUI
                value={
                  templateDetails.paymentFrequency === freq
                    ? templateDetails.amount
                    : ""
                }
                onValueChange={handleAmountChange}
                disabled={templateDetails.paymentFrequency !== freq}
                error={
                  templateDetails.paymentFrequency === freq ? error : false
                }
                helperText={
                  templateDetails.paymentFrequency === freq ? helperText : false
                }
              />
            </div>
          </div>
        ))}
      </div> */}

      {/* <div className="mt-8 flex flex-row items-center">
        <span className="mr-2 text-sm text-gray-500">
          Payable via cash, check, other
        </span>

        <Switch
          checked={templateDetails.allowOtherPayments}
          onCheckedChange={() =>
            setTemplateDetails({
              ...templateDetails,
              allowOtherPayments: !templateDetails.allowOtherPayments,
            })
          }
        />
      </div> */}
    </>
  );
}

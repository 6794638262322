import React, { useContext, useState, useEffect } from "react";
import { useUserContext } from "context/UserContext";
import { db } from "../../utils/firebase";
import {
  collection,
  query,
  orderBy,
  limit,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  getDocs,
  Timestamp,
  getDoc,
  deleteDoc,
  where,
  serverTimestamp,
  increment,
  startAfter,
} from "firebase/firestore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MomentBasedDatePicker from "components/reusable/MomentBasedDatePicker";
import JobStats from "./components/JobStatsReporting";
import { useLocation } from "react-router-dom";
import BasicSelect from "components/reusable/BasicDropdown";
import SalespersonReporting from "./components/SalespersonReporting";
import JobsReporting from "./components/JobsReporting";
import SalespersonStats from "./components/SalespersonStats";

export default function Reporting() {
  const { user, userData, bannerVisible } = useUserContext();
  const location = useLocation();
  const { salesPerson } = location?.state;
  // set view to either jobs or salespeople for now -- eventually we can add one for field techs too
  const [view, setView] = useState(salesPerson ? "sales" : "jobs");
  const [jobs, setJobs] = useState([]);
  const [salesPersonData, setSalesPersonData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  // console.log("view", view);
  const timezone = userData.bizData.timeZone;

  // const nowInBusinessTimezone = moment.tz(timezone).endOf("day").toDate();
  // keep it as a moment object
  const nowInBusinessTimezone = moment.tz(timezone).endOf("day");
  // const thirtyDaysAgoInBusinessTimezone = moment
  //   .tz(timezone)
  //   .startOf("day")
  //   .subtract(7, "days")
  //   .toDate();
  // we want to keep it as a moment object
  const thirtyDaysAgoInBusinessTimezone = moment
    .tz(timezone)
    .startOf("day")
    .subtract(7, "days");

  const [selectedStartDate, setSelectedStartDate] = useState(
    thirtyDaysAgoInBusinessTimezone
  );
  const [selectedEndDate, setSelectedEndDate] = useState(nowInBusinessTimezone);
  const [timeReference, setTimeReference] = useState("start");

  const [datesWrong, setDatesWrong] = useState(false);

  // console.log("selectedRows", selectedRows);
  // console.log("jobs", jobs);

  // console.log("selectedStartDate", selectedStartDate);
  // console.log("selectedEndDate", selectedEndDate);
  useEffect(() => {
    // fetch all data

    const fetchJobsRecursive = async (
      startDate,
      endDate,
      allJobs = [],
      lastDoc = null
    ) => {
      // console.log("fetchJobsRecursive");
      // Fetch data batch
      const jobsRef = collection(db, "businesses", userData.bizData.id, "jobs");
      let q;
      if (timeReference === "start") {
        q = query(
          jobsRef,
          where("start", ">=", startDate),
          where("start", "<=", endDate),
          orderBy("start"),
          startAfter(lastDoc),
          limit(500)
        );
      } else {
        q = query(
          jobsRef,
          where("dateAdded", ">=", startDate),
          where("dateAdded", "<=", endDate),
          orderBy("dateAdded"),
          startAfter(lastDoc),
          limit(500)
        );
      }

      const querySnapshot = await getDocs(q);
      const fetchedJobs = querySnapshot.docs.map((doc) => doc.data());
      allJobs = [...allJobs, ...fetchedJobs];

      // check if there are more jobs to fetch
      if (querySnapshot.empty || fetchedJobs.length === 0) {
        console.log("no more jobs to fetch");
        // process the jobs data
        processJobsData(allJobs);
        processSalespersonData(allJobs);
        return;
      } else {
        // console.log(`${fetchedJobs?.length} jobs received`);

        // fetch next batch
        const newLastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        await fetchJobsRecursive(startDate, endDate, allJobs, newLastDoc);
      }
    };

    // console.log("selectedStartDate from useEffect", selectedStartDate);
    // console.log("selectedEndDate from useEffect", selectedEndDate);

    if (selectedEndDate.toDate() > selectedStartDate.toDate()) {
      setDatesWrong(false);

      // console.log("selectedStartDate", selectedStartDate);
      // console.log("selectedEndDate", selectedEndDate);
      // console.log(
      //   "before fetching here is startDate",
      //   selectedStartDate,
      //   "and end date",
      //   selectedEndDate
      // );
      // console.log("selectedStartDate converted", selectedStartDate.toDate());
      // console.log("selectedEndDate converted", selectedEndDate.toDate());
      fetchJobsRecursive(selectedStartDate.toDate(), selectedEndDate.toDate());
    } else {
      console.log("Dates are wrong");
      setDatesWrong(true);
    }
  }, [selectedStartDate, selectedEndDate, timeReference]);

  /// function for processing jobsData
  const processJobsData = (allJobs) => {
    // process the jobs data
    if (allJobs.length === 0) {
      setJobs([]);
      return;
    }
    const jobsData = allJobs.map((job) => {
      let dispatchedTo = "";
      if (job.dispatchedTo) {
        if (job.dispatchedTo.length == 1) {
          dispatchedTo =
            job.dispatchedTo[0].firstName + " " + job.dispatchedTo[0].lastName;
        } else if (job.dispatchedTo.length > 1) {
          for (let i = 0; i < job.dispatchedTo.length; i++) {
            if (i == job.dispatchedTo.length - 1) {
              dispatchedTo +=
                job.dispatchedTo[i].firstName +
                " " +
                job.dispatchedTo[i].lastName;
            } else {
              dispatchedTo +=
                job.dispatchedTo[i].firstName +
                " " +
                job.dispatchedTo[i].lastName +
                ", ";
            }
          }
        }
      }

      let source = "";
      if (job.leadSource?.direct && job.leadSource?.online) {
        source = "Direct, Online";
      } else if (job.leadSource?.direct) {
        source = "Direct";
      } else if (job.leadSource?.online) {
        source = "Online";
      }

      let totalPaid = 0;
      let paymentType = "";
      let tip = 0;
      let tax = 0;
      if (job.paymentHistory) {
        for (const payment of job.paymentHistory) {
          totalPaid += parseFloat(payment.totalAmountFromStripe) / 100;

          if (payment.tip) {
            tip += parseFloat(payment.tip) / 100;
          }
          if (payment.tax) {
            tax += parseFloat(payment.tax) / 100;
          }

          if (payment.totalAmountFromStripe > 0) {
            paymentType = payment.billingType;
          }
        }
      }

      const paid = totalPaid > 0 ? "Yes" : "No";
      // we could actually do the logic for aggregating salesperson and field tech here

      let jobDuration = 0;
      const startTime = job?.startedJobTime?.toDate() || null;
      let endTime = job?.endedJobTime?.toDate() || null;

      if (startTime && !endTime) {
        endTime = new Date(); // set to current time if there's no end time
      }

      if (startTime && endTime) {
        jobDuration = moment(endTime).diff(startTime, "minutes");
      }

      return {
        id: job.jobId,
        date: job.start.toDate().toLocaleDateString(),
        customerName: job.customer.displayName,
        dispatchedTo: dispatchedTo,
        salesRep: job.leadSource?.salesPerson
          ? job.leadSource?.salesPerson?.firstName +
            " " +
            job.leadSource?.salesPerson?.lastName
          : "",
        leadType: source,
        totalAmount: parseFloat(parseFloat(job.jobTotal).toFixed(2)),
        paid: paid,
        totalPaid: parseFloat(totalPaid.toFixed(2)),
        tip: parseFloat(tip.toFixed(2)),
        tax: parseFloat(tax.toFixed(2)),
        paymentType: paymentType,
        jobDuration: jobDuration,
        reviewRating: job.reviewRating || null,
        note: job.note,
      };
    });

    const jobs = jobsData.sort((a, b) => {
      return a.date - b.date;
    });

    // console.log("jobs Data", jobs);
    setJobs(jobs);
  };

  // so we have a leadSource object on each job ==> allJobs[0].leadSource.salesPerson?.id
  // we want to aggregate data for each salesperson
  // ==> number of jobs, cancelled, total amount sold, total amount paid
  // then we want to setSalesPersonData to an array of objects with the following properties
  // ==> id, firstName, lastName, numberOfJobs, numberOfCancelledJobs, totalAmountSold, totalAmountSoldPaid
  // the other attributes that we need to know on each job are the following
  // ==> jobTotal (use this value for incrementing jobs that haven't paid yet), status (looking for status === "cancelled"), paymentHistory (use this to get total amount paid for each job == we did similar logic in the processJobs function)

  // process salespersonData
  const processSalespersonData = (allJobs) => {
    if (allJobs.length === 0) {
      setSalesPersonData([]);
      return;
    }

    const salespersonAggregator = {};

    allJobs.forEach((job) => {
      const salesPerson = job.leadSource?.salesPerson;
      if (salesPerson) {
        const salesPersonId = salesPerson.id;

        if (!salespersonAggregator[salesPersonId]) {
          salespersonAggregator[salesPersonId] = {
            id: salesPersonId,
            name: salesPerson.firstName + " " + salesPerson.lastName,
            numberOfJobs: 0,
            numberOfCancelledJobs: 0,
            numberOfPaidJobs: 0,
            totalAmountSold: 0,
            totalAmountSoldPaid: 0,
          };
        }

        salespersonAggregator[salesPersonId].numberOfJobs += 1;

        if (job.status === "cancelled") {
          salespersonAggregator[salesPersonId].numberOfCancelledJobs += 1;
        }

        salespersonAggregator[salesPersonId].totalAmountSold += parseFloat(
          job.jobTotal
        );

        let totalPaid = 0;

        if (job.paymentHistory) {
          for (const payment of job.paymentHistory) {
            totalPaid += parseFloat(payment.totalAmountFromStripe) / 100;
            let totalPaidMinusTaxAndTip = parseFloat(
              payment.totalAmountFromStripe
            );
            if (payment.tip) {
              totalPaidMinusTaxAndTip -= parseFloat(payment.tip);
            }
            if (payment.tax) {
              totalPaidMinusTaxAndTip -= parseFloat(payment.tax);
            }

            salespersonAggregator[salesPersonId].totalAmountSoldPaid +=
              totalPaidMinusTaxAndTip / 100;
          }
        }

        const paid = totalPaid > 0 ? true : false;

        if (paid) {
          salespersonAggregator[salesPersonId].numberOfPaidJobs += 1;
        }
      }
    });

    // check if there is anything in the salespersonAggregator
    if (Object.keys(salespersonAggregator).length === 0) {
      setSalesPersonData([]);
      return;
    }

    const salesPersonDataArray = Object.values(salespersonAggregator);
    // console.log("salesPeronsDataArray", salesPersonDataArray);
    setSalesPersonData(salesPersonDataArray);
  };

  // console.log("salesperson data", salesPersonData);

  const headers = [
    { label: "Date", key: "date" },
    { label: "Customer Name", key: "customerName" },
    { label: "Dispatched To", key: "dispatchedTo" },
    { label: "Salesrep", key: "salesRep" },
    { label: "Lead Type", key: "leadType" },
    { label: "Time on Job", key: "jobDuration" },
    { label: "Review", key: "reviewRating" },
    { label: "Job Total", key: "totalAmount" },
    { label: "Paid", key: "paid" },
    { label: "Payment Type", key: "paymentType" },
    { label: "Tip", key: "tip" },
    { label: "Tax", key: "tax" },
    { label: "Total Paid", key: "totalPaid" },
    { label: "Notes", key: "note" },
  ];

  const salesPersonHeaders = [
    { label: "Name", key: "name" },
    { label: "Jobs", key: "numberOfJobs" },
    { label: "Cancelled", key: "numberOfCancelledJobs" },
    { label: "Total Amount Jobs", key: "totalAmountSold" },
    { label: "Jobs Paid", key: "numberOfPaidJobs" },
    { label: "Total Amount Paid Jobs", key: "totalAmountSoldPaid" },
  ];

  let selectedJobsData = jobs;
  if (selectedRows.length > 0) {
    selectedJobsData = jobs.filter((job) => selectedRows.includes(job.id));
  }

  return (
    <div
      className={`bg-gray-50  ${
        bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
      } overflow-y-auto`}
    >
      <div className="mt-5 w-full">
        <div className="flex flex-row justify-center">
          <BasicSelect
            value={view}
            setValue={setView}
            label="Change view"
            labelColor="#4b5563"
            focusedLabelColor="#4b5563"
            width={250}
            options={[
              { value: "sales", label: "Salesperson Reporting" },
              { value: "jobs", label: "Jobs Reporting" },
            ]}
          />
        </div>
      </div>

      {view === "jobs" ? (
        <div className="flex flex-row mt-5 px-10 pr-12 h-48">
          <JobStats chartData={jobs} />
        </div>
      ) : (
        <div className="flex flex-row mt-5 px-10 pr-12 h-48">
          <SalespersonStats chartData={salesPersonData} />
        </div>
      )}
      <div className="flex flex-row justify-between mt-5 px-12">
        <div className="flex flex-row items-start gap-4 text-sm">
          <BasicSelect
            value={timeReference}
            setValue={setTimeReference}
            label="Time"
            borderColor="#4b5563"
            labelColor="#4b5563"
            focusedLabelColor="#4b5563"
            width={200}
            options={[
              { value: "start", label: "Job Start" },
              { value: "dateAdded", label: "Created At" },
            ]}
          />

          <div className="w-48">
            <MomentBasedDatePicker
              label="Start Date"
              date={selectedStartDate}
              setDate={setSelectedStartDate}
              timezone={timezone}
              borderColor="#4b5563" // gray-600
              wrong={datesWrong}
              start={true}
            />
          </div>

          <div className="w-48">
            <MomentBasedDatePicker
              label="End Date"
              date={selectedEndDate}
              setDate={setSelectedEndDate}
              timezone={timezone}
              borderColor="#4b5563"
              wrong={datesWrong}
            />
          </div>
        </div>
        <CSVLink
          data={view === "jobs" ? selectedJobsData : salesPersonData}
          headers={view === "jobs" ? headers : salesPersonHeaders}
          filename={view === "jobs" ? `jobs.csv` : "sales.csv"}
          className="bg-gray-900 text-gray-50 hover:text-yellow-100 py-2.5 px-4 rounded shadow-md text-sm items-center flex flex-shrink-0"
        >
          Export To CSV
        </CSVLink>
      </div>
      {view === "jobs" ? (
        <JobsReporting
          rows={jobs}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : (
        <SalespersonReporting rows={salesPersonData} />
      )}
    </div>
  );
}

// <TableContainer
//   component={Paper}
//   className="flex flex-col items-center pt-10"
//   sx={{}}
// >
//   <Table
//     sx={{
//       minWidth: 650,
//       maxWidth: 800,
//       borderRadius: "10px",
//       backgroundColor: "rgba(116, 221, 241, 0.5)",
//       boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
//     }}
//     aria-label="simple table"
//   >
//     <TableHead>
//       <TableRow>
//         <TableCell>Date</TableCell>
//         <TableCell>Customer Name</TableCell>
//         <TableCell>Dispatched To</TableCell>
//         {/* <TableCell>Email</TableCell> */}
//         <TableCell>Sales Rep</TableCell>
//         <TableCell>Leadtype</TableCell>
//         <TableCell>Paid</TableCell>
//         <TableCell>Total Paid</TableCell>
//         <TableCell>Tip</TableCell>
//         <TableCell>Tax</TableCell>
//         <TableCell>Payment Type</TableCell>
//       </TableRow>
//     </TableHead>
//     <TableBody>
//       {jobs.map((job, index) => (
//         <TableRow
//           key={job.id}
//           sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//         >
//           <TableCell>{job.date.toLocaleDateString()}</TableCell>
//           <TableCell>{job.customerName}</TableCell>
//           <TableCell>{job.dispatchedTo}</TableCell>
//           {/* <TableCell>{business.email}</TableCell> */}
//           <TableCell>{job.salesRep}</TableCell>
//           <TableCell>{job.leadType}</TableCell>
//           <TableCell>{job.paid}</TableCell>
//           <TableCell>{job.totalPaid}</TableCell>
//           <TableCell>{job.tip}</TableCell>
//           <TableCell>{job.tax}</TableCell>
//           <TableCell>{job.paymentType}</TableCell>
//         </TableRow>
//       ))}
//     </TableBody>
//   </Table>
// </TableContainer>

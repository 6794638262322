import React from "react";

export default function Customer({ customer }) {
  return (
    <div className=" mt-6 ">
      <h2 className="text-xl text-gray-800 mb-4 font-medium">Customer</h2>
      <div className="w-3/4 max-w-xl flex flex-col  rounded-md p-6 border border-gray-100 shadow-lg mb-6 gap-2">
        <p className="text-sm text-gray-600 font-medium">
          {customer?.displayName}
        </p>
        <p className="text-sm text-gray-600">
          {customer?.address?.[0] ? `${customer?.address?.[0]}` : ""}
        </p>
      </div>
    </div>
  );
}

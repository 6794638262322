// AutocompleteMUI.js
import React, { useState, useEffect, useRef } from "react";
import InputMUI from "components/reusable/InputMUI";

export default function AutocompleteMUI({
  label,
  address,
  setAddress,
  ...props
}) {
  const [value, setValue] = useState(address);
  const [zipValue, setZipValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [unitValue, setUnitValue] = useState("");
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const center = { lat: 40.7128, lng: -74.006 };
  const defaultBounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1,
  };

  useEffect(() => {
    if (!unitValue && !cityValue && !stateValue && !zipValue) {
      setAddress(value);
      return;
    }
    const updatedAddress = `${value}, ${
      unitValue ? unitValue + ", " : ""
    }${cityValue}, ${stateValue} ${zipValue}`;
    setAddress(updatedAddress);
  }, [value, unitValue, cityValue, stateValue, zipValue]);

  const options = {
    componentRestrictions: { country: ["ca", "us"] },
    fields: ["address_components", "geometry", "name", "formatted_address"],
    // fields: ["ALL"],
    types: ["address"],
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      // console.log("Place ====>", place);
      if (!place?.geometry) {
        setValue("");
        setZipValue("");
        setCityValue("");
        setStateValue("");
        setUnitValue("");
        return;
      } else {
        console.log({ place });
        setValue("");
        setZipValue("");
        setCityValue("");
        setStateValue("");
        setUnitValue("");

        for (let i = 0; i < place.address_components.length; i++) {
          const addressType = place.address_components[i].types[0];
          if (addressType === "subpremise") {
            setUnitValue(place.address_components[i].long_name);
          }
          if (addressType === "street_number") {
            setValue(place.address_components[i].long_name);
          }
          if (addressType === "route") {
            setValue(
              (prev) => prev + " " + place.address_components[i].long_name
            );
          }
          if (addressType === "locality") {
            setCityValue(place.address_components[i].long_name);
          }
          if (addressType === "administrative_area_level_1") {
            setStateValue(place.address_components[i].short_name);
          }
          if (addressType === "postal_code") {
            setZipValue(place.address_components[i].long_name);
          }
        }
      }
    });
  }, []);

  return (
    <div className="flex flex-col" {...props}>
      <InputMUI
        ref={inputRef}
        value={value}
        setValue={setValue}
        label={label}
        autoComplete="off"
      />

      <div className="flex justify-center gap-4 mt-4">
        <InputMUI label="Unit" value={unitValue} setValue={setUnitValue} />
        <InputMUI label="Zip" value={zipValue} setValue={setZipValue} />
      </div>
      <div className="flex justify-center gap-4 mt-4">
        <InputMUI label="City" value={cityValue} setValue={setCityValue} />
        <InputMUI label="State" value={stateValue} setValue={setStateValue} />
      </div>
    </div>
  );
}

import React from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordionExtraInputCustomerModal";
import PhoneInputMUI from "components/reusable/PhoneInputMUI";

export default function ExtraPhoneAccordion({
  workPhone,
  setWorkPhone,
  additionalPhone,
  setAdditionalPhone,
}) {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item-1">
        <AccordionTrigger>Additional Phones</AccordionTrigger>
        <AccordionContent>
          <div className="w-full flex flex-row justify-center gap-4 py-2">
            <PhoneInputMUI
              label="Work Phone"
              value={workPhone}
              setValue={setWorkPhone}
            />
            <PhoneInputMUI
              label="Additional Phone"
              value={additionalPhone}
              setValue={setAdditionalPhone}
            />
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

import React, { useState, useEffect, useRef } from "react";

function CurrencyDropdown({
  initialCurrency,
  selectedCurrency,
  setSelectedCurrency,
  setCurrencyHasBeenChanged,
  ...props
}) {
  const [showOptions, setShowOptions] = useState(false);

  const options = ["usd", "cad"];

  const editButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        showOptions &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !editButtonRef.current.contains(e.target)
      ) {
        setShowOptions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showOptions, dropdownRef]);

  const handleOptionClick = (option) => {
    setSelectedCurrency(option);
    setCurrencyHasBeenChanged(true);
    setShowOptions(false);
  };

  const handleDropdownClick = (event) => {
    event.preventDefault();
    setShowOptions(!showOptions);
  };

  return (
    <div className="relative mt-[-10px]">
      <button
        disabled={props.disabled ? true : false}
        onClick={handleDropdownClick}
        className={` border-2 border-gray-600 bg-gray-100 rounded-md px-4 py-2 w-24 text-center focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:border-transparent`}
        ref={editButtonRef}
      >
        {selectedCurrency}
      </button>
      {showOptions && (
        <ul
          className="absolute mt-1 w-24 rounded-md bg-white shadow-lg max-h-60 overflow-y-auto z-10 text-center"
          ref={dropdownRef}
        >
          {options.map((option) => (
            <li
              key={option}
              onClick={() => handleOptionClick(option)}
              className={`px-4 py-2 text-gray-700 hover:bg-gray-200 cursor-pointer ${
                selectedCurrency === option && "bg-gray-100"
              }`}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default CurrencyDropdown;

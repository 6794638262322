import React, { useState, useEffect } from "react";
import { FaToggleOn, FaToggleOff } from "react-icons/fa";
import TaxesDropdown from "components/other/TaxesDropdown";
import { useUserContext } from "context/UserContext";
import { Switch } from "components/ui/switch";

export default function TurnOnTax({
  subscriptionDetails,
  setSubscriptionDetails,
}) {
  const { userData } = useUserContext();
  const taxRates = userData?.bizData?.taxRates || [];

  const [taxesOn, setTaxesOn] = useState(false);
  const [disabled, setDisabled] = useState(false);

  // console.log("tax rates from lineItem component", taxRates);
  const handleTurnTaxesOn = () => {
    if (!taxRates) {
      alert("Please add a tax rate first in your company profile");
      return;
    }
    if (taxRates?.length === 0) {
      alert("Please add a tax rate first in your company profile");
      return;
    }
    if (!taxesOn) {
      handleSetSelectedTaxRate(taxRates[0]);
      setTaxesOn(true);
    } else {
      handleSetSelectedTaxRate(null);
      setTaxesOn(false);
    }
  };

  const handleSetSelectedTaxRate = (taxRate) => {
    setSubscriptionDetails({
      ...subscriptionDetails,
      tax: taxRate,
    });
  };

  return (
    <div className="flex flex-col w-2/5 justify-end">
      <div className="flex flex-row items-center gap-2 pr-4 justify-end">
        <label className=" font-medium">Taxable</label>

        <Switch
          disabled={disabled}
          checked={taxesOn}
          onCheckedChange={handleTurnTaxesOn}
        />
      </div>
      {taxesOn ? (
        <div className="flex flex-row justify-end">
          <TaxesDropdown
            textSize="text-sm"
            taxRates={taxRates}
            selectedTaxRate={subscriptionDetails?.tax || null}
            setSelectedTaxRate={handleSetSelectedTaxRate}
            disabled={disabled}
          />
        </div>
      ) : (
        <div className="h-6"></div>
      )}
    </div>
  );
}

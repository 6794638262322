import React, { useEffect } from "react";
import Dialogue from "components/modals/Dialogue";
import NormalButton from "components/buttons/NormalButton";
import DeleteButton from "components/buttons/DeleteButton";
import CancelButton from "components/buttons/CancelButton";
import ExitModalButton from "components/buttons/ExitModalButton";
import { node } from "constants/constants";
import { useSnackBar } from "context/SnackBarContext";
import { db } from "utils/firebase";
import { deleteDoc, doc } from "firebase/firestore";

export default function SubscriptionDeleteModal({
  open,
  onClose,
  subscription,
  navigate,
}) {
  const { openSnackBar } = useSnackBar();
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState(null);

  useEffect(() => {
    setDeleteError(null);
  }, [open]);

  const handleDeleteSubscription = async () => {
    setDeleteLoading(true);
    setDeleteError(null);

    try {
      // Step 1: Check if the subscription exists in Stripe
      if (subscription.stripeSubscription) {
        // Step 2: Cancel the Stripe subscription
        const response = await fetch(`${node}/serviceSubscriptionsApi/cancel`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscriptionId: subscription.subscriptionId,
            businessId: subscription.businessId,
            stripeSubscriptionId: subscription.stripeSubscription.id,
            stripeAccountId: subscription.stripeAccountId,
          }),
        });

        const result = await response.json();

        console.log("result from cancellation", result);

        if (!result.success) {
          throw new Error("Failed to cancel subscription in Stripe");
        }
      }

      // Step 3: Delete the subscription from Firestore
      const subRef = doc(
        db,
        "businesses",
        subscription.businessId,
        "subscriptions",
        subscription.subscriptionId
      );
      await deleteDoc(subRef);

      // Step 4: Success handling
      openSnackBar("Subscription deleted successfully", true);
      navigate(`/customers/${subscription.customer.customerId}`);
    } catch (error) {
      console.error("Error deleting subscription:", error);
      setDeleteError(
        error.message || "An error occurred while deleting the subscription."
      );
      openSnackBar("An error occurred. Please try again", false, true);
    } finally {
      setDeleteLoading(false);
    }
  };
  return (
    <Dialogue open={open} onClose={onClose} width={500}>
      <ExitModalButton handleClose={onClose} position="top-5 right-8" />
      <div className="flex flex-col gap-4">
        <h2 className="text-xl font-bold">Delete Subscription</h2>
        <p className="text-gray-700">
          Are you sure you want to delete this subscription? If this
          subscription is active it will first be cancelled before being
          deleted. After deletion, all data associated with this subscription
          will be lost.
        </p>
        {deleteError && <p className="text-red-500 text-sm">{deleteError}</p>}
        <div className="flex justify-between gap-4 mt-4">
          <CancelButton handleCancel={onClose} text="Go Back" />
          <DeleteButton
            onClick={handleDeleteSubscription}
            text="Delete Subscription"
            loading={deleteLoading}
            width="w-48"
          />
        </div>
      </div>
    </Dialogue>
  );
}

import React, { useState } from "react";

export default function InputWithLabelBilling({
  value,
  setValue,
  label,
  addresserror,
  formattedaddress,
  name,
  autocomplete,
  ...props
}) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="relative flex-1 flex flex-col font-stripe " {...props}>
      <input
        className={
          `bg-gray-50 border ${
            addresserror && !formattedaddress
              ? "border-red-400 focus:border-red-300"
              : "border-gray-500 focus:border-yellow-400"
          } h-12 rounded-md outline-none text-gray-900 text-base ` +
          (value ? "pt-6 pb-1 px-4" : "pt-4 pb-3 px-4")
        }
        disabled={props.disabled ? true : false}
        value={value}
        placeholder={label}
        onChange={handleChange}
        id={label}
        type="text"
        name={name}
        autoComplete={autocomplete}
      />
      {value && (
        <label
          className="absolute top-0 left-0 p-3 font-stripe text-gray-500"
          style={{
            pointerEvents: "none",
            transition: "all 0.3s ease",
            transform: "translateY(-10px) translateX(-5px) scale(0.8)",
            opacity: value ? 1 : 0,
          }}
        >
          {label}
        </label>
      )}
    </div>
  );
}

import React, { useState, useEffect, forwardRef } from "react";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import ExitModalInlineButton from "components/buttons/ExitModalInlineButton";
import InputMUI from "components/reusable/InputMUI";

import YellowButton from "components/buttons/YellowButton";
import AddTemplateModal from "../Templates/AddTemplateModal";
import DisabledButton from "components/buttons/DisabledButton";
import { db } from "utils/firebase";
import {
  doc,
  getDocs,
  collection,
  updateDoc,
  addDoc,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import { useUserContext } from "context/UserContext";
import { useSnackBar } from "context/SnackBarContext";
import { useFetchData } from "../hooks/useFetchData";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import SubDetails from "./SubDetails";
import Customer from "./Customer";
import SubscriptionStartDate from "./SubscriptionStartDate";
import IncludedVisits from "./IncludedVisits";
import ConfirmCreationModal from "./ConfirmCreationModal";
import AcceptSubscription from "./AcceptSubscription";
import { convertToJsDateNano } from "utils/helpers";
import ManualAcceptAndPayServiceSubscription from "./ManualAcceptAndPayServiceSubscription";
import { handleCreateServiceSubscription } from "features/ServiceSubscription/utils/handleCreateServiceSubscription";
import { useNavigate } from "react-router-dom";

const TransitionComponent = forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

const returnDefaultSubscriptionDetails = ({ customer, businessId }) => ({
  businessId: businessId,
  customer: customer,
  template: null, // refer to AddTemplateModal.js for template details
  startDate: new Date(),
  soldBy: null,
  tax: null, // {rate:12, name: "San Diego tax"}
  visits: [], // { id: uuid(), month: 1, year: 2022, status: "unscheduled", jobId: null}
  accept: {
    signature: null,
    dateAccepted: null,
    type: "link", // default to sending a link to customer
  },
  createdAt: new Date(),
  createdBy: null,
  status: "draft", // probably want to change this to something else
  history: [], //   {date: new Date().toISOString(), action: "created",  by: userData.userData.id, details: "Initial creation of subscription" }
});

export default function AddServiceSubscriptionModal({ open, onClose, customer, setSubscriptions }) {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();
  const navigate = useNavigate();
  const businessId = userData?.bizData?.id;
  const { employees, subscriptionTemplates, setSubscriptionTemplates } = useFetchData(
    userData.userData.businessId
  );
  const [subscriptionDetails, setSubscriptionDetails] = useState(
    returnDefaultSubscriptionDetails({ customer, businessId })
  );

  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openConfirmCreationModal, setOpenConfirmCreationModal] = useState(false);
  const [acceptAndPayModal, setAcceptAndPayModal] = useState(false);

  const [loading, setLoading] = useState(false);

  // Keep state synced -- may eventually want to include prev subscription details if we are editing...
  useEffect(() => {
    setSubscriptionDetails(returnDefaultSubscriptionDetails({ customer, businessId }));
  }, [open, customer, businessId]);

  const handleSubscriptionCreation = async ({
    emailToSend = null,
    phoneToSend = null,
    message = null,
  }) => {
    console.log("handleCreateSubscription");
    if (!userData?.bizData?.stripeAccountId || !userData?.bizData?.stripe_charges_enabled) {
      openSnackBar(
        "You need to finish connecting your stripe account before you can send a subscription",
        false,
        true
      );
      return;
    }

    console.log("TYPE", subscriptionDetails.accept?.type);

    try {
      setLoading(true);

      const result = await handleCreateServiceSubscription({
        businessId,
        subscriptionDetails,
        userData,
        emailToSend,
        phoneToSend,
        message,
      });

      if (!result.success) {
        throw new Error(result.error || "Failed to create subscription");
      }
      // await setDoc(subDocRef, subscription);
      // await addDoc(subDocRef.collection("history"), initialHistoryObj);

      // wait 3 seconds before navigating to subscription details page -- this is so the history has time to update
      const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await wait(3000);
      openSnackBar(
        `Subscription created${subscriptionDetails?.accept?.type === "link" ? " and sent" : ""}.`,
        true
      );
      setSubscriptions((prevSubs) => {
        const newSubscriptions = [...prevSubs, result.subscription];
        newSubscriptions.sort(
          (a, b) => convertToJsDateNano(b.createdAt) - convertToJsDateNano(a.createdAt)
        );
        return [...newSubscriptions];
      });
      navigate(`/servicesubscription/${result.subscription.subscriptionId}`, {
        state: {
          customerId: customer.customerId,
          openAcceptModal: subscriptionDetails?.accept?.type === "business",
        },
      });

      onClose();
      // we also want to navigate to subscription details page here.. which we have yet to build.. shouldn't take too long though...
    } catch (error) {
      console.error("Error creating subscription", error);
      openSnackBar("Error creating subscription.", false, true);
    } finally {
      setLoading(false);
    }
  };

  //////////////// Animation logic //////////////////////////
  const [intialDelay, setIntialDelay] = useState(false);
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setIntialDelay(true);
      }, 1000);
    } else {
      setTimeout(() => {
        setIntialDelay(false);
      }, 1500);
    }
  }, [open]);
  /////////////////////////////////////////////////

  const disabledCreate =
    !subscriptionDetails.template ||
    !subscriptionDetails.template.duration ||
    !subscriptionDetails.template.visits === "" ||
    subscriptionDetails.template.amount === "" ||
    subscriptionDetails.template.paymentFrequency === "" ||
    !subscriptionDetails.startDate ||
    !subscriptionDetails.visits ||
    !subscriptionDetails.customer;

  return (
    <Modal open={open} onClose={() => {}}>
      <TransitionComponent
        in={open}
        style={
          intialDelay
            ? {
                transform: openTemplateModal ? "scale(0.95) translateX(10%)" : "",
                transition: "transform 0.3s ease-in-out",
              }
            : {}
        }
      >
        <div
          className={`absolute  top-0 sm:w-3/4 sm:left-1/4 w-full h-full items-center bg-white rounded-t-md rounded-bl-md`}
        >
          <Footer
            onClose={onClose}
            disabled={disabledCreate}
            loading={loading}
            onCreate={() => {
              if (subscriptionDetails.accept?.type === "business") {
                handleSubscriptionCreation({
                  emailToSend: subscriptionDetails.customer.email?.[0] || null,
                  phoneToSend: subscriptionDetails.customer.phone || null,
                  message: null,
                  type: "business",
                });
              } else {
                setOpenConfirmCreationModal(true);
              }
            }}
          />
          <Header title="Add Service Subscription" onClose={onClose} />

          <div
            className="flex flex-col w-full px-6 overflow-auto pb-12"
            style={{ maxHeight: "calc(100% - 8rem)" }}
          >
            <Customer customer={customer} />

            <SubDetails
              subscriptionDetails={subscriptionDetails}
              setSubscriptionDetails={setSubscriptionDetails}
              subscriptionTemplates={subscriptionTemplates}
              setOpenTemplateModal={setOpenTemplateModal}
            />
            <SubscriptionStartDate
              subscriptionDetails={subscriptionDetails}
              setSubscriptionDetails={setSubscriptionDetails}
              employees={employees}
            />
            <IncludedVisits
              subscriptionDetails={subscriptionDetails}
              setSubscriptionDetails={setSubscriptionDetails}
            />
            <AcceptSubscription
              subscriptionDetails={subscriptionDetails}
              setSubscriptionDetails={setSubscriptionDetails}
            />
          </div>
          <AddTemplateModal
            open={openTemplateModal}
            onClose={() => setOpenTemplateModal(false)}
            setSubscriptionTemplates={setSubscriptionTemplates}
            setSubscriptionDetails={setSubscriptionDetails}
          />

          <ConfirmCreationModal
            open={openConfirmCreationModal}
            onClose={() => setOpenConfirmCreationModal(false)}
            subscriptionDetails={subscriptionDetails}
            handleCreateSubscription={handleSubscriptionCreation}
            loading={loading}
          />
        </div>
      </TransitionComponent>
    </Modal>
  );
}

const exampleServiceTemplates = [
  {
    title: "Service Template 1",
    description: "This is a description for Service Template 1",
  },
  {
    title: "Service Template 2",
    description: "This is a description for Service Template 2",
  },
  {
    title: "Service Template 3",
    description: "This is a description for Service Template 3",
  },
];

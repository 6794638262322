import React from "react";
import { plans } from "utils/subscriptionPlans";
import { BsFillCheckCircleFill } from "react-icons/bs";
import NormalButton from "components/buttons/NormalButton";

export default function Pricing({
  period,
  setPeriod,
  handleBillingInfoModal = () => {},
  currentPlan,
  currentPeriod,
  handleClickBuyNow,
  loading,
  updating = false,
}) {
  //   console.log("loading", loading);
  //   console.log("currentPlan", currentPlan);
  //   console.log("currentPeriod", currentPeriod);
  //   console.log("period", period);
  return (
    <div className="flex flex-col  items-center px-7 pb-5">
      <div className="flex w-full flex-row items-center justify-center border-slate-200 pt-5">
        <div className="flex-row flex items-center justify-center border rounded-md  border-gray-800 shadow-2xl text-sm font-medium sm:right-12">
          <button
            onClick={() => {
              setPeriod("monthly");
            }}
            className={
              period === "monthly"
                ? "bg-gray-200 text-gray-900 p-2 rounded-l cursor-pointer "
                : "bg-gray-50 text-gray-900 p-2 rounded-l cursor-pointer hover:bg-gray-100"
            }
          >
            Monthly
          </button>

          <button
            onClick={() => {
              setPeriod("yearly");
            }}
            className={
              period === "yearly"
                ? "bg-gray-600 text-gray-50 p-2 rounded-r cursor-pointer"
                : "bg-gray-50 text-gray-900 p-2 rounded-r cursor-pointer hover:bg-gray-600 hover:text-gray-50"
            }
          >
            Annual
          </button>
        </div>
        <div className="text-sm font-medium text-yellow-600 ml-4 p-2 bg-yellow-100 rounded-md">
          Save big with an annual plan
        </div>
      </div>

      {/* <h1 className="text-md w-full font-semibold text-slate-500">
        Free trial 14 days
      </h1> */}

      <div className="flex md:flex-row flex-col">
        {plans.map((plan) => (
          <div
            key={plan.name}
            className={`pb-18 relative mt-16 mr-0 md:mr-4 last:mr-0 flex flex-col ${
              currentPlan === plan.name ? "rounded-b-lg" : "rounded-lg"
            } p-10 py-8 pt-8 pb-20 text-gray-500 shadow-lg transition-colors ${
              period === "yearly" ? "bg-gray-700 text-yellow-100" : " bg-gray-100 "
            } `}
          >
            {currentPlan?.toLowerCase() === plan.name.toLowerCase() &&
              period?.toLowerCase() === currentPeriod?.toLowerCase() && (
                <div
                  className="h-8 absolute bg-gray-400 rounded-t-md flex items-center justify-center px-1"
                  style={{
                    top: "-30px",
                    left: 0,
                    width: "100%",
                  }}
                >
                  <h1 className={`text-xs font-semibold text-gray-100 text-center`}>
                    {updating ? "Your Current Plan" : "Currently trialing this plan"}
                  </h1>
                </div>
              )}

            {plan.name === "Growing" && !updating && (
              <div
                className="h-8  absolute  text-gray-600  flex items-center justify-center px-1"
                style={{
                  top: 2,
                  left: 0,
                  width: "100%",
                }}
              >
                <h1
                  className={`text-md font-semibold ${
                    period === "yearly" ? "text-yellow-100" : "text-gray-600"
                  }  text-center`}
                >
                  Most Popular
                </h1>
              </div>
            )}
            <div className="flex flex-row items-center justify-center">
              <h1
                className={`text-2xl font-bold ${
                  period === "yearly" ? "text-gray-50" : "text-gray-700"
                }`}
              >
                {plan.name}
              </h1>
            </div>
            <ul className="text-md flex flex-col gap-2 pt-6 font-semibold">
              {plan.features.map((feature) => (
                <li key={feature} className="flex">
                  <BsFillCheckCircleFill className="text-green-400 mt-1 flex-shrink-0" />

                  <span className="ml-2">{feature}</span>
                </li>
              ))}
            </ul>
            <div className="flex mt-6 flex-col mb-8 items-center w-full flex-grow justify-end">
              {/* Old discount code for the year  */}
              {/* <h1
                className={`text-md font-semibold line-through ${
                  period === "yearly" ? "text-red-400" : "text-red-700"
                }`}
              >
                {period === "monthly"
                  ? plan.monthlyPrice + "/month"
                  : plan.annualPrice + "/month"}
              </h1> */}
              {/* <h1
                className={`text-3xl font-bold ${
                  period === "yearly" ? "text-gray-50" : "text-gray-800"
                }`}
              >
                {period === "monthly"
                  ? "$" +
                    Math.floor(
                      parseFloat(plan.monthlyPrice.replace("$", "")) -
                        parseFloat(plan.monthlyPrice.replace("$", "")) * 0.2
                    ) +
                    "/mo"
                  : "$" +
                    Math.floor(
                      parseFloat(plan.annualPrice.replace("$", "")) -
                        parseFloat(plan.annualPrice.replace("$", "")) * 0.1
                    ) +
                    "/mo"}
              </h1> */}
              <h1
                className={`text-3xl font-bold ${
                  period === "yearly" ? "text-gray-50" : "text-gray-800"
                }`}
              >
                {period === "monthly"
                  ? "$" + Math.floor(parseFloat(plan.monthlyPrice.replace("$", ""))) + "/mo"
                  : "$" + Math.floor(parseFloat(plan.annualPrice.replace("$", ""))) + "/mo"}
              </h1>

              {period === "yearly" && (
                <h2 className="text-sm font-semibold text-gray-100 mt-2">
                  ${parseFloat(plan.annualPrice.replace("$", "")) * 12} billed annually
                </h2>
              )}
            </div>
            <div className="absolute left-0 bottom-10 flex w-full justify-center">
              {/* <button
                className={` rounded-md bg-gray-500 px-4 py-2 font-medium text-yellow-200 shadow-md ${"cursor-pointer"}
                  `}
                onClick={() => goToCheckout(plan.name)}
              >
                Buy Now
              </button> */}
              {/* <button
                className={` rounded-md bg-gray-500 px-4 py-2 font-medium text-yellow-200 shadow-md ${"cursor-pointer"}
                  `}
                // onClick={() => handleBillingInfoModal(plan.name)}
                onClick={() => handleClickBuyNow({ planName: plan.name })}
              >
                Buy Now
              </button> */}
              <NormalButton
                text="Change Plan"
                onClick={() => handleClickBuyNow({ planName: plan.name })}
                disabled={
                  (currentPlan?.toLowerCase() === plan.name.toLowerCase() &&
                    period?.toLowerCase() === currentPeriod?.toLowerCase()) ||
                  (loading.planName === !"None" && loading.period === !"None")
                }
                loading={loading.planName === plan.name && loading.period === period}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

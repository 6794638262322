import React, { useState, useEffect, useContext } from "react";
import { BsFillExclamationCircleFill, BsFillCheckCircleFill } from "react-icons/bs";

import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth, db } from "../../utils/firebase";

import { useNavigate, Link, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { PasswordRegister, TextField, AuthLayout } from "../../components/reusable/fields";

// import { Logo } from '@/components/Logo'

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, loading] = useAuthState(auth);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const [errorReset, setErrorReset] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    // console.log("user", user);
    if (user) {
      // Read redirect path from the URL query parameters if available, otherwise default to home page
      const params = new URLSearchParams(location.search);
      const redirectPath = params.get("redirect");
      if (redirectPath === "/games") {
        navigate(redirectPath);
      } else {
        navigate("/");
      }

      // navigate("/");
    }
  }, [user]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setPasswordError("Please fill in all fields");
      return;
    }
    setPasswordError(false);
    setResetSent(false);
    setForgotPassword(false);
    setErrorReset(false);

    try {
      const userCred = await signInWithEmailAndPassword(auth, email, password);
      // console.log("user logged in, user cred: ", userCred);
    } catch (error) {
      // we should change this to the different friendly codes
      switch (error.code) {
        case "auth/user-not-found":
          setPasswordError("No user found with this email. Please check and try again.");
          break;
        case "auth/wrong-password":
          setPasswordError("Incorrect password. Please check and try again.");
          setForgotPassword(true);
          break;
        case "auth/user-disabled":
          setPasswordError("Your account has been disabled. Please contact support.");
          break;
        case "auth/invalid-email":
          setPasswordError("Invalid email format. Please check and try again.");
          break;
        default:
          setPasswordError("An unexpected error occurred. Please try again.");
      }
    }
  };

  const sendResetEmail = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setResetSent(true);
      setPasswordError(false);
      setForgotPassword(false);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      setErrorReset("Email reset did not send: " + errorMessage);
    }
  };
  return (
    <AuthLayout>
      <div className="flex flex-col items-center">
        {/* <Logo className="h-10 w-auto" /> */}
        <img src="/images/Homebase-logo-black.png" alt="Homebase 360" width={200} height={50} />
      </div>
      <form onSubmit={handleLogin} className="mt-16 grid grid-cols-1 gap-y-8">
        <TextField
          label="Email address"
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <PasswordRegister
          className="col-span-full mt-5"
          label="Password"
          id="password"
          name="password"
          autoComplete="new-password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          togglePassword={togglePassword}
          type={passwordShown ? "text" : "password"}
          required
          passwordShown={passwordShown}
        />
        <div>
          <button
            type="submit"
            variant="solid"
            color="slate"
            className=" group mt-10 inline-flex w-full items-center justify-center gap-2 rounded-full
          bg-neutral-900 py-2 px-4 text-sm font-semibold text-white hover:bg-neutral-700 hover:text-neutral-100 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-900 active:bg-neutral-800 active:text-neutral-300"
            onClick={() => console.log("handle login")}
          >
            <span>
              Sign in <span aria-hidden="true">&rarr;</span>
            </span>
          </button>
          {passwordError && (
            <div className="flex flex-col mt-5 flex-nowrap">
              <p className=" text-sm mt-1 text-center text-red-600" id="email-error">
                {passwordError}
              </p>

              {forgotPassword && (
                <div className="flex mt-5 items-center justify-center align-middle gap-2 row-auto flex-nowrap">
                  <BsFillExclamationCircleFill
                    className="h-5 mt-1 w-5 text-red-500"
                    aria-hidden="true"
                  />

                  <p
                    className=" text-sm mt-1  text-red-600 hover:underline cursor-pointer"
                    id="email-error"
                    onClick={sendResetEmail}
                  >
                    Forgot Password?
                  </p>
                </div>
              )}
            </div>
          )}
          {resetSent && (
            <div className="flex mt-5 items-center justify-center align-middle gap-2 row-auto flex-nowrap">
              <BsFillCheckCircleFill className="h-5 mt-1 w-5 text-green-500" aria-hidden="true" />
              <p
                className=" text-sm mt-1  text-green-600 hover:underline cursor-pointer"
                id="email-error"
                onClick={sendResetEmail}
              >
                Email Sent!
              </p>
            </div>
          )}
          {errorReset && (
            <div className="flex mt-3 items-center justify-center align-middle gap-2 row-auto flex-nowrap">
              <p className=" text-sm mt-1  text-red-600" id="reset-error">
                {errorReset}
              </p>
            </div>
          )}
          <div className="mt-10">
            <p className="mt-2 text-center text-sm text-gray-700">
              Don&apos;t have an account?{" "}
              <Link
                to="https://app.homebase360.io/register"
                className="font-medium text-yellow-600 hover:underline"
              >
                Sign up
              </Link>{" "}
              for a free trial.
            </p>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
}

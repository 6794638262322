import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import moment from "moment-timezone";

export default function MomentBasedDatePicker({
  date,
  setDate,
  timezone,
  borderColor,
  label,
  disabled,
  wrong,
  start = false,
}) {
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          //   root: {
          //     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          //       borderColor: borderColor, // Your custom border color
          //     },
          //   },
          root: {
            fontFamily: '"Inter var", system-ui, sans-serif',
            // Change border color when not focused
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: wrong ? "red" : borderColor,
            },
            // Change border color when focused
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: wrong ? "red" : borderColor,
            },
            // Change border color when hovered
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: wrong ? "red" : borderColor,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: '"Inter var", system-ui, sans-serif',

            color: borderColor, // Default label color
            "&.Mui-focused": {
              color: borderColor, // Label color when input is focused
            },
          },
        },
      },

      MuiPickersDay: {
        styleOverrides: {
          root: {
            "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
              backgroundColor: "#facc15", // Your custom selected day color
              color: "white", // Text color for the selected day
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <DatePicker
        disabled={disabled}
        slotProps={{ textField: { size: "small" } }}
        label={label}
        value={date && moment.isMoment(date) ? date.clone().tz(timezone) : null}
        onChange={(time) => {
          let timeInBusinessTimezone = time.clone().tz(timezone);
          if (start) {
            timeInBusinessTimezone = timeInBusinessTimezone.startOf("day");
          } else {
            timeInBusinessTimezone = timeInBusinessTimezone.endOf("day");
          }

          // Update your start Date/ endDate
          setDate(timeInBusinessTimezone);
        }}
      />
    </ThemeProvider>
  );
}

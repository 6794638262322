// features/ServiceSubscription/utils/handleSubscriptionPaymentNewCard.js
import { CardElement } from "@stripe/react-stripe-js";
import { node } from "constants/constants";

const validateInputs = (stripe, elements, subscription) => {
  if (!stripe || !elements) {
    return {
      isValid: false,
      error: "Stripe has not loaded yet. Please try again.",
    };
  }
  if (!subscription?.subscriptionId) {
    return {
      isValid: false,
      error: "No subscription id found.",
    };
  }
  return { isValid: true };
};

export const handleSubscriptionPaymentNewCard = async (params) => {
  const {
    stripe,
    elements,
    subscription,
    customer,
    shouldSavePaymentMethod = true,
  } = params;

  const validationResult = validateInputs(stripe, elements, subscription);
  if (!validationResult.isValid) {
    return { success: false, error: validationResult.error };
  }

  try {
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      throw new Error(error.message);
    }

    const response = await fetch(`${node}/serviceSubscriptionsApi/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subscription: {
          businessId: subscription.businessId,
          customerId: customer.customerId,
          subscriptionId: subscription.subscriptionId,
          template: subscription.template,
          startDate: subscription.startDate,
          accept: subscription.accept,
          tax: subscription.tax,
        },
        customer: customer,
        paymentMethodId: paymentMethod.id,
        shouldSavePaymentMethod,
        stripeAccountId: subscription.stripeAccountId,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to create subscription");
    }

    const result = await response.json();

    if (!result.success) {
      throw new Error(result.error || "Failed to create subscription");
    }

    if (result.message === "Operation was already completed") {
      throw new Error("Subscription already activated");
    }

    return {
      success: true,
      newStatus: "active",
      stripeSubscription: result.stripeSubscription,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message || "An error occurred, please try again later.",
    };
  }
};

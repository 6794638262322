// features/ServiceSubscription/utils/handleCreateServiceSubscription.js

import { doc, setDoc, collection } from "firebase/firestore";
import { db } from "utils/firebase";

export const handleCreateServiceSubscription = async ({
  businessId,
  subscriptionDetails,
  userData,
  emailToSend = null,
  phoneToSend = null,
  message = null,
}) => {
  if (
    !userData?.bizData?.stripeAccountId ||
    !userData?.bizData?.stripe_charges_enabled
  ) {
    throw new Error("Stripe account not fully set up");
  }

  const subDocRef = doc(
    collection(db, "businesses", businessId, "subscriptions")
  );

  const subscription = {
    subscriptionId: subDocRef.id,
    ...subscriptionDetails,
    accept: {
      ...subscriptionDetails.accept,
      ...(emailToSend ? { emailToSend } : {}),
      ...(phoneToSend ? { phoneToSend } : {}),
      message,
    },
    createdBy: userData.userData.id,
    stripeAccountId: userData.bizData.stripeAccountId,
    companyName: userData.bizData.companyName,
    status: subscriptionDetails?.accept?.type === "link" ? "sent" : "draft",
    source: {
      type: "client_update",
      userId: userData.userData.id,
      userName: `${userData.userData.firstName} ${userData.userData.lastName}`,
      date: new Date(),
    },
  };

  try {
    await setDoc(subDocRef, subscription);
    return { success: true, subscription };
  } catch (error) {
    console.error("Error creating subscription", error);
    throw new Error("Failed to create subscription");
  }
};

import React, { useEffect, useState, useContext, useRef } from "react";
import { UserContext } from "../../index";
import { AiOutlineEdit } from "react-icons/ai";
import { IoCloseSharp } from "react-icons/io5";
import { ImSpinner } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { node } from "../../constants/constants";
import { db } from "../../utils/firebase";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { findPriceId } from "../../utils/findPriceId";
import { useSnackBar } from "../../context/SnackBarContext";
import NormalButton from "components/buttons/NormalButton";
import { useEmployeeContext } from "context/EmployeeContext";
import { PageHeader } from "../../layouts/PageHeader";
import { SolidButton } from "../../components/NewButtons/SolidButton";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";

export default function Employees() {
  const { user, userData, setUserData, bannerVisible } = useContext(UserContext);
  const { openSnackBar } = useSnackBar();
  const navigate = useNavigate();

  // const [employees, setEmployees] = useState([]);
  const { employees, loading } = useEmployeeContext();
  const [modal, setModal] = useState(false);
  // const [loading, setLoading] = useState(false);

  const notAbleToViewEmployees =
    !userData?.userData?.isAdmin &&
    userData?.userData?.userType !== "Manger" &&
    userData?.userData?.customPermissions?.view_employees !== true;

  const notAbleToEditEmployees =
    !userData?.userData?.isAdmin &&
    userData?.userData?.customPermissions?.manage_employees !== true;

  const notAbleToEditBilling =
    !userData?.userData?.isAdmin && userData?.userData?.customPermissions?.edit_billing !== true;

  const plan = userData?.subData?.plan?.nickname;

  const planType = plan?.includes("Starter")
    ? "Starter"
    : plan?.includes("Growing")
    ? "Growing"
    : plan?.includes("Enterprise")
    ? "Enterprise"
    : null;

  const nextPlanType = planType === "Starter" ? "Growing" : "Enterprise";

  const period = plan?.includes("Monthly") ? "month" : "annual";

  const handleUpdateSubscriptionPlan = async () => {
    if (notAbleToEditBilling) {
      openSnackBar(
        "You do not have permission to edit billing. Please contact your admin to get access."
      );
      return;
    }
    if (userData?.subData?.status === "manual trial") {
      navigate("/new-subscription");
      return;
    }

    if (!userData?.subData?.customer || !userData?.subData?.id) {
      alert(
        "There is missing data to upgrade your plan. Please contact support@homebase30.io for assistance"
      );
      return;
    }

    navigate("/change-subscription");
    return;
  };

  // useEffect(() => {
  //   const getEmployees = async () => {
  //     // get employees from firestore
  //     const employeeCollectionRef = collection(db, "users");
  //     const employeeQuery = query(
  //       employeeCollectionRef,
  //       where("businessId", "==", userData.userData.businessId)
  //     );
  //     try {
  //       const employeeSnapshot = await getDocs(employeeQuery);
  //       const employeeListFirestore = employeeSnapshot.docs.map((doc) =>
  //         doc.data()
  //       );
  //       const sortedEmployeeList = employeeListFirestore.sort((a, b) =>
  //         a.firstName.localeCompare(b.firstName)
  //       );
  //       setEmployees(sortedEmployeeList);
  //       // console.log("employees", employeeListFirestore);

  //       if (userData.userData.userType !== "Admin") return;
  //       // update the business doc with the number of employees
  //       const businessDocRef = doc(
  //         db,
  //         "businesses",
  //         userData.userData.businessId
  //       );
  //       await updateDoc(businessDocRef, {
  //         numberOfUsers: employeeListFirestore.length,
  //       });
  //     } catch (error) {
  //       console.log("error getting employees", error);
  //     }
  //   };
  //   getEmployees();
  // }, []);

  // use effect whenever employees change we update the number of employees in the business doc
  // useEffect(() => {
  //   if (employees.length === 0) return;
  //   if (userData.userData.userType !== "Admin") return;

  //   // console.log("updating employees with length", employees.length);
  //   // update the business doc with the number of employees
  //   const businessDocRef = doc(db, "businesses", userData.userData.businessId);
  //   updateDoc(businessDocRef, {
  //     numberOfUsers: employees.length,
  //   });
  //   setUserData((prev) => ({
  //     ...prev,
  //     bizData: {
  //       ...prev.bizData,
  //       numberOfUsers: employees.length,
  //     },
  //   }));
  // }, [employees]);

  // console.log("employees length", employees?.length);
  // console.log("plan", plan);
  // console.log("planType", planType);
  const handleAddEmployee = () => {
    if (notAbleToEditEmployees) {
      openSnackBar(
        "You do not have permission to edit employees. Please contact your admin to receive access."
      );
      return;
    }

    if (!planType) return alert("Please select a plan to add employees");

    if (planType === "Starter") {
      setModal(true);
      return;
    }

    if (planType === "Growing" && employees?.length >= 9) {
      setModal(true);
      return;
    }

    navigate("/settings/newemployee", { state: { employees } });
  };

  const handleClick = (employee) => {
    if (notAbleToEditEmployees) {
      openSnackBar("You do not have permission to edit employees");
      return;
    }
    navigate(`/settings/employees/${employee.id}`, {
      state: { employee, employees },
    });
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <>
      <div
        className={`bg-gray-100 ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        }  overflow-y-auto`}
      >
        <CenteredMaxWidthLayout>
          <PageHeader title="Employees">
            <SolidButton text="Add Employee" onClick={handleAddEmployee} />
          </PageHeader>
          <div
            className={`flex flex-col mx-5 mb-10 bg-gray-50 shadow-md rounded-sm ${
              notAbleToViewEmployees && "blur"
            }`}
          >
            <div className="flex flex-row font-semibold items-center  justify-start py-3 px-5 gap-5 border-b-2 border-gray-400 bg-gray-900/5 whitespace-nowrap ">
              <h1 className="w-1/4 shrink-0">Name</h1>
              <h1 className="w-1/4 shrink-0">Role</h1>
              <h1 className="w-1/4 shrink-0">Created At</h1>
              <AiOutlineEdit className="w-1/4 h-5" />
            </div>
            {employees &&
              employees.length > 0 &&
              employees.map((employee, key) => (
                <div key={key} className="flex flex-row items-start justify-start px-5 gap-5 py-3 ">
                  <h1 className="w-1/4 shrink-0">{employee.firstName + " " + employee.lastName}</h1>
                  <h1 className="w-1/4 shrink-0">
                    {employee.userType === "Salesperson2" ? "Salesperson" : employee.userType}
                  </h1>
                  <h1 className="w-1/4 shrink-0">
                    {employee.createdAt &&
                      new Date(employee.createdAt?.seconds * 1000).toLocaleDateString()}
                  </h1>
                  <AiOutlineEdit
                    className="w-1/4 h-5 cursor-pointer hover:text-yellow-400"
                    onClick={() => handleClick(employee)}
                  />
                </div>
              ))}
          </div>
        </CenteredMaxWidthLayout>
      </div>
      {modal && (
        <UpgradeModal
          toggleModal={toggleModal}
          nextPlanType={nextPlanType}
          planType={planType}
          upgradePlan={handleUpdateSubscriptionPlan}
          loading={false}
        />
      )}
    </>
  );
}

function UpgradeModal({ toggleModal, planType, nextPlanType, upgradePlan, loading }) {
  const modalRef = useRef(); // Import useRef from react and create a reference to modal div

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      // if the target of the click isn't the container nor a descendant of the container
      toggleModal();
    }
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="modal">
        <div className="overlay" onClick={() => console.log("clicked overlay")}></div>
        <div ref={modalRef} className="upgrade-modal-content">
          <IoCloseSharp onClick={toggleModal} className="close-modal text-5xl cursor-pointer" />
          <div className="flex flex-col items-center">
            <h2 className="text-xl font-bold text-center mb-3">Upgrade your plan 🚀</h2>

            <p className="text-center mb-3 mt-6">
              Nice work! It looks like you've been working hard to scale your business 👍 <br />
              You have reached the maximum number of employees for your current plan. Please upgrade
              to add more employees.
            </p>

            <p className="text-center mb-3 mt-6">
              Your current plan is <span className="font-bold ">{planType}</span>, which allows for{" "}
              {planType === "Growing" ? 8 : 0} employees. The next plan up is{" "}
              <span className="font-bold">{nextPlanType}</span>, which allows for{" "}
              {nextPlanType === "Growing" ? 8 : "30"} employees.
            </p>
            {/* <button
              disabled={loading}
              className="bg-gray-900 hover:text-yellow-100 text-yellow-50 p-2 rounded-md text-md  mt-6 mb-4 font-medium w-48 h-10 flex justify-center items-center"
              onClick={upgradePlan}
            >
              {loading ? (
                <ImSpinner className="animate-spin-slow" />
              ) : (
                <span>Change plan</span>
              )}
            </button> */}
            <NormalButton
              disabled={loading}
              loading={loading}
              text="Change plan"
              onClick={upgradePlan}
              additionalClasses="mt-6 mb-4"
              width="w-48"
            />
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { format, set } from "date-fns";
// import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import SubscriptionInvoiceRefundModal from "./SubscriptionInvoiceRefundModal";

export default function InvoiceHistory({ invoices, loading }) {
  // const invoices = [
  //   {
  //     date: { toDate: () => new Date() },
  //     amount: 1000,
  //     status: "paid",
  //     invoiceId: "1234",
  //   },
  //   {
  //     date: { toDate: () => new Date() },
  //     amount: 2000,
  //     status: "open",
  //     invoiceId: "1235",
  //   },
  //   {
  //     date: { toDate: () => new Date() },
  //     amount: 3000,
  //     status: "paid",
  //     invoiceId: "1236",
  //   },
  //   {
  //     date: { toDate: () => new Date() },
  //     amount: 4000,
  //     status: "open",
  //     invoiceId: "1237",
  //   },
  //   {
  //     date: { toDate: () => new Date() },
  //     amount: 5000,
  //     status: "paid",
  //     invoiceId: "1238",
  //   },
  //   {
  //     date: { toDate: () => new Date() },
  //     amount: 6000,
  //     status: "open",
  //     invoiceId: "1239",
  //   },
  //   {
  //     date: { toDate: () => new Date() },

  //     amount: 7000,

  //     status: "paid",
  //     invoiceId: "1240",
  //   },
  // ];

  const [invoicesDisplayed, setInvoicesDisplayed] = useState(invoices);
  useEffect(() => {
    setInvoicesDisplayed(invoices);
  }, [invoices]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [refundModal, setRefundModal] = useState(false);

  const handleClick = (event, invoice) => {
    // check if the invoice is paid
    if (invoice.status === "paid") {
      setAnchorEl(event.currentTarget);
      setSelectedInvoice(invoice);
    } else {
      return;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRefundClick = () => {
    setRefundModal(true);
    handleClose();
  };

  const sortedInvoices = useMemo(() => {
    if (invoicesDisplayed) {
      return [...invoicesDisplayed].sort((a, b) => b.date.toDate() - a.date.toDate());
    }
    return [];
  }, [invoicesDisplayed]);

  return (
    <div className="flex flex-col bg-white shadow-bold rounded-md ">
      <h2 className="font-bold text-lg px-4 py-4 pt-6 ">Invoices</h2>
      <div className="overflow-y-auto max-h-96">
        <table className="w-full text-sm">
          <thead className="border-b border-gray-200 bg-white">
            <tr>
              <th className="sticky top-0 px-6 py-3 text-left font-medium text-gray-500 bg-white">
                Date
              </th>
              <th className="sticky top-0  px-6 py-3 text-left font-medium text-gray-500 bg-white">
                Amount
              </th>
              <th className="sticky top-0 px-6 py-3 text-left font-medium text-gray-500 bg-white">
                Status
              </th>
              <th className="sticky top-0 px-6 py-3 text-left font-medium text-gray-500 bg-white">
                Invoice ID
              </th>
              <th className="sticky top-0 px-6 py-3 text-right font-medium text-gray-500 bg-white z-10"></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {loading ? (
              Array.from({ length: 2 }).map((_, index) => (
                <tr key={index}>
                  <td className="px-6 py-4">
                    <div className="h-4 bg-gray-200 rounded-full w-24 animate-pulse"></div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="h-4 bg-gray-200 rounded-full w-20 animate-pulse"></div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="h-4 bg-gray-200 rounded-full w-16 animate-pulse"></div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="h-4 bg-gray-200 rounded-full w-32 animate-pulse"></div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="h-4 bg-gray-200 rounded-full w-8 animate-pulse"></div>
                  </td>
                </tr>
              ))
            ) : sortedInvoices.length > 0 ? (
              sortedInvoices.map((invoice, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {format(invoice.date.toDate(), "MMM d, yyyy")}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    ${(invoice.amount / 100).toFixed(2)}
                  </td>
                  <td className="px-6 py-4 text-sm">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded ${
                        invoice.status === "paid"
                          ? "bg-green-100 text-green-800 border-green-200 border"
                          : invoice.status === "open"
                          ? "bg-yellow-100 text-yellow-800 border-yellow-200 border"
                          : "bg-red-100 text-red-800 border border-red-200"
                      }`}
                    >
                      {invoice.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">{invoice.invoiceId}</td>
                  <td className="px-6 py-4 text-right text-sm font-medium">
                    <button
                      className="rounded-full flex items-center text-gray-400 hover:text-gray-600"
                      onClick={(event) => handleClick(event, invoice)}
                      aria-label="Open options"
                    >
                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-sm text-gray-500">
                  No invoices to display
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={handleRefundClick}
          disableRipple
          sx={{
            fontFamily: "Inter var, system-ui, sans-serif",
            fontSize: "0.875rem",
            "&:hover": {
              backgroundColor: "#f3f4f6", // Tailwind's gray-100 color
            },
          }}
        >
          Refund payment
        </MenuItem>
      </Menu>

      <SubscriptionInvoiceRefundModal
        open={refundModal}
        onClose={() => setRefundModal(false)}
        invoice={selectedInvoice}
        setInvoices={setInvoicesDisplayed}
      />
    </div>
  );
}

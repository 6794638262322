import React, { useState } from "react";
import CardLayout from "layouts/CardLayout";
import { convertToJsDate, calculateEndDateString } from "utils/helpers";
import { utcToZonedTime, zonedTimeToUtc, format } from "date-fns-tz";
import { addYears } from "date-fns";
import PaymentMethodDisplay from "./PaymentMethodDisplay";
import ChangeServiceSubscriptionPaymentMethodModal from "./ChangeServiceSubscriptionPaymentMethod";
import UpcomingInvoiceDisplay from "./UpcomingInvoiceDisplay";

const frequencyMap = {
  monthly: "month",
  yearly: "year",
  quarterly: "quarter",
  "6 months": "6 months",
};
// {
//     businessId: businessId,
//     customer: customer,
//     template: null, // refer to AddTemplateModal.js for template details
//     startDate: new Date(),
//     soldBy: null,
//     tax: null, // {rate:12, name: "San Diego tax"}
//     visits: [], // { id: uuid(), month: 1, year: 2022, status: "unscheduled", jobId: null}
//     accept: {
//       signature: null,
//       dateAccepted: null,
//       type: "link", // default to sending a link to customer
//     },
//     createdAt: new Date(),
//     createdBy: null,
//     status: "draft", // probably want to change this to something else
//     history: [], //   {date: new Date().toISOString(), action: "created",  by: userData.userData.id, details: "Initial creation of subscription" }
//   }

const defaultTemplateDetails = {
  title: "",
  description: "",
  type: "indefinite", // default type
  duration: 1, // default duration, used if type is "fixed"
  visits: 2, // default number of visits // max of 12 per year
  amount: "0.00", // default amount
  paymentFrequency: "monthly", // default frequency
  allowOtherPayments: false, // default to false
  requireSignature: false, // default to false
  terms: null,
  // terms look like this when truthy
  //   {
  //     title: "",
  //     description: "",
  //   },
};

export default function SubscriptionDescription({
  subscription,
  setSubscription,
}) {
  // console.log(
  //   "subscription.stripeSubscription?.id subscriptionDescription",
  //   subscription.stripeSubscription?.id
  // );

  // console.log("subscription", subscription);
  let endDate = "Continues until canceled";

  if (subscription?.template?.type === "fixed") {
    // to calc end date we do duration times the frequecy so if duration is 12 months then it is 12 months after the start....
    // frequency is either monthly, quarterly, 6 months, yearly
    // "monthly", "quarterly", "6 months", "yearly"
    endDate = calculateEndDateString(subscription);
  }

  const formatDuration = (type, duration, startDate) => {
    const startDateJs = convertToJsDate(subscription.startDate || new Date());
    if (type === "fixed") {
      // calculate end date
      // let endDateString = new Date(startDateJs)
      // endDate.setFullYear(endDate.getFullYear() + duration);

      // or
      const endDate = addYears(startDateJs, duration);
      // then we add like 1 or 2 years to it
      return `${format(startDateJs, "MMMM do, yyyy")} - ${format(
        endDate,
        "MMMM do, yyyy"
      )} (${duration} ${duration > 1 ? "years" : "year"})`;
    }
    return `${format(startDateJs, "MMMM do, yyyy")} - until canceled`;
  };

  const formatStatus = (statusOriginal) => {
    let status = statusOriginal;
    // console.log("status", status);
    if (!status) return null;
    if (status === "trialing") status = "active"; // we will treat trialing as active
    const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1);
    let statusColor = "";
    let backgroundColor = "";
    let textColor = "text-gray-600";
    let borderColor = "border-gray-100";
    switch (status) {
      case "active":
        statusColor = "bg-green-400";
        backgroundColor = "bg-green-100";
        textColor = "text-green-600";
        borderColor = "border-green-200";
        break;
      case "canceled":
        statusColor = "bg-gray-400";
        backgroundColor = "bg-gray-100";
        textColor = "text-gray-600";
        borderColor = "border-gray-200";
        break;
      case "draft":
        statusColor = "bg-yellow-400";
        backgroundColor = "bg-gray-100";
        textColor = "text-yellow-600";
        borderColor = "border-yellow-200";
        break;
      case "pending":
        statusColor = "bg-yellow-400";
        backgroundColor = "bg-yellow-100";
        textColor = "text-yellow-600";
        borderColor = "border-yellow-200";
        break;
      case "past_due":
        statusColor = "bg-red-400";
        backgroundColor = "bg-red-100";
        textColor = "text-red-600";
        borderColor = "border-red-200";
        break;
      case "sent":
        backgroundColor = "bg-gray-100";
        textColor = "text-gray-600";
        borderColor = "border-green-200";
        statusColor = "bg-gray-400";
        break;
      default:
        statusColor = "bg-gray-400";
        backgroundColor = "bg-gray-100";
        textColor = "text-gray-600";
        borderColor = "border-gray-200";
        break;
    }
    // if status is active then we have a green dot next to the status if not we will just render a gray dot for now
    // const statusColor = status === "active" ? "bg-green-400" : "bg-gray-500";
    // const backgroundColor = status === "active" ? "" : "bg-yellow-100";
    return (
      <div
        className={`flex flex-row items-center ${backgroundColor} rounded-md px-2 py-1 border ${borderColor}`}
      >
        <h1 className={`${textColor}`}>{capitalizedStatus}</h1>
        <div className={`h-2 w-2 rounded ml-2 ${statusColor}`}></div>
      </div>
    );
  };

  const [changePaymentMethodModal, setChangePaymentMethodModal] =
    useState(false);

  const calculateTax = (amount, rate) => {
    return (parseFloat(amount) * rate) / 100;
  };
  return (
    <CardLayout>
      <div className="flex flex-col text-gray-600">
        {/* Full-width header section */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center -mt-6 px-4 py-8 bg-gray-50 -mx-4 rounded-t-md relative">
          <div className="absolute top-2 right-4 text-gray-500 text-xs">
            {formatStatus(subscription?.status)}
          </div>
          {/* Title and description */}
          <div className="flex flex-col pr-4 mb-4 sm:mb-0">
            <h2 className="text-gray-600 font-bold text-xl">
              {subscription?.template?.title}
            </h2>
            <p className="text-gray-500">
              {subscription?.template?.description}
            </p>
          </div>

          {/* Price and payment frequency */}
          {/* <div className="flex flex-row items-center">
            <div className="font-medium text-3xl">
              ${parseFloat(subscription?.template?.amount).toFixed(2)}
            </div>
            <div className="flex flex-col ml-2 text-gray-500">
              <span className="leading-none">per</span>
              <span className="leading-none">
                {frequencyMap[subscription?.template?.paymentFrequency]}
              </span>
            </div>
          </div> */}

          <div className="flex flex-col items-end relative">
            <div className="flex flex-row items-center">
              <div className="font-medium text-3xl">
                ${parseFloat(subscription?.template?.amount).toFixed(2)}
              </div>
              <div className="flex flex-col ml-2 text-gray-500">
                <span className="leading-none">per</span>
                <span className="leading-none">
                  {frequencyMap[subscription?.template?.paymentFrequency]}
                </span>
              </div>
            </div>
            {subscription?.tax && (
              <div className="absolute top-full left-0 right-0 text-xs text-gray-400 mt-1 flex items-center">
                <span className="whitespace-nowrap">
                  {`+ $${calculateTax(
                    subscription?.template?.amount,
                    subscription.tax.rate
                  ).toFixed(2)}`}
                </span>
                <span className="ml-1 truncate">{subscription.tax.name}</span>
                <span className="ml-1 whitespace-nowrap">
                  {`(${subscription.tax.rate}%)`}
                </span>
              </div>
            )}
          </div>
        </div>

        {/* Divider */}
        <hr className="border-gray-300 -mx-4" />

        {/* Two-column layout for details */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
          {/* Left column */}
          <div className="space-y-2">
            <div>
              <label className="font-semibold block mb-1">Visits</label>
              <div className="text-gray-500">
                {subscription?.template?.visits} visits per year
              </div>
            </div>

            <div>
              <label className="font-semibold block mb-1">Duration</label>
              <div className="text-gray-500">
                {formatDuration(
                  subscription?.template?.type,
                  subscription?.template?.duration,
                  subscription.startDate
                )}
              </div>
            </div>

            {subscription?.soldBy && (
              <div>
                <label className="font-semibold block mb-1">Sold by</label>
                <div className="text-gray-500">
                  {subscription?.soldBy.firstName}{" "}
                  {subscription?.soldBy?.lastName}
                </div>
              </div>
            )}

            <SubscriptionSignatureDetails subscription={subscription} />
          </div>

          {/* Right column */}
          <div className="space-y-2">
            {subscription?.stripeSubscription && (
              <>
                <PaymentMethodDisplay
                  stripeSubscription={subscription.stripeSubscription}
                  stripeAccountId={subscription.stripeAccountId}
                  onChangePaymentMethod={() =>
                    setChangePaymentMethodModal(true)
                  }
                />
                <UpcomingInvoiceDisplay
                  stripeSubscriptionId={subscription.stripeSubscription.id}
                  stripeAccountId={subscription.stripeAccountId}
                  labelClassName="font-semibold text-sm sm:text-base"
                  contentClassName="text-gray-500 text-sm sm:text-base"
                />
              </>
            )}
            {/* You can add more details here in the future */}
          </div>
        </div>
      </div>

      {/* Change payment method modal */}
      <ChangeServiceSubscriptionPaymentMethodModal
        open={changePaymentMethodModal}
        onClose={() => setChangePaymentMethodModal(false)}
        stripeSubscription={subscription.stripeSubscription}
        onSuccess={(updatedStripeSubscription) =>
          setSubscription((prev) => ({
            ...prev,
            stripeSubscription: updatedStripeSubscription,
          }))
        }
      />
    </CardLayout>
  );
}

const SubscriptionSignatureDetails = ({ subscription }) => {
  const [imageError, setImageError] = useState(false);

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return format(date, "MMMM d, yyyy 'at' h:mm a");
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };

  return (
    subscription?.template?.requireSignature && (
      <div className="mt-4">
        <label className="font-semibold">Signature</label>
        <div className="flex flex-col sm:flex-row justify-start items-start sm:items-center mt-1">
          <div className="text-gray-500">Required</div>
          {subscription?.accept?.signature && (
            <div className="flex flex-col sm:flex-row items-start sm:items-center mt-2 ml-4 sm:mt-0">
              {!imageError ? (
                <img
                  src={subscription.accept.signature}
                  alt="Signature"
                  className="w-24 h-12 object-contain mt-2 sm:mt-0"
                  onError={() => setImageError(true)}
                />
              ) : (
                <div className="text-red-500">Image not available</div>
              )}
              <div className="text-gray-500 ml-2">
                Signed on {formatDate(subscription.accept.dateAccepted)}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

import React, { useState, useEffect } from "react";
// import "./index.css";
import {
  Route,
  Routes,
  Navigate,
  Router,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useUserContext } from "context/UserContext";
import GeneralSnackBar from "components/reusable/GeneralSnackBar";
import { useBannerVisibility } from "utils/userData";
import theme from "theme/ThemeMUI";
import { ThemeProvider } from "@mui/material/styles";

// Pages
import Login from "./features/Auth/Login";
import Register from "./features/Auth/Registration";
import Home from "./features/Home/Home";
import Success from "./features/Payments/Success";
import Refresh from "./features/Payments/Refresh";
import Invoice from "./features/Payments/Invoice";
import Schedule from "./features/Schedule/Schedule";
import AddJob from "./features/AddJob/AddJob";
import Customers from "./features/Customers/Customers";
import CustomerDetails from "./features/Customers/CustomerDetails";
import JobDetails from "./features/JobDetails/JobDetails";
import Settings from "./features/Settings/Settings";
import CompanyProfile from "./features/Settings/CompanyProfile";
import Employees from "./features/Settings/Employees";
import Homebase360Payments from "./features/Payments/Payments";
import Billing from "./features/Billing/Billing";
import EmployeeDetails from "./features/Settings/EmployeeDetails";
import Messages from "./features/Messages/Messages";
import Conversations from "./features/Messages/Conversations";
import NavBar from "components/other/NavBar";
// import CustomersImport from "./features/Customers/components/CustomersImport/CustomersImportModal";

import NewEmployee from "./features/Settings/NewEmployee";
import NewEstimate from "./features/Estimate/NewEstimate";

import UpdateSubscription from "./features/Billing/UpdateSubscription";
import NewSubscription from "./features/Billing/NewSubscription";
import SubscriptionSuccess from "./features/Billing/SubscriptionSuccess";
import NewTrial from "features/Billing/NewTrial";
import NewTrialSuccess from "features/Billing/NewTrialSuccess";

import { ProtectedRoutes } from "components/other/ProtectedRoutes";
import ConnectBanner from "components/other/ConnectBanner";
import TimeLeftBanner from "components/other/TimeLeftBanner";
import Reviews from "./pages/Reviews";
import DynamicReviewLink from "./features/JobDetails/DynamicReviewLink";
import Reporting from "./features/Reporting/Reporting";
// import Customizations from "./pages/MessageCustomizations";
import TerritoryMap from "./pages/TerritoryMap";
import DLCBanner from "components/10DLC/DLCBanner";
import DLCInfoPage from "./features/Settings/DLCInfoPage";
import EstimateDetails from "./features/Estimate/EstimateDetails";
// import ApproveEstimate from "./pages/ApproveEstimate";

import MessageCustomizations from "./features/SettingsCustomizations/MessageCustomizations";
import TermsCustomizations from "./features/SettingsCustomizations/TermsCustomizations";
import EstimateCustomizations from "./features/SettingsCustomizations/EstimateCustomizations";
import InvoiceCustomizations from "./features/SettingsCustomizations/InvoiceCustomizations";
import JobRemindersCustomizations from "./features/SettingsCustomizations/JobRemindersCustomizations";

import SidebarLayout from "layouts/SidebarLayout";
import ChangeSubscription from "./features/Billing/ChangeSubscription";

import Games from "./features/Games/Games";
import Leaderboard from "./features/Games/Leaderboard";

import SubscriptionDetails from "./features/ServiceSubscription/ServiceSubscriptionDetails";
import AcceptServiceSubscription from "./features/ServiceSubscription/AcceptServiceSubscription";
import SalesRegistration from "./pages/SalesRegistration";
import { PriceBook } from "./features/PriceBook/PriceBook";
import { Category } from "./features/PriceBook/Category";

import ServiceSubscriptionCustomizations from "./features/SettingsCustomizations/ServiceSubscriptionCustomizations";

const App = () => {
  const { userData, bannerVisible } = useUserContext();
  const location = useLocation();
  const {
    hideNavBar,
    stripeConnectStatus,
    freeTrialTimeLeft,
    dlcBannerShouldBeVisible,
  } = useBannerVisibility(userData, location);

  return (
    <ThemeProvider theme={theme}>
      <>
        {!hideNavBar && bannerVisible && (
          <>
            <TimeLeftBanner timeLeft={freeTrialTimeLeft} />
          </>
        )}
        {!hideNavBar && <NavBar />}

        {!hideNavBar && stripeConnectStatus && (
          <ConnectBanner status={stripeConnectStatus} />
        )}

        {/*DLC banner will be visible only for admins and if they have not input the necessary information*/}
        {!hideNavBar && dlcBannerShouldBeVisible && <DLCBanner />}

        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/addjob" element={<AddJob />} />
            <Route path="/newestimate" element={<NewEstimate />} />
            <Route path="/customers" element={<Customers />} />
            <Route
              path="/customers/:customerId"
              element={<CustomerDetails />}
            />
            <Route path="/jobdetails/:jobId" element={<JobDetails />} />
            <Route
              path="/estimates/:estimateId"
              element={<EstimateDetails />}
            />
            <Route
              path="/servicesubscription/:subscriptionId"
              element={<SubscriptionDetails />}
            />

            <Route path="/settings" element={<Settings />} />
            <Route
              path="/settings/companyprofile"
              element={<CompanyProfile />}
            />
            <Route path="/settings/employees" element={<Employees />} />
            <Route
              path="/settings/homebase360payments"
              element={<Homebase360Payments />}
            />
            <Route path="/settings/billing" element={<Billing />} />
            <Route
              path="/settings/employees/:employeeId"
              element={<EmployeeDetails />}
            />
            <Route path="/settings/newemployee" element={<NewEmployee />} />
            <Route path="/messages/:messagesId" element={<Messages />} />
            <Route path="/messages" element={<Conversations />} />
            <Route path="/success" element={<Success />} />
            <Route path="/refresh" element={<Refresh />} />
            <Route path="/new-subscription" element={<NewSubscription />} />
            <Route
              path="/change-subscription"
              element={<ChangeSubscription />}
            />
            <Route
              path="/update-subscription"
              element={<UpdateSubscription />}
            />
            <Route
              path="/subscription-success"
              element={<SubscriptionSuccess />}
            />
            <Route path="/new-trial" element={<NewTrial />} />
            <Route path="/new-trial-success" element={<NewTrialSuccess />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/reporting" element={<Reporting />} />

            <Route path="/settings/customizations" element={<SidebarLayout />}>
              {/* Default or index route for "/settings/customizations" */}

              {/* Nested routes */}
              <Route path="notifications" element={<MessageCustomizations />} />
              <Route path="terms" element={<TermsCustomizations />} />
              <Route path="estimates" element={<EstimateCustomizations />} />
              <Route path="invoices" element={<InvoiceCustomizations />} />
              <Route
                path="jobreminders"
                element={<JobRemindersCustomizations />}
              />
              <Route path="price-book" element={<PriceBook />} />
              <Route path="price-book/:categoryId" element={<Category />} />
              <Route
                path="service-subscriptions"
                element={<ServiceSubscriptionCustomizations />}
              />
            </Route>

            <Route path="/territorymap" element={<TerritoryMap />} />
            <Route path="/settings/DLCInfo" element={<DLCInfoPage />} />
          </Route>

          {/* <Route path="/view-estimate" element={<ApproveEstimate />} /> */}
          <Route path="/invoice" element={<Invoice />} />
          <Route
            path="/service-subscription"
            element={<AcceptServiceSubscription />}
          />
          <Route path="/register" element={<Register />} />
          <Route path="/register-reactivate" element={<SalesRegistration />} />

          <Route path="/login" element={<Login />} />
          <Route path="/review" element={<DynamicReviewLink />} />
          <Route path="/games" element={<Games />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
        </Routes>
        <GeneralSnackBar />
      </>
    </ThemeProvider>
  );
};

export default App;

import React, { useState, useEffect } from "react";
import { useUserContext } from "context/UserContext";
import { db } from "../../utils/firebase";
import { doc, getDoc, getDocs, updateDoc, collection } from "firebase/firestore";
import { useSnackBar } from "../../context/SnackBarContext";
import DisabledButton from "components/buttons/DisabledButton";
import InvoicePreview from "components/customizeInvoices/InvoicePreview";
import InvoiceOptions from "components/customizeInvoices/InvoiceOptions";

export default function InvoiceCustomizations() {
  const { userData, setUserData, subData, bannerVisible } = useUserContext();
  const { openSnackBar } = useSnackBar();
  // Permissions //////////////////////////////////////////////////
  const notAbleToViewCompanyInfo =
    userData?.userData?.customPermissions?.view_company_info === false;
  const notAbleToEditCompanyInfo =
    !userData?.userData?.isAdmin && !userData?.userData?.customPermissions?.edit_company_info;

  // State //////////////////////////////////////////////////////
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoiceSettings, setInvoiceSettings] = useState(
    userData?.bizData?.invoiceSettings || {
      logo: userData?.bizData?.logo ? true : false,
      invoiceNumber: true,
      // invoiceServiceDate: true,
      // invoiceDueDate: true,
      customerDisplayName: true,
      customerAddress: true,
      customerEmail: true,
      customerPhone: true,
      businessName: true,
      businessPhone: true,
      businessEmail: true,
      businessAddress: true,
      lineItemsDescription: true,
      lineItemsQuantity: true,
      lineItemsUnitPrice: true,
      lineItemsAmount: true,
      subtotal: true,
      tax: true,
      footerBusinessName: true,
      footerWebsite: true,
      savePaymentMethod: false,
    }
  );

  // keep in sync with the database
  useEffect(() => {
    setInvoiceSettings(
      userData?.bizData?.invoiceSettings || {
        logo: userData?.bizData?.logo ? true : false,
        invoiceNumber: true,
        // invoiceServiceDate: true,
        // invoiceDueDate: true,
        customerDisplayName: true,
        customerAddress: true,
        customerEmail: true,
        customerPhone: true,
        businessName: true,
        businessPhone: true,
        businessEmail: true,
        businessAddress: true,
        lineItemsDescription: true,
        lineItemsQuantity: true,
        lineItemsUnitPrice: true,
        lineItemsAmount: true,
        subtotal: true,
        tax: true,
        footerBusinessName: true,
        footerBusinessWebsite: true,
        savePaymentMethod: false,
      }
    );
  }, [userData?.bizData?.invoiceSettings]);
  // Check dirty
  useEffect(() => {
    if (JSON.stringify(invoiceSettings) !== JSON.stringify(userData?.bizData?.invoiceSettings)) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [invoiceSettings, userData?.bizData?.invoiceSettings]);

  const handleSave = async () => {
    if (notAbleToEditCompanyInfo) {
      openSnackBar("You do not have permission to edit company info");
      return;
    }
    setLoading(true);

    try {
      // Update the terms in the Firestore
      const bizRef = doc(db, "businesses", userData.bizData.id);
      await updateDoc(bizRef, { invoiceSettings: invoiceSettings });
      setUserData({
        ...userData,
        bizData: { ...userData.bizData, invoiceSettings },
      });
      openSnackBar("Invoice settings updated", true);
    } catch (error) {
      openSnackBar("Failed to update");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`bg-gray-100 ${
        bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
      }  overflow-y-auto pb-36`}
    >
      <div className="flex items-center justify-center">
        <div className="flex flex-row w-full md:justify-center justify-between items-center  mt-6 mb-6  ml-4">
          <h1 className="text-3xl md:w-3/4 max-w-2xl font-extrabold text-gray-900 ">
            Invoice Settings
          </h1>

          <div className="md:w-1/4 ">
            <DisabledButton
              disabled={!dirty}
              loading={loading}
              onClick={handleSave}
              text="Save"
              width="w-16"
              additionalClasses="mr-8"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-start mt-3 mb-5 px-4">
        <div className="md:w-3/4 w-full max-w-2xl mb-4 md:mb-0">
          <InvoicePreview userData={userData} invoiceSettings={invoiceSettings} />
        </div>
        <div className="md:w-1/4 w-full md:ml-4">
          <InvoiceOptions
            invoiceSettings={invoiceSettings}
            setInvoiceSettings={setInvoiceSettings}
          />
        </div>
      </div>
    </div>
  );
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth, signOut, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

import Cookies from "universal-cookie";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
const analytics = getAnalytics(app);
export const functions = getFunctions(app);

// Connect to local emulator if REACT_APP_USE_LOCAL_FUNCTIONS is set to 'true'
if (process.env.REACT_APP_USE_LOCAL_FUNCTIONS === "true") {
  connectFunctionsEmulator(functions, "localhost", 5001);
  console.log("Connected to local Functions emulator");
}

// some helpful functions

// this adds a new value to the user doc
export async function addValueToUserDoc(userId, key, value) {
  const docRef = doc(db, "users", userId);
  try {
    await setDoc(
      docRef,
      {
        [key]: value,
      },
      { merge: true }
    );
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.log(error.message);
  }
}

export async function SignOutUser({ setUserData }) {
  try {
    await signOut(auth);

    // Clear the customToken cookie
    const cookies = new Cookies();
    const domain =
      window.location.hostname === "localhost"
        ? "localhost"
        : ".homebase360.io";
    cookies.remove("customToken3498203423253098", {
      domain,
      path: "/",
    });

    console.log(
      "User signed out and customToken cookie removed and clearing user data"
    );
    setUserData(null);
  } catch (error) {
    console.error("Error signing out user:", error);
  }
}

export async function SignInUser(email, password) {
  try {
    const userCred = await signInWithEmailAndPassword(auth, email, password);
    console.log("user logged in, user cred: ", userCred);
  } catch (error) {
    console.log(error.message);
  }
}

import React, { useState } from "react";
import InputWithLabel from "../../AddJob/components/InputWithLabel";
import { GrNotes } from "react-icons/gr";
import { AiOutlineEdit } from "react-icons/ai";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import HorizontalScrollImages from "components/reusable/HorizontalScrollImages";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxWidth: "90%",
  maxHeight: "90%",
  boxShadow: 24,
  outline: "none",
  borderRadius: "8px",
};

export default function NoteJobDetails({ note, toggleNoteModal, voiceMemo, images }) {
  const [open, setOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");

  const handleOpen = (imgSrc) => {
    console.log(imgSrc);
    setSelectedImg(imgSrc);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  return (
    <div className="flex flex-col flex-1 w-full lg:col-span-2 bg-white rounded-md shadow-bold  gap-2 mb-8 pb-8 p-4">
      <div className="flex flex-row items-center justify-between gap-2 mt-2 mb-3">
        <div className="flex flex-row">
          <GrNotes className="inline-block text-2xl mr-2" />
          <h1 className="text-xl font-bold">Private Notes</h1>
        </div>
        <AiOutlineEdit onClick={toggleNoteModal} className="text-lg cursor-pointer ml-2" />
      </div>
      <div
        className="text-gray-900 px-2 rounded-md bg-gray-100 shadow-md py-4 flex flex-wrap whitespace-pre-wrap flex-shrink-0"
        style={{
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
          minHeight: "50px",
        }}
      >
        {note}
      </div>
      {voiceMemo && (
        <audio controls src={voiceMemo}>
          Your browser does not support the audio element.
        </audio>
      )}

      {images && images.length > 0 && (
        <HorizontalScrollImages images={images} handleClick={handleOpen} />
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
      >
        <Box sx={style}>
          <img
            src={selectedImg}
            alt="Larger view"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              borderRadius: "8px",
            }}
          />
        </Box>
      </Modal>
    </div>
  );
}

import React, { useState } from "react";
import { format } from "date-fns";
import ReceiptModal from "./ReceiptModal";
import EditPaymentFromHistoryModal from "./EditPaymentFromHistoryModal";
import { convertToJsDateNano } from "utils/helpers";

export default function PaymentHistory({
  jobDetails,
  handleRefund,
  setJobDetails,
}) {
  const [receiptModal, setReceiptModal] = useState(false);
  const [paymentForReceipt, setPaymentForReceipt] = useState(null);

  const [editPaymentModal, setEditPaymentModal] = useState(false);
  const [paymentForEdit, setPaymentForEdit] = useState({});

  // console.log("Payment history from ph component", jobDetails.paymentHistory);

  const handleOpenEditPaymentModal = (payment) => {
    setPaymentForEdit(payment);
    setEditPaymentModal(true);
  };

  const onCloseEditPaymentModal = () => {
    setEditPaymentModal(false);
  };
  return (
    <div className="flex flex-col rounded-md shadow-bold bg-white py-6 px-4 gap-2 mt-2 lg:mt-0 mb-2">
      <ReceiptModal
        paymentAmount={paymentForReceipt}
        setModal={setReceiptModal}
        modal={receiptModal}
        jobDetails={jobDetails}
      />
      <EditPaymentFromHistoryModal
        open={editPaymentModal}
        onClose={onCloseEditPaymentModal}
        payment={paymentForEdit}
        setJobDetails={setJobDetails}
        jobDetails={jobDetails}
      />
      <h1 className="text-xl font-bold text-gray-900">Payment History </h1>
      {jobDetails &&
      jobDetails.paymentHistory &&
      jobDetails.paymentHistory.length > 0 ? (
        jobDetails.paymentHistory
          .slice()
          .sort((a, b) => {
            const aDate =
              a.date instanceof Date
                ? a.date.getTime()
                : (a.date?.seconds || 0) * 1000;
            const bDate =
              b.date instanceof Date
                ? b.date.getTime()
                : (b.date?.seconds || 0) * 1000;
            return aDate - bDate;
          })
          .map((payment, key) => (
            <React.Fragment key={key}>
              <div className="flex flex-row justify-between items-center mt-4">
                <h1 className="text-sm  text-gray-900">
                  {convertToJsDateNano(payment.date) &&
                    format(
                      convertToJsDateNano(payment.date),
                      "EEE MM/dd/yyyy, hh:mm aa"
                    )}
                  {/* {payment.date && payment.date.seconds
                    ? format(
                        new Date(payment.date.seconds * 1000),
                        "MM/dd/yyyy, hh:mm aa"
                      )
                    : payment.date instanceof Date &&
                      format(payment.date, "MM/dd/yyyy, hh:mm aa")} */}
                </h1>
                {payment.paymentIntentId &&
                  payment.status === "succeeded" &&
                  payment.billingType !== "Refund" && (
                    <button
                      className="text-xs border border-gra-500 text-gray-600 hover:shadow-md hover:text-red-500 rounded-md p-1"
                      onClick={() => handleRefund(payment)}
                    >
                      Refund
                    </button>
                  )}
                {!payment.paymentIntentId && (
                  <button
                    className="text-xs border border-gra-500 text-gray-600 hover:shadow-md hover:text-gray-400 rounded-md p-1"
                    onClick={() => handleOpenEditPaymentModal(payment)}
                  >
                    Edit
                  </button>
                )}
              </div>
              <div className="flex flex-row justify-between items-center">
                <h1 className=" font-semibold text-gray-900 ">Status</h1>
                <h1 className=" font-semibold text-gray-700 ">
                  {payment.paymentIntentId &&
                  payment.status === "succeeded" &&
                  payment.billingType !== "Refund" ? (
                    <span className="text-green-600">
                      {payment.status.slice(0, 1).toUpperCase() +
                        payment.status.slice(1)}
                    </span>
                  ) : (
                    <span>
                      {payment.status.slice(0, 1).toUpperCase() +
                        payment.status.slice(1)}
                    </span>
                  )}
                </h1>
              </div>
              <div className="flex flex-row justify-between items-center">
                <h1 className=" font-semibold text-gray-900 ">Type</h1>
                <h1 className="  text-gray-700">
                  {payment.billingType === "Refund" ? (
                    <span className="text-red-500 font-semibold">Refund</span>
                  ) : (
                    payment.billingType.slice(0, 1).toUpperCase() +
                    payment.billingType.slice(1)
                  )}
                </h1>
              </div>
              {payment.paymentNote && (
                <div className="flex flex-row justify-between items-center">
                  <h1 className=" font-semibold text-gray-900 ">Note</h1>
                  <h1 className=" text-gray-700 ">{payment.paymentNote}</h1>
                </div>
              )}
              {payment?.tip > 0 && (
                <div className="flex flex-row justify-between items-center">
                  <h1 className=" font-semibold text-gray-900 ">Tip</h1>
                  <h1 className=" font-semibold text-gray-700 ">
                    (${(payment.tip / 100).toFixed(2)})
                  </h1>
                </div>
              )}

              {payment?.tax > 0 && (
                <div className="flex flex-row justify-between items-center">
                  <h1 className=" font-semibold text-gray-900 ">Tax</h1>
                  <h1 className=" font-semibold text-gray-700 ">
                    (${(payment.tax / 100).toFixed(2)})
                  </h1>
                </div>
              )}

              <div className="flex flex-row justify-between items-center">
                <h1 className=" font-semibold text-gray-900 ">Amount</h1>
                <h1 className=" font-semibold text-gray-700 ">
                  ${(payment.totalAmountFromStripe / 100).toFixed(2)}
                </h1>
              </div>

              {payment.status !== "card_declined" &&
                payment.billingType !== "Refund" && (
                  <div className="flex flex-start items-start">
                    <button
                      className="text-xs bg-gray-100 shadow-md text-gray-600 hover:text-gray-900 rounded-md p-1"
                      onClick={() => {
                        setReceiptModal(true);
                        setPaymentForReceipt(payment.totalAmountFromStripe);
                      }}
                    >
                      Send Receipt
                    </button>
                  </div>
                )}

              <hr className="border border-gray-300" />
            </React.Fragment>
          ))
      ) : (
        <div className="flex flex-row justify-between items-center">
          <h1 className="text-lg font-bold text-gray-900 pt-2">Total</h1>
          <h1 className="text-lg font-bold text-gray-900">$0.00</h1>
        </div>
      )}
    </div>
  );
}

// calcTotals.js

// used in manual payment modal to generate totals, should also be used in the invoice page
// and should be same function that is used in mobile app and when generating invoice and receipt... this is effectively our source of truth for the total amounts... since we want line items to be the source of truth

export const calcTotalsLineItems = (lineItems) => {
  if (!lineItems || !lineItems.length) {
    return {
      totalWithoutTax: 0,
      tax: 0,
      total: 0,
      totalWithoutTaxCents: 0,
      taxCents: 0,
      totalCents: 0,
    };
  }

  const totalWithoutTax = lineItems.reduce((acc, item) => {
    const unitPrice = parseFloat(item.unitPrice);
    const quantity = parseFloat(item.quantity);
    const lineItemAmount = unitPrice * quantity;
    return acc + lineItemAmount;
  }, 0);

  const totalTax = lineItems.reduce((acc, item) => {
    const unitPrice = parseFloat(item.unitPrice);
    const quantity = parseFloat(item.quantity);
    const itemSubtotal = unitPrice * quantity;

    const taxRate = parseFloat(item.taxRate?.rate) || 0;
    const itemTax = itemSubtotal * (taxRate / 100);
    return acc + itemTax;
  }, 0);

  // i want to return these limited to 2 decimal places.. before we were using .toFixed(2) but that returns a string so maybe we should use Math.round() instead? or is there another way to just truncate it, because we haven't been rounding it anywhere else so I'd like to stay consistent...

  // do we want to change everything to using Math.round??

  const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;
  const truncateToTwoDecimals = (num) => parseInt(num * 100) / 100;

  const convertToCents = (num) => Math.round(num * 100);
  const truncateToCents = (num) => parseInt(num * 100);

  return {
    totalWithoutTax: truncateToTwoDecimals(totalWithoutTax),
    tax: truncateToTwoDecimals(totalTax),
    total: truncateToTwoDecimals(totalWithoutTax + totalTax),
    totalWithoutTaxCents: truncateToCents(totalWithoutTax),
    taxCents: truncateToCents(totalTax),
    totalCents: truncateToCents(totalWithoutTax + totalTax),
  };
};

export const convertToCents = (num) => {
  if (!num) {
    return 0;
  }

  const parsedNum = parseFloat(num) || 0;

  const truncatedCents = parseInt(parsedNum * 100);
  const roundedCents = Math.round(parsedNum * 100);

  return truncatedCents;
}; // returns a number -- truncated

// converts to a number or dollars from cents
export const convertToDollars = (num) => {
  if (!num) {
    return 0;
  }

  const truncatedNum = parseInt(num) || 0; // we are truncating it to an integer

  const parsedNum = parseFloat(num) || 0; // we are parsing it to a float
  const roundedNum = Math.round(parsedNum);

  const formattedDollars = truncatedNum / 100; // returns as a number
  // to switch we just replace truncatedNum with roundedNum

  return formattedDollars;
}; // returns a number -- truncated

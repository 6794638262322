import { Bar } from "react-chartjs-2";
function TopSalesrep({ chartData }) {
  function formatDate(date) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[parseInt(date, 10) - 1];
  }

  const currentMonthIndex = new Date().getMonth();

  const monthToDisplay = formatDate(currentMonthIndex + 1);
  let salesPersonData = [];

  const chartDataPrep = chartData[chartData.length - 1];
  // console.log("chartDataPrep", chartDataPrep);
  // we need to make sure that the chartDataPrep object date is equal to the current month if not we will keep the salesPersonData array empty
  if (
    chartDataPrep?.date &&
    currentMonthIndex + 1 === parseInt(chartDataPrep.date.slice(5), 10)
  ) {
    Object.keys(chartDataPrep).forEach((key, index) => {
      if (key !== "date" && key !== "totalJobs" && key !== "revenue") {
        salesPersonData.push({ name: key, revenue: chartDataPrep[key] });
      }
    });
  }
  // console.log("salesPersonData", salesPersonData);

  let chartDataFormatted = null;

  if (salesPersonData.length > 0) {
    chartDataFormatted = {
      labels: salesPersonData.map((data) => data.name),
      datasets: [
        {
          label: "Sales for",
          data: salesPersonData.map((data) => data.revenue),
          backgroundColor: [
            "rgba(28, 99, 191, 0.6)",
            "rgba(218, 228, 123, 0.6)",

            "rgba(0, 0, 0, 0.6)",
            "rgba(123, 211, 212, 0.6)",
          ],
          borderColor: "black",
          borderWidth: 2,
          scales: {
            x: {
              stacked: true,
            },
          },
        },
      ],
    };
  } else {
    chartDataFormatted = {
      labels: ["No sales yet"],
      datasets: [
        {
          label: "Sales for",
          data: [0],

          backgroundColor: [
            "rgba(28, 99, 191, 0.6)",
            "rgba(218, 228, 123, 0.6)",

            "rgba(0, 0, 0, 0.6)",
            "rgba(123, 211, 212, 0.6)",
          ],

          scales: {
            x: {
              stacked: true,
            },
          },
        },
      ],
    };
  }
  return (
    <>
      {chartDataFormatted && (
        <Bar
          data={chartDataFormatted}
          options={{
            plugins: {
              title: {
                display: true,
                text: `Top Salespeople for ${monthToDisplay}`,
                font: {
                  size: 16,
                },
                color: "#1f2937",
              },
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || "";
                    if (label) {
                      label += ": ";
                    }
                    let value = context.parsed.y;
                    value = parseFloat(value).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    });
                    label += `$${value}`;
                    return label;
                  },
                },
              },
            },
            scales: {
              x: {
                grid: {
                  color: "rgba(255, 255, 255, 0.3)", // Color of the vertical grid lines
                },
                ticks: {
                  color: "#1f2937", // Color of the x-axis labels
                },
              },
              y: {
                min: 0,
                beginAtZero: true,
                grid: {
                  color: "rgba(255, 255, 255, 0.3)", // Color of the horizontal grid lines
                },
                ticks: {
                  color: "#1f2937", // Color of the y-axis labels
                  callback: function (value, index, values) {
                    return `$${value.toLocaleString()}`; // This adds the $ before the value
                  },
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      )}
    </>
  );
}

export default TopSalesrep;

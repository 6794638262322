export const EmptyCategoriesView = ({ setNewCategoryModalOpen }) => {
  return (
    <div className="relative">
      {/* Skeleton UI */}
      <div className="animate-pulse">
        <div className="bg-gray-200 rounded h-16 my-2"></div>
        <div className="bg-gray-200 rounded h-16 my-2"></div>
        <div className="bg-gray-200 rounded h-16 my-2"></div>
        <div className="bg-gray-200 rounded h-16 my-2"></div>
        <div className="bg-gray-200/60 rounded h-16 my-2"></div>
        <div className="bg-gray-200/30 rounded h-16 my-2"></div>
        <div className="bg-gray-200/20 rounded h-16 my-2"></div>
      </div>
      {/* Overlay Message */}
      <div className="absolute top-28 flex items-center justify-center w-full">
        <div className="bg-white p-4 rounded-md text-center shadow-lg w-96">
          <h1 className="text-xl font-semibold text-gray-900 ">
            Start by creating a {/* MF: underline-effect ? */}
            <button
              className="font-bold underline-effect hover:cursor-pointer underline"
              onClick={() => setNewCategoryModalOpen(true)}
              // onClick={() => navigate("/customers")}
            >
              new category
            </button>
            !
          </h1>
          <p className="text-gray-700">
            This allows you to save different line items, so you can add them quickly when creating
            jobs or estimates.
          </p>
        </div>
      </div>
    </div>
  );
};

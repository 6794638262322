import React, { useEffect, useState, useContext } from "react";

import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import { BiCheckCircle, BiLoader } from "react-icons/bi";
import { BsToggleOn, BsToggleOff, BsFillCheckCircleFill } from "react-icons/bs";
import { node } from "constants/constants";
import {
  getDoc,
  doc,
  updateDoc,
  serverTimestamp,
  arrayUnion,
} from "firebase/firestore";
import { useUserContext } from "context/UserContext";
import { db, auth } from "utils/firebase";
import { findPriceId, findPriceIdNew } from "utils/findPriceId";
import { findPrice } from "utils/findPrice";
import { BiUserCircle } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import InputWithLabelBilling from ".//InputWithLabelBilling";
import CountryDropdown from "./CountryDropdown";
import PaymentFailedBanner from "components/other/PaymentFailedBanner";
import { createCheckoutSession } from "utils/stripe";
import NormalButton from "components/buttons/NormalButton";
import { plans } from "utils/subscriptionPlans";

export default function BillingCard() {
  const stripe = useStripe();
  const elements = useElements();
  const { userData, setUserData, bannerVisible } = useUserContext();
  const [period, setPeriod] = useState("month");
  const [selectedPlan, setSelectedPlan] = useState(
    userData?.bizData.companySize === "Just you"
      ? "Starter"
      : userData?.bizData.companySize === "2-7 people"
      ? "Growing"
      : "Enterprise"
  );
  const [billingInfoModal, setBillingInfoModal] = useState(false);
  const currentPlan = userData?.subData?.plan?.nickname;
  const showFailedPaymentBanner =
    userData?.subData?.status === "incomplete_expired" ||
    userData?.subData?.status === "unpaid";

  const [loading, setLoading] = useState({ planName: "None", period: "None" });

  const handleClickBuyNow = async ({ planName }) => {
    // we don't need to validate any forms here, since we will just be passing the customerId if it exists and the priceId of either on of the month or the annual plans. Then stripe will create the checkout page where they enter their payment info and then confirm their plan...

    try {
      setLoading({ planName, period });
      // Define the necessary data
      const businessId = userData?.userData?.businessId;

      // this will fetch the updated subscription prices
      const priceId = findPriceId(planName, period);

      // we are pulling the customerId from the subscription document so that we don't create a new customer if one already exists
      const customerId = userData?.subData?.customer || null;

      const { url, error } = await createCheckoutSession({
        businessId,
        priceId,
        customerId,
      });

      if (url) {
        console.log("Checkout session created:", url);
        window.location.href = url;
      } else {
        throw new Error(error);
      }
    } catch (error) {
      console.error("Failed to create checkout session: ", error);
      alert(
        "Failed to create checkout session, please try again. If this continues to fail please contact support at support@homebase360.io"
      );
    } finally {
      setLoading({ planName: "None", period: "None" });
    }
  };

  const handleBillingInfoModal = (planName) => {
    setSelectedPlan(planName);
    setBillingInfoModal((prev) => !prev);
  };

  if (billingInfoModal) {
    document.body.classList.add("lock-scroll");
  } else {
    document.body.classList.remove("lock-scroll");
  }

  return (
    <>
      <div
        className={`flex ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        } flex-col items-center overflow-auto bg-slate-100/90 font-stripe`}
      >
        {showFailedPaymentBanner && <PaymentFailedBanner />}
        {/* <h1 className="mt-12 flex justify-center pl-2  text-4xl font-bold text-slate-900 ">
          Limited Time Offer
         
        </h1> */}
        {/* <h2 className="mt-2 flex justify-center pl-2 text-center  text-2xl font-semibold text-slate-800  w-4/5 max-w-3xl  ">
          Save 20% for three months when you choose a monthly plan. Or save up
          to 10% when you select an annual plan. Offer ends June 30th.
        </h2> */}
        <div
          className="relative mt-10 mb-36 flex   w-11/12 max-w-5xl flex-col rounded-lg bg-slate-50 shadow-2xl"
          // style={{
          //   width: "920px",
          // }}
        >
          <Pricing
            period={period}
            setPeriod={setPeriod}
            // goToCheckout={goToCheckout}
            handleClickBuyNow={handleClickBuyNow}
            setModal={setBillingInfoModal}
            handleBillingInfoModal={handleBillingInfoModal}
            currentPlan={currentPlan}
            loading={loading}
          />

          <div
            className="absolute w-full text-center text-xs text-gray-400"
            style={{
              bottom: "-70px",
            }}
          >
            Powered by{" "}
            <span className="font-semibold text-gray-500/90">Homebase 360</span>
          </div>
        </div>
      </div>

      {billingInfoModal && (
        <BillingInfoModal
          stripe={stripe}
          elements={elements}
          userData={userData}
          CardElement={CardElement}
          selectedPlan={selectedPlan}
          period={period}
          setPeriod={setPeriod}
          setModal={setBillingInfoModal}
          setUserData={setUserData}
        />
      )}
    </>
  );
}

const Pricing = ({
  period,
  setPeriod,
  handleBillingInfoModal,
  currentPlan,
  handleClickBuyNow,
  loading,
}) => (
  <div className="flex flex-col  items-center px-7 pb-5">
    <div className="flex w-full flex-row items-center justify-center border-slate-200 pt-5">
      <div className="flex-row flex items-center justify-center border rounded-md  border-gray-800 shadow-2xl text-sm font-medium sm:right-12">
        <button
          onClick={() => {
            setPeriod("month");
          }}
          className={
            period === "month"
              ? "bg-gray-200 text-gray-900 p-2 rounded-l cursor-pointer "
              : "bg-gray-50 text-gray-900 p-2 rounded-l cursor-pointer hover:bg-gray-100"
          }
        >
          Monthly
        </button>

        <button
          onClick={() => {
            setPeriod("annual");
          }}
          className={
            period === "annual"
              ? "bg-gray-600 text-gray-50 p-2 rounded-r cursor-pointer"
              : "bg-gray-50 text-gray-900 p-2 rounded-r cursor-pointer hover:bg-gray-600 hover:text-gray-50"
          }
        >
          Annual
        </button>
      </div>
      <div className="text-sm font-medium text-yellow-600 ml-4 p-2 bg-yellow-100 rounded-md">
        Save big with an annual plan
      </div>
    </div>

    {/* <h1 className="text-md w-full font-semibold text-slate-500">
      Free trial 14 days
    </h1> */}

    <div className="flex md:flex-row flex-col">
      {plans.map((plan) => (
        <div
          key={plan.name}
          className={`pb-18 relative mt-16 mr-0 md:mr-4 last:mr-0 flex flex-col ${
            currentPlan === plan.name ? "rounded-b-lg" : "rounded-lg"
          } p-10 py-8 pt-8 pb-20 text-gray-500 shadow-lg transition-colors ${
            period === "annual"
              ? "bg-gray-700 text-yellow-100"
              : " bg-gray-100 "
          } `}
        >
          {currentPlan === plan.name && (
            <div
              className="h-8 absolute bg-gray-400 rounded-t-md flex items-center justify-center px-1"
              style={{
                top: "-30px",
                left: 0,
                width: "100%",
              }}
            >
              <h1 className={`text-xs font-semibold text-gray-100 text-center`}>
                Currently trialing this plan
              </h1>
            </div>
          )}

          {plan.name === "Growing" && (
            <div
              className="h-8  absolute  text-gray-600  flex items-center justify-center px-1"
              style={{
                top: 2,
                left: 0,
                width: "100%",
              }}
            >
              <h1
                className={`text-md font-semibold ${
                  period === "annual" ? "text-yellow-100" : "text-gray-600"
                }  text-center`}
              >
                Most Popular
              </h1>
            </div>
          )}
          <div className="flex flex-row items-center justify-center">
            <h1
              className={`text-2xl font-bold ${
                period === "annual" ? "text-gray-50" : "text-gray-700"
              }`}
            >
              {plan.name}
            </h1>
          </div>
          <ul className="text-md flex flex-col gap-2 pt-6 font-semibold">
            {plan.features.map((feature) => (
              <li key={feature} className="flex">
                <BsFillCheckCircleFill className="text-green-400 mt-1 flex-shrink-0" />

                <span className="ml-2">{feature}</span>
              </li>
            ))}
          </ul>
          <div className="flex mt-6 flex-col mb-8 items-center w-full flex-grow justify-end">
            {/* Old discount code for the year  */}
            {/* <h1
              className={`text-md font-semibold line-through ${
                period === "annual" ? "text-red-400" : "text-red-700"
              }`}
            >
              {period === "month"
                ? plan.monthlyPrice + "/month"
                : plan.annualPrice + "/month"}
            </h1> */}
            {/* <h1
              className={`text-3xl font-bold ${
                period === "annual" ? "text-gray-50" : "text-gray-800"
              }`}
            >
              {period === "month"
                ? "$" +
                  Math.floor(
                    parseFloat(plan.monthlyPrice.replace("$", "")) -
                      parseFloat(plan.monthlyPrice.replace("$", "")) * 0.2
                  ) +
                  "/mo"
                : "$" +
                  Math.floor(
                    parseFloat(plan.annualPrice.replace("$", "")) -
                      parseFloat(plan.annualPrice.replace("$", "")) * 0.1
                  ) +
                  "/mo"}
            </h1> */}
            <h1
              className={`text-3xl font-bold ${
                period === "annual" ? "text-gray-50" : "text-gray-800"
              }`}
            >
              {period === "month"
                ? "$" +
                  Math.floor(parseFloat(plan.monthlyPrice.replace("$", ""))) +
                  "/mo"
                : "$" +
                  Math.floor(parseFloat(plan.annualPrice.replace("$", ""))) +
                  "/mo"}
            </h1>
            <h2>
              {period === "annual" && (
                <h2 className="text-sm font-semibold text-gray-100 mt-2">
                  ${parseFloat(plan.annualPrice.replace("$", "")) * 12} billed
                  annually
                </h2>
              )}
            </h2>
          </div>
          <div className="absolute left-0 bottom-10 flex w-full justify-center">
            {/* <button
              className={` rounded-md bg-gray-500 px-4 py-2 font-medium text-yellow-200 shadow-md ${"cursor-pointer"}
                `}
              onClick={() => goToCheckout(plan.name)}
            >
              Buy Now
            </button> */}
            {/* <button
              className={` rounded-md bg-gray-500 px-4 py-2 font-medium text-yellow-200 shadow-md ${"cursor-pointer"}
                `}
              // onClick={() => handleBillingInfoModal(plan.name)}
              onClick={() => handleClickBuyNow({ planName: plan.name })}
            >
              Buy Now
            </button> */}
            <NormalButton
              text="Buy Now"
              onClick={() => handleClickBuyNow({ planName: plan.name })}
              // additionalClasses="rounded-md bg-gray-500 px-4 py-2 font-medium text-yellow-200 shadow-md cursor-pointer"
              loading={
                loading.planName === plan.name && loading.period === period
              }
              disabled={
                loading.planName !== "None" && loading.period !== "None"
              }
            />
          </div>
        </div>
      ))}
    </div>
  </div>
);

function BillingInfoModal({
  stripe,
  elements,
  CardElement,
  userData,
  setUserData,
  setModal,
  selectedPlan,
  period,
  setPeriod,
}) {
  const [firstName, setFirstName] = useState(userData.bizData.firstName);
  const [lastName, setLastName] = useState(userData.bizData.lastName);
  const [isFocus, setIsFocus] = useState(null);
  const [address, setAddress] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [unit, setUnit] = useState("");
  const [country, setCountry] = useState("United States");
  const [billingEmail, setBillingEmail] = useState(userData.bizData.email);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponError, setCouponError] = useState(false);
  const [validCoupon, setValidCoupon] = useState(false);

  useEffect(() => {
    if (!couponCode) {
      setCouponError(false);
      setValidCoupon(false);
      return;
    }
    if (couponCode === "windows" || couponCode === "frames") {
      setValidCoupon(true);
      setCouponError(false);
      return;
    } else {
      setValidCoupon(false);
      setCouponError(true);
    }
  }, [couponCode]);

  const navigate = useNavigate();

  // set formatted address
  useEffect(() => {
    if (!city && !state && !zip && address) {
      setFormattedAddress(address);
    } else if (city && state && address && country && zip) {
      const updatedAddress = `${address},${
        unit ? " " + unit + "," : ""
      } ${city}, ${state} ${zip} ${country === "United States" ? "US" : "CA"}`;
      setFormattedAddress(updatedAddress);
    } else {
      setFormattedAddress("");
    }
  }, [address, unit, city, state, zip, country]);

  //   console.log("formattedAddress", formattedAddress);

  const handleConfirmSubscription = async () => {
    if (!formattedAddress) {
      setAddressError(true);
      setErrorMessage("Please fill out your billing address");
      return;
    }
    if (!firstName || !lastName) {
      setErrorMessage("Please fill out your name");
      return;
    }
    if (!billingEmail) {
      setErrorMessage("Please fill out your email");
      return;
    }
    if (
      !userData?.userData?.businessId ||
      !stripe ||
      !elements ||
      isProcessing ||
      isSuccess ||
      !period ||
      !selectedPlan
    )
      return;
    setIsProcessing(true);

    // Define the necessary data
    const businessId = userData?.userData?.businessId;
    const priceId = findPriceId(selectedPlan, period);
    // we are pulling the customerId from the subscription document so that we don't create a new customer if one already exists
    const customerId = userData?.subData?.customer || null;
    // const couponId =
    //   period === "month"
    //     ? process.env.REACT_APP_MONTHLY_COUPON
    //     : process.env.REACT_APP_ANNUAL_COUPON;
    let couponId = null;
    if (validCoupon) {
      couponId = couponCode;
    }

    console.log("couponId", couponId);
    let addressForStripe = {
      city: city,
      country: country === "United States" ? "US" : "CA",
      line1: address,
      postal_code: zip,
      state: state,
    };
    if (unit) {
      addressForStripe.line2 = unit;
    }

    // Create the payment method
    let paymentMethod;
    let error;

    try {
      const cardElement = elements.getElement(CardElement);
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: `${firstName} ${lastName}`,
          email: billingEmail,
          address: addressForStripe,
        },
      });
      paymentMethod = result.paymentMethod;
      error = result.error;
    } catch (error) {
      console.error("Failed to create payment method: ", error);
      setErrorMessage("Failed to create payment method.");
      setIsProcessing(false);
      return;
    }
    console.log("paymentMethod: ", paymentMethod);

    if (error) {
      setErrorMessage(error.message);
      setIsProcessing(false);
      return;
    }

    let response;
    try {
      response = await fetch(
        `${node}/subscriptions/create-subscription-start-now`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: billingEmail,
            name: `${firstName} ${lastName}`,
            paymentMethodId: paymentMethod.id,
            priceId,
            businessId,
            couponId,
            customerId,
            address: addressForStripe,
          }),
        }
      );
    } catch (error) {
      console.error("Failed to create subscription: ", error);
      setErrorMessage("Failed to create subscription.");
      setIsProcessing(false);
      return;
    }

    let subscription;
    let customer;
    let subscriptionError;
    try {
      const json = await response.json();
      subscription = json.subscription;
      customer = json.customer;
      subscriptionError = json.subscriptionError;
    } catch (error) {
      console.error("Failed to parse response: ", error);
      setErrorMessage("There was an error with creating your subscription.");
      setIsProcessing(false);
      return;
    }

    // console.log("subscriptionResult: ", subscription);
    // console.log("subscription.status: ", subscription?.status);
    // console.log("customer:", customer);

    if (subscriptionError) {
      console.error("Failed to create subscription: ", subscriptionError);
      setErrorMessage("Failed to create subscription.");
      setIsProcessing(false);
      return;
    }
    if (subscription.status === "incomplete") {
      const { client_secret } = subscription.latest_invoice.payment_intent;

      // console.log("client_secret: ", client_secret);
      const confirmResult = await stripe.confirmCardPayment(client_secret);
      // Handle the result of the confirmation

      console.log("confirmResult: ", confirmResult);
      if (confirmResult.error) {
        // The card was declined (i.e. insufficient funds, card has expired, etc)
        setErrorMessage(confirmResult.error.message);
        setIsProcessing(false);
        // we still want to set the subscription data in the userdata so if they try again before refreshing we won't be creating another customer object in stripe
        setUserData((prev) => ({
          ...prev,
          subData: subscription,
        }));
        return;
      }
      if (confirmResult.paymentIntent.status === "succeeded") {
        setIsSuccess(true);
        setIsProcessing(false);
        setUserData((prev) => ({
          ...prev,
          subData: subscription,
        }));

        setTimeout(() => {
          navigate("/");
        }, 1500);
      }
      return;
    }

    if (
      (subscription && subscription?.status === "trialing") ||
      subscription?.status === "active"
    ) {
      try {
        setIsSuccess(true);
        setUserData((prev) => ({
          ...prev,
          subData: subscription,
        }));
        // await pollSubscriptionStatus(user.uid);
        // setDatabaseUpdated(true);
      } catch (error) {
        // console.error("Failed to poll subscription status: ", error);

        setErrorMessage(
          "There was an error verifying your subscription. Please contact support."
        );
      }
    } else {
      console.log("subscriptionError: ", subscriptionError);
      setErrorMessage(
        "There was an error with initializing your subscription. Please contact support."
      );
    }

    setIsProcessing(false);
  };
  const options = {
    style: {
      base: {
        iconColor: "#eab308",
        color: "#111827",
        fontWeight: "400",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#374151",
        },
        "::placeholder": {
          color: "#6b7280",
        },
      },
      invalid: {
        iconColor: "#ef4444",
        color: "#ef4444",
      },
    },
  };

  let threeMonthsFromNow = new Date();
  threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);

  let oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  const normalPrice = findPrice(selectedPlan, period);
  const discountedPrice =
    period === "month"
      ? Math.floor(findPrice(selectedPlan, period) * 0.8)
      : Math.floor(findPrice(selectedPlan, period) * 0.9);
  return (
    <>
      <div className="billing-modal">
        <div
          className="billing-modal-overlay"
          onClick={() => console.log("clicked overlay")}
        ></div>
        <div className="billing-modal-wrapper">
          <div className="billing-modal-content  ">
            <div className="flex flex-row items-center justify-between bg-gray-200/60 px-5 py-3 font-stripe">
              <h2 className="text-2xl font-bold ">Add billing information</h2>
              <IoCloseSharp
                onClick={() => {
                  if (!isProcessing && !isSuccess) {
                    setModal(false);
                  }
                }}
                className=" text-3xl cursor-pointer"
              />
            </div>
            <h3 className="text-lg font-semibold mt-3 px-4">
              Credit card details
            </h3>
            <div className="w-full grid grid-cols-2 gap-2 mt-4 px-5">
              <div className="col-span-1">
                <InputWithLabelBilling
                  label="First Name"
                  value={firstName}
                  setValue={setFirstName}
                />
              </div>
              <div className="col-span-1">
                <InputWithLabelBilling
                  label="Last Name"
                  value={lastName}
                  setValue={setLastName}
                />
              </div>
            </div>
            <div className="px-5   mt-4 w-full">
              <CardElement
                options={options}
                className={`h-12 p-4 rounded-md  border ${
                  isFocus ? "border-yellow-300" : "border-gray-500"
                } `}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
              />
            </div>
            <h3 className="text-lg font-semibold mt-5 px-4">Billing address</h3>
            <div className="w-full flex flex-col justify-between px-5 items-center mt-2">
              <div className="w-full">
                <InputWithLabelBilling
                  label="Address"
                  value={address}
                  setValue={setAddress}
                  addresserror={addressError}
                  formattedaddress={formattedAddress}
                  name="address"
                  autocomplete="street-address"
                />
              </div>

              <div className="w-full grid grid-cols-6 gap-2 mt-4 ">
                <div className="col-span-2">
                  <InputWithLabelBilling
                    label="Unit"
                    value={unit}
                    setValue={setUnit}
                    name="unit"
                    autocomplete="address-line2"
                  />
                </div>
                <div className="col-span-2">
                  <InputWithLabelBilling
                    label="Zip"
                    value={zip}
                    setValue={setZip}
                    addresserror={addressError}
                    formattedaddress={formattedAddress}
                    name="postal-code"
                    autocomplete="postal-code"
                  />
                </div>
                <div className="col-span-2">
                  <InputWithLabelBilling
                    label="State"
                    value={state}
                    setValue={setState}
                    addresserror={addressError}
                    formattedaddress={formattedAddress}
                    name="state"
                    autocomplete="address-level1"
                  />
                </div>
              </div>
              <div className="w-full flex flex-row justify-start gap-2 mt-4">
                <div className="w-1/2">
                  <InputWithLabelBilling
                    label="City"
                    value={city}
                    setValue={setCity}
                    addresserror={addressError}
                    formattedaddress={formattedAddress}
                    name="city"
                    autocomplete="address-level2"
                  />
                </div>
                <div className="w-1/2 ">
                  <CountryDropdown
                    label="Country"
                    value={country}
                    setValue={setCountry}
                    name="country"
                    autocomplete="country"
                  />
                </div>
              </div>

              {/* <div className="w-full mt-8">
                <InputWithLabelBilling
                  label="Billing email"
                  value={billingEmail}
                  setValue={setBillingEmail}
                />
              </div> */}
            </div>
            <h3 className="text-lg font-semibold mt-5 px-4">Coupon code</h3>
            <div className="w-full flex flex-row px-5 mt-2 items-center flex-wrap">
              <div className="relative flex flex-col font-stripe w-1/2 sm:w-1/3 ">
                <input
                  className={`bg-gray-50 border ${
                    couponError
                      ? "border-red-400 focus:border-red-300"
                      : "border-gray-500 focus:border-yellow-400"
                  } h-12 rounded-md outline-none text-gray-900  text-base pt-4 pb-3 px-4
                  ${validCoupon && "border-green-300 focus:border-green-300"}
                   `}
                  value={couponCode}
                  placeholder="Coupon"
                  onChange={(e) => setCouponCode(e.target.value)}
                  id="coupon"
                  type="text"
                  autoComplete="false"
                />
              </div>
              {validCoupon && (
                <div className="font-stripe ml-2   mt-4">
                  20% off for 3 months 🎉
                </div>
              )}
            </div>
            <h2 className="text-lg font-bold mt-8 px-4">{selectedPlan}</h2>
            <div className="flex flex-row items-start justify-between px-5 mt-4">
              <div className="flex items-center">
                <button
                  disabled={isProcessing || isSuccess}
                  className={`w-4 h-4 rounded-full border-2 ${
                    period === "annual"
                      ? "bg-yellow-400 border-gray-700"
                      : "border-gray-700"
                  }`}
                  onClick={() => setPeriod("annual")}
                />
                <label className="ml-2" htmlFor="annual">
                  Annual
                </label>
                {/* <span className="ml-2 bg-yellow-100 rounded-md text-yellow-600  p-1 px-2 text-xs">
                  Save $
                  {Math.floor(
                    parseInt(findPrice(selectedPlan, "annual")) * 0.1
                  )}{" "}
                  for the first year
                </span> */}
              </div>
              {/* <div className="flex flex-col items-end">
                <div className="font-semibold text-right text-sm">
                  $
                  {Math.floor(
                    parseInt(findPrice(selectedPlan, "annual")) * 0.9
                  ).toLocaleString()}{" "}
                  USD for the first year
                </div>
                <div className="font-light text-xs text-right">
                  $
                  {Math.floor(
                    parseInt(findPrice(selectedPlan, "annual"))
                  ).toLocaleString()}{" "}
                  USD/year after
                </div>
              </div> */}
              <div className="flex flex-col items-end">
                <div className="font-semibold text-right text-sm">
                  $
                  {Math.floor(
                    parseInt(findPrice(selectedPlan, "annual"))
                  ).toLocaleString()}{" "}
                  USD/year
                </div>
              </div>
            </div>
            <div className="flex flex-row items-start justify-between px-5 mt-4">
              <div className="flex items-center">
                <button
                  disabled={isProcessing || isSuccess}
                  className={`w-4 h-4 rounded-full border-2 ${
                    period === "month"
                      ? "bg-yellow-400 border-gray-700"
                      : "border-gray-700"
                  }`}
                  onClick={() => setPeriod("month")}
                />
                <label className="ml-2" htmlFor="month">
                  Month
                </label>
                {/* <span className="ml-2 bg-yellow-100 text-yellow-600 px-2 p-1 rounded-md text-xs">
                  Save $
                  {Math.floor(
                    3 * parseInt(findPrice(selectedPlan, "month")) * 0.2
                  )}{" "}
                  for the first year
                </span> */}
              </div>
              {validCoupon ? (
                <div className="flex flex-col">
                  <div className="font-semibold text-right text-sm">
                    $
                    {Math.floor(
                      parseInt(findPrice(selectedPlan, "month") * 0.8)
                    ).toLocaleString()}{" "}
                    USD/month for the first 3 months
                  </div>
                  <div className="font-light text-xs text-right">
                    $
                    {Math.floor(
                      parseInt(findPrice(selectedPlan, "month"))
                    ).toLocaleString()}{" "}
                    USD/month after
                  </div>
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className="font-semibold text-right text-sm">
                    $
                    {Math.floor(
                      parseInt(findPrice(selectedPlan, "month"))
                    ).toLocaleString()}{" "}
                    USD/month
                  </div>
                </div>
              )}
            </div>
            {/* Discount code */}
            {validCoupon ? (
              <>
                {period === "month" ? (
                  <p className="text-sm text-gray-600 px-5 mt-4">
                    You will be charged{" "}
                    <span className="font-semibold text-gray-800">
                      ${discountedPrice} USD
                    </span>{" "}
                    on {new Date().toLocaleDateString()} and your subscription
                    will renew at{" "}
                    <span className="font-semibold text-gray-800">
                      ${discountedPrice}
                    </span>{" "}
                    for the following 2 months. Starting{" "}
                    {threeMonthsFromNow.toLocaleDateString()} you will be
                    charged{" "}
                    <span className="font-semibold text-gray-800">
                      ${normalPrice} USD/month
                    </span>
                    . Plus taxes where applicable. Billing dates subject to
                    change during remaining trial period.
                  </p>
                ) : (
                  // old discount code
                  // <p className="text-sm text-gray-600 px-5 mt-4">
                  //   You will be charged{" "}
                  //   <span className="font-semibold text-gray-800">
                  //     ${discountedPrice} USD
                  //   </span>{" "}
                  //   on {new Date().toLocaleDateString()} and your subscription
                  //   will renew on {oneYearFromNow.toLocaleDateString()} for{" "}
                  //   <span className="font-semibold text-gray-800">
                  //     ${normalPrice} USD
                  //   </span>
                  //   . Plus taxes where applicable. Billing dates subject to
                  //   change during remaining trial period.
                  // </p>
                  <p className="text-sm text-gray-600 px-5 mt-4">
                    You will be charged{" "}
                    <span className="font-semibold text-gray-800">
                      ${normalPrice} USD
                    </span>{" "}
                    on {new Date().toLocaleDateString()} and your subscription
                    will renew on {oneYearFromNow.toLocaleDateString()} for{" "}
                    <span className="font-semibold text-gray-800">
                      ${normalPrice} USD
                    </span>
                    . Plus taxes where applicable. Billing dates subject to
                    change during remaining trial period.
                  </p>
                )}
              </>
            ) : (
              <>
                {period === "month" ? (
                  <p className="text-sm text-gray-600 px-5 mt-4">
                    You will be charged{" "}
                    <span className="font-semibold text-gray-800">
                      ${normalPrice} USD
                    </span>{" "}
                    on {new Date().toLocaleDateString()} and your subscription
                    will renew at{" "}
                    <span className="font-semibold text-gray-800">
                      ${normalPrice} USD/month
                    </span>{" "}
                    . Plus taxes where applicable. Billing dates subject to
                    change during remaining trial period.
                  </p>
                ) : (
                  <p className="text-sm text-gray-600 px-5 mt-4">
                    You will be charged{" "}
                    <span className="font-semibold text-gray-800">
                      ${normalPrice} USD
                    </span>{" "}
                    on {new Date().toLocaleDateString()} and your subscription
                    will renew on {oneYearFromNow.toLocaleDateString()} for{" "}
                    <span className="font-semibold text-gray-800">
                      ${normalPrice} USD
                    </span>
                    . Plus taxes where applicable. Billing dates subject to
                    change during remaining trial period.
                  </p>
                )}
              </>
            )}
            <div className="flex relative flex-row items-center justify-end gap-2 px-5 mt-8 pb-8">
              <button
                disabled={isProcessing || isSuccess}
                className="bg-gray-200 text-gray-600 font-semibold px-4 py-2 rounded-md shadow-sm"
                onClick={() => setModal(false)}
              >
                Cancel
              </button>
              <button
                disabled={isProcessing || isSuccess}
                className={`bg-gray-800 ${
                  isSuccess || isProcessing ? "" : "hover:bg-gray-900"
                }  flex items-center justify-center w-48 text-yellow-300 font-bold py-2 rounded-md shadow-sm`}
                onClick={handleConfirmSubscription}
              >
                {isSuccess ? (
                  <span className="text-green-400 flex-row flex items-center">
                    <BiCheckCircle className="text-green-400 mr-1 text-lg" />
                    Success
                  </span>
                ) : (
                  <>
                    {" "}
                    {isProcessing ? (
                      <BiLoader className="animate-spin-slow text-xl text-white" />
                    ) : (
                      "Confirm Subscription"
                    )}
                  </>
                )}
              </button>
              {errorMessage && (
                <div className="text-sm absolute bottom-2 text-red-500">
                  {errorMessage}
                </div>
              )}
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
}

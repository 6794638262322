import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const drawerWidth = 240;
const SidebarCustomizations = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  // Function to check the window width
  const checkWidthAndUpdateState = () => {
    const isMobile = window.innerWidth < 768; // Tailwind's md breakpoint
    if (isMobile) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    checkWidthAndUpdateState();
    window.addEventListener("resize", checkWidthAndUpdateState);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", checkWidthAndUpdateState);
  }, []);

  return (
    <div className="relative z-10">
      <div className="mt-1 px-1">
        <IconButton onClick={toggleDrawer}>
          {isOpen ? (
            <ChevronLeftIcon color="#f9fafb" />
          ) : (
            <ChevronRightIcon color="#f9fafb" />
          )}
        </IconButton>
      </div>
      <Drawer
        variant="persistent"
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            top: "64px",
            height: `calc(100% - 64px)`,
            // backgroundColor: "#111827", // Background color to gray 800
            // color: "#f9fafb", // Text color to gray 100
          },
        }}
      >
        <div className="w-full flex flex-col">
          <div className="flex justify-between items-center mt-1 pr-2">
            <h1 className=" text-2xl font-medium pl-1">Customizations</h1>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </div>

          <div className="flex flex-col mt-2 ml-4">
            <NavLink
              to="/settings/customizations/notifications"
              className={({ isActive }) =>
                `${
                  isActive
                    ? "text-gray-900 font-bold pl-7 mr-6"
                    : "text-gray-400 pl-4 hover:text-gray-600"
                } 
       py-1 transition-all duration-300 ease-in-out relative group`
              }
            >
              <div
                className={`absolute left-1 w-0.5 h-full ${
                  location.pathname.includes("notifications")
                    ? "bg-yellow-400"
                    : "bg-gray-100 group-hover:bg-gray-200"
                }   transition-all duration-300 ease-in-out  `}
              ></div>
              Messages
            </NavLink>

            <NavLink
              to="/settings/customizations/jobreminders"
              className={({ isActive }) =>
                `${
                  isActive
                    ? "text-gray-900 font-bold pl-7 mr-6"
                    : "text-gray-400 pl-4 hover:text-gray-600"
                } 
       py-1 transition-all duration-300 ease-in-out relative group`
              }
            >
              <div
                className={`absolute left-1 w-0.5 h-full ${
                  location.pathname.includes("jobreminders")
                    ? "bg-yellow-400"
                    : "bg-gray-100 group-hover:bg-gray-200"
                }   transition-all duration-300 ease-in-out  `}
              ></div>
              Job Reminders
            </NavLink>

            <NavLink
              to="/settings/customizations/estimates"
              className={({ isActive }) =>
                `${
                  isActive
                    ? "text-gray-900 font-bold pl-7 mr-6"
                    : "text-gray-400 pl-4 hover:text-gray-600"
                } 
       py-1 transition-all duration-300 ease-in-out relative group`
              }
            >
              <div
                className={`absolute left-1 w-0.5 h-full ${
                  location.pathname.includes("estimates")
                    ? "bg-yellow-400"
                    : "bg-gray-100 group-hover:bg-gray-200"
                }   transition-all duration-300 ease-in-out  `}
              ></div>
              Estimates
            </NavLink>
            <NavLink
              to="/settings/customizations/invoices"
              className={({ isActive }) =>
                `${
                  isActive
                    ? "text-gray-900 font-bold pl-7 mr-6"
                    : "text-gray-400 pl-4 hover:text-gray-600"
                } 
       py-1 transition-all duration-300 ease-in-out relative group`
              }
            >
              <div
                className={`absolute left-1 w-0.5 h-full ${
                  location.pathname.includes("invoices")
                    ? "bg-yellow-400"
                    : "bg-gray-100 group-hover:bg-gray-200"
                }   transition-all duration-300 ease-in-out  `}
              ></div>
              Invoices
            </NavLink>
            <NavLink
              to="/settings/customizations/terms"
              className={({ isActive }) =>
                `${
                  isActive
                    ? "text-gray-900 font-bold  pl-7 mr-6"
                    : "text-gray-400 pl-4 hover:text-gray-600"
                } 
       py-1 transition-all duration-300 ease-in-out relative group`
              }
            >
              <div
                className={`absolute left-1 w-0.5 h-full ${
                  location.pathname.includes("terms")
                    ? "bg-yellow-400"
                    : "bg-gray-100 group-hover:bg-gray-200 "
                } transition-all duration-300 ease-in-out `}
              ></div>
              Terms
            </NavLink>
            <NavLink
              to="/settings/customizations/service-subscriptions"
              className={({ isActive }) =>
                `${
                  isActive
                    ? "text-gray-900 font-bold  pl-7 mr-6"
                    : "text-gray-400 pl-4 hover:text-gray-600"
                } 
       py-1 transition-all duration-300 ease-in-out relative group`
              }
            >
              <div
                className={`absolute left-1 w-0.5 h-full ${
                  location.pathname.includes("service-subscriptions")
                    ? "bg-yellow-400"
                    : "bg-gray-100 group-hover:bg-gray-200 "
                } transition-all duration-300 ease-in-out `}
              ></div>
              Service Subscriptions
            </NavLink>

            <NavLink
              to="/settings/customizations/price-book"
              className={({ isActive }) =>
                `${
                  isActive
                    ? "text-gray-900 font-bold  pl-7 mr-6"
                    : "text-gray-400 pl-4 hover:text-gray-600"
                } 
       py-1 transition-all duration-300 ease-in-out relative group`
              }
            >
              <div
                className={`absolute left-1 w-0.5 h-full ${
                  location.pathname.includes("price-book")
                    ? "bg-yellow-400"
                    : "bg-gray-100 group-hover:bg-gray-200 "
                } transition-all duration-300 ease-in-out `}
              ></div>
              Price Book
            </NavLink>
          </div>

          {/* <List>
            <ListItem
              component={NavLink}
              to="messages"
              //   onClick={toggleDrawer}
              style={linkStyle}
            >
              <ListItemText primary="Messages" />
            </ListItem>
            <ListItem
              component={NavLink}
              to="terms"
              //   onClick={toggleDrawer}
              style={linkStyle}
            >
              <ListItemText primary="Terms" />
            </ListItem>
     
          </List> */}
        </div>
      </Drawer>
    </div>
  );
};

export default SidebarCustomizations;

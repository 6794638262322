import { storage } from "./firebase.js";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export async function uploadFile(file, folderName) {
  const fileName = `${folderName}/${Date.now()}-${file.name}`;
  const fileRef = ref(storage, fileName);

  const uploadTask = uploadBytesResumable(fileRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // handle progress updates
      },
      (error) => {
        reject(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(fileRef);
        resolve(downloadURL);
      }
    );
  });
}

export async function uploadAttachments(files, folderName = "customer-attachments") {
  const attachmentObjects = [];

  try {
    if (files?.length > 0) {
      // iterate through attachements and upload each of them using imported uploadFile function
      for (const file of files) {
        if (!file.file || !file.name) {
          console.warn("Invalid file object:", file);
          continue;
        }

        const url = await uploadFile(file.file, folderName);
        const newAttachmentObject = {
          url: url,
          name: file.name,
        }; // local attachments are {file: file, name: file.name} // firebase attachments are {url: url, name: file.name} -- this is how we will tell the difference
        attachmentObjects.push(newAttachmentObject);
      }
    }
    return attachmentObjects;
  } catch (error) {
    console.error("Error uploading attachments", error);
    // throw error to be caught by caller saying that there was an error uploading attachments
    alert("Error uploading attachments");
    throw error;
    return [];
  }
}

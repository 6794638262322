import { node } from "constants/constants";

export const createStripeCustomer = async ({
  customerData,
  stripeAccountId,
}) => {
  try {
    if (!customerData || !stripeAccountId) {
      throw new Error("Missing required parameters.");
    }
    const result = await fetch(`${node}/connectCustomers/create-customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerData,
        stripeAccountId,
      }),
    });
    if (!result.ok) {
      const errorData = await result.json();
      throw new Error(errorData.message || "Result was not ok.");
    }
    const response = await result.json();
    const stripeCustomerId = response.stripeCustomerId;

    return { stripeCustomerId };
  } catch (error) {
    return { error };
  }
};

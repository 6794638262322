import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import { TextField, InputLabel, ButtonGroup } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  format,
  getDay,
  startOfMonth,
  differenceInCalendarDays,
} from "date-fns";
import { set } from "lodash";

//define these outside of the component so we don't have to redefine them on every render
function weekOfMonth(date) {
  const start = startOfMonth(date);
  const difference = differenceInCalendarDays(date, start) + 1;
  return Math.ceil(difference / 7);
}

function ordinalSuffix(i) {
  const j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export default function RecurringDropdown({
  startTime,
  recurrenceData,
  setRecurrenceData,
  initialLabel = null,
}) {
  // Use date-fns to format and extract data
  const weeklyLabel = `Weekly on ${format(startTime, "EEEE")}`;
  const monthlyLabel = `Monthly on ${ordinalSuffix(
    weekOfMonth(startTime)
  )} ${format(startTime, "EEEE")}`;
  const annuallyLabel = `Annually on ${format(startTime, "MMMM d")}`;

  // create another monthlyLabel that display Monthly on day 1, 2, 3, etc.
  const monthlyLabel2 = `Monthly on day ${format(startTime, "d")}`;

  // these are for displaying in the custom recurrence modal for type month
  const monthlyLabel3 = `On the ${ordinalSuffix(
    weekOfMonth(startTime)
  )} ${format(startTime, "EEEE")}`;
  const monthlyLabel4 = `On the ${ordinalSuffix(format(startTime, "d"))}`;

  // There are the initial labels before we dive into custom complex land

  const initialRecurringLabels = [
    { label: "Does not repeat", type: "dnr" },
    { label: weeklyLabel, type: "week" },
    { label: monthlyLabel, type: "month" },
    { label: annuallyLabel, type: "year" },
    { label: "Custom...", type: "custom" },
  ];

  const [recurringLabels, setRecurringLabels] = useState(
    initialRecurringLabels
  );

  useEffect(() => {
    let newRecurringLabels = [...initialRecurringLabels];
    if (recurring?.type?.includes("custom")) {
      const labelExists = newRecurringLabels.some(
        (label) => label.label === recurring.label
      );

      if (!labelExists) {
        newRecurringLabels.push(recurring);
      }
    }
    setRecurringLabels(newRecurringLabels);

    if (recurring?.type === "week") {
      setRecurring({ label: weeklyLabel, type: "week" });
    } else if (recurring?.type === "month") {
      setRecurring({ label: monthlyLabel, type: "month" });
    } else if (recurring?.type === "year") {
      setRecurring({ label: annuallyLabel, type: "year" });
    } else if (recurring?.type?.includes("custom")) {
      // console.log("it contains customs so we keep it as is");
    } else {
      setRecurring({ label: "Does not repeat", type: "dnr" });
    }
  }, [weeklyLabel, monthlyLabel, annuallyLabel]);

  const [initialRecurrenceData] = useState(() => {
    // Check if recurrenceData exists and is not an empty object
    if (recurrenceData && Object.keys(recurrenceData).length > 0) {
      return recurrenceData;
    }
    return {};
  });

  const [recurring, setRecurring] = useState(
    initialLabel || { label: "Does not repeat", type: "dnr" }
  );

  const [customModal, setCustomModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);

  const [customRecurrenceData, setCustomRecurrenceData] = useState(
    recurrenceData?.type?.includes("custom") ? recurrenceData : {}
  );

  // console.log("customRecurrenceData: ", customRecurrenceData);

  const handleClickMenuItem = (event) => {
    // console.log("event.target.value: ", event.target.value);
    if (event.target.value === "Custom...") {
      // this is where we will open the custom modal and reset it to initial labels
      setCustomModal(true);
      setRecurringLabels(initialRecurringLabels);
      setRecurring({ label: "Does not repeat", type: "dnr" });
      setCustomRecurrenceData({});
      setMonthlyUnit("dayOfWeek");
      const day = format(startTime, "EEEE");
      // console.log("day: ", day);
      setSelectedDay(day);
      return;
    }
    // find the label that matches the value
    const label = recurringLabels.find(
      (label) => label.label === event.target.value
    );
    setRecurring(label);
  };

  const handleCloseModal = () => {
    setCustomModal(false);
  };

  useEffect(() => {
    const { type, label } = recurring;
    if (type === "dnr") {
      // console.log("type is dnr we are resetting recurrence data");
      setRecurrenceData({
        label: "Does not repeat",
        type: "dnr",
        rawDate: null,
        dayOfWeek: null,
        dayOfMonth: null,
        weekOfMonth: null,
        dayOfYear: null,
        endDate: null,
        occurrences: null,
        frequency: null,
        forever: false,
        monthOfYear: null,
      });
    } else if (type === "week") {
      // console.log("type is week we are resetting recurrence data");
      setRecurrenceData({
        label: weeklyLabel,
        type: "week",
        rawDate: startTime,
        dayOfWeek: format(startTime, "EEEE"),
        dayOfMonth: null,
        weekOfMonth: null,
        dayOfYear: null,
        endDate: null,
        occurrences: null,
        frequency: 1,
        forever: true,
        monthOfYear: null,
      });
    } else if (type === "month") {
      // console.log("type is month we are resetting recurrence data");
      setRecurrenceData({
        label: monthlyLabel,
        type: "month",
        rawDate: startTime,
        dayOfWeek: format(startTime, "EEEE"),
        dayOfMonth: null,
        weekOfMonth: weekOfMonth(startTime),
        dayOfYear: null,
        endDate: null,
        occurrences: null,
        frequency: 1,
        forever: true,
        monthOfYear: null,
      });
    } else if (type === "year") {
      // console.log("type is year we are resetting recurrence data");
      setRecurrenceData({
        label: annuallyLabel,
        type: "year",
        rawDate: startTime,
        dayOfWeek: null,
        dayOfMonth: null,
        weekOfMonth: null,
        dayOfYear: format(startTime, "MMMM d"),
        endDate: null,
        occurrences: null,
        frequency: 1,
        forever: true,
        monthOfYear: format(startTime, "MMMM"),
      });
    } else if (recurring?.type?.includes("custom")) {
      // update the starting time in recurrenceData but keep the rest the same
      // console.log(
      //   "type contains custom so we are just updating the start time"
      // );
      setRecurrenceData({
        ...customRecurrenceData,
        rawDate: startTime,
      });
    } else if (initialLabel?.label && recurring.label === initialLabel.label) {
      // console.log("we are re-syncing recurrence data with startTime");
      // console.log("startTime: ", startTime);
      setRecurrenceData({
        ...initialRecurrenceData,
        rawDate: startTime,
      });
    } else {
      // console.log("we are about to handle save");
      handleSave(true);
    }
    // ... handle other recurrence options similarly
  }, [recurring, startTime]);

  const handleSave = (dontSetRecurring = false) => {
    // console.log("handle Save", dontSetRecurring);
    setCustomModal(false);

    let recurringLabel = "";

    // Handle base recurrence unit
    if (repeatUnit === "week") {
      recurringLabel = `Every ${
        repeatEvery > 1 ? `${repeatEvery} weeks` : "week"
      } on ${selectedDay}`;
    } else if (repeatUnit === "month") {
      if (monthlyUnit === "dayOfMonth") {
        recurringLabel = `Every ${
          repeatEvery > 1 ? `${repeatEvery} months` : "month"
        } on day ${format(startTime, "d")}`;
      } else {
        recurringLabel = `Every ${
          repeatEvery > 1 ? `${repeatEvery} months` : "month"
        } on the ${ordinalSuffix(weekOfMonth(startTime))} ${format(
          startTime,
          "EEEE"
        )}`;
      }
    } else if (repeatUnit === "year") {
      recurringLabel = `Every  ${
        repeatEvery > 1 ? `${repeatEvery} years` : "year"
      }  on ${format(startTime, "MMMM d")}`;
    }

    // Handle end type
    if (endType === "onDate") {
      recurringLabel += ` until ${format(endDate, "MMMM d, yyyy")}`;
    } else if (endType === "afterOccurrences") {
      recurringLabel += `, ${endOccurrences} times`;
    }

    // console.log("endType", endType);
    const newRecurrenceData = {
      label: recurringLabel,
      type: repeatUnit + "...custom",
      rawDate: startTime,
      dayOfWeek:
        repeatUnit === "week" ||
        (repeatUnit === "month" && monthlyUnit === "dayOfWeek")
          ? format(startTime, "EEEE")
          : null,
      dayOfMonth:
        repeatUnit === "month" && monthlyUnit === "dayOfMonth"
          ? format(startTime, "d")
          : null,
      weekOfMonth:
        repeatUnit === "month" && monthlyUnit !== "dayOfMonth"
          ? weekOfMonth(startTime)
          : null,
      dayOfYear: repeatUnit === "year" ? format(startTime, "MMMM d") : null,
      endDate: endType === "onDate" ? endDate : null,
      occurrences: endType === "afterOccurrences" ? endOccurrences : null,
      frequency: repeatEvery,
      forever: endType === "never" ? true : false,
      monthOfYear: repeatUnit === "year" ? format(startTime, "MMMM") : null,
    };

    setRecurrenceData(newRecurrenceData);
    setCustomRecurrenceData(newRecurrenceData);

    if (dontSetRecurring) {
      return;
    }
    // console.log("recurringLabel: ", recurringLabel);
    let recurringLabelsLabels = recurringLabels.map((label) => label.label);
    if (!recurringLabelsLabels?.includes(recurringLabel)) {
      setRecurringLabels((prevLabels) => [
        ...prevLabels,
        { label: recurringLabel, type: repeatUnit + "...custom" },
      ]);
    }
    setRecurring({ label: recurringLabel, type: repeatUnit + "...custom" });
  };

  const [repeatEvery, setRepeatEvery] = useState(1);
  const [repeatUnit, setRepeatUnit] = useState("week");
  const [endType, setEndType] = useState("never");
  // create a new date that is 6 months from now
  const sixMonthsFromNow = new Date();
  sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);
  const [endDate, setEndDate] = useState(sixMonthsFromNow);
  const [endOccurrences, setEndOccurrences] = useState(1);
  const [monthlyUnit, setMonthlyUnit] = useState(null);

  return (
    <div>
      {/* <button
        className="bg-gray-100 text-gray-700 font-medium py-2 px-4 rounded-md inline-flex items-center"
        // variant="contained"
        // aria-controls={open ? "basic-menu" : undefined}
        // aria-haspopup="true"
        // aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {recurring}
      </button> */}
      <Select
        sx={{
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fef08a",
          },
          "&.MuiOutlinedInput-root": {
            backgroundColor: "#f3f4f6", // Button's background color
            color: "#111827", // Text color
            "&:hover": {
              backgroundColor: "#f3f4f6", // Button's hover background color
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#f3f4f6", // Button's border color
            },
          },
          maxWidth: {
            xs: "200px", // for screens larger than xs
            sm: "300px", // for screens larger than sm
            md: "200px", // for screens larger than md
            lg: "250px", // for screens larger than lg
            xl: "350px",
            // ... you can add more breakpoints if needed
          },
        }}
        MenuProps={{
          sx: {
            "&& .Mui-selected": {
              backgroundColor: "#fef3c7",
            },
            "&& .Mui-selected:hover": {
              backgroundColor: "#e5e7eb",
            },
          },
        }}
        labelId="repeat-unit-label"
        value={recurring.label}
        onChange={handleClickMenuItem}
        size="small"
        variant="outlined"
      >
        {recurringLabels.map((label) => (
          <MenuItem key={label.label} value={label.label}>
            {label.label}
          </MenuItem>
        ))}
      </Select>

      <Modal
        open={customModal}
        onClose={handleCloseModal}
        aria-labelledby="custom-recurrence-title"
        aria-describedby="custom-recurrence-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            pt: 4,
            px: 4,
            pb: 3,
          }}
        >
          <Typography id="custom-recurrence-title" variant="h6" component="h2">
            Custom recurrence
          </Typography>

          <FormControl margin="normal" className="flex flex-row">
            <Box
              className="flex items-center space-x-4" // Tailwind utility classes
              margin="normal"
            >
              {/* Use Typography or span for inline label */}
              <Typography variant="body1">Repeat every</Typography>

              {/* Number Input */}
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#fef08a",
                    },
                  },
                }}
                type="number"
                value={repeatEvery}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value < 1) {
                    setRepeatEvery(1);
                  } else {
                    setRepeatEvery(value);
                  }
                }}
                variant="outlined"
                size="small"
                className="w-20" // fixed width using Tailwind
                inputProps={{
                  min: 1,
                }}
              />

              {/* Dropdown Select */}
              <Select
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fef08a",
                  },
                }}
                MenuProps={{
                  sx: {
                    "&& .Mui-selected": {
                      backgroundColor: "#e5e7eb",
                    },
                    "&& .Mui-selected:hover": {
                      backgroundColor: "#e5e7eb",
                    },
                  },
                }}
                labelId="repeat-unit-label"
                value={repeatUnit}
                onChange={(e) => {
                  if (e.target.value === "month") {
                    setMonthlyUnit("dayOfWeek");
                  }
                  setRepeatUnit(e.target.value);
                }}
                variant="outlined"
                size="small"
                className="w-32" // fixed width using Tailwind
              >
                <MenuItem value="week">
                  {repeatEvery == 1 ? "Week" : "Weeks"}
                </MenuItem>
                <MenuItem value="month">
                  {repeatEvery == 1 ? "Month" : "Months"}
                </MenuItem>
                <MenuItem value="year">
                  {repeatEvery == 1 ? "Year" : "Years"}
                </MenuItem>
              </Select>
            </Box>
          </FormControl>

          {/* You can expand on these conditionals to show the desired controls based on `repeatUnit` */}
          {repeatUnit === "week" && (
            <div className="flex flex-row px-4 mt-1 justify-center ">
              <ButtonGroup
                variant="contained"
                // sx={{
                //   width: "300px",
                // }}
              >
                <Button
                  onClick={() => setSelectedDay("Sunday")}
                  style={{
                    backgroundColor:
                      selectedDay === "Sunday" ? "#e5e7eb" : "white",
                    color: "black",
                    border: "1px solid #e5e7eb",
                  }}
                >
                  S
                </Button>
                <Button
                  onClick={() => setSelectedDay("Monday")}
                  style={{
                    backgroundColor:
                      selectedDay === "Monday" ? "#e5e7eb" : "white",
                    color: "black",
                    border: "1px solid #e5e7eb",
                  }}
                >
                  M
                </Button>
                <Button
                  onClick={() => setSelectedDay("Tuesday")}
                  style={{
                    backgroundColor:
                      selectedDay === "Tuesday" ? "#e5e7eb" : "white",
                    color: "black",
                    border: "1px solid #e5e7eb",
                  }}
                >
                  T
                </Button>
                <Button
                  onClick={() => setSelectedDay("Wednesday")}
                  style={{
                    backgroundColor:
                      selectedDay === "Wednesday" ? "#e5e7eb" : "white",
                    color: "black",
                    border: "1px solid #e5e7eb",
                  }}
                >
                  W
                </Button>
                <Button
                  onClick={() => setSelectedDay("Thursday")}
                  style={{
                    backgroundColor:
                      selectedDay === "Thursday" ? "#e5e7eb" : "white",
                    color: "black",
                    border: "1px solid #e5e7eb",
                  }}
                >
                  T
                </Button>
                <Button
                  onClick={() => setSelectedDay("Friday")}
                  style={{
                    backgroundColor:
                      selectedDay === "Friday" ? "#e5e7eb" : "white",
                    color: "black",
                    border: "1px solid #e5e7eb",
                  }}
                >
                  F
                </Button>
                <Button
                  onClick={() => setSelectedDay("Saturday")}
                  style={{
                    backgroundColor:
                      selectedDay === "Saturday" ? "#e5e7eb" : "white",
                    color: "black",
                    border: "1px solid #e5e7eb",
                  }}
                >
                  S
                </Button>
              </ButtonGroup>
            </div>
          )}

          {repeatUnit === "month" && (
            <Select
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fef08a",
                },
                marginTop: "10px",
              }}
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: "#e5e7eb",
                  },
                  "&& .Mui-selected:hover": {
                    backgroundColor: "#e5e7eb",
                  },
                },
              }}
              labelId="monthly-unit-label"
              value={monthlyUnit}
              onChange={(e) => setMonthlyUnit(e.target.value)}
              size="small"
            >
              <MenuItem value="dayOfMonth">{monthlyLabel4}</MenuItem>
              <MenuItem value="dayOfWeek">{monthlyLabel3}</MenuItem>
            </Select>
          )}

          {/* Ending options */}
          <FormControl margin="normal">
            <FormLabel
              sx={{
                "&.Mui-focused": {
                  color: "#111827",
                },
              }}
            >
              Ends
            </FormLabel>
            <RadioGroup
              value={endType}
              onChange={(e) => setEndType(e.target.value)}
            >
              <FormControlLabel
                value="never"
                control={
                  <Radio
                  // sx={{
                  //   "&, &.Mui-checked": {
                  //     color: "#4b5563",
                  //   },

                  // }}
                  />
                }
                label="Never"
              />
              <FormControlLabel
                value="onDate"
                control={<Radio />}
                label={
                  <div className="flex flex-row gap-12 justify-between flex-1 items-center">
                    On
                    <DatePicker
                      selected={endDate}
                      onChange={(newDate) => setEndDate(newDate)}
                      className={` bg-gray-100 rounded-md py-1 w-32 text-center focus:outline-none ${
                        endType === "onDate"
                          ? "text-black cursor-pointer"
                          : "text-gray-400 cursor-default"
                      } `}
                      disabled={endType !== "onDate"}
                    />
                  </div>
                }
              />
              <Box display="flex" alignItems="center" gap={2}>
                <FormControlLabel
                  value="afterOccurrences"
                  control={<Radio />}
                  label="After"
                />
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#fef08a",
                      },
                    },
                    paddingLeft: "5px",
                  }}
                  type="number"
                  disabled={endType !== "afterOccurrences"}
                  value={endOccurrences}
                  variant="outlined"
                  size="small"
                  className="w-32"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value < 1) {
                      setEndOccurrences(1);
                    } else {
                      setEndOccurrences(value);
                    }
                  }}
                  inputProps={{
                    min: 1,
                  }}
                />
                <Typography
                  style={{
                    color: endType === "afterOccurrences" ? "inherit" : "gray",
                  }}
                >
                  {endOccurrences == 1 ? "occurrence" : "occurrences"}
                </Typography>
              </Box>
            </RadioGroup>
          </FormControl>
          <div className="flex flew-row justify-end items-center mt-4">
            <button
              onClick={handleCloseModal}
              className="bg-gray-50 text-gray-500 px-4 py-2 rounded-md mr-4 hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              onClick={() => handleSave()}
              className="bg-gray-900 text-white hover:text-yellow-100 px-4 py-2 rounded-md"
            >
              Save
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

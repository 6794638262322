import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdDateRange } from "react-icons/md";
import { FaSave } from "react-icons/fa";
import CustomDropdown from "../../AddJob/components/CustomDropdown";
import RecurringDropdown from "../../AddJob/components/RecurringDropdown";

import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { parse, addHours, add } from "date-fns";
import {
  updateDoc,
  doc,
  writeBatch,
  getDocs,
  collection,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../../utils/firebase";
import Modal from "@mui/material/Modal";
import { v4 as uuidv4 } from "uuid";

export default function EditDate({ jobDetails, setJobDetails, setModal }) {
  const { userData } = useContext(UserContext);

  const [editRecurringModal, setEditRecurringModal] = useState(false);
  const [recurrenceData, setRecurrenceData] = useState({
    label: jobDetails?.recurrenceLabel,
    type: jobDetails?.recurrenceType,
    rawDate:
      jobDetails?.recurrenceStartDate instanceof Date
        ? jobDetails?.recurrenceStartDate
        : jobDetails?.recurrenceStartDate?.toDate(),
    dayOfWeek: jobDetails?.recurrenceDayOfWeek,
    dayOfMonth: jobDetails?.recurrenceDayOfMonth,
    weekOfMonth: jobDetails?.recurrenceWeekOfMonth,
    dayOfYear: jobDetails?.recurrenceDayOfYear,
    endDate:
      jobDetails?.recurrenceEndDate instanceof Date
        ? jobDetails?.recurrenceEndDate
        : jobDetails?.recurrenceEndDate?.toDate(),
    occurrences: jobDetails?.recurrenceOccurrences,
    frequency: jobDetails?.recurrenceFrequency,
    forever: jobDetails?.recurrenceForever,
    monthOfYear: jobDetails?.recurrenceMonthOfYear,
    custom: jobDetails?.recurrenceCustom,
  });
  const [loading, setLoading] = useState(false);

  // useEffect(
  //   () => console.log("recurranceData===>", recurrenceData),
  //   [recurrenceData]
  // );

  const generateUniqueId = () => uuidv4();

  // convert the jobStart and jobEnd to date objects in the correct time zone
  const timeZone = userData?.bizData?.timeZone;
  const initialStartTime = utcToZonedTime(new Date(jobDetails?.start), timeZone);
  const initialEndTime = utcToZonedTime(new Date(jobDetails?.end), timeZone);

  // format the time values
  const pattern = "h:mm a";
  const startTime = format(initialStartTime, pattern, { timeZone });
  const endTime = format(initialEndTime, pattern, { timeZone });

  // set the state variables
  const [selectedStartDate, setSelectedStartDate] = useState(initialStartTime);
  const [selectedEndDate, setSelectedEndDate] = useState(initialEndTime);
  const [selectedTimeStart, setSelectedTimeStart] = useState(startTime);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(endTime);

  const [datesWrong, setDatesWrong] = useState(false);
  const [timeHasBeenChanged, setTimeHasBeenChanged] = useState(false);

  const initialCombinedStartTime = new Date(
    selectedStartDate.getFullYear(),
    selectedStartDate.getMonth(),
    selectedStartDate.getDate(),
    initialStartTime.getHours(),
    initialStartTime.getMinutes()
  );
  const [combinedStartTime, setCombinedStartTime] = useState(initialCombinedStartTime);
  const initialCombinedEndTime = addHours(initialCombinedStartTime, 2);
  const [combinedEndTime, setCombinedEndTime] = useState(initialCombinedEndTime);

  useEffect(() => {
    // set the selectedDateEnd to the same date as selectedStartDate when the selectedStartDate changes
    setSelectedEndDate(selectedStartDate);
  }, [selectedStartDate]);

  useEffect(() => {
    // this is where we update the endTime when we change the startTime
    // parse the selectedTimeStart string to a date object
    const startDate = parse(selectedTimeStart, "h:mm a", new Date());

    // calculate the new end date by adding 2 hours to the start date
    const newEndDate = addHours(startDate, 2);

    // format the new end date as a time string using Date FNS
    const newEndTime = format(newEndDate, "h:mm a");

    // update the selectedTimeEnd state variable
    setSelectedTimeEnd(newEndTime);
  }, [selectedTimeStart]);

  useEffect(() => {
    // set the new combined start time and end time every time the selectedStartDate, selectedEndDate, selectedTimeStart, or selectedTimeEnd state variables change
    const newCombinedStartTime = new Date(
      selectedStartDate.getFullYear(),
      selectedStartDate.getMonth(),
      selectedStartDate.getDate(),
      parse(selectedTimeStart, "h:mm a", new Date()).getHours(),
      parse(selectedTimeStart, "h:mm a", new Date()).getMinutes()
    );
    const newCombinedEndTime = new Date(
      selectedEndDate.getFullYear(),
      selectedEndDate.getMonth(),
      selectedEndDate.getDate(),
      parse(selectedTimeEnd, "h:mm a", new Date()).getHours(),
      parse(selectedTimeEnd, "h:mm a", new Date()).getMinutes()
    );
    setCombinedStartTime(newCombinedStartTime);
    setCombinedEndTime(newCombinedEndTime);
  }, [selectedStartDate, selectedEndDate, selectedTimeStart, selectedTimeEnd]);

  useEffect(() => {
    // check if the combined start time is after the combined end time
    if (combinedStartTime > combinedEndTime) {
      setDatesWrong(true);
    } else {
      setDatesWrong(false);
    }
  }, [combinedStartTime, combinedEndTime]);

  // logic for saving

  const recurrenceChanged = recurrenceData?.label !== jobDetails?.recurrenceLabel;

  const handleSaveClick = async () => {
    console.log("handle save click");
    if (datesWrong) {
      alert("The start date must be before the end date");
      return;
    }
    if (loading) return;

    if (timeHasBeenChanged || recurrenceChanged) {
      if (jobDetails?.isRecurring) {
        // this means our job was recurring and we need to ask the user if they want to edit all recurring jobs or just this one
        setEditRecurringModal(true);
      } else if (recurrenceData.label !== "Does not repeat") {
        // this means our job wasn't previously recurring but now it is so we need to update this job and create new recurring jobs

        try {
          setLoading(true);
          let includeCurrentJob = true;
          await addNewRecurringJobs(includeCurrentJob);
          setEditRecurringModal(false);
          setModal(false);
        } catch (error) {
          console.log("error", error);
          alert("There was an error updating the jobs, please try again");
          setEditRecurringModal(false);
          setModal(false);
        } finally {
          setLoading(false);
        }
      } else {
        // Convert combined start and end times back to UTC before saving
        try {
          setLoading(true);
          const utcStart = zonedTimeToUtc(combinedStartTime, timeZone);
          const utcEnd = zonedTimeToUtc(combinedEndTime, timeZone);

          const response = await handleSaveSingleJob(utcStart, utcEnd);
          console.log(response);
        } catch (error) {
          console.log("error", error);
          alert("There was an error updating the jobs, please try again");
          setEditRecurringModal(false);
          setModal(false);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleSaveSingleJob = async () => {
    console.log("handle save date");

    setLoading(true);

    const jobStart = zonedTimeToUtc(combinedStartTime, timeZone);
    const jobEnd = zonedTimeToUtc(combinedEndTime, timeZone);

    console.log("start", jobStart, "end", jobEnd);

    const jobRef = doc(db, "businesses", userData.bizData.id, "jobs", jobDetails.jobId);
    if (recurrenceChanged) {
      const isRecurring = recurrenceData.label === "Does not repeat" ? false : true;
      let foreverEndDate = new Date();
      foreverEndDate.setFullYear(foreverEndDate.getFullYear() + 2);
      let recurrenceEndDate = null;
      if (recurrenceData.forever) {
        recurrenceEndDate = foreverEndDate;
      } else if (recurrenceData.endDate) {
        recurrenceEndDate = recurrenceData.endDate;
      }
      await updateDoc(jobRef, {
        start: jobStart,
        end: jobEnd,
        isRecurring: isRecurring,
        recurrenceType: recurrenceData.type,
        recurrenceLabel: recurrenceData.label,
        recurrenceStartDate: recurrenceData.rawDate,
        recurrenceDayOfWeek: recurrenceData.dayOfWeek,
        recurrenceDayOfMonth: recurrenceData.dayOfMonth,
        recurrenceWeekOfMonth: recurrenceData.weekOfMonth,
        recurrenceDayOfYear: recurrenceData.dayOfYear,
        recurrenceEndDate: recurrenceEndDate,
        recurrenceOccurrences: recurrenceData.occurrences,
        recurrenceFrequency: recurrenceData.frequency,
        recurrenceForever: recurrenceData.forever,
        recurrenceMonthOfYear: recurrenceData.monthOfYear,
        recurrenceJobsCreated: 1,
        recurrenceLastUpdated: new Date(),
        recurrenceId: jobDetails.recurrenceId || generateUniqueId(),
      });
      setJobDetails({
        ...jobDetails,
        start: jobStart,
        end: jobEnd,
        isRecurring: isRecurring,
        recurrenceType: recurrenceData.type,
        recurrenceLabel: recurrenceData.label,
        recurrenceStartDate: recurrenceData.rawDate,
        recurrenceDayOfWeek: recurrenceData.dayOfWeek,
        recurrenceDayOfMonth: recurrenceData.dayOfMonth,
        recurrenceWeekOfMonth: recurrenceData.weekOfMonth,
        recurrenceDayOfYear: recurrenceData.dayOfYear,
        recurrenceEndDate: recurrenceEndDate,
        recurrenceOccurrences: recurrenceData.occurrences,
        recurrenceFrequency: recurrenceData.frequency,
        recurrenceForever: recurrenceData.forever,
        recurrenceMonthOfYear: recurrenceData.monthOfYear,
        recurrenceJobsCreated: 1,
        recurrenceLastUpdated: new Date(),
        recurrenceId: jobDetails.recurrenceId || generateUniqueId(),
      });
    } else {
      await updateDoc(jobRef, {
        start: jobStart,
        end: jobEnd,
      });
      setJobDetails({
        ...jobDetails,
        start: jobStart,
        end: jobEnd,
      });
    }
    setLoading(false);
    setEditRecurringModal(false);
    setModal(false);
  };

  const handleSaveAllRecurringJobs = async () => {
    console.log("handle save recurring date");
    console.log("recurrence changed", recurrenceChanged);
    console.log("time has been changed", timeHasBeenChanged);
    if (loading) return;
    setLoading(true);

    try {
      // this is the logic for deleting all following recurring docs
      await deleteAllFollowingRecurringJobs();

      // then we want to add all the new jobs using the same recurrenceId (still in the jobDetails.recurrenceId)
      await addNewRecurringJobs();

      // then we want to update any previous recurring jobs with the new recurrenceData (but same recurrenceId) So our cron job doesn't get confused
      await updatePreviousRecurringJobs();

      // then we want to update the current job with the new start and end date if it changed
      await handleSaveSingleJob();

      setLoading(false);
      setEditRecurringModal(false);
      setModal(false);
    } catch (error) {
      console.log("error", error);
      alert("There was an error updating the jobs, please try again");
      setEditRecurringModal(false);
      setModal(false);
    }
  };

  const updatePreviousRecurringJobs = async () => {
    console.log("updatePreviousRecurringJobs");
    const dbQuery = query(
      collection(db, "businesses", userData.userData.businessId, "jobs"),
      where("recurrenceId", "==", jobDetails.recurrenceId),
      where("start", "<", jobDetails.start)
    );

    const querySnapshot = await getDocs(dbQuery);

    // Check if there are any documents to update
    if (querySnapshot.empty) {
      console.log("No documents found with the provided recurrenceId.");
      return;
    }

    const batch = writeBatch(db);

    querySnapshot.forEach((docSnapshot) => {
      const docRef = doc(db, "businesses", userData.userData.businessId, "jobs", docSnapshot.id);
      let foreverEndDate = new Date();
      foreverEndDate.setFullYear(foreverEndDate.getFullYear() + 2);
      const isRecurring = recurrenceData.label === "Does not repeat" ? false : true;

      let recurrenceEndDate = null;
      if (recurrenceData.forever) {
        recurrenceEndDate = foreverEndDate;
      } else if (recurrenceData.endDate) {
        recurrenceEndDate = recurrenceData.endDate;
      }

      batch.update(docRef, {
        isRecurring: isRecurring,
        recurrenceType: recurrenceData.type,
        recurrenceLabel: recurrenceData.label,
        recurrenceStartDate: recurrenceData.rawDate,
        recurrenceDayOfWeek: recurrenceData.dayOfWeek,
        recurrenceDayOfMonth: recurrenceData.dayOfMonth,
        recurrenceWeekOfMonth: recurrenceData.weekOfMonth,
        recurrenceDayOfYear: recurrenceData.dayOfYear,
        recurrenceEndDate: recurrenceEndDate,
        recurrenceOccurrences: recurrenceData.occurrences,
        recurrenceFrequency: recurrenceData.frequency,
        recurrenceForever: recurrenceData.forever,
        recurrenceMonthOfYear: recurrenceData.monthOfYear,
        recurrenceLastUpdated: new Date(),
      });
    });

    // Commit the batch
    await batch.commit();
    console.log("All following recurring jobs with the specified recurrenceId have been updated");
  };

  const addNewRecurringJobs = async (includeCurrentJob = false) => {
    console.log("addNewRecurringJobs");

    let jobStart = null;
    let jobEnd = null;
    if (timeHasBeenChanged) {
      jobStart = zonedTimeToUtc(combinedStartTime, timeZone);
      jobEnd = zonedTimeToUtc(combinedEndTime, timeZone);
    } else {
      jobStart = jobDetails.start;
      jobEnd = jobDetails.end;
    }
    console.log("start", jobStart, "end", jobEnd);

    // Create a new recurrenceId if the job is not already recurring
    const recurrenceId = jobDetails.recurrenceId || generateUniqueId();

    console.log("recurranceData===>", recurrenceData);

    const isRecurring = recurrenceData.label === "Does not repeat" ? false : true;

    console.log("time zone", userData.bizData.timeZone);
    const newJobData = {
      // jobId: newJobRef.id,
      dateAdded: serverTimestamp(),
      lastUpdated: serverTimestamp(),
      businessId: userData.userData.businessId,
      customer: jobDetails.customer,
      lineItems: jobDetails.lineItems,
      jobTotal: jobDetails.jobTotal,
      start: jobStart,
      end: jobEnd,
      dispatchedTo: jobDetails.dispatchedTo,
      dispatchedToIdsOnly: jobDetails.dispatchedToIdsOnly,
      leadSource: jobDetails.leadSource,
      note: jobDetails.note,
      timezone: userData.bizData.timeZone,
      isRecurring: isRecurring,
    };

    if (isRecurring) {
      let foreverEndDate = new Date();
      foreverEndDate.setFullYear(foreverEndDate.getFullYear() + 2);

      let recurrenceEndDate = null;
      if (recurrenceData.forever) {
        recurrenceEndDate = foreverEndDate;
      } else if (recurrenceData.endDate) {
        recurrenceEndDate = recurrenceData.endDate;
      }
      Object.assign(newJobData, {
        recurrenceType: recurrenceData.type,
        recurrenceLabel: recurrenceData.label,
        recurrenceStartDate: recurrenceData.rawDate,
        recurrenceDayOfWeek: recurrenceData.dayOfWeek,
        recurrenceDayOfMonth: recurrenceData.dayOfMonth,
        recurrenceWeekOfMonth: recurrenceData.weekOfMonth,
        recurrenceDayOfYear: recurrenceData.dayOfYear,
        recurrenceEndDate: recurrenceEndDate,
        recurrenceOccurrences: recurrenceData.occurrences,
        recurrenceFrequency: recurrenceData.frequency,
        recurrenceForever: recurrenceData.forever,
        recurrenceMonthOfYear: recurrenceData.monthOfYear,
        recurrenceLastUpdated: new Date(),
        recurrenceId: recurrenceId,
      });

      const daysOfWeek = {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
      };

      const frequency = parseInt(newJobData.recurrenceFrequency) || 1;
      const jobDuration = new Date(newJobData.end).getTime() - new Date(newJobData.start).getTime();

      console.log("job duration", jobDuration);

      // Calculate the number of occurrences
      let occurrences;

      if (recurrenceData.occurrences) {
        occurrences = recurrenceData.occurrences;
      } else {
        // If no specific number of occurrences is set, calculate based on the end date
        const startDate = new Date(newJobData.recurrenceStartDate);
        const endDate = new Date(newJobData.recurrenceEndDate);
        const timeDifference = endDate - startDate;

        console.log("startDate", startDate, "endDate", endDate, "timeDifference", timeDifference);

        if (recurrenceData.type.includes("week")) {
          occurrences = Math.floor(timeDifference / (7 * frequency * 24 * 60 * 60 * 1000));
        } else if (recurrenceData.type.includes("month") && recurrenceData.dayOfMonth) {
          let tempDate = new Date(startDate);
          occurrences = 0;
          while (tempDate <= endDate) {
            occurrences++;
            tempDate.setMonth(tempDate.getMonth() + frequency);
          }
        } else if (recurrenceData.type.includes("month") && recurrenceData.weekOfMonth) {
          // ect 3 = third week of every month on recurrenceData.dayOfWeek which would be like Thursday or Friday
          occurrences = Math.floor(timeDifference / (7 * 4 * frequency * 24 * 60 * 60 * 1000));
        } else if (recurrenceData.type.includes("year")) {
          occurrences = Math.ceil(timeDifference / (365 * frequency * 24 * 60 * 60 * 1000));
        }
      }

      console.log("occurances for adding jobs", occurrences);
      // Create and write each job to Firestore

      const batch = writeBatch(db);
      let lastJobRef = null;
      for (let i = 0; i < occurrences; i++) {
        // console.log("newJobData", newJobData);
        // console.log(
        //   "newJobData.recurrenceStartDate",
        //   newJobData.recurrenceStartDate
        // );
        const jobStartDate = new Date(newJobData.start);

        // console.log("jobStartDate", jobStartDate, "i", i);

        if (recurrenceData.type.includes("week")) {
          jobStartDate.setDate(jobStartDate.getDate() + 7 * frequency * i);
        } else if (recurrenceData.type.includes("month") && recurrenceData.dayOfMonth) {
          jobStartDate.setMonth(jobStartDate.getMonth() + frequency * i);
        } else if (recurrenceData.type.includes("month") && recurrenceData.weekOfMonth) {
          // ect 3 = third week of every month on recurrenceData.dayOfWeek which would be like Thursday or Friday

          // Adjust the jobStartDate based on the weekOfMonth and dayOfWeek
          jobStartDate.setMonth(jobStartDate.getMonth() + frequency * i); // Move to the next month based on frequency and iteration
          jobStartDate.setDate(1); // Set to the first day of the month

          // Find the first occurrence of the specified dayOfWeek in the month
          while (jobStartDate.getDay() !== daysOfWeek[recurrenceData.dayOfWeek]) {
            jobStartDate.setDate(jobStartDate.getDate() + 1);
          }

          // Adjust by the specified weekOfMonth
          jobStartDate.setDate(jobStartDate.getDate() + 7 * (recurrenceData.weekOfMonth - 1));
        } else if (recurrenceData.type.includes("year")) {
          jobStartDate.setFullYear(jobStartDate.getFullYear() + frequency * i);
        }

        if (jobStartDate > newJobData.recurrenceEndDate && !recurrenceData.occurrences) {
          console.log("breaking out of loop", jobStartDate);
          break; // Exit the loop if the jobStartDate exceeds the endDate (only if no specific number of occurrences is set)
        }

        const jobEndDate = new Date(jobStartDate.getTime() + jobDuration); // Adjust the end time based on the job duration

        const jobRefIndividualRecurringJob = doc(
          collection(db, "businesses", userData.userData.businessId, "jobs")
        );

        lastJobRef = jobRefIndividualRecurringJob;

        if (i === 0) {
          continue;
        }
        const job = {
          ...newJobData,
          start: jobStartDate,
          end: jobEndDate,
          dateAdded: serverTimestamp(),
          lastUpdated: serverTimestamp(),
          jobId: jobRefIndividualRecurringJob.id,
        };

        if (i === 1) {
          job.recurrenceFirstJob = true;
        } else {
          job.recurrenceFirstJob = false;
        }
        // if (i === occurrences - 1) {
        //   console.log("setting the recurrenceLastJob true for the last job");
        //   job.recurrenceLastJob = true;
        // }

        console.log("adding job for start date===>", job.start, "and end date===>", job.end);

        // add each individual job to batch
        batch.set(jobRefIndividualRecurringJob, job);
      }

      if (lastJobRef) {
        // update just that job with recurrenceLastJob = true
        batch.update(lastJobRef, {
          recurrenceLastJob: true,
        });
      }

      // Commit the batch
      await batch.commit();
      console.log("commited the batch");

      // if includeCurrent job is true then we want to update the current job with the new reccurence data and the new start and end date

      if (includeCurrentJob) {
        const currentJobRef = doc(
          db,
          "businesses",
          userData.userData.businessId,
          "jobs",
          jobDetails.jobId
        );
        await updateDoc(currentJobRef, {
          start: jobStart,
          end: jobEnd,
          lastUpdated: serverTimestamp(),
          isRecurring: isRecurring,
          recurrenceType: recurrenceData.type,
          recurrenceLabel: recurrenceData.label,
          recurrenceStartDate: recurrenceData.rawDate,
          recurrenceDayOfWeek: recurrenceData.dayOfWeek,
          recurrenceDayOfMonth: recurrenceData.dayOfMonth,
          recurrenceWeekOfMonth: recurrenceData.weekOfMonth,
          recurrenceDayOfYear: recurrenceData.dayOfYear,
          recurrenceEndDate: recurrenceEndDate,
          recurrenceOccurrences: recurrenceData.occurrences,
          recurrenceFrequency: recurrenceData.frequency,
          recurrenceForever: recurrenceData.forever,
          recurrenceMonthOfYear: recurrenceData.monthOfYear,
          recurrenceLastUpdated: new Date(),
          recurrenceId: recurrenceId,
        });

        setJobDetails({
          ...jobDetails,
          start: jobStart,
          end: jobEnd,
          lastUpdated: new Date(),
          isRecurring: isRecurring,
          recurrenceType: recurrenceData.type,
          recurrenceLabel: recurrenceData.label,
          recurrenceStartDate: recurrenceData.rawDate,
          recurrenceDayOfWeek: recurrenceData.dayOfWeek,
          recurrenceDayOfMonth: recurrenceData.dayOfMonth,
          recurrenceWeekOfMonth: recurrenceData.weekOfMonth,
          recurrenceDayOfYear: recurrenceData.dayOfYear,
          recurrenceEndDate: recurrenceEndDate,
          recurrenceOccurrences: recurrenceData.occurrences,
          recurrenceFrequency: recurrenceData.frequency,
          recurrenceForever: recurrenceData.forever,
          recurrenceMonthOfYear: recurrenceData.monthOfYear,
          recurrenceLastUpdated: new Date(),
          recurrenceId: recurrenceId,
        });
      }
    }
  };

  const deleteAllFollowingRecurringJobs = async () => {
    const dbQuery = query(
      collection(db, "businesses", userData.userData.businessId, "jobs"),
      where("recurrenceId", "==", jobDetails.recurrenceId),
      where("start", ">", jobDetails.start)
    );

    const querySnapshot = await getDocs(dbQuery);

    // Check if there are any documents to delete
    if (querySnapshot.empty) {
      console.log("No documents found with the provided recurrenceId.");
      return;
    }

    const batch = writeBatch(db);

    querySnapshot.forEach((docSnapshot) => {
      const docRef = doc(db, "businesses", userData.userData.businessId, "jobs", docSnapshot.id);
      batch.delete(docRef);
    });

    // Commit the batch
    await batch.commit();
    console.log("All following recurring jobs with the specified recurrenceId have been deleted");
  };

  const [jobStartForRecurringDropdown, setJobStartForRecurringDropdown] = useState(
    zonedTimeToUtc(combinedStartTime, timeZone)
  );

  useEffect(() => {
    setJobStartForRecurringDropdown(zonedTimeToUtc(combinedStartTime, timeZone));
  }, [combinedStartTime]);
  // console.log("recurrence data from editDate", recurrenceData);

  return (
    <>
      <div className="flex flex-col flex-1 bg-white   gap-2 p-4 ">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-2 mt-2 mb-2">
            <MdDateRange className="inline-block text-2xl" />
            <h1 className="text-xl font-bold">Select Date</h1>
          </div>
          <RecurringDropdown
            startTime={jobStartForRecurringDropdown}
            setRecurrenceData={setRecurrenceData}
            recurrenceData={recurrenceData}
            initialLabel={
              jobDetails?.recurrenceLabel && jobDetails?.recurrenceType
                ? {
                    label: jobDetails.recurrenceLabel,
                    type: jobDetails?.recurrenceType,
                  }
                : null
            }
          />
        </div>
        <div className="flex flex-row items-center">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center">
              <h1
                className="w-16"
                // style={{
                //   marginRight: "5px",
                //   width: "100px",
                // }}
              >
                Start
              </h1>
              <DatePicker
                placeholderText="Start Date"
                className={`date-picker ${
                  datesWrong ? "bg-red-300" : "bg-white"
                } border border-gray-300 rounded-md pl-4 py-2  text-left focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent `}
                selected={selectedStartDate}
                // onChange={(start) => setNewEvent({ ...newEvent, start })}
                onChange={(start) => {
                  setSelectedStartDate(start);
                  setTimeHasBeenChanged(true);
                }}
              />
              <CustomDropdown
                initial={"9:00 AM"}
                selectedTime={selectedTimeStart}
                setSelectedTime={setSelectedTimeStart}
                timesWrong={datesWrong}
                setTimeHasBeenChanged={setTimeHasBeenChanged}
              />
            </div>
            <div className="flex flex-row items-center mb-4">
              <h1
                className="w-16"
                // style={{
                //   marginRight: "5px",
                //   width: "100px",
                // }}
              >
                End
              </h1>
              <DatePicker
                placeholderText="End Date"
                className={`date-picker ${
                  datesWrong ? "bg-red-300" : "bg-white"
                } border border-gray-300 rounded-md pl-4 py-2  text-left focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent `}
                selected={selectedEndDate}
                onChange={(end) => {
                  setSelectedEndDate(end);
                  setTimeHasBeenChanged(true);
                }}
              />
              <CustomDropdown
                initial={"11:00 AM"}
                selectedTime={selectedTimeEnd}
                setSelectedTime={setSelectedTimeEnd}
                timesWrong={datesWrong}
                setTimeHasBeenChanged={setTimeHasBeenChanged}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={handleSaveClick}
        disabled={datesWrong || loading}
        className="bg-gray-900 hover:text-yellow-100 text-white font-bold py-2 px-4 rounded"
        style={{
          position: "absolute",
          bottom: "40px",
          right: "40px",
        }}
      >
        <span className="">Save</span>
      </button>

      <Modal open={editRecurringModal} onClose={() => setEditRecurringModal(false)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 450,
            backgroundColor: "#fff", // Assuming "background.paper" refers to a white or light color
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Adjust as needed
            paddingTop: 20, // 4 * 4 = 16 assuming a spacing system where 4 is the base unit
            paddingLeft: 32, // 4 * 4 = 16
            paddingRight: 32, // 4 * 4 = 16
            paddingBottom: 20, // 4 * 3 = 12
          }}
        >
          <h1 className="mb-6 font-bold text-lg">
            Do you want to edit all recurring or just this job?
          </h1>
          <div className="flex flex-row items-center justify-center">
            <button
              className="bg-gray-900 rounded-md px-3 py-3 justify-center shadow-lg flex items-center text-white whitespace-nowrap hover:text-yellow-100"
              onClick={handleSaveAllRecurringJobs}
              disabled={loading}
            >
              All recurring
            </button>
            <button
              className="bg-gray-900 rounded-md  px-3 py-3 justify-center shadow-lg flex items-center text-white whitespace-nowrap ml-6  hover:text-yellow-100 "
              onClick={handleSaveSingleJob}
              disabled={loading}
            >
              Just this job
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

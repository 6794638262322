import React, { useState, useEffect, useContext } from "react";

import {
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth, db } from "../../utils/firebase";
import { setDoc, doc, Timestamp } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
// import { UserContext } from "../index";
import { useUserContext } from "context/UserContext";
import PersonalDetails from "../../components/registerComponents/PersonalDetails";
import BusinessDetails from "../../components/registerComponents/BusinessDetails";
import { node } from "../../constants/constants";
import { TextField } from "../../components/reusable/fields";
import { ImSpinner } from "react-icons/im";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { createFreeTrialCheckoutSession } from "utils/stripe";

const acceptedTimezones = [
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Louisville",
  "America/Indiana/Indianapolis",
  "America/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Knox_IN",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Shiprock",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "America/Atka",
  "Pacific/Honolulu",
  "America/Toronto",
  "America/Montreal",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Fort_Wayne",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "America/St_Johns",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Halifax",
  "Atlantic/Bermuda",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Pacific/Honolulu",
  "US/Alaska",
  "US/Arizona",
  "US/Central",
  "US/Eastern",
  "US/Hawaii",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
  "US/East-Indiana",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Aleutian",
  "America/Coral_Harbour",
];

export default function Register() {
  const navigate = useNavigate();
  // const { user, userData, setUserData, setRerun } = useContext(UserContext);
  const { user, userData, setUserData, setRerun } = useUserContext();

  // logic for promotion
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const promo = params.get("promo");
  const redirect = params.get("redirect");

  console.log("redirect", redirect);

  // console.log("promo", promo);

  /* global gr */
  // tracking data
  useEffect(() => {
    (function (w, d, s, p, t) {
      w.gr =
        w.gr ||
        function () {
          w.gr.q = w.gr.q || [];
          w.gr.q.push(arguments);
        };
      p = d.getElementsByTagName(s)[0];
      t = d.createElement(s);
      t.async = true;
      t.src = "https://app.getreditus.com/gr.js?_ce=90";
      p.parentNode.insertBefore(t, p);
    })(window, document, "script");
    gr("track", "pageview");
  }, []);

  // console.log("userData", userData);
  //create Personal details
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneBeta, setPhoneBeta] = useState("");
  const [country, setCountry] = useState("US");
  const [step, setStep] = useState(1);

  //create business details state
  const [companyName, setCompanyName] = useState("");
  const [companyPhoneBeta, setCompanyPhoneBeta] = useState("");
  const [companyPhoneCountry, setCompanyPhoneCountry] = useState("US");
  const [industry, setIndustry] = useState("Window Cleaning");
  const [companySize, setCompanySize] = useState("Just you");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");

  const [notFilledIn, setNotFilledIn] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verifyModal, setVerifyModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [verifySuccess, setVerifySuccess] = useState(false);

  const [termsAccepted, setTermsAccepted] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return regex.test(phoneNumber);
  };

  // console.log("companySize", companySize);
  //Submit the form and register the user
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    if (
      !password ||
      !companyName ||
      !companyPhoneBeta ||
      !industry ||
      !companySize
    ) {
      setError("Please fill out all fields");
      return;
    }
    if (!isValidPhoneNumber(companyPhoneBeta)) {
      setError("Please enter a valid phone number");
      return;
    }
    if (!address) {
      setError("Please enter a valid address");
      setNotFilledIn(true);
      return;
    }

    if (password.length < 6) {
      setError("Password must be at least 6 characters");
      return;
    }
    const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!acceptedTimezones.includes(timezone)) {
      setError(
        "You are blocked from using this service. Please contact support at support@homebase360.io"
      );
      return;
    }

    setError(false);

    setIsLoading(true);

    //////////////////////////////////////////////////////////////////
    console.log("about to create new business");
    await createNewBusiness();

    return;
    /////////////////////////////////////////////////////////////
    // add the prefix to the personal and business phone numbers
    let prefix = "+1";
    let modified = phoneBeta.replace(/\D/g, "");
    const final_number = prefix + modified;
    let modifiedCompany = companyPhoneBeta.replace(/\D/g, "");
    const final_company_number = prefix + modifiedCompany;

    try {
      // send verification text to phone number( final_number ) -- fetch request to backend send-verification endpoint
      const verificationText = await fetch(`${node}/auth/send-verification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: final_number,
        }),
      });

      const { status, error } = await verificationText.json();
      // console.log("status", status);
      // console.log("error", error);

      if (!error && status === "pending") {
        setVerifyModal(true);
      }
      if (error) {
        setError(error);
        setIsLoading(false);
        return;
      }
    } catch (err) {
      console.log(err);
      setError(err);
      setIsLoading(false);
      return;
    }
  };

  const handleVerify = async () => {
    console.log("handleVerify");

    // add the prefix to the personal and business phone numbers
    let prefix = "+1";
    let modified = phoneBeta.replace(/\D/g, "");
    const final_number = prefix + modified;

    setIsVerifying(true);

    try {
      const verification = await fetch(`${node}/auth/verify-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: final_number,
          otp: verificationCode,
        }),
      });

      const { status, error } = await verification.json();

      if (error) {
        console.log("error", error);
        setVerifyError("Invalid verification code: " + error);
        return;
      }

      if (status === "approved") {
        // console.log("status", status);
        setVerifySuccess(true);
        setVerifyError(false);
        setVerifyModal(false);

        createNewBusiness();
      } else {
        // console.log("status", status);
        setVerifyError("Invalid verification code. Status: " + status);
      }
    } catch (err) {
      console.log(err);
      setVerifyError(true);
    } finally {
      setIsVerifying(false);
    }
  };

  const createNewBusiness = async () => {
    setIsLoading(true);
    // add the prefix to the personal and business phone numbers
    let prefix = "+1";
    let modified = phoneBeta.replace(/\D/g, "");
    const final_number = prefix + modified;
    let modifiedCompany = companyPhoneBeta.replace(/\D/g, "");
    const final_company_number = prefix + modifiedCompany;

    try {
      const userCred = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // console.log(userCred.user.uid);
      // console.log({ ...userCred.user });
      // sendEmailVerification(auth.currentUser).then(() => {
      //   console.log("email verification sent")
      // })

      //promise all

      // create the currency value based on their location

      // split the address by spaces and get the last element
      let country = address.split(" ").pop();

      // set the default currency as "usd"
      let currency = "usd";

      // check if the last element in the address is "CA" for Canadian addresses
      if (country === "CA") {
        currency = "cad";
      }

      const defaultEstimateSettings = {
        businessAddress: true,
        businessEmail: true,
        businessName: true,
        businessPhone: true,
        customerAddress: true,
        customerEmail: true,
        customerPhone: true,
        customerDisplayName: true,
        estimateDate: true,
        estimateNumber: true,
        footerBusinessName: true,
        footerBusinessWebsite: false,
        lineItemsAmount: true,
        lineItemsDescription: true,
        lineItemsQuantity: true,
        lineItemsUnitPrice: true,
        subtotal: true,
        tax: true,
        logo: false,
      };

      const defaultInvoiceSettings = {
        businessEmail: true,
        businessName: true,
        businessPhone: true,
        businessAddress: true,
        customerAddress: true,
        customerDisplayName: true,
        customerEmail: true,
        customerPhone: true,
        footerBusinessName: true,
        footerBusinessWebsite: false,
        invoiceNumber: true,
        lineItemsAmount: true,
        lineItemsDescription: true,
        lineItemsQuantity: true,
        lineItemsUnitPrice: true,
        logo: false,
        subtotal: true,
        tax: true,
        savePaymentMethod: false,
      };

      const businessData = {
        estimateSettings: defaultEstimateSettings,
        invoiceSettings: defaultInvoiceSettings,
        fromWeb: true,
        id: userCred.user.uid,
        email: userCred.user.email,
        firstName: firstName,
        lastName: lastName,
        phone: final_number,
        companyName: companyName,
        companyPhone: final_company_number,
        companyWebsite: website,
        address: address,
        industry: industry,
        companySize: companySize,
        createdAt: Timestamp.now(),
        timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
        twilioNumber: "",
        taxRates: [],
        yelpReviewLink: "",
        googleReviewLink: "",
        facebookReviewLink: "",
        currency: currency,
      };

      // we automatically register them if they come from the games page
      // if (redirect === "games") {
      //   businessData.hbGames = {
      //     registered: true,
      //   };
      // }

      // add promotion to the businessData object if it equals windowcleaning
      if (promo === "josh") {
        console.log("promotion", promo);
        businessData.promo = "josh";
      }

      const userDataForDb = {
        id: userCred.user.uid,
        email: userCred.user.email,
        firstName: firstName,
        lastName: lastName,
        phone: final_number,
        userType: "Admin",
        isAdmin: true,
        businessId: userCred.user.uid,
        createdAt: Timestamp.now(),
        color: {
          label: "Blue",
          value: "#3b82f6",
        },
      };

      const nickname =
        companySize === "Just you"
          ? "Starter"
          : companySize === "2-7 people"
          ? "Growing"
          : "Enterprise";

      // manually set the userData context so that we can access it, before all the database syncing happens
      // this doesn't really matter bc we are going to navigate to stripe and then it will refresh when we come back anyways..
      setUserData({
        userData: userDataForDb,
        bizData: businessData,
        subData: {
          status: "not_started", // "manual trial" --> used to be this before
          // manualTrialStart: new Date(),
          plan: { nickname: "starter" },
        },
      });

      // We should move this doc creating logic to the backend server for security purposes. We could create a firebase function that creates the userDoc and the businessDoc. That way we can tighten our firebase security rules and not allow anyone on the client  write to the db unless they are logged in and have a matching businessId in their useDoc.

      // what happens if we don't await this and the navigate to the next page? will it still happen? ( we don't need to wait for this)
      await Promise.all([
        // createCustomToken(userCred.user.uid),

        setDoc(doc(db, "businesses", userCred.user.uid), businessData),

        setDoc(doc(db, "users", userCred.user.uid), userDataForDb),
      ]);

      // If user data creation was successful, track the conversion
      if (typeof window.gr === "function") {
        console.log("✅ gr function is defined");
        window.gr("track", "conversion", { email: userCred.user.email });
      } else {
        console.log(
          "⛔️ gr function is NOT defined. The Tracking Script might not be running on this page."
        );
      }

      // once the business doc is created a firestore cloud function is triggered that adds stripe info and sends text message notifications.

      // Here is where we need to send the user to the stripe checkout page so that they can add their payment info and start their trial.

      const intent = redirect === "/games" ? "games" : "default";

      // console.log("intent", intent);

      const handleCreateSession = async () => {
        const businessId = userCred.user.uid;
        const priceId = process.env.REACT_APP_STARTER_MONTH;

        const { url, error } = await createFreeTrialCheckoutSession({
          businessId,
          priceId,
          intent,
        });
        if (url) {
          console.log("Checkout session created:", url);
          window.location.href = url;
        } else {
          // Handle error here
          console.log(error);
        }
      };

      await handleCreateSession();

      // pass the below state to the homepage so that it will trigger the onboarding modal
      // console.log("userData from register", userData);
      // navigate("/", {
      //   state: {
      //     setUp: true,
      //   },
      // });
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      setError(errorMessage);
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  if (verifyModal) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  return (
    <>
      <div className="relative flex min-h-screen h-full bg-white  justify-center pt-16  overflow-auto">
        {step === 1 ? (
          <PersonalDetails
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            phoneBeta={phoneBeta}
            setPhoneBeta={setPhoneBeta}
            email={email}
            setEmail={setEmail}
            setStep={setStep}
            error={error}
            setError={setError}
            country={country}
            setCountry={setCountry}
          />
        ) : (
          <BusinessDetails
            companyPhoneBeta={companyPhoneBeta}
            setCompanyPhoneBeta={setCompanyPhoneBeta}
            companyName={companyName}
            setCompanyName={setCompanyName}
            companyPhoneCountry={companyPhoneCountry}
            setCompanyPhoneCountry={setCompanyPhoneCountry}
            password={password}
            setPassword={setPassword}
            passwordShown={passwordShown}
            togglePassword={togglePassword}
            address={address}
            setAddress={setAddress}
            website={website}
            setWebsite={setWebsite}
            companySize={companySize}
            setCompanySize={setCompanySize}
            industry={industry}
            setIndustry={setIndustry}
            handleSubmit={handleSubmit}
            error={error}
            setStep={setStep}
            isLoading={isLoading}
            notFilledIn={notFilledIn}
            termsAccepted={termsAccepted}
            setTermsAccepted={setTermsAccepted}
          />
        )}
      </div>

      {verifyModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="relative bg-white rounded-lg p-8 px-12 flex flex-col max-w-lg">
            {" "}
            <AiOutlineClose
              className="absolute top-2 right-2 text-gray-900 text-xl cursor-pointer"
              onClick={() => {
                setIsLoading(false);
                setVerifyModal(false);
                setVerifyError("");
                setVerificationCode("");
              }}
            />
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-2xl font-bold text-gray-800 text-center">
                Please enter the verification code sent to your phone
              </h1>
              <div className="w-36 pt-8">
                <TextField
                  label=""
                  id="verification_code"
                  name="verification_code"
                  type="text"
                  autoComplete="none"
                  className="col-span-full"
                  onChange={(e) => setVerificationCode(e.target.value)}
                  value={verificationCode}
                  required
                />
              </div>
              {/* <button
                onClick={() => {
                  setVerifyModal(false);
                  setIsLoading(false);
                }}
              >
                Cancel
              </button> */}

              <button
                disabled={isVerifying}
                type="submit"
                variant="solid"
                color="slate"
                className={` group mt-10  inline-flex w-36 items-center justify-center gap-2 rounded-full
              bg-neutral-900 py-2 px-4 text-sm font-semibold text-white hover:bg-neutral-700 hover:text-neutral-100 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-900 active:bg-neutral-800 active:text-neutral-300 ${
                isVerifying && "cursor-not-allowed bg-neutral-700"
              }`}
                onClick={handleVerify}
              >
                {isVerifying ? (
                  <ImSpinner className="animate-spin-slow text-lg" />
                ) : (
                  <span>Verify</span>
                )}
              </button>
              {verifyError && (
                <p className="text-red-500 text-sm mt-2">{verifyError}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: '"Inter var", system-ui, sans-serif',
  },
  palette: { yellow: { main: "#eab308" } },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontFamily: '"Inter var", system-ui, sans-serif',
          "& .MuiDataGrid-cell": {
            fontFamily: '"Inter var", system-ui, sans-serif',
          },
          "& .MuiDataGrid-columnHeader": {
            fontFamily: '"Inter var", system-ui, sans-serif',
          },
          // You can add more specific selectors here if needed
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: '"Inter var", system-ui, sans-serif',
          // fontSize: "0.875rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: '"Inter var", system-ui, sans-serif',
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#64748b", // gray-500 for unfocused color
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fef08a",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#1f2937", // gray-800 -- hover I think?
          "&.Mui-focused": {
            color: "#1f2937", // gray-800 -- label color when input is focused
          },
        },
      },
    },

    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
            backgroundColor: "#facc15", // Your custom selected day color
            color: "white", // Text color for the selected day
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Inter var", system-ui, sans-serif', // Ensuring font consistency
          "&.Mui-selected": {
            backgroundColor: "#fef9c3", // tailwind yellow-100
          },
          "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
            backgroundColor: "#fef9c3", // Yellow for selected state
            color: "#1f2937", // Ensuring text color is consistent and readable
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#e5e7eb", // gray-200
          },
        },
      },
    },

    // MuiAutocomplete: { // this doesn't work... smh
    //   styleOverrides: {
    //     listbox: {
    //       "& .MuiAutocomplete-option": {
    //         "&.Mui-selected": {
    //           backgroundColor: "#fef3c7",
    //         },
    //         "&.Mui-selected:hover": {
    //           backgroundColor: "#e5e7eb",
    //         },
    //       },
    //     },
    //   },
    // },
  },
});

export default theme;

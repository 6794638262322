import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingSpinner({ size = 22, color = "#fef9c3" }) {
  return (
    <>
      <CircularProgress
        style={{
          color: color,
        }}
        size={size}
      />
    </>
  );
}

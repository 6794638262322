const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const revenueColumns = [
  {
    field: "rank",
    headerName: "Rank",
    width: 70,
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: "companyName",
    headerName: "Business",
    //   width: 170,
    minWidth: 200,
    sortable: false,
    editable: false,
    filterable: true,
    disableColumnMenu: false,
    flex: 2,
  },
  {
    field: "firstName",
    headerName: "Name",
    //   width: 80,
    minWidth: 80,
    sortable: false,
    editable: false,
    filterable: true,
    disableColumnMenu: false,
    valueFormatter: (params) => {
      return `${params.value.slice(0, 1).toUpperCase()}${params.value.slice(
        1
      )}`;
    },
    flex: 1,
  },
  {
    field: "lastDayRevenue",
    headerName: "Last 24h",
    width: 120,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
    valueFormatter: (params) => {
      return currencyFormatter.format(params.value);
    },
  },
  {
    field: "totalRevenue",
    headerName: "Total Revenue",
    width: 130,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
    valueFormatter: (params) => {
      return currencyFormatter.format(params.value);
    },
  },
];

export const knockingColumns = [
  {
    field: "rank",
    headerName: "Rank",
    width: 70,
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: "userName",
    headerName: "Name",
    width: 150,
    sortable: false,
    editable: false,
    filterable: true,
    disableColumnMenu: false,
    flex: 1,
  },
  {
    field: "companyName",
    headerName: "Business",
    minWidth: 200,
    sortable: false,
    editable: false,
    filterable: true,
    disableColumnMenu: false,
    flex: 2,
  },
  {
    field: "Sale",
    headerName: "Sales",
    width: 100,
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: "Quote",
    headerName: "Quotes",
    width: 100,
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: "total",
    headerName: "Total Doors",
    width: 120,
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
  },
];

import React from "react";
import PageLayout from "layouts/PageLayout";

export default function SubLoadingSkeleton() {
  return (
    <PageLayout>
      <div className="flex  max-w-[1510px] flex-col md:grid md:grid-cols-6 mx-5 mt-20 pt-3 gap-8 pb-8">
        <div className=" md:col-start-1 md:col-end-3 flex-none">
          <div className="border rounded-md p-5 shadow-lg bg-gray-50 flex-1 h-64">
            <div className="animate-pulse">
              <div className="h-6 bg-gray-200 rounded"></div>
              <div className="h-3 mt-4 bg-gray-300 rounded w-3/4"></div>
              <div className="h-3 mt-2 bg-gray-300 rounded w-3/5"></div>
            </div>
          </div>
        </div>

        <div className="max-w-[1510px] md:col-start-3 md:col-end-7 ">
          <div className="flex flex-col gap-8">
            <div className="border rounded-md p-5 shadow-lg bg-gray-50 flex-1">
              <div className="animate-pulse h-72">
                <div className="h-6 bg-gray-200 rounded"></div>
                <div className="h-3 mt-4 bg-gray-300 rounded w-3/4"></div>
                <div className="h-3 mt-2 bg-gray-300 rounded w-3/5"></div>

                <div className="h-3 mt-12 bg-gray-300 rounded w-3/4 "></div>
                <div className="h-3 mt-2 bg-gray-300 rounded w-3/5"></div>
                <div className="h-3 mt-12 bg-gray-300 rounded w-3/4 "></div>
                <div className="h-3 mt-2 bg-gray-300 rounded w-3/5"></div>
              </div>
            </div>
            <div className="border rounded-md p-5 shadow-lg bg-gray-50 flex-1 w-full h-64">
              <div className="animate-pulse">
                <div className="h-6 bg-gray-200 rounded"></div>
                <div className="h-3 mt-4 bg-gray-300 rounded w-3/4"></div>
                <div className="h-3 mt-2 bg-gray-300 rounded w-3/5"></div>
              </div>
            </div>
            <div className="border rounded-md p-5 shadow-lg bg-gray-50 flex-1 h-64">
              <div className="animate-pulse">
                <div className="h-6 bg-gray-200 rounded"></div>
                <div className="h-3 mt-4 bg-gray-300 rounded w-3/4"></div>
                <div className="h-3 mt-2 bg-gray-300 rounded w-3/5"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

import React, { useState, useEffect, forwardRef } from "react";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";

const TransitionComponent = forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));
export default function SlideOver({ open, onClose = () => {}, children }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiBackdrop-root": {
          outline: "none !important", // Attempts to override backdrop focus style
        },
      }}
    >
      <TransitionComponent in={open}>
        <div
          className={`absolute  top-0 sm:w-3/4 sm:left-1/4 w-full h-full items-center bg-white rounded-t-md rounded-bl-md outline-none`}
        >
          {children}
        </div>
      </TransitionComponent>
    </Modal>
  );
}

import React from "react";
import SlideOver from "components/modals/SlideOver";
import SubscriptionDescription from "features/ServiceSubscription/components/SubDetails/SubscriptionDescription";
import Visits from "features/ServiceSubscription/components/SubDetails/Visits";
import InvoiceHistory from "features/ServiceSubscription/components/SubDetails/InvoiceHistory";
import ActivityHistory from "features/ServiceSubscription/components/SubDetails/ActivityHistory";
// import SubLoadingSkeleton from "features/ServiceSubscription/components/SubDetails/SubLoadingSkeleton";
import Header from "../shared/Header";
import { useFetchHistory } from "features/ServiceSubscription/components/hooks/useFetchHistory";
import { useFetchInvoices } from "features/ServiceSubscription/components/hooks/useFetchInvoices";

export default function SubDetailsPopOverModal({
  open,
  onClose,
  subscription,
  setSubscription,
}) {
  const { history, loadingHistory } = useFetchHistory({
    businessId: subscription?.businessId,
    subscriptionId: subscription?.subscriptionId,
  });

  const { invoices, loadingInvoices } = useFetchInvoices({
    businessId: subscription?.businessId,
    subscriptionId: subscription?.subscriptionId,
  });

  const subscriptionId =
    subscription?.subscriptionId ||
    subscription?.selectedSubscription?.subscriptionId ||
    false;
  const url = subscriptionId ? `/servicesubscription/${subscriptionId}` : false;

  return (
    <SlideOver open={open} onClose={onClose}>
      <Header
        title="Subscription Details"
        onClose={onClose}
        openInNewTab={url}
      />
      <div
        className="flex flex-col flex-grow gap-8 w-full px-12 overflow-auto py-12"
        style={{ maxHeight: "calc(100% - 4rem)" }}
      >
        <SubscriptionDescription
          subscription={subscription}
          setSubscription={() => {}}
        />
        <Visits subscription={subscription} setSubscription={setSubscription} />
        <InvoiceHistory invoices={invoices} loading={loadingInvoices} />
        <ActivityHistory history={history} loading={loadingHistory} />
      </div>
    </SlideOver>
  );
}

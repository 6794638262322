import React, { useState, useEffect, useRef } from "react";
import { BsSquareFill } from "react-icons/bs";

function ColorDropdown({ initial, selectedColor, setSelectedColor }) {
  const [showOptions, setShowOptions] = useState(false);
  // tailwind colors 500
  const options = [
    { label: "Blue", value: "#3b82f6" },
    { label: "Orange", value: "#f97316" },
    { label: "Purple", value: "#d946ef" }, // this is fuchsia but we will keep it as purple since we had it as that previously
    { label: "Yellow", value: "#facc15" },
    { label: "Teal", value: "#14b8a6" },
    { label: "Violet", value: "#8b5cf6" },
    { label: "Lime", value: "#84cc16" },
    { label: "Pink", value: "#ec4899" },
    { label: "Cyan", value: "#06b6d4" },
    { label: "Red", value: "#ef4444" }, // start of new colors
    { label: "Green", value: "#22c55e" },
    { label: "Sky", value: "#0ea5e9" },
    { label: "Indigo", value: "#6366f1" },
    { label: "Fuchsia", value: "#a855f7" }, // this is purple but we will keep it as fuchsia
    { label: "Rose", value: "#fda4af" },
    { label: "Emerald", value: "#10b981" },
    { label: "Amber", value: "#f59e0b" },
    { label: "Slate", value: "#64748b" },
    { label: "Dark Blue", value: "#1e3a8a" }, // blue 900
    { label: "Dark Green", value: "#14532d" }, // green 900
  ];

  const editButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    //create event listener for click outside of dropdown to close it
    const handleClickOutside = (e) => {
      if (
        showOptions &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !editButtonRef.current.contains(e.target)
      ) {
        console.log("clicked outside");
        setShowOptions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showOptions, dropdownRef]);

  useEffect(() => {
    if (showOptions && selectedColor) {
      const selectedOptionIndex = options.indexOf(selectedColor);
      if (selectedOptionIndex > -1) {
        const optionElements = dropdownRef.current.querySelectorAll("li");
        const selectedOption = optionElements[selectedOptionIndex];
        const container = dropdownRef.current;
        const scrollTop = selectedOption.offsetTop - container.offsetTop;
        container.scrollTop = scrollTop;
        selectedOption.classList.add("bg-gray-100");
      }
    }
  }, [showOptions, selectedColor]);

  const handleOptionClick = (option) => {
    setSelectedColor(option);
    setShowOptions(false);
  };

  const handleDropdownClick = (event) => {
    event.preventDefault();
    setShowOptions(!showOptions);
  };

  return (
    <div className="relative">
      <button
        onClick={handleDropdownClick}
        className={`${"bg-white"} border border-gray-300 rounded-md px-4 h-14 w-48 text-center focus:outline-none focus:ring-1 focus:ring-yellow-300 focus:border-transparent`}
        ref={editButtonRef}
      >
        {" "}
        {selectedColor?.value ? (
          <div className="flex flex-row items-center gap-3">
            <BsSquareFill color={selectedColor.value} className="text-lg" />
            <h1 className="text-gray-700">{selectedColor.label}</h1>
          </div>
        ) : (
          <h1 className="text-gray-400">Select Color</h1>
        )}
      </button>
      {showOptions && (
        <ul
          className="absolute mt-1 w-48 rounded-md bg-white shadow-lg max-h-60 overflow-y-auto z-10"
          ref={dropdownRef}
        >
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => handleOptionClick(option)}
              className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer flex items-center gap-3 flex-row"
            >
              <BsSquareFill color={option.value} className="text-lg" />
              <h1 className="text-gray-700">{option.label}</h1>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ColorDropdown;

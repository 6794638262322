import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "utils/firebase";

export function useFetchData(businessId) {
  const [employees, setEmployees] = useState([]);
  const [subscriptionTemplates, setSubscriptionTemplates] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const employeesQuery = query(
          collection(db, "users"),
          where("businessId", "==", businessId)
        );
        const employeesSnapshot = await getDocs(employeesQuery);
        const employees = employeesSnapshot.docs
          .map((doc) => doc.data())
          .sort((a, b) => a.firstName.localeCompare(b.firstName));
        setEmployees(employees);
      } catch (error) {
        console.error("Error fetching employees", error);
      }
    };

    const fetchSubscriptionTemplates = async () => {
      try {
        const subscriptionTemplatesRef = collection(
          db,
          "businesses",
          businessId,
          "subscriptionTemplates"
        );
        const subscriptionTemplatesSnapshot = await getDocs(
          subscriptionTemplatesRef
        );
        const subscriptionTemplates = subscriptionTemplatesSnapshot.docs.map(
          (doc) => doc.data()
        );
        setSubscriptionTemplates(subscriptionTemplates);
      } catch (error) {
        console.error("Error fetching subscription templates", error);
      }
    };

    fetchEmployees();
    fetchSubscriptionTemplates();
  }, [businessId]); // Dependency array to re-fetch if businessId changes

  return { employees, subscriptionTemplates, setSubscriptionTemplates };
}

import React from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordionCustomerDetails";

import DisplayAttachments from "components/reusable/DisplayAttachments";
import DisplayTags from "components/reusable/DisplayTags";

export default function ViewAttachmentsAndTagsAccordion({ attachments, tags }) {
  return (
    <Accordion type="multiple" collapsible="true" className="w-full">
      {attachments && attachments?.length > 0 && (
        <AccordionItem value="item-1">
          <AccordionTrigger>Attachments</AccordionTrigger>
          <AccordionContent>
            <div className="py-5">
              <DisplayAttachments attachments={attachments} />
            </div>
          </AccordionContent>
        </AccordionItem>
      )}
      {tags && tags?.length > 0 && <hr className="border-gray-200 mt-2" />}
      {tags && tags?.length > 0 && (
        <AccordionItem value="item-2">
          <AccordionTrigger>Tags</AccordionTrigger>
          <AccordionContent>
            <div className="py-5">
              <DisplayTags tags={tags} />
            </div>
          </AccordionContent>
        </AccordionItem>
      )}
    </Accordion>
  );
}

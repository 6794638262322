import React, { useEffect, useState } from "react";
import InputMUI from "components/reusable/InputMUI";
import { useUserContext } from "context/UserContext";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "utils/firebase";
import { useSnackBar } from "context/SnackBarContext";
import Modal from "@mui/material/Modal";
import CancelButton from "components/buttons/CancelButton";

export default function NewTermsModal({
  open,
  setOpen,
  setTemplateDetails,
  terms,
}) {
  const { userData, setUserData } = useUserContext();
  const { openSnackBar } = useSnackBar();

  const [newTerms, setNewTerms] = useState({
    title: "",
    text: "",
    id: Date.now(),
  });

  useEffect(() => {
    setNewTerms({
      title: "",
      text: "",
      id: Date.now(),
    });
  }, [open]);

  const handleSaveTerm = () => {
    setTemplateDetails((prev) => ({
      ...prev,
      terms: newTerms,
    }));

    setOpen(false);

    // if len of terms is less than 4 then we save to userData and firestore, if not we open snackbar saying max terms reached, but you can still use those terms

    if (terms.length >= 3) {
      // opensnackbar
      openSnackBar(
        "Max number of terms reached, you can still use the new terms on this template but they won't be saved to your business profile for future use.",
        false,
        true
      );
      return;
    }

    // if admin then save to firestore and update bizData in userData
    if (userData?.userData?.isAdmin) {
      setUserData((prev) => ({
        ...prev,
        bizData: {
          ...prev.bizData,
          terms: [...terms, newTerms],
        },
      }));
      updateDoc(doc(db, "businesses", userData?.bizData?.id), {
        terms: [...terms, newTerms],
      });
      openSnackBar("New terms saved to business profile.", true);
    } else {
      // opensnackbar
      openSnackBar(
        "These new terms are available for this template, but they will not be saved to your company profile for future use, unauthorized permission.",
        false,
        true
      );
    }
  };

  //   console.log("newTerms", newTerms);
  //   console.log("terms", terms);

  return (
    <Modal
      open={open}
      //  onClose={() => setOpen(false)}
    >
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:w-[600px] bg-white shadow-bold p-8 py-8 rounded-md`}
      >
        <h2 className="text-lg font-bold text-center mb-4">Add Terms</h2>
        <div className="w-1/3 mb-4">
          <InputMUI
            value={newTerms.title}
            onChangeFunction={(e) =>
              setNewTerms({ ...newTerms, title: e.target.value })
            }
            label="Terms Title"
            inputProps={{ maxLength: 40 }}
          />
        </div>
        <div className="w-full">
          <InputMUI
            value={newTerms.text}
            onChangeFunction={(e) =>
              setNewTerms({ ...newTerms, text: e.target.value })
            }
            label="Enter new terms"
            multiline
            rows={6}
            inputProps={{
              maxLength: 2400,
            }}
            sx={{
              "& .MuiInputBase-inputMultiline": {
                maxHeight: "200px", // control the maximum height
                overflow: "auto", // enable scrolling
              },
            }}
          />
        </div>
        <div className="flex flex-row items-center justify-end mt-4 gap-2">
          <CancelButton
            handleCancel={() => {
              setOpen(false);
            }}
          />
          <button
            className={`px-2 h-10 ${
              newTerms.text === "" || newTerms.title === ""
                ? "bg-gray-300"
                : "bg-gray-900 hover:text-yellow-100"
            } text-white text-center rounded-md w-16`}
            disabled={newTerms.text === "" || newTerms.title === ""}
            onClick={handleSaveTerm}
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
}

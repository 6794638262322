import React from "react";
import { BsPersonBadge } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import EmployeeSearch from "../../AddJob/components/EmployeeSearch";

export default function DispatchTo({ employees, toggleDispatchModal }) {
  return (
    <div className="flex flex-col flex-1 bg-white rounded-md shadow-bold  gap-2 mt-8 p-4">
      <div className="flex flex-row items-center justify-between gap-2 mt-2 mb-2">
        <div className="flex flex-row">
          <BsPersonBadge className="inline-block text-2xl mr-2" />
          <h1 className="text-xl font-bold">Dispatched To</h1>
        </div>
        <AiOutlineEdit onClick={toggleDispatchModal} className="text-lg cursor-pointer ml-2" />
      </div>

      <div className="mb-4">
        {/* <EmployeeSearch employees={employees} label="Search employees" /> */}
        <div className="relative pl-2 flex flex-row flex-wrap items-center gap-2">
          {employees &&
            employees.length > 0 &&
            employees.map((employee) => (
              <div
                className="flex flex-row items-center gap-2 px-2 py-3 bg-gray-800 rounded-md"
                key={employee.id}
              >
                <span className="font-semibold text-sm text-yellow-100">
                  {employee.firstName + " " + employee.lastName}
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

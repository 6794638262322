import React, { useState } from "react";

export default function CountryDropdown({
  value,
  setValue,
  label,
  name,
  autocomplete,
  ...props
}) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="relative flex-1 flex flex-col font-stripe " {...props}>
      <select
        className={`bg-gray-50 border border-gray-500 h-12 rounded-md outline-none text-gray-900 focus:border-yellow-400 text-base 
          px-4 pt-1`}
        disabled={props.disabled ? true : false}
        value={value}
        placeholder={label}
        onChange={handleChange}
        name={name}
        autoComplete={autocomplete}
      >
        <option value="United States">United States</option>
        <option value="Canada">Canada</option>
      </select>
    </div>
  );
}

import { Bar } from "react-chartjs-2";
function DailyRev({ chartData }) {
  // console.log("chartData from daily rev save to see", chartData);
  const chartDataFormatted = {
    labels: chartData.map((data) => data.date),
    datasets: [
      {
        label: "Job Revenue",
        data: chartData.map((data) => data.revenue),
        backgroundColor: [
          "rgba(28, 99, 191, 0.6)",
          "rgba(218, 228, 123, 0.6)",

          "rgba(0, 0, 0, 0.6)",
          "rgba(123, 211, 212, 0.6)",
        ],
      },
    ],
  };
  // console.log("chartDataFormatted", chartDataFormatted);
  return (
    <>
      <Bar
        data={chartDataFormatted}
        options={{
          plugins: {
            title: {
              display: true,
              text: "Daily Job Revenue",
              color: "#1f2937", // Color of the title
              font: {
                size: 16,
              },
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  let value = context.parsed.y;
                  value = parseFloat(value).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                  label += `$${value}`;
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                color: "rgba(255, 255, 255, 0.3)", // Color of the vertical grid lines
              },
              ticks: {
                color: "#1f2937", // Color of the x-axis labels
              },
            },
            y: {
              min: 0,
              beginAtZero: true,
              grid: {
                color: "rgba(255, 255, 255, 0.3)", // Color of the horizontal grid lines
              },
              ticks: {
                color: "#1f2937", // Color of the y-axis labels
                callback: function (value, index, values) {
                  return `$${value.toLocaleString()}`; // This adds the $ before the value
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        }}
      />
    </>
  );
}

export default DailyRev;

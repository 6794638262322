import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../index";
import { db, auth } from "../../utils/firebase";
import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  collection,
} from "firebase/firestore";
import Tooltip from "@mui/material/Tooltip";
import { ImSpinner } from "react-icons/im";
import { FaToggleOff } from "react-icons/fa";
import { FaToggleOn } from "react-icons/fa";
import { useSnackBar } from "../../context/SnackBarContext";
import DisabledButton from "components/buttons/DisabledButton";
import { SwitchGreen as Switch } from "components/ui/switchGreen";

export default function MessageCustomizations() {
  const { userData, setUserData, subData, bannerVisible } =
    useContext(UserContext);
  const { openSnackBar } = useSnackBar();
  const notAbleToViewCompanyInfo =
    userData?.userData?.customPermissions?.view_company_info === false;

  // only Admins and people with custom permission to edit_company_info can edit the company info
  const notAbleToEditCompanyInfo =
    !userData?.userData?.isAdmin &&
    !userData?.userData?.customPermissions?.edit_company_info;

  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
    timeZone: userData?.bizData?.timeZone,
  };

  const formattedDay = new Intl.DateTimeFormat("en-US", options).format(
    new Date()
  );

  const [onJobCreationNotification, setOnJobCreationNotification] = useState(
    userData?.bizData?.onJobCreationNotification || false
  );

  const [onJobCreationMessageBefore, setOnJobCreationMessageBefore] = useState(
    userData?.bizData?.onJobCreationMessage?.beforeTime ||
      "You have been scheduled for your work to be completed on"
  );

  const [onJobCreationMessageAfter, setOnJobCreationMessageAfter] = useState(
    userData?.bizData?.onJobCreationMessage?.afterTime ||
      ` - ${userData.bizData?.companyName}`
  );

  const [onDriveStartMessage, setOnDriveStartMessage] = useState(
    userData?.bizData?.onDriveStartMessage ||
      `Your technician is on their way to your location. \n\n- ${userData.bizData?.companyName}`
  );
  const [onDriveEndMessage, setOnDriveEndMessage] = useState(
    userData?.bizData?.onDriveEndMessage ||
      `Your technician has arrived.\n\n- ${userData.bizData?.companyName}`
  );
  const [onJobFinishMessage, setOnJobFinishMessage] = useState(
    userData?.bizData?.onJobFinishMessage ||
      `Your work with ${userData.bizData?.companyName} has finished.`
  );
  const [onInvoiceMessage, setOnInvoiceMessage] = useState(
    userData?.bizData?.onInvoiceMessage ||
      `Here is our invoice. We appreciate your business. \n\n- ${userData.bizData?.companyName}`
  );
  const [onReviewRequestMessage, setOnReviewRequestMessage] = useState(
    userData?.bizData?.onReviewRequestMessage ||
      `Thanks for business, if you could leave us a review we would greatly appreciate it!`
  );

  const [reviewFilterDisabled, setReviewFilterDisabled] = useState(
    userData?.bizData?.reviewFilterDisabled === true ||
      userData?.bizData?.reviewFilterDisabled === false
      ? userData.bizData.reviewFilterDisabled
      : false
  );

  const [onEstimateMessage, setOnEstimateMessage] = useState(
    userData?.bizData?.onEstimateMessage ||
      `Here is your estimate. \n\n- ${userData.bizData?.companyName}`
  );

  const [onEstimateSubject, setOnEstimateSubject] = useState(
    userData?.bizData?.onEstimateSubject ||
      `Your Estimate From ${userData.bizData?.companyName}`
  );

  const [onInvoiceSubject, setOnInvoiceSubject] = useState(
    userData?.bizData?.onInvoiceSubject || "Here is your invoice"
  );

  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    if (notAbleToEditCompanyInfo) {
      openSnackBar("You do not have permission to edit company info");
      return;
    }
    if (loading) return;
    try {
      setLoading(true);
      const bizRef = doc(db, "businesses", userData.bizData.id);
      await updateDoc(bizRef, {
        onJobCreationNotification: onJobCreationNotification,
        onJobCreationMessage: {
          beforeTime: onJobCreationMessageBefore,
          afterTime: onJobCreationMessageAfter,
        },
        onDriveStartMessage: onDriveStartMessage,
        onDriveEndMessage: onDriveEndMessage,
        onJobFinishMessage: onJobFinishMessage,
        onInvoiceMessage: onInvoiceMessage,
        onReviewRequestMessage: onReviewRequestMessage,
        reviewFilterDisabled: reviewFilterDisabled,
        onEstimateMessage: onEstimateMessage,
        onEstimateSubject: onEstimateSubject,
        onInvoiceSubject: onInvoiceSubject,
      });
      setUserData({
        ...userData,
        bizData: {
          ...userData.bizData,
          onJobCreationNotification: onJobCreationNotification,
          onJobCreationMessage: {
            beforeTime: onJobCreationMessageBefore,
            afterTime: onJobCreationMessageAfter,
          },
          onDriveStartMessage: onDriveStartMessage,
          onDriveEndMessage: onDriveEndMessage,
          onJobFinishMessage: onJobFinishMessage,
          onInvoiceMessage: onInvoiceMessage,
          onReviewRequestMessage: onReviewRequestMessage,
          reviewFilterDisabled: reviewFilterDisabled,
          onEstimateMessage: onEstimateMessage,
          onEstimateSubject: onEstimateSubject,
          onInvoiceSubject: onInvoiceSubject,
        },
      });
      setDirty(false);
      openSnackBar("Successfully saved", true);
    } catch (err) {
      console.log(err);
      alert("Something went wrong, please try again later");
    } finally {
      setLoading(false);
    }
  };

  const notifications = [
    {
      label: "Job Creation",
      placeholder:
        `You have been scheduled for your work to be completed on` +
        ` ${formattedDay}` +
        `\n\n- ${userData.bizData?.companyName}`,
      beforeTime: onJobCreationMessageBefore,
      setBeforeTime: setOnJobCreationMessageBefore,
      afterTime: onJobCreationMessageAfter,
      setAfterTime: setOnJobCreationMessageAfter,
      timePlaceholder: formattedDay,
      tooltip:
        "This is the message that will be sent to the customer when you create a job.",
      toggleOnText: "Turn on job creation notifications",
      toggleOffText: "Turn off job creation notifications",
      toggleSetter: setOnJobCreationNotification,
      toggleValue: onJobCreationNotification,
    },
    {
      label: "Drive Start",
      placeholder: `Your technician is on their way to your location. \n\n- ${userData.bizData?.companyName}`,
      value: onDriveStartMessage,
      setValue: setOnDriveStartMessage,
      tooltip:
        "This is the message that will be sent to the customer when the technician starts driving to their location.",
    },
    {
      label: "Drive End",
      placeholder: `Your technician has arrived.\n\n- ${userData.bizData?.companyName}`,
      value: onDriveEndMessage,
      setValue: setOnDriveEndMessage,
      tooltip:
        "This is the message that will be sent to the customer when the technician arrives at their location.",
    },
    {
      label: "Job Finish",
      placeholder: `Your work with ${userData.bizData?.companyName} has finished.`,
      value: onJobFinishMessage,
      setValue: setOnJobFinishMessage,
      tooltip:
        "This is the message that will be sent to the customer when the technician finishes the job.",
    },
    {
      label: "Invoice Message",
      placeholder: `Here is our invoice. We appreciate your business. \n\n- ${userData.bizData?.companyName}`,
      value: onInvoiceMessage,
      setValue: setOnInvoiceMessage,
      tooltip:
        "This is the message that will be sent to the customer you send the invoice. The link to the invoice will be added after your text.",
    },
    {
      label: "Invoice Subject Header",
      placeholder: `Here is our invoice. We appreciate your business. \n\n- ${userData.bizData?.companyName}`,
      value: onInvoiceSubject,
      setValue: setOnInvoiceSubject,
      tooltip:
        "This is the subject header that will be sent with the invoice email.",
    },
    {
      label: "Review Request Message",
      placeholder: `Thanks for business, if you could leave us a review we would greatly appreciate it!`,
      value: onReviewRequestMessage,
      setValue: setOnReviewRequestMessage,
      tooltip:
        "This is the message that will be sent to the customer when you send a review request. If you have review filtering enabled, the link will automatically be added after your text. If you have disabled the review filter, you will need to provide your own link in the message.",
      toggleOffText: "Enable review filter, this is the default setting.",
      toggleOnText:
        "Disable review filter, you will need to provide your own link in the message.",
      toggleSetter: setReviewFilterDisabled,
      toggleValue: reviewFilterDisabled,
      invertLogic: true,
    },
    {
      label: "Estimate Message",
      placeholder: `Here is your estimate. \n\n- ${userData.bizData?.companyName}`,
      value: onEstimateMessage,
      setValue: setOnEstimateMessage,
      tooltip:
        "This is the message that will be sent to the customer when you send an estimate. The estimate pdf will be after the text.",
    },
    {
      label: "Estimate Subject Header",
      placeholder: `Your Estimate From ${userData.bizData?.companyName}`,
      value: onEstimateSubject,
      setValue: setOnEstimateSubject,
      tooltip:
        "This is the subject header that will be sent with the estimate email.",
    },
  ];

  return (
    <div
      className={`bg-gray-100 ${
        bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
      }  overflow-y-auto pb-36`}
    >
      <div className="flex items-center justify-center">
        <div className="flex flex-col w-11/12 max-w-3xl mt-6 mb-6">
          <div className="flex justify-between items-start mb-6">
            <div>
              <h1 className="text-3xl font-extrabold text-gray-900">
                Notification Messages
              </h1>
              <p className="text-sm text-gray-500">
                Customize the status, estimate, and invoice messages that are
                sent to customers.
              </p>
            </div>

            <DisabledButton
              disabled={!dirty}
              loading={loading}
              onClick={handleSave}
              text="Save"
              width="w-16"
              additionalClasses="mr-8"
            />
          </div>

          {/* <div className="flex flex-col w-full justify-between items-center  mt-3 mb-5 "> */}
          <div className="bg-white p-4 rounded-lg shadow mb-4 text-sm text-gray-700">
            {/* <div className="flex flex-col max-w-2xl w-11/12 shadow-bold rounded-md bg-white pb-8"> */}
            <h1 className="font-semibold text-gray-900 text-base mb-1">
              Messages
            </h1>
            <hr className="mb-3" />
            {notifications.map((notification, index) => (
              <div key={index} className="flex flex-col w-full ">
                <div
                  className={`text-gray-600 font-medium flex flex-row items-center ${
                    index === 0 ? "pt-4" : "pt-8"
                  }  px-4 pb-4 `}
                >
                  <Tooltip title={notification.tooltip} placement="top-start">
                    <label>{notification.label}</label>
                  </Tooltip>

                  {notification.toggleOnText && (
                    <div className="pl-2">
                      <ToggleSwitch
                        toggleValue={notification.toggleValue}
                        toggleSetter={notification.toggleSetter}
                        toggleOnText={notification.toggleOnText}
                        toggleOffText={notification.toggleOffText}
                        label={notification.label}
                        disabled={notAbleToEditCompanyInfo}
                        setDirty={setDirty}
                        invertLogic={notification.invertLogic}
                      />
                    </div>
                  )}
                </div>

                {notification.label === "Job Creation" ? (
                  <>
                    <textarea
                      className="bg-gray-50 py-2 px-4 mx-5 rounded-md shadow  resize-none mb-2 focus:outline-none"
                      placeholder="Edit before time text"
                      value={notification.beforeTime}
                      onChange={(e) => {
                        notification.setBeforeTime(e.target.value);
                        setDirty(true);
                      }}
                      maxLength={140}
                    />
                    <Tooltip
                      title="This is the date and time of the start of the job, this is not editable"
                      placement="top-start"
                    >
                      <div className="bg-gray-50/90 text-gray-800 font-semibold py-2 px-4 mx-5   resize-none cursor-default mb-2 shadow-md">
                        {notification.timePlaceholder}
                      </div>
                    </Tooltip>
                    <textarea
                      className="bg-gray-50 py-2 px-4 mx-5 rounded-md shadow  resize-none focus:outline-none"
                      placeholder="Edit after time text"
                      value={notification.afterTime}
                      onChange={(e) => {
                        notification.setAfterTime(e.target.value);
                        setDirty(true);
                      }}
                      maxLength={140}
                    />
                  </>
                ) : (
                  <textarea
                    className="bg-gray-50  py-2 px-4 mx-5 rounded-md shadow  resize-none focus:outline-none"
                    rows="3"
                    placeholder={notification.placeholder}
                    value={notification.value}
                    onChange={(e) => {
                      notification.setValue(e.target.value);
                      setDirty(true);
                    }}
                    maxLength={300}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const ToggleSwitch = ({
  toggleValue,
  toggleSetter,
  toggleOnText,
  toggleOffText,
  label,
  disabled = false,
  setDirty,
  invertLogic = false,
}) => {
  const handleClick = (newValue) => {
    if (invertLogic) {
      toggleSetter(!newValue);
    } else {
      toggleSetter(newValue);
    }
    setDirty(true);
  };

  return (
    <div className="pl-2">
      <Tooltip
        title={toggleValue ? toggleOffText : toggleOnText}
        placement="top-start"
      >
        <div>
          <Switch
            checked={invertLogic ? !toggleValue : toggleValue}
            onCheckedChange={handleClick}
            disabled={disabled}
            className={toggleValue ? "bg-green-400" : "bg-gray-900"}
          />
        </div>
      </Tooltip>
    </div>
  );
};

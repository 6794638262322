import React, { useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";

export default function SearchInput({
  label,
  inputValue,
  setInputValue,
  selected,
  setSelected,
  handleSelect,
  customers,
  ...props
}) {
  const handleChange = (event) => {
    setSelected(false);
    setInputValue(event.target.value);
  };

  // const handleSelect = (customer) => {
  //   setInputValue(customer.firstName);
  //   setSelected(customer);
  //   setNotifications(customer?.notifications);
  // };

  // Filter the salespeople based on the user's input
  // const filteredCustomer =
  //   customers &&
  //   customers.length > 0 &&
  //   customers.filter(
  //     (customer) =>
  //       customer.firstName?.toLowerCase().includes(inputValue.toLowerCase()) ||
  //       customer.lastName?.toLowerCase().includes(inputValue.toLowerCase()) ||
  //       customer.displayName
  //         ?.toLowerCase()
  //         .includes(inputValue.toLowerCase()) ||
  //       customer.phone.mobile?.toLowerCase().includes(inputValue.toLowerCase())
  //   );

  return (
    <div className="relative flex-1 flex flex-col " {...props}>
      <input
        className="bg-gray-50 border-2 border-gray-300 rounded-md outline-none focus:border-gray-900 text-lg py-2 pl-9"
        value={inputValue}
        placeholder={label}
        onChange={handleChange}
      />
      <BiSearchAlt2
        className={`absolute top-3 left-2 text-2xl text-gray-400  ${inputValue && "text-gray-600"}`}
      />
      {inputValue && !selected && (
        <div
          className="absolute z-50 mt-1 bg-gray-50 rounded-md shadow-lg max-h-96 overflow-auto w-full"
          style={{
            top: "45px",
            left: "0px",
          }}
        >
          <ul>
            {customers && customers.length > 0 ? (
              customers.map((customer) => (
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  key={customer.customerId}
                  onClick={() => handleSelect(customer)}
                >
                  <div>{customer.firstName + " " + customer.lastName}</div>
                  {customer?.phone?.mobile && (
                    <div className="pt-1">
                      {"(" +
                        customer.phone.mobile.substring(2, 5) +
                        ") " +
                        customer.phone.mobile.substring(5, 8) +
                        "-" +
                        customer.phone.mobile.substring(8, 12)}
                    </div>
                  )}
                  {customer?.address?.[0] && <div className="pt-1">{customer.address[0]}</div>}
                </li>
              ))
            ) : (
              <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">No results found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

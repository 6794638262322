import React, { useState, useEffect, useContext } from "react";
import { useUserContext } from "context/UserContext";
import { useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import SearchInputNewMessage from "../components/SearchInputNewMessage";

import algoliasearch from "algoliasearch";
import { CloseModalIconButton } from "components/NewButtons/CloseModalIconButton";

export default function NewMessageModal({
  toggleModalOn,
  toggleModalOff,
  conversations,
}) {
  const { user, userData } = useUserContext();
  const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY
  );
  const index = client.initIndex("customers");
  const [customers, setCustomers] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();

  const handleAlgoliaSearch = async () => {
    if (!inputValue) return;
    if (loadingCustomers) return;

    const businessId = userData.userData.businessId;

    try {
      setLoadingCustomers(true);
      const { hits } = await index.search(inputValue, {
        filters: `businessId:${businessId}`,
        hitsPerPage: 6,
      });
      setCustomers(hits);
    } catch (err) {
      console.error("Error with search", err);
    } finally {
      setLoadingCustomers(false);
    }
  };

  useEffect(() => {
    if (!inputValue) {
      setCustomers([]);
      return;
    }
    handleAlgoliaSearch();
  }, [inputValue]);

  const handleSelectCustomer = async (customer) => {
    // since we are only passing phone number and display name we don't need to fetch the firestore data
    setSelected(customer);
  };

  useEffect(() => {
    if (selected) {
      if (!selected.phone.mobile) {
        console.log("no mobile phone number");
        alert(
          "Selected customer does not have a phone number, please add it before sending a message"
        );
        return;
      }
      navigate(`/messages/${80085}`, {
        state: { phone: selected.phone.mobile, name: selected.displayName },
      });
    }
  }, [selected]);

  return (
    <>
      <div className="modal">
        <div className="overlay" onClick={() => console.log("clicked overlay")}>
          <div
            className="absolute z-100 top-1/2 left-1/2 bg-gray-50 px-10 rounded-md w-3/4 max-w-2xl overflow-y-auto h-3/4 flex flex-col "
            style={{
              transform: "translate(-50%, -50%)",
            }}
          >
            {/* <IoCloseSharp
              onClick={toggleModalOff}
              className="absolute top-2 right-2 text-3xl cursor-pointer"
            /> */}
            <div className="absolute top-4 right-3">
              <CloseModalIconButton handleCancel={toggleModalOff} />
            </div>
            <h2 className="text-2xl font-bold mt-5 mb-5">New conversation</h2>
            <SearchInputNewMessage
              label="Search Customers"
              inputValue={inputValue}
              setInputValue={setInputValue}
              selected={selected}
              setSelected={setSelected}
              handleSelect={handleSelectCustomer}
              customers={customers}
            />
          </div>
        </div>
      </div>
    </>
  );
}

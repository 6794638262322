import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import CancelButton from "components/buttons/CancelButton";
import DisabledButton from "components/buttons/DisabledButton";
import { Switch } from "components/ui/switch";
import InputMUI from "components/reusable/InputMUI";
import PhoneInputMUI from "components/reusable/PhoneInputMUI";
import { useUserContext } from "context/UserContext";
import { CgAttachment } from "react-icons/cg";
import CustomFileInput from "components/reusable/CustomFileInput";
export default function ConfirmCreationModal({
  open,
  onClose,
  subscriptionDetails,
  handleCreateSubscription,
  loading,
}) {
  const { userData } = useUserContext();
  // so if we are sending this as a link to the customer then this confirmation modal will act as a preview for the message and the email/phone number that the customer will receive the link at.

  // if we are accepting on behalf of the customer then this modal will give the option to pick between entering payment information or using the card on file... I think it will actually be better to handle the payment on the subscriptionDetails page though... so that it will be created (marked as a draft and not active yet) and then they can come back to it and pay later.

  // so this modal should only be activated if the user is sending the subscription via link...

  const [attachments, setAttachments] = useState([]); // [ {name: "attachment1.pdf", url: "https://www.google.com"}
  const [message, setMessage] = useState(
    `Hi ${subscriptionDetails?.customer?.displayName},\n\n${userData?.bizData?.companyName} is sending you a service subscription. Accept the plan by clicking the link below. Please contact us if you have questions.\n\nThank you.`
  );

  const [emailToSend, setEmailToSend] = useState(
    subscriptionDetails?.customer?.email?.[0] || ""
  );
  const [shouldSendEmail, setShouldSendEmail] = useState(
    subscriptionDetails?.customer?.email?.[0] ? true : false
  );
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");

  // console.log(
  //   "subscriptinDetails from confirm create modal",
  //   subscriptionDetails
  // );

  const [phoneToSend, setPhoneToSend] = useState(
    subscriptionDetails?.customer?.phone?.mobile || ""
  );
  const [shouldSendPhone, setShouldSendPhone] = useState(
    subscriptionDetails?.customer?.phone?.mobile ? true : false
  );

  const [phoneError, setPhoneError] = useState(false);
  const [phoneHelperText, setPhoneHelperText] = useState("");

  const validateEmail = (email) => {
    if (!email && !shouldSendEmail) {
      setEmailError(false);
      setEmailHelperText("");
      return;
    }

    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      setEmailHelperText("Please enter a valid email address.");
    } else {
      setEmailError(false);
      setEmailHelperText("");
    }
  };

  const validatePhone = (phone) => {
    if (!phone && !shouldSendPhone) {
      setPhoneError(false);
      setPhoneHelperText("");
      return;
    }
    // Phone validation logic
    if (phone.length < 12) {
      setPhoneError(true);
      setPhoneHelperText("Please enter a valid phone number.");
    } else {
      setPhoneError(false);
      setPhoneHelperText("");
    }
  };

  const handlePhoneBlur = () => {
    validatePhone(phoneToSend);
  };

  // useEffect(() => {
  //   validatePhone(phoneToSend);

  //   validateEmail(emailToSend);
  // }, [phoneToSend, shouldSendPhone, emailToSend, shouldSendEmail]);

  useEffect(() => {
    const handler = setTimeout(() => {
      validateEmail(emailToSend);
      validatePhone(phoneToSend);
    }, 200); // Validates only after 200 ms

    return () => clearTimeout(handler);
  }, [emailToSend, phoneToSend, shouldSendPhone, shouldSendEmail]);

  const handleClose = () => {
    setEmailToSend(subscriptionDetails?.customer?.email?.[0] || "");
    setShouldSendEmail(
      subscriptionDetails?.customer?.email?.[0] ? true : false
    );
    setEmailError(false);
    setEmailHelperText("");
    setPhoneToSend(subscriptionDetails?.customer?.phone?.mobile || "");
    setShouldSendPhone(
      subscriptionDetails?.customer?.phone?.mobile ? true : false
    );
    setPhoneError(false);
    setPhoneHelperText("");

    onClose();
  };

  const handleSend = async () => {
    let email = shouldSendEmail ? emailToSend : null;
    let phone = shouldSendPhone ? phoneToSend : null;

    await handleCreateSubscription({
      emailToSend: email,
      phoneToSend: phone,
      message,
      attachments,
    });

    handleClose();
  };

  // const handleFileChange = (e) => {
  //   // console.log(e.target.files);
  //   const files = Array.from(e.target.files);

  //   const newAttachments = files.map((file, index) => {
  //     return {
  //       file: file,
  //       name: file.name,
  //     };
  //   });
  //   // Update the state with new and existing attachments
  //   setAttachments([...attachments, ...newAttachments]);
  // };

  // const removeAttachment = (index) => {
  //   setAttachments(attachments.filter((_, i) => i !== index));
  // };

  return (
    <Modal open={open} onClose={handleClose}>
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:w-[600px] bg-white shadow-bold p-8 py-8 rounded-md max-h-90% overflow-y-auto`}
      >
        <h2 className="text-xl font-bold text-center mb-4">
          Confirm send subscription
        </h2>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2">
            <div className="w-1/2">
              <InputMUI
                label="Email"
                value={emailToSend}
                setValue={setEmailToSend}
                error={emailError}
                helperText={emailHelperText}
              />
            </div>

            <div className="w-1/2  justify-start pl-6 flex items-center gap-2">
              <Switch
                label="Send Via Email"
                checked={shouldSendEmail}
                onCheckedChange={() => {
                  setShouldSendEmail(!shouldSendEmail);
                }}
              />
              <label className="text-sm text-gray-600">Send Via Email</label>
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <div className="w-1/2">
              <PhoneInputMUI
                label="Phone"
                value={phoneToSend}
                setValue={setPhoneToSend}
                onBlur={handlePhoneBlur}
                error={phoneError}
                helperText={phoneHelperText}
              />
            </div>
            <div className="w-1/2 justify-start pl-6 flex items-center gap-2">
              <Switch
                label="Send Via Text"
                checked={shouldSendPhone}
                onCheckedChange={() => {
                  setShouldSendPhone(!shouldSendPhone);
                }}
              />
              <label className="text-sm text-gray-600">Send Via Text</label>
            </div>
          </div>
        </div>
        <div className="mt-4 relative">
          <InputMUI
            multiline
            rows={8}
            inputProps={{
              maxLength: 700,
            }}
            label="Message"
            value={message}
            setValue={setMessage}
          />

          {/* <div className="absolute -bottom-12 ">
            <CustomFileInput
              backgroundColor="bg-transparent"
              handleFileChange={handleFileChange}
              attachments={attachments}
              removeAttachment={removeAttachment}
              renderIcon={() => (
                <CgAttachment className="text-gray-600 text-2xl" />
              )}
            />
          </div> */}
        </div>
        <div className="flex flex-row items-center justify-end mt-4 gap-2">
          <CancelButton handleCancel={handleClose} />
          <DisabledButton
            text="Send"
            disabled={
              emailError || phoneError || (!emailToSend && !phoneToSend)
            }
            loading={loading}
            onClick={handleSend}
          />
        </div>
      </div>
    </Modal>
  );
}

import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { BiSearchAlt2 } from "react-icons/bi";

export default function SearchInputNewMessage({
  label,
  inputValue,
  setInputValue,
  selected,
  setSelected,
  customers,
  handleSelect,
  ...props
}) {
  const handleChange = (event) => {
    setSelected(false);
    setInputValue(event.target.value);
  };

  // const handleSelect = (customer) => {
  //   setInputValue(customer.firstName);
  //   setSelected(customer);
  // };

  return (
    <div className="relative flex-1 flex flex-col " {...props}>
      <input
        className="bg-gray-50 border border-gray-300 rounded-md outline-none focus:border-gray-400 text-base font-normal py-1 pl-9"
        value={inputValue}
        placeholder={label}
        onChange={handleChange}
      />
      <BiSearchAlt2
        className={`absolute top-1.5 left-2 text-2xl text-gray-400  ${
          inputValue && "text-gray-600"
        }`}
      />
      {inputValue && !selected && (
        <div
          className="absolute z-50 mt-1 bg-gray-50 rounded-md shadow-lg max-h-96 overflow-auto w-full"
          style={{
            top: "45px",
            left: "0px",
          }}
        >
          <ul>
            {customers && customers.length > 0 ? (
              customers.map((customer) => (
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  key={customer.customerId}
                  onClick={() => handleSelect(customer)}
                >
                  {customer.firstName + " " + customer.lastName},{" "}
                  {customer.address[0]} <br />
                  {customer?.phone?.mobile &&
                    "(" +
                      customer?.phone.mobile.substring(2, 5) +
                      ") " +
                      customer?.phone.mobile.substring(5, 8) +
                      "-" +
                      customer?.phone.mobile.substring(8, 12)}
                </li>
              ))
            ) : (
              <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">
                No results found
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

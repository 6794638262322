import React, { useState, useContext, useEffect, useMemo } from "react";
import SearchInput from "./SearchInput";
import { FaPlus } from "react-icons/fa";
import { BsPersonFill } from "react-icons/bs";
import { FaToggleOff } from "react-icons/fa";
import { FaToggleOn } from "react-icons/fa";
import { FaUndo } from "react-icons/fa";
import { UserContext } from "../../../index";
import { db } from "../../../utils/firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import algoliasearch from "algoliasearch";
import GoogleMaps from "components/GoogleMaps/GoogleMaps";
import { UserIcon } from "@heroicons/react/24/solid";
import Tooltip from "@mui/material/Tooltip";
import { Switch } from "components/ui/switch";
import { splitAddress } from "utils/helpers";
import { MapPinIcon } from "@heroicons/react/24/solid";

const iconButtonClasses =
  "w-6 h-6 text-gray-500 hover:text-gray-900 transition-colors duration-200 ease-in-out cursor-pointer";

const buttonClasses =
  "p-1 rounded-full hover:bg-yellow-100 transition-colors duration-200 ease-in-out outline:none";

export default function SearchCustomer({ toggleModal, selected, setSelected }) {
  const { userData } = useContext(UserContext);
  const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY
  );
  const index = client.initIndex("customers");
  const [customers, setCustomers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loadingCustomers, setLoadingCustomers] = useState(false);

  const handleMapClick = () => {
    const address = encodeURIComponent(selected?.address?.[0] || "");
    window.open(`https://www.google.com/maps/search/?api=1&query=${address}`, "_blank");
  };

  const address1Split = useMemo(() => {
    return splitAddress(selected?.address?.[0] || "");
  }, [selected]);

  const handleAlgoliaSearch = async () => {
    if (!inputValue) return;
    if (loadingCustomers) return;
    const businessId = userData.userData.businessId;

    try {
      setLoadingCustomers(true);
      const { hits } = await index.search(inputValue, {
        filters: `businessId:${businessId}`,
        hitsPerPage: 10,
      });
      setCustomers(hits);
    } catch (err) {
      console.error("Error with search", err);
    } finally {
      setLoadingCustomers(false);
    }
  };

  useEffect(() => {
    if (!inputValue) {
      setCustomers([]);
      return;
    }
    handleAlgoliaSearch();
  }, [inputValue]);

  const handleSelectCustomer = async (customer) => {
    // console.log("handleSelectCustomer (from algolia)", customer);
    // we initially set it to the algolia object for instant feedback
    setSelected(customer);
    try {
      const customerRef = doc(
        db,
        "businesses",
        userData.userData.businessId,
        "customers",
        customer.customerId
      );
      const customerDoc = await getDoc(customerRef);
      const customerData = customerDoc.data();
      // console.log("customerData from firestore", customerData);
      setSelected(customerData);
    } catch (err) {
      console.log(err);
    }
  };

  async function toggleNotifications() {
    try {
      //update customer doc in firestore with new notifications value
      const customerRef = doc(
        db,
        "businesses",
        userData.userData.businessId,
        "customers",
        selected.customerId
      );
      await updateDoc(customerRef, {
        notifications: !selected.notifications,
      });

      //update selected Customer ( for adding to the jobDoc)
      setSelected((prev) => ({
        ...prev,
        notifications: !selected.notifications,
      }));

      // console.log("notifications should be updated");
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="flex flex-col flex-1 rounded-md shadow-bold bg-white py-6">
      <div className="flex flex-row items-center justify-between mb-4 px-4">
        <div className="flex flex-row items-center">
          <UserIcon className="w-6 h-6 mr-2 text-gray-700" />
          <h1 className="text-xl font-bold text-gray-800">Customer</h1>
        </div>
        {/* <div className="flex flex-row">
          <BsPersonFill className="text-2xl mr-2" />
          <h1 className="text-xl font-bold text-gray-900 ">Customer</h1>
        </div> */}
        {selected && (
          <FaUndo
            className="text-lg cursor-pointer"
            onClick={() => {
              setSelected(false);
              setInputValue("");
            }}
          />
        )}
      </div>
      {!selected ? (
        <div className="flex flex-row gap-2 justify-between flex-wrap px-4 ">
          <SearchInput
            label="Search"
            inputValue={inputValue}
            setInputValue={setInputValue}
            selected={selected}
            setSelected={setSelected}
            handleSelect={handleSelectCustomer}
            customers={customers}
          />
          <button
            className="bg-gray-900 hover:text-yellow-100 rounded-md px-2 py-3 flex items-center text-white whitespace-nowrap"
            onClick={toggleModal}
          >
            <FaPlus className="inline-block mr-2" />
            New Customer
          </button>
        </div>
      ) : (
        <>
          <GoogleMaps customer={selected} isStreetViewOnly={true} />
          <div className="flex flex-col gap-2 text-lg  text-gray-900 px-4 mt-4">
            <h1>{selected.firstName + " " + selected.lastName}</h1>
            {selected?.phone?.mobile && (
              <h1>
                {"(" +
                  selected.phone.mobile.substring(2, 5) +
                  ") " +
                  selected.phone.mobile.substring(5, 8) +
                  "-" +
                  selected.phone.mobile.substring(8, 12)}
              </h1>
            )}
            {selected?.email?.[0] && <h1>{selected.email[0]}</h1>}
            {/* <h1>{selected.address[0]}</h1> */}
            {address1Split?.[0] && (
              <div>
                <div className="flex items-center justify-between">
                  <div>
                    <p>{address1Split[0]}</p>
                    {address1Split?.[1] && <p className="text-wrap">{address1Split[1]}</p>}
                  </div>
                  <Tooltip title="Open in Google Maps">
                    <button onClick={handleMapClick} className={buttonClasses}>
                      <MapPinIcon className={iconButtonClasses} />
                    </button>
                  </Tooltip>
                </div>
              </div>
            )}
            {toggleNotifications && (
              <div className="flex flex-row mt-4 items-center">
                <Tooltip
                  title={`Turn ${
                    selected?.notifications ? "off" : "on"
                  } notifications for job updates`}
                >
                  <Switch
                    checked={selected?.notifications}
                    onCheckedChange={toggleNotifications}
                    className="mr-2"
                  />
                </Tooltip>
                <h2 className="text-lg font-medium">Notifications</h2>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export const generateCanViewIdsAndDispatchedToIds = ({ dispatchedTo, leadSource, userData }) => {
  let dispatchedToIdsOnly = [];
  if (dispatchedTo.length > 0) {
    dispatchedToIdsOnly = dispatchedTo.map((employee) => employee.id);
  }

  let canViewIdsOnly = [];
  if (dispatchedTo.length > 0) {
    canViewIdsOnly = dispatchedTo.map((employee) => employee.id);
  }

  if (leadSource?.salesPerson?.id) {
    // check first that the leadSource salesperson is not already in the canViewIdsOnly array
    if (!canViewIdsOnly.includes(leadSource.salesPerson.id)) {
      canViewIdsOnly.push(leadSource.salesPerson.id);
    }
  }
  // check first that the user creating job is not already in the canViewIdsOnly array
  if (!canViewIdsOnly.includes(userData.userData.id)) {
    canViewIdsOnly.push(userData.userData.id);
  }

  return { dispatchedToIdsOnly, canViewIdsOnly };
};

export const uploadImages = async ({ images, uploadFile }) => {
  // setUploading(true);
  const uploadedImageUrls = [];

  for (let file of images) {
    try {
      const downloadURL = await uploadFile(file.data, "jobPhotos");
      uploadedImageUrls.push(downloadURL);
    } catch (error) {
      console.error("Upload error:", error);
      alert("Upload error:", error.message); // TODO: Replace with a notification (e.g., snackbar)
    }
  }

  return uploadedImageUrls;
  // setUploading(false);
};

export const generateYearOptions = (startYear) => {
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let year = startYear; year <= currentYear + 4; year++) {
    yearOptions.push({ label: year.toString(), value: year });
  }
  return yearOptions;
};

import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../index";
import { db, auth } from "../../utils/firebase";
import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  collection,
} from "firebase/firestore";
import Tooltip from "@mui/material/Tooltip";
import { ImSpinner } from "react-icons/im";
import { FaToggleOff } from "react-icons/fa";
import { FaToggleOn } from "react-icons/fa";
import { useSnackBar } from "../../context/SnackBarContext";
import DisabledButton from "components/buttons/DisabledButton";
import CollapsibleMessagesSection from "components/Customizations/Messages/CollapsibleMessagesSection";
import { IoIosInformationCircleOutline } from "react-icons/io";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import InputMUI from "components/reusable/InputMUI";
import Modal from "@mui/material/Modal";
import { AiOutlineEdit } from "react-icons/ai";

export default function TermsCustomizations() {
  const { userData, setUserData, subData, bannerVisible } =
    useContext(UserContext);
  const { openSnackBar } = useSnackBar();
  const notAbleToViewCompanyInfo =
    userData?.userData?.customPermissions?.view_company_info === false;

  // only Admins and people with custom permission to edit_company_info can edit the company info
  const notAbleToEditCompanyInfo =
    !userData?.userData?.isAdmin &&
    !userData?.userData?.customPermissions?.edit_company_info;

  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(userData?.bizData?.terms || []); // Handles the terms array
  const [editModalOpen, setEditModalOpen] = useState(false); // Controls the edit term modal

  const [selectedTerm, setSelectedTerm] = useState(null);

  // console.log("date.now", Date.now());

  useEffect(() => {
    // json.stringify to compare objects
    if (JSON.stringify(terms) !== JSON.stringify(userData?.bizData?.terms)) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [terms, userData?.bizData?.terms]);

  const handleSave = async () => {
    if (notAbleToEditCompanyInfo) {
      openSnackBar("You do not have permission to edit company info");
      return;
    }
    // Check if there are already 3 terms
    if (terms.length > 3) {
      alert("You can only add up to 3 terms.");
      return;
    }

    setLoading(true);

    try {
      // Update the terms in the Firestore
      const bizRef = doc(db, "businesses", userData.bizData.id);
      await updateDoc(bizRef, { terms: terms });
      setUserData({ ...userData, bizData: { ...userData.bizData, terms } });
      openSnackBar("Terms added successfully", true);
    } catch (error) {
      openSnackBar("Failed to add term");
    } finally {
      setLoading(false);
    }
  };

  // Function to handle updating a term
  const handleUpdateTerm = async ({ updatedTerm }) => {
    const updatedTerms = terms.map((term) => {
      if (term.id === updatedTerm.id) {
        return updatedTerm;
      }
      return term;
    });
    setTerms(updatedTerms);
  };

  // Function to handle adding new term
  const handleAddTerm = async ({ newTerm }) => {
    console.log("newTerm", newTerm);
    if (newTerm.text === "") return;
    if (terms.length >= 3) {
      alert("You can only add up to 3 terms.");
      return;
    }

    const termTitleNumber = terms.length + 1;
    const updatedTerms = [
      ...terms,
      {
        id: Date.now(),
        text: newTerm.text.trim(),
        title: newTerm.title || `Terms ${termTitleNumber}`,
      },
    ];
    setTerms(updatedTerms);
  };

  const handleDeleteTerm = async (termId) => {
    // Remove the term with the given termId from the terms array
    const updatedTerms = terms.filter((term) => term.id !== termId);
    setTerms(updatedTerms);
  };

  // console.log("terms", terms);
  return (
    <div
      className={`bg-gray-100 ${
        bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
      }  overflow-y-auto pb-36`}
    >
      <EditTermsModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        term={selectedTerm}
        setTerm={setSelectedTerm}
        addTerm={handleAddTerm}
        updateTerm={handleUpdateTerm}
        handleDeleteTerm={handleDeleteTerm}
      />
      <div className="flex items-center justify-center">
        <div className="flex flex-col w-11/12 max-w-3xl mt-6 mb-6">
          <div className="flex flex-row justify-between items-start mb-6">
            <div>
              <h1 className="text-3xl font-extrabold text-gray-900">
                Terms and Conditions
              </h1>
              <p className="text-sm text-gray-500">
                Customize the terms for service subscriptions, estimates, and
                invoices.
              </p>
            </div>

            <DisabledButton
              disabled={!dirty}
              loading={loading}
              onClick={handleSave}
              text="Save"
              width="w-16"
              additionalClasses="mr-8"
            />
          </div>

          {/* <div className="flex flex-col w-full justify-between items-center  mt-3 mb-5 ">
        <div className="flex flex-col max-w-2xl w-11/12 shadow-bold rounded-md bg-white pb-8"> */}
          <div className="bg-white p-4 rounded-lg shadow mb-4 text-sm text-gray-700">
            <div className="flex flex-row items-center gap-2 rounded-sm mb-1">
              <h1 className="font-semibold text-gray-900 text-base">Terms</h1>

              <Tooltip
                title="These are terms that you can add to estimates, job approvals, or service plans that may include legal information, warranty, conditions of purchase ect. When included terms will appear below the presentation of your estimate, job, or service proposal before approval. You can save up to 3 different versions."
                arrow
                placement="top"
              >
                <div>
                  <IoIosInformationCircleOutline
                    size={22}
                    className="text-gray-900"
                  />
                </div>
              </Tooltip>
            </div>
            <hr className="mb-3" />
            {terms.map((term, index) => (
              <div
                key={term.id}
                className="p-2 bg-gray-50 rounded-md shadow mb-4 mx-3 flex justify-between items-start relative"
              >
                <div>
                  <p className="font-semibold text-gray-700">{term.title}</p>

                  <p className="text-gray-600 whitespace-pre-wrap text-xs">
                    {term.text}
                  </p>
                </div>

                <button
                  className="absolute top-1 right-1 font-medium text-yellow-500 hover:bg-yellow-100 px-2 py-2 rounded-full transition-colors duration-300 ease-in-out"
                  onClick={() => {
                    setSelectedTerm(term);
                    setEditModalOpen(true);
                  }}
                >
                  Edit
                </button>
              </div>
            ))}
            {terms.length < 3 && (
              <div className="flex flex-col px-3 gap-4 items-start">
                {/* <InputMUI
                value={newTerm}
                setValue={setNewTerm}
                label="Enter new terms"
                multiline
                inputProps={{ maxLength: 700 }}
              /> */}
                <button
                  className={`p-2 ml-2 ${"bg-gray-900 hover:text-yellow-100"} text-white rounded-md w-16`}
                  onClick={() => setEditModalOpen(true)}
                >
                  Add
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const EditTermsModal = ({
  open,
  setOpen,
  term,
  addTerm,
  updateTerm,
  handleDeleteTerm,
  setTerm,
}) => {
  const [editedTerm, setEditedTerm] = useState(term?.text || "");
  const [editedTermTitle, setEditedTermTitle] = useState(term?.title || "");

  useEffect(() => {
    setEditedTerm(term?.text || "");
    setEditedTermTitle(term?.title || "");
  }, [term, open]);

  const clearLocalState = () => {
    setEditedTerm("");
    setEditedTermTitle("");
    setTerm(null);
  };

  const handlePressDelete = () => {
    handleDeleteTerm(term.id);
    clearLocalState();
    setOpen(false);
  };

  const handleAddOrSaveTerm = ({ title, text }) => {
    if (term?.id) {
      const updatedTerm = { ...term, title, text };
      updateTerm({ updatedTerm });
      clearLocalState();
      setOpen(false);
      return;
    }
    const newTerm = { title, text };
    addTerm({ newTerm });
    clearLocalState();
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      //  onClose={() => setOpen(false)}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "600px",
          backgroundColor: "#fff", // Assuming "background.paper" refers to a white or light color
          borderRadius: "10px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Adjust as needed
          paddingTop: 16, // 4 * 4 = 16 assuming a spacing system where 4 is the base unit
          paddingLeft: 16, // 4 * 4 = 16
          paddingRight: 16, // 4 * 4 = 16
          paddingBottom: 12, // 4 * 3 = 12
          outline: "none",
          maxHeight: "80vh",
        }}
      >
        {term?.text && term?.id && (
          <div className="absolute top-3 right-2">
            <Tooltip title="Delete terms">
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => handlePressDelete()}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        )}
        <h2 className="text-lg font-bold text-center">
          {term?.text ? "Edit Terms" : "Add Terms"}
        </h2>
        <div className="w-1/3 mb-4">
          <InputMUI
            value={editedTermTitle}
            setValue={setEditedTermTitle}
            label="Terms Title"
            inputProps={{ maxLength: 40 }}
          />
        </div>
        <div className="w-full">
          <InputMUI
            value={editedTerm}
            setValue={setEditedTerm}
            label="Enter new terms"
            multiline
            rows={6}
            inputProps={{
              maxLength: 700,
            }}
            sx={{
              "& .MuiInputBase-inputMultiline": {
                maxHeight: "200px", // control the maximum height
                overflow: "auto", // enable scrolling
              },
            }}
          />
        </div>
        <div className="flex flex-row items-center justify-end mt-4 gap-2">
          <button
            className={`px-2 h-10 text-center bg-gray-50 text-gray=800 rounded-md w-16 border border-gray-500`}
            onClick={() => {
              setOpen(false);
              setEditedTerm("");
              setEditedTermTitle("");
            }}
          >
            Cancel
          </button>
          <button
            className={`px-2 h-10 ${
              editedTerm === ""
                ? "bg-gray-300"
                : "bg-gray-900 hover:text-yellow-100"
            } text-white text-center rounded-md w-16`}
            disabled={editedTerm === ""}
            onClick={() =>
              handleAddOrSaveTerm({ title: editedTermTitle, text: editedTerm })
            }
          >
            {term?.id ? "Save" : "Add"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

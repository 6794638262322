import React, { useEffect } from "react";
import {
  useFetchSubSecure,
  useFetchParams,
} from "features/ServiceSubscription/components/hooks/useFetchSub";
import { Elements } from "@stripe/react-stripe-js";
import RenderServiceSubscription from "features/ServiceSubscription/components/AcceptAndSign/RenderServiceSubscription";
import RenderSuccess from "features/ServiceSubscription/components/AcceptAndSign/RenderSuccess";

export default function AcceptServiceSubscription() {
  const { businessId, subscriptionId } = useFetchParams();
  const {
    subscriptionData,
    setSubscriptionData,
    customerData,
    businessData,
    stripePromise,
    loadingSub,
    errorFetching,
  } = useFetchSubSecure({ businessId, subscriptionId });

  if (loadingSub) {
    return <LoadingSkeleton />;
  }

  if (errorFetching) {
    return <ErrorView errorMessage={errorFetching} />;
  }
  return (
    <>
      <Elements stripe={stripePromise}>
        {subscriptionData?.status !== "active" ? (
          <RenderServiceSubscription
            subscription={subscriptionData}
            setSubscription={setSubscriptionData}
            customer={customerData || subscriptionData?.customer}
          />
        ) : (
          <RenderSuccess
            subscription={subscriptionData}
            customer={customerData || subscriptionData?.customer}
            business={businessData}
          />
        )}
      </Elements>
    </>
  );
}

const LoadingSkeleton = () => {
  return (
    <div className="animate-pulse bg-gray-50 min-h-screen w-full flex flex-col items-center px-4 sm:px-0 py-8 sm:py-16">
      <div className="w-full max-w-[700px] font-stripe">
        <div className="bg-gray-300 h-6 sm:h-8 w-2/3 mb-4 rounded"></div>
        <div className="flex flex-col w-full rounded-lg bg-gray-100 py-4 sm:py-6 px-4 sm:px-6 mt-4 shadow-md sm:shadow-2xl">
          <div className="bg-gray-300 h-5 sm:h-6 w-1/2 mb-4 rounded"></div>
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-4 sm:mt-6 px-4 sm:px-6 py-4 bg-gray-200 -mx-4 sm:-mx-6">
            <div className="flex flex-col pr-4 mb-4 sm:mb-0">
              <div className="bg-gray-300 h-5 sm:h-6 w-24 mb-2 rounded"></div>
              <div className="bg-gray-300 h-5 sm:h-6 w-40 sm:w-48 mb-2 rounded"></div>
            </div>
            <div className="flex flex-row items-center">
              <div className="bg-gray-300 h-6 sm:h-8 w-16 mb-2 rounded"></div>
              <div className="bg-gray-300 h-3 sm:h-4 w-10 sm:w-12 ml-2 rounded"></div>
            </div>
          </div>

          <div className="space-y-2 sm:space-y-4 mt-4 sm:mt-8">
            <div className="bg-gray-300 h-5 sm:h-6 w-1/3 rounded"></div>
            <div className="bg-gray-300 h-5 sm:h-6 w-1/3 rounded"></div>
            <div className="bg-gray-300 h-5 sm:h-6 w-1/3 rounded"></div>
            <div className="bg-gray-300 h-5 sm:h-6 w-1/3 rounded"></div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-[700px] font-stripe mt-8 sm:mt-16 mb-8 sm:mb-16">
        <div className="flex flex-col w-full rounded-lg bg-gray-100 py-4 sm:py-6 px-4 sm:px-6 mt-4 shadow-md sm:shadow-2xl">
          <div className="bg-gray-300 h-5 sm:h-6 w-1/2 mb-4 rounded"></div>
          <div className="space-y-2 sm:space-y-4 mt-4 sm:mt-8">
            <div className="bg-gray-300 h-5 sm:h-6 w-1/3 rounded"></div>
            <div className="bg-gray-300 h-5 sm:h-6 w-1/3 rounded"></div>
            <div className="bg-gray-300 h-5 sm:h-6 w-1/3 rounded"></div>
            <div className="bg-gray-300 h-5 sm:h-6 w-1/3 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ErrorView = ({ errorMessage }) => {
  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center bg-gray-50 px-4 sm:px-6 py-8 sm:py-12">
      <div className="w-full max-w-[700px] text-center">
        <div className="bg-white rounded-lg shadow-xl p-6 sm:p-8">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <h2 className="mt-4 text-lg sm:text-xl font-semibold text-gray-900">
            Error Fetching Subscription Data
          </h2>
          <p className="mt-2 text-sm sm:text-base text-gray-500">
            {errorMessage}
          </p>
          <div className="mt-6">
            <button
              onClick={() => window.location.reload()}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import TextAreaWithLabel from "components/other/TextAreaWithLabel";
import { GrNotes } from "react-icons/gr";
import CustomImageInput from "components/reusable/CustomImageInput";

export default function AddNote({
  note,
  setNote,
  images = [],
  setImages,
  fromEstimate = false,
}) {
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const filesWithPreview = files.map((file) => ({
      data: file,
      preview: URL.createObjectURL(file),
    }));

    setImages(filesWithPreview);
  };

  useEffect(() => {
    // Cleanup created URLs on unmount
    return () => {
      images?.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [images]);

  const deleteImage = (previewUrl) => {
    const newSelectedFiles = images.filter(
      (file) => file.preview !== previewUrl
    );
    setImages(newSelectedFiles);

    // Clean up the memory by revoking the Object URL
    URL.revokeObjectURL(previewUrl);
  };

  // console.log("images from addNote", images);

  return (
    <div className="flex flex-col flex-1 lg:col-span-2 bg-white rounded-md shadow-bold  gap-2 mb-8 pb-8 p-4">
      <div className="flex flex-row items-center gap-2 mt-2 mb-2">
        <GrNotes className="inline-block text-2xl" />
        <h1 className="text-xl font-bold">Private Notes</h1>
      </div>
      <TextAreaWithLabel
        label="Notes"
        value={note}
        setValue={setNote}
        className="relative flex-1 flex flex-col"
      />

      {!fromEstimate && (
        <div className="flex flex-row mt-2 ml-1 ">
          {/* <CustomFileInput handleFileChange={handleFileChange} /> */}
          <CustomImageInput handleFileChange={handleFileChange} />
        </div>
      )}
      <div className="flex flex-wrap">
        {!fromEstimate &&
          images &&
          images.length > 0 &&
          images.map((file, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                display: "inline-block",
                margin: 10,
              }}
            >
              <img
                src={file.preview}
                alt={`preview-${index}`}
                style={{ width: 100, height: 100, borderRadius: 5 }}
              />

              <button
                className="absolute top-[-8px] right-[-8px] bg-gray-50 hover:bg-gray-100 rounded-full p-0.5 shadow-md"
                onClick={() => deleteImage(file.preview)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}

import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export default function DollarInputMUI({
  value,
  onValueChange,
  error,
  helperText,
  ...props
}) {
  const handleChangeAmount = (event) => {
    let value = event.target.value;

    // Remove any characters that are not digits or decimal points
    value = value.replace(/[^0-9.]/g, "");

    // Match the format to two decimal places
    const match = value.match(/^\d*\.\d{0,2}/) || value.match(/^\d+/); // Also match number without any leading digits before a decimal
    if (match) {
      value = match[0];
    } else if (value === ".") {
      // Handle case where only a decimal point is entered
      value = "0."; // Convert standalone decimal to "0." for usability
    } else {
      value = ""; // Reset to empty if no valid numeric input exists
    }
    onValueChange(value); // should already be a string
  };
  return (
    <>
      <TextField
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#9ca3af", // Lighter border color
            },
            "&:hover fieldset": {
              borderColor: "#6b7280", // Darker on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "#fef08a", // Highlight color when focused
            },
          },
        }}
        type="text" // Change to text to handle formatted input
        value={value}
        onChange={handleChangeAmount}
        error={error} // Adjust if using a different error state for amount
        helperText={helperText} // Adjust if using a different helper text for amount
        variant="outlined"
        size="small"
        placeholder="0.00"
        label="Amount"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          inputProps: {
            min: 0, // Ensures no negative numbers
          },
        }}
        {...props}
      />
    </>
  );
}

import React, { useMemo } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import LineItem from "../../AddJob/components/LineItem";

export default function LineItemsStatic({ toggleModal, lineItems }) {
  // console.log("lineItems, lineItems Static", lineItems);
  const subTotal = useMemo(() => {
    return lineItems?.reduce((acc, item) => {
      const quantity = parseFloat(item?.quantity) || 0;
      const unitPrice = parseFloat(item?.unitPrice) || 0;
      const total = quantity * unitPrice;
      return acc + total;
    }, 0);
  }, [lineItems]);

  const taxTotal = useMemo(() => {
    return lineItems?.reduce((acc, item) => {
      const quantity = parseFloat(item?.quantity) || 0;
      const unitPrice = parseFloat(item?.unitPrice) || 0;
      const total = quantity * unitPrice;
      const taxRate = parseFloat(item?.taxRate?.rate) || 0;
      const tax = total * (taxRate / 100);
      return acc + tax;
    }, 0);
  }, [lineItems]);

  const total = subTotal + taxTotal;

  return (
    <div className="flex flex-col rounded-md shadow-bold bg-white py-6 px-4 gap-2 mt-2 lg:mt-0 mb-8">
      <div className="flex flex-row justify-between">
        <h1 className="text-xl font-bold">Line Items</h1>
        <AiOutlineEdit
          className="text-lg hover:text-gray-400 cursor-pointer"
          onClick={toggleModal}
        />
      </div>

      {lineItems?.map((item, index) => (
        <div key={index} className={`${index > 0 && "mt-16"}`}>
          <LineItem
            lineItem={item}
            index={index}
            updateLineItem={() => {}}
            taxRates={[]}
            disabled={true}
          />
        </div>
      ))}

      <hr className="my-8 border-b-2 border-b-gray-400 mt-16" />

      <div className="flex flex-col place-self-end items-end pr-2 gap-2 text-lg font-medium">
        <div className="flex flex-row items-end justify-between gap-2 font-medium text-sm w-48 text-gray-500">
          <label className="w-1/2">Subtotal</label>
          <h1 className="font-base text-sm">
            $
            {subTotal.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h1>
        </div>
        <div className="flex flex-row items-end gap-2 justify-between text-sm font-medium w-48 text-gray-500">
          <label className="w-1/2">Tax</label>
          <h1 className="font-base text-sm">
            $
            {taxTotal.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h1>
        </div>
        <div className="flex flex-row items-end  gap-2 justify-between text-lg font-medium w-48">
          <label className="w-1/2">Total</label>
          <h1 className="font-semibold">
            $
            {total.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h1>
        </div>
      </div>
    </div>
  );
}

// import React, { useState, useEffect } from "react";
// import InputWithLabel from "../AddJob/InputWithLabel";
// import UnitQuantityInput from "../AddJob/UnitQuantityInput";
// import UnitPriceInput from "../AddJob/UnitPriceInput";
// import { FaPlus } from "react-icons/fa";
// import LineItem from "../AddJob/LineItem";
// import { AiOutlineCloseCircle, AiOutlineEdit } from "react-icons/ai";
// import { set } from "lodash";

// export default function LineItemsStatic({ toggleModal, lineItems, ...props }) {
//   const [lineItemNumber, setLineItemNumber] = useState(1);
//   const [total, setTotal] = useState(0.0);

//   // state for line item 1
//   const [name1, setName1] = useState("");
//   const [description1, setDescription1] = useState("");
//   const [quantity1, setQuantity1] = useState("");
//   const [unitPrice1, setUnitPrice1] = useState("");
//   const [taxRate1, setTaxRate1] = useState(null);

//   useEffect(() => {
//     if (lineItems && lineItems.length == 1) {
//       setLineItemNumber(1);
//     }
//     if (lineItems && lineItems.length == 2) {
//       setLineItemNumber(2);
//     }
//     if (lineItems && lineItems.length == 3) {
//       setLineItemNumber(3);
//     }
//     if (lineItems && lineItems.length == 4) {
//       setLineItemNumber(4);
//     }
//   }, [lineItems]);

//   useEffect(() => {
//     if (lineItems && lineItems.length > 0) {
//       setName1(lineItems[0]?.name || "");
//       setDescription1(lineItems[0]?.description || "");
//       setQuantity1(lineItems[0]?.quantity || "");
//       setUnitPrice1(lineItems[0]?.unitPrice || "");
//       setTaxRate1(lineItems[0]?.taxRate || "");

//       setName2(lineItems[1]?.name || "");
//       setDescription2(lineItems[1]?.description || "");
//       setQuantity2(lineItems[1]?.quantity || "");
//       setUnitPrice2(lineItems[1]?.unitPrice || "");
//       setTaxRate2(lineItems[1]?.taxRate || "");

//       setName3(lineItems[2]?.name || "");
//       setDescription3(lineItems[2]?.description || "");
//       setQuantity3(lineItems[2]?.quantity || "");
//       setUnitPrice3(lineItems[2]?.unitPrice || "");
//       setTaxRate3(lineItems[2]?.taxRate || "");

//       setName4(lineItems[3]?.name || "");
//       setDescription4(lineItems[3]?.description || "");
//       setQuantity4(lineItems[3]?.quantity || "");
//       setUnitPrice4(lineItems[3]?.unitPrice || "");
//       setTaxRate4(lineItems[3]?.taxRate || "");
//     }
//   }, [lineItems]);

//   // state for line item 2
//   const [name2, setName2] = useState("");
//   const [description2, setDescription2] = useState("");
//   const [quantity2, setQuantity2] = useState(1);
//   const [unitPrice2, setUnitPrice2] = useState(0.0);
//   const [taxRate2, setTaxRate2] = useState(null);

//   // state for line item 3
//   const [name3, setName3] = useState("");
//   const [description3, setDescription3] = useState("");
//   const [quantity3, setQuantity3] = useState(1);
//   const [unitPrice3, setUnitPrice3] = useState(0.0);
//   const [taxRate3, setTaxRate3] = useState(null);

//   // state for line item 4
//   const [name4, setName4] = useState("");
//   const [description4, setDescription4] = useState("");
//   const [quantity4, setQuantity4] = useState(1);
//   const [unitPrice4, setUnitPrice4] = useState(0.0);
//   const [taxRate4, setTaxRate4] = useState(null);

//   const increaseLineItemNumber = () => {
//     if (lineItemNumber < 4) {
//       setLineItemNumber(lineItemNumber + 1);
//     }
//   };

//   useEffect(() => {
//     setTotal(
//       quantity1 * unitPrice1 +
//         quantity2 * unitPrice2 +
//         quantity3 * unitPrice3 +
//         quantity4 * unitPrice4
//     );
//   }, [
//     quantity1,
//     unitPrice1,
//     quantity2,
//     unitPrice2,
//     quantity3,
//     unitPrice3,
//     quantity4,
//     unitPrice4,
//   ]);

//   return (
//     <div className="flex flex-col rounded-md shadow-bold bg-white py-6 px-4 gap-2 mt-2 lg:mt-0 mb-8">
//       <div className="flex flex-row justify-between">
//         <h1 className="text-xl font-bold">Line Items</h1>
//         {props.disabled && (
//           <AiOutlineEdit
//             className="text-lg hover:text-gray-400 cursor-pointer"
//             onClick={toggleModal}
//           />
//         )}
//       </div>

//       <LineItem
//         name={name1}
//         setName={setName1}
//         description={description1}
//         setDescription={setDescription1}
//         quantity={quantity1}
//         setQuantity={setQuantity1}
//         unitPrice={unitPrice1}
//         setUnitPrice={setUnitPrice1}
//         taxRate={taxRate1}
//         setTaxRate={setTaxRate1}
//         disabled={true}
//       />
//       {lineItemNumber > 1 && (
//         <>
//           {/* <AiOutlineCloseCircle
//             className="text-lg ml-1 hover:text-red-400 cursor-pointer"
//             onClick={() => {
//               setLineItemNumber(lineItemNumber - 1)
//               setUnitPrice2(0.0)
//               setQuantity2(1)
//               setName2("")
//               setDescription2("")
//             }}
//           /> */}
//           <div className="mt-16"></div>
//           <LineItem
//             name={name2}
//             setName={setName2}
//             description={description2}
//             setDescription={setDescription2}
//             quantity={quantity2}
//             setQuantity={setQuantity2}
//             unitPrice={unitPrice2}
//             setUnitPrice={setUnitPrice2}
//             taxRate={taxRate2}
//             setTaxRate={setTaxRate2}
//             disabled={true}
//           />
//         </>
//       )}
//       {lineItemNumber > 2 && (
//         <>
//           {/* <AiOutlineCloseCircle
//             className="text-lg ml-1 hover:text-red-400 cursor-pointer"
//             onClick={() => {
//               setLineItemNumber(lineItemNumber - 1)
//               setUnitPrice3(0.0)
//               setQuantity3(1)
//               setName3("")
//               setDescription3("")
//             }}
//           /> */}
//           <div className="mt-16"></div>
//           <LineItem
//             name={name3}
//             setName={setName3}
//             description={description3}
//             setDescription={setDescription3}
//             quantity={quantity3}
//             setQuantity={setQuantity3}
//             unitPrice={unitPrice3}
//             setUnitPrice={setUnitPrice3}
//             taxRate={taxRate3}
//             setTaxRate={setTaxRate3}
//             disabled={true}
//           />
//         </>
//       )}
//       {lineItemNumber > 3 && (
//         <>
//           {/* <AiOutlineCloseCircle
//             className="text-lg ml-1 hover:text-red-400 cursor-pointer"
//             onClick={() => {
//               setLineItemNumber(lineItemNumber - 1)
//               setUnitPrice4(0.0)
//               setQuantity4(1)
//               setName4("")
//               setDescription4("")
//             }}
//           /> */}
//           <div className="mt-16"></div>
//           <LineItem
//             name={name4}
//             setName={setName4}
//             description={description4}
//             setDescription={setDescription4}
//             quantity={quantity4}
//             setQuantity={setQuantity4}
//             unitPrice={unitPrice4}
//             setUnitPrice={setUnitPrice4}
//             taxRate={taxRate4}
//             setTaxRate={setTaxRate4}
//             disabled={true}
//           />
//         </>
//       )}

//       {!props.disabled && (
//         <button
//           className="px-2 mt-4 py-3 border-2 border-gray-900 rounded-md place-self-end  text-gray-900 flex flex-row items-center"
//           onClick={increaseLineItemNumber}
//         >
//           <FaPlus className="inline-block mr-2" /> New Line item
//         </button>
//       )}
//       <hr className="my-8 border-b-2 border-b-gray-400 mt-16" />
//       <div className="flex flex-row place-self-end items-end pr-2 gap-2 text-lg font-medium">
//         <label>Total:</label>
//         <h1 className="font-semibold">${total.toFixed(2)}</h1>
//       </div>
//     </div>
//   );
// }

import React from "react";

export default function PrizeTable() {
  return (
    <div className="w-full flex flex-col items-center justify-center mt-10">
      <div className="max-w-lg w-full">
        <h2 className="text-gray-800 font-bold text-xl mb-2 text-center">
          Top prizes
        </h2>
        <div className="p-2 bg-white rounded-md shadow-lg">
          <table className="w-full">
            <tbody className="divide-y divide-gray-200">
              <tr>
                <td className="font-semibold w-28 px-4 py-3 border-r divide-x divide-gray-200">
                  Top 10
                </td>
                <td className="flex-1 px-4 py-3">
                  Free coaching session with Josh Lester to take your business
                  to the next level.
                </td>
              </tr>
              <tr>
                <td className="font-semibold w-28 px-4 py-3 border-r divide-x divide-gray-200">
                  3rd Place
                </td>
                <td className="flex-1 px-4 py-3">
                  Screen Cleaner ($500 prize value)
                </td>
              </tr>
              <tr>
                <td className="font-semibold w-28 px-4 py-3 border-r divide-x divide-gray-200">
                  2nd Place
                </td>
                <td className="flex-1 px-4 py-3">
                  Waterfed Pole ($1,000 prize value)
                </td>
              </tr>
              <tr>
                <td className="font-semibold w-28 px-4 py-3 border-r divide-x divide-gray-200">
                  1st Place
                </td>
                <td className="flex-1 px-4 py-3">
                  Deionization Tank ($2,000 prize value)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

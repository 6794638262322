import React, { useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "../../index";
import { useUserContext } from "context/UserContext";
import { db, auth } from "../../utils/firebase";
import { getDoc, doc } from "firebase/firestore";
import { node } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import { InlineWidget } from "react-calendly";
import { PopupWidget, PopupButton } from "react-calendly";
import { BsFillArrowRightSquareFill } from "react-icons/bs";

// this is the polling function that will be used later
const pollSubscriptionStatus = async (userId, attempts = 0) => {
  if (attempts > 20) {
    throw new Error("Subscription status not updated in time.");
  }
  // console.log("attempt", attempts);

  if (attempts === 0) {
    // console.log("attempt is 0 so we will do set timeout to start");
    await new Promise((resolve) => setTimeout(resolve, 5000));
  }

  const docRef = doc(db, "businesses", userId, "stripe", "subscription");
  const docSnap = await getDoc(docRef);

  if (
    docSnap.exists() &&
    (docSnap.data().status === "trialing" || docSnap.data().status === "active")
  ) {
    console.log("document exists and status is 'trialing' or 'active'");
    return docSnap.data();
  }

  // Wait for a bit before checking again
  await new Promise((resolve) => setTimeout(resolve, 500));

  return pollSubscriptionStatus(userId, attempts + 1);
};

export default function SubscriptionSuccess() {
  const { user, userData, setUserData } = useUserContext();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const hasFetchedStripeData = useRef(false);

  useEffect(() => {
    if (!userData) return;

    const getUpdatedStripeData = async () => {
      try {
        setLoading(true);
        const subscriptionData = await pollSubscriptionStatus(userData.userData.businessId);

        if (subscriptionData.status === "trialing" && !subscriptionData?.cancel_at_period_end) {
          console.log("Subscription status fetched and is trialing, navigating to dashboard");
          // If the subscription is on trial, set the user's setup to true
          // This will trigger the setup modal to show when they navigate to the dashboard
          // Note we only do this if the subscription is trialing and hasn't been canceled
          setUserData({ ...userData, subData: { ...subscriptionData } });
          navigate("/", {
            state: {
              setUp: true,
            },
          });
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    getUpdatedStripeData();
    // Mark that the data has been fetched
    hasFetchedStripeData.current = true;
  }, [userData]);

  const handleClick = () => {
    console.log("download app");
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    let url = "";

    if (/windows phone/i.test(userAgent)) {
      url = "https://play.google.com/store/apps/details?id=com.derickio.homebase360";
    }

    if (/android/i.test(userAgent)) {
      url = "https://play.google.com/store/apps/details?id=com.derickio.homebase360";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      url = "https://apps.apple.com/us/app/homebase-360/id6467654086";
    }

    if (url !== "") {
      window.open(url, "_blank");
    } else {
      window.open("https://apps.apple.com/us/app/homebase-360/id6467654086", "_blank");
    }
  };

  const prefill = {
    email: userData?.userData?.email,
    name: userData?.userData?.firstName + " " + userData?.userData?.lastName,
  };

  return (
    <>
      <div className="flex h-full min-h-screen  w-full flex-col items-center overflow-auto  bg-gray-50 font-stripe scrollbar-hide">
        <h1
          className="mt-12 flex justify-center pl-2  text-2xl font-semibold text-slate-600 "
          style={
            {
              // width: "920px",
            }
          }
        >
          <img
            src="/images/Homebase-logo-black.png"
            // className="h-14 w-auto"
            width={200}
            alt="Homebase logo"
          />
        </h1>
        <div className="relative mt-10 mb-36 flex flex-col rounded-lg bg-slate-50 shadow-2xl w-11/12 max-w-4xl items-center sm-px-10 px-5 ">
          <h1 className="mt-10 flex justify-center pl-2 text-center text-2xl font-semibold text-gray-600 max-w-xl ">
            You've successfully updated your account 🚀
          </h1>
          <p className="mt-6 mb-6 flex justify-center pl-2 text-center text-lg font-semibold text-gray-600 max-w-xl ">
            Feel free to schedule an onboarding call with us if you need any further assistance.
          </p>
          <div className=" mt-4 mb-6 flex flex-col items-center justify-center bg-gray-800 rounded-md hover:bg-gray-700">
            <PopupButton
              styles={{
                color: "#ffffff",
                padding: "12px 20px",
              }}
              url="https://calendly.com/homebase360/onboard"
              prefill={prefill}
              rootElement={document.getElementById("root")}
              text="Schedule onboarding call 📆"
            />
          </div>
          <h1 className="mt-4 mb-6 flex justify-center pl-2 text-center text-lg font-semibold text-gray-600 max-w-xl ">
            For the best experience on mobile, download the Homebase 360 app if you haven't already.
          </h1>
          <button
            className=" mt-4 mb-6 bg-gray-800 text-white px-5 py-3 rounded-md hover:bg-gray-700"
            onClick={handleClick}
          >
            Download app 📱
          </button>
          <h1 className="mt-4 mb-6 flex justify-center pl-2 text-center text-lg font-semibold text-gray-600 max-w-xl ">
            {loading
              ? "Please wait a few seconds while we update your account before proceeding to the dashboard."
              : "Your account is ready to go you can now proceed to the dashboard."}
          </h1>
          <button
            disabled={loading}
            className={`mt-4 mb-10 bg-gray-800 text-white px-5 py-3 w-56 rounded-md ${
              !loading && "hover:bg-gray-700"
            } flex flex-row items-center justify-center`}
            onClick={() => {
              window.location.href = "/";
            }}
          >
            {loading ? (
              <span className="">Wait to proceed ✋</span>
            ) : (
              <>
                <span className="mr-2">Proceed to dashboard</span>
                <BsFillArrowRightSquareFill className="text-green-400 text-sm" />
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
}

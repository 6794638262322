import React, { useState, useEffect, forwardRef } from "react";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import MomentBasedDatePicker from "components/reusable/MomentBasedDatePicker";
import Header from "features/ServiceSubscription/components/shared/Header";
import Footer from "features/ServiceSubscription/components/shared/Footer";
import InputMUI from "components/reusable/InputMUI";
import { db } from "utils/firebase";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";
import { convertToJsDateNano } from "utils/helpers";

const TransitionComponent = forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

export default function AdvancedFiltersSidebar({
  open,
  onClose,
  setFilteredCustomers,
  userData,
  setIsLoadingCustomers,
  setLoadingCustomersIndicator,
  setAppliedFilters,
}) {
  const timezone = userData.bizData.timeZone;
  const [createdAtStartDate, setCreatedAtStartDate] = useState(null);
  const [createdAtEndDate, setCreatedAtEndDate] = useState(null);

  const [lastJobStartDate, setLastJobStartDate] = useState(null);
  const [lastJobEndDate, setLastJobEndDate] = useState(null);

  const [city, setCity] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const buildQuery = () => {
    let queryRef = collection(
      db,
      "businesses",
      userData?.userData?.businessId,
      "customers"
    );

    if (city) {
      //   console.log("city", city.toLowerCase());
      queryRef = query(
        queryRef,
        where("cities", "array-contains", city.toLowerCase())
      );
    }

    if (createdAtStartDate && createdAtEndDate) {
      let jsStartDate = new Date(createdAtStartDate);
      let jsEndDate = new Date(createdAtEndDate);
      //   console.log(
      //     "jsStartDate",
      //     jsStartDate,
      //     "is Date?",
      //     jsStartDate instanceof Date
      //   );
      //   console.log(
      //     "jsEndDate",
      //     jsEndDate,
      //     "is Date?",
      //     jsEndDate instanceof Date
      //   );

      queryRef = query(
        queryRef,
        where("dateAdded", ">=", jsStartDate),
        where("dateAdded", "<=", jsEndDate)
      );
    }

    // we have to filter for last date after the fact
    // if (lastJobStartDate && lastJobEndDate) {
    //   let jsStartDate = new Date(lastJobStartDate);
    //   let jsEndDate = new Date(lastJobEndDate);
    //   queryRef = query(
    //     queryRef,
    //     where("lastJob", ">=", jsStartDate),
    //     where("lastJob", "<=", jsEndDate)
    //   );
    // }

    return queryRef;
  };

  const applyFilters = async () => {
    setIsLoading(true);
    setIsLoadingCustomers(true);
    setLoadingCustomersIndicator(true);

    try {
      const queryRef = buildQuery();
      const querySnapshot = await getDocs(queryRef);
      let filteredCustomers = querySnapshot.docs.map((doc) => doc.data());

      //   console.log("filteredCustomers.length", filteredCustomers.length);

      if (lastJobStartDate && lastJobEndDate) {
        // Additional client-side filtering for 'lastJob'
        filteredCustomers = filteredCustomers.filter(
          (customer) =>
            convertToJsDateNano(customer.lastJob) >=
              new Date(lastJobStartDate) &&
            convertToJsDateNano(customer.lastJob) <= new Date(lastJobEndDate)
        );
      }
      //   console.log(
      //     "filteredCustomers.length after lastJob",
      //     filteredCustomers.length
      //   );
      setFilteredCustomers(filteredCustomers);

      setAppliedFilters({
        created: createdAtStartDate && createdAtEndDate ? true : false,
        lastJob: lastJobStartDate && lastJobEndDate ? true : false,
        city: city ? true : false,
      });
    } catch (error) {
      console.error("Error fetching filtered customers:", error);
    } finally {
      setIsLoadingCustomers(false);
      setIsLoading(false);
      setLoadingCustomersIndicator(false);
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <TransitionComponent in={open}>
        <div
          className="absolute top-0 bg-white shadow-bold rounded-tl-md rounded-bl-md h-full"
          style={{
            right: 0, // Position it on the right edge of the screen
            width: "400px", // Set a fixed width of 300 pixels
          }}
        >
          <Header title="Advanced Filters" onClose={onClose} />
          <div className="p-6">
            <div className="flex flex-row items-center py-2 gap-4 ">
              <h3 className=" font-medium text-gray-800 ">Created</h3>
            </div>
            <div className="flex flex-row justify-between relative">
              <div className="w-36">
                <MomentBasedDatePicker
                  label="Start"
                  date={createdAtStartDate}
                  setDate={setCreatedAtStartDate}
                  timezone={timezone}
                  borderColor="#4b5563" // gray-600
                  //   wrong={datesWrong}
                  start={true}
                />
              </div>

              <div className="w-36">
                <MomentBasedDatePicker
                  label="End"
                  date={createdAtEndDate}
                  setDate={setCreatedAtEndDate}
                  timezone={timezone}
                  borderColor="#4b5563"
                  //   wrong={datesWrong}
                />
              </div>
              {createdAtStartDate && createdAtEndDate && (
                <button
                  className="text-yellow-300 absolute -bottom-6 left-0"
                  onClick={() => {
                    // clear the date
                    setCreatedAtStartDate(null);
                    setCreatedAtEndDate(null);
                  }}
                >
                  clear
                </button>
              )}
            </div>
            <div className="flex flex-row items-center py-2 gap-4 mt-8">
              <h3 className=" font-medium text-gray-800 ">Last job</h3>
            </div>
            <div className="flex flex-row justify-between relative">
              <div className="w-36">
                <MomentBasedDatePicker
                  label="Start"
                  date={lastJobStartDate}
                  setDate={setLastJobStartDate}
                  timezone={timezone}
                  borderColor="#4b5563" // gray-600
                  //   wrong={datesWrong}
                  start={true}
                />
              </div>

              <div className="w-36">
                <MomentBasedDatePicker
                  label="End"
                  date={lastJobEndDate}
                  setDate={setLastJobEndDate}
                  timezone={timezone}
                  borderColor="#4b5563"
                  //   wrong={datesWrong}
                />
              </div>
              {lastJobStartDate && lastJobEndDate && (
                <button
                  className="text-yellow-300 absolute -bottom-6 left-0"
                  onClick={() => {
                    // clear the date
                    setLastJobEndDate(null);
                    setLastJobStartDate(null);
                  }}
                >
                  clear
                </button>
              )}
            </div>
            <h3 className=" font-medium text-gray-800 mb-2 mt-10">City</h3>
            <InputMUI
              label="City"
              placeholder="Enter city"
              value={city}
              setValue={setCity}
              autoComplete="off"
            />
          </div>
          <Footer
            onClose={onClose}
            disabled={isLoading}
            onCreate={applyFilters}
            loading={isLoading}
            buttonText="Apply Filters"
          />
        </div>
      </TransitionComponent>
    </Modal>
  );
}

import React, { useState, useEffect } from "react";

import { TiArrowRepeatOutline } from "react-icons/ti";

import { AiOutlineEdit } from "react-icons/ai";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Tooltip from "@mui/material/Tooltip";

import { monthMap, frequencyMap } from "../data/constants";
import { FormattedStatus } from "./FormattedStatus";
import { AssociatedVisitToJobModal } from "./AssociatedVisitToJobModal";

// We can also use this for jobDetails page... but then we will need to change the modal that selects the vist... or maybe just the function that saves... because we need to save the current job and it will take a second.... but other than that I think we built this is a way where we can keep everything else the same....

// we need to have a function for adding the visit to the line items... so it is more general.. that way we can just pass a parameter on whether or not we are coming from job Details or add job...

export default function FutureVisits({
  subscription,
  setSubscription,
  fromJobDetails,
  openSubscriptionDetailsModal,
  pushVisitToLineItems,
}) {
  const nextVisit = subscription?.selectedSubscription?.visits?.find(
    (visit) => visit.status === "unscheduled"
  );

  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="flex flex-col flex-1 rounded-md shadow-bold bg-white py-6 px-4 mt-8">
      <AssociatedVisitToJobModal
        subscription={subscription}
        setSubscription={setSubscription}
        open={openModal}
        onClose={() => setOpenModal(false)}
        fromJobDetails={fromJobDetails}
        pushVisitToLineItems={pushVisitToLineItems}
      />
      <div className="flex flex-row items-center ">
        <TiArrowRepeatOutline className="text-2xl mr-2" />
        <h1 className="text-xl font-bold text-gray-900">
          Service Subscription
        </h1>
      </div>

      <div className="mt-2">
        <Tooltip title="View Subscription Details" placement="top">
          <div
            className="flex flex-row justify-between items-center -mx-2 bg-gray-50  p-2 py-6 rounded-md cursor-pointer relative"
            onClick={openSubscriptionDetailsModal}
          >
            <div className="absolute top-1 right-1 text-gray-500 text-xs">
              <FormattedStatus
                status={subscription?.selectedSubscription?.status}
              />
              {/* {formatStatus(subscription?.selectedSubscription.status)} */}
            </div>
            <div className="pr-3">
              <h1 className="text-lg">
                {subscription.selectedSubscription.template.title}
              </h1>
              <h1 className="text-gray-500">
                {subscription?.selectedSubscription?.template.description}
              </h1>
            </div>
            <div className="flex flex-row items-center">
              <div className="font-medium text-lg text-gray-500">
                $
                {parseFloat(
                  subscription?.selectedSubscription?.template?.amount
                ).toFixed(2)}
              </div>
              <div className="flex flex-col ml-2 text-gray-500">
                <span className="leading-none text-xs">per</span>
                <span className="leading-none text-xs">
                  {
                    frequencyMap[
                      subscription?.selectedSubscription?.template
                        ?.paymentFrequency
                    ]
                  }
                </span>
              </div>
            </div>
          </div>
        </Tooltip>
        <div className="flex flex-col gap-2 text-lg text-gray-900 mt-2 justify-start items-start">
          {subscription?.selectedVisit ? (
            <div className="flex flex-row items-center">
              <IoIosCheckmarkCircle className="text-lg h-6 w-6 text-green-400 mr-3" />
              <h1>{subscription?.selectedVisit.name} added to job</h1>

              <AiOutlineEdit
                className="text-lg hover:text-gray-400 cursor-pointer ml-3"
                onClick={() => setOpenModal(true)}
              />
            </div>
          ) : (
            <>
              {nextVisit ? (
                <h1>
                  Next Visit: {monthMap[nextVisit.month]}, {nextVisit.year}
                </h1>
              ) : (
                <h1 className="text-gray-500">No future visits scheduled.</h1>
              )}
              <button
                className="ml-2 text-gray-500 hover:text-gray-700 p-2  hover:bg-yellow-50 rounded-md"
                onClick={() => setOpenModal(true)}
              >
                Add visit to job +
              </button>{" "}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

import { useState, useMemo } from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import InputMUI, { inputStyles } from "components/reusable/InputMUI";
import PriceInputMUI from "components/reusable/PriceInputMUI";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { NewCategoryModal } from "../CategoryModal/NewCategoryModal";
import { getLineItemErrors } from "./helper";
import { useUserContext } from "context/UserContext";
import { createLineItem, deleteLineItem, getAllLineItems } from "features/PriceBook/data/api";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useSnackBar } from "../../context/SnackBarContext";
import { OutlineButton } from "../NewButtons/OutlineButton";
import { SolidButton } from "../NewButtons/SolidButton";
import { CloseModalIconButton } from "../NewButtons/CloseModalIconButton";

export const NewLineItemModal = ({
  open,
  setOpen,
  lineItem,
  setSaved = () => {},
  preselectedCategory,
  isUpdate,
  addAutocompleteOption = () => {},
  isLineItemEditable = true,
}) => {
  const { userData } = useUserContext();
  const categories = userData.bizData.priceBookCategories || [];
  const { openSnackBar } = useSnackBar();

  const [name, setName] = useState(lineItem?.name ? lineItem.name : "");
  const [description, setDescription] = useState(lineItem?.description ? lineItem.description : "");
  const [unitPrice, setUnitPrice] = useState(lineItem?.unitPrice ? lineItem.unitPrice : "");

  const [categoryInputValue, setCategoryInputValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(
    preselectedCategory ? preselectedCategory : null
  );
  const [errors, setErrors] = useState({});
  const [newCategoryModalOpen, setNewCategoryModalOpen] = useState(false);

  const [saving, setSaving] = useState(false);

  const handleSaveLineItem = async () => {
    setSaving(true);
    const lineItemErrors = getLineItemErrors({
      name,
      description,
      unitPrice,
      category: selectedCategory,
    });
    if (lineItemErrors) {
      setErrors(lineItemErrors);
    } else {
      try {
        await createLineItem({
          businessId: userData.userData.businessId,
          categoryId: selectedCategory.id,
          lineItem: {
            name,
            description,
            unitPrice,
            id: lineItem?.id ? lineItem.id : undefined,
          },
        });
        setSaved(true);
        addAutocompleteOption({
          name,
          description,
          unitPrice,
          id: lineItem?.id ? lineItem.id : undefined,
          category: selectedCategory,
        });
      } catch (err) {
        openSnackBar("Error saving line item.", false, true);
        console.error("Error saving line item.", err);
      } finally {
        setOpen(false);
      }
    }

    setSaving(false);
  };

  const handleDeleteLineItem = async () => {
    try {
      await deleteLineItem({
        businessId: userData.userData.businessId,
        categoryId: selectedCategory.id,
        lineItemId: lineItem?.id,
      });
    } catch (err) {
      console.error("Error deleting line item", err);
    }
    setOpen(false);
  };

  return (
    <>
      <Dialog className="relative z-10" open={open} onClose={() => {}}>
        <DialogBackdrop transition className="fixed inset-0 bg-gray-500 bg-opacity-75 " />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative overflow-hidden rounded-lg bg-white px-3 py-3 text-left shadow-xl sm:w-full sm:max-w-lg sm:p-4 "
            >
              <div className="absolute top-3 right-3">
                <CloseModalIconButton handleCancel={() => setOpen(false)} />
              </div>
              <div className="text-center">
                <DialogTitle as="h2" className="text-xl font-bold text-center mb-6">
                  {isUpdate ? "Update Line Item" : "Save New Line Item"}
                </DialogTitle>
                <div>
                  {!preselectedCategory && (
                    <div className="flex flex-row mb-2 items-center">
                      <h2 className="text-lg font-medium">Select Category</h2>
                    </div>
                  )}
                  <Autocomplete
                    blurOnSelect
                    disabled={!!preselectedCategory}
                    options={categories}
                    getOptionLabel={(option) => option.name}
                    value={selectedCategory}
                    onChange={(event, newValue) => {
                      setSelectedCategory(newValue);
                    }}
                    inputValue={categoryInputValue}
                    onInputChange={(event, newInputValue) => {
                      setCategoryInputValue(newInputValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="Category"
                        sx={inputStyles}
                        error={!!errors.category}
                        helperText={errors.category ? errors.category : " "}
                      />
                    )}
                    renderOption={(props, option, { selected, active }) => (
                      <li
                        {...props}
                        style={{
                          ...props.style,

                          fontFamily: '"Inter var", system-ui, sans-serif',
                          backgroundColor: selected && !active ? "#fefce8" : undefined,
                        }}
                      >
                        <div className="">
                          <p className="text-base">{option.name}</p>
                        </div>
                      </li>
                    )}
                    PaperComponent={({ children }) => {
                      return (
                        <Paper>
                          {!selectedCategory && (
                            <Button
                              fullWidth
                              size="large"
                              variant="text"
                              color="yellow"
                              sx={{ justifyContent: "flex-start", p: 2 }}
                              onMouseDown={() => {
                                setNewCategoryModalOpen(true);
                              }}
                            >
                              + Create New Category
                            </Button>
                          )}
                          {children}
                        </Paper>
                      );
                    }}
                  />
                </div>
                <div className="mt-2">
                  <div className="flex flex-row mb-2 items-center justify-between">
                    <h2 className="text-lg font-medium">Line Item Details</h2>
                    {isUpdate && (
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 w-fit"
                        onClick={handleDeleteLineItem}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                  <InputMUI
                    disabled={!isLineItemEditable}
                    autoComplete="off"
                    label="Item Name"
                    value={name}
                    setValue={(value) => setName(value)}
                    error={!!errors.name}
                    helperText={errors.name ? errors.name : " "}
                  />
                  <InputMUI
                    disabled={!isLineItemEditable}
                    autoComplete="off"
                    label="Description (optional)"
                    value={description}
                    setValue={(value) => setDescription(value)}
                    multiline
                    error={!!errors.description}
                    helperText={errors.description ? errors.description : " "}
                  />
                  <PriceInputMUI
                    disabled={!isLineItemEditable}
                    autoComplete="off"
                    label="Unit Price"
                    value={unitPrice} // Ensure this is the clean initial value
                    onChange={(cleanValue) => setUnitPrice(cleanValue)}
                    onBlur={() => {
                      if (!unitPrice || isNaN(parseFloat(unitPrice))) {
                        setUnitPrice("0.00");
                      }
                    }}
                    placeholder="0.00"
                    error={!!errors.unitPrice}
                    helperText={errors.unitPrice ? errors.unitPrice : " "}
                  />
                </div>
              </div>
              <div className="mt-5 flex flex-row items-center gap-3">
                <OutlineButton text="Cancel" onClick={() => setOpen(false)} fullWidth />
                <SolidButton text="Save" loading={saving} onClick={handleSaveLineItem} fullWidth />
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      {newCategoryModalOpen && (
        <NewCategoryModal
          open={newCategoryModalOpen}
          setOpen={setNewCategoryModalOpen}
          categoryName={categoryInputValue}
          setSelectedCategory={setSelectedCategory}
        />
      )}
    </>
  );
};

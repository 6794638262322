import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "utils/firebase";
import { useSnackBar } from "context/SnackBarContext";

export function useSubscriptionListener({ businessId, subscriptionId }) {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const { openSnackBar } = useSnackBar();

  useEffect(() => {
    if (!businessId || !subscriptionId) return;

    setLoading(true);

    const subscriptionDocRef = doc(
      db,
      "businesses",
      businessId,
      "subscriptions",
      subscriptionId
    );

    const unsubscribe = onSnapshot(
      subscriptionDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setSubscription(docSnapshot.data());
        } else {
          setSubscription(null);
        }
        setLoading(false);
      },
      (error) => {
        openSnackBar(
          "Error loading subscription, please try again.",
          false,
          true
        );
        console.error("Error fetching subscription", error);
        setLoading(false);
      }
    );

    // Cleanup function to unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, [businessId, subscriptionId]);

  return { subscription, setSubscription, loading };
}

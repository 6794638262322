import React from "react";

export default function PreviewLeaderboard() {
  return (
    <div className="bg-white shadow p-2 rounded mb-4 relative">
      <div className="space-y-2 pb-2">
        {/* Static Header */}
        <div className="flex justify-between text-gray-600 px-2 text-sm gap-2">
          <div className="w-1/12 truncate">Rank</div>
          <div className="w-3/12 truncate">Business</div>
          <div className="w-3/12 truncate">Name</div>
          <div className="w-2/12 truncate">Last 24h</div>
          <div className="w-3/12 truncate">Total Revenue</div>
        </div>

        {/* Pulsating Rows */}
        {Array.from({ length: 10 }).map((_, idx) => (
          <div
            key={idx}
            className="flex justify-between items-center px-2 animate-pulse gap-1"
          >
            <div className="bg-gray-100 rounded h-10 w-1/12"></div> {/* Rank */}
            <div className="bg-gray-100 rounded h-10 w-3/12"></div>{" "}
            {/* Business */}
            <div className="bg-gray-100 rounded h-10 w-3/12"></div> {/* Name */}
            <div className="bg-gray-100 rounded h-10 w-2/12"></div>{" "}
            {/* Last 24h */}
            <div className="bg-gray-100 rounded h-10 w-3/12"></div>{" "}
            {/* Total Revenue */}
          </div>
        ))}
      </div>
      <div className="absolute top-1/4 right-1/4 left-1/4 flex items-center justify-center">
        <div className="bg-white p-4 rounded-md text-center shadow-lg">
          <h1 className=" font-semibold text-gray-600 ">
            Leaderboard will be shown here{" "}
          </h1>
          <h2 className="font-bold underline-effect-yellow ">
            When the games begin
          </h2>
        </div>
      </div>
    </div>
  );
}

// EmployeeContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../utils/firebase";
import { useUserContext } from "./UserContext";

const EmployeeContext = createContext();

export const useEmployeeContext = () => useContext(EmployeeContext);

export const EmployeeProvider = ({ children }) => {
  const { userData } = useUserContext();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // console.log("EmployeeContext: useEffect triggered");
    if (!userData?.userData?.businessId) {
      setLoading(false);
      return;
    }
    // console.log(
    //   `EmployeeContext: Setting up listener for businessId: ${userData?.userData.businessId}`
    // );
    const usersRef = collection(db, "users");
    const allEmployees = query(
      usersRef,
      where("businessId", "==", userData?.userData.businessId)
    );

    const unsubscribe = onSnapshot(
      allEmployees,
      (querySnapshot) => {
        // console.log(
        //   `EmployeeContext: Snapshot received with ${querySnapshot.docs.length} documents`
        // );
        const employeeArray = querySnapshot.docs.map((doc) => doc.data()); // id is already in there
        setEmployees(
          employeeArray.sort((a, b) => a.firstName.localeCompare(b.firstName))
        );
        setLoading(false);
        // console.log(
        //   `EmployeeContext: Updated employees state with ${employeeArray.length} employees`
        // );
      },
      (error) => {
        console.error("EmployeeContext: Error fetching employees:", error);
        setLoading(false);
      }
    );

    return () => {
      // console.log("EmployeeContext: Unsubscribing from listener");
      try {
        unsubscribe();
      } catch (error) {
        // console.error("Error unsubscribing from listener:", error);
      }
    };
  }, [userData?.userData?.businessId]); // Re-subscribe if businessId changes

  return (
    <EmployeeContext.Provider value={{ employees, loading }}>
      {children}
    </EmployeeContext.Provider>
  );
};

import React from "react";
import SubDescription from "./SubDescription";
import DisplayTerms from "./DisplayTerms";
import PaymentSection from "./PaymentSection";

// so we need a few different sections here... all pretty similar to the sections in the subscription details page
// subscription description
//-- display the number of visits per year (if there are any)
//-- and the payment frequency
// and duration (this includes the start date until the end)

// if terms exist for the subscription we will display it here.. if subscription is required then we will need to have them sign below that
// payment section ... we will either allow them to enter in their payment details, or if there is a card on file then they can use that to pay -- there will be a button that says accept and pay

// then once they pay and accept the service subscription we need to display the fact that it was successful -- I think continuing to see the details would be good.. but just having some sort of clear user feedback that it is successful.. I think we should probably go through what that should look like by copying housecall pro as well

// we may need to prop drill the setSubscription as well...so that we can just directly make changes when we handle paid... that's definitely the best approach as opposed to having loose state to store those things..

const defaultTemplateDetails = {
  title: "",
  description: "",
  type: "indefinite", // default type
  duration: 1, // default duration, used if type is "fixed"
  visits: 2, // default number of visits // max of 12 per year
  amount: "0.00", // default amount
  paymentFrequency: "monthly", // default frequency
  allowOtherPayments: false, // default to false
  requireSignature: false, // default to false
  terms: null,
  attachments: [],
  // terms look like this when truthy
  //   {
  //     title: "",
  //     text: "",
  //     id: 23423432,
  //   },
};
const defaultSubscription = {
  businessId: "businessID",
  customer: "customerObject",
  template: defaultTemplateDetails,
  startDate: new Date(),
  soldBy: null,
  tax: null, // {rate:12, name: "San Diego tax"}
  visits: [], // { id: uuid(), month: 1, year: 2022, status: "unscheduled", jobId: null}
  accept: {
    signature: null, // will be updated when signed
    dateAccepted: null, // these will be updated once it's accepted and signed
    type: "link", // default to sending a link to customer
  },
  createdAt: new Date(),
  createdBy: null,
  status: "sent",
  history: [], //   {date: new Date().toISOString(), action: "created",  by: userData.userData.id, details: "Initial creation of subscription" }
};

export default function RenderServiceSubscription({
  subscription,
  setSubscription,
  customer,
}) {
  return (
    <div className="bg-gray-50 min-h-screen w-full flex flex-col items-center px-3 sm:px-0">
      <div className="w-full max-w-[700px] font-stripe mt-8 sm:mt-16">
        <SubDescription subscription={subscription} customer={customer} />
        {subscription?.template?.terms?.text && (
          <DisplayTerms
            subscription={subscription}
            setSubscription={setSubscription}
            customer={customer}
          />
        )}
        <PaymentSection
          subscription={subscription}
          setSubscription={setSubscription}
          customer={customer}
        />
      </div>
    </div>
  );
}

import React from "react";
import { useUserContext } from "context/UserContext";

export default function PageLayout({ children }) {
  const { bannerVisible } = useUserContext();
  return (
    <div
      className={`bg-gray-50 ${
        bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
      } overflow-y-auto`}
    >
      {children}
    </div>
  );
}

import { useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import InputMUI from "components/reusable/InputMUI";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { createCategory } from "../../features/PriceBook/data/api";
import { useUserContext } from "../../context/UserContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackBar } from "../../context/SnackBarContext";
import { OutlineButton } from "../NewButtons/OutlineButton";
import { SolidButton } from "../NewButtons/SolidButton";
import { CloseModalIconButton } from "../NewButtons/CloseModalIconButton";

export const NewCategoryModal = ({
  open,
  setOpen,
  categoryName = "",
  redirect,
  setSelectedCategory,
}) => {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();
  const navigate = useNavigate();

  const [name, setName] = useState(categoryName);
  const [saving, setSaving] = useState(false);

  const onCreateCategory = async () => {
    setSaving(true);
    const catId = uuidv4();
    try {
      await createCategory({
        businessId: userData.userData.businessId,
        categoryId: catId,
        categoryName: name,
      });
      if (redirect) {
        navigate(`/settings/customizations/price-book/${catId}`, {
          state: { name },
        });
      } else {
        setSelectedCategory({ id: catId, name });
      }
    } catch (err) {
      console.error("Error", err);
      openSnackBar("Error creating category.", false, true);
    }
    setSaving(false);
    setOpen(false);
  };

  return (
    <Dialog className="relative z-50" open={open} onClose={() => {}}>
      <DialogBackdrop transition className="fixed inset-0 bg-gray-500 bg-opacity-75 " />

      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative overflow-hidden rounded-lg bg-white px-4 py-4 text-left shadow-xl   w-full max-w-lg "
          >
            <div className="absolute top-3 right-3">
              <CloseModalIconButton handleCancel={() => setOpen(false)} />
            </div>
            <div className="text-center">
              <DialogTitle as="h2" className="text-xl font-bold text-center mb-6">
                Create New Category
              </DialogTitle>

              <div className="mt-2">
                <InputMUI
                  disabled={false}
                  autoComplete="off"
                  label="Category Name"
                  value={name}
                  setValue={(value) => setName(value)}
                />
              </div>
            </div>

            <div className="mt-5 flex flex-row items-center gap-3">
              <OutlineButton text="Cancel" onClick={() => setOpen(false)} fullWidth />
              <SolidButton
                onClick={onCreateCategory}
                disabled={!name}
                text="Create"
                fullWidth
                loading={saving}
              />
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

import React, { useState, useEffect } from "react";
import { TbLayoutList } from "react-icons/tb";

// const salespeople = [
//   { id: 1, name: "John Doe" },
//   { id: 2, name: "Jane Smith" },
//   { id: 3, name: "Bob Johnson" },
//   { id: 4, name: "Alice Williams" },
// ]

export default function SalesPersonSearch({
  employeeList: salespeople,
  setSelected,
  selected,
}) {
  const [inputValue, setInputValue] = useState("");

  const [selectedSalesperson, setSelectedSalesperson] = useState(null);

  useEffect(() => {
    if (selected) {
      setSelectedSalesperson(selected);
      setInputValue(selected.firstName);
    }
  }, [selected]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    setSelectedSalesperson(null);
    setSelected(null);
  };

  const handleSelect = (salesperson) => {
    setSelectedSalesperson(salesperson);
    setSelected(salesperson);
    setInputValue(salesperson.firstName);
  };

  // Filter the salespeople based on the user's input
  const filteredSalespeople =
    salespeople &&
    salespeople.length > 0 &&
    salespeople.filter((salesperson) =>
      salesperson.firstName
        .toLowerCase()
        .includes(
          inputValue.toLowerCase() ||
            salesperson.lastName
              .toLowerCase()
              .includes(inputValue.toLowerCase())
        )
    );

  return (
    <div className="flex flex-col items-center w-full">
      <TbLayoutList
        className={`text-xl absolute ${
          selectedSalesperson && " text-yellow-300 "
        }`}
        style={{
          top: "13px",
          left: "7px",
        }}
      />
      <input
        className={` flex flex-row items-center cursor-pointer w-full px-2 h-12 pl-8 gap-2 py-3 border border-gray-300 rounded-md text-sm    focus:outline-none ${
          selectedSalesperson
            ? "bg-gray-800  text-yellow-100 font-semibold"
            : "bg-white font-medium text-gray-700 hover:bg-gray-50"
        }`}
        placeholder="Salesperson"
        value={inputValue}
        onChange={handleChange}
      />
      {!selectedSalesperson && inputValue && (
        <div
          className="absolute z-50 w-1/2 mt-1 bg-white rounded-md shadow-lg"
          style={{
            top: "45px",
            left: "0px",
          }}
        >
          <ul>
            {filteredSalespeople && filteredSalespeople.length > 0 ? (
              filteredSalespeople.map((salesperson) => (
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  key={salesperson.id}
                  onClick={() => handleSelect(salesperson)}
                >
                  {salesperson.firstName + " " + salesperson.lastName}
                </li>
              ))
            ) : (
              <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">
                No Salesperson Found
              </li>
            )}
          </ul>
        </div>
      )}
      {/* {inputValue && (
        <label
          className="absolute top-0 left-3  p-3 font-semibold text-gray-600"
          style={{
            pointerEvents: "none",
            transition: "all 0.3s ease",
            transform: "translateY(-30px) scale(0.8)",

            opacity: inputValue ? 1 : 0,
          }}
        >
          Salesperson
        </label>
      )} */}
    </div>
  );
}

import React, { useState, useMemo, useEffect } from "react";
import { CheckboxInvoice } from "components/ui/checkboxInvoice";
import {
  useStripe,
  CardElement,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { IoIosInformationCircleOutline } from "react-icons/io";
import PayButton from "components/payments/PayButton";
import { fakeStartServiceSubscription } from "utils/serverCalls";
import { createStripeCustomer } from "utils/stripeConnect";
import { node } from "constants/constants";
import { determinePaymentTiming } from "../../utils/determinePaymentTiming";

const freqMap = {
  monthly: "month",
  quarterly: "3 mo",
  "6 months": "6 mo",
  yearly: "year",
};

const defaultTemplateDetails = {
  title: "",
  description: "",
  type: "indefinite", // default type
  duration: 1, // default duration, used if type is "fixed"
  visits: 2, // default number of visits // max of 12 per year
  amount: "0.00", // default amount
  paymentFrequency: "monthly", // default frequency
  allowOtherPayments: false, // default to false
  requireSignature: false, // default to false
  terms: null,
  attachments: [],
  // terms look like this when truthy
  //   {
  //     title: "",
  //     text: "",
  //     id: 23423432,
  //   },
};
const defaultSubscription = {
  businessId: "businessID",
  customer: "customerObject",
  template: defaultTemplateDetails,
  startDate: new Date(),
  soldBy: null,
  tax: null, // {rate:12, name: "San Diego tax"}
  visits: [], // { id: uuid(), month: 1, year: 2022, status: "unscheduled", jobId: null}
  accept: {
    signature: null, // will be updated when signed
    dateAccepted: null, // these will be updated once it's accepted and signed
    type: "link", // default to sending a link to customer
  },
  createdAt: new Date(),
  createdBy: null,
  status: "sent",
  history: [], //   {date: new Date().toISOString(), action: "created",  by: userData.userData.id, details: "Initial creation of subscription" }
};

export default function PaymentSection({
  subscription,
  setSubscription,
  customer,
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [shouldSavePaymentMethod, setShouldSavePaymentMethod] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);

  const [paymentTiming, setPaymentTiming] = useState(null);

  useEffect(() => {
    if (subscription?.startDate) {
      try {
        const timing = determinePaymentTiming(subscription.startDate);
        setPaymentTiming(timing);
      } catch (error) {
        // console.error("Error determining payment timing:", error);
      }
    }
  }, [subscription]);

  const validateInputs = (stripe, elements) => {
    if (!stripe || !elements) {
      setErrorMessage("Stripe has not been initialized.");
      return false;
    }
    if (
      subscription.template.requireSignature &&
      !subscription.accept?.signature
    ) {
      setErrorMessage("Please sign the terms before proceeding.");
      return false;
    }
    // Add any other necessary validations
    return true;
  };

  // Paying with a card they just entered
  const handleSubmit = async (event, stripe, elements) => {
    event.preventDefault();
    setErrorMessage("");
    console.log("submitting payment");

    if (!validateInputs(stripe, elements) || isProcessing) return;
    setIsProcessing(true);
    try {
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        throw new Error(error.message);
      }

      const response = await fetch(`${node}/serviceSubscriptionsApi/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscription: {
            businessId: subscription.businessId,
            customerId: customer.customerId,
            subscriptionId: subscription.subscriptionId,
            template: subscription.template,
            startDate: subscription.startDate,
            accept: subscription.accept,
            tax: subscription.tax,
          },
          customer: customer,
          paymentMethodId: paymentMethod.id,
          shouldSavePaymentMethod,
          stripeAccountId: subscription.stripeAccountId,
          useExistingPaymentMethod: false,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to create subscription");
      }

      const result = await response.json();

      if (!result.success) {
        throw new Error(result.error || "Failed to create subscription");
      }
      setSubscription((prevSubscription) => ({
        ...prevSubscription,
        stripeSubscription: result.stripeSubscription,
        status: "active",
      }));
    } catch (error) {
      setErrorMessage(
        error.message || "An error occurred, please try again later."
      );
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePayWithCardOnFile = async () => {
    // Check for signature if required
    if (
      subscription.template.requireSignature &&
      !subscription.accept?.signature
    ) {
      setErrorMessage("Please sign the terms above before proceeding.");
      return;
    }

    if (isProcessing) return;
    setIsProcessing(true);

    setErrorMessage("");

    try {
      const response = await fetch(`${node}/serviceSubscriptionsApi/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscription: {
            businessId: subscription.businessId,
            customerId: customer.customerId,
            subscriptionId: subscription.subscriptionId,
            template: subscription.template,
            startDate: subscription.startDate,
            accept: subscription.accept,
            tax: subscription.tax,
          },
          customer: customer,
          paymentMethodId: customer.stripePaymentMethod.id,
          shouldSavePaymentMethod: false, // it should already be saved on file to customer doc
          stripeAccountId: subscription.stripeAccountId,
          useExistingPaymentMethod: true,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to create subscription");
      }

      const result = await response.json();

      if (!result.success) {
        throw new Error(result.error || "Failed to create subscription");
      }

      setSubscription((prev) => ({
        ...prev,
        status: "active",
        stripeSubscription: result.stripeSubscription,
      }));
    } catch (error) {
      console.error("Error creating subscription:", error);
      setErrorMessage(
        error.message || "An error occurred, please try again later."
      );
    } finally {
      setIsProcessing(false);
    }
  };

  const taxAmount = useMemo(() => {
    let amount = parseFloat(subscription?.template?.amount) || 0;
    let taxRate = parseFloat(subscription?.tax?.rate) || 0;

    let total = (amount * taxRate) / 100;
    return total.toFixed(2);
  }, [subscription]);

  const totalPaymentAmount = useMemo(() => {
    let amount = parseFloat(subscription?.template?.amount) || 0;
    let taxRate = parseFloat(subscription?.tax?.rate) || 0;

    let total = amount + (amount * taxRate) / 100;
    return total.toFixed(2);
  }, [subscription]);
  return (
    <div className="flex flex-col w-full rounded-lg bg-gray-25 py-4 sm:py-6 px-4 sm:px-6 mt-4 sm:mt-8 shadow-md sm:shadow-2xl mb-8 sm:mb-16 text-gray-600">
      <div className="bg-gray-100 -mx-4 sm:-mx-6 py-2 -mt-4 sm:-mt-6 mb-2 rounded-t-lg">
        <h1 className="py-2 sm:py-4 px-4 sm:px-6 text-lg sm:text-xl font-semibold">
          Summary
        </h1>
      </div>

      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-2 sm:mt-4">
        <h1 className="text-base sm:text-lg text-gray-500 font-medium pr-4 mb-2 sm:mb-0 w-full sm:max-w-[60%]">
          {subscription.template.title}
        </h1>
        <h1 className="text-base sm:text-lg text-gray-500 flex flex-row items-center">
          <p className="mr-1">
            ${parseFloat(subscription.template.amount).toFixed(2)}
          </p>
          <p className="text-gray-400 text-sm sm:text-base">
            /{freqMap[subscription.template.paymentFrequency]}
          </p>
        </h1>
      </div>

      {subscription?.tax?.rate && subscription?.tax?.name && (
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-2 sm:mt-4">
          <h1 className="text-base sm:text-lg text-gray-500 font-medium pr-4 mb-2 sm:mb-0">
            Tax ({subscription?.tax?.name})
          </h1>
          <h1 className="text-base sm:text-lg text-gray-500 flex flex-row items-center">
            <p className="mr-1 text-gray-500">${taxAmount}</p>
            <p className="text-gray-400 text-sm sm:text-base">
              /{freqMap[subscription.template.paymentFrequency]}
            </p>
          </h1>
        </div>
      )}

      <hr className="border border-gray-300 mt-4" />

      <div className="flex flex-row justify-between items-center mt-4">
        <h1 className="text-lg sm:text-xl font-semibold pr-4">Total</h1>
        <h1 className="text-lg sm:text-xl font-semibold flex flex-row items-center">
          <p className="mr-1">${totalPaymentAmount}</p>
          <p className="text-sm sm:text-base">
            /{freqMap[subscription.template.paymentFrequency]}
          </p>
        </h1>
      </div>

      <PaymentForm
        subscription={subscription}
        customer={customer}
        handleSubmit={handleSubmit}
        handlePayWithCardOnFile={handlePayWithCardOnFile}
        errorMessage={errorMessage}
        isProcessing={isProcessing}
        shouldSavePaymentMethod={shouldSavePaymentMethod}
        setShouldSavePaymentMethod={setShouldSavePaymentMethod}
        cardComplete={cardComplete}
        setCardComplete={setCardComplete}
        totalPaymentAmount={totalPaymentAmount}
      />

      {paymentTiming && (
        <div className="w-full mx-auto flex flex-col items-center sm:items-center sm:mt-4">
          <div className="bg-sky-50 border-l border-sky-500 sm:border-0 text-slate-500 w-full  px-2  text-xs h-10 rounded-r-md items-center flex flex-col justify-center">
            <p className="">{paymentTiming.message}</p>
          </div>
        </div>
      )}
    </div>
  );
}

const PaymentForm = ({
  subscription,
  customer,
  handleSubmit,
  errorMessage,
  isProcessing,
  shouldSavePaymentMethod,
  setShouldSavePaymentMethod,
  cardComplete,
  setCardComplete,
  handlePayWithCardOnFile,
  totalPaymentAmount,
}) => {
  const [useSavedPaymentMethod, setUseSavedPaymentMethod] = useState(true);

  const savedPaymentMethod = customer?.stripePaymentMethod;

  if (savedPaymentMethod) {
    let brand = savedPaymentMethod?.card?.brand || "";
    let formattedBrand =
      brand.charAt(0).toUpperCase() + brand.slice(1).toLowerCase();
    return (
      <div>
        <div className="flex flex-col ml-2 mt-10 mb-6 gap-3">
          <div>
            <input
              disabled={isProcessing}
              type="radio"
              id="use-saved-payment-method"
              name="use-saved-payment-method"
              // value="warrant-work"
              checked={useSavedPaymentMethod}
              onChange={() => setUseSavedPaymentMethod(true)}
            />
            <label
              htmlFor="use-saved-payment-method"
              className="ml-2 text-gray-600"
            >
              Card on file
            </label>
            {useSavedPaymentMethod && (
              <div className="flex flex-row items-center gap-2 ml-4 p-2 font-medium text-slate-600">
                <div>{formattedBrand}</div>
                <div>••••{savedPaymentMethod.card.last4}</div>
                <div>
                  Expires {savedPaymentMethod.card.exp_month}/
                  {savedPaymentMethod.card.exp_year}
                </div>
              </div>
            )}
          </div>
          <div>
            <input
              disabled={isProcessing}
              type="radio"
              id="use-other-card"
              name="use-other-card"
              value="use-other-card"
              checked={!useSavedPaymentMethod}
              onChange={() => setUseSavedPaymentMethod(false)}
            />
            <label htmlFor="use-other-card" className="ml-2 text-gray-600">
              Other Card
            </label>
          </div>
        </div>

        {useSavedPaymentMethod ? (
          <div className="ml-0 sm:ml-7 mb-0">
            {errorMessage && (
              <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
            )}
            <div className="flex flex-col sm:flex-row-reverse sm:justify-between sm:items-center mt-6 sm:mt-10">
              <h1 className="text-lg sm:text-lg text-gray-600 font-semibold text-right sm:text-left">
                Total ${totalPaymentAmount}
              </h1>
              <div className="w-full sm:w-2/5 mb-4 sm:mb-0 mt-2 sm:mt-0">
                <PayButton
                  text="Confirm Subscription"
                  onClick={handlePayWithCardOnFile}
                  loading={isProcessing}
                  width="w-full"
                  height="min-h-[48px] sm:h-10"
                  fontSize="text-sm sm:text-base"
                />
              </div>
            </div>
          </div>
        ) : (
          <StripeCardForm
            handleSubmit={handleSubmit}
            errorMessage={errorMessage}
            subscription={subscription}
            isProcessing={isProcessing}
            shouldSavePaymentMethod={shouldSavePaymentMethod}
            setShouldSavePaymentMethod={setShouldSavePaymentMethod}
            cardComplete={cardComplete}
            setCardComplete={setCardComplete}
            totalPaymentAmount={totalPaymentAmount}
            customer={customer}
          />
        )}
      </div>
    );
  } else {
    return (
      <StripeCardForm
        handleSubmit={handleSubmit}
        errorMessage={errorMessage}
        subscription={subscription}
        isProcessing={isProcessing}
        shouldSavePaymentMethod={shouldSavePaymentMethod}
        setShouldSavePaymentMethod={setShouldSavePaymentMethod}
        cardComplete={cardComplete}
        setCardComplete={setCardComplete}
        totalPaymentAmount={totalPaymentAmount}
        customer={customer}
      />
    );
  }
};

const StripeCardForm = ({
  handleSubmit,
  errorMessage,
  subscription,
  isProcessing,
  shouldSavePaymentMethod,
  setShouldSavePaymentMethod,
  cardComplete,
  setCardComplete,
  totalPaymentAmount,
  customer,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = {
    style: {
      base: {
        iconColor: "#0f172a",
        color: "#1e293b",
        fontWeight: "400",
        fontFamily: "Inter Var, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        // ":focus": {
        //   borderStyle: "solid",
        //   borderWidth: "8px",
        //   borderColor: "#db28ff",
        // },
        ":-webkit-autofill": {
          color: "#1e293b",
        },
        "::placeholder": {
          color: "#94a3b8",
        },
      },
      invalid: {
        iconColor: "#ef4444",
        color: "#ef4444",
      },
    },
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e, stripe, elements)}
      className="sm:mx-7   rounded-md mb-4 sm:mb-0 mt-5"
    >
      <CardElement
        className="p-4 shadow-md ring-1 ring-slate-300 rounded-md my-5"
        options={options}
        onChange={(e) => {
          setCardComplete(e.complete);
        }}
      />

      <div className="flex flex-col mt-5">
        {/* <div className="flex flex-row items-center mt-5 ml-3">
          <CheckboxInvoice
            disabled={isProcessing}
            checked={shouldSavePaymentMethod}
            onCheckedChange={() =>
              setShouldSavePaymentMethod(!shouldSavePaymentMethod)
            }
          />

          <h1 className="text-slate-600 text-sm ml-2">Save card on file</h1>
        </div> */}
      </div>

      {errorMessage && (
        <div className="text-red-500 text-sm mt-4">{errorMessage}</div>
      )}

      <div className="flex flex-col sm:flex-row-reverse sm:justify-between sm:items-center mt-6 sm:mt-10">
        <h1 className="text-lg sm:text-lg text-gray-600 font-semibold text-right sm:text-left mb-2 sm:mb-0">
          Total ${totalPaymentAmount}
        </h1>
        <div className="w-full sm:w-2/5">
          <PayButton
            text="Confirm Subscription"
            loading={isProcessing}
            disabled={!cardComplete}
            cardComplete={cardComplete}
            width="w-full"
            height="min-h-[48px] sm:h-10"
            fontSize="text-sm sm:text-base"
          />
        </div>
      </div>
    </form>
  );
};

// PhoneInputMUI.js
// PhoneInputMUI.js
import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

const formatInitialPhone = (value) => {
  if (!value) return value;
  const formattedPhoneNumber =
    "(" +
    value.substring(2, 5) +
    ") " +
    value.substring(5, 8) +
    "-" +
    value.substring(8, 12);
  return formattedPhoneNumber;
};

const PhoneInputMUI = ({
  value,
  setValue,
  label,
  onChangeFunction = null,
  ...props
}) => {
  const [phoneBeta, setPhoneBeta] = useState("");

  useEffect(() => {
    setPhoneBeta(formatInitialPhone(value));
  }, []);

  useEffect(() => {
    if (phoneBeta === "") {
      setValue("");
    } else {
      let modified = phoneBeta.replace(/\D/g, "");
      const final_number = "+1" + modified;
      setValue(final_number);
    }
  }, [phoneBeta]);

  const handlePhoneInput = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneBeta(formattedPhoneNumber);
    if (onChangeFunction) {
      onChangeFunction();
    }
  };

  return (
    <TextField
      value={phoneBeta}
      onChange={handlePhoneInput}
      label={label}
      variant="filled"
      fullWidth
      sx={{
        "& .MuiFilledInput-root": {
          backgroundColor: "#f9fafb", // gray-50 gray-100 #f3f4f6
          color: "#111827",
          "&:hover": {
            backgroundColor: "#f9fafb", // gray-100 -- like gray-50 better
          },
          "&.Mui-focused": {
            backgroundColor: "#f9fafb", // Keep the background color gray-50 when focused
          },
          "&:before": {
            borderBottomColor: "#6b7280", // gray-500
          },
          "&:after": {
            borderBottomColor: "#fef08a", // yellow-200
          },
          "&.Mui-focused:after": {
            borderBottomColor: "#fef08a", // yellow-200
          },
        },
        "& .MuiInputLabel-root": {
          color: "#6b7280", // gray-500
          "&.Mui-focused": {
            color: "#6b7280", // yellow-200
          },
        },
      }}
      {...props}
    />
  );
};

export default PhoneInputMUI;

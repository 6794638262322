import React, { useState, useContext } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoCloseSharp } from "react-icons/io5";
import EditDate from "./EditDate";
import Modal from "@mui/material/Modal";
import { updateDoc, doc, writeBatch, getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../../utils/firebase";
import { UserContext } from "../../../index";

export default function SelectDateModal({ modal, setModal, jobDetails, setJobDetails }) {
  const { userData } = useContext(UserContext);
  const [editRecurringModal, setEditRecurringModal] = useState(false);

  return (
    <>
      <Modal open={modal} onClose={() => setModal(false)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "600px",
            backgroundColor: "#fff", // Assuming "background.paper" refers to a white or light color
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Adjust as needed
            paddingTop: 16, // 4 * 4 = 16 assuming a spacing system where 4 is the base unit
            paddingLeft: 16, // 4 * 4 = 16
            paddingRight: 16, // 4 * 4 = 16
            paddingBottom: 12, // 4 * 3 = 12
            outline: "none",
          }}
        >
          <EditDate jobDetails={jobDetails} setJobDetails={setJobDetails} setModal={setModal} />
        </div>
      </Modal>
    </>
  );
}

import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function FreeformTags({ tags, setTags, setDirty }) {
  const handleTagsChange = (event, values) => {
    setTags(values);
    setDirty(true);
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      value={tags}
      onChange={handleTagsChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Tags"
          placeholder="Add Tags"
          InputLabelProps={{
            sx: {
              color: "#6b7280",
              "&.Mui-focused": {
                color: "#1f2937",
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: null,
          }}
        />
      )}
      sx={{
        "& .MuiOutlinedInput-root": {
          backgroundColor: "#f9fafb",
          color: "#111827",
          "&:hover": {
            backgroundColor: "#f9fafb",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fef08a",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fef08a",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fef08a",
          },
        },
        "& .MuiAutocomplete-tag": {
          backgroundColor: "#fef08a",
          color: "#111827",
          "&:hover": {
            backgroundColor: "#fde68a",
          },
        },
      }}
    />
  );
}

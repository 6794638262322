import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoCreateOutline } from "react-icons/io5";
import SearchConversations from "components/other/SearchConversations";
import NewMessageModal from "./components/NewMessageModal";
import { UserContext } from "../../index";
import { db } from "../../utils/firebase";
import {
  collection,
  query,
  orderBy,
  limit,
  addDoc,
  doc,
  setDoc,
  getDoc,
  getDocs,
  startAfter,
  where,
  onSnapshot,
  getCountFromServer,
} from "firebase/firestore";
import {
  useCollectionData,
  useCollection,
} from "react-firebase-hooks/firestore";
import FilterMenu from "./components/FilterMenu";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";

import { formatMessageDate } from "./utils";

export default function Conversations() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData, bannerVisible } = useContext(UserContext);

  const [searchInput, setSearchInput] = useState("");
  const [conversations, setConversations] = useState([]);
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [newMessageModal, setNewMessageModal] = useState(false);

  // New state
  const [queryLimit, setQueryLimit] = useState(30);
  const [filterType, setFilterType] = useState("all");
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalConversationCount, setTotalConversationCount] = useState(0);
  const [totalDatabaseConversationsCount, setTotalDatabaseConversationsCount] =
    useState(0);
  const messagesContainerRef = useRef(null);

  const scrollPositionRef = useRef(0);

  // console.log("loading", loading);
  // console.log("loadingMore", loadingMore);

  // users should only not be able to view messages if their custom permissions were set to false for viewMessages
  const notAbleToView =
    userData.userData?.customPermissions?.view_messages === false;

  const conversationsRef = useMemo(
    () =>
      collection(
        db,
        "businesses",
        userData.userData.businessId,
        "conversations"
      ),
    [userData.userData.businessId]
  );

  // New Memoized query function that will be used to create/change the query based on applied filter
  const getQuery = useCallback(() => {
    let baseQuery = query(
      conversationsRef,
      orderBy("lastMessageTime", "desc"),
      limit(queryLimit)
    );

    if (filterType === "unread") {
      baseQuery = query(
        conversationsRef,
        where("read", "==", false),
        orderBy("lastMessageTime", "desc"),
        limit(queryLimit)
      );
    }

    return baseQuery;
  }, [conversationsRef, queryLimit, filterType]);

  const memoizedQuery = useMemo(() => getQuery(), [getQuery]);

  console.log("conversations", conversations?.length);
  console.log("filteredConversations", filteredConversations?.length);

  // on mount we get the total conversation docs from db
  useEffect(() => {
    const fetchTotalDatabaseConversationsCount = async () => {
      const countQuery = query(conversationsRef);
      const snapshot = await getCountFromServer(countQuery);
      setTotalDatabaseConversationsCount(snapshot.data().count);
    };
    fetchTotalDatabaseConversationsCount();
  }, []);

  // we run this whenever the memoized dQuery changes
  useEffect(() => {
    if (notAbleToView) return;

    console.log("filterType", filterType);

    const fetchTotalCount = async () => {
      let countQuery;
      if (filterType === "unread") {
        countQuery = query(conversationsRef, where("read", "==", false));
      } else {
        countQuery = query(conversationsRef);
      }
      const snapshot = await getCountFromServer(countQuery);
      // console.log("total count", snapshot.data().count);
      setTotalConversationCount(snapshot.data().count);
    };

    fetchTotalCount();

    // Only set loading to true if we're not loading more
    if (!loadingMore) {
      setLoading(true);
    }
    // setLoading(true);
    // console.log("useEffect getQuery");
    // setConversations([]); // Clear conversations when filter changes

    const unsubscribe = onSnapshot(memoizedQuery, (snapshot) => {
      const newConversations = snapshot.docs.map((doc) => doc.data());
      console.log("conversations length", newConversations?.length);
      setConversations(newConversations); // filtered conversations will automatically be set
      // console.log("setting loading to false, and more loading to false");
      setLoading(false);
      setLoadingMore(false);
      // Only restore scroll position if we're loading more, not on filter change
      if (
        messagesContainerRef.current &&
        scrollPositionRef.current &&
        loadingMore
      ) {
        setTimeout(() => {
          messagesContainerRef.current.scrollTop = scrollPositionRef.current;
        }, 0);
      }
    });

    return () => unsubscribe();
  }, [notAbleToView, memoizedQuery]);

  const toggleNewMessageModalOn = async () => {
    setNewMessageModal(true);
  };

  const toggleNewMessageModalOff = () => {
    setNewMessageModal(false);
  };

  const handleScroll = useCallback(
    (e) => {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      if (
        scrollHeight - scrollTop <= clientHeight + 5 &&
        !loadingMore &&
        conversations?.length &&
        conversations.length < totalConversationCount
      ) {
        scrollPositionRef.current = scrollTop;
        setLoadingMore(true);
        setQueryLimit((prevLimit) => prevLimit + 30);
        // Disable scrolling

        // e.target.style.overflowY = "hidden";
      }
    },
    [loadingMore, conversations?.length, totalConversationCount]
  );

  // Add an effect to re-enable scrolling when loading is complete
  // useEffect(() => {
  //   // console.log("loading more changed", loadingMore);
  //   const container = document.querySelector(".messages-container");
  //   if (!loadingMore) {
  //     if (container) {
  //       container.style.overflowY = "auto";
  //     }
  //   } else {
  //     // Disable scrolling
  //     container.style.overflowY = "hidden";
  //   }
  // }, [loadingMore]);

  const handleFilterChange = (newFilter) => {
    setFilterType(newFilter);
    setQueryLimit(30); // Reset the query limit when changing filters
    setLoading(true);
    setConversations([]); // Clear conversations when filter changes
    // Reset the scroll position to the top
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = 0;
    }

    // Reset the scroll position ref
    scrollPositionRef.current = 0;
  };

  const NormalConversationsView = ({ conversation, index }) => {
    const content = conversation;
    //     const generateContent = (index) => {
    //       const names = [
    //         "John Doe",
    //         "Jane Smith",
    //         "Bob Johnson",
    //         "Alice Brown",
    //         "Charlie Davis",
    //       ];
    //       const phones = [
    //         "+11234567890",
    //         "+19876543210",
    //         "+15551234567",
    //         "+14159876543",
    //         "+17778889999",
    //       ];
    //       const messages = [
    //         "",
    //         "This is a slightly longer message to test wrapping",
    //         "This is an even longer message that will definitely need to be truncated because it exceeds the available space in the component",
    //         `Hi Test Derick,

    // Squeegee Squad is sending you a service subscription. Accept the plan by clicking the link below. Please contact us if you have questions.

    // Thank you.

    // http://localhost:3000/service-subscription?uniqueToken=xsUrbqrcTQSyFjJOtpEZyC0BUUK2&access=jvNt5bXqsosNhMEX6XaI`,
    //         "Another message with some more content to test different lengths",
    //       ];

    //       return {
    //         displayName: names[index % names.length],
    //         phone: phones[index % phones.length],
    //         lastMessage: messages[index % messages.length],
    //         read: index % 2 === 0,
    //         lastMessageTime: new Date(Date.now() - index * 86400000), // Subtract days
    //       };
    //     };

    //     const content = index !== undefined ? generateContent(index) : conversation;

    // // Function to truncate the message
    // const truncateMessage = (message, maxLength = 0) => {
    //   if (message.length <= maxLength) return message;
    //   return message.substr(0, maxLength);
    // };

    return (
      <div className="flex flex-row min-h-20 border-b border-gray-200 hover:bg-gray-100 cursor-pointer relative">
        <div
          className={`flex items-center mx-3 text-2xl text-gray-700 ${
            content.read === true && "opacity-0"
          }`}
        >
          •
        </div>

        <div
          className="flex flex-row flex-1 px-4 items-center"
          onClick={() =>
            navigate(`/messages/${content.id}`, {
              state: {
                phone: content.phone,
                name: content.displayName,
              },
            })
          }
        >
          <div className="flex flex-col w-1/3 mr-10%">
            <h2 className="text-base font-semibold text-gray-900 truncate">
              {content.displayName}
            </h2>
            <p className="text-sm text-gray-700 truncate">
              {`(${content.phone.substring(2, 5)}) ${content.phone.substring(
                5,
                8
              )}-${content.phone.substring(8, 12)}`}
            </p>
          </div>

          <div className="flex flex-col justify-start w-1/2">
            <p
              className="text-xs text-gray-600 overflow-hidden"
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                wordBreak: "break-word",
              }}
            >
              {content.lastMessage}
            </p>
          </div>

          <div className="absolute top-0 right-2 text-2xs text-gray-600">
            <span className="text-2xs text-gray-600">
              {formatMessageDate(
                content.lastMessageTime.toDate
                  ? content.lastMessageTime.toDate()
                  : content.lastMessageTime
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const NoConversationsView = () => {
    return (
      <div className="flex flex-col w-full justify-center py-4 px-4">
        {/* Skeleton UI */}
        <div className="animate-pulse">
          <div className="bg-gray-200 rounded h-16 my-2"></div>
          <div className="bg-gray-200 rounded h-16 my-2"></div>
          <div className="bg-gray-200 rounded h-16 my-2"></div>
          <div className="bg-gray-200 rounded h-16 my-2"></div>
          <div className="bg-gray-200/60 rounded h-16 my-2"></div>
          <div className="bg-gray-200/30 rounded h-16 my-2"></div>
          <div className="bg-gray-200/20 rounded h-16 my-2"></div>
        </div>
        {/* Overlay Message */}
        <div className="absolute top-1/4 right-1/4 left-1/4 flex items-center justify-center">
          <div className="bg-white p-4 rounded-md text-center shadow-lg">
            <h1 className="text-xl font-semibold text-gray-900 ">
              Start by creating a{" "}
              <span
                className="font-bold underline-effect hover:cursor-pointer"
                onClick={() => navigate("/customers")}
              >
                customer
              </span>{" "}
              or
              <span
                className="font-bold underline-effect hover:cursor-pointer"
                onClick={() => navigate("/addjob")}
              >
                {" "}
                job!
              </span>
            </h1>
            <p className="text-gray-700">
              Send notifications, protected review requests, or mass marketing
              messages to your customers. And they can text back too! Messages
              will be sent from a custom local number exclusively for your
              business.
            </p>
          </div>
        </div>
      </div>
    );
  };

  const NotAbleToViewView = () => {
    return (
      <div className="flex flex-col w-full justify-center py-4 px-4">
        {/* Skeleton UI */}
        <div className="animate-pulse">
          <div className="bg-gray-200 rounded h-16 my-2"></div>
          <div className="bg-gray-200 rounded h-16 my-2"></div>
          <div className="bg-gray-200 rounded h-16 my-2"></div>
          <div className="bg-gray-200 rounded h-16 my-2"></div>
          <div className="bg-gray-200/60 rounded h-16 my-2"></div>
          <div className="bg-gray-200/30 rounded h-16 my-2"></div>
          <div className="bg-gray-200/20 rounded h-16 my-2"></div>
        </div>
        {/* Overlay Message */}
        <div className="absolute top-1/4 right-1/4 left-1/4 flex items-center justify-center">
          <div className="bg-white p-4 rounded-md text-center shadow-lg">
            <h1 className="text-xl font-semibold text-gray-900 ">
              It looks like you don't have permission to view messages.
            </h1>
            <p className="text-gray-700">
              If this is a mistake, please contact your administrator.
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`bg-gray-50 relative ${
          bannerVisible ? "h-full-messages-minus-banner" : "h-full-messages"
        } overflow-y-auto text-gray-800`}
      >
        <div
          className="flex flex-col justify-start items-center w-full "
          style={{
            height: "calc(100% - 1rem)",
          }}
        >
          <div className="flex flex-row justify-between items-center w-4/5 max-w-3xl mt-4">
            <SearchConversations
              label="Search"
              inputValue={searchInput}
              setInputValue={setSearchInput}
              conversations={conversations}
              setFilteredConversations={setFilteredConversations}
            />

            <div className="flex flex-row items-center justify-end gap-2">
              <Tooltip title="New Conversation" arrow>
                <div>
                  <IoCreateOutline
                    className="text-2xl text-gray-600 hover:text-yellow-400 cursor-pointer"
                    onClick={toggleNewMessageModalOn}
                  />
                </div>
              </Tooltip>
              {/* Use either FilterMenu or FilterSelect here */}
              <FilterMenu onFilterChange={handleFilterChange} />
              {/* or */}
              {/* <FilterSelect onFilterChange={handleFilterChange} /> */}
            </div>
          </div>
          <div
            className="flex flex-col bg-white rounded-tl-md rounded-bl-md mt-2 shadow-md h-full mb-5 w-4/5 max-w-3xl overflow-y-auto overflow-x-hidden relative messages-container"
            ref={messagesContainerRef}
            onScroll={(e) => handleScroll(e)}
          >
            {notAbleToView ? (
              <NotAbleToViewView />
            ) : loading ? (
              <div className="flex flex-col w-full justify-center py-4 px-4">
                {/* Skeleton UI */}
                <div className="animate-pulse">
                  <div className="bg-gray-200 rounded h-16 my-2"></div>
                  <div className="bg-gray-200 rounded h-16 my-2"></div>
                  <div className="bg-gray-200 rounded h-16 my-2"></div>
                  <div className="bg-gray-200 rounded h-16 my-2"></div>
                  <div className="bg-gray-200/60 rounded h-16 my-2"></div>
                  <div className="bg-gray-200/30 rounded h-16 my-2"></div>
                  <div className="bg-gray-200/20 rounded h-16 my-2"></div>
                </div>
              </div>
            ) : (
              <>
                {filteredConversations && filteredConversations.length > 0 ? (
                  filteredConversations.map((conversation, index) => (
                    <NormalConversationsView
                      index={index}
                      conversation={conversation}
                      key={conversation.phone}
                    />
                  ))
                ) : conversations.length === 0 &&
                  totalDatabaseConversationsCount === 0 ? (
                  <NoConversationsView />
                ) : (
                  <div className="flex flex-col w-full justify-center  h-full">
                    <div className="bg-white p-4 rounded-md text-center">
                      <h1 className="text-lg font-semibold text-gray-900 ">
                        No conversations found
                      </h1>
                      <p className="text-gray-700 text-sm">
                        Try searching for a different conversation.
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <LoadingOverlay
        isLoading={loadingMore}
        containerRef={messagesContainerRef}
      />
      {newMessageModal && (
        <NewMessageModal
          toggleModalOn={toggleNewMessageModalOn}
          toggleModalOff={toggleNewMessageModalOff}
          conversations={conversations}
        />
      )}
    </>
  );
}

const LoadingOverlay = ({ isLoading, containerRef }) => {
  const [overlayStyle, setOverlayStyle] = useState({});
  // const containerRef = useRef(null);

  useEffect(() => {
    const updateOverlayStyle = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setOverlayStyle({
          position: "fixed",
          top: `${rect.top}px`,
          left: `${rect.left}px`,
          width: `${rect.width}px`,
          height: `${rect.height}px`,
        });
      }
    };

    updateOverlayStyle();
    window.addEventListener("resize", updateOverlayStyle);
    return () => window.removeEventListener("resize", updateOverlayStyle);
  }, []);

  if (!isLoading) return null;

  return (
    <div
      style={overlayStyle}
      className="bg-gray-200 bg-opacity-50 flex items-center justify-center z-50"
    >
      <CircularProgress size={50} style={{ color: "#fde047" }} />
    </div>
  );
};

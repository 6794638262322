import React, { useState, useEffect } from "react";
import CardLayout from "layouts/CardLayout";
import { ChevronDown } from "lucide-react";
import EditVisitModal from "../NewServiceSubscription/EditVisitModal";
import { LuCalendarPlus } from "react-icons/lu";
import Tooltip from "@mui/material/Tooltip";
import BasicSelect from "components/reusable/BasicDropdown";
import { useNavigate } from "react-router-dom";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const statusOptions = [
  { label: "All statuses", value: "all" },
  { label: "Scheduled", value: "scheduled" },
  { label: "Unscheduled", value: "unscheduled" },
  { label: "Completed", value: "completed" },
];

const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const yearOptions = [{ label: "All time", value: "all" }];
  for (let year = currentYear; year < currentYear + 6; year++) {
    yearOptions.push({ label: year.toString(), value: year });
  }
  return yearOptions;
};

const yearOptions = generateYearOptions();

export default function Visits({ subscription, setSubscription }) {
  const navigate = useNavigate();
  const [editVisitModal, setEditVisitModal] = useState(false);
  const [editVisit, setEditVisit] = useState(null);
  const [filterByStatus, setFilterByStatus] = useState("all");
  const currentYear = new Date().getFullYear();
  const [filterByYear, setFilterByYear] = useState(currentYear);

  // console.log("filterByStatus", filterByStatus);
  // console.log("filter by year", filterByYear);

  const [visibleVisits, setVisibleVisits] = useState(
    subscription?.visits || []
  );

  const handleEditVisit = (visit) => {
    setEditVisit(visit);
    setEditVisitModal(true);
  };

  const filterVisits = () => {
    // Access visits from the subscription object safely
    const allVisits = subscription?.visits || [];

    // Filter visits based on selected year and status
    const filteredVisits = allVisits.filter((visit) => {
      const filterByYearCondition =
        filterByYear === "all" || visit.year === filterByYear;
      const filterByStatusCondition =
        filterByStatus === "all" || visit.status === filterByStatus;

      return filterByYearCondition && filterByStatusCondition;
    });

    setVisibleVisits(filteredVisits);
  };

  useEffect(() => {
    filterVisits();
  }, [filterByYear, filterByStatus, subscription.visits]);

  const scheduleVisit = (visit) => {
    // so we are going to go to addJob page.. passing the customer and the subscriptionId and the visitId.. that way we can have it already set up..
    navigate(`/addjob`, {
      state: {
        customer: subscription.customer,
        subscription: {
          selectedSubscription: subscription,
          selectedVisit: visit,
          subscriptions: [subscription],
        },
      },
    });
  };

  return (
    <div
      className="flex flex-col flex-1 rounded-md shadow-bold bg-white py-6 overflow-x-auto relative flex-shrink-0"
      // style={{ flex: "1 0 auto" }}
    >
      <div className="flex flex-row items-start justify-between">
        <h2 className="text-xl text-gray-800 mb-4 font-bold px-4">Visits</h2>

        <div className="gap-4 flex flex-row">
          <BasicSelect
            fontSize="0.875rem"
            value={filterByStatus}
            setValue={(value) => setFilterByStatus(value)}
            options={statusOptions}
            borderNone={true}
          />
          <BasicSelect
            fontSize="0.875rem"
            value={filterByYear}
            setValue={(value) => setFilterByYear(value)}
            options={yearOptions}
            borderNone={true}
          />
        </div>
      </div>

      <div className="w-full ">
        <table className="w-full text-sm px-2">
          <thead className="border-b border-gray-200">
            <tr>
              <th className="px-6 py-3 text-left font-medium text-gray-500">
                Visit Name
              </th>
              <th className="px-6 py-3 text-left font-medium text-gray-500 ">
                Date
              </th>
              <th className="px-6 py-3 text-left font-medium text-gray-500 ">
                Status
              </th>
              <th className="px-6 py-3 text-left font-medium text-gray-500 ">
                Job
              </th>

              <th className="px-3 py-3 text-left font-medium text-gray-500 "></th>
            </tr>
          </thead>
          <tbody className=" divide-y divide-gray-200 ">
            {visibleVisits?.length > 0 ? (
              visibleVisits?.map((visit) => (
                <tr
                  key={visit.id}
                  className={`transition-opacity duration-1000 ease-in-out `}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {visit.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {monthNames[visit.month]} {visit.year}
                  </td>
                  <td className="pl-6 pr-2 py-4 whitespace-nowrap text-sm text-gray-500">
                    {visit.status.slice(0, 1).toUpperCase() +
                      visit.status.slice(1)}
                  </td>
                  <td className="pl-6 pr-2 py-4 whitespace-nowrap text-sm text-gray-500">
                    {visit.jobId ? (
                      <Tooltip title="Go to job details page">
                        <button
                          className="text-yellow-400 font-medium"
                          onClick={() => {
                            console.log(visit.jobId);
                            navigate(`/jobdetails/${visit.jobId}`, {
                              state: { jobId: visit.jobId },
                            });
                          }}
                        >
                          {visit.jobId.slice(0, 5)}{" "}
                        </button>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 ">
                    <div className="flex flex-row items-center gap-3">
                      <Tooltip title="Schedule Visit">
                        <button
                          className=" hover:bg-gray-50 rounded-md p-2"
                          onClick={() => scheduleVisit(visit)}
                        >
                          <LuCalendarPlus className="text-gray-800" />
                        </button>
                      </Tooltip>
                      <Tooltip title="Edit Visit">
                        <button
                          className="text-yellow-400 hover:text-yellow-500 p-2 rounded-md hover:bg-gray-50"
                          onClick={() => handleEditVisit(visit)}
                        >
                          Edit
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  No visits to display
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <EditVisitModal
        open={editVisitModal}
        onClose={() => {
          setEditVisitModal(false);
          setEditVisit(null);
        }}
        visit={editVisit}
        setVisit={setEditVisit}
        subscriptionDetails={subscription}
        setSubscriptionDetails={setSubscription}
      />
    </div>
  );
}

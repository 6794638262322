import React, { useContext, useEffect, useState, useRef } from "react";
import { useUserContext } from "context/UserContext";
import { db, auth } from "../../utils/firebase";
import {
  getDoc,
  doc,
  updateDoc,
  serverTimestamp,
  arrayUnion,
} from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import { BsFillArrowRightSquareFill } from "react-icons/bs";

// this is the polling function that will be used later
const pollSubscriptionStatus = async (userId, attempts = 0) => {
  if (attempts > 20) {
    throw new Error("Subscription status not updated in time.");
  }
  // console.log("attempt", attempts);

  if (attempts === 0) {
    // console.log("attempt is 0 so we will do set timeout to start");
    await new Promise((resolve) => setTimeout(resolve, 3000));
  }

  const docRef = doc(db, "businesses", userId, "stripe", "subscription");
  const docSnap = await getDoc(docRef);

  if (
    docSnap.exists() &&
    (docSnap.data().status === "trialing" || docSnap.data().status === "active")
  ) {
    console.log("document exists and status is 'trialing' or 'active'");
    return docSnap.data();
  }

  // Wait for a bit before checking again
  await new Promise((resolve) => setTimeout(resolve, 500));

  return pollSubscriptionStatus(userId, attempts + 1);
};

export default function NewTrialSuccess() {
  const { user, userData, setUserData } = useUserContext();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const hasFetchedStripeData = useRef(false);
  const intent = new URLSearchParams(location.search).get("intent");

  useEffect(() => {
    if (!userData || hasFetchedStripeData.current) return;

    const getUpdatedStripeData = async () => {
      try {
        setLoading(true);
        const subscriptionData = await pollSubscriptionStatus(
          userData.userData.businessId
        );

        if (
          subscriptionData.status === "trialing" &&
          !subscriptionData?.cancel_at_period_end
        ) {
          console.log(
            "Subscription status fetched and is trialing, navigating to dashboard"
          );
          // If the subscription is on trial, set the user's setup to true
          // This will trigger the setup modal to show when they navigate to the dashboard
          // Note we only do this if the subscription is trialing and hasn't been canceled
          setUserData({ ...userData, subData: { ...subscriptionData } });
          setLoading(false);

          // wait for a few seconds before navigating to the dashboard
          await new Promise((resolve) => setTimeout(resolve, 3000));

          // navigate to game if that was their intent
          if (intent === "games") {
            navigate("/games");
          } else {
            navigate("/", {
              state: {
                setUp: true,
                // hbGames: intent === "games" ? true : false, // then we do a modal to tell them they have been registered
              },
            });
          }
        }
      } catch (error) {
        // This is where we end up if we don't fetch it successfully after 20 tries
        // Probably should do an alert or something to notify them that something went wrong
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    getUpdatedStripeData();
    // Mark that the data has been fetched
    hasFetchedStripeData.current = true;
  }, [userData]);

  const navigateToDashboard = () => {
    if (intent === "games") {
      navigate("/games");
    } else {
      navigate("/", {
        state: {
          setUp: true,
        },
      });
    }
  };

  return (
    <>
      <div className="flex h-full min-h-screen w-full flex-col items-center overflow-auto  bg-slate-100/90 font-stripe scrollbar-hide">
        <h1
          className="mt-12 flex justify-center pl-2  text-2xl font-semibold text-slate-600 "
          style={
            {
              // width: "920px",
            }
          }
        >
          <img
            src="/images/Homebase-logo-black.png"
            // className="h-14 w-auto"
            width={200}
            alt="Homebase logo"
          />
        </h1>
        <div className="relative mt-10 mb-36 flex flex-col rounded-lg bg-slate-50 shadow-2xl w-11/12 max-w-4xl items-center sm-px-10 px-5 ">
          <h1 className="mt-10 flex justify-center pl-2 text-center text-2xl font-semibold text-gray-600 max-w-xl ">
            You've successfully started your trial 🚀
          </h1>

          <h1 className="mt-4 mb-6 flex justify-center pl-2 text-center text-lg font-semibold text-gray-600 max-w-xl h-16 ">
            {loading
              ? "Please wait a few seconds while we initialize your account before proceeding to the dashboard."
              : "Your account is ready and you can now proceed to the dashboard."}
          </h1>
          <button
            disabled={loading}
            className={`mt-4 mb-10 bg-gray-800 text-yellow-50 font-medium w-56 h-12 rounded-md ${
              !loading && "hover:text-yellow-100"
            } flex flex-row items-center justify-center`}
            onClick={navigateToDashboard}
          >
            {loading ? (
              <span className="">Wait to proceed ✋</span>
            ) : (
              <>
                <span className="mr-2">Proceed to dashboard</span>
                <BsFillArrowRightSquareFill className="text-green-400 text-sm" />
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
}

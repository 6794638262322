import React, { useState, useContext, useEffect } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { MdDateRange } from "react-icons/md";
import { UserContext } from "../../..";
import SelectDate from "../../AddJob/components/SelectDate";
import SelectDateModal from "./SelectDateModal";
import { format, utcToZonedTime } from "date-fns-tz";
import Tooltip from "@mui/material/Tooltip";

export default function Scheduled({ jobStart, jobEnd, toggleDateModal, jobDetails }) {
  const { userData } = useContext(UserContext);

  const timeZone = userData?.bizData?.timeZone;
  const startDate = jobStart ? utcToZonedTime(new Date(jobStart), timeZone) : null;
  const endDate = jobEnd ? utcToZonedTime(new Date(jobEnd), timeZone) : null;
  const pattern = "EEEE M/d/yyyy, h:mmaaa";

  const jobStartFormatted = startDate && format(startDate, pattern, { timeZone });
  const jobEndFormatted = endDate && format(endDate, pattern, { timeZone });

  // logic for recreating the recurrence label:
  let label = "Does not repeat";

  // lol to generate the label we don't need to do anything lol we can just pull the label...

  if (jobDetails?.recurrenceLabel) {
    label = jobDetails.recurrenceLabel;
  }

  return (
    <>
      <div className="flex flex-col flex-1 rounded-lg shadow-bold bg-white py-6 px-4 mt-8 ">
        <div className="flex flex-row items-center justify-between mb-2">
          <div className="flex flex-row">
            <MdDateRange className="text-2xl mr-2" />
            <h1 className="text-xl font-bold text-gray-900 ">Scheduled</h1>
          </div>
          <div className="flex flex-row justify-end items-center">
            <Tooltip title={label} placement="top">
              <div className="text-md font-medium text-gray-900 bg-gray-100 cursor-default shadow-md p-2 mx-2 max-w-40 sm:max-w-64 md:max-w-36 xl:max-w-64 overflow-hidden whitespace-nowrap truncate">
                {label}
              </div>
            </Tooltip>
            <AiOutlineEdit onClick={toggleDateModal} className="text-lg cursor-pointer ml-2" />
          </div>
        </div>
        <div className="flex flex-row items-center pt-2">
          <h1 className="text-lg font-bold text-gray-900 w-14  ">Start</h1>
          <h1>{jobStartFormatted}</h1>
        </div>
        <div className="flex flex-row items-center pt-4">
          <h1 className="text-lg font-bold text-gray-900 w-14  ">End</h1>
          <h1>{jobEndFormatted}</h1>
        </div>
      </div>
    </>
  );
}

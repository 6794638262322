import React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { IoClose } from "react-icons/io5";
import { CiSquareCheck } from "react-icons/ci";
import { useSnackBar } from "../../context/SnackBarContext";
import { BiError } from "react-icons/bi";

export default function GeneralSnackBar() {
  const { snackBarOpen, snackBarMessage, closeSnackBar, successIcon, isError } =
    useSnackBar();

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeSnackBar}
      >
        <IoClose />
      </IconButton>
    </React.Fragment>
  );

  const messageContent = (
    <span className="flex flex-row items-center justify-center">
      {successIcon && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5" // Changed to camelCase
          stroke="currentColor"
          className="w-5 h-5 mr-2 text-green-300" // Changed to camelCase
        >
          <path
            strokeLinecap="round" // Changed to camelCase
            strokeLinejoin="round" // Changed to camelCase
            d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
          />
        </svg>
      )}
      {isError && <BiError className="w-5 h-5 mr-2 text-red-300" />}
      {snackBarMessage}
    </span>
  );

  return (
    <>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={closeSnackBar}
        message={messageContent}
        action={action}
      />
    </>
  );
}

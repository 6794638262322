//servicesubscription/:subscriptionId
import React, { useEffect, useState } from "react";
import PageLayout from "layouts/PageLayout";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useUserContext } from "context/UserContext";
// import { useFetchSub } from "features/ServiceSubscription/components/hooks/useFetchSub";
import { useSubscriptionListener } from "./components/hooks/useFetchSubListener";
import { useFetchHistory } from "features/ServiceSubscription/components/hooks/useFetchHistory";
import { useFetchInvoices } from "features/ServiceSubscription/components/hooks/useFetchInvoices";
import { useUpdateCustomer } from "features/ServiceSubscription/components/hooks/useUpdateCustomer";
import { useSnackBar } from "context/SnackBarContext";
import Header from "features/ServiceSubscription/components/SubDetails/Header";
import CustomerJob from "features/JobDetails/components/CustomerJob";
import SubscriptionDescription from "features/ServiceSubscription/components/SubDetails/SubscriptionDescription";
import Visits from "features/ServiceSubscription/components/SubDetails/Visits";
import InvoiceHistory from "features/ServiceSubscription/components/SubDetails/InvoiceHistory";
import ActivityHistory from "features/ServiceSubscription/components/SubDetails/ActivityHistory";
import SubLoadingSkeleton from "features/ServiceSubscription/components/SubDetails/SubLoadingSkeleton";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";

export default function SubscriptionDetails() {
  const { userData } = useUserContext();
  const businessId = userData?.bizData?.id;
  const navigate = useNavigate();
  const location = useLocation();
  const { subscriptionId } = useParams(); // get the subscriptionId from the url
  const {
    subscriptionId: subIdFromState,
    customerId: fromCustomerDetailsPage,
    openAcceptModal,
  } = location?.state || {}; // we used to be getting it from state but now we are getting it from the url
  // const { subscription, setSubscription } = useFetchSub({
  //   businessId,
  //   subscriptionId,
  // });

  const { subscription, setSubscription, loading } = useSubscriptionListener({
    businessId,
    subscriptionId,
  });
  useUpdateCustomer({ subscription, setSubscription }); // syncs customer data in subscription with the customer data in customer document

  // These are both real time listeners now
  const { history, loadingHistory } = useFetchHistory({
    businessId,
    subscriptionId,
  });

  const { invoices, loadingInvoices } = useFetchInvoices({
    businessId,
    subscriptionId,
  });

  if (!subscription) {
    return (
      <PageLayout>
        <CenteredMaxWidthLayout>
          <SubLoadingSkeleton />
        </CenteredMaxWidthLayout>
      </PageLayout>
    );
  }
  return (
    <PageLayout>
      <CenteredMaxWidthLayout>
        <Header
          fromCustomerDetailsPage={fromCustomerDetailsPage}
          subscription={subscription}
          setSubscription={setSubscription}
          openAcceptModal={openAcceptModal}
        />

        <div className="flex flex-col   md:grid md:grid-cols-6 mx-5 mb-5 gap-8 pb-8">
          <div className=" md:col-start-1 md:col-end-3 flex-none">
            <CustomerJob
              toggleModal={() => {}}
              customer={subscription?.customer}
              toggleNotifications={null}
              notifications={false}
              navigate={navigate}
              textSize="text-base"
              textColor="text-gray-500"
              noEdit={true}
              subscriptionId={subscriptionId}
            />
          </div>
          <div className=" md:col-start-3 md:col-end-7 ">
            <div className="flex flex-col gap-8">
              <SubscriptionDescription
                subscription={subscription}
                setSubscription={setSubscription}
              />
              <Visits
                subscription={subscription}
                setSubscription={setSubscription}
              />
              <InvoiceHistory invoices={invoices} loading={loadingInvoices} />
              <ActivityHistory history={history} loading={loadingHistory} />
            </div>
          </div>
        </div>
      </CenteredMaxWidthLayout>
    </PageLayout>
  );
}

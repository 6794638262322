import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { NewCategoryModal } from "../../components/CategoryModal/NewCategoryModal";
import { EmptyCategoriesView } from "./components/EmptyCategoriesView";
import { Link } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { OutlineButton } from "../../components/NewButtons/OutlineButton";

export const PriceBook = () => {
  const { userData, bannerVisible } = useUserContext();
  const { priceBookCategories: categories } = userData.bizData;
  const [newCategoryModalOpen, setNewCategoryModalOpen] = useState(false);

  return (
    <>
      <div
        className={`bg-gray-100 ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        }  overflow-y-auto pb-36`}
      >
        <div className="flex items-center justify-center">
          <div className="flex flex-col w-11/12 max-w-3xl mt-6 mb-6">
            <div className="flex justify-between items-center mb-6">
              <div>
                <h1 className="text-3xl font-extrabold text-gray-900">Price Book</h1>
                {/* <p className="text-sm text-gray-500">
                  Customize the status, estimate, and invoice messages that are sent to customers.
                </p> */}
              </div>

              <OutlineButton
                text="New Category"
                onClick={() => setNewCategoryModalOpen(true)}
                icon={<FaPlus className="h-3 w-3" />}
              />
            </div>
            {categories && categories.length ? (
              <ul className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                {categories.map((category) => (
                  <li key={category.id}>
                    <Link
                      to={`/settings/customizations/price-book/${category.id}`}
                      state={{ name: category.name }}
                      className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
                    >
                      <div className="flex min-w-0 gap-x-4">
                        <div className="min-w-0 flex-auto">
                          <p className="text-lg font-semibold leading-7 text-gray-900">
                            {category.name}
                          </p>
                        </div>
                      </div>
                      <div className="flex shrink-0 items-center gap-x-4">
                        <ChevronRightIcon
                          aria-hidden="true"
                          className="h-5 w-5 flex-none text-gray-400"
                        />
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              <EmptyCategoriesView setNewCategoryModalOpen={setNewCategoryModalOpen} />
            )}
          </div>
        </div>
      </div>
      {newCategoryModalOpen && (
        <NewCategoryModal open={newCategoryModalOpen} setOpen={setNewCategoryModalOpen} redirect />
      )}
    </>
  );
};

import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../..";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdDateRange } from "react-icons/md";
import CustomDropdown from "./CustomDropdown";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { parse, addHours } from "date-fns";
import RecurringDropdown from "./RecurringDropdown";

export default function SelectDate({
  jobStart,
  jobEnd,
  setJobStart,
  setJobEnd,
  recurrenceData,
  setRecurrenceData,
}) {
  const { userData } = useContext(UserContext);

  const timeZone = userData?.bizData?.timeZone;
  //convert the jobStart and jobEnd to date objects
  const initialStartTime = jobStart;
  const initialEndTime = jobEnd;

  // format the time values
  const pattern = "h:mm a";
  const startTime = format(initialStartTime, pattern);
  const endTime = format(initialEndTime, pattern);

  // set the state variables
  const [selectedStartDate, setSelectedStartDate] = useState(initialStartTime);
  const [selectedEndDate, setSelectedEndDate] = useState(initialEndTime);
  const [selectedTimeStart, setSelectedTimeStart] = useState(startTime);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(endTime);

  const [datesWrong, setDatesWrong] = useState(false);

  const initialCombinedStartTime = new Date(
    selectedStartDate.getFullYear(),
    selectedStartDate.getMonth(),
    selectedStartDate.getDate(),
    initialStartTime.getHours(),
    initialStartTime.getMinutes()
  );
  const [combinedStartTime, setCombinedStartTime] = useState(initialCombinedStartTime);
  const initialCombinedEndTime = addHours(initialCombinedStartTime, 2);
  const [combinedEndTime, setCombinedEndTime] = useState(initialCombinedEndTime);

  // console.log("selectedStartDate: ", selectedStartDate);
  // console.log("selectedEndDate: ", selectedEndDate);
  useEffect(() => {
    // set the selectedDateEnd to the same date as selectedStartDate when the selectedStartDate changes
    setSelectedEndDate(selectedStartDate);
  }, [selectedStartDate]);

  useEffect(() => {
    // this is where we update the endTime when we change the startTime
    // parse the selectedTimeStart string to a date object
    const startDate = parse(selectedTimeStart, "h:mm a", new Date());

    // calculate the new end date by adding 2 hours to the start date
    const newEndDate = addHours(startDate, 2);

    // format the new end date as a time string using Date FNS
    const newEndTime = format(newEndDate, "h:mm a");

    // update the selectedTimeEnd state variable
    setSelectedTimeEnd(newEndTime);
  }, [selectedTimeStart]);

  useEffect(() => {
    // set the new combined start time and end time every time the selectedStartDate, selectedEndDate, selectedTimeStart, or selectedTimeEnd state variables change
    const newCombinedStartTime = new Date(
      selectedStartDate.getFullYear(),
      selectedStartDate.getMonth(),
      selectedStartDate.getDate(),
      parse(selectedTimeStart, "h:mm a", new Date()).getHours(),
      parse(selectedTimeStart, "h:mm a", new Date()).getMinutes()
    );
    const newCombinedEndTime = new Date(
      selectedEndDate.getFullYear(),
      selectedEndDate.getMonth(),
      selectedEndDate.getDate(),
      parse(selectedTimeEnd, "h:mm a", new Date()).getHours(),
      parse(selectedTimeEnd, "h:mm a", new Date()).getMinutes()
    );
    setCombinedStartTime(newCombinedStartTime);
    setCombinedEndTime(newCombinedEndTime);
  }, [selectedStartDate, selectedEndDate, selectedTimeStart, selectedTimeEnd]);

  useEffect(() => {
    // check if the combined start time is after the combined end time
    if (combinedStartTime > combinedEndTime) {
      setDatesWrong(true);
    } else {
      setDatesWrong(false);
    }
  }, [combinedStartTime, combinedEndTime]);

  useEffect(() => {
    // update the jobStart and jobEnd state variables in the AddJob component
    const utcStart = zonedTimeToUtc(combinedStartTime, timeZone);
    const utcEnd = zonedTimeToUtc(combinedEndTime, timeZone);
    setJobStart(utcStart);
    setJobEnd(utcEnd);
  }, [combinedStartTime, combinedEndTime]);

  return (
    <div className="flex flex-col flex-1 bg-white  rounded-md shadow-bold  gap-2 mt-8 p-4">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center gap-2 mt-2 mb-2">
          <MdDateRange className="inline-block text-2xl" />
          <h1 className="text-xl font-bold">Select Date</h1>
        </div>
        <RecurringDropdown
          startTime={jobStart}
          setRecurrenceData={setRecurrenceData}
          recurrenceData={recurrenceData}
        />
      </div>
      <div className="flex flex-row items-center">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center">
            <h1
              className="w-16"
              // style={{
              //   marginRight: "5px",
              //   width: "100px",
              // }}
            >
              Start
            </h1>
            <DatePicker
              placeholderText="Start Date"
              className={`date-picker ${
                datesWrong ? "bg-red-300" : "bg-white"
              } border border-gray-300 rounded-md pl-4 py-2  text-left focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent cursor-pointer `}
              selected={selectedStartDate}
              // onChange={(start) => setNewEvent({ ...newEvent, start })}
              onChange={(start) => setSelectedStartDate(start)}
            />
            <CustomDropdown
              initial={"9:00 AM"}
              selectedTime={selectedTimeStart}
              setSelectedTime={setSelectedTimeStart}
              timesWrong={datesWrong}
            />
          </div>
          <div className="flex flex-row items-center mb-4">
            <h1
              className="w-16"
              // style={{
              //   marginRight: "5px",
              //   width: "100px",
              // }}
            >
              End
            </h1>
            <DatePicker
              placeholderText="End Date"
              className={`date-picker ${
                datesWrong ? "bg-red-300" : "bg-white"
              } border border-gray-300 rounded-md pl-4 py-2  text-left focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent cursor-pointer `}
              selected={selectedEndDate}
              onChange={(end) => setSelectedEndDate(end)}
            />
            <CustomDropdown
              initial={"11:00 AM"}
              selectedTime={selectedTimeEnd}
              setSelectedTime={setSelectedTimeEnd}
              timesWrong={datesWrong}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

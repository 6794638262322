import React, { useState, useEffect } from "react";
// date
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

// select
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function SubscriptionStartDate({
  subscriptionDetails,
  setSubscriptionDetails,
  employees,
}) {
  const handleSetStartDate = (date) => {
    setSubscriptionDetails({
      ...subscriptionDetails,
      startDate: date,
    });
  };

  return (
    <div className=" mt-6 w-full">
      <h2 className="text-xl text-gray-800 mb-4 font-medium">Details</h2>
      <div className="w-3/4 max-w-xl  rounded-md p-6 border border-gray-100 shadow-lg mb-6">
        <div className="max-w-64">
          <CustomDatePicker
            label="Start Date"
            date={subscriptionDetails?.startDate || new Date()}
            setDate={handleSetStartDate}
            wrong={false}
          />
          <div className="mt-4">
            <CustomComboBox
              options={employees}
              value={subscriptionDetails.soldBy}
              label="Sold By"
              onValueChange={(event, value) => {
                setSubscriptionDetails((prev) => ({
                  ...prev,
                  soldBy: value,
                }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const gray500 = "#6b7280";
const yellow400 = "#facc15";
const yellow200 = "#fef08a";
const yellow100 = "#fef9c3";
const yellow50 = "#fefce8";
const gray50 = "#f9fafb";
const gray900 = "#111827";
const gray800 = "#1f2937";

function CustomDatePicker({ date, setDate, label, disabled, wrong }) {
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: gray50, // Background color as in Autocomplete
            color: gray900, // Text color
            fontFamily: '"Inter var", system-ui, sans-serif',

            // Default border color
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: wrong ? "red" : gray500,
            },

            // Hover state when not focused
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: wrong ? "red" : gray500,
            },

            // Change border color when focused
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: wrong ? "red" : yellow200,
              // Override hover on focus

              "&:hover": {
                borderColor: wrong ? "red" : yellow200, // Maintain yellow-200 on hover when focused
              },
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: '"Inter var", system-ui, sans-serif',
            color: gray500, // Default label color
            "&.Mui-focused": {
              color: gray500, // Label color when input is focused
            },
          },
        },
      },

      MuiPickersDay: {
        styleOverrides: {
          root: {
            "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
              backgroundColor: yellow400, // Your custom selected day color
              color: "white", // Text color for the selected day
            },
          },
        },
      },
      MuiPickersYear: {
        styleOverrides: {
          yearButton: {
            "&.Mui-selected": {
              "&:focus": {
                backgroundColor: yellow400, // Color when focused
              },
              // hover
              "&:hover": {
                backgroundColor: yellow400, // Color when hovering over years
              },
              backgroundColor: yellow400, // Custom color for the selected year
              color: "white",
            },
            "&:hover": {
              backgroundColor: yellow100, // Color when hovering over years
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disabled={disabled}
          slotProps={{ textField: { size: "small", fullWidth: true } }}
          label={label}
          value={date}
          onChange={(newDate) => {
            if (newDate) {
              setDate(newDate);
            }
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

function CustomComboBox({
  options,
  value,
  setValue,
  placeholder = "Sold By",
  label = "Sold By",
  onValueChange = null,
}) {
  // console.log("value from autocomplete dropdown", value);
  const handleChange = (event, values) => {
    // console.log("values from hanlde change", values);
    setValue(values);
  };

  return (
    <CustomAutocomplete
      size="small"
      options={options}
      value={value}
      onChange={onValueChange || handleChange}
      getOptionLabel={(option) => option.firstName + " " + option.lastName}
      isOptionEqualToValue={(option, value) =>
        option.firstName === value.firstName && option.lastName === value.lastName
      }
      renderOption={(props, option, { selected, active }) => (
        <li
          {...props}
          style={{
            ...props.style,
            backgroundColor: selected && !active ? yellow50 : undefined,
          }}
        >
          <div className="text-base">
            {option.firstName} {option.lastName}
          </div>
        </li>
      )}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          autoComplete="new-password"
          inputProps={{ ...params.inputProps, autoComplete: "new-password" }} // Disable autocomplete and autofill
        />
      )}
    />
  );
}

const CustomAutocomplete = styled(Autocomplete)({
  fontFamily: '"Inter var", system-ui, sans-serif',
  "& .MuiOutlinedInput-root": {
    backgroundColor: gray50,
    color: gray900,
    "&:hover": {
      backgroundColor: gray50,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: gray500,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: gray500,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: yellow200,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: '"Inter var", system-ui, sans-serif',
      "&.Mui-selected": {
        backgroundColor: yellow200,
      },
    },
  },
});

const CustomTextField = styled(TextField)({
  fontFamily: '"Inter var", system-ui, sans-serif',
  "& .MuiInputBase-input": {
    fontFamily: '"Inter var", system-ui, sans-serif',
  },
  "& .MuiInputLabel-root": {
    color: gray500,
    "&.Mui-focused": {
      color: gray800,
    },
  },
});

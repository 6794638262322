import { parse } from "date-fns";

const formats = [
  "MM-dd-yyyy",
  "MM/dd/yyyy",
  "MM dd yyyy", // U.S. formats
  "yyyy-MM-dd",
  "yyyy/MM/dd",
  "yyyy MM dd", // ISO formats
  "dd-MM-yyyy",
  "dd/MM/yyyy",
  "dd MM yyyy", // European formats
];

const parseDate = (dateString) => {
  const baseDate = new Date(); // Used as reference to fill in missing parts

  for (let format of formats) {
    try {
      const date = parse(dateString, format, baseDate);
      if (!isNaN(date)) {
        return date;
      }
    } catch (error) {
      // This block can be used to log errors or handle failed parsing attempts
      console.error(`Failed to parse '${dateString}' with format '${format}'`);
    }
  }
  return new Date(); // or throw an error if no valid date is found
};

export function formatImportDataRowToCustomer(dataRow) {
  const {
    firstName,
    lastName,
    email,
    phone,
    createdAt,
    serviceStreet1,
    serviceStreet2,
    serviceCity,
    serviceState,
    serviceZip,
    billingStreet1,
    billingStreet2,
    billingCity,
    billingState,
    billingZip,
    notes,
  } = dataRow;

  // const formats = [
  //   "MM-dd-yyyy",
  //   "MM/dd/yyyy",
  //   "MM dd yyyy",
  //   "yyyy-MM-dd",
  //   "yyyy/MM/dd",
  //   "yyyy MM dd",
  //   "dd-MM-yyyy",
  //   "dd/MM/yyyy",
  //   "dd MM yyyy",
  // ];

  return {
    firstName: firstName || "",
    lastName: lastName || "",
    displayName: `${firstName || ""} ${lastName || ""}`.trim(),
    address: [
      formatAddress(serviceStreet1, serviceStreet2, serviceCity, serviceState, serviceZip),
      formatAddress(billingStreet1, billingStreet2, billingCity, billingState, billingZip),
    ],
    phone: {
      mobile: extractFirstPhoneNumber(phone) || "",
      home: "",
      work: "",
      additional: "",
    },
    email: extractEmails(email),
    notes: notes || "",
    businessId: "",
    dateAdded: parseDate(createdAt),
    lastUpdated: new Date(),
    tags: [],
    notifications: true,
    attachments: [],
    cities: [],
    geocodedAddresses: [],
  };
}

function formatAddress(addressStreet1, addressStreet2, addressCity, addressState, addressZip) {
  let result = "";

  if (addressStreet1) {
    result += addressStreet1.trim();
  }
  if (addressStreet2) {
    result += (result ? ", " : "") + addressStreet2.trim();
  }
  if (addressCity) {
    result += (result ? ", " : "") + addressCity.trim();
  }
  if (addressState) {
    result += (result ? ", " : "") + addressState.trim();
  }
  if (addressZip) {
    result += (result ? " " : "") + addressZip.trim();
  }

  return result;
}

function formatPhoneNumber(phone) {
  if (!phone) {
    return "";
  }
  let sanitizedPhone = phone.trim();
  sanitizedPhone = sanitizedPhone.replace(/\D/g, "");
  if (sanitizedPhone.length === 11 && sanitizedPhone.startsWith("1")) {
    sanitizedPhone = sanitizedPhone.substring(1);
  }
  return sanitizedPhone.length === 10 ? `+1${sanitizedPhone}` : "";
}

// function extractEmails(emailString) {
//   if (!emailString) {
//     return [""];
//   }
//   return emailString
//     .split(",")
//     .map((email) => email.trim())
//     .filter((email) => email.includes("@"));
// }

// potential improvement to extract emails:
// MF: checked and tested -- works well
function extractEmails(emailString) {
  if (!emailString || emailString.trim() === "") {
    return ["", ""];
  }

  const emails = emailString
    .split(",")
    .map((email) => email.trim())
    .filter((email) => {
      const parts = email.split("@");
      return parts.length === 2 && parts[0].length > 0 && parts[1].includes(".");
    });

  if (emails.length === 0) {
    return ["", ""];
  } else if (emails.length === 1) {
    return [emails[0], ""];
  } else {
    return emails.slice(0, 2);
  }
}

function extractFirstPhoneNumber(phoneString) {
  if (!phoneString) {
    return "";
  }
  const phones = phoneString.split(",").map((phone) => formatPhoneNumber(phone.trim()));
  return phones.find((phone) => phone !== "") || "";
}

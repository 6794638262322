import { db } from "../../utils/firebase";
import {
  collection,
  query,
  doc,
  updateDoc,
  getDocs,
  getDoc,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { node } from "../../constants/constants";

export async function getEstimateDetails({ businessId, estimateId }) {
  try {
    const estimateRef = doc(db, "businesses", businessId, "estimates", estimateId);
    const estimate = await getDoc(estimateRef);
    if (estimate.exists()) {
      return {
        ...estimate.data(),
        dateAdded: estimate.data()?.dateAdded?.toDate() ?? null,
      };
    } else {
      console.log("no estimate found");
      return {};
    }
  } catch (err) {
    console.error("Error:", err);
  }
}

export const resendEstimate = async ({ estimateDetails, userData }) => {
  try {
    const response = await fetch(`${node}/estimate/estimate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        estimate: estimateDetails,
        businessName: userData.bizData.companyName,
        businessEmail: userData.bizData.email,
        businessPhone: userData.bizData.phone,
        businessAddress: userData.bizData.address,
      }),
    });

    const { message } = await response.json();
    return message;
  } catch (err) {
    console.log("Error", err);
    alert("There was an error sending the estimate, please try again");
  }
};

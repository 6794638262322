import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function BasicSelect({
  value,
  setValue,
  options,
  label,
  width,
  focusedBorderColor = "#fef08a",
  borderColor, // gray -500
  multiple = false,
  backgroundColor,
  height = "auto",
  focusedLabelColor = "#e5e7eb",
  labelColor,
  borderNone = false,
  clear = false,
  selectedTextColor = false,
  arrowColor = false,
  onChangeFunction = null,
  fontSize = "1rem",
}) {
  const [hasValue, setHasValue] = useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (value) {
      setHasValue(true);
    } else {
      setHasValue(false);
    }
  }, [value]);

  return (
    <Box sx={{ width: width }}>
      <FormControl fullWidth>
        <InputLabel
          className="text-gray-900"
          sx={{
            fontFamily: '"Inter var", system-ui, sans-serif',
            fontSize: fontSize, // Apply text size
            "&.Mui-focused": {
              color: focusedLabelColor,
            },

            ...(labelColor && { color: labelColor }),

            // Color when there is a value but not focused (Selected/Unfocused)
            ...(hasValue && {
              color: focusedLabelColor,
            }),
          }}
        >
          {label}
        </InputLabel>
        <Select
          sx={{
            fontFamily: '"Inter var", system-ui, sans-serif',
            fontSize: fontSize, // Apply text size
            ...(borderNone && {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }),
            ...(borderColor && {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: borderColor,
              },
            }),
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: focusedBorderColor,
            },
            ...(borderColor && {
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: borderColor,
              },
            }),
            // Style for the selected text
            ...(selectedTextColor && {
              ".MuiSelect-select": {
                color: selectedTextColor, // change this to your desired color
              },
            }),

            // Style for the dropdown arrow
            ...(arrowColor && {
              ".MuiSelect-icon": {
                color: arrowColor, // replace this with your desired color
              },
            }),
            ...(backgroundColor && { backgroundColor }),
            height: height,
          }}
          size="small"
          value={value}
          label={label}
          onChange={onChangeFunction || handleChange}
          multiple={multiple}
          MenuProps={{
            PaperProps: {
              style: {
                maxWidth: 400,
              },
            },
          }}
          renderValue={(selected) => {
            const selectedOption = options.find((option) => option.value === selected);
            return selectedOption ? selectedOption.label : "";
          }}
        >
          {clear && (
            <MenuItem key="clear" value="clear">
              Clear Selection
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                fontSize: fontSize, // Apply text size
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                whiteSpace: "normal", // This allows text wrapping
                wordBreak: "break-word", // This ensures long words break and wrap
                paddingY: 1, // Add some vertical padding
              }}
            >
              <div>{option.label}</div>
              {option?.description && (
                <div className="text-gray-500 text-sm">{option.description}</div>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

// {/* <FormControl margin="normal" className="flex flex-row">
// <Box
//   className="flex items-center space-x-4" // Tailwind utility classes
//   margin="normal"
// >
//   {/* Use Typography or span for inline label */}
//   <Typography variant="body1">Repeat every</Typography>

//   {/* Number Input */}
//   <TextField
//     sx={{
//       "& .MuiOutlinedInput-root": {
//         "&.Mui-focused fieldset": {
//           borderColor: "#fef08a",
//         },
//       },
//     }}
//     type="number"
//     value={repeatEvery}
//     onChange={(e) => {
//       const value = e.target.value;
//       if (value < 1) {
//         setRepeatEvery(1);
//       } else {
//         setRepeatEvery(value);
//       }
//     }}
//     variant="outlined"
//     size="small"
//     className="w-20" // fixed width using Tailwind
//     inputProps={{
//       min: 1,
//     }}
//   />

//   {/* Dropdown Select */}
//   <Select
//     sx={{
//       "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//         borderColor: "#fef08a",
//       },
//     }}
//     MenuProps={{
//       sx: {
//         "&& .Mui-selected": {
//           backgroundColor: "#e5e7eb",
//         },
//         "&& .Mui-selected:hover": {
//           backgroundColor: "#e5e7eb",
//         },
//       },
//     }}
//     labelId="repeat-unit-label"
//     value={repeatUnit}
//     onChange={(e) => {
//       if (e.target.value === "month") {
//         setMonthlyUnit("dayOfWeek");
//       }
//       setRepeatUnit(e.target.value);
//     }}
//     variant="outlined"
//     size="small"
//     className="w-32" // fixed width using Tailwind
//   >
//     <MenuItem value="week">
//       {repeatEvery == 1 ? "Week" : "Weeks"}
//     </MenuItem>
//     <MenuItem value="month">
//       {repeatEvery == 1 ? "Month" : "Months"}
//     </MenuItem>
//     <MenuItem value="year">
//       {repeatEvery == 1 ? "Year" : "Years"}
//     </MenuItem>
//   </Select>
// </Box>
// </FormControl> */}

import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const getDisplayValue = (input) => {
  const isDecimal = input.includes(".");
  // Match parts before and after the decimal point:
  const sections = input.split(".");

  // Integer part: add comma every 3 digits from the end of the segment
  const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Fractional part (ensuring only two digits after the decimal)
  const fractionalPart = sections[1] ? sections[1].slice(0, 2) : "";

  // Combine integer and fractional parts:
  input = integerPart + (isDecimal ? `.${fractionalPart}` : "");
  return input;
};

const cleanDecimal = (value) => {
  // Remove commas
  const withoutCommas = value.replace(/[,]/g, "");
  if (isNaN(parseFloat(withoutCommas))) {
    return "0.00";
  }
  return parseFloat(withoutCommas).toFixed(2);
};

const PriceInputMUI = ({
  label,
  value,
  onChange,
  onBlur = false,
  sx,
  priceInputFromSelect,
  ...props
}) => {
  const [displayValue, setDisplayValue] = useState(getDisplayValue(value));

  useEffect(() => {
    if (priceInputFromSelect) setDisplayValue(getDisplayValue(priceInputFromSelect));
  }, [priceInputFromSelect]);

  const handleChange = (event) => {
    let input = event.target.value;
    // Strip all non-numeric characters except the decimal point
    input = input.replace(/[^0-9.]/g, "");

    if (input) input = getDisplayValue(input);

    setDisplayValue(input);

    if (input === "") {
      onChange("0.00");
      return;
    }
    // Maintain a clean version for calculations and updating
    const cleanValue = cleanDecimal(input);
    onChange(cleanValue);
  };

  const handleBlur = () => {
    if (
      displayValue === "" ||
      !displayValue ||
      isNaN(parseFloat(displayValue?.replace(/[,]/g, "")))
    ) {
      onChange("0.00");
      setDisplayValue("0.00");
    } else {
      const clean = cleanDecimal(displayValue);
      onChange(clean);
      setDisplayValue(
        new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(clean)
      );
    }
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <TextField
      label={label}
      value={displayValue}
      onChange={handleChange}
      onBlur={handleBlur}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        inputProps: {
          min: 0, // Ensures no negative numbers
        },
      }}
      fullWidth
      variant="filled"
      sx={{
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "#111827",
          cursor: "not-allowed",
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#f9fafb",
          color: "#111827",
          "&:hover": {
            backgroundColor: "#f9fafb",
          },
          "&.Mui-focused": {
            backgroundColor: "#f9fafb", // Keep the background color gray-50 when focused
          },
          "&:before": {
            borderBottomColor: "#6b7280",
          },
          "&:after": {
            borderBottomColor: "#fef08a",
          },
          "&.Mui-focused:after": {
            borderBottomColor: "#fef08a",
          },
          "&.Mui-disabled": {
            cursor: "not-allowed",
            backgroundColor: "#f9fafb", // Ensure disabled background color matches enabled state
            color: "#111827", // Ensure disabled text color matches enabled state
            WebkitTextFillColor: "#111827 !important", // Change this line
            "&:before": {
              borderBottomStyle: "solid",
              borderBottomColor: "#6b7280",
            },
          },
        },
        "& .MuiInputLabel-root": {
          color: "#6b7280",
          "&.Mui-focused": {
            color: "#6b7280",
          },
          "&.Mui-disabled": {
            color: "#6b7280", // Ensure disabled label color matches enabled state
            cursor: "not-allowed",
          },
        },
        ...sx, // custom styles
      }}
      {...props}
    />
  );
};

export default PriceInputMUI;

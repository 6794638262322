import React, { useState, useEffect, useContext } from "react";
import {
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { auth, db } from "../utils/firebase";
import { setDoc, doc, Timestamp } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import { useUserContext } from "context/UserContext";
import Details from "../components/registerComponents/DetailsFormSalesRegister";
import { useLocation } from "react-router-dom";
import { createFreeTrialCheckoutSession } from "utils/stripe";
import Dialogue from "components/modals/Dialogue";
import { findPriceIdNew } from "utils/findPriceId";
import { node } from "constants/constants";
import NormalButton from "components/buttons/NormalButton";
import CancelButton from "components/buttons/CancelButton";

// Utility function to generate a secure random password
const generateSecurePassword = () => {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=<>?";
  let password = "";
  for (let i = 0, n = charset.length; i < 16; i++) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
};

export default function SalesRegistration() {
  const navigate = useNavigate();
  const { user, userData, setUserData, setRerun } = useUserContext();

  const [thisIsDuplicate, setThisIsDuplicate] = useState(false);
  const [modal, setModal] = useState({
    duplicate: false,
    subscription: false,
  });

  // logic for promotion
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const promo = params.get("promo");
  const redirect = params.get("redirect");

  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneBeta, setPhoneBeta] = useState("");

  //create business details state
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState("Window Cleaning");
  const [companySize, setCompanySize] = useState("Just you");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSession, setLoadingSession] = useState(false);
  const [country, setCountry] = useState("US");

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return regex.test(phoneNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    if (!companyName || !phoneBeta || !email || !country || !firstName || !lastName) {
      setError("Please fill out all fields");
      return;
    }
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    if (!isValidPhoneNumber(phoneBeta)) {
      setError("Please enter a valid phone number");
      return;
    }

    // so the user is the only check that we really need to do...
    const signInMethods = await fetchSignInMethodsForEmail(auth, email);

    if (signInMethods.length > 0) {
      // User already exists, handle accordingly.
      // this is where we should open a confirmation modal that asks if they want to create a new subscription for this account?
      //   setError("User already exists. Please sign in or reset your password.");
      setModal({ subscription: false, duplicate: true });
      setThisIsDuplicate(true);
      setIsLoading(false);
      return;
    } else {
      // Create new user
      console.log("no conflicting account");
      setModal({ subscription: true, duplicate: false });
      setThisIsDuplicate(false);
      setIsLoading(false);
    }
    return;
  };

  // plan will either Starter, Growing, or Enterprise
  const createNewBusiness = async ({ plan }) => {
    setIsLoading(plan);
    // add the prefix to the personal and business phone numbers
    let prefix = "+1";
    let modified = phoneBeta.replace(/\D/g, "");
    const final_number = prefix + modified;

    try {
      // we should conver this so that it is a magic link and doesn't require a password.. or have a default secure password and then there is an email that is sent to them automatically

      const password = generateSecurePassword();
      const userCred = await createUserWithEmailAndPassword(auth, email, password);

      // set the default currency as "usd"
      let currency = "usd";

      // check if the last element in the address is "CA" for Canadian addresses
      if (country === "CA") {
        currency = "cad";
      }

      console.log("country", country, "currency", currency);

      const defaultEstimateSettings = {
        businessAddress: true,
        businessEmail: true,
        businessName: true,
        businessPhone: true,
        customerAddress: true,
        customerEmail: true,
        customerPhone: true,
        customerDisplayName: true,
        estimateDate: true,
        estimateNumber: true,
        footerBusinessName: true,
        footerBusinessWebsite: false,
        lineItemsAmount: true,
        lineItemsDescription: true,
        lineItemsQuantity: true,
        lineItemsUnitPrice: true,
        subtotal: true,
        tax: true,
        logo: false,
      };

      const defaultInvoiceSettings = {
        businessEmail: true,
        businessName: true,
        businessPhone: true,
        businessAddress: true,
        customerAddress: true,
        customerDisplayName: true,
        customerEmail: true,
        customerPhone: true,
        footerBusinessName: true,
        footerBusinessWebsite: false,
        invoiceNumber: true,
        lineItemsAmount: true,
        lineItemsDescription: true,
        lineItemsQuantity: true,
        lineItemsUnitPrice: true,
        logo: false,
        subtotal: true,
        tax: true,
        savePaymentMethod: false,
      };

      const businessData = {
        estimateSettings: defaultEstimateSettings,
        invoiceSettings: defaultInvoiceSettings,
        fromWeb: true,
        fromSales: true,
        id: userCred.user.uid,
        email: userCred.user.email,
        firstName: firstName,
        lastName: lastName,
        phone: final_number,
        companyName: companyName,
        companyPhone: final_number,
        companyWebsite: website,
        address: country, // this will be either US or CA
        industry: industry, // these will be empty strings for now
        companySize: companySize, // default value
        createdAt: Timestamp.now(),
        timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone, // we will need to set the timezone based on the address?
        twilioNumber: "",
        taxRates: [],
        yelpReviewLink: "",
        googleReviewLink: "",
        facebookReviewLink: "",
        currency: currency,
      };

      // add promotion to the businessData object if it equals windowcleaning
      if (promo === "josh") {
        console.log("promotion", promo);
        businessData.promo = "josh";
      }

      const userDataForDb = {
        id: userCred.user.uid,
        email: userCred.user.email,
        firstName: firstName,
        lastName: lastName,
        phone: final_number,
        userType: "Admin",
        isAdmin: true,
        businessId: userCred.user.uid,
        createdAt: Timestamp.now(),
        color: {
          label: "Blue",
          value: "#3b82f6",
        },
      };

      const nickname =
        companySize === "Just you"
          ? "Starter"
          : companySize === "2-7 people"
          ? "Growing"
          : "Enterprise";

      // manually set the userData context so that we can access it, before all the database syncing happens
      // this doesn't really matter bc we are going to navigate to stripe and then it will refresh when we come back anyways..
      setUserData({
        userData: userDataForDb,
        bizData: businessData,
        subData: {
          status: "not_started", // "manual trial" --> used to be this before
          // manualTrialStart: new Date(),
          plan: { nickname: "starter" },
        },
      });

      await Promise.all([
        setDoc(doc(db, "businesses", userCred.user.uid), businessData),
        setDoc(doc(db, "users", userCred.user.uid), userDataForDb),
      ]);

      await handleCreateSession({ plan, businessId: userCred.user.uid });
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      setError(errorMessage);
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateSession = async ({ plan, businessId = null }) => {
    console.log("handle create new session");
    setLoadingSession(plan);
    try {
      const priceId = findPriceIdNew(plan, "monthly");

      if (!businessId) {
        try {
          const response = await fetch(`${node}/subscriptions/find-business-id`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email,
            }),
          });
          const result = await response.json();
          businessId = result.businessId;
        } catch (error) {
          console.error("Error finding business id:", error);
          alert("Error finding business id");
          throw new Error("Error finding business id");
        }
      }

      console.log("businessID!!!!", businessId);
      console.log("priceID", priceId);

      // Send password reset email
      await sendPasswordResetEmail(auth, email);

      const { url, error } = await createFreeTrialCheckoutSession({
        businessId,
        priceId,
        trialDays: null, // this way it will start instantly without a trial
      });
      if (url) {
        console.log("Checkout session created:", url);
        window.location.href = url;
      } else {
        // Handle error here
        alert("Error creating checkout session");
        console.log(error);
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    } finally {
      setLoadingSession(false);
    }
  };

  return (
    <>
      <div className="relative flex min-h-screen h-full bg-white  justify-center pt-16  overflow-auto">
        <Details
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          phoneBeta={phoneBeta}
          setPhoneBeta={setPhoneBeta}
          email={email}
          setEmail={setEmail}
          error={error}
          setError={setError}
          country={country}
          setCountry={setCountry}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          companyName={companyName}
          setCompanyName={setCompanyName}
        />
      </div>

      <Dialogue open={modal.duplicate} onClose={() => {}}>
        <div>
          This account already exists would you like to start a new subscription associated with
          this account? The account will be sent a link to reset their password to their email. Or
          they can sign in using their old password
        </div>
        <div className="flex flex-row items-center justify-between">
          <button
            type="submit"
            variant="solid"
            color="slate"
            className="w-32 gap-2  mt-10 bg-neutral-50 text-neutral-900 border border-neutral-400  active:bg-neutral-100
           group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2"
            onClick={() => setModal({ duplicate: false, selectSubscription: false })}
          >
            Cancel
          </button>
          <button
            onClick={() => setModal({ duplicate: false, subscription: true })}
            type="submit"
            variant="solid"
            color="slate"
            className="w-32 gap-2  mt-10 bg-neutral-900 text-white hover:bg-neutral-700 hover:text-neutral-100 active:bg-neutral-800
          active:text-neutral-300 focus-visible:outline-neutral-900 group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            Confirm
          </button>
        </div>
      </Dialogue>

      <Dialogue open={modal.subscription} onClose={() => {}}>
        <div className="flex flex-row items-start justify-between mb-16">
          <div className="font-bold  ">Select your plan (monthly)</div>
          <CancelButton handleCancel={() => setModal({ duplicate: false, subscription: false })} />
        </div>
        <div className="flex flex-row items-center justify-between">
          <NormalButton
            onClick={() =>
              thisIsDuplicate
                ? handleCreateSession({ plan: "Starter" })
                : createNewBusiness({ plan: "Starter" })
            }
            text="Starter"
            loading={loadingSession === "Starter" || isLoading === "Starter"}
          />
          <NormalButton
            onClick={() =>
              thisIsDuplicate
                ? handleCreateSession({ plan: "Growing" })
                : createNewBusiness({ plan: "Growing" })
            }
            text="Growing"
            loading={loadingSession === "Growing" || isLoading === "Growing"}
          />
          <NormalButton
            onClick={() =>
              thisIsDuplicate
                ? handleCreateSession({ plan: "Enterprise" })
                : createNewBusiness({ plan: "Enterprise" })
            }
            text="Enterprise"
            loading={loadingSession === "Enterprise" || isLoading === "Enterprise"}
          />
        </div>
      </Dialogue>
    </>
  );
}

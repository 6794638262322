import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { BiSearchAlt2 } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";

export default function SearchInput({
  label,
  inputValue,
  setInputValue,
  selected,
  setSelected,
  handleSelect,
  customers,
  loadingCustomers,
  ...props
}) {
  const navigate = useNavigate();
  const handleChange = (event) => {
    setSelected(false);
    setInputValue(event.target.value);
  };

  const handleClickSeeCustomers = () => {
    setInputValue("");
    setSelected(false);
    navigate("/customers");
  };

  return (
    <div className="relative flex-1 flex flex-col " {...props}>
      <input
        className=" appearance-none rounded-full outline-none focus:ring-0  text-md font-normal pl-9 h-9 w-64"
        value={inputValue}
        placeholder={label}
        onChange={handleChange}
        autoComplete="off"
      />
      <BiSearchAlt2
        className={`absolute top-1.5 left-2 text-2xl text-gray-400  ${
          inputValue && "text-gray-800"
        }`}
      />
      {inputValue && !selected && (
        <div
          className="absolute z-50 mt-1 bg-white rounded-md shadow-lg  w-96"
          style={{
            top: "35px",
            right: "0px",
          }}
        >
          <div className="px-4 font-thin text-sm pt-4 pb-3">Customers</div>
          <ul>
            {/* {loadingCustomers && customers?.length === 0 ? (
              <>
                {[...Array(3)].map((_, i) => (
                  <li
                    key={i}
                    className="px-4 py-2 flex justify-between animate-pulse items-center"
                  >
                    <div className="font-medium bg-gray-200 rounded h-6 w-1/2"></div>
                    <div className="font-normal bg-gray-200 rounded h-5 w-1/3"></div>
                  </li>
                ))}
              </>
            ) : ( */}
            <>
              {customers && customers.length > 0 ? (
                customers.map((customer) => {
                  const formattedMobile = customer?.phone?.mobile
                    ? "(" +
                      customer?.phone.mobile.substring(2, 5) +
                      ") " +
                      customer?.phone.mobile.substring(5, 8) +
                      "-" +
                      customer?.phone.mobile.substring(8, 12)
                    : "";

                  return (
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100 font-medium  flex fex-row justify-between"
                      key={customer.customerId}
                      onClick={() => handleSelect(customer)}
                    >
                      <div className="font-medium">{customer.displayName}</div>
                      <div className="font-normal">{formattedMobile}</div>
                    </li>
                  );
                })
              ) : (
                <li className="px-4 py-2 cursor-pointer hover:bg-gray-100 font-medium">
                  No results found
                </li>
              )}
            </>
            {/* )} */}
          </ul>
          <button
            className="px-4 pt-3 pb-4 text-xs font-medium underline"
            onClick={handleClickSeeCustomers}
          >
            See all customers
          </button>
        </div>
      )}
    </div>
  );
}

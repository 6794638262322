// RemovePaymentMethodModal.js
import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { node } from "constants/constants";
import { useUserContext } from "context/UserContext";
import { db } from "utils/firebase";
import { updateDoc, doc, collection } from "firebase/firestore";
import NormalButton from "components/buttons/NormalButton";
import CancelButton from "components/buttons/CancelButton";
import { useSnackBar } from "context/SnackBarContext";

export default function RemovePaymentMethodModal({
  open,
  onClose,
  jobDetails = null,
  setJobDetails = () => {},
  customerDetails = null,
  setCustomerDetails = () => {},
}) {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();
  const [loading, setLoading] = useState(false);

  const removePaymentMethod = async () => {
    setLoading(true);
    // data from business
    const businessId = userData?.bizData?.id;
    const stripeAccountId = userData?.bizData?.stripeAccountId;
    // data from jobDetails or customerDetails
    const customerId =
      jobDetails?.customer?.customerId || customerDetails?.customerId;
    const stripeCustomerId =
      jobDetails?.customer?.stripeCustomerId ||
      customerDetails?.stripeCustomerId;
    const stripePaymentMethodId =
      jobDetails?.customer?.stripePaymentMethod?.id ||
      customerDetails?.stripePaymentMethod?.id;

    if (
      !businessId ||
      !stripeAccountId ||
      !customerId ||
      !stripeCustomerId ||
      !stripePaymentMethodId
    ) {
      // show an alert message bc the required data is missing
      alert("Required data is missing");
      return;
    }

    const customerRef = doc(
      db,
      "businesses",
      businessId,
      "customers",
      customerId
    );

    try {
      const response = await fetch(
        `${node}/connectCustomers/remove-payment-method`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stripePaymentMethodId,
            stripeAccountId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to remove the payment method");
      }

      // update the customer object in firestore --> we do this in all cases..
      await updateDoc(customerRef, {
        stripePaymentMethod: null,
      });

      // update the job doc in firestore if we are coming from jobDetails
      if (jobDetails) {
        const jobRef = doc(
          db,
          "businesses",
          businessId,
          "jobs",
          jobDetails.jobId
        );
        await updateDoc(jobRef, {
          "customer.stripePaymentMethod": null,
        });

        // update the local state
        setJobDetails((prev) => ({
          ...prev,
          customer: {
            ...prev.customer,
            stripePaymentMethod: null,
          },
        }));
      }

      // update local customerDetails state if we are coming from customerDetails
      if (customerDetails) {
        setCustomerDetails((prev) => ({
          ...prev,
          stripePaymentMethod: null,
        }));
      }

      // Open snackbar
      openSnackBar("Payment method removed successfully", true);

      // update the local state (could either be the customer object or the job object)
    } catch (error) {
      // show a toast message
      openSnackBar("Failed to remove the payment method", false, true);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[645px] bg-white shadow-bold p-8 py-8 rounded-md">
        <h2 className="text-xl font-medium">Remove Saved Card</h2>
        <p className="mt-2">
          Removing this card will delete it from your account. You will not be
          able to use it for future payments unless you add it again.
        </p>
        <div className="flex flex-row items-center  gap-4 mt-6">
          <CancelButton handleCancel={onClose} disabled={loading} />
          <NormalButton
            onClick={removePaymentMethod}
            loading={loading}
            text="Remove Card"
          />
        </div>
      </div>
    </Modal>
  );
}

import { Bar } from "react-chartjs-2";

export default function SalespersonStats({ chartData }) {
  //   const names = chartData.map((person) => person.name);
  //   const totalAmountSoldPaid = chartData.map(
  //     (person) => person.totalAmountSoldPaid
  //   );
  //   const totalAmountSold = chartData.map((person) => person.totalAmountSold);

  // Calculate the combined total of all the paid jobs and total amount sold
  const combinedTotalPaid = chartData.reduce(
    (acc, person) => acc + person.totalAmountSoldPaid,
    0
  );
  const combinedTotalSold = chartData.reduce(
    (acc, person) => acc + person.totalAmountSold,
    0
  );
  //   const chartDataFormatted = {
  //     labels: [...names, "Total Paid"],
  //     datasets: [
  //       {
  //         label: "Paid Total",
  //         data: [...totalAmountSoldPaid, combinedTotalPaid],
  //         backgroundColor: "rgba(75, 192, 192, 0.6)",
  //       },
  //       {
  //         label: "Total Amount",
  //         data: totalAmountSold,
  //         backgroundColor: "rgba(153, 102, 255, 0.6)",
  //       },
  //     ],
  //   };
  const chartDataFormatted = {
    labels: ["Total Amount Sold", "Total Paid"],
    datasets: [
      {
        label: "Sales Stats",
        data: [combinedTotalSold, combinedTotalPaid],
        backgroundColor: [
          "rgba(218, 228, 123, 0.6)",
          "rgba(123, 211, 212, 0.6)",
        ],
      },
    ],
  };

  return (
    <>
      <Bar
        data={chartDataFormatted}
        options={{
          plugins: {
            title: {
              display: false,
              text: "Salesperson Stats",
              color: "#1f2937",
              font: {
                size: 16,
              },
            },
            legend: {
              display: false, // Set this property to false to hide the legend
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  const value = context.parsed.y;
                  label += `$${value.toLocaleString()}`;
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                color: "rgba(255, 255, 255, 0.3)",
              },
              ticks: {
                color: "#1f2937",
              },
            },
            y: {
              min: 0,
              beginAtZero: true,
              grid: {
                color: "rgba(255, 255, 255, 0.3)",
              },
              ticks: {
                color: "#1f2937",
                callback: function (value, index, values) {
                  return `$${value.toLocaleString()}`;
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        }}
      />
    </>
  );
}

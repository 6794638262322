//InvoicePreview.jsx
import React from "react";
import { formatPhoneDisplay } from "utils/formatPhone";
import { splitAddress } from "utils/helpers";

const InvoicePreview = ({ userData, invoiceSettings }) => {
  //   console.log("invoiceSettings", invoiceSettings);
  const businessAddressSplit = splitAddress(userData?.bizData?.address);
  return (
    <div className="px-8 py-8 bg-white shadow-bold w-full relative" style={{ height: "700px" }}>
      <div className="flex justify-between ">
        <div className="flex flex-col">
          {/* Invoice Details */}
          <h2 className=" text-xl font-bold">Invoice</h2>
          <div className="mt-2 text-sm">
            {invoiceSettings?.invoiceNumber && (
              <p>
                <span className="">Number:</span> 1234
              </p>
            )}

            <p>
              <span className="">Service Date:</span> 01/01/2023
            </p>
            <p>
              <span className="">Due:</span> Upon Receipt
            </p>
          </div>
        </div>
        {invoiceSettings?.logo ? (
          <>
            {userData?.bizData?.logo ? (
              <img src={userData?.bizData?.logo} alt="Business Logo" className="w-24 h-24" />
            ) : (
              <div className="w-24 h-24">
                <p className="text-center text-xs">
                  No logo found. You can upload it in company profile
                </p>
              </div>
            )}
          </>
        ) : (
          <div className="w-24 h-24"></div>
        )}
      </div>
      <div className="flex justify-between text-sm mt-4">
        {/* Customer Details */}
        <div>
          {invoiceSettings?.customerDisplayName && (
            <p className="font-medium">Customer Display Name</p>
          )}
          {invoiceSettings?.customerAddress && <p>Customer Address</p>}
          {invoiceSettings?.customerEmail && <p>Customer Email</p>}
          {invoiceSettings?.customerPhone && <p>Customer Phone</p>}
        </div>
        {/* Business Details */}
        <div className="text-right w-2/5 text-wrap">
          {invoiceSettings?.businessName && (
            <p className="font-medium">{userData?.bizData?.companyName}</p>
          )}
          {invoiceSettings?.businessAddress && (
            <div>
              <p className="">{businessAddressSplit[0]}</p>

              {businessAddressSplit?.[1] ? (
                <p className="text-wrap">{businessAddressSplit[1]}</p>
              ) : null}
            </div>
          )}

          {invoiceSettings?.businessEmail && (
            <p className="text-wrap break-words">{userData?.bizData?.email}</p>
          )}
          {invoiceSettings?.businessPhone && (
            <p>{formatPhoneDisplay(userData?.bizData?.companyPhone)}</p>
          )}
        </div>
      </div>

      {/* Line Items */}
      <div className="mt-12 text-sm">
        <table className="w-full">
          <thead className=" border-b border-gray-400 text-xs">
            <tr>
              <th className="text-left  px-2 w-1/2">Description</th>
              {invoiceSettings?.lineItemsQuantity && <th className="text-right  px-2">Qty</th>}
              {invoiceSettings?.lineItemsUnitPrice && (
                <th className="text-right  px-2">Unit Price</th>
              )}
              {invoiceSettings?.lineItemsAmount && <th className="text-right  px-2">Amount</th>}
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-300">
              <td className="py-4 px-4 align-top ">
                <p>Line Item Name</p>
                {invoiceSettings?.lineItemsDescription && (
                  <p className="text-xs mt-1">Line Item Description</p>
                )}
              </td>
              {invoiceSettings?.lineItemsQuantity && (
                <td className="text-right py-4 px-2 align-top">1.0</td>
              )}
              {invoiceSettings?.lineItemsUnitPrice && (
                <td className="text-right py-4 px-2 align-top">$100.00</td>
              )}
              {invoiceSettings?.lineItemsAmount && (
                <td className="text-right py-4 px-2 align-top">$100.00</td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      {/* Totals */}
      <div className="mt-4 flex justify-end text-right w-full text-sm">
        <div className=" w-5/12">
          {invoiceSettings?.subtotal && (
            <p className="flex justify-between px-2">
              <span className="">Subtotal</span> <span>$100.00</span>
            </p>
          )}
          {invoiceSettings?.tax && (
            <p className="flex justify-between px-2 pt-2">
              <span className="">Tax</span> <span>$10.00</span>
            </p>
          )}

          {(invoiceSettings?.tax || invoiceSettings?.subtotal) && (
            <div className="border-b border-gray-300 my-2"></div>
          )}

          <div className="flex justify-between px-2">
            <p className="">Total</p>
            <p className="">$110.00</p>
          </div>

          <div className="border-b border-gray-300 my-2"></div>

          <div className="flex justify-between px-2">
            <p className="font-bold">Amount Due</p>
            <p className="font-bold">$110.00</p>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="mt-4 text-start absolute bottom-6 w-full left-0 right-0">
        {(invoiceSettings?.footerBusinessName || invoiceSettings?.footerBusinessWebsite) && (
          <div className="border-b border-gray-200 mx-2" />
        )}
        <div className="flex flex-row mt-2 justify-between">
          <p className="text-2xs text-gray-500 pl-8">
            {invoiceSettings?.footerBusinessName ? `${userData?.bizData?.companyName}` : ""}
          </p>

          <p className="text-2xs text-gray-500 pr-8">
            {invoiceSettings?.footerBusinessWebsite ? `${userData?.bizData?.companyWebsite}` : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InvoicePreview;
